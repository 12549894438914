import { v4 as uuid } from 'uuid';
import { Grid } from '@mui/material'
import { H3 } from 'components/texts'
import React, { useState } from 'react'
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { Input, Checkbox, DatePicker, Button } from 'components/forms/components'

const goToLibro = (libro_id) => {
    window.open(`/empleado/libros/${libro_id}`, '_blank', 'noopener,noreferrer')
}

export default function DataFormLibroClasesParticulares({ claseParticular, setValue, register, errors }) {
    const [libro, setLibro] = useState(claseParticular?.libro ?? null)
    const [libroAsociado, setLibroAsociado] = useState(claseParticular?.libro?.libro_asociado ?? null)

    const [libroSolicitadoChecked, setLibroSolicitadoChecked] = useState(claseParticular?.venta_libro ? true : false)
    const [libroSolicitadoAsociadoChecked, setLibroSolicitadoAsociadoChecked] = useState(claseParticular?.venta_libro_asociado ? true : false)

    // const handleLibroSolicitadoChange = (checked) => {
    //     setLibroSolicitadoChecked(checked)

    //     // Si no está seleccionado, limpia o establece en null los valores relacionados
    //     if (!checked) {
    //         setValue("precio", null)
    //         setValue("fecha_peticion", null)
    //     }
    // }

    return (
        <Grid container p={3}>

            {/* LIBRO PRINCIPAL */}

            <Grid item xs={12} style={{ display: 'flex', alignItems: 'end', justifyContent: 'end', gap: 10 }}>
                <Button disabled className="" variant="outlined">Emitir recibo</Button>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <H3>Libro principal: </H3>
                </Grid>

                <Grid item xs={8}>
                    <ItemsSelect
                        label={"Libro principal"}
                        name={"libro_id"}
                        keyValue={"libro"}
                        register={register}
                        errors={errors}
                        defaultValue={claseParticular?.libro_id ?? ""}
                        onChange={(_, libro) => {
                            if (libro) setLibro(libro ?? null)

                            setValue("libro_asociado_id", libro?.libro_asociado?.id ?? null)
                            setValue("libro_asociado_solicitado", false)
                            setLibroAsociado(libro.libro_asociado ?? null)
                            setLibroSolicitadoAsociadoChecked(false)
                        }}
                        searcheable />
                </Grid>


                <Grid item xs={2}>
                    <Checkbox
                        label={"Libro solicitado"}
                        name={"libro_solicitado"}
                        register={register}
                        errors={errors}
                        onClick={setLibroSolicitadoChecked}
                        // onClick={handleLibroSolicitadoChange}
                        disabled={libro ? false : true}
                        defaultChecked={libroSolicitadoChecked} />
                </Grid>

                <Grid item xs={2}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_peticion"} label={"Fecha solicitado"} defaultValue={claseParticular?.venta_libro?.fecha_peticion} setValue={setValue} clearable disabled={!libroSolicitadoChecked} />
                </Grid>

                <Grid item xs={2}>
                    <Input register={register} errors={errors} label={"Precio"} name={"precio"} className="w-100" defaultValue={claseParticular?.venta_libro?.precio ?? null} type={"number"} disabled={!libroSolicitadoChecked} />
                </Grid>

                <Grid item xs={3}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_pago"} label={"Fecha de pago"} defaultValue={claseParticular?.venta_libro?.fecha_pago ?? ""} setValue={setValue} clearable disabled={!libroSolicitadoChecked} />
                </Grid>

                <Grid item xs={3}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_entrega"} label={"Fecha de entrega"} defaultValue={claseParticular?.venta_libro?.fecha_entrega ?? ""} setValue={setValue} clearable disabled={!libroSolicitadoChecked} />
                </Grid>

                <Grid item xs={2}>
                    <Input
                        register={register}
                        errors={errors}
                        label={"Unidades en el almacén"}
                        name={"unidades"}
                        className="w-100"
                        defaultValue={libro?.unidades_disponibles ?? ""}
                        uppercase
                        disabled
                        useValue />
                </Grid>
                <Grid item xs={2}>
                    <Button onClick={() => goToLibro(libro.id)} disabled={libro ? false : true}>
                        Detalles
                    </Button>
                </Grid>
            </Grid>

            {/* ******************************************************************************** */}
            {/* LIBRO ASOCIADO */}
            <Grid container spacing={2} mt={5}>
                <Grid item xs={12}>
                    <H3>Libro asociado: </H3>
                </Grid>

                <Grid item xs={8}>
                    <Input key={uuid()} label={"Libro asociado"} defaultValue={libroAsociado?.nombre ?? "..."} className="w-100" uppercase disabled />
                    <Input type={"hidden"} name={"libro_asociado_id"} className="invisible" defaultValue={libroAsociado?.id ?? null} register={register} />
                </Grid>

                <Grid item xs={2}>
                    <Checkbox
                        key={uuid()}
                        name={"libro_asociado_solicitado"}
                        label={"Libro solicitado"}
                        register={register}
                        errors={errors}
                        onClick={setLibroSolicitadoAsociadoChecked}
                        disabled={libroAsociado ? false : true}
                        defaultChecked={libroSolicitadoAsociadoChecked} />
                </Grid>

                <Grid item xs={2}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_asociado_peticion"} label={"Fecha solicitado"} defaultValue={claseParticular?.venta_libro_asociado?.fecha_peticion} setValue={setValue} clearable disabled={!libroSolicitadoAsociadoChecked} />
                </Grid>

                <Grid item xs={2}>
                    <Input register={register} errors={errors} label={"Precio"} name={"precio_asociado"} className="w-100" defaultValue={claseParticular?.venta_libro_asociado?.precio ?? null} uppercase type={"number"} disabled={!libroSolicitadoAsociadoChecked} />
                </Grid>

                <Grid item xs={3}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_pago_asociado"} label={"Fecha de pago"} defaultValue={claseParticular?.venta_libro_asociado?.fecha_pago ?? ""} setValue={setValue} clearable disabled={!libroSolicitadoAsociadoChecked} />
                </Grid>

                <Grid item xs={3}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_entrega_asociado"} label={"Fecha de entrega"} defaultValue={claseParticular?.venta_libro_asociado?.fecha_entrega ?? ""} setValue={setValue} clearable disabled={!libroSolicitadoAsociadoChecked} />
                </Grid>

                <Grid item xs={2}>
                    <Input
                        register={register}
                        errors={errors}
                        label={"Unidades en el almacén"}
                        name={"unidades_asociado"}
                        className="w-100"
                        defaultValue={libroAsociado?.unidades_disponibles ?? ""}
                        uppercase
                        disabled
                        useValue />
                </Grid>

                <Grid item xs={2}>
                    <Button onClick={() => goToLibro(libroAsociado.id)} disabled={libroAsociado ? false : true}>
                        Detalles
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}