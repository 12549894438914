import {
    InscripcionAlumnosAcademiaTemplate,
    InscripcionActividadesAcademiaTemplate,
    InscripcionGruposAcademiaTemplate,
    InscripcionesColectivosAcademiaTemplate,
    InscripcionPendientesAcademiaTemplate
} from "./parts"

/**
 * Array que contiene las opciones de búsqueda disponibles en la interfaz.
 * Cada objeto del array representa una opción de búsqueda específica con los siguientes atributos:
 * - value: Un identificador numérico único para cada opción de búsqueda.
 * - columnKey: Una clave que identifica la categoría de la búsqueda, utilizada para referenciar los datos.
 * - label: Texto descriptivo de la opción de búsqueda, que se mostrará en la interfaz de usuario.
 * - template: Componente React que se renderizará como formulario o interfaz específica para esa búsqueda.
 */
export const itemsSearch = [
    { value: 0, columnKey: "alumnos", label: "Inscripciones de la Academia por Alumnos", template: InscripcionAlumnosAcademiaTemplate },
    { value: 1, columnKey: "grupos", label: "Inscripciones de la Academia por Grupos", template: InscripcionGruposAcademiaTemplate },
    { value: 2, columnKey: "actividades", label: "Inscripciones de la Academia por Actividades", template: InscripcionActividadesAcademiaTemplate },
    { value: 3, columnKey: "colectivos", label: "Inscripciones de la Academia por colectivos", template: InscripcionesColectivosAcademiaTemplate },
    { value: 4, columnKey: "pendientes", label: "Inscripciones Pendientes de la Academia", template: InscripcionPendientesAcademiaTemplate },
]

/**
 * Array que contiene las opciones de ordenamiento disponibles para los resultados de búsqueda.
 * Cada objeto en el array define una manera de ordenar los resultados, con los siguientes atributos:
 * - value: Cadena de texto que representa la sintaxis de ordenamiento (en este caso, JSON) que la API puede entender.
 * - label: Texto descriptivo de la opción de ordenamiento, mostrado al usuario en la interfaz.
 */
export const itemsOrder = [
    [
        { value: '{"alumno.nombre": "ASC"}', label: "Alumnos" },
    ],
    [
        { value: '{"alumno.nombre": "ASC"}', label: "Alumnos" },
        { value: '{"grupo_academias.clave_grupo": "ASC"}', label: "Grupos" },
    ],
    [
        { value: '{"alumno.nombre": "ASC"}', label: "Alumnos" },
        { value: '{"registro_actividad.nombre": "ASC"}', label: "Actividades" },
        { value: '{"inscripcion_academias.nivel": "ASC"}', label: "Test de Nivel" },
    ],
    [
        { value: '{"alumno.nombre": "ASC"}', label: "Alumnos" },
        { value: '{"registro_actividad.nombre": "ASC"}', label: "Actividades" },
        { value: '{"actividades.test": "ASC"}', label: "Empresas" },
    ],
    [
        { value: '{"alumno.nombre": "ASC"}', label: "Alumnos" },
        { value: '{"registro_actividad.nombre": "ASC"}', label: "Actividades" },
        { value: '{"inscripcion_academias.nivel": "ASC"}', label: "Test de Nivel" },
    ],
]

/**
 * Array que contiene las opciones para poder buscar todas las inscripciones, 
 * o filtrar por las inscripciones que son actuales (fecha de baja NULL)
 */
export const itemsActual = [
    { value: 0, label: "Todos" },
    { value: 1, label: "Actual" }
]