import React from 'react'
import { Text } from 'components/texts'
import { Grid, Stack } from '@mui/material'
import Statics from 'objects/common/Statics'
import { Line } from 'components/data_display'
import { AlumnoFullItem } from 'components/data_display/users'

/**
 * Formulario con los datos generales de la inscripcion a un colegio
 */
export default function GeneralInscripcionColegioDataForm({ inscripcion }) {
    return (
        <Grid container>
            <Grid container px={5}>
                <Grid item xs={12} md={6} py={3} pr={2}>
                    <AlumnoFullItem alumno={inscripcion?.alumno ?? null} imageXS={4} />
                </Grid>

                <Grid item xs={12} md={6} sx={{ borderLeft: { xs: "none", md: "1px solid #D9D9D9" } }} py={3} pl={3}>
                    <Stack sx={{ flexDirection: { xs: "row", md: "column" } }}>
                        <Stack sx={{ mx: { xs: 2, md: 0 }, my: { xs: 0, md: 2 } }}>
                            <Text fontWeight={"bold"}>Curso escolar</Text>
                            <Text>{inscripcion.curso_escolar?.valor ?? "Sin especificar"}</Text>
                        </Stack>

                        <Stack sx={{ mx: { xs: 2, md: 0 }, my: { xs: 0, md: 2 } }}>
                            <Text fontWeight={"bold"}>Forma de recogida</Text>
                            <Text>{inscripcion.forma_recogida?.nombre ?? "Sin especificar"}</Text>
                        </Stack>

                        <Stack sx={{ mx: { xs: 2, md: 0 }, my: { xs: 0, md: 2 } }}>
                            <Text fontWeight={"bold"}>Forma de conocimiento</Text>
                            <Text>{inscripcion.forma_conocimiento?.nombre ?? "Sin especificar"}</Text>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>

            <Grid container>
                <Line />
            </Grid>

            <Grid container px={5} mt={2} spacing={3} pb={3}>
                <Grid item xs={6} md={3}>
                    <Stack>
                        <Text fontWeight={"bold"}>Colegio</Text>
                        <Text>{inscripcion.colegio?.nombre ?? "Sin especificar"}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={6} md={3}>
                    <Stack>
                        <Text fontWeight={"bold"}>Actividad</Text>
                        <Text>{inscripcion.actividad?.valor ?? "Sin especificar"}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={6} md={3}>
                    <Stack>
                        <Text fontWeight={"bold"}>Horario</Text>
                        <Text>{inscripcion.horario?.horario_formatted ?? "Sin especificar"}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={6} md={3}>
                    <Stack>
                        <Text fontWeight={"bold"}>Hermanos</Text>
                        <Text>{inscripcion.hermanos ? "Sí" : "No"}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={6} md={3}>
                    <Stack>
                        <Text fontWeight={"bold"}>Encuesta telefónica</Text>
                        <Text>{inscripcion.encuestado ? "Sí" : "No"}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={6} md={3}>
                    <Stack>
                        <Text fontWeight={"bold"}>Test de nivel</Text>
                        <Text>{inscripcion.test_nivel ?? "Sin especificar"}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={6} md={3}>
                    <Stack>
                        <Text fontWeight={"bold"}>Fecha de inscripción</Text>
                        <Text>{inscripcion?.fecha_inscripcion ? Statics.formatDate(inscripcion?.fecha_inscripcion) : "Sin especificar"}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={6} md={3}>
                    <Stack>
                        <Text fontWeight={"bold"}>Fecha de incorporación</Text>
                        <Text>{inscripcion?.fecha_incorporacion ? Statics.formatDate(inscripcion?.fecha_incorporacion) : "Sin especificar"}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={6} md={3}>
                    <Stack>
                        <Text fontWeight={"bold"}>Fecha baja</Text>
                        <Text>{inscripcion?.fecha_baja ? Statics.formatDate(inscripcion?.fecha_baja) : "Sin especificar"}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={6} md={3}>
                    <Stack>
                        <Text fontWeight={"bold"}>Motivo baja</Text>
                        <Text>{inscripcion?.motivo_baja?.nombre ?? "Sin especificar"}</Text>
                    </Stack>
                </Grid>
            </Grid>

        </Grid >
    )
}
