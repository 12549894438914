import { Text } from "components/texts";
import { EmpleadoListContext } from "contexts/employee/ListContext";
import { enqueueSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";

export default function useSingleAulaLogic(navigationParams, navigate, requests) {
    const { items, updateItem } = useContext(EmpleadoListContext)

    const [id_aula, setIdAula] = useState(navigationParams.id_aula)
    const [aula, setAula] = useState()

    const updateId = (id) => {
        navigate(`../academias/aulas/${id}`);
        setIdAula(id);
    }

    const getAula = (getFromServer = false) => {
        const aula = items.find(item => item.id == id_aula)
        if (items.length == 0 || !aula || getFromServer) {
            requests.getAula(id_aula, (aula) => {
                setAula(aula)
                updateItem(aula)
            }, () => enqueueSnackbar(<Text>No se ha podido obtener el aula.</Text>, { variant: "error" }))
        } else {
            setAula(aula)
        }
    }

    useEffect(getAula, [id_aula])

    return { id_aula, setAula, updateId, aula }
}