import axios from 'axios'
import store from 'store/store'
import { v4 as uuid } from 'uuid'
import { API_URL } from 'constants/Api'
import { useQuery } from "@tanstack/react-query"
import { SearcheableSelect, Select } from 'components/forms/components'
import React, { useState, useEffect, useRef } from 'react'
import { itemsOrder } from 'components/forms/display/academia/grupos/SearchForm/constants'
import { Text } from 'components/texts'

const CURSO_ESCOLAR_URL = 'aux/curso_escolar/all'

const getCursoEscolarActual = () => {
    const fechaActual = new Date();
    const mesActual = fechaActual.getMonth(); // Obtenemos el mes actual (0-11)

    let añoInicio, añoFin;

    // Determinar el año escolar
    if (mesActual >= 8) { // Si estamos en o después de septiembre (mes 8)
        añoInicio = fechaActual.getFullYear();
        añoFin = añoInicio + 1;
    } else {
        añoFin = fechaActual.getFullYear();
        añoInicio = añoFin - 1;
    }

    return `${añoInicio}-${añoFin}`
}

const CursoEscolarSelect = ({
    register,
    defaultValue,
    className,
    name,
    errors,
    label,
    width,
    disabled,
    initialLabel,
    wantToReset,
    onDefaultValueLoaded,
    onFetchedFinished = () => { },
    onChange,
    goToPreviousOption,
    setValue,
    allOption,
    params,
    searcheable = false,
}) => {
    const [items, setItems] = useState([{ value: '', label: initialLabel ?? 'Cargando...' }])
    const [rawItems, setRawItems] = useState([])

    const selectRef = useRef()

    const getData = (url = CURSO_ESCOLAR_URL) => {
        const { userState } = store.getState()
        return axios.get(`${API_URL}/${url}`, {
            params,
            responseType: 'json',
            headers: { Authorization: `Bearer ${userState.token}` }
        }).then(response => {
            return response.data
        })
    }

    const query = useQuery({ queryKey: ["curso_escolar-all"], queryFn: () => getData(), enabled: false })

    const onFetched = (res) => {
        const fetchedItems = res.cursos_escolares.map(item => ({ value: item.id, label: item.valor }))

        if (defaultValue === null && (!defaultValue || defaultValue == "")) {
            const cursoEscolarActual = getCursoEscolarActual()
            const item = fetchedItems.find(item => item.label == cursoEscolarActual)
            if (item) {
                selectRef.current.setValue(item.value, () => {
                    if (setValue) setValue(name ?? "", item.value)
                    if (onChange) onChange({ target: { value: item.value } })
                })
            }
        }

        setItems(fetchedItems)
        setRawItems(res.cursos_escolares)
    }

    const onError = () => {
        setItems([{ value: "", label: "Ha habido un error al descargar los cursos escolares" }])
        setRawItems([])
    }

    useEffect(() => {
        if (query.isFetched && !query.isError) {
            onFetched(query.data)
            onFetchedFinished(query.data)
        }
        if (query.isError) onError()
        if (!query.isFetched && !query.isFetching) query.refetch()
    }, [query.isFetched])

    useEffect(() => {
        if (goToPreviousOption)
            selectRef.current.goToPreviousOption()
    }, [goToPreviousOption])

    const onChangeSelect = (event) => {
        if (onChange) {
            const itemSelected = rawItems.find(item => item.id == event.target.value)
            onChange(event, itemSelected)
        }
    }

    const clearFutureCursos = () => {
        const cursoEscolarActual = getCursoEscolarActual()
        const itemsCopy = [...items]
        const index = itemsCopy.findIndex(item => item.label == cursoEscolarActual)

        const finalCursos = index != -1 ? itemsCopy.splice(index, itemsCopy.length) : itemsCopy
        if (allOption)
            finalCursos.unshift({ value: 0, label: <Text fontWeight={"bold"}>Todos</Text> })

        return finalCursos
    }

    const itemsToRender = clearFutureCursos()

    const labelId = name + "_select-id-" + uuid()
    const SelectToRender = searcheable ? SearcheableSelect : Select
    return (
        <SelectToRender
            ref={selectRef}
            width={width ?? "100%"}
            items={itemsToRender}
            className={className}
            disabled={disabled}
            defaultValue={defaultValue}
            onDefaultValueLoaded={onDefaultValueLoaded}
            register={register}
            onChange={onChangeSelect}
            name={name}
            errors={errors}
            labelId={labelId}
            wantToReset={wantToReset}
            label={label ?? "Curso escolar"}
            native />
    )
}

export default CursoEscolarSelect