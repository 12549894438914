import { EmpleadoListContext } from "contexts/employee/ListContext"
import { useContext, useEffect, useState } from "react"

/**
 * Hook personalizado `useLogic` para gestionar la lógica común utilizada en varias partes de la aplicación.
 * Proporciona estados y funciones para controlar la tabla de datos y las operaciones de búsqueda.
 * Utiliza el contexto `EmpleadoListContext` para acceder y actualizar los datos.
 * 
 * @returns {object} - Objeto que contiene estados y funciones para la gestión de datos y búsquedas.
 */
export default function useLogic() {
    const [data, setData] = useState({ data: [], rowCount: 0 })
    const [loadingTable, setLoadingTable] = useState(false)
    const [params, setParams] = useState(null)

    const { update, resetContextState } = useContext(EmpleadoListContext)

    useEffect(() => {
        setLoadingTable(false)
    }, [data])

    useEffect(resetContextState, [])

    /**
     * Callback para actualizar la tabla una vez realizada la búsqueda.
     * 
     * @param {Array} data - Datos de los libros encontrados.
     * @param {Int} rowCount - Contador de filas de la tabla.
     * @param {Int} page - Página actual.
     * @param {Object} params - Parámetros de búsqueda.
     */
    const updateTable = (data, rowCount, page, params) => {
        setData({ data, rowCount })
        update(rowCount, data, params, page)
        setParams(params)
    }

    return { loadingTable, updateTable, setLoadingTable, params, data }
}