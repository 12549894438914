import { TabContext } from '@mui/lab'
import { COLOR } from 'theme/Colors.js'
import { SnackbarProvider } from 'notistack'
import Requests from './Requests/Requests.js'
import { Text } from 'components/texts/index.js'
import { useAcademiaGrupoCreateLogic } from 'hooks'
import { CreateHeader } from 'pages/logged/admin/components'
import { BreadcrumbsList, Line } from 'components/data_display'
import { Box, Container, Paper, Tab, Tabs } from '@mui/material'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { GeneralGrupoAcademiaDataForm, IdiomaGrupoAcademiaDataForm, ProgramacionesAcademiaDataForm } from 'components/forms/display/index.js'

/**
 * Componente React para crear un grupo en la academia.
 * Utiliza múltiples formularios para capturar y validar los datos del grupo a crear.
 *
 * @returns {JSX.Element} Elemento JSX que representa el formulario completo para crear un grupo académico.
 */
export default function CreateGrupoAcademia() {
  const { register, handleSubmit, errors, setValue, getValues, control, isMounted, createGrupoAcademia, tab, setTab, headerRef, libro, setLibro } = useAcademiaGrupoCreateLogic({ requests: new Requests() })

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(createGrupoAcademia, (errors) => console.error(errors))}>
        <BreadcrumbsList breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../", label: "Academia" }, //--> Te dirige al apartado Grupos
          { to: "./../", label: "Grupos" },
          { to: "./", label: "Crear grupo", actualItem: true },
        ]} />

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <CreateHeader
            ref={headerRef}
            title={"Crear grupo"} />

          <Line />

          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
              <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>DATOS GENERALES</Text>} value={"1"} />
                <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>IDIOMAS</Text>} value={"2"} />
                <Tab label={<Text fontWeight={tab === "3" ? "bold" : null} color={tab === "3" ? COLOR.standard.main : null}>PROGRAMACIONES</Text>} value={"3"} />
                <Tab label={<Text fontWeight={tab === "4" ? "bold" : null} color={tab === "4" ? COLOR.standard.main : null}>HORARIOS</Text>} value={"4"} disabled/> {/**Tab para ver el horario */}
              </Tabs>
            </Box>

            <TabPanel value={"1"}>
              <GeneralGrupoAcademiaDataForm
                setValue={setValue}
                register={register}
                errors={errors}
                isMounted={isMounted}
                control={control}
              />
            </TabPanel>

            <TabPanel value={"2"}>
              <IdiomaGrupoAcademiaDataForm
                setValue={setValue}
                register={register}
                onChangeLibro={setLibro}
                errors={errors}
                control={control}
                getValues={getValues}
              />

            </TabPanel>

            <TabPanel value={"3"}>
              <ProgramacionesAcademiaDataForm
                setValue={setValue}
                libro={libro}
                register={register}
                errors={errors}
                getValues={getValues} />
            </TabPanel>


          </TabContext>

        </Paper>

        <SnackbarProvider autoHideDuration={3000} />
      </form>
    </Container>
  )
}