import React from 'react'
import { Grid, Stack } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

/**
 * Informacion básica del alumno
 */
export default function SkeletonCurriculum() {


    return (
        <Grid container spacing={2} mt={2} px={5}>


            <Grid container >
                <Grid item xs={12} mt={2}>
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1.2em' }} width={"90%"} />

                    </Stack>
                </Grid>
                <Grid item xs={10} mt={2}>
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1.2em' }} width={"90%"} />
                    </Stack>
                </Grid>

                <Grid item xs={2} mt={2}>
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1.2em' }} width={"90%"} />
                    </Stack>
                </Grid>

                <Grid item xs={10} mt={2}>
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1.2em' }} width={"90%"} />
                    </Stack>
                </Grid>

            </Grid>

            <Grid container mt={4} >

                <Grid item xs={2} mt={2}>
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1.2em' }} width={"90%"} />
                    </Stack>
                </Grid>
            </Grid>

        </Grid>
    )
}

