import React, { Component } from 'react'

export default class H2 extends Component {

  /**
   * Render
   * 
   * @returns {Component}
   */
  render() {
    return (
      <h2 className={'custom-h2 bold ' + (this.props.className ?? "")} style={this.props.style ?? {}}>{this.props.children ?? ""}</h2>
    )
  }
}
