import React from 'react'
import { Grid } from "@mui/material"
import { Datagrid } from 'components/data_display'
import Statics from 'objects/common/Statics'


const columns = [
    { field: "anio", headerName: "Año", valueGetter: (params) => { { return params?.row?.curso_escolar?.valor ?? 'No definido' } }, sortable: false, filterable: false },

    { field: "Grupo", headerName: "Grupo", valueGetter: (params) => { { return params?.row?.grupo_academia?.clave_grupo ?? 'No definido' } }, sortable: false, filterable: false },

    { field: "fecha_baja", headerName: "F. Baja", valueGetter: (params) => { { return params?.row?.fecha_baja ? Statics.formatDate(params?.row?.fecha_baja) : 'No definido' } }, sortable: false, filterable: false },

    { field: "libro", headerName: "Libro", flex: 1, valueGetter: (params) => { { return params?.row?.grupo_academia?.libro?.nombre ?? 'No definido' } }, sortable: false, filterable: false },

    {
        field: "profesor", headerName: "Profesor", flex: 1,
        valueGetter: (params) => {
            if (!params?.row?.profesor_actual)
                return "No definido"

            return params?.row?.profesor_actual?.empleado?.nombre + ' ' + params?.row?.profesor_actual?.empleado?.apellido1 + ' ' + params?.row?.profesor_actual?.empleado?.apellido2 ?? 'No definido'
        },
        sortable: false, filterable: false
    },

    //TODO: El campo Nota es la calificación que obtienen los alumnos de idiomas en elreport trimestral (en el histórico muestro la nota media del report del últimotrimestre)
    // { field: "nota", headerName: "Nota", valueGetter: (params) => { { return params?.row?.alumno?.curso_actual?.nota_final ?? 'No definido' } }, sortable: false, filterable: false },

    { field: "colegio", headerName: "Colegio", flex: 1, valueGetter: (params) => { { return params?.row?.alumno?.curso_actual?.colegio?.nombre ?? 'No definido' } }, sortable: false, filterable: false },

    { field: "libroColegio", headerName: "Libro en Colegio", flex: 1, valueGetter: (params) => { { return params?.row?.alumno?.curso_actual?.libro?.nombre ?? 'No definido' } }, sortable: false, filterable: false },

    { field: "notaColegio", headerName: "Nota en Colegio", flex: 1, maxWidth: 120, valueGetter: (params) => { { return params?.row?.alumno?.curso_actual?.nota_final ?? 'No definido' } }, sortable: false, filterable: false },

    { field: "comentarioProfesorAP", headerName: "Comentario Profesor A/P", flex: 1, valueGetter: (params) => { { return params?.row?.comentario_profesor_ap ?? 'No definido' } }, sortable: false, filterable: false },
]

export default function InscripcionesAcademiaTemplate({ historico = [], isLoading }) {
    return (
        <Grid container p={3}>
            <Grid item xs={12}>
                <Datagrid
                    loading={isLoading}
                    rows={historico}
                    onRowDoubleClick={({ row }) => Statics.openNewTab(`../../empleado/academias/inscripciones/${row.id}`)}
                    columns={columns} />
            </Grid>
        </Grid>
    )
}
