import { useDatosColegioAgeCheck, useErrorHandler } from 'hooks'
import { useForm } from "react-hook-form"
import Statics from "objects/common/Statics"
import { useNavigate } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import { useAlert, useCheckDirtyFields } from "hooks"
import { ChangesContext } from "contexts/ChangesContext"
import { useContext, useEffect, useRef, useState } from "react"
import { validationSchema } from 'components/forms/display/datos-colegio/DataForm/validation.js'
import { EmpleadoListContext } from 'contexts/employee/ListContext'

/**
 * Hook personalizado para manejar la lógica de creación de datos del colegio de un alumno.
 * Integra react-hook-form para el manejo del formulario y yup para la validación del esquema.
 * Controla el estado del componente, la navegación, y la interacción con el contexto de cambios.
 * 
 * @returns {Object}
 */
export default function useAlumnoDatosColegioCreateLogic({ requests }) {
    const handleError = useErrorHandler()
    
    const updateAlert = useAlert()

    const { register, handleSubmit, formState: { errors, dirtyFields }, setValue, getValues } = useForm({ resolver: yupResolver(validationSchema) })

    const navigate = useNavigate()

    const headerRef = useRef()
    const alumnoSectionRef = useRef()

    const [isMounted, setIsMounted] = useState(false)

    const { updateChanges } = useContext(ChangesContext)
    const { addItem } = useContext(EmpleadoListContext)

    useEffect(() => setIsMounted(true), [])

    useCheckDirtyFields(dirtyFields, updateChanges)

    const onValid = () => {
        if (alumnoSectionRef.current.isValid())
            createDatosColegio()
    }

    const onError = () => {
        alumnoSectionRef.current.isValid()
    }

    const { checkEdad } = useDatosColegioAgeCheck({ alumnoEdad: alumnoSectionRef?.current?.getAlumno()?.edad })

    /**
     * Función para enviar los datos del formulario a la API y crear los datos del colegio del alumno.
     * Muestra notificaciones según el resultado de la operación y redirige en caso de éxito.
     */
    const createDatosColegio = () => {
        headerRef.current.button.setLoading(true)

        requests.createDatosColegio(getValues, alumnoSectionRef.current.getAlumno().id, async (res) => {
            const { id } = res.item
            updateAlert('Se han creado los datos del colegio del alumno correctamente. Redirigiendo a la pagina de visualización...', 'success')
            updateChanges(false)
            addItem(res?.item)
            // Aqui comprobariamos la edad
            checkEdad(res.item)
            await Statics.delay(1500).then(() => { navigate('./../' + id) }) //Redirección al single de dato de colegio
        }, (err) => {
            headerRef.current.button.setLoading(false)
            handleError(err)
        })
    }

    return { setValue, isMounted, errors, updateChanges, headerRef, alumnoSectionRef, register, handleSubmit, onError, onValid }
}