import React, { useEffect, useRef, useState } from 'react'
import { Text } from 'components/texts'
import { enqueueSnackbar } from 'notistack'
import { RequestVentaLibro } from 'objects/requests/index.js'
import { useErrorHandler, useAlert } from 'hooks'

/**
 * Hook `useLogic` que encapsula la lógica para generar pedidos de libros.
 * Utiliza `useRef` para mantener una referencia al componente de cabecera y `useState` para controlar el montaje del componente.
 * 
 * @param {Function} getValues - Función del hook `useForm` para obtener los valores de los campos del formulario.
 * @returns {Object} - Objeto que contiene la función `generarPedidos` para iniciar el proceso de generación de pedidos.
 */
export default function useLogic(getValues) {
    const handleError = useErrorHandler()

    const updateAlert = useAlert()

    const headerRef = useRef()

    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        setIsMounted(true)
    }, [])

    /**
     * Función `generarPedidos` que maneja la generación de pedidos de libros.
     * Actualiza la UI para mostrar el estado de carga, realiza la solicitud para generar los pedidos,
     * y maneja las respuestas de éxito y error actualizando la UI acordemente.
     */
    const generarPedidos = () => {
        headerRef.current.button.setLoading(true)
        enqueueSnackbar(<Text>Generando pedidos...</Text>, { variant: "info" })

        new RequestVentaLibro().generarPedidos(getValues('id_curso_escolar'), getValues('id_libro'),
            (res) => {
                //TODO: sustituir todos los enqueueSnackbar por el hook useAlert
                updateAlert('Pedidos generados correctamente. Se va a proceder a su descarga.', 'success')
                headerRef.current.button.setLoading(false)
            },
            (err) => handleError(err))
    }

    return { headerRef, isMounted, generarPedidos }
}