import React from 'react'
import { Grid } from '@mui/material'
import { Checkbox, Input } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Formulario con los datos de un libro
 */
export default function DataFormLibro({ libro, register, errors }) {

    return (
        <Grid container px={5}>

            <Grid container spacing={2} mt={2}>
                <Grid item xs={6}>
                    <ItemsSelect
                        name={"id_editorial"}
                        keyValue={"editorial"}
                        register={register}
                        errors={errors}
                        defaultValue={libro?.id_editorial ?? ""} />
                </Grid>

                <Grid item xs={6}>
                    <Input label="ISBN" register={register} errors={errors} className="w-100" name={"isbn"} defaultValue={libro?.isbn ?? ""} uppercase />
                </Grid>

                <Grid item xs={12}>
                    <Input label="Nombre del libro" register={register} errors={errors} className="w-100" name={"nombre"} defaultValue={libro?.nombre ?? ""} uppercase />
                </Grid>

                <Grid item xs={8}>
                    <Input label="Nombre abreviado" register={register} errors={errors} className="w-100" name={"abreviatura"} defaultValue={libro?.abreviatura ?? ""} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <Checkbox name={"activo"} label={"Libro activo"} register={register} defaultChecked={libro?.activo ? true : false} />
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect label={"Libro asociado"} name={"id_libro"} keyValue={"libro"} register={register} errors={errors} defaultValue={libro?.id_libro ?? ""} searcheable />
                </Grid>

                <Grid item xs={3}>
                    <Input register={register} errors={errors} label={"Precio editorial"} name={"precio_editorial"} className="w-100" defaultValue={libro?.precio_editorial ?? ""} type={"number"} />
                </Grid>

                <Grid item xs={3}>
                    <Input register={register} errors={errors} label={"PVP"} name={"pvp"} className="w-100" defaultValue={libro?.pvp ?? ""} type={"number"} />
                </Grid>
            </Grid>
        </Grid>
    )
}
