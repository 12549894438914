import { Grid, Stack } from "@mui/material";
import { Text } from "components/texts";
import { Line } from "components/data_display";
import Skeleton from './skeleton'

/**
 * Pagina con los datos de las anotaciones de la clase particular
 */
export default function ClaseParticularAnotaciones({ claseParticular }) {
    return (
        <Grid container p={5}>
            {claseParticular ?
                <>
                    <Grid item xs={12}>
                        <Stack>
                            <Text fontWeight={"bold"}>Comentarios</Text>
                            <Text>{claseParticular.comentarios ?? "Sin rellenar"}</Text>
                        </Stack>
                    </Grid>

                    <Grid item xs={12} pt={5}>
                        <Line />
                    </Grid>

                    <Grid item xs={12} pt={5}>
                        <Stack>
                            <Text fontWeight={"bold"}>Cancelaciones y recuperaciones</Text>
                            <Text>{claseParticular.cancelaciones ?? "Sin rellenar"}</Text>
                        </Stack>
                    </Grid>

                </>
                : <Skeleton />}
        </Grid>
    )
}