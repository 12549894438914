import { Modal } from "components/modals"
import { Container, Grid, Paper } from '@mui/material'
import { useEffect, useRef } from "react"
import { AlumnoFullItem } from 'components/data_display/users'
import { DataFormIncidencia } from 'components/forms/display'
import { responses } from 'constants/Responses'
import { useForm } from "react-hook-form"
import { validationSchema } from "components/forms/display/incidencia/DataForm/validation"
import { yupResolver } from "@hookform/resolvers/yup"
import Requests from '../../../../../../pages/logged/admin/alumnos/incidencia/create/Requests/Requests'
import { enqueueSnackbar } from "notistack"
import { Text } from "components/texts"

/**
 * Componente para lanzar un modal para manejar incidencias en diferentes partes de la aplicación, como al editar una inscripcion de academia, etc
 * 
 * @returns Component
 */
export default function CreateIncidenciaModal({ open, setOpen, alumno, isMounted, onSaveFinish }) {
    const modalRef = useRef()

    const { register, setValue, getValues, handleSubmit, reset: resetForm } = useForm({ resolver: yupResolver(validationSchema) })

    useEffect(() => {
        if (open) {
            //Resetear el formulario de creacion de incidencia
            resetForm()

            modalRef.current.open()
            setOpen(false)
        }
    }, [open])

    const createIncidencia = () => {
        new Requests().createIncidencia(getValues, alumno.id, (res) => {

            onSaveFinish()
            modalRef.current.close()
            enqueueSnackbar(<Text>Se ha creado la incidencia correctamente.</Text>, { variant: "success" })

        }, (res) => enqueueSnackbar(<Text>{res}</Text>, { variant: "error" })
        )
    }

    return (
        <Modal ref={modalRef} title="Incidencias">
            <Grid sx={{ mx: 2, my: 2, pb: 2 }}>
                {alumno ? <AlumnoFullItem alumno={alumno} containerClassName="m-5" /> : null}
            </Grid>

            <Grid sx={{ mx: 2, my: 2, pb: 2 }}>
                <DataFormIncidencia
                    isMounted={isMounted}
                    register={register}
                    setValue={setValue}
                    handleSubmit={handleSubmit}
                    onSubmit={createIncidencia} />
            </Grid>
        </Modal>
    )
}