import React, { Component } from 'react'
import { Header, Main, Footer } from './parts'

export default class AdminSidebar extends Component {



  /**
   * Render
   * 
   * @returns {Component}
   */
  render() {
    return (
      <div 
        id="kt_app_sidebar"
        className="app-sidebar flex-column" data-kt-app-layout="dark-sidebar" data-kt-drawer="true" data-kt-drawer-name="app-sidebar" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="225px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle">
        <Header />

        <Main sidebar={this.props.sidebar}/>

        <Footer />
      </div>
    )
  }
}
