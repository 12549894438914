import { Text } from "components/texts"
import { enqueueSnackbar } from "notistack"
import { RequestPagoInscripcion } from "objects/requests"
import { useState } from "react"

export default function useReciboLogic(getValues, inscripcion) {
    const [recibosContent, setRecibosContent] = useState([])

    const handleRecibo = () => {
        // Si no se ha rellenado ninguna cantidad en la tabla de pagos, muestra un aviso
        if (recibosContent.length === 0) {
            enqueueSnackbar(<Text>No hay ningún pago hecho para imprimir su recibo.</Text>, { variant: "info" })
            return
        }

        const queryParams = new URLSearchParams()

        recibosContent.forEach((pago, index) => {
            queryParams.append(`pagos[${index}][mes]`, pago.mes)
            queryParams.append(`pagos[${index}][total]`, pago.total)
        })

        queryParams.append(`alumno`, inscripcion.alumno.nombre + " " + inscripcion.alumno.apellido1 + " " + inscripcion.alumno.apellido2)

        new RequestPagoInscripcion().recibo(queryParams.toString(), (err) => console.log('err', err))
    }

    // Si la forma de pago es Efectivo (con ID 1), se renderiza el botón de Recibo. Si es cualquier otra forma de pago, queda deshabilitado
    const isReciboDisabled = getValues('forma_pago_id') !== 1

    return { setRecibosContent, isReciboDisabled, handleRecibo }
}