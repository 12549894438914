import React, { useState, useEffect, useRef } from 'react'
import { RequestFormaPagoInscripcion } from 'objects/requests'
import { Box, Chip, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { v4 as uuid } from 'uuid';
import { COLOR } from 'theme/Colors'

/**
 * Componente Select personalizado para el campo "Forma de pago" de la sección de inscripciones del módulo Academia
 * 
 * @param {Object} props propiedades para el select personalizado
 * @returns Select - Elemeto Select de Mui UI personalizado para las Formas de pago en inscripcion
 */
export default function SelectFormaPago({ register,
    defaultValue,
    className,
    name,
    errors,
    label,
    width,
    disabled,
    multiple = false,
    type = "standard",
    alumno }) {

    /**
     * Referencia del Select
     */
    const selectRef = useRef()

    /**
     * Estado para almacenar los items del select
     */
    const [items, setItems] = useState([])
    /**
     * Estado usado para almacenar el valor que tiene el select
     */
    const [value, setValue] = useState(defaultValue)

    /**
     * Obtiene y establece las opciones del select comprobando si tiene o no domiciliación bancaria
     */
    useEffect(() => {
        getFormasPago()
        // Se actualiza cada vez que cambia el id de alumno
    }, [alumno?.id])

    /**
     * Función de callback usada para establecer las opciones del select en el estado valdiando si estan deshabilitadas o no
     * 
     * @param {Array} res - devuelve una lista de objetos. Las distintas formas de pago de las inscripciones de la academia
     */
    const callback = (res) => {
        const formasDePago = res.map((formaPago) => {
            return {
                value: formaPago.id,
                label: formaPago.valor,
                disabled: !alumno?.domiciliacion_bancaria?.length > 0 && formaPago.id == 2
            }
        })
        setItems(formasDePago)
    }

    /**
     * Función específica que obtiene las opciones del select de la bd y las setea en el estado
     */
    const getFormasPago = () => new RequestFormaPagoInscripcion().all({}, callback)

    /**
     * Devuelve las propiedades propias del Select
     * @returns JSON con las propiedades propias del Select
     */
    const getTypeProps = () => {
        const typeProps = {}
        if (type == "chip" && multiple) {
            typeProps.renderValue = (selected) => {
                return (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => {
                            const item = items.find(item => item.value == value)
                            return <Chip key={value} label={item.label} />
                        })}
                    </Box>
                )
            }
        }
        return typeProps
    }

    /**
     * Label/Nombre único de la etiqueta del Select
     */
    const labelId = name + "_select-id-" + uuid()

    /**
     * Obtiene las props del Select
     */
    const typeProps = getTypeProps()

    /**
     * Función para la funcionalidadde cambiar el select
     * @param {Event} event evento que produce el select
     */
    const onChange = (event) => setValue(event.target.value)

    /**
     * Obtiene el valor renderizado comprobando si tiene domiciliacion bancaria y es domiciliado o no.
     * Comprueba si el valor del select coincide con el valor de la opcion "Domiciliado" y si tiene o no Domiciliación Bancaria.
     * Si no tiene domiciliación bancaria y el value es 2 (Domiciliado), se establece el valor del select a 1 (Efectivo), 
     * si no se establece el valor de la opcion "Domiciliado" 
     *
     * {1 es efectivo, 2 es domiciliacion bancaria}
     */
    const renderedValue = value == 2 && !alumno?.domiciliacion_bancaria?.length > 0 ? 1 : value

    return (
        <FormControl variant={'outlined'} sx={{ m: 1, width: width ?? "100%", margin: 0 }} size={"small"} className={className}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select ref={selectRef}
                labelId={labelId}
                label={label}
                onChange={onChange}
                className='custom-select'
                disabled={disabled}
                multiple={multiple ?? false}
                value={renderedValue}
                name={name}
                inputProps={{ ...register ? register(name) : {} }}
                error={errors && errors[name] ? true : false}
                {...typeProps}>
                {items?.map(item => <MenuItem key={uuid()} disabled={item.disabled} value={item.value}>{item.label ?? ""}</MenuItem>)}
            </Select>
            {errors && errors[name]?.message ? <FormHelperText style={{ color: COLOR.error.main }}>{errors[name]?.message}</FormHelperText> : null}
        </FormControl>
    )
}