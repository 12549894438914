import { Grid } from '@mui/material'
import React, { Component } from 'react'
import Skeleton from '@mui/material/Skeleton'

/**
 * SKELETON
 */
export default class SkeletonActividadesAcademia extends Component {

    render() {
        return (
            <Grid container mt={2} px={4} spacing={2}>
                <Grid item xs={4}>
                    <Skeleton variant="rounded" width={"100%"} height={30} className={"my-2"} />
                </Grid>
                <Grid item xs={6}></Grid>

                <Grid item xs={4}>
                    <Skeleton variant="rounded" width={"100%"} height={30} className={"my-2"} />
                </Grid>

                <Grid item xs={4}>
                    <Skeleton variant="rounded" width={"100%"} height={30} className={"my-2"} />
                </Grid>

                <Grid item xs={4}>
                    <Skeleton variant="rounded" width={"100%"} height={30} className={"my-2"} />
                </Grid>

                <Grid item xs={3}>
                    <Skeleton variant="rounded" width={"100%"} height={30} className={"my-2"} />
                </Grid>

                <Grid item xs={3}>
                    <Skeleton variant="rounded" width={"100%"} height={30} className={"my-2"} />
                </Grid>

                <Grid item xs={3}>
                    <Skeleton variant="rounded" width={"100%"} height={30} className={"my-2"} />
                </Grid>

                <Grid item xs={3}>
                    <Skeleton variant="rounded" width={"100%"} height={30} className={"my-2"} />
                </Grid>
            </Grid>
        )
    }
}
