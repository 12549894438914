const { default: Statics } = require("objects/common/Statics")

/**
 * Hook personalizado `useLibrosDevueltosLogic` para filtrar los libros devueltos por curso escolar y calcular el total de unidades devueltas.
 * 
 * @param {Array} librosDevueltos - Array que contiene la información de los libros devueltos.
 * @param {String} cursoEscolar - El curso escolar seleccionado para filtrar los libros devueltos.
 * @returns {Object} Objeto que contiene los libros filtrados por curso escolar y la suma total de unidades devueltas.
 */
export default function useLibrosDevueltosLogic(librosDevueltos, cursoEscolar) {
    const librosToRender = cursoEscolar ? librosDevueltos.filter(item => item.id_curso_escolar == cursoEscolar && item.deleted != true) ?? [] : []
    const sumDevueltas = Statics.sumParamsOfObjectArray(librosToRender, "cantidad_devuelta")

    return { librosToRender, sumDevueltas }
}