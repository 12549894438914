import { useEffect } from 'react'

/**
 * //TODO: seria interesante hacerlo dinamico, para poder usarlo en cualquier parte de la aplicación
 * 
 * Hook personalizado para resetear el formulario de creación rápida de responsables.
 * Se ejecuta automáticamente al montar el componente para asegurar que el formulario
 * inicie con los valores por defecto.
 * 
 * @param {function} reset - Función de react-hook-form para resetear los valores del formulario.
 */
export default function useResetForm(reset) {
    useEffect(() => {
        reset({
            responsable_nombre: '',
            responsable_apellido1: '',
            responsable_apellido2: '',
            responsable_email: '',
            responsable_tipo_documento_id: '',
            responsable_numero_documento: '',
            responsable_tipo_relacion_id: null,
            responsable_profesion: '',
            responsable_telefono_movil: '',
            responsable_telefono_trabajo: '',
            responsable_telefono_otros: ''
        })
    }, [])
}