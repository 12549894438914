import React, { useState, useEffect } from 'react'
import { Grid, Box, Container, Tab, Tabs } from "@mui/material";
import { Text } from 'components/texts/index.js'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import {
    DetallesPagos,
    PagosData,
    Anticipos
} from './parts';
import { COLOR } from 'theme/Colors.js'
import { TabContext } from '@mui/lab'


export default function PagosDataForm({ empleado, register, errors, setValue, getValues, pagos }) {
    const [tab, setTab] = useState("1")
    const [detallesData, setDetallesData] = useState([]);
    const [anticiposData, setAnticiposData] = useState([]);

    useEffect(() => {
        if (pagos && pagos.length > 0) {
            const nuevosDetalles = extraerDetalles(pagos);
            const nuevosAnticipos = extraerAnticipos(pagos);

            setDetallesData(nuevosDetalles);
            setAnticiposData(nuevosAnticipos);
        }
    }, [pagos]);

    const extraerDetalles = (pagos) => {
        if (!pagos || pagos.length === 0) {
            return [];
        }
        return pagos.map(pago => pago.detalles).filter(detalle => detalle !== null);
    };

    const extraerAnticipos = (pagos) => {
        if (!pagos || pagos.length === 0) {
            return [];
        }
        return pagos.flatMap(pago => pago.anticipos || []); // Añade '|| []' para manejar pagos sin anticipos
    };

    return (
        <Grid container p={3}>
            <Container maxWidth={false} sx={{ mt: 2 }}>

                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
                        <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                            <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>PAGOS</Text>} value={"1"} />
                            <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>DETALLES DE PAGOS</Text>} value={"2"} />
                            <Tab label={<Text fontWeight={tab === "3" ? "bold" : null} color={tab === "3" ? COLOR.standard.main : null}>ANTICIPOS</Text>} value={"3"} />
                        </Tabs>
                    </Box>

                    <TabPanel value={"1"}>
                        <PagosData pagos={pagos} />
                    </TabPanel>

                    <TabPanel value={"2"}>
                        <DetallesPagos pagos={detallesData} />
                    </TabPanel>

                    <TabPanel value={"3"}>
                        <Anticipos pagos={anticiposData} />
                    </TabPanel>

                </TabContext>
            </Container>
        </Grid>
    )
}

