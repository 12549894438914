import { Delete, Edit, GroupAdd } from '@mui/icons-material'
import { Avatar, Box, Grid, Stack } from '@mui/material'
import React, { Component } from 'react'
import { v4 as uuid } from 'uuid'
import { Line, PopOver } from 'components/data_display'
import { H3, Text } from 'components/texts'
import { ASSETS } from 'constants/Assets'
import Requests from '../../Requests'
import TooltipTemplate from './tooltipTemplate'
import { enqueueSnackbar } from 'notistack'

/**
 * Plantilla para mostrar la información del evento
 */
export default class Template extends Component {

    constructor(props) {
        super(props)

        this.state = {
            evento: this.props.evento
        }
    }


    componentDidUpdate(prevProps) {
        if (prevProps.evento != this.props.evento)
            this.setState({ evento: this.props.evento })
    }


    /**
     * Callback para cuando se borra una relacion de un empleadop a un evento
     * 
     * @param {Object} item
     */
    empleadoEventoDeleted = (item) => {
        const { evento } = this.state
        const indexDeleted = evento.compartidos.indexOf(item)
        if (indexDeleted > -1)
            evento.compartidos.splice(indexDeleted, 1)

        this.setState({ evento })
    }


    /**
     * Hace el callback para cuando se comparte un evento
     * 
     * @param {Object} empleado 
     */
    onShare = (empleado) => {
        const { evento } = this.state
        evento.compartidos.push(empleado)
        this.setState({ evento }, () => {
            this.popover.open(null, false)
        })
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        const { evento } = this.state
        return (
            <Grid container p={5} spacing={2}>
                <Grid item xs={8}>
                    <H3>{evento?.title ?? ""}</H3>
                    <Stack direction={"row"} spacing={4}>
                        <Stack direction={"row"} spacing={1}>
                            <Text fontWeight={"bold"}>Fecha: </Text>
                            <Text>{evento.date.split('-').reverse().join('/')}</Text>
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                            <Text fontWeight={"bold"}>Hora inicio:</Text>
                            <Text>{evento?.hour ? (evento.hour.split(":")[0] + ":" + evento.hour.split(":")[1]) : "Sin especificar"}</Text>
                        </Stack>

                        <Stack direction={"row"} spacing={1}>
                            <Text fontWeight={"bold"}>Hora fin:</Text>
                            <Text>{evento?.end_hour ? (evento.end_hour.split(":")[0] + ":" + evento.end_hour.split(":")[1]) : "Sin especificar"}</Text>
                        </Stack>
                    </Stack>
                    {evento?.description ? <Text>{evento.description}</Text> : null}
                </Grid>

                <Grid item xs={4} display={"flex"} justifyContent={"end"} alignItems={"start"}>
                    {this.props.canEdit ?
                        <Stack spacing={2} direction={"row"}>
                            <Box sx={{ position: 'relative' }}>
                                <div className="circular-grey-button" onClick={(e) => { this.popover.open(e) }}>
                                    <GroupAdd color={"standard"} />
                                </div>
                                <PopOver ref={(ref) => this.popover = ref} content={<TooltipTemplate evento={evento} onShare={this.onShare} />} />
                            </Box>

                            <div className="circular-grey-button" onClick={this.props.onEditClick}>
                                <Edit color={"standard"} />
                            </div>
                            <div className="circular-grey-button">
                                <Delete color={"grey"} onClick={this.props.onDeleteClick} />
                            </div>

                        </Stack>
                        : null}
                </Grid>

                <Grid item xs={2} display={"flex"} justifyContent={"center"}>
                    <Avatar
                        alt={"Foto de: " + (evento?.empleado?.nombre ?? "")}
                        src={`${ASSETS.images + (evento?.empleado?.foto_logo ? evento?.empleado?.foto_logo : 'private/empleado/perfiles/0/default.png')}`}
                        sx={{ width: "80%", height: "100%", borderRadius: 2 }} />
                </Grid>
                <Grid item xs={7} display={"flex"} alignItems={"center"}>
                    <Stack>
                        <Text fontWeight={"bold"}>{"Evento creado por: "}</Text>
                        <H3>{evento?.empleado?.nombre + " " + (evento?.empleado?.apellido1 ?? "") + " " + (evento?.empleado?.apellido2 ?? "")}</H3>
                        <Box display={"flex"} mt={2}>
                            <Text fontWeight={"bold"}>Email:</Text>
                            <Text className="mx-2">{evento?.empleado?.email ?? ""}</Text>
                        </Box>
                    </Stack>
                </Grid>


                {evento?.compartidos.length > 0 ?
                    (
                        <>
                            <Grid item xs={12} my={2}>
                                <Line />
                            </Grid>

                            <Grid container px={2}>
                                <Grid item xs={12} my={2}>
                                    <H3>Invitados:</H3>
                                </Grid>

                                {evento.compartidos.map(item => <EmpleadoItem key={uuid()} item={item} onDelete={this.empleadoEventoDeleted} canDelete={this.props.canEdit} />)}
                            </Grid>
                        </>
                    ) : null}
            </Grid>
        )
    }
}


class EmpleadoItem extends Component {

    /**
     * Hace el delete de la relación de un invitado a un evento de la agenda
     */
    onDelete = () => {
        new Requests().deleteEventoEmpleado(this.props.item.pivot.id, () => {
            this.props.onDelete(this.props.item)
            enqueueSnackbar(<Text>Se ha borrado al invitado correctamente</Text>, { variant: "success" })
        }, (res) => {
            enqueueSnackbar(<Text>{res}</Text>, { variant: "error" })
        })
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        const { item } = this.props
        return (
            <Grid item xs={6} xl={4}>
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <Avatar
                        alt={"Foto de: " + (item.nombre ?? "")}
                        src={`${ASSETS.images + (item.foto_logo ?? 'private/empleado/perfiles/0/default.png')}`}
                        sx={{ width: 60, height: 60, borderRadius: "100%" }} />

                    <Stack>
                        <Text>{item.nombre + " " + (item.apellido1 ?? "")}</Text>
                        <Text>{item.email ?? ""}</Text>
                    </Stack>

                    {this.props.canDelete ?
                        <div className="circular-grey-button" onClick={this.onDelete}>
                            <Delete color={"grey"} />
                        </div>
                        : null}
                </Stack>
            </Grid>
        )
    }
}