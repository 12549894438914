import { H3 } from 'components/texts'
import { columns } from './columns.js'
import { Box, Container, Paper } from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'
import React from 'react'
import { ListTableHeader } from 'pages/logged/admin/components'
import { Accordion, BreadcrumbsList, Datagrid } from 'components/data_display'
import SearchFormLibro, { search } from 'components/forms/display/libro/libro/SearchForm'
import { useListLogic } from 'hooks/index.js'

/**
 * Página de Listado de Libros.
 * Muestra una tabla de libros y permite realizar búsquedas y paginación.
 */
export default function ListadoLibros() {
  const { updateTable, loadingTable, setLoadingTable, params, data } = useListLogic()

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <BreadcrumbsList breadcrumbsList={[
        { to: "/", label: "Inicio" },
        { to: "./", label: "Libros", actualItem: true },
      ]} />

      <Accordion header={<Box display={"flex"} alignItems={"center"}><SearchIcon className={"mx-5"} /> <H3 className="my-0">Buscar libros</H3></Box>}>
        <SearchFormLibro
          searchCallback={updateTable}
          onLoadingCallback={setLoadingTable} />
      </Accordion>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
        <ListTableHeader rowCount={data.rowCount} params={params} keyValue={"libros"} />

        <Datagrid
          rows={data.data}
          columns={columns}
          loading={loadingTable}
          rowCount={data.rowCount}
          onPageChange={(page) => { search(page, () => setLoadingTable(true), updateTable) }}
          serverSide />
      </Paper>
    </Container>
  )
}