import React, { useState } from 'react'
import { Grid, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@mui/material'
import { CursoEscolarSelect, ItemsSelect } from 'components/forms/components/Select/custom'
import { H3 } from 'components/texts'

const destinatario = "academia"

export default function AcademiaTemplate({ register, errors, reset, setValue }) {
  const [opcionSeleccionada, setOpcionSeleccionada] = useState('adulto');

  return (
    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={4}>

      <Grid item xs={3}>
        <CursoEscolarSelect name={`${destinatario}.curso_escolar_id`} register={register} errors={errors} wantToReset={reset} defaultValue={null} setValue={setValue} searcheable allOption/>
      </Grid>

      <Grid item xs={3}>
        <ItemsSelect name={`${destinatario}.tipo_actividad_id`} keyValue={"tipo_actividad"} register={register}
          allOption errors={errors} />
      </Grid>

      <Grid item xs={3}>

        <ItemsSelect name={`${destinatario}.actividad_id`} keyValue={"actividad"} register={register} allOption
          errors={errors} wantToReset={reset} searcheable />
      </Grid>

      <Grid item xs={3}>
        <ItemsSelect name={`${destinatario}.modalidad_curso_id`} keyValue={"modalidad_cursos"} register={register} allOption
          errors={errors} wantToReset={reset} searcheable />
      </Grid>

      <Grid item xs={2} mt={4}>
        <FormControl>
          <FormLabel >
            <H3>Nivel de Grupo</H3>
          </FormLabel>
          <RadioGroup onChange={({ target }) => setOpcionSeleccionada(target.value)} value={opcionSeleccionada}
            row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
            <FormControlLabel value="niño" control={<Radio />} label="Niños" />
            <FormControlLabel value="adulto" control={<Radio />} label="Adultos" />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={4} mt={{ xs: 8 }}>
        <ItemsSelect
          allOption
          key={opcionSeleccionada}
          register={register}
          errors={errors}
          name={`${destinatario}.nivel_grupo_id`}
          keyValue={opcionSeleccionada === 'adulto' ? "nivel_grupo_adulto" : "nivel_grupo_niño"} />
      </Grid>
    </Grid>
  )
}
