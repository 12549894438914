import React, { useState, useEffect, useRef } from "react";
import { Grid, Box, Tab, Tabs, Stack } from "@mui/material";
import { Button, Input, DatePicker, Checkbox } from 'components/forms/components';
import { COLOR } from 'theme/Colors.js'
import { H3, Text } from 'components/texts/index.js'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { TabContext } from '@mui/lab'
import { DataCurriculumTable } from "components/data_display";
import ActividadesCurriculum from "./parts/ActividadesCurriculum";
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import Requests from "./Requests/Requests.js";
import AttachFile from '@mui/icons-material/AttachEmail';
import { RequestFile } from 'objects/requests';
// import useMediaQuery from "hooks/utils/useMediaQuery"
import { useMediaQuery } from "hooks"

/**
 * Formulario con los datos del currículum del candidato
 * 
 */

const FORMATS = {
    '.txt': 'text/plain',
    '.pdf': 'application/pdf',
}
export default function DataCurriculumEmpleado({ getValues, register, errors, empleado, setValue }) {
    const { componentSizes: { buttonSize } } = useMediaQuery()
    const inputC = useRef(null)
    const inputFileC = useRef(null)
    const accept = ".txt,.pdf,.docx,.doc"

    const [fileToSubmit, setFileToSubmit] = useState(null); // Almacenar el archivo a enviar
    const [tab, setTab] = useState("1")
    const [isFormDisabled, setIsFormDisabled] = useState(!empleado?.curriculum?.entrega_curriculum)

    const showFile = (field) => {
        const file = getValues(field)[0]
        if (file) {
            const tmppath = URL.createObjectURL(file)
            window.open(tmppath, '_blank')
        } else if (empleado[field]) {
            const array = empleado[field].split('.')
            const type = array[array.length - 1]
            new RequestFile().file(empleado[field], FORMATS["." + type], {}, (res) => {
                console.error("response", res)
            })
        }
    }

    const updateInput = (value, ref) => {
        const array = value.split("\\")
        const newValue = array[array.length - 1]
        ref.setValue(newValue)
    }

    useEffect(() => {
        if (!fileToSubmit) {
            if (empleado?.curriculum && empleado?.curriculum?.archivos && empleado?.curriculum?.archivos[0]) {
                const archivo = empleado?.curriculum?.archivos[0];
                setFileToSubmit(archivo);
            }
        }
    }, [empleado?.curriculum, fileToSubmit]);

    const entregaCurriculum = empleado?.curriculum?.entrega_curriculum
    return (
        <Grid container p={3} direction={'row'}>

            <Grid item mb={2} p={2} xs={12}>

                <Grid container spacing={2} alignItems="center">

                    <Grid item xs={10} >
                        <H3>Datos Entrega</H3>
                    </Grid>

                    <Grid item xs={12} mt={2}>
                        <Checkbox
                            name={"entrega_curriculum"}
                            label={"Entrega del curriculum"}
                            register={register}
                            className="mt-2"
                            defaultChecked={entregaCurriculum ? true : false}
                            onChange={e => setIsFormDisabled(!e.target.checked)} />
                    </Grid>

                    <Grid item xs={4} >
                        <Input className="w-80" label="Nº Curriculum" name="codigo_curriculum" disabled defaultValue={empleado?.curriculum?.codigo_curriculum ?? 0} />
                    </Grid>

                    <Grid item xs={2} >
                        <DatePicker
                            name={"fecha_entrada"}
                            label="Fecha"
                            defaultValue={empleado?.curriculum?.fecha_entrada ?? ""}
                            clearable={true}
                            disabled={isFormDisabled}
                            register={register}
                            errors={errors}
                            setValue={setValue} />
                    </Grid>

                    <Grid item xs={3} >
                        <Stack>
                            <Input ref={inputC} className="w-100" disabled useValue label={'Adjuntar Archivo'} defaultValue={empleado && empleado.archivos && empleado.archivos[0] ? empleado.archivos[0].path : (empleado?.curriculum && empleado?.curriculum?.archivos && empleado?.curriculum?.archivos[0] ? empleado?.curriculum?.archivos[0].path : '')}
                            />
                            <Input ref={inputFileC} className="w-100" type={"file"} register={register} name={"archivo"} style={{ display: "none" }}
                                inputProps={{ onChange: ({ target }) => updateInput(target.value, inputC.current), accept }} />
                        </Stack>
                    </Grid>

                    <Grid item xs={3} display={"flex"} alignItems={"end"}>
                        <Stack direction={"row"} spacing={2} className={"w-100"}>
                            <Button className={"w-100 py-3"} onClick={() => { inputFileC.current.input.click() }} size={'small'} >
                                <AttachFile style={{ marginLeft: '-8px' }} />
                            </Button>
                            <Button className={"w-100 py-3"} color={"cancel"} onClick={() => showFile("archivo")}>
                                Ver archivo
                            </Button>
                        </Stack>
                    </Grid>

                </Grid>
            </Grid>

            <Grid item mt={2} xs={12}>
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
                        <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                            <Tab label={<Text fontWeight={tab === "1" ? "normal" : null} color={tab === "1" ? COLOR.standard.main : null}>Datos Actividades</Text>} value={"1"} />
                            <Tab label={<Text fontWeight={tab === "2" ? "normal" : null} color={tab === "2" ? COLOR.standard.main : null}>Datos Currículum y Archivo</Text>} value={"2"} />
                        </Tabs>
                    </Box>

                    <TabPanel value={"1"}>
                        <ActividadesCurriculum isFormDisabled={isFormDisabled} empleado={empleado} register={register} errors={errors} setValue={setValue} getValues={getValues} />
                    </TabPanel>

                    <TabPanel value={"2"}>
                        <DataCurriculumTable isFormDisabled={isFormDisabled} curriculum={empleado?.curriculum} register={register} errors={errors} setValue={setValue} getValues={getValues} />
                    </TabPanel>

                </TabContext>

            </Grid>
            <SnackbarProvider autoHideDuration={3000} />
        </Grid>
    )
}
