import { Text } from 'components/texts'
import { useParams } from 'react-router'
import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Container, Paper, Stack } from '@mui/material'
import { EditHeader } from 'pages/logged/admin/components'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { ChangesContext } from 'contexts/ChangesContext/index'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import React, { useRef, useState, useEffect, useContext } from 'react'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'

//FORM
import { DataFormActividadColegio } from 'components/forms/display'
import Skeleton from 'components/forms/display/datos-colegio/DataForm/skeleton.jsx'
import { validationSchema } from 'components/forms/display/actividades-colegio/DataForm/validation.js'

const Wrapper = () => {
  const navigationParams = useParams()

  const { items, updateItem } = useContext(EmpleadoListContext)

  const [actividad, setActividad] = useState(null)
  const [id_actividad, setIdActividad] = useState(navigationParams.id_actividad)

  const updateAlert = (message, variant = "error") => {
    enqueueSnackbar(<Text>{message}</Text>, { variant })
  }

  const getActividad = (getFromServer = false) => {
    const item = items.find(item => item?.id == id_actividad)
    if (items.length == 0 || !item || getFromServer) {
      new Requests().getActividad(id_actividad, (actividad) => {
        setActividad(actividad)
        updateItem(actividad)
      }, () => updateAlert("No se ha podido recuperar la actividad."))
    } else {
      setActividad(item)
    }
  }

  useEffect(getActividad, [id_actividad])

  return (
    <React.Fragment>
      <EditActividadesColegio key={actividad?.id ?? 0} actividad={actividad} id_actividad={id_actividad} setIdActividad={setIdActividad} setActividad={setActividad} updateAlert={updateAlert} onSaveFinish={() => getActividad(true)} />

      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}
export default Wrapper

function EditActividadesColegio({ actividad, id_actividad, setIdActividad, setActividad, updateAlert, onSaveFinish }) {
  const navigate = useNavigate()

  const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) })

  const headerRef = useRef()

  const { updateChanges } = useContext(ChangesContext)

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0)
      updateChanges(true)
  }, [Object.keys(dirtyFields).length])

  const updateHeaderAndAlert = ({ message, severity = "error" }, loading = false) => {
    headerRef.current.button.setLoading(loading)
    updateAlert(message, severity)
  }

  const editActividadColegio = () => {
    headerRef.current.button.setLoading(true)

    new Requests().editActividad(id_actividad, getValues, () => {
      updateHeaderAndAlert({ message: "Se ha editado la actividad correctamente.", severity: "success" })
      reset({}, { keepValues: true })
      updateChanges(false)
      onSaveFinish()
    }, (res) => {
      updateHeaderAndAlert({ message: res })
    })
  }

  const updateId = (id) => {
    navigate(`../colegios/actividades/${id}/editar`)
    setIdActividad(id)
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(editActividadColegio)}>
        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/", label: "Inicio" },
            { to: "./../../../", label: "Colegios" },
            { to: "./../../", label: "Actividades" },
            { to: "./../", label: "Actividad #" + id_actividad },
            { to: "./", label: "Editar", actualItem: true },
          ]} />

          <MoveButtons
            actualItemId={id_actividad}
            beforeFetchCallback={() => setActividad(null)}
            onUpdateActualItem={updateId} />
        </Stack>

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <EditHeader
            ref={headerRef}
            title={"Editar actividad en colegio"}
            item={actividad} />

          <Line />

          {actividad ? <DataFormActividadColegio
            actividad={actividad}
            register={register}
            errors={errors}
            hideButton
            isMounted /> : <Skeleton />}
        </Paper>
      </form>
    </Container>
  )
}