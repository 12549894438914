import { Modal } from "components/modals"
import { useEffect, useState } from "react"
import { Text } from 'components/texts'
import { Edit as EditIcon, Search as SearchIcon } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { Button } from "components/forms/components"
import { COLOR } from "theme/Colors"
import { ChooseListEdit, FastCreateFormResponsableEdit } from "components/forms/display"

const CREATE_BUTTON = 1
const ASOCIATE_BUTTON = 2

/**
 * Componente `AddResponsableModalEdit` gestiona la adición de un tutor, ya sea creando uno nuevo o asociando uno existente.
 * 
 * @param {Object} props - Propiedades recibidas por el componente para gestionar responsables.
 */
export default function AddResponsableModalEdit({ setIsResponsableChosen, register, getValues, errors, reset, addResponsableModalEditRef, open, setOpen, setResponsables }) {
    const [buttonSelected, setButtonSelected] = useState(CREATE_BUTTON)

    /**
     * Actualiza el estado local para indicar el botón seleccionado entre crear un nuevo tutor o asociar uno existente.
     * 
     * @param {number} buttonSelected - Identificador del botón seleccionado.
     */
    const buttonClicked = (buttonSelected) => setButtonSelected(buttonSelected)

    /**
     * Efecto que abre el modal automáticamente cuando la propiedad `open` se establece en `true`.
     */
    useEffect(() => {
        if (open) {
            addResponsableModalEditRef.current.open()
            setOpen(false)
        }
    }, [open])

    return (
        <Modal ref={addResponsableModalEditRef} title={"Añadir un tutor"} onClose={() => console.log('cierro')}>
            <Grid container pb={5} pt={2} px={5}>
                <Grid item xs={12} display={"flex"} justifyContent={"center"} >
                    <Text fontWeight={"bold"}>Puedes crear un nuevo tutor o escoger uno ya existente.</Text>
                </Grid>

                <Grid container my={2}>
                    <Grid item xs={6} display={"flex"} justifyContent={"end"} alignSelf={"end"} pr={1}>
                        <Button
                            startIcon={<EditIcon style={{ color: buttonSelected == CREATE_BUTTON ? COLOR.white.main : COLOR.standard.main }} />}
                            variant={buttonSelected == CREATE_BUTTON ? "contained" : "outlined"}
                            onClick={() => buttonClicked(CREATE_BUTTON)}>
                            Crear nuevo
                        </Button>
                    </Grid>

                    <Grid item xs={6} pl={1}>
                        <Button
                            startIcon={<SearchIcon style={{ color: buttonSelected == ASOCIATE_BUTTON ? COLOR.white.main : COLOR.standard.main }} />}
                            variant={buttonSelected == ASOCIATE_BUTTON ? "contained" : "outlined"}
                            onClick={() => buttonClicked(ASOCIATE_BUTTON)}>
                            Escoger de la lista
                        </Button>
                    </Grid>
                </Grid>

                {buttonSelected == CREATE_BUTTON ?
                    <FastCreateFormResponsableEdit
                        addResponsableModalEditRef={addResponsableModalEditRef}
                        register={register}
                        reset={reset}
                        getValues={getValues}
                        errors={errors}
                        setResponsables={setResponsables} /> :

                    <ChooseListEdit
                        setIsResponsableChosen={setIsResponsableChosen}
                        addResponsableModalEditRef={addResponsableModalEditRef}
                        setResponsables={setResponsables} />}
            </Grid>
        </Modal>
    )
}