import { EmpleadoListContext } from "contexts/employee/ListContext"
import { useAlert } from "hooks"
import { useContext, useEffect, useState } from "react"

/**
 * Hook que encapsula la lógica de obtención del grupo en el Wrapper que envuelve a EditGrupoAcademia
 * 
 * @param {*} navigationParams 
 * @param {*} requests - Objeto con las peticiones necesarias para obtener el grupo del servidor
 * 
 * @returns {Object}
 */
export default function useWrapperLogic(navigationParams, requests) {
    const updateAlert = useAlert()
    const { items, updateItem } = useContext(EmpleadoListContext)
    const [grupo, setGrupo] = useState(null)
    const [id_grupo, setIdGrupo] = useState(navigationParams.id_grupo)
    const [tab, setTab] = useState("1")

    //TODO: Cambiamos el getFromServer para traer los datos actualizados de la base de datos, por si acaso en el single borran una clase
    const getGrupo = (getFromServer = true) => {
        const grupo = items?.find(item => item?.id == id_grupo)
        if (items.length == 0 || !grupo || getFromServer) {
            requests.getGrupo(id_grupo, (grupo) => {
                setGrupo(grupo)
                updateItem(grupo)
            }, () => updateAlert("No se ha podido recuperar el grupo."))
        } else {
            setGrupo(grupo)
        }
    }

    useEffect(getGrupo, [id_grupo])

    return { grupo, tab, setTab, updateAlert, id_grupo, setIdGrupo, getGrupo }
}