import React from 'react'
import { Grid, Box } from '@mui/material'
import { CursoEscolarSelect } from 'components/forms/components/Select/custom'
import { COLOR } from 'theme/Colors';

export default function SimulacrosYExamenesTemplate({ register, errors, reset, setValue }) {

    const fieldsetStyles = {
        position: 'relative',
        border: '1px solid #7F8790 !important',
        borderRadius: '4px',
        padding: '30px',
    };

    const legendStyles = {
        position: 'absolute',
        top: '-28px',
        backgroundColor: '#fff',
        borderRadius: '4px',
        width: 'auto',
        padding: '15px'
    };

    return (
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={4}>

            <Grid item xs={6} mt={4}>
                <Box component="fieldset" display="flex" sx={fieldsetStyles} flexDirection={"row"}>
                    <legend style={legendStyles} >
                        <p style={{ fontWeight: 'bold', fontSize: '14px', color: COLOR.standard.main }}>Simulacros</p>
                    </legend>
                    <Grid container>
                        <Grid item xs={4}>
                            <CursoEscolarSelect name={`id_curso_escolar`} register={register} errors={errors} wantToReset={reset} defaultValue={null} setValue={setValue} searcheable allOption/>
                        </Grid>
                        <Grid item xs={4}>
                            <CursoEscolarSelect name={`id_curso_escolar`} register={register} errors={errors} wantToReset={reset} defaultValue={null} setValue={setValue} searcheable  sx={{ marginLeft: '10px' }} allOption/>
                        </Grid>
                    </Grid>

                </Box>
            </Grid>

            <Grid item xs={6} mt={4}>
                <Box component="fieldset" display="flex" sx={fieldsetStyles} flexDirection={"row"}>
                    <legend style={legendStyles} >
                        <p style={{ fontWeight: 'bold', fontSize: '14px', color: COLOR.standard.main }}>Seminario</p>
                    </legend>
                    <CursoEscolarSelect width={'45%'} name={`id_curso_escolar`} register={register} errors={errors} wantToReset={reset} defaultValue={null} setValue={setValue} searcheable allOption />
                    <CursoEscolarSelect width={'45%'} name={`id_curso_escolar`} register={register} errors={errors} wantToReset={reset} defaultValue={null} setValue={setValue} searcheable allOption />
                </Box>
            </Grid>

        </Grid>
    )
}
