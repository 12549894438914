import { RequestResponsable } from "objects/requests"

export default class Requests {
    /**
     * Realiza una petición HTTP para editar un responsable específico identificado por su ID. Este método
     * actualiza la información de un responsable en el servidor. Para ello, recopila los valores del formulario
     * utilizando la función `getValues`, prepara estos datos en un objeto `FormData` y realiza la petición de edición.
     * 
     * @param {number} id Identificador único del responsable a editar.
     * @param {Function} getValues Función que retorna los valores actuales de los campos de un formulario.
     * @param {Function} callbackOk Función de callback que se ejecuta si la petición se completa exitosamente.
     * @param {Function} callbackError Función de callback que se ejecuta si la petición falla.
     */
    editResponsable(id, getValues, callbackOk = () => { }, callbackError = () => { }) {
        var formData = new FormData()
        formData.append("nombre", getValues("nombre").toUpperCase())
        formData.append("apellido1", getValues("apellido1").toUpperCase())
        formData.append("apellido2", getValues("apellido2").toUpperCase())
        formData.append("email", getValues("email"))
        formData.append("numero_documento", getValues("numero_documento").toUpperCase())
        formData.append("tipo_documento_id", getValues("tipo_documento_id"))
        formData.append("profesion", getValues("profesion").toUpperCase())
        formData.append("telefono_movil", getValues("telefono_movil"))
        formData.append("telefono_trabajo", getValues("telefono_trabajo"))
        formData.append("telefono_otros", getValues("telefono_otros"))
        if (getValues("foto_logo")[0])
            formData.append("foto_logo", getValues("foto_logo")[0])

        new RequestResponsable().edit(id, formData, callbackOk, callbackError)
    }


    /**
     * Recupera la información detallada de un responsable específico por su ID. Este método hace una petición
     * al servidor para obtener los datos completos de un responsable, incluyendo relaciones importantes como
     * `alumno` y `tipo_documento`. Es útil para cargar la información de un responsable, por ejemplo, en una
     * vista de detalle o en un formulario de edición.
     * 
     * @param {number} id Identificador único del responsable cuya información se desea recuperar.
     * @param {Function} callbackOk Función de callback que se ejecuta si la petición se completa exitosamente,
     *                              recibe como parámetro la información del responsable.
     * @param {Function} callbackError Función de callback que se ejecuta si la petición falla.
     */
    getResponsable(id, callbackOk = () => { }, callbackError = () => { }) {
        const params = { relations: 'alumno,tipo_documento' }
        new RequestResponsable().get(id, params, (res) => res.responsable ? callbackOk(res.responsable) : callbackError(res))
    }
}
