import Statics from "objects/common/Statics"
import { useNavigate } from "react-router-dom"
import { RequestAlumnoResponsable, RequestResponsable } from "objects/requests"
import { validateIbanAcount } from "pages/logged/admin/alumnos/alumno/validateIbanAcount"
// import validateIbanAcount from "pages/logged/admin/alumnos/alumno/validateIbanAcount"

export default function useEditAlumno(requestCreate, request, reset, updateChanges, onSaveFinish, headerRef, updateAlert, alumno, responsables, esMenorDeEdad, isResponsableChosen, id_alumno, handleError) {
    const navigate = useNavigate()

    /**
     * Convertimos la arrow function en asincrona para poder usar la función delay de Statics, para que pueda visualizarse 
     * el mensaje de datos guardados y posteriormente modificamos la URL para que se rediriga a single.
     * 
     * @param {String} message - EL mensaje de error a mostrar
     */
    const handleSuccessRequest = async (message) => {
        // updateHeaderAndAlert({ message: "Se ha actualizado el alumno. Se esta redirigiendo...", severity: "success" })
        updateAlert(message, 'success')
        reset({}, { keepValues: true })
        updateChanges(false)
        onSaveFinish()

        await Statics.delay(3000).then(() => { navigate('./../') })
    }

    // const updateHeaderAndAlert = ({ message, severity = "error" }, loading = false) => {
    //     headerRef.current.button.setLoading(loading)
    //     updateAlert(message, severity)
    // }

    const checkFieldsBeforeCreateDomiciliacion = (id_alumno, values) => {
        //Crea la domiciliacion bancaria si hay datos
        if (alumno.domiciliacion_bancaria.length === 0) {
            createDatosBancarios(id_alumno, values)
        } else {
            editDatosBancarios(values)
        }
    }

    /**
     * Hace la petición para editar los datos basicos del alumno
     * 
     * @param {Object} values
     */
    const editAlumno = (values) => {
        // //Obtener los responsables que han sido creados, para descartar los que vienen del servidor
        // const responsablesFiltered = responsables.filter(responsable => responsable.edit)

        // Lanzar notificacion si se quiere crear un alumno menor de edad sin ningun responsable asociado
        if (esMenorDeEdad && !isResponsableChosen && responsables.length === 0) {
            //enqueueSnackbar(<Text>Se debe crear o asociar un responsable a este alumno.</Text>, { variant: "warning" })
            updateAlert('Se debe crear o asociar un responsable a este alumno.', 'warning')
            return
        }

        headerRef.current.button.setLoading(true)

        request.editAlumno(id_alumno, values, async () => {

            //Obtener los responsables que han sido creados, para descartar los que vienen del servidor
            const responsablesFiltered = responsables.filter(responsable => responsable.creating || responsable.selectedFromList)
            console.log('responsablesFiltered', responsablesFiltered)

            // Lanzar notificacion si se quiere crear un alumno menor de edad sin ningun responsable asociado
            if (esMenorDeEdad && responsablesFiltered.length === 0 && responsables.length === 0) {
                //enqueueSnackbar(<Text>Se debe crear o asociar un responsable a este alumno.</Text>, { variant: "warning" })
                updateAlert('Se debe crear o asociar un responsable a este alumno.', 'warning')
                return
            }

            try {
                // 11/04/2024 - Se modifica la logica debido a que ahora se pueden asignar responsables aunque el alumno no sea menor de edad
                if (isResponsableChosen) {
                    //Si se ha seleccionado un responsable de la lista de responsables existentes, crea solo la relacion
                    const createPromises = responsablesFiltered.map(responsableData => createRelation(responsableData, id_alumno))

                    // Esperamos a que todos los responsables se creen
                    const responsablesResults = await Promise.all(createPromises)

                    // Ahora tienes un array de resultados de cada responsable creado
                    console.log('Todos los responsables han sido creados 1', responsablesResults)
                } else {
                    if (responsablesFiltered.length !== 0) {
                        // Inicializar un array para guardar los resultados junto con el tipo_relacion_id
                        const responsablesConRelaciones = []

                        // Crear los responsables y guardar en el array junto con su tipo_relacion_id

                        // Esto se hace debido a que el valor de tipo de relacion se encuentra en el array de responsablesFiltered y no en responsablesResults,
                        // por lo que la llamada a createRelation falla.
                        for (const responsableData of responsablesFiltered) {
                            const createResult = await createResponsable(responsableData)
                            responsablesConRelaciones.push({
                                responsableId: createResult.responsable.id, // Asegúrate de que este es el camino correcto para acceder al ID en tu objeto de respuesta
                                tipoRelacionId: responsableData.tipo_relacion_id
                            })
                        }

                        // Se crean las relaciones después de crear los responsables.
                        for (const { responsableId, tipoRelacionId } of responsablesConRelaciones) {
                            // const idResponsable = resultado.responsable.id
                            await createRelation([], id_alumno, responsableId, tipoRelacionId)
                        }
                    }
                }

                // Comprueba si hay datos bancarios rellenos, para crearlos
                const ibanValidated = validateIbanAcount(values)
                if (!ibanValidated.state) {
                    headerRef.current.button.setLoading(false)
                    updateAlert(ibanValidated.response)
                    return
                }
                checkFieldsBeforeCreateDomiciliacion(id_alumno, values)

                // Si todo ha ido bien, muestra el mensaje de éxito
                handleSuccessRequest('Se ha actualizado el alumno. Redirigiendo...')
            } catch (err) {
                handleError(err)
                headerRef.current.button.setLoading(false)
            }
        }, (err) => {
            headerRef.current.button.setLoading(false)
            handleError(err)
        })
    }

    const createResponsable = async (responsableData) => {
        const formData = new FormData()
        formData.append("nombre", responsableData.nombre.toUpperCase())
        formData.append("apellido1", responsableData.apellido1.toUpperCase())
        formData.append("apellido2", responsableData.apellido2?.toUpperCase() ?? null)
        formData.append("email", responsableData.email)
        formData.append("numero_documento", responsableData.numero_documento.toUpperCase())
        formData.append("tipo_documento_id", responsableData.tipo_documento_id)
        formData.append("profesion", responsableData.profesion.toUpperCase())
        formData.append("telefono_movil", responsableData.telefono_movil)
        formData.append("telefono_trabajo", responsableData.telefono_trabajo)
        formData.append("telefono_otros", responsableData.telefono_otros)

        return new Promise((resolve, reject) => {
            new RequestResponsable().create(formData, resolve, reject)
        })
    }

    // const createDatosBancarios = (id, values) => {
    //     requestCreate.createDatosBancarios(id, values, handleSuccessRequest('Datos bancarios guardados correctamente.'), (err) => {
    //         // updateHeaderAndAlert({ message: res })
    //         handleError(err)
    //     })
    // }

    const createDatosBancarios = (id, values) => {
        requestCreate.createDatosBancarios(id, values, (res) => {
            updateAlert('Datos bancarios guardados correctamente.', 'success')
        }, (err) => {
            handleError(err)
        })
    }

    /**
   * Hace la petición para editar los datos bancarios del alumno
   */
    const editDatosBancarios = (values) => {
        const idDomiciliacionBancaria = alumno.domiciliacion_bancaria[0].id
        request.editDatosBancarios(id_alumno, idDomiciliacionBancaria, values, () => {
            reset({}, { keepValues: true })
            updateChanges(false)
        }, (err) => {
            handleError(err)
        })
    }

    const createRelation = async (responsableData, idAlumno, idResponsable = null, tipoRelacionId = null) => {
        const formData = new FormData()
        formData.append("responsable_id", responsableData.id ?? idResponsable)
        formData.append("alumno_id", idAlumno)
        formData.append("tipo_relacion_id", responsableData.tipo_relacion_id ?? tipoRelacionId)

        return new Promise((resolve, reject) => {
            new RequestAlumnoResponsable().create(formData, resolve, reject)
        })
    }

    return { editAlumno }
}