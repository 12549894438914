import Requests from './request/Request'
import { useAulasCreateLogic } from 'hooks'
import { SnackbarProvider } from 'notistack'
import { Container, Paper } from '@mui/material'
import { DataFormAula } from 'components/forms/display'
import { CreateHeader } from 'pages/logged/admin/components'
import { BreadcrumbsList, Line } from 'components/data_display'

/**
 * Componente React para crear aulas dentro de una academia.
 * Utiliza un hook personalizado para manejar la lógica de creación, interactúa con una API a través de un objeto de solicitudes,
 * y presenta un formulario para ingresar los datos de la nueva aula.
 */
export default function CreateAulasAcademia() {
  const { isMounted, errors, register, createActividad, handleSubmit, headerRef } = useAulasCreateLogic({ requests: new Requests() })

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(createActividad)}>
        <BreadcrumbsList breadcrumbsList={[
          { to: "/",        label: "Inicio" },
          { to: "./../", label: "Academia" }, //--> Te dirige al apartado Aulas
          { to: "./../",    label: "Aulas" },
          { to: "./",       label: "Crear", actualItem: true },
        ]} />

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <CreateHeader
            ref={headerRef}
            title={"Crear Aula"}
            btnText={"Guardar"} />

          <Line />

          <DataFormAula register={register} errors={errors} isMounted={isMounted} />
        </Paper>

        <SnackbarProvider autoHideDuration={3000} />
      </form>
    </Container>
  )
}