/**
 * Hook personalizado para la lógica relacionada con la gestión del almacén de libros.
 *
 * @param {Object} cursoEscolar - Información sobre el curso escolar actual.
 * @param {Array} librosDevueltos - Un array de libros devueltos.
 * @param {Array} librosPedidos - Un array de libros pedidos.
 * 
 * @returns {Object} - Un objeto que contiene el curso escolar actual y una función para calcular el total de unidades en el almacén.
 */
export default function useLogic(cursoEscolar, librosDevueltos, librosPedidos) {
    
    /**
     * Calcula el total de unidades en el almacén restando las unidades devueltas y vendidas
     * de las unidades llegadas de libros pedidos.
     * 
     * @returns {number} - El total de unidades en el almacén.
     */
    const getTotal = () => {
        const unidadesLlegadas = getUnidades(librosPedidos, "cantidad_llegada")
        const unidadesDevueltas = getUnidades(librosDevueltos, "cantidad_devuelta")
        const unidadesVendidas = 0

        return unidadesLlegadas - unidadesDevueltas - unidadesVendidas
    }

    /**
     * Devuelve las unidades de un array, primero lo filtra por el curso escolar actual 
     * y luego suma todos los valores del parámetro del objeto indicado.
     * 
     * @param {Array} array - El array de libros.
     * @param {String} param - El nombre del parámetro que contiene las unidades.
     * 
     * @returns {number} - La suma de las unidades filtradas por el curso escolar actual.
     */
    const getUnidades = (array, param) => {
        const unidades = array?.filter(item => item.id_curso_escolar == cursoEscolar).reduce((accumulator, currentValue) => accumulator + (currentValue[param] ? parseInt(currentValue[param]) : 0), 0) ?? 0;
        return unidades
    }

    return { getTotal }
}