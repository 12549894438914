import React, { useState } from "react";
import { Grid, Box, useMediaQuery, useTheme, Tab, Tabs, FormControlLabel, Checkbox } from "@mui/material";
import { Input, DatePicker } from 'components/forms/components';
import { COLOR } from 'theme/Colors.js'
import { useForm } from 'react-hook-form';
import { H3, Text } from 'components/texts/index.js'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { TabContext } from '@mui/lab'
import ActividadesCurriculum from "./parts/ActividadesCurriculum";
import { DataCurriculumTable } from 'components/data_display';



/**
 * Formulario con los datos del currículum del candidato
 * 
 */
export default function DataCurriculumCandidato({ onChange, register, errors, empleado, setValue }) {

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isMd = useMediaQuery(theme.breakpoints.only('md'));
    const isXl = useMediaQuery(theme.breakpoints.only('xl'));

    const buttonSize = isXs ? "small" : isMd ? "medium" : isXl ? "large" : "medium";
    const [tab, setTab] = useState("1")

    return (
        <Grid container p={3} direction={'row'}>

            <Grid item mb={2} p={2} xs={12}>

                <Grid container spacing={2} alignItems="center">

                    <Grid item xs={10} >
                        <H3>Datos Entrega</H3>
                    </Grid>

                    <Grid item xs={2} >
                        <FormControlLabel control={<Checkbox />} label="Entrega Currículum" />
                    </Grid>

                    <Grid item xs={4} >
                        <Input className="w-80" label="Nº Curriculum" name="num_curriculum" disabled />
                    </Grid>

                    <Grid item xs={4} >
                        <DatePicker
                            name="fecha"
                            label="Fecha"
                            defaultValue={undefined}
                            clearable={true}
                            register={register}
                            errors={errors}
                            setValue={setValue}
                        />
                    </Grid>

                    <Grid item xs={4} >
                        <Input className="w-100" label="Archivo" name="archivo" />
                    </Grid>

                </Grid>
            </Grid>

            <Grid item mt={2} xs={12}>
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2} >
                        <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                            <Tab label={<Text fontWeight={tab === "1" ? "normal" : null} color={tab === "1" ? COLOR.standard.main : null}>Datos Actividades</Text>} value={"1"} />
                            <Tab label={<Text fontWeight={tab === "2" ? "normal" : null} color={tab === "2" ? COLOR.standard.main : null}>Datos Currículum y Archivo</Text>} value={"2"} />
                        </Tabs>
                    </Box>

                    <TabPanel value={"1"}>
                        <ActividadesCurriculum />
                    </TabPanel>

                    <TabPanel value={"2"}>
                        {/* TABLA PARA AÑADIR TITULACIÓN, DEBE MOSTRAS Y EDITAR  */}
                        <DataCurriculumTable />
                    </TabPanel>

                </TabContext>

            </Grid>
        </Grid>
    )
}
