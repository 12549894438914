import { useAlert } from "hooks"
import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"

/**
 * Hook que encapsula la lógica del modal de búsqueda de grupos de colegios en seguimiento
 * 
 * @param {Boolean} open - Indica el estado visible o no visible del modal
 * @param {Object} inscripcion  - Objeto con la informacion de la inscripcion
 * @param {String} tipoGrupo  - Indica el tipo de grupo (colegio, academia)
 * 
 * @returns {Object}
 */
export default function useLogic({ open, inscripcion, tipoGrupo, requests }) {
    const updateAlert = useAlert()

    const { register, handleSubmit, formState: { errors }, getValues, unregister } = useForm()

    const [trimestre, setTrimestre] = useState(null)

    const modalRef = useRef()

    useEffect(() => {
        if (open)
            modalRef.current.open()
    }, [open])

    const createSeguimiento = () => {
        const typeMapped = {
            'academia': 'inscripcion-academia',
            'colegio': 'inscripcion-colegio'
        }
        requests.createSeguimientoTrimestral(inscripcion.id, typeMapped[tipoGrupo], trimestre, getValues, (res) => {
            updateAlert('Seguimiento creado correctamente.', 'success')
            modalRef.current.close()
        }, (err) => {
            updateAlert('Ha habido un error inesperado.', '')
        })
    }

    const unregisterFormFields = () => {
        unregister([
            'comportamiento',
            'expresion_oral',
            'compresion',
            'expresion_escrita',
            'nota_media',
            'evolucion_simulacros',
            'materia_repasar',
            'comentarios'
        ])
    }

    const alumno = inscripcion?.alumno ?? null

    return { trimestre, setTrimestre, register, handleSubmit, errors, modalRef, createSeguimiento, alumno, unregisterFormFields }
}