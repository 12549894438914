import RequestMaster from '../RequestMaster'

export default class RequestImage extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + ''
    }

    /**
     * Función para obtener una imagen
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    get(path = '', params = {}, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        this.file(path, true, params, callback, callbackError, callbackFinally)
    }
}
