import { RequestEmpleado } from 'objects/requests'

export default class Requests {

    /**
     * Hace la petición generar listados del empleado
     * 
     * @param {Int} id 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */

    getListadoEmpleado(id, callbackOk = () => { }, callbackError = () => { }) {
        new RequestEmpleado().getListadosGruposEmpleado(id, {}, (res) => {
            res ? callbackOk(res) : callbackError(res)
        })
    }


    /**
     * Hace la petición generar listados de los horarios del empleado
     * 
     * @param {Int} id 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */

    getHorarioEmpleado(id, params, callbackOk = () => { }, callbackError = () => { }) {
        new RequestEmpleado().getHorarioEmpleado(id, params, (res) => {
            res ? callbackOk(res) : callbackError(res)
        })
    }
}
