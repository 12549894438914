import { RequestEmpleado } from "objects/requests"
import RequestsCreate from '../../create/Request/Request'

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Devuelve el empledo
     * 
     * @param {Int} id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getEmpleado( callbackOk = () => { }, callbackError = () => { }) {
        new RequestEmpleado().get( {}, (res) => {
            res ? callbackOk(res) : callbackError(res)
        })
    }


    /**
     * Devuelve todas las horas extra de un empleado
     * 
     * @param {Int} id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getHorasExtra(params, { }, callbackOk = () => { }, callbackError = () => { }) {
        new RequestEmpleado().getHorasExtra(params, (res) => {
            res ? callbackOk(res.data) : callbackError(res.data)
        })
    }

    /**
     * Devuelve todas las horas extra de un empleado
     * 
     * @param {Int} id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getPartido(params, { }, callbackOk = () => { }, callbackError = () => { }) {
        new RequestEmpleado().getPartido(params, (res) => {
            res ? callbackOk(res.data) : callbackError(res.data)
        })
    }


    /**
     * Devuelve los pagos el empleado
     * 
     * @param {Int} id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getPagosEmpleado(params, { }, callbackOk = () => { }, callbackError = () => { }) {
        new RequestEmpleado().getPago(params, (res) => {
            res ? callbackOk(res.data) : callbackError(res.data)
        })
    }


    /**
     * Devuelve las sustituciones del empleado
     * 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getSustitucion(params, { }, callbackOk = () => { }, callbackError = () => { }) {
        new RequestEmpleado().getSustitucion(params, (res) => {
            res.data ? callbackOk(res.data) : callbackError(res.data)
        })
    }

    /**
     * Devuelve las horas extra de un empleado
     * 
     * @param {Int} id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getGruposEmpleado(id, callbackOk = () => { }, callbackError = () => { }) {
        new RequestEmpleado().getGruposEmpleado(id, {}, (res) => {
            res ? callbackOk(res) : callbackError(res)
        })
    }

    /**
     * Devuelve contrato empleado
     * 
     * @param {Int} id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getContratoEmpleado(id, callbackOk = () => { }, callbackError = () => { }) {
        new RequestEmpleado().getContratoEmpleado(id, {}, (res) => {
            res ? callbackOk(res) : callbackError(res)
        })
    }

    /* Devuelve contrato empleado
     * 
     * @param {Int} id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getCurriculum(id, callbackOk = () => { }, callbackError = () => { }) {
        new RequestEmpleado().getCurriculum(id, {}, (res) => {
            res ? callbackOk(res) : callbackError(res)
        })
    }


    //     /**
    // * Devuelve las horas extra
    // * 
    // * @param {Int} id 
    // * @param {Function} callbackOk 
    // * @param {Function} callbackError 
    // */
    //     getHorasExtraEmpleado(id, callbackOk = () => { }, callbackError = () => { }) {
    //         new RequestEmpleado().getHorasExtraEmpleado(id, {}, (res) => {
    //             console.log(res, 'request respuesta')
    //             res ? callbackOk(res) : callbackError(res)
    //         })
    //     }

    /**
     * Hace la petición para editar el empleado
     * 
     * @param {Object} params 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editEmpleado(params, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()
        formData.append("nombre", getValues("nombre").toUpperCase())
        formData.append("apellido1", getValues("apellido1").toUpperCase())
        formData.append("apellido2", getValues("apellido2").toUpperCase())
        formData.append("email", getValues("email"))
        formData.append("email2", getValues("email2"))
        formData.append("sexo_id", getValues("sexo_id"))
        formData.append("movil", getValues("movil"))
        formData.append("telefono", getValues("telefono"))
        formData.append("comentarios", getValues("comentarios_datos_personales"))
        formData.append("fecha_nacimiento", getValues("fecha_nacimiento"))

        formData.append("numero_documento", getValues("numero_documento").toUpperCase())
        formData.append("tipo_documento_id", getValues("tipo_documento_id"))
        formData.append("cod_postal", getValues("cod_postal"))
        formData.append("provincia_id", getValues("provincia_id"))
        formData.append("municipio", getValues("municipio"))
        formData.append("direccion", getValues("direccion"))
        formData.append("tipo_via_id", getValues("tipo_via_id"))

        formData.append("pais_nacimiento", getValues("pais_nacimiento"))
        formData.append("provincia_nacimiento_id", getValues("provincia_nacimiento_id"))
        formData.append("municipio_nacimiento", getValues("municipio_nacimiento"))
        formData.append("certificado_penales", getValues("certificado_penales") ? 1 : 0)
        formData.append("retener_sueldo", 0)
        formData.append("es_candidato", 0)
        formData.append("otras_actividades", getValues("otras_actividades"))

        if (getValues("foto_perfil_url")[0])
            formData.append("foto_perfil_url", getValues("foto_perfil_url")[0])

        new RequestEmpleado().edit(params.id_empleado, formData, callbackOk, callbackError)
    }

    /**
     * Hace la petición para editar el curriculum del empleado
     * 
     * @param {Number} curriculumId 
     * @param {Number} empleadoId 
     * @param {Object} values 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editCurriculum(curriculumId, empleadoId, values, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()

        formData.append("codigo_curriculum", empleadoId)
        formData.append("idioma_principal_id", getValues("idioma_principal_id"))
        formData.append("entrega_curriculum", getValues("entrega_curriculum") ? 1 : 0)
        formData.append("seleccionar", getValues("seleccionar") ? 0 : 1)
        formData.append("fecha_entrada", getValues("fecha_entrada"))
        if (getValues("archivo")[0])
            formData.append("path", getValues("archivo")[0])

        const actividadesCurriculum = values["actividades-curriculum"] ?? []
        const otrasActividadesCurriculum = values["otras-curriculum"] ?? []
        const datosActividadesCurriculum = values["datosActividadesCurriculum"] ?? []

        const idiomas = values["idiomas_ids"] ?? []

        for (const idioma of idiomas)
            formData.append("idiomas_ids[]", idioma)

        const finalActividades = actividadesCurriculum.concat(otrasActividadesCurriculum).concat(datosActividadesCurriculum)
        for (const actividad of finalActividades)
            formData.append("caracteristicas_ids[]", actividad.id)

        new RequestEmpleado().editCurriculum(curriculumId, formData, callbackOk, callbackError)
    }

    /**
     * Hace la petición para editar los detalles del curriculum del empleado, o crearlos en caso de que sean registros nuevos
     * 
     * @param {Number} curriculumId 
     * @param {FunctionConstructor} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editDetallesCurriculum(curriculumId, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const detallesCurriculum = getValues('datos_curriculum')

        const nuevosDetalles = detallesCurriculum.filter(detalle => detalle.creating)
        if (nuevosDetalles.length > 0)
            new RequestsCreate().createCurriculumDetalle(curriculumId, getValues, nuevosDetalles, callbackOk, callbackError)

        const detallesExistentes = detallesCurriculum.filter(detalle => !detalle.creating)
        if (!detallesExistentes.length > 0)
            return

        const formData = new FormData()
        formData.append(`curriculum_id`, curriculumId)
        detallesExistentes.forEach((item, index) => {
            formData.append(`datos_curriculum[${index}][id]`, item.id)
            formData.append(`datos_curriculum[${index}][descripcion]`, item.descripcion)
            formData.append(`datos_curriculum[${index}][comentarios]`, item.comentarios)
            formData.append(`datos_curriculum[${index}][fecha_inicio]`, item.fecha_inicio)
            formData.append(`datos_curriculum[${index}][fecha_fin]`, item.fecha_fin)
        })

        new RequestEmpleado().editCurriculumDetalle(formData, callbackOk, callbackError)
    }

    /**
     * Hace la petición para editar las horas extra del empleado, o crearlas en caso de que sean registros nuevos
     * 
     * @param {Number} empleadoId 
     * @param {Number} cursoEscolar 
     * @param {Array} horasExtra 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editHorasExtra(empleadoId, cursoEscolar, horasExtra, callbackOk = () => { }, callbackError = () => { }) {
        const nuevasHorasExtra = horasExtra.filter(horaExtra => horaExtra.creating)
        if (nuevasHorasExtra.length > 0)
            new RequestsCreate().createHorasExtra(empleadoId, cursoEscolar, nuevasHorasExtra, callbackOk, callbackError)

        // Quitamos las horas extras que se han creado nuevas, éstas se mandarán a la petición de crear
        const horasExtraExistentes = horasExtra.filter(horaExtra => !horaExtra.creating)
        if (!horasExtraExistentes.length > 0)
            return

        const formData = new FormData()

        horasExtraExistentes.forEach((horaExtra, index) => {
            formData.append(`horas_extra[${index}][empleado_id]`, empleadoId)
            formData.append(`horas_extra[${index}][id]`, horaExtra.id)
            formData.append(`horas_extra[${index}][curso_escolar_id]`, cursoEscolar)
            formData.append(`horas_extra[${index}][fecha]`, horaExtra.fecha)
            formData.append(`horas_extra[${index}][horas]`, horaExtra.horas)
            formData.append(`horas_extra[${index}][importe]`, horaExtra.importe)
            formData.append(`horas_extra[${index}][fecha_pago]`, horaExtra.fecha_pago)
            formData.append(`horas_extra[${index}][motivo]`, horaExtra.motivo)
            formData.append(`horas_extra[${index}][liquidacion_id]`, horaExtra.liquidacion_id)
        });
        new RequestEmpleado().editHorasExtra(formData, callbackOk, callbackError)
    }

    /**
     * Hace la petición para editar los partidos del empleado, o crearlos en caso de que sean registros nuevos
     * 
     * @param {Number} empleadoId 
     * @param {Number} cursoEscolar 
     * @param {Array} partidos 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editPartidos(empleadoId, cursoEscolar, partidos, callbackOk = () => { }, callbackError = () => { }) {
        const nuevosPartidos = partidos.filter(partido => partido.creating);
        if (nuevosPartidos.length > 0)
            new RequestsCreate().createPartidos(empleadoId, cursoEscolar, nuevosPartidos, callbackOk, callbackError)

        // Quitamos el partido que se ha creado nuevo, éste se mandará a la petición de crear
        const partidosExistentes = partidos.filter(partido => !partido.creating);

        if (!partidosExistentes.length > 0)
            return

        const formData = new FormData()
        partidosExistentes.forEach((partido, index) => {
            formData.append(`partidos[${index}][id]`, partido.id)
            formData.append(`partidos[${index}][curso_escolar_id]`, partido.curso_escolar_id)
            formData.append(`partidos[${index}][empleado_id]`, partido.empleado_id)
            formData.append(`partidos[${index}][fecha]`, partido.fecha)
            formData.append(`partidos[${index}][horas]`, partido.horas)
            formData.append(`partidos[${index}][importe]`, partido.importe)
            formData.append(`partidos[${index}][fecha_pago]`, partido.fecha_pago)
            formData.append(`partidos[${index}][nombre_partido]`, partido.nombre_partido)
            formData.append(`partidos[${index}][liquidacion_id]`, partido.liquidacion_id)
        })

        new RequestEmpleado().editPartidos(formData, callbackOk, callbackError)
    }

    /**
     * Devuelve las sustituciones del empleado
     * 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editSustituciones(empleadoId, cursoEscolar, sustituciones, { }, callbackOk = () => { }, callbackError = () => { }) {
        const nuevasSustituciones = sustituciones.filter(sustitucion => sustitucion.creating)
        if (nuevasSustituciones.length > 0)
            new RequestsCreate().createSustituciones(empleadoId, cursoEscolar, nuevasSustituciones, callbackOk, callbackError)

        const sustitucionesExistentes = sustituciones.filter(sustitucion => !sustitucion.creating)
        if (!sustitucionesExistentes.length > 0)
            return

        const formData = new FormData()
        sustitucionesExistentes.forEach((sustitucion, index) => {
            formData.append(`sustituciones[${index}][id]`, sustitucion.id)
            formData.append(`sustituciones[${index}][curso_escolar_id]`, cursoEscolar)
            formData.append(`sustituciones[${index}][empleado_id]`, empleadoId)
            formData.append(`sustituciones[${index}][centro_id]`, sustitucion.centro_id)
            formData.append(`sustituciones[${index}][horas]`, sustitucion.horas)
            formData.append(`sustituciones[${index}][importe]`, sustitucion.importe)
            formData.append(`sustituciones[${index}][fecha_pago]`, sustitucion.fecha_pago)
            formData.append(`sustituciones[${index}][liquidacion_id]`, '123')
        })

        new RequestEmpleado().editPartidos(formData, callbackOk, callbackError)
    }
}
