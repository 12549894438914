import * as Yup from 'yup'

const Regex = "^[A-Za-z0-9]+$"
const RegexNombres = /^[A-Za-zÁ-ú]+(?:\s[A-Za-zÁ-ú]+)*$/
export const validationSchema = Yup.object().shape({
    nombre: Yup.string().required('El nombre es obligatorio'),
    apellido1: Yup.string().required('El primer apellido es obligatorio'),
    email: Yup.string().required('El email es obligatorio').email('El formato del email no es correcto.'),
    numero_documento: Yup.string().required('El número de documento es obligatorio'),
    // id_tipo_documento: Yup.string().required('El tipo de documento es obligatorio'),
    tipo_documento_id: Yup.string().required('El tipo de documento es obligatorio'),
    tipo_relacion_id: Yup.string().required('El tipo de relación es obligatorio'),
})