import React from 'react'
import { GeneralData, IdiomasData, ProgramacionesData, Header } from './parts'
import { Container, Paper, Stack, Box, Tab, Tabs } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { BreadcrumbsList, MoveButtons } from 'components/data_display'
import { Text } from 'components/texts/index.js'
import { COLOR } from 'theme/Colors.js'
import Requests from './Requests/Requests.js'
import { TabContext } from '@mui/lab'
import { SnackbarProvider } from 'notistack'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { useGrupoSingleWrapperLogic } from 'hooks'
import HorarioData from './parts/HorarioData'
import SkeletonHorario from './SkeletonHorario'

/**
 * Componente `Wrapper` que encapsula el estado y la lógica de un grupo académico individual.
 * Utiliza el hook `useGrupoSingleWrapperLogic` para gestionar el estado y las acciones específicas del grupo,
 * como obtener datos y manejar la selección y actualización del grupo.
 * Envuelve al componente `SingleGruposAcademia` y proporciona un proveedor `SnackbarProvider` para mostrar notificaciones.
 *
 * @returns {JSX.Element} - Un fragmento que contiene el componente `SingleGruposAcademia` y el proveedor de notificaciones.
 */
const Wrapper = () => {
    const navigationParams = useParams()

    const { getGrupo, grupo, id_grupo, setIdGrupo, setGrupo, tab, setTab } = useGrupoSingleWrapperLogic(navigationParams, new Requests())

    return (
        <React.Fragment>
            <SingleGruposAcademia key={grupo?.id ?? 0} onSaveFinish={() => getGrupo(true)} tab={tab} setTab={setTab} grupo={grupo} id_grupo={id_grupo} setIdGrupo={setIdGrupo} setGrupo={setGrupo} />

            <SnackbarProvider autoHideDuration={3000} />
        </React.Fragment>
    )
}
export default Wrapper

/**
 * Componente `SingleGruposAcademia` que muestra la interfaz de usuario para un grupo académico individual.
 * Permite la navegación y gestión de datos del grupo seleccionado.
 * Muestra miga de pan, botones de movimiento y pestañas con datos del grupo en diferentes categorías.
 *
 * @param {Object} grupo - Datos del grupo actual.
 * @param {number} id_grupo - ID del grupo actual.
 * @param {Function} setIdGrupo - Función para establecer el ID del grupo.
 * @param {Function} setGrupo - Función para establecer los datos del grupo.
 * @param {string} tab - Valor de la pestaña actualmente seleccionada.
 * @param {Function} setTab - Función para cambiar la pestaña seleccionada.
 *
 * @returns {JSX.Element} - Contenedor con toda la interfaz de usuario relacionada con un grupo individual.
 */
function SingleGruposAcademia({ onSaveFinish, grupo, id_grupo, setIdGrupo, setGrupo, tab, setTab }) {
    const navigate = useNavigate()

    const updateId = (id) => {
        navigate(`../academias/grupos/${id}`)
        setIdGrupo(id)
    }

    return (
        <Container maxWidth={false} sx={{ mt: 5 }}>

            <Stack direction="row" justifyContent={"space-between"} marginX={8}>
                <BreadcrumbsList mx={0} breadcrumbsList={[
                    { to: "/", label: "Inicio" },
                    { to: "./../", label: "Academia" }, //--> Te dirige al apartado Grupos
                    { to: "./../", label: "Grupos" },
                    { to: "./", label: "Grupo #" + (id_grupo ?? "0000"), actualItem: true },
                ]} />

                <MoveButtons
                    actualItemId={id_grupo}
                    beforeFetchCallback={() => setGrupo(null)}
                    onUpdateActualItem={updateId} />
            </Stack>

            <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
                <Header grupo={grupo} />

                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
                        <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                            <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>DATOS GENERALES</Text>} value={"1"} />
                            <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>IDIOMAS</Text>} value={"2"} />
                            <Tab label={<Text fontWeight={tab === "3" ? "bold" : null} color={tab === "3" ? COLOR.standard.main : null}>PROGRAMACIONES</Text>} value={"3"} />
                            <Tab label={<Text fontWeight={tab === "4" ? "bold" : null} color={tab === "4" ? COLOR.standard.main : null}>HORARIOS</Text>} value={"4"} /> {/**Tab para ver el horario */}
                        </Tabs>
                    </Box>

                    <TabPanel value={"1"}>
                        <GeneralData grupo={grupo} />
                    </TabPanel>

                    <TabPanel value={"2"}>
                        <IdiomasData grupo={grupo} />
                    </TabPanel>

                    <TabPanel value={"3"}>
                        <ProgramacionesData grupo={grupo} />
                    </TabPanel>
                    {/* Este es el tab con el que visualizamos los horarios, se hace esa condición para que no se renderice hasta que lleguen los datos, 
                        mientras tanto carga un skeleton.
                    */}
                    <TabPanel value={"4"}>
                        {grupo && grupo?.horarios ? <HorarioData horario={grupo?.horarios} grupo={grupo} onSaveFinish={onSaveFinish} /> : <SkeletonHorario />}
                    </TabPanel>
                </TabContext>

            </Paper>
        </Container>
    )
}
