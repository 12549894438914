import { RequestEmpleado } from 'objects/requests'

/**
 * Clase donde se guardan las peticiones que se van a usar para ficha-empleado
 */
export default class Requests {
    getCaracteristicas(callbackOk = () => { }, callbackError = () => { }) {
        new RequestEmpleado().getCaracteristicasCurriculum({}, (res) => {
            res ? callbackOk(res) : callbackError(res)
        })
    }
}