import React, { useRef } from 'react'
import { H3, Text } from 'components/texts'
import { Grid, Stack } from '@mui/material'
import TableMesesPagados from './TableMesesPagados'
import { Checkbox } from 'components/forms/components'
import { Button } from 'components/forms/components/index.js'
import { Groups as GroupsIcon, InsertDriveFile as FileIcon, CardMembership as CardMember } from '@mui/icons-material'
import SkeletonPagosAcademiaDataForm from './SkeletonPagosAcademiaDataForm'
import Statics from 'objects/common/Statics'

/**
 * Formulario con los datos generales de la inscripcion a una academia
 */
export default function PagosAcademiaDataForm({ inscripcion, isMounted, register, errors, control, handleSubmit }) {

    return (
        <Grid container py={3} px={3} >
            {!inscripcion ?
                <SkeletonPagosAcademiaDataForm />
                : (
                    <>
                        <Grid item xs={12} px={3}>
                            <Stack>
                                <Text fontWeight={"bold"}>Forma de pago:</Text>
                                <Text>{inscripcion?.forma_pago?.valor ?? "Sin especificar"}</Text>
                            </Stack>
                        </Grid>

                        <Grid container item xs={12} xl={7} p={3} spacing={2} display={"flex"} alignItems={"center"} >
                            <Grid item xs={4} >
                                <Stack>
                                    <Text fontWeight={"bold"}>Tipo de Matrícula:</Text>
                                    <Text>{inscripcion?.tipo_matricula?.nombre ?? "Sin especificar"}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={4} >
                                <Stack>
                                    <Text fontWeight={"bold"}>Cantidad Matrícula:</Text>
                                    <Text>{inscripcion?.matricula ?? "Sin rellenar"}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={4}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Fecha pago Matrícula:</Text>
                                    <Text>{inscripcion?.fecha_pago_matricula ? Statics.formatDate(inscripcion.fecha_pago_matricula) : ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={4}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Tipo pago:</Text>
                                    <Text>{inscripcion?.forma_pago?.valor ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={4} >
                                <Stack>
                                    <Text fontWeight={"bold"}>Tipo pago Cantidad:</Text>
                                    <Text>{inscripcion?.cantidad_pago ?? "Sin especificar"}</Text>
                                </Stack>
                            </Grid>
                            <Grid item xs={4}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Fecha de pago:</Text>
                                    <Text>{inscripcion?.fecha_pago ?? "Sin rellenar"}</Text>
                                </Stack>
                            </Grid>

                            {/* <Grid item xs={6} md={2}>
                                <Button startIcon={<FileIcon />}>
                                    Recibo?
                                </Button>
                            </Grid>

                            <Grid item xs={4} ml={2}>
                                <Button startIcon={<CardMember />} variant={"outlined"} onClick={handleSubmit}>
                                    Certificado?
                                </Button>
                            </Grid> */}

                        </Grid>
                        <Grid container item xs={12} xl={5} style={{ borderLeft: "1px solid #D9D9D9" }} p={3}>

                            <Grid container spacing={2} >
                                <Grid item xs={12}>
                                    <H3>Contrato</H3>
                                </Grid>
                                <Grid item xs={6}>
                                    <Checkbox disabled name={"emitido"} label={"Emitido"} defaultChecked={inscripcion?.contrato_emitido ? true : false} />
                                </Grid>

                                <Grid item xs={6}>
                                    <Checkbox disabled name={"recibido"} label={"Recibido"} defaultChecked={inscripcion?.contrato_recibido ? true : false} />
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid container mx={3} mt={5} pb={2} sx={{ overflowX: "auto" }} className={"darker-scrollbar"}>
                            <TableMesesPagados register={register} inscripcion={inscripcion} disabled />
                        </Grid>

                        <Grid container px={3} mt={5}>
                            <Stack>
                                <Text fontWeight={"bold"}>Observaciones de los Pagos:</Text>
                                <Text>{inscripcion?.observaciones ?? ""}</Text>
                            </Stack>
                        </Grid>
                    </>
                )
            }
        </Grid>
    )
}
