import { EmpleadoListContext } from "contexts/employee/ListContext"
import { useAlert } from "hooks"
import { useContext, useEffect, useState } from "react"

/**
 * Función personalizada que encapsula la lógica relacionada con el componente Wrapper.
 * 
 * Esta función maneja el estado del responsable, realiza solicitudes para obtener su información
 * y proporciona funciones para actualizar y obtener información relacionada con el responsable.
 * 
 * @param {Object} navigationParams - Parámetros de navegación del componente.
 * @returns {Object} - Objeto con funciones y datos relacionados con el responsable.
 */
export default function useWrapperLogic(navigationParams, requests) {
    const { items, updateItem } = useContext(EmpleadoListContext)

    const [responsable, setResponsable] = useState(null)
    
    const [id_responsable, setIdResponsable] = useState(navigationParams.id_responsable)

    const updateAlert = useAlert()

    const getResponsable = (getFromServer = false) => {
        const item = items.find(item => item?.id == id_responsable)
        if (items.length == 0 || !item || getFromServer) {
            requests.getResponsable(id_responsable, (responsable) => {
                setResponsable(responsable)
                updateItem(responsable)
            }, () => updateAlert("No se ha podido recuperar el responsable."))
        } else {
            setResponsable(item)
        }
    }

    useEffect(getResponsable, [id_responsable])

    return { getResponsable, updateAlert, id_responsable, responsable, setResponsable, setIdResponsable }
}