import { COLOR } from 'theme/Colors'
import { H2, Text } from 'components/texts'
import { keyValues } from './constants'
import { Box, Stack } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import { QuestionMark as InfoIcon } from '@mui/icons-material'
// import AlertModal from './parts/AlertModal'
import { Link } from 'components/data_display'
import { Alert, Tooltip } from "@mui/material"
import React, { useRef, useState } from 'react'
import { useAlert, useErrorHandler } from 'hooks'
import { Button, LoadingButton } from 'components/forms/components'
import { Add as AddIcon, FileDownload as FileDownloadIcon } from '@mui/icons-material'

/**
 * Hook personalizado `useLogic` que encapsula la lógica para exportar datos a Excel.
 * Mantiene un estado de carga y una referencia a un modal de alerta.
 *
 * @param {string} keyValue - Clave utilizada para seleccionar la solicitud de exportación y el nombre del archivo desde el objeto keyValues.
 * @param {Object} params - Parámetros utilizados para la solicitud de exportación de Excel.
 *
 * @returns {Object} - Retorna el estado de carga, la referencia al modal y la función de exportación de Excel.
 */
function useLogic(keyValue, params, rowCount) {
    const modalRef = useRef()
    const updateAlert = useAlert()
    const handleError = useErrorHandler()

    const [loadingPdf, setLoadingPdf] = useState(false)
    const [loadingExcel, setLoadingExcel] = useState(false)

    const exportExcel = () => {
        setLoadingExcel(true)

        const { request, fileName } = keyValues[keyValue]

        new request().excel(params, fileName, () => { }, handleError, () => setLoadingExcel(false))
    }

    /**
     *  Función con la que exportamos a PDF los registros de la tabla.
     *  Activamos el loading al buton
     *  Comprobamos que en la tabla al menos hay 1 registro renderizado y hacemos la petición para exportarlo
     *  En caso contrario mostramos un mensaje informativo indicando de que la tabla esta vacia y desactivando el estado loading del buton
     */
    const exportPdf = () => {
        setLoadingPdf(true)

        const { request } = keyValues[keyValue]

        if (rowCount >= 1) {
            new request().pdf('pdf', params, handleError, () => setLoadingPdf(false))
        } else {
            updateAlert('No hay elementos en la tabla para exportar.', 'info')
            setLoadingPdf(false)
        }

    }

    return { loadingExcel, loadingPdf, modalRef, exportExcel, exportPdf }
}

/**
 * Componente `ListTableHeader` que muestra el encabezado de una tabla listado con botones de acción.
 * Incluye funcionalidad para exportar datos a Excel y enlaces para crear nuevos elementos, además de botones adicionales personalizables.
 *
 * @param {number} rowCount - Número total de filas en la tabla, usado para habilitar/deshabilitar el botón de exportación.
 * @param {string} keyValue - Clave para definir textos y acciones específicas basadas en la tabla mostrada.
 * @param {Object} params - Parámetros utilizados para la exportación de Excel.
 * @param {string} [title] - Título opcional del encabezado de la tabla.
 * @param {string} [addText] - Texto opcional para el botón de añadir.
 * @param {boolean} [hideAddButton] - Si es verdadero, no se muestra el botón de añadir.
 * @param {JSX.Element} [extraButtons] - Botones adicionales a renderizar en el encabezado.
 *
 * @returns {JSX.Element} - Un componente visual que representa el encabezado de la tabla con botones de acción.
 */
export default function ListTableHeader({ rowCount, keyValue, params, title, addText, hideAddButton = false, extraButtons = null }) {

    const { loadingExcel, loadingPdf, modalRef, exportExcel, exportPdf } = useLogic(keyValue, params, rowCount)

    return (
        <>
            <Box display={"flex"} justifyContent={"space-between"} mb={5}>
                <H2>{title ?? keyValues[keyValue]?.title ?? "Listado"}</H2>

                <Stack display={"flex"} flexDirection={"row"} alignItems={"center"} spacing={"2"}>
                    {extraButtons}

                    <Tooltip arrow title={<Text>Si desea exportar en excel o en PDF, deberá primero de realizar una búsqueda. La exportación se realizará de los registros que haya en la tabla. En caso de que esté vacía, los botones quedarán deshabilitados.</Text>}>
                        <InfoIcon color={"info"} className={"mx-2"} />
                    </Tooltip>

                    {/** //TODO: COVERTIR EL TOOLTIP DE LA INTERROGACION A COMPONENTE PERSONALIZADO */}
                    {/* <InfoTooltip text="Si desea exportar en excel o en PDF, deberá primero de realizar una " color="" size /> */}

                    <></>
                    {/** 
                     * 08/04/24
                     * 
                     * Establecido el boton de exportar como habilitado si hay filas o si esta cargando.
                     * La funcionalidad de exportacion en excel de inscripcion academias no está hecha en back, pero todo lo de alumnos sí está hecho.
                     * 
                     * Valor original de disabled -> disabled={rowCount == 0 || loading} 
                     */}                                                                                                                                            {/*onClick={rowCount >= 1000 ? () => { modalRef.current.modal.open() } : exportExcel}  -> Estos fueron los valores originales del onClick*/}
                    <LoadingButton className="me-5" startIcon={<FileDownloadIcon style={{ color: COLOR.white.main }} />} disabled={rowCount == 0 || loadingExcel} loading={loadingExcel} onClick={exportExcel}>
                        Exportar en excel
                    </LoadingButton>

                    {/* 
                        El botón nuevo con el que permitimos descargar los pdfs                                                                                   
                    */}                                                                                                                                            {/*onClick={rowCount >= 1000 ? () => { modalRef.current.modal.open() } : exportPdf} -> Estos fueron los valores originales del onClick*/}
                    <LoadingButton className="me-5" startIcon={<FileDownloadIcon style={{ color: COLOR.white.main }} />} disabled={rowCount == 0 || loadingPdf} loading={loadingPdf} onClick={exportPdf}>
                        Exportar en pdf
                    </LoadingButton>

                    {hideAddButton ? null :
                        <Link to={"./crear"}>
                            <Button color={'success'} startIcon={<AddIcon style={{ color: COLOR.white.main }} />}>
                                {addText ?? keyValues[keyValue]?.addText ?? "Añadir"}
                            </Button>
                        </Link>
                    }
                </Stack>

                {/* 
                    Fecha: 04/04/2024
                    Modificado: Aleks/becario

                    ----------------- VENTANA MODAL -----------------

                    Esta es una ventana modal que se ejecutaría cuando el rowCount detecte que hay más de 1000 registros, pero esta desactivado
                    porque la tabla solo renderiza la paginación de 25 registros en 25 registros, además cuando se exporta a PDF solo se exporta lo que 
                    hay en la plantilla que son los 25 registros renderizados.

                    En el caso de que se tenga que usar la Ventana Modal se tendra que añadir más funcionalidad, para ver que se exporta si PDF o Excel,
                    se penso en hacer una función global que reciba un parametro que depende de donde se ejecute tenga un valor y con if se comprueba y se hace
                    una acción u otra.


                */}
                {/* <AlertModal ref={modalRef} rowCount={rowCount} onGoBack={() => { modalRef.current.modal.close() }} onContinue={() => {
                modalRef.current.modal.close()
                // exportExcel()
                //exportPdf()
            }} /> */}

                <SnackbarProvider autoHideDuration={3000} />
            </Box>
        </>
    )
}
