import Skeleton from './skeleton'
import { columns } from './columns'
import { Grid } from '@mui/material'
import React, { Component } from 'react'
import Requests from '../../Requests/Requests'
import { Datagrid } from 'components/data_display'
import { CursoEscolarSelect, ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Pagina con los trabajadores del colegio
 */
export default class TrabajadoresData extends Component {

  constructor(props) {
    super(props)

    this.state = {
        colegio: this.props.colegio ?? null,
        trabajadores: [],
        loading: false,
    }

    this.requests = new Requests
  }


  componentDidUpdate(prevProps) {
    if(prevProps.colegio != this.props.colegio)
        this.setState({colegio: this.props.colegio})
  }

  //-------------------------------------------------------
  //-------------------- CLASS FUNCTIONS ------------------
  //-------------------------------------------------------

  onCursoEscolarChange = ({ target }) => {
    this.setState({loading: true}, () => {
      this.requests.getTrabajadoresColegio(this.state.colegio.id, {id_curso_escolar: target.value}, (trabajadores) => {
        this.setState({ trabajadores, loading: false })
      }, (error) => {
        this.setState({ trabajadores: [], loading: false })
      })
    })
    
  }

  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
        <Grid container>
            {this.state.colegio ? (
              <Grid container p={5}>

                <Grid item xs={12}>
                  <CursoEscolarSelect name={`id_curso_escolar`} onChange={this.onCursoEscolarChange} defaultValue={null} width={"60%"} searcheable />
                </Grid>

                <Grid item xs={12} mt={5}>
                  <Datagrid
                    rows    = {this.state.trabajadores}
                    columns = {columns}
                    loading = {this.state.loading} />
                </Grid>

              </Grid>
            ) : <Skeleton />}
        </Grid>
    )
  }
}
