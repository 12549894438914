import React from 'react'
import { Box, Grid } from "@mui/material";
import { Datagrid } from 'components/data_display'
import moment from 'moment'
import { CursoEscolarSelect } from 'components/forms/components/Select/custom'

const columns = [
    {
        field: 'alumno',
        headerName: 'Alumno',
        width: 300,
        editable: true,
        valueGetter: (params) => { return params.row?.alumno?.nombre + ' ' + params.row?.alumno?.apellido1 + ' ' + params.row?.alumno?.apellido2 }

    },
    {
        field: 'fecha_alta',
        headerName: 'Fecha Alta',
        width: 180,
        editable: true,
    },
    {
        field: 'fecha_baja',
        headerName: 'Fecha Baja',
        width: 180,
        editable: true,
    },
    {
        field: 'horasSem',
        headerName: 'Horas Sem.',
        type: 'number',
        width: 150,
        editable: true,
    },
    {
        field: 'horaAlumno',
        headerName: 'Hora Alumno',
        type: 'number',
        width: 150,
        editable: true,
    },
    {
        field: 'horaProf',
        headerName: 'Hora Prof',
        type: 'number',
        width: 120,
        editable: true,
    }
];

const cursoActual = moment().subtract(1, 'year').format('YYYY') + "-" + moment().format('YYYY')

export default function ClasesParticulares({ setValue, onChangeCursoEscolar, gruposEmpleadoClasesParticulares = [] }) {

    return (
        <Grid container>
            <Grid item xs={12} marginTop={2} marginBottom={2}>
                <Box display="flex" justifyContent="flex-end">
                    <CursoEscolarSelect
                        label={"Curso escolar"}
                        name={"id_curso_escolar"}
                        onChange={({ target }) => onChangeCursoEscolar(target.value)}
                        defaultValue={null}
                        setValue={setValue}
                        searcheable />
                </Box>
            </Grid>
            <Grid item xs={12}>
            <Datagrid
                    rows={Array.isArray(gruposEmpleadoClasesParticulares) ? gruposEmpleadoClasesParticulares : []}
                    columns={columns}
                />
            </Grid>
        </Grid >



    )
}