import { Grid } from '@mui/material'
import React, { Component } from 'react'
import { Text } from 'components/texts'
import { Input } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Esta es la plantilla para cuando se busque por los datos basicos del alumno
 */
export default function CandidatosDomicilioTemplate({ errors, register }) {

    return (
        <Grid container>
            <Grid container mt={2} mb={1}>
                <Text fontWeight={'bold'}>Según datos de:</Text><br />
            </Grid>

            <Grid container spacing={2}>

                <Grid item xs={4}>
                    <Input label="Dirección" width={"100%"} name={"direccion"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <Input label="Municipio" width={"100%"} name={"municipio"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={2}>
                    <Input label="C.P" width={"100%"} name={"cp"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={2}>
                    <ItemsSelect name={"provincia_id"} keyValue={"provincia"} className="w-100" register={register} errors={errors} />
                </Grid>

            </Grid>
            
        </Grid>
    )
}

