import { v4 as uuid } from "uuid"
import { COLOR } from 'theme/Colors'
import React, { Component, useState } from "react"
import { H3, Text } from 'components/texts'
import { Button, LoadingButton } from 'components/forms/components'
import { RequestGrupoAcademia } from "objects/requests"
import { Visibility as EyeIcon } from "@mui/icons-material"
import { Grid, ListItemButton, ListItemText, Stack, List, Box, TextField } from "@mui/material"
import { enqueueSnackbar } from "notistack"

export default class GrupoAcademiaPicker extends Component {

    constructor(props) {
        super(props)

        this.state = {
            grupos: [],
            selected: null,
            defaultId: this.props.defaultValue ?? null,
            grupoAsignado: null //Creamos el nuevo estado para almacenar el grupo al que esta asignada esta inscripción
        }
    }

    componentDidMount() {
        if (this.props.defaultValue)
            this.props.setValue(this.props.name ?? "id", this.props.defaultValue)

        if (this.props.cursoEscolar && this.props.actividad) {
            this.getGrupos(this.props.cursoEscolar, this.props.actividad)
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.cursoEscolar && this.props.actividad) && ((this.props.cursoEscolar != prevProps.cursoEscolar) || (this.props.actividad != prevProps.actividad))) {
            this.getGrupos(this.props.cursoEscolar, this.props.actividad)
        }
    }

    /**
     * Devuelve los grupos a partir del horario
     * 
     * @param {Int} id 
     */
    getGrupos = (curso_escolar_id, registro_actividad_id) => {
        const params = { curso_escolar_id, registro_actividad_id, limit: 50 }
        params.appends = ['curso_escolar', 'registro_actividad', 'nivel_grupo', 'horarios', 'profesor_actual', 'libro']
        /**
         * 
         * Esta request trae todos los grupos en función del select y a su vez comprueba si alguno esta asignado al alumno,
         * ya que por el componente nos llega el ID del grupo al que esta asignado he añadido otra condición para comprobar si
         * tiene un grupo asignado y lo guardo en el estado
         */
        new RequestGrupoAcademia().search(params, (res) => {
            this.setState({
                grupos: res.data,
                selected: this.state.defaultId ? res.data.find(item => item.id == this.state.defaultId) : null
            }, () => {
                //Si el ID del grupo que nos llega no es null obtenemos los datos del grupo y los almacenamos en el estado.
                const grupoAsignado = this.state.defaultId ? res.data.find(item => item.id == this.state.defaultId) : null;
                if (grupoAsignado) {
                    this.setState({ grupoAsignado: grupoAsignado });
                }
            });
        }, (res) => {
            this.setState({ grupos: [], selected: null });
        });
    }


    /**
     * Resetea el valor
     */
    reset = () => {
        this.setState({ selected: null }, () => {
            this.props.setValue(this.props.name ?? "id", "")

            if (this.props.onGrupoChange)
                this.props.onGrupoChange(null)
        })
    }

    /**
     * Devuelve el PDF de un grupo del colegio
     */
    composicion = () => {
        new RequestGrupoAcademia().preliminar(this.state.selected.id, {}, (err) => {
            enqueueSnackbar(<Text>{err}</Text>, { variant: "error" })
        })
    }


    /**
     * Actualiza el item seleccionado
     * 
     * @param {Object} selected 
     */
    onSelect = (selected) => {
        this.setState({ selected }, () => {
            this.props.setValue(this.props.name ?? "id", selected?.id ?? "", { shouldDirty: true })

            if (this.props.onGrupoChange)
                this.props.onGrupoChange(selected)
        })
    }


    /**
     * Render
     *
     * @returns {Component}
     */
    render() {
        const { selected} = this.state

        return (
            <Grid container>
                <Grid item xs={12} md={3} display={"flex"} flexDirection={"column"}>
                    <Box sx={{ backgroundColor: COLOR.grey.light, minHeight: 150, maxHeight: 200, overflowY: "auto", borderRadius: 3, flexGrow: 1 }} px={2}>
                        <List dense>
                            {this.state.grupos.map(item => {
                                return (
                                    <ListItemButton
                                        key={uuid()}
                                        sx={{ backgroundColor: COLOR.white.main, borderRadius: 2, my: 1 }}
                                        selected={selected?.id === item.id}
                                        onClick={(e) => { this.onSelect(item) }}
                                        disabled={item.max_alumnos && item.max_alumnos <= 3}>
                                        <ListItemText primary={item.clave_grupo} />
                                    </ListItemButton>
                                )
                            })}
                        </List>
                    </Box>

                    <Button variant={"outlined"} sx={{ mt: 1, alignSelf: "end" }} onClick={this.reset}>
                        Limpiar
                    </Button>

                    <TextField sx={{ width: 0 }} type={"hidden"} value={selected?.id ?? ""} variant={"standard"}
                        {...this.props.register ? this.props.register(this.props.name ?? "id") : {}} />
                </Grid>
                <Grid container item xs={12} md={9} px={4} mt={2}>
                    <Grid item xs={12}>
                        <H3>Información del academia seleccionada</H3>
                    </Grid>
                    <Grid item xs={12}>
                    <Text fontWeight={"bold"}>Grupo asginado: </Text>
                        <Text>{this.state.grupoAsignado ? this.state.grupoAsignado.clave_grupo : 'No tiene grupos asignados'}</Text>
                    </Grid>
                    <Grid item xs={6} lg={4} mt={2}>
                        <Stack>
                            <Text fontWeight={"bold"}>Grupo</Text>
                            <Text>{selected?.clave_grupo ?? ""}</Text>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} lg={4} mt={2}>
                        <Stack>
                            <Text fontWeight={"bold"}>Alumnos</Text>
                            <Text>{selected ? selected?.num_alumnos : ""}</Text>
                        </Stack>
                    </Grid>

                    <Grid item xs={6} lg={4} mt={2} >
                        <Stack>
                            <Text fontWeight={"bold"}>Horario</Text>
                            <Text>{selected?.horarios[0]?.horario_formatted ?? ""}</Text>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} lg={4} mt={2}>
                        <Stack>
                            <Text fontWeight={"bold"}>Nivel</Text>
                            <Text>{selected?.nivel_grupo?.valor ?? ''}</Text>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} lg={4} mt={2}>
                        <Stack>
                            <Text fontWeight={"bold"}>Profesor</Text>
                            <Text>
                                {(selected?.profesor_actual?.empleado?.nombre ?? "") + " " + (selected?.profesor_actual?.empleado?.apellido1 ?? "") + " " + (selected?.profesor_actual?.empleado?.apellido2 ?? "")}
                            </Text>
                        </Stack>
                    </Grid>

                    <Grid item xs={6} lg={4} mt={2}>
                        <Stack>
                            <Text fontWeight={"bold"}>Libro</Text>
                            <Text>
                                {selected?.libro?.nombre ?? ""}
                            </Text>
                        </Stack>
                    </Grid>

                    <ComposicionButton grupo={selected} />
                </Grid>
            </Grid>
        )
    }
}

function ComposicionButton({ grupo }) {
    const [loading, setLoading] = useState(false)

    const composicion = () => {
        setLoading(true)

        new RequestGrupoAcademia().preliminar(grupo.id, {}, (err) => {
            enqueueSnackbar(<Text>{err}</Text>, { variant: "error" })
        }, () => setLoading(false))
    }

    return (
        <Grid item xs={6} mt={2}>
            <LoadingButton colorVariant={"cancel"} startIcon={<EyeIcon />} disabled={!grupo} loading={loading} onClick={composicion}>
                {"Composición del Grupo"}
            </LoadingButton>
        </Grid>
    )
}
