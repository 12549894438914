import React, { useRef, useState, useEffect, useContext } from 'react'
import { TabContext } from '@mui/lab'
import { Text } from 'components/texts'
import { COLOR } from 'theme/Colors.js'
import Requests from './Request/Request'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { BreadcrumbsList } from 'components/data_display'
import { CreateHeader } from 'pages/logged/admin/components'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { TabPanel } from 'components/modifications/mui/TabPanel'
import { Grid, Box, Tab, Tabs, Container, Paper, Stack } from "@mui/material"
import { validationSchema } from 'components/forms/display/ficha-empleado/validation'
import { DatosEmpleadoDataForm, DataCurriculumEmpleado, ListadosDataForm, DatosContratosForm, PagosDataForm, Reclamaciones, AsistenciaYextras } from "components/forms/display"
import { useErrorHandler } from 'hooks'
import { ChangesContext } from 'contexts/ChangesContext'
import Statics from 'objects/common/Statics'
import { useNavigate } from 'react-router-dom'

export default function CreateEmpleado() {
    //State
    const [tab, setTab] = useState("1")
    const [cursoEscolar, setCursoEscolar] = useState()

    //Hooks
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm({ resolver: yupResolver(validationSchema) })
    const handleError = useErrorHandler()

    //Context
    const { updateChanges } = useContext(ChangesContext)

    //Ref
    const headerRef = useRef()

    /**
     * Handle success request
     * 
     * @param {Number} empleadoId 
     */
    const handleSuccessRequest = async (empleadoId) => {
        headerRef.current.button.setLoading(false)
        enqueueSnackbar(<Text>Empleado creado con éxito. Redirigiendo a la página de edición...</Text>, { variant: 'success' })
        updateChanges(false)

        await Statics.delay(1500).then(() => { navigate('./../' + empleadoId + "/editar") })
    }

    /**
     * Crear un nuevo empleado
     * 
     * @param {*} values 
     */
    const createEmpleado = (values) => {
        // headerRef.current.button.setLoading(true)

        new Requests().createEmpleado(getValues, (res) => {
            const empleadoId = res?.id
            createHorasExtra(values, empleadoId)
            createCurriculum(values, getValues, empleadoId)
            createPartidos(empleadoId)
            handleSuccessRequest(empleadoId)
        }, (error) => {
            headerRef.current.button.setLoading(false)
            handleError(error)
        })
    }

    /**
     * Crear el curriculum asociado al empleado
     * 
     * @param {*} values 
     * @param {Function} getValues 
     * @param {Number} empleadoId 
     * @returns 
     */
    const createCurriculum = (values, getValues, empleadoId) => {
        if (!getValues('entrega_curriculum'))
            return

        new Requests().createCurriculum(empleadoId, values, getValues, (res) => {
            const curriculumId = res?.id
            // enqueueSnackbar(<Text>Currículum creado con éxito.</Text>, { variant: 'success' })
            createDatosCurriculum(curriculumId)
        }, (error) => {
            headerRef.current.button.setLoading(false)
            handleError(error)
        })
    }

    const createDatosCurriculum = (curriculumId) => {
        if (getValues('datos_curriculum') && !getValues('datos_curriculum')?.length > 0)
            return

        new Requests().createCurriculumDetalle(curriculumId, getValues, (res) => {
            // enqueueSnackbar(<Text>Datos del currículum creados con éxito.</Text>, { variant: 'success' })
        }, (error) => {
            headerRef.current.button.setLoading(false)
            handleError(error)
        })
    }

    /**
     * Crear las horas extras asociadas al empleado
     * 
     * @param {*} values 
     * @param {Number} empleadoId 
     * @returns 
     */
    const createHorasExtra = (values, empleadoId) => {
        const horasExtra = values?.horasExtra;
        if (!horasExtra)
            return

        new Requests().createHorasExtra(empleadoId, cursoEscolar, horasExtra, (res) => {
            // enqueueSnackbar(<Text>Horas extra registradas con éxito.</Text>, { variant: 'success' })
        }, (error) => {
            headerRef.current.button.setLoading(false)
            handleError(error)
        });
    };

    const createPartidos = (empleadoId) => {
        const partidos = getValues('partido')
        if (!partidos)
            return

        new Requests().createPartidos(empleadoId, cursoEscolar, partidos, (res) => {
            // enqueueSnackbar(<Text>Partidos registrados con éxito.</Text>, { variant: 'success' })
        }, (error) => {
            headerRef.current.button.setLoading(false)
            handleError(error)
        })
    }

    return (
        <Grid container p={3}>
            <Container maxWidth={false} sx={{ mt: 5 }}>
                <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(createEmpleado, (errors) => {
                    enqueueSnackbar(<Text>Revisa los campos obligatorios.</Text>, { variant: "error" })
                })}>
                    <Stack direction="row" justifyContent={"space-between"} marginX={8}>
                        <BreadcrumbsList mx={0} breadcrumbsList={[
                            { to: "/", label: "Inicio" },
                            { to: "./../", label: "Empleado" },
                            { to: "./", label: "Crear Empleado", actualItem: true },
                        ]} />
                    </Stack>

                    <Paper elevation={2} sx={{ m: 2, mx: 8, my: 2, pb: 5 }}>
                        <Grid container display={"flex"} justifyContent={"end"} alignItems={"start"}>
                            <CreateHeader
                                ref={headerRef}
                                title={"Crear ficha del Empleado"} />
                        </Grid>

                        <TabContext value={tab}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
                                <Tabs value={tab} onChange={(_, tab) => setTab(tab)} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                                    <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>DATOS PERSONALES</Text>} value={"1"} />
                                    <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>CURRICULUM VITAE</Text>} value={"2"} />
                                    <Tab label={<Text fontWeight={tab === "3" ? "bold" : null} color={tab === "3" ? COLOR.standard.main : null}>LISTADOS</Text>} value={"3"} />
                                    <Tab label={<Text fontWeight={tab === "4" ? "bold" : null} color={tab === "4" ? COLOR.standard.main : null}>DATOS CONTRATOS</Text>} value={"4"} />
                                    <Tab label={<Text fontWeight={tab === "5" ? "bold" : null} color={tab === "5" ? COLOR.standard.main : null}>PAGOS</Text>} value={"5"} />
                                    <Tab label={<Text fontWeight={tab === "6" ? "bold" : null} color={tab === "6" ? COLOR.standard.main : null}>ASISTENCIA Y EXTRAS</Text>} value={"6"} />
                                    {/* <Tab label={<Text fontWeight={tab === "6" ? "bold" : null} color={tab === "6" ? COLOR.standard.main : null}>RECLAMACIONES</Text>} value={"6"} /> */}
                                </Tabs>
                            </Box>

                            <TabPanel value={"1"}>
                                <DatosEmpleadoDataForm register={register} errors={errors} getValues={getValues} setValue={setValue} />
                            </TabPanel>

                            <TabPanel value={"2"}>
                                <DataCurriculumEmpleado getValues={getValues} register={register} errors={errors} setValue={setValue} />
                            </TabPanel>

                            <TabPanel value={"3"}>
                                <ListadosDataForm />
                            </TabPanel>

                            <TabPanel value={"4"}>
                                <DatosContratosForm onChangeCursoEscolar={setCursoEscolar} getValues={getValues} register={register} errors={errors}
                                    setValue={setValue} />
                            </TabPanel>

                            <TabPanel value={"5"}>
                                <PagosDataForm getValues={getValues} register={register} errors={errors}
                                    setValue={setValue} />
                            </TabPanel>


                            <TabPanel value={"6"}>
                                <AsistenciaYextras onChangeCursoEscolar={setCursoEscolar} cursoEscolar={cursoEscolar} getValues={getValues} register={register} errors={errors}
                                    setValue={setValue} />
                            </TabPanel>

                            {/* <TabPanel value={"6"}>
                            <Reclamaciones />
                        </TabPanel> */}

                        </TabContext>
                    </Paper>
                </form>
            </Container>

            <SnackbarProvider autoHideDuration={3000} />
        </Grid>
    )
}
