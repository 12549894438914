import React, { useEffect } from 'react'
import { Grid } from '@mui/material';
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { H3 } from 'components/texts';
import { ImagePicker, Input } from 'components/forms/components'
import { ASSETS } from 'constants/Assets'
import { DatePicker, Checkbox } from 'components/forms/components';

/**
 * Formulario con los datos personales
 */

export default function DatosEmpleadoDataForm({ register, errors, empleado, setValue }) {


    useEffect(() => {
        console.log(empleado?.foto_perfil_url)
    }, []);


    return (
        <Grid container px={5} mt={3}>
            <Grid container alignItems={"center"}>
                <Grid item xs={4} md={2}>
                    <ImagePicker
                        mimeTypes={['image/jpeg', 'image/png']}
                        register={register}
                        className="w-100"
                        name={"foto_perfil_url"}
                        defaultImage={ASSETS.images + (empleado?.foto_perfil_url ?? 'private/empleado/perfiles/0/default.png')} />
                </Grid>

                <Grid item xs={8} md={10} container spacing={2} px={2}>

                    <Grid item xs={12} display={"flex"} flexDirection={"column"} >
                        <Checkbox name={"certificado_penales"} label={"Certificado penales"} register={register} className="mt-2"
                            defaultChecked={empleado?.certificado_penales ? true : false} />
                    </Grid>

                    <Grid item xs={6}>
                        <Input label="Nombre" register={register} errors={errors} className="w-100" name={"nombre"}
                            defaultValue={empleado?.nombre ?? ""} uppercase />
                    </Grid>

                    <Grid item xs={6}>
                        <Input label="Primer apellido" register={register} errors={errors} className="w-100" name={"apellido1"}
                            defaultValue={empleado?.apellido1 ?? ""} uppercase />
                    </Grid>

                    <Grid item xs={6}>
                        <Input label="Segundo apellido" register={register} errors={errors} className="w-100" name={"apellido2"}
                            defaultValue={empleado?.apellido2 ?? ""} uppercase />
                    </Grid>

                    <Grid item xs={6}>
                        <ItemsSelect name={"sexo_id"} keyValue={"sexo"} register={register} label={'Género'} errors={errors} defaultValue={empleado?.sexo_id ?? ""} />
                    </Grid>

                    <Grid item xs={6}>
                        <ItemsSelect name={"tipo_documento_id"} keyValue={"tipo_documento"} register={register} errors={errors} defaultValue={empleado?.tipo_documento_id ?? ""} />
                    </Grid>

                    <Grid item xs={6}>
                        <Input label="Numero de documento" register={register} errors={errors} className="w-100" name={"numero_documento"}
                            defaultValue={empleado?.numero_documento ?? ""} uppercase />
                    </Grid>



                    <Grid item xs={6}>
                        <Input label="Email" register={register} errors={errors} className="w-100" name={"email"}
                            defaultValue={empleado?.email ?? ""} uppercase />
                    </Grid>

                    <Grid item xs={6}>
                        <Input label="Email secundario" register={register} errors={errors} className="w-100" name={"email2"}
                            defaultValue={empleado?.email2 ?? ""} uppercase />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                    <H3 className={"m-0"}>Datos de contacto</H3>
                </Grid>

                <Grid item xs={6}>
                    <Input label="Teléfono" register={register} errors={errors} className="w-100" name={"telefono"}
                        defaultValue={empleado?.telefono ?? ""} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Móvil principal" register={register} errors={errors} className="w-100" name={"movil"}
                        defaultValue={empleado?.movil ?? ""} />
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                    <H3 className={"m-0"}>Datos domicilio actual</H3>
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect name={"tipo_via_id"} keyValue={"tipo_via"} className="w-100" register={register} errors={errors} defaultValue={empleado?.tipo_via_id ?? ""} />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Dirección" register={register} errors={errors} className="w-100" name={"direccion"}
                        defaultValue={empleado?.direccion ?? ""} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect name={"provincia_id"} keyValue={"provincia"} className="w-100" register={register} errors={errors} defaultValue={empleado?.provincia_id ?? ""} />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Municipio" register={register} errors={errors} className="w-100" name={"municipio"}
                        defaultValue={empleado?.municipio ?? ""} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Código postal" register={register} errors={errors} className="w-100" name={"cod_postal"}
                        defaultValue={empleado?.cod_postal ?? ""} uppercase />
                </Grid>

            </Grid>

            <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                    <H3 className={"m-0"}>Datos de nacimiento</H3>
                </Grid>

                <Grid item xs={2} >
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_nacimiento"} label={"Fecha Nacimiento"}
                        defaultValue={empleado?.fecha_nacimiento ?? ""} clearable setValue={setValue} />
                </Grid>

                <Grid item xs={6}>
                    <Input
                        label="País de nacimiento"
                        register={register}
                        errors={errors}
                        className="w-100"
                        name={"pais_nacimiento"}
                        defaultValue={empleado?.pais_nacimiento ?? ""}
                        uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input
                        label="Municipio de nacimiento"
                        register={register}
                        errors={errors}
                        className="w-100"
                        name={"municipio_nacimiento"}
                        defaultValue={empleado?.municipio_nacimiento ?? ""}
                        uppercase />
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect
                        label={"Provincia de nacimiento"}
                        name={"provincia_nacimiento_id"}
                        keyValue={"provincia"}
                        register={register}
                        errors={errors}
                        defaultValue={empleado?.provincia_nacimiento_id ?? ""} />
                </Grid>

            </Grid>

            <Grid container mt={4} >

                <Grid item xs={12} mb={2}>
                    <H3 className={"m-0"}>Comentarios</H3>
                </Grid>

                <Grid item xs={12}>
                    <Input
                        placeholder={"Comentarios"}
                        register={register}
                        errors={errors}
                        className="w-100"
                        name={"comentarios_datos_personales"}
                        defaultValue={empleado?.comentarios ?? ""}
                        multiline minRows={6} maxRows={10} uppercase />
                </Grid>
            </Grid>
        </Grid>
    )
}


