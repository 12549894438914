import React, { useEffect, useRef, useState } from "react"
// import { ASSETS } from 'constants/Assets'
import { H3, Text } from 'components/texts'
import { Button } from 'components/forms/components'
import { SearchAlumnoModal } from 'components/modals'
import { Add, Delete, Edit } from '@mui/icons-material'
import { Avatar, Box, Stack, TextField } from "@mui/material"
import { useImageLoader } from "hooks"
import { DEFAULT_IMAGES_URL } from "constants/DefaultImagesUR"

/**
 * Hook que maneja la lógica y el estado de la selección de un alumno, incluyendo la carga de su imagen.
 * Facilita la interacción con el modal de selección de alumno y actualiza la información del alumno seleccionado.
 * 
 * @param {string} name - Nombre del campo en el formulario para el ID del alumno.
 * @param {Object} alumnoProp - Propiedad inicial del alumno, puede ser nulo o un objeto de alumno.
 * @param {Function} setValue - Función de react-hook-form para establecer el valor de un campo del formulario.
 * @param {Function} onAlumnoSelected - Callback cuando se selecciona un nuevo alumno.
 * @param {Function} clearErrors - Función de react-hook-form para limpiar errores de validación.
 * @param {Function} setHistorico - Función para actualizar el historial del alumno.
 * @returns {Object} Objeto que incluye la imagen del alumno, referencias y manejadores de eventos.
 */
function useLogic(name, alumnoProp, setValue, onAlumnoSelected, clearErrors, setHistorico) {
    const alumnoModal = useRef()
    const [alumno, setAlumno] = useState(alumnoProp ?? null)

    useEffect(() => {
        if (alumnoProp != alumno)
            setAlumno(alumnoProp)
    }, [alumnoProp])

    const { imageSrc } = useImageLoader(alumno, 'foto_logo', DEFAULT_IMAGES_URL.alumno, true)

    /**
     * Función `handleDeleteAlumno` se encarga de gestionar la eliminación de la selección del alumno actual.
     * Limpia la información del alumno seleccionado, el historial asociado y restablece los campos relevantes
     * del formulario, utilizando las funciones proporcionadas por `react-hook-form`.
     */
    const handleDeleteAlumno = () => {
        if (onAlumnoSelected)
            onAlumnoSelected(alumno)
        if (setHistorico)
            setHistorico({})
        setAlumno(null)
        if (setValue)
            setValue(name, "")
    };

    // useEffect para manejar lógica después de que 'alumno' se actualice a null
    useEffect(() => {
        if (alumno === null) {
            // Suponiendo que 'setValue' y 'clearErrors' son funciones pasadas como props
            if (clearErrors) clearErrors(name);
            setValue(name, "");
        }
    }, [alumno, clearErrors, name, setValue]); // Dependencias del efecto

    /**
     * Función `handleAlumnoSelected` se encarga de actualizar el estado con el alumno seleccionado
     * y notificar al componente padre. Cierra el modal de selección de alumno y ejecuta el callback
     * proporcionado para manejar el alumno seleccionado.
     * 
     * @param {Object} alumno - Objeto con la información del alumno seleccionado.
     */
    const handleAlumnoSelected = (alumno) => {
        if (alumnoModal.current && alumnoModal.current.modal) {
            alumnoModal.current.modal.close(); // Asegúrate de que la implementación de tu modal soporte esta llamada.
        }

        setAlumno(alumno); // Actualiza el estado del alumno
        setValue(name, alumno?.id, { shouldDirty: true })

        // Aquí, en vez de un callback, simplemente seguimos con la lógica deseada después de actualizar el estado.
        if (onAlumnoSelected)
            onAlumnoSelected(alumno)
    }

    return { imageSrc, alumnoModal, alumno, handleDeleteAlumno, handleAlumnoSelected }
}

/**
 * Componente AlumnoPicker para seleccionar un alumno de una lista y mostrar su información.
 * Permite editar la selección y eliminar al alumno seleccionado. Utiliza un modal para la selección.
 * 
 * @param {Object} props - Propiedades del componente incluyendo callbacks y configuración de visualización.
 * @returns {JSX.Element} Estructura del selector de alumno con imagen y datos básicos.
 */
export default function AlumnoPicker({ alumno: alumnoProp, name, register, setValue, errors, clearErrors, setHistorico, onAlumnoSelected, deleteLabel, hiddenFieldStyles, relations, label, canEdit, editLabel, addLabel }) {
    const { imageSrc, alumnoModal, alumno, handleDeleteAlumno, handleAlumnoSelected } = useLogic(name, alumnoProp, setValue, onAlumnoSelected, clearErrors, setHistorico)
    return (
        <>
            <Box display={"flex"}>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} order={1}>
                    <H3 className={"m-0"}>{label ?? ""}</H3>
                    {canEdit ? (
                        <Stack direction={"row"} spacing={2}>
                            <Button size={"small"} color={"success"} startIcon={alumno ? <Edit /> : <Add />} onClick={() => alumnoModal.current.modal.open()}>{alumno ? (editLabel ?? "") : (addLabel ?? "")}</Button>
                            {/* 20/03/2024 Se ha comentado el botón de eliminar alumno por petición de Samuel */}
                            {/* <Button
                                size={"small"}
                                color={"cancel"}
                                startIcon={<Delete htmlColor='white' />}
                                disabled={alumno ? false : true}
                                onClick={handleDeleteAlumno}
                            >{deleteLabel ?? ""}</Button> */}
                        </Stack>
                    ) : null}
                </Box>

                <TextField
                    type={"hidden"}
                    value={alumno ? alumno.id : ""}
                    style={hiddenFieldStyles ?? null}
                    variant={"standard"}
                    error={errors && errors[name] ? true : false}
                    helperText={errors && errors[name]?.message}
                    {...register ? register(name) : {}} />

                {alumno != null ? (
                    <Box display={"flex"} alignItems={"center"} mt={2}>
                        <Avatar
                            alt={"Foto de: " + (alumno.nombre ?? "")}
                            src={imageSrc}
                            sx={{ width: "20%", height: "100%", borderRadius: 2 }} />

                        <Stack mx={2}>
                            <Text fontWeight={"bold"}>{(alumno.nombre ?? "") + " " + (alumno.apellido1 ?? "") + " " + (alumno.apellido2 ?? "")}</Text>
                            <Text>Correo: {alumno.email ?? ""}</Text>
                            <Text>Telefonos fijos: {alumno.telefono1 ?? ""} {alumno.telefono2 ?? ""}</Text>
                            <Text>Telefonos móviles: {alumno.tlfno_movil1 ?? ""} {alumno.tlfno_movil2 ?? ""}</Text>
                            <Text>Curso colegio: {alumno?.curso_actual?.curso_colegio?.valor ? alumno?.curso_actual?.curso_colegio?.valor : 'Sin asignar'}</Text>
                        </Stack>
                    </Box>
                ) : null}
            </Box>

            <SearchAlumnoModal
                ref={alumnoModal}
                relations={relations}
                onSelectedCallback={handleAlumnoSelected}
            />
        </>
    )
}