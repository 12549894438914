import { RequestDatosColegio } from "objects/requests"

/**
 * Esta clase encapsula las peticiones HTTP relacionadas con los datos del colegio de los alumnos.
 * Permite realizar operaciones como editar los datos del colegio de un alumno y recuperar dichos datos.
 */
export default class Requests {

    /**
     * Envía una petición para editar los datos del colegio asociados a un alumno específico.
     * 
     * @param {Int} idDatosColegio - El identificador único de los datos del colegio que se van a editar.
     * @param {Function} getValues - Una función que se utiliza para obtener los valores de los campos del formulario.
     *                               Esta función debe retornar el valor del campo especificado.
     * @param {Function} callbackOk - Función que se ejecuta si la petición se completa exitosamente.
     *                                Se espera que maneje la respuesta positiva de la petición.
     * @param {Function} callbackError - Función que se ejecuta si la petición falla.
     *                                   Se espera que maneje los errores o la respuesta negativa de la petición.
     * 
     * La función crea un objeto FormData y adjunta a este los valores obtenidos de la función getValues.
     * Luego, hace una llamada a la API utilizando una instancia de RequestDatosColegio, pasando el ID de los datos
     * del colegio, el objeto FormData, y las funciones de callback para manejar la respuesta.
     */
    editDatosColegio(idDatosColegio, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()
        // Se agregan los campos del formulario al objeto formData.
        formData.append("nota_final", getValues("nota_final").replace(',', "."))
        formData.append("num_curso", getValues("num_curso").toUpperCase())
        formData.append("id_colegio", getValues("id_colegio"))
        formData.append("id_curso_colegio", getValues("id_curso_colegio"))
        formData.append("id_curso_escolar", getValues("id_curso_escolar"))
        formData.append("libro_id", getValues("libro_id"))

        new RequestDatosColegio().edit(idDatosColegio, formData, callbackOk, callbackError)
    }

    /**
     * Obtiene los detalles de los datos del colegio de un alumno por su ID.
     * 
     * @param {Int} id - El identificador único de los datos del colegio que se desean obtener.
     * @param {Function} callbackOk - Función que se ejecuta si la petición se completa exitosamente,
     *                                pasando como argumento los datos obtenidos.
     * @param {Function} callbackError - Función que se ejecuta si la petición falla,
     *                                   pasando como argumento los detalles del error.
     * 
     * La función construye un objeto de parámetros especificando las relaciones que se desean incluir
     * en los datos del colegio obtenidos. Luego, hace una llamada a la API utilizando una instancia
     * de RequestDatosColegio, pasando el ID de los datos del colegio y las funciones de callback para
     * manejar la respuesta.
     */
    getDatosColegio(id, callbackOk = () => { }, callbackError = () => { }) {
        const params = { relations: 'alumno,colegio,curso_escolar,libro' }
        new RequestDatosColegio().get(id, params, (res) => res.item ? callbackOk(res.item) : callbackError(res))
    }

}
