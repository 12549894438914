import React, { useState } from 'react';
import { Box, Grid } from "@mui/material";
import { Datagrid, Link } from 'components/data_display';
import { CursoEscolarSelect } from 'components/forms/components/Select/custom';
import { Text } from 'components/texts';


//IMPORTANTE TODO: CAMBIAR HORASEXTRA POR FALTAS-ASISTENCIA
const texto = {
    marginBottom: '30px',
    marginRight: '20px',
};

const columns1 = [
    { field: "id", headerName: "ID", maxWidth: 100 },
    { field: "fecha", headerName: "Fecha", flex: 1, maxWidth: 100 },
    { field: "motivo", headerName: "Motivo", flex: 1, maxWidth: 650 },
    { field: "justificante", headerName: "Justf.", flex: 1, maxWidth: 65 },
    { field: "horas", headerName: "Horas", flex: 1, maxWidth: 65 },
    { field: "importe", headerName: "Importe", flex: 1, maxWidth: 100 },
    { field: "fecha_pago", headerName: "Fecha Pago", maxWidth: 100},
];

const columns2 = [
    { field: "id", headerName: "ID", maxWidth: 100 },
    { field: "centro", headerName: "Centro", flex: 1, maxWidth: 100 },
    { field: "grupo", headerName: "Grupo", flex: 1, maxWidth: 650 },
];


export default function FaltasAsistencia({ horasExtra = [], empleado, setValue, onChangeCursoEscolar }) {

    return (
        <Box sx={{ height: '100%', width: '100%', mt: 2, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <Grid item container xs={4}>
                    <CursoEscolarSelect 
                        label={"Curso escolar"}
                        name={"id_curso_escolar"}
                        onChange={({ target }) => onChangeCursoEscolar(target.value)}
                        defaultValue={null}
                        setValue={setValue}
                        searcheable />
                </Grid>
            </Box>

            <Grid container spacing={1} mt={2}>
                <Grid item xs={8} >
                    <Text style={texto}>Faltas de Asistencia:</Text>
                    <Datagrid
                        heightWithContent={400}
                        height={800}
                        rows={horasExtra}
                        columns={columns1}
                    />
                </Grid>

                <Grid item xs={4}>
                    <Text style={texto}>Grupos Faltados:</Text>
                    <Datagrid
                        heightWithContent={400}
                        height={800}
                        rows={horasExtra}
                        columns={columns2}
                    />
                </Grid>
            </Grid>

        </Box>
    );
}
