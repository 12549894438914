import * as Yup from 'yup'

const phoneRegex = "^[6-9][0-9]{8}$"

export const validationSchema = Yup.object().shape({
    nombre:            Yup.string().required('El nombre es obligatorio'),
    apellido1:         Yup.string().required('El primer apellido es obligatorio'),
    apellido2:         Yup.string().required('El segundo apellido es obligatorio'),
    email:             Yup.string().required('El email es obligatorio').email('El formato del email no es correcto.'),
    numero_documento:  Yup.string().required('El número de documento es obligatorio'),
    tipo_documento_id: Yup.string().required('El tipo de documento es obligatorio'),

    telefono_movil:    Yup.string().matches(phoneRegex, {message: 'El formato del teléfono es incorrecto', excludeEmptyString: true}),
    telefono_trabajo:  Yup.string().matches(phoneRegex, {message: 'El formato del teléfono es incorrecto', excludeEmptyString: true}),
    telefono_otros:    Yup.string().matches(phoneRegex, {message: 'El formato del teléfono es incorrecto', excludeEmptyString: true}),
})