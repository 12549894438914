import RequestMaster from '../RequestMaster'

export default class RequestHorario extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/horario'
    }

    /**
     * Crea un horario
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    create(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.post(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Elimina una definicion del horario
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    deleteDefinicionHorario(id, params = {}, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "definicion/" + id
        this.delete(path, new FormData(), callback, callbackError, callbackFinally)
    }
}