import React, { Component } from 'react'
import Skeleton from '@mui/material/Skeleton';
import { Grid } from '@mui/material';

/**
 * SKELETON
 */
export default class SkeletonPagosAlumnos extends Component {

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
      <Grid container px={5} spacing={2} mt={2}>

        <Grid item xs={6}>
          <Skeleton variant="rounded" width={"100%"} height={20} />
        </Grid>

        <Grid item xs={6}>
          <Skeleton variant="rounded" width={"100%"} height={20} />
        </Grid>

        <Grid item xs={4}>
          <Skeleton variant="rounded" width={"100%"} height={20} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rounded" width={"100%"} height={20} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rounded" width={"100%"} height={20} />
        </Grid>

        <Grid item xs={4}>
          <Skeleton variant="rounded" width={"100%"} height={20} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rounded" width={"100%"} height={20} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rounded" width={"100%"} height={20} />
        </Grid>

        <Grid item xs={12}>
          <Skeleton variant="rounded" width={"20%"} height={20} />
        </Grid>

        <Grid item xs={4}>
          <Skeleton variant="rounded" width={"100%"} height={20} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rounded" width={"100%"} height={20} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="rounded" width={"100%"} height={20} />
        </Grid>

        <Grid item xs={2}>
          <Skeleton variant="rounded" width={"100%"} height={20} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="rounded" width={"100%"} height={20} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="rounded" width={"100%"} height={20} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="rounded" width={"100%"} height={20} />
        </Grid>
        <Grid item xs={2}>
          <Skeleton variant="rounded" width={"100%"} height={20} />
        </Grid>
        <Grid item xs={8}>
          <Skeleton variant="rounded" width={"100%"} height={20} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rounded" width={"100%"} height={20} />
        </Grid>
      </Grid>
    )
  }
}
