import { RequestLibro, RequestPedidoLibro, RequestNivelLibro, RequestDevolucionLibro } from "objects/requests"

/**
 * Clase que concentra todas las peticiones relacionadas con libros.
 * Aquí se definen varios métodos para realizar operaciones CRUD (Crear, Leer, Actualizar, Eliminar)
 * sobre libros, pedidos de libros, devoluciones de libros y niveles de libros.
 */
export default class Requests {

    /**
     * Obtiene la información de un libro específico.
     * Realiza una petición GET para obtener los detalles de un libro, incluyendo sus relaciones y atributos.
     *
     * @param {Int} id - ID del libro a obtener.
     * @param {Function} callbackOk - Función de callback para una respuesta exitosa.
     * @param {Function} callbackError - Función de callback para manejar errores.
     */
    getLibro(id, callbackOk = () => { }, callbackError = () => { }) {
        new RequestLibro().get(id, { relations: 'pedidos_libro,devoluciones_libro,niveles_libro', atributtes: 'numVentasCurso' }, (res) => {
            res.libro ? callbackOk(res.libro) : callbackError(res)
        })
    }

    /**
     * Edita la información de un libro.
     * Recopila la información del formulario, la procesa y realiza una petición PUT para actualizar el libro.
     *
     * @param {number} id_libro - ID del libro a editar.
     * @param {Function} getValues - Función para obtener valores del formulario.
     * @param {Function} callbackOk - Función de callback para una respuesta exitosa.
     * @param {Function} callbackError - Función de callback para manejar errores.
     */
    editLibro(id_libro, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()
        formData.append("nombre", getValues("nombre").toUpperCase())
        formData.append("isbn", getValues("isbn").toUpperCase())
        formData.append("id_editorial", getValues("id_editorial"))
        formData.append("abreviatura", getValues("abreviatura").toUpperCase())
        formData.append("activo", getValues("activo") ? 1 : 0)
        formData.append("id_libro", getValues("id_libro"))
        formData.append("precio_editorial", getValues("precio_editorial"))
        formData.append("pvp", getValues("pvp"))

        formData.append("programacion_1t", getValues("programacion_1t")[0] ?? "")
        formData.append("programacion_2t", getValues("programacion_2t")[0] ?? "")
        formData.append("programacion_3t", getValues("programacion_3t")[0] ?? "")

        new RequestLibro().put(id_libro, formData, callbackOk, callbackError)
    }

    /**
     * Realiza una petición para crear nuevos registros de libros pedidos en la base de datos.
     * Filtra los datos obtenidos del formulario para incluir solo aquellos pedidos que son nuevos
     * y tienen especificadas las cantidades pedidas o llegadas. Luego, compone un objeto FormData
     * que es enviado a la API correspondiente para realizar la operación de creación múltiple.
     * 
     * @param Integer   $libroId        El ID del libro para el que se realizan los pedidos.
     * @param Function  $getValues      Función que obtiene los valores del formulario.
     * @param Function  $callbackOk     Función callback que se ejecuta si la petición es exitosa.
     * @param Function  $callbackError  Función callback que se ejecuta si ocurre un error en la petición.
     */
    createLibrosPedidos(libroId, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const librosPedidos = getValues("librosPedidos").filter(item => ((item.cantidad_llegada || item.cantidad_pedida) && item.deleted != true && item.new == true))

        if (librosPedidos.length > 0) {
            const formData = new FormData()

            librosPedidos.forEach((libro, index) => {
                formData.append(`pedidos[${index}][id_curso_escolar]`, libro.id_curso_escolar)
                formData.append(`pedidos[${index}][id_libro]`, libroId)
                formData.append(`pedidos[${index}][cantidad_pedida]`, libro.cantidad_pedida)
                formData.append(`pedidos[${index}][cantidad_llegada]`, libro.cantidad_llegada)
                formData.append(`pedidos[${index}][fecha]`, libro.fecha)
            })

            new RequestPedidoLibro().create(formData, callbackOk, callbackError)
        } else {
            callbackOk()
        }
    }

    /**
     * Realiza una petición para editar registros de libros pedidos.
     * Filtra los datos obtenidos del formulario para incluir solo aquellos pedidos que no han sido eliminados,
     * no son nuevos y están marcados para ser editados. Si existen pedidos que cumplen con estos criterios,
     * se compila un objeto FormData con la información de los pedidos y se envía una petición para editarlos.
     * 
     * @param Integer   $libroId        El ID del libro para el que se realizan los pedidos.
     * @param Function  $getValues      Función que obtiene los valores del formulario, esperando que devuelva un array de libros pedidos.
     * @param Function  $callbackOk     Función callback que se ejecuta si la petición es exitosa.
     * @param Function  $callbackError  Función callback que se ejecuta si ocurre un error en la petición.
     */
    editLibrosPedidos(libroId, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const librosPedidos = getValues("librosPedidos").filter(item => ((item.cantidad_llegada || item.cantidad_pedida) && item.deleted != true && item.new != true && item.edit == true))

        if (librosPedidos.length > 0) {
            const formData = new FormData()

            librosPedidos.forEach((libro, index) => {
                formData.append(`pedidos[${index}][id]`, libro.id)
                formData.append(`pedidos[${index}][id_curso_escolar]`, libro.id_curso_escolar)
                formData.append(`pedidos[${index}][id_libro]`, libroId)
                formData.append(`pedidos[${index}][cantidad_pedida]`, libro.cantidad_pedida)
                formData.append(`pedidos[${index}][cantidad_llegada]`, libro.cantidad_llegada)
                formData.append(`pedidos[${index}][fecha]`, libro.fecha)
            })

            new RequestPedidoLibro().edit(formData, callbackOk, callbackError)
        } else {
            callbackOk()
        }
    }

    /**
     * Realiza una petición para eliminar libros pedidos.
     * Filtra los datos obtenidos del formulario para incluir solo aquellos pedidos que están marcados como eliminados
     * y no son nuevos (es decir, ya existían en la base de datos). Si hay pedidos que cumplen con estos criterios,
     * prepara un objeto FormData con los IDs de los pedidos a eliminar y realiza una petición de eliminación.
     * 
     * @param {Function} getValues - Función que obtiene los valores del formulario, esperando que devuelva un array de libros pedidos.
     * @param {Function} callbackOk - Función callback que se ejecuta si la petición es exitosa.
     * @param {Function} callbackError - Función callback que se ejecuta si ocurre un error en la petición.
     */
    deleteLibrosPedidos(getValues, callbackOk = () => { }, callbackError = () => { }) {
        const librosPedidos = getValues("librosPedidos").filter(item => (item.deleted == true && item.new != true && item.edit != true))

        if (librosPedidos.length > 0) {
            const formData = new FormData()

            librosPedidos.forEach((libro) => {
                console.log('dentro foreach libros pedidos')
                formData.append(`pedidos[]`, libro?.id)
            })

            new RequestPedidoLibro().deleteMultiple(formData, callbackOk, callbackError)
        } else {
            callbackOk()
        }
    }

    /**
     * Realiza una petición para crear registros de libros devueltos.
     * Filtra los libros devueltos obtenidos del formulario para incluir solo aquellos que tienen una cantidad especificada,
     * no están marcados como eliminados y son nuevos (es decir, aún no existen en la base de datos). 
     * Si hay libros que cumplen con estos criterios, prepara un objeto FormData, incluyendo el ID del libro y 
     * los datos de los libros devueltos, y realiza una petición para crear los registros de devolución.
     * 
     * @param {number} libroId - ID del libro para el cual se crean los registros de devolución.
     * @param {Function} getValues - Función que obtiene los valores del formulario, esperando que devuelva un array de libros devueltos.
     * @param {Function} [callbackOk=() => {}] - Función callback que se ejecuta si la petición es exitosa.
     * @param {Function} [callbackError=() => {}] - Función callback que se ejecuta si ocurre un error en la petición.
     */
    createLibrosDevueltos(libroId, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const librosDevueltos = getValues("librosDevueltos").filter(item => (item.cantidad_devuelta && item.deleted != true && item.edit != true && item.new == true))

        if (librosDevueltos.length > 0) {
            const formData = new FormData()

            librosDevueltos.forEach((libro, index) => {
                formData.append(`devoluciones[${index}][id_curso_escolar]`, libro.id_curso_escolar)
                formData.append(`devoluciones[${index}][id_libro]`, libroId)
                formData.append(`devoluciones[${index}][cantidad_devuelta]`, libro.cantidad_devuelta)
                formData.append(`devoluciones[${index}][fecha]`, libro.fecha)
            })

            new RequestDevolucionLibro().create(formData, callbackOk, callbackError)
        } else {
            callbackOk()
        }
    }


    /**
     * Método para editar la información de libros devueltos.
     * 
     * Este método se encarga de procesar y enviar los datos de los libros devueltos a través de una petición. 
     * Utiliza tres funciones callback para obtener valores, manejar respuestas exitosas y errores.
     * 
     * @param {Number} libroId - ID del libro que se está editando.
     * @param {Function} getValues - Función para obtener los valores necesarios (en este caso, 'librosDevueltos').
     * @param {Function} callbackOk - Función de callback para manejar una respuesta exitosa (opcional).
     * @param {Function} callbackError - Función de callback para manejar errores (opcional).
     */
    editLibrosDevueltos(libroId, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const librosDevueltos = getValues("librosDevueltos").filter(item => (item.cantidad_devuelta && item.deleted != true && item.new != true && item.edit == true))

        if (librosDevueltos.length > 0) {
            const formData = new FormData()

            librosDevueltos.forEach((libro, index) => {
                formData.append(`devoluciones[${index}][id]`, libro.id)
                formData.append(`devoluciones[${index}][id_curso_escolar]`, libro.id_curso_escolar)
                formData.append(`devoluciones[${index}][id_libro]`, libroId)
                formData.append(`devoluciones[${index}][cantidad_devuelta]`, libro.cantidad_devuelta)
                formData.append(`devoluciones[${index}][fecha]`, libro.fecha)
            })

            new RequestDevolucionLibro().edit(formData, callbackOk, callbackError)
        } else {
            callbackOk()
        }
    }


    /**
     * Hace la petición para crear los libros pedidos
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    deleteLibrosDevueltos(getValues, callbackOk = () => { }, callbackError = () => { }) {
        const librosDevueltos = getValues("librosDevueltos").filter(item => (item.deleted == true && item.new != true && item.edit != true))

        if (librosDevueltos.length > 0) {
            const formData = new FormData()

            librosDevueltos.forEach((libro) => {
                formData.append(`devoluciones[]`, libro?.id)
            })

            new RequestDevolucionLibro().deleteMultiple(formData, callbackOk, callbackError)
        } else {
            callbackOk()
        }
    }


    /**
     * Hace la petición para actualizar los niveles
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    updateNiveles(id_libro, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()
        formData.append("id_libro", id_libro)

        formData.append("ids_niveles[]", "")
        for (const nivel of getValues("niveles"))
            formData.append("ids_niveles[]", nivel)

        new RequestNivelLibro().updateMultiple(formData, callbackOk, callbackError)
    }
}
