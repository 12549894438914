import { Grid } from '@mui/material'
import React, { Component } from 'react'
import Skeleton from '@mui/material/Skeleton'

/**
 * Informacion básica del alumno
 */
export default class SkeletonAlumno extends Component {

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {

    return (
        <Grid container spacing={2} mt={2}>
            <Grid item xs={2}>
                <Skeleton variant="rounded" width={"80%"} height={"100%"}/>
            </Grid>
            <Grid item xs={8}>
                <Skeleton variant="text" sx={{ fontSize: '2.5em' }} width={"20%"}/>
                <Skeleton variant="text" sx={{ fontSize: '2em' }}   width={"30%"}/>
                <Skeleton variant="text" sx={{ fontSize: '1em' }}   width={"30%"}/>
            </Grid>
        </Grid>
    )
  }
}
