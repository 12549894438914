import { Component } from 'react'
import { RequestResponsable } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests extends Component {

    /**
     * Hace la petición para crear el responsable
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createResponsable(getValues, callbackOk = () => {}, callbackError = () => {}) {
        var formData = new FormData()
        formData.append("nombre",             getValues("nombre").toUpperCase())
        formData.append("apellido1",          getValues("apellido1").toUpperCase())
        formData.append("apellido2",          getValues("apellido2").toUpperCase())
        formData.append("email",              getValues("email"))
        formData.append("numero_documento",   getValues("numero_documento").toUpperCase())
        formData.append("tipo_documento_id",  getValues("tipo_documento_id"))
        formData.append("profesion",          getValues("profesion").toUpperCase())
        formData.append("telefono_movil",     getValues("telefono_movil"))
        formData.append("telefono_trabajo",   getValues("telefono_trabajo"))
        formData.append("telefono_otros",     getValues("telefono_otros"))
        if(getValues("foto_logo")[0])
            formData.append("foto_logo",     getValues("foto_logo")[0])
        
        new RequestResponsable().create(formData, callbackOk, callbackError)
    }
}
