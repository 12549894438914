import React, { useEffect } from 'react'
import { MenuItem, MenuItemGroup } from 'components/navigation'
import { useLocation } from 'react-router-dom'
import {
    Announcement as IncidenciaIcon,
    ContentPaste as HistorialIcon,
    RememberMe as FichajeIcon,
    NaturePeople as Persona,
    Hail as Empleado,
} from '@mui/icons-material'

export default function EmpleadoMenu() {
    const location = useLocation()
    const currentPath = location.pathname

    const defaultOpen = currentPath === '/empleado/fichajes/historial' || currentPath === '/empleado/fichajes/incidencias'

    useEffect(() => {
        window.KTMenu?.createInstances()
        window.KTToggle?.createInstances()
    }, [])

    return (
        <div className="menu menu-column menu-rounded menu-sub-indention px-3" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">
            <div className="menu-item pt-5">
                <div className="menu-content">
                    <span className="menu-heading fw-bold text-uppercase fs-7">Menú empleados</span>
                </div>
            </div>
            <MenuItem title="Empleados" to="./ficha-empleados" icon={<Empleado />} />
            <MenuItemGroup title="Fichaje" icon={<FichajeIcon />} defaultOpen={defaultOpen} >
                <MenuItem title="Historial de fichajes" to="./fichajes/historial" icon={<HistorialIcon />} />
                <MenuItem title="Incidencias" to="./fichajes/incidencias" icon={<IncidenciaIcon />} />
            </MenuItemGroup>
            <MenuItem title="Ficha Candidato" to="./ficha-candidato" icon={<Persona />} />

        </div>
    )
}