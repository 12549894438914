import React, { useContext, useRef, useState } from 'react'
import { ChangesContext } from 'contexts/ChangesContext'
import { WarningNotSavedModal } from 'components/modals'
import { Stack, ButtonGroup, Button } from '@mui/material'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { KeyboardArrowLeft, KeyboardDoubleArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowRight } from '@mui/icons-material'

const PAGE_SIZE = 25

/**
 * Proporciona botones de navegación para moverse entre ítems en un contexto paginado.
 * Incluye verificaciones para cambios no guardados antes de permitir la navegación.
 *
 * @param {Object} props Las propiedades pasadas al componente.
 * @param {number} props.actualItemId El ID del ítem actualmente visualizado.
 * @param {Object} props.context El contexto que se utilizará para la navegación. Por defecto, se usa EmpleadoListContext.
 * @param {Function} props.beforeFetchCallback Función que se ejecuta antes de realizar una nueva búsqueda.
 * @param {Function} props.onUpdateActualItem Función para actualizar el ítem actual basado en la navegación.
 */
export default function MoveButtons({
  actualItemId,
  context = EmpleadoListContext,
  beforeFetchCallback = () => { },
  onUpdateActualItem = () => { },
}) {
  const { updateChanges, hasChanges } = useContext(ChangesContext)
  const { rowCount, items, page, setPage, fetchByPage } = useContext(context)

  const [continueCallback, setCallback] = useState(() => { })

  const modalRef = useRef()

  const fetchNew = (newPage, first = true) => {
    beforeFetchCallback()

    fetchByPage(newPage, (items) => {
      updateItem(items[first ? 0 : items.length - 1].id)
    })
  }

  /**
   * Actualiza el ítem actual basado en el ID proporcionado y ajusta la página actual si es necesario.
   * Esta función es esencial para mantener la coherencia de la interfaz de usuario al navegar entre ítems,
   * asegurando que el ítem visualizado y la paginación se mantengan sincronizados.
   *
   * @param {number} id El ID del ítem al que se quiere navegar.
   */
  const updateItem = (id) => {
    onUpdateActualItem(id)

    const index = items.findIndex((item) => item?.id === id)
    if (index !== -1) {
      const page = Math.ceil(index / PAGE_SIZE)
      setPage(page && page !== 0 ? page : 1)
    }
  }

  /**
   * Navega al ítem anterior en la lista. Si el ítem actual es el primero en la página actual,
   * intentará cargar la página anterior y seleccionar el último ítem de esa página.
   */
  const previousItem = () => {
    const index = items.findIndex(item => item?.id == actualItemId)
    const item = items[index - 1]
    !item ? fetchNew(page - 1, false) : updateItem(item.id)
  }

  /**
   * Navega al siguiente ítem en la lista. Si el ítem actual es el último en la página actual,
   * intentará cargar la página siguiente y seleccionar el primer ítem de esa página.
   */
  const nextItem = () => {
    const index = items.findIndex(item => item?.id == actualItemId)
    const item = items[index + 1]
    !item ? fetchNew(page + 1) : updateItem(item.id)
  }

  /**
   * Navega al último ítem disponible en la lista, cargando la última página si es necesario.
   */
  const goToLast = () => {
    const lastItem = items[rowCount - 1]
    !lastItem ? fetchNew(Math.ceil(rowCount / PAGE_SIZE), false) : updateItem(lastItem.id)
  }

  /**
   * Navega al primer ítem de la lista, seleccionándolo directamente ya que siempre estará en la primera página.
   */
  const goToFirst = () => {
    updateItem(items[0].id)
  }

  /**
   * Verifica si hay cambios no guardados antes de ejecutar una acción de navegación.
   * Si hay cambios pendientes, muestra un modal de advertencia. Si no, ejecuta la acción directamente.
   *
   * @param {Function} callback La acción a ejecutar después de verificar los cambios no guardados.
   */
  const checkHasChanges = (callback) => {
    if (hasChanges) {
      setCallback(() => callback)
      modalRef.current.modal.open()
    } else {
      callback()
    }
  }

  const index = items.findIndex(item => item?.id == actualItemId)
  return (
    <React.Fragment>
      {items.length > 0 ?
        <Stack direction="row" justifyContent={"space-between"}>
          <ButtonGroup variant="contained" aria-label="text button group">
            <Button disabled={(index == 0)} onClick={() => checkHasChanges(goToFirst)}>
              <KeyboardDoubleArrowLeft />
            </Button>

            <Button disabled={(index == 0)} onClick={() => checkHasChanges(previousItem)}>
              <KeyboardArrowLeft />
            </Button>

            <Button disabled={(index + 1 == rowCount)} onClick={() => checkHasChanges(nextItem)}>
              <KeyboardArrowRight />
            </Button>

            <Button disabled={(index + 1 == rowCount)} onClick={() => checkHasChanges(goToLast)}>
              <KeyboardDoubleArrowRight />
            </Button>
          </ButtonGroup>
        </Stack>
        : null}

      <WarningNotSavedModal
        ref={modalRef}
        onGoBack={() => { modalRef.current.modal.close() }}
        onContinue={() => {
          modalRef.current.modal.close()
          updateChanges(false)
          continueCallback()
        }} />
    </React.Fragment>

  )
}
