import { DataFormInscripcionClasesParticulares, DataFormPagoClasesParticulares, DataFormLibroClasesParticulares, DataFormAnotacionesClasesParticulares } from 'components/forms/display'
import { Box, Container, Paper, Tab, Tabs } from '@mui/material'
import { TabPanel } from 'components/modifications/mui/TabPanel'
import { BreadcrumbsList, Line } from 'components/data_display'
import { useClaseParticularCreateLogic } from 'hooks/index.js'
import { CreateHeader } from 'pages/logged/admin/components'
import Requests from './Requests/Requests.js'
import { SnackbarProvider } from 'notistack'
import { Text } from 'components/texts'
import { COLOR } from 'theme/Colors.js'
import { TabContext } from '@mui/lab'

/**
 * Componente para la página de creación de clases particulares.
 * Presenta un formulario con múltiples pestañas para ingresar los detalles de la clase,
 * pagos, libros y anotaciones. Utiliza el hook personalizado useLogic para su lógica y estado.
 * 
 * @returns {JSX.Element} El JSX del componente.
 */
export default function CreateClasesParticulares() {
    const {alumnoSelected, setAlumnoSelected, operadorName, header, tab, setTab, isMounted, register, handleSubmit, errors, getValues, setValue, control, clearErrors, createClaseParticular, onError } = useClaseParticularCreateLogic({ requests: new Requests() })

    const onAlumnoSelected = (alumno) => {
        setAlumnoSelected(alumno)
      }

    return (
        <Container maxWidth={false} sx={{ mt: 5 }}>
            <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(createClaseParticular, onError)}>
                <BreadcrumbsList breadcrumbsList={[
                    { to: "/", label: "Inicio" },
                    { to: "./../", label: "Clases particulares" },
                    { to: "./", label: "Crear clase particular", actualItem: true },
                ]} />

                <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
                    <CreateHeader
                        ref={header}
                        title={"Crear clase particular"} />

                    <Line />

                    <TabContext value={tab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
                            <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                                <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>DATOS DE LA INSCRIPCIÓN</Text>} value={"1"} />
                                <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>DATOS DE LOS PAGOS</Text>} value={"2"} />
                                <Tab label={<Text fontWeight={tab === "3" ? "bold" : null} color={tab === "3" ? COLOR.standard.main : null}>LIBROS</Text>} value={"3"} />
                                <Tab label={<Text fontWeight={tab === "4" ? "bold" : null} color={tab === "4" ? COLOR.standard.main : null}>ANOTACIONES</Text>} value={"4"} />
                                <Tab label={<Text fontWeight={tab === "5" ? "bold" : null} color={tab === "5" ? COLOR.standard.main : null}>HORARIOS</Text>} value={"5"} disabled /> {/**Añadido el TAB de horarios disabled como indica samuel */}
                            </Tabs>
                        </Box>

                        <TabPanel value={"1"}>
                            <DataFormInscripcionClasesParticulares
                                operador={operadorName}
                                setValue={setValue}
                                getValues={getValues}
                                register={register}
                                errors={errors}
                                clearErrors={clearErrors}
                                isMounted={isMounted}
                                control={control}
                                onAlumnoSelected={onAlumnoSelected} />
                        </TabPanel>

                        <TabPanel value={"2"}>
                            <DataFormPagoClasesParticulares
                                setValue={setValue}
                                register={register}
                                getValues={getValues}
                                errors={errors}
                                clearErrors={clearErrors}
                                isMounted={isMounted}
                                control={control}
                                alumno={alumnoSelected} />
                        </TabPanel>

                        <TabPanel value={"3"}>
                            <DataFormLibroClasesParticulares
                                setValue={setValue}
                                register={register}
                                getValues={getValues}
                                errors={errors}
                                clearErrors={clearErrors}
                                isMounted={isMounted}
                                control={control} />
                        </TabPanel>

                        <TabPanel value={"4"}>
                            <DataFormAnotacionesClasesParticulares
                                setValue={setValue}
                                register={register}
                                getValues={getValues}
                                errors={errors}
                                clearErrors={clearErrors}
                                isMounted={isMounted}
                                control={control} />
                        </TabPanel>
                    </TabContext>
                </Paper>

                <SnackbarProvider autoHideDuration={3000} />
            </form>
        </Container>
    )
}