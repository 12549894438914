import React, { createContext, useState } from 'react';

// Proporciona un objeto de valor por defecto que coincida con la estructura que tu componente espera
const CursoEscolarContext = createContext({
  cursoEscolar: null,
  setCursoEscolar: () => {}
});

const CursoEscolarProvider = ({ children }) => {
  const [cursoEscolar, setCursoEscolar] = useState(null);
  
  // El valor del contexto se pasa a través del value prop al Provider
  return (
    <CursoEscolarContext.Provider value={{ cursoEscolar, setCursoEscolar }}>
      {children}
    </CursoEscolarContext.Provider>
  );
};

// Exporta el contexto y el proveedor para su uso en otros componentes
export { CursoEscolarProvider, CursoEscolarContext };
