import { Grid } from "@mui/material";
import { Text } from "components/texts";

export default function DevolucionesHeader() {
    return (
        <Grid container px={5}>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={12} display={"flex"} alignItems={"center"}>
                    <Text>Se devolverán todos los libros en Stock. ¿Quiere realizar la devolución?</Text>
                </Grid>
            </Grid>
        </Grid>
    )
}