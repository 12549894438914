import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import Statics from "objects/common/Statics"
import { useContext, useRef, useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { ChangesContext } from "contexts/ChangesContext"
import { useAlert, useCheckDirtyFields, useErrorHandler } from "hooks"

/**
 * Hook personalizado para encapsular la lógica de edición de un grupo.
 * Este hook se encarga de inicializar el formulario, manejar la navegación, y ejecutar las operaciones de edición
 * mediante peticiones al servidor. Utiliza el contexto de cambios para gestionar el estado global de la aplicación.
 *
 * @param {Object} params - Objeto que contiene parámetros y funciones necesarios para la lógica de edición.
 * 
 * @param {Object} params.grupo - Información del grupo actual que se está editando.
 * @param {number} params.id_grupo - ID del grupo que se está editando.
 * @param {Function} params.setIdGrupo - Función para actualizar el ID del grupo en el estado global.
 * @param {Function} params.onSaveFinish - Callback que se ejecuta tras completar la operación de guardado exitosamente.
 * @param {Object} params.requests - Objeto que contiene funciones para realizar peticiones HTTP relacionadas con la edición del grupo.
 * @param {Object} params.validationSchema - Esquema de validación de Yup para validar los datos del formulario.
 *
 * @returns {Object} - Objeto que contiene varias propiedades y funciones que pueden ser utilizadas por los componentes.
 */
export default function useLogic({ grupo, id_grupo, setIdGrupo, onSaveFinish, requests, validationSchema }) {
    const upadteAlert = useAlert()

    const navigate = useNavigate()

    const handleError = useErrorHandler()

    const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, setValue, control, reset } = useForm({ resolver: yupResolver(validationSchema) })

    const { updateChanges } = useContext(ChangesContext)

    const [libro, setLibro] = useState(grupo?.libro ?? -1)

    const headerRef = useRef()

    useCheckDirtyFields(dirtyFields, updateChanges)

    /**
     * Función para enviar los datos editados del grupo al servidor. Maneja las respuestas de éxito y error,
     * actualiza el estado de cambios y muestra alertas apropiadas.
     *
     * @param {Object} values - Los valores recopilados del formulario para ser enviados al servidor.
     */
    const editGrupo = (values) => {
        headerRef.current.button.setLoading(true)
        requests.getGrupo(id_grupo, (res) => {
            const tieneProfesor = res.profesor_actual
            requests.editGrupo(id_grupo, values, (res) => {
                updateChanges(false);
                headerRef.current.button.setLoading(false);
                upadteAlert('Se ha editado el grupo correctamente.', 'success');
                updateId(id_grupo);
                handlerGrupoEmpleado(tieneProfesor, values)
            }, (err) => {
                headerRef.current.button.setLoading(false);
                handleError(err);
            });
        }, (err) => {
            // Manejar error si la obtención del grupo falla
            handleError(err);
        });
    }

    /**
     *  Función encargada de gestionar el metodo post o put de un grupo, se encarga de comprobar si el grupo ya tenia un profesor asignado para poder updatear sus datos o borrarlos,
     *  en caso de que el grupo no tuviera ningun profesor se lo inserta con el metodo post. 
     * 
     *  Para esto comprueba con la variable tieneProfesor, que sale de una petición get del grupo,
     *  en el caso de que esta variable sea null y el values.empleado_id venga relleno quiere decir que quieren añadir un profesor por lo tanto hacemos un insert
     * 
     *  En el caso de ir al else if comprueba que ese grupo tenga un profesor asignado en el grupo, esta condición es menos restrictiva porque cuando quieren borrar un profesor el empleado_id viene null, entonces si por
     *  ese lado es false pero ese grupo tiene un profesor quiere decir que lo quieren borrar por lo tanto tienen que hacer la request de edit para eliminarlo.
     * @param {*} tieneProfesor 
     * @param {*} values 
     */
    const handlerGrupoEmpleado = (tieneProfesor, values) => {
        if (tieneProfesor == null && values.empleado_id != "") {
            requests.createEmpleadoGrupo(id_grupo, values.empleado_id, () => {
                upadteAlert('Se ha añadido el profesor al grupo correctamente.', 'success');
            }, (err) => {
                handleError(err)
            })

        } else if (values.empleado_id != "" || tieneProfesor != null) {

            requests.editEmpleadoGrupo(grupo, values, () => { }, handleError, () => {
                headerRef.current.button.setLoading(false);
                onSaveFinish();
            });
        }
    }

    /**
     * Actualiza el ID del grupo en la URL y el estado local para reflejar la navegación a otro grupo.
     *
     * @param {Number} id - El nuevo ID del grupo para actualizar en la URL y el estado local.
     */
    const updateId = async (id) => {
        // navigate(`../academias/grupos/${id}/editar`)
        await Statics.delay(3000).then(() => { navigate(`../academias/grupos/${id} `) }) // URL para redirigir a Single
        setIdGrupo(id)
    }

    return { libro, setLibro, headerRef, register, handleSubmit, errors, getValues, setValue, control, updateId, editGrupo }
}