import { CursoEscolarSelect } from "components/forms/components/Select/custom"
import { itemsActual } from "../searchAndOrderOptions"
import { Select } from 'components/forms/components'
import { Input } from "components/forms/components"
import { Text } from "components/texts"
import { Grid } from "@mui/material"
import { Component } from "react"



export default class ClasesParticularesAlumnosTemplate extends Component {
  /**
   * Constructor
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props)
  }

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    const { register, errors, setValue } = this.props

    return (
      <>
        <Grid container mt={2} mb={1}>
          <Text fontWeight={"bold"}>Según datos de:</Text>
          <br />
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Input
              label="Nombre"
              width={"100%"}
              name={"nombre_alumno"}
              register={register}
              errors={errors}
              uppercase
            />
          </Grid>

          <Grid item xs={6}>
            <Input
              label="Primer apellido"
              width={"100%"}
              name={"apellido1_alumno"}
              register={register}
              errors={errors}
              uppercase
            />
          </Grid>

          <Grid item xs={6}>
            <Input
              label="Segundo apellido"
              width={"100%"}
              name={"apellido2_alumno"}
              register={register}
              errors={errors}
              uppercase
            />
          </Grid>

          <Grid item xs={6}>
            <CursoEscolarSelect name={"curso_escolar_id"} wantToReset={this.props.reset} register={register} errors={errors} defaultValue={null} setValue={setValue} searcheable />
          </Grid>
          <Grid item xs={4}>
            <Select register={register} errors={errors} name="actual" items={itemsActual} defaultValue={itemsActual[0].value} />
          </Grid>
        </Grid>
      </>
    )
  }
}
