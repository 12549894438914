import { useContext, useEffect, useState } from "react"
import { EmpleadoListContext } from "contexts/employee/ListContext"

/**
 * Hook personalizado para gestionar la lógica y el estado de las listas de aulas en un contexto de empleados.
 * Utiliza el contexto `EmpleadoListContext` para acceder y manipular el estado global relacionado con las listas de empleados,
 * adaptándolo para el uso específico con aulas. Proporciona funcionalidades para actualizar la tabla y manejar el estado de carga.
 */
export default function useListAulaLogic() {
    const [loadingTable, setLoadingTable] = useState(false)

    const { items, rowCount, params, update } = useContext(EmpleadoListContext)

    useEffect(() => {
        setLoadingTable(false)
    }, [items])

    /**
     * Actualiza los datos de la tabla y el contexto global con los resultados obtenidos de la búsqueda.
     * 
     * @param {Array} data - Los datos obtenidos para llenar la tabla.
     * @param {number} rowCount - El número total de filas encontradas para la búsqueda realizada.
     * @param {number} page - La página actual de los resultados.
     * @param {Object} params - Los parámetros de búsqueda utilizados.
     */
    const updateTable = (newData, newRowCount, newPage, newParams) => update(newRowCount, newData, newParams, newPage)

    return { items, rowCount, params, updateTable, setLoadingTable, loadingTable }
}