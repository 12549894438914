import { Navigate } from 'react-router-dom'
import { alumnoRoutes, employeeRoutes } from '../routes'
import { useSelector } from 'react-redux'

const NotLoggedRouter = ({ children }) => {
    const user   = useSelector((state) => state.userState.user)
    const roles  = useSelector((state) => state.userState.roles)
    const token  = useSelector((state) => state.userState.token)

    if(user && roles.length > 0 && token) {
        if(roles.includes('alumno'))
            return <Navigate to={alumnoRoutes.HOME} />

        if(roles.includes('profesor') || roles.includes('admin') || roles.includes('personal-oficina'))
            return <Navigate to={employeeRoutes.HOME} />
    }
    
    return children
}

export default NotLoggedRouter