import RequestMaster from '../RequestMaster'

export default class RequestEmpleado extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/empleado'
    }

    /**
     * Hace el logueo del responsable
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    search(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.get(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Crea un empleado
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    create(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.post(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Crea curriculum de empleado
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    createCurriculum(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "curriculum"
        this.post(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Crea el detalle del curriculum
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    createCurriculumDetalle(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "curriculum/detalle"
        this.post(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Edita el detalle del curriculum
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    editCurriculumDetalle(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "curriculum/detalle/update"
        this.put(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Crea horas extra
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    createHorasExtra(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "horas-extra"
        this.post(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Crea partidos del empleado
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    createPartidos(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "partido"
        this.post(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * obtiene nombre de caracteristica curriculum
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    getCaracteristicasCurriculum(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "curriculum/caracteristicas"
        this.get(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * obtiene las horas extra
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */

    getHorasExtra(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "horas-extra"
        this.get(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * obtiene los grupos del empleado
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */

    getGruposEmpleado(id, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = `${id}/grupos`
        this.get(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * obtiene las sustituciones del empleado
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    getSustitucion(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "sustitucion"
        this.get(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * obtiene las sustituciones del empleado
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    createSustituciones(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "sustitucion"
        this.post(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * obtiene las sustituciones del empleado
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    editSustitucion(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "sustitucion/update"
        this.put(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * obtiene las sustituciones del empleado
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    getPago(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "pago"
        this.get(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * obtiene partido empleado
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */

    getPartido(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "partido"
        this.get(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * obtiene nombre de caracteristica curriculum
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    getContratoEmpleado(id, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = `contrato/${id}`
        this.get(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * obtiene nombre de caracteristica curriculum
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    getCurriculum(id, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "curriculum/" + id
        this.get(path, params, callback, callbackError, callbackFinally)
    }



    /**
     * obtiene Listado getListadosGruposProfesor
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    getListadosGruposEmpleado(id, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = `listados/${id}/grupos`

        this.pdf(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * obtiene Listado getHorario
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    getHorarioEmpleado(id, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = `listados/${id}/horario`

        this.pdf(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Hace el edit del empleado
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    edit(id, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "" + id
        this.put(path, params, callback, callbackError, callbackFinally)
    }

    editCurriculum(id, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "curriculum/" + id
        this.put(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Hace el edit de las horas extra
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    editHorasExtra(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "horas-extra/update"
        this.put(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Hace el edit de los partidos del empleado
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    editPartidos(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "partido/update"
        this.put(path, params, callback, callbackError, callbackFinally)
    }
}
