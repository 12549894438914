import { Alert, Box, CircularProgress, Grid, Stack } from '@mui/material'
import React, { Component } from 'react'
import { v4 as uuid } from 'uuid';
import { Line } from '../../data_display';
import { Button } from '../../forms/components'
import SearchFormResponsable, { search } from '../../forms/display/responsable/SearchForm'
import { H3, Text } from '../../texts';

/**
 * Componente que nos muestra un listado con el listado de los responsables para poder elegir uno
 */
export default class ResponsableListSelector extends Component {

    constructor(props) {
        super(props)
        
        this.state = {
            responsables: [],
            searched: false,
            actualPage: 1,
            totalResults: 0,
            loading: false,
        }
    }


    /**
     * Callback para cuando se completa la búsqueda
     * 
     * @param {Array} responsables
     */
    searchCallback = (responsables, totalResults) => {
        this.setState({responsables, searched: true, actualPage: this.state.actualPage + 1, totalResults, loading: false}, () => {
            this.scrollable.scrollTo(0, 0)
        })
    }


    /**
     * Callback para cuando se elige un responsable de la lista
     * 
     * @param {Object} responsable
     */
    onSelectedCallback = (responsable) => {
        if(this.props.onSelectedCallback)
            this.props.onSelectedCallback(responsable)
    }


    /**
     * Se encarga de controlar el scroll
     * 
     * @param {Event} e 
     */
    handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if(bottom && this.state.totalResults != this.state.responsables.length && !this.state.loading) {
            this.setState({loading: true}, () => {
                search(this.state.actualPage, () => {}, (responsables) => {
                    this.setState({responsables: this.state.responsables.concat(responsables), loading: false, actualPage: this.state.actualPage + 1})
                })
            })
            
        } else if(bottom && this.state.totalResults == this.state.responsables.length) {
            this.setState({actualPage: 1})
        }
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <Grid container pt={5} px={2}>
                <SearchFormResponsable
                    searchCallback    = {this.searchCallback}
                    onLoadingCallback = {() => {this.setState({loading: true})}} />

                <Grid container p={2} display={"flex"} flexDirection={"column"}>
                    <H3>Resultado de la búsqueda: </H3>
                    <div ref={(ref) => this.scrollable = ref} className={"mh-300 overflow-y"} onScroll={this.handleScroll}>
                        {this.state.responsables.length == 0 ? <Alert severity="info"><Text>{!this.state.searched ? "Inserta los datos del tutor para buscarlo." : "No se ha encontrado ningun tutor."}</Text></Alert> : null}

                        {this.state.responsables.map(item => <ResponsableItem key={uuid()} item={item} onSelectedCallback={this.onSelectedCallback}/>)}

                        {this.state.loading ? 
                            <Box my={2} display={"flex"} justifyContent={"center"}>
                                <CircularProgress color="standard" className=''/>
                            </Box> : null
                        }
                    </div>
                </Grid>
            </Grid>
        )
    }
}


class ResponsableItem extends Component {
    render() {
        return (
            <Grid item xs={12}>
                <Box display={"flex"} justifyContent={"space-between"} my={2}>
                    <Stack>
                        <Text fontWeight={"bold"}>{(this.props.item.nombre ?? "") + " " + (this.props.item.apellido1 ?? "") + " " + (this.props.item.apellido2 ?? "")}</Text>
                        <Text>{(this.props.item.tipo_documento?.nombre ?? "" ) + " - " + this.props.item.numero_documento ?? ""}</Text>
                        <Text>{this.props.item.email ?? ""}</Text>
                    </Stack>

                    <Button 
                        variant = {"outlined"} 
                        sx      = {{ mx: 2, alignSelf: "center" }}
                        onClick = {() => {this.props.onSelectedCallback(this.props.item)}}>
                            
                        Seleccionar tutor
                    </Button>
                </Box>

                <Line />
            </Grid>
        )
    }
}