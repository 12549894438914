import * as Yup from 'yup'
import Statics from 'objects/common/Statics'

/**
 * Validación para el formulario de creación de una actividad de un grupo de academia
 */
export const validationSchema = Yup.object().shape({
    nombre: Yup.string().required('El nombre es obligatorio'),

    id_tipo_actividad: Yup.string().required('El tipo de actividad es obligatorio'),

    id_actividad: Yup.string().required('La actividad es obligatoria'),

    id_modalidad_cursos: Yup.string().required('La modalidad es obligatoria'),

    precio: Yup.number()
        .required('El precio es obligatorio')
        .typeError('El precio debe ser un número')
        .transform((value, originalValue) =>
            Statics.validatorValidNumberWithDecimals(originalValue, value)),

    precio_junio: Yup.number()
        .required('El precio de Junio es obligatorio')
        .typeError('El precio debe ser un número')
        .transform((value, originalValue) =>
            Statics.validatorValidNumberWithDecimals(originalValue, value)),

    matricula: Yup.number()
        .required('La matrícula obligatoria')
        .typeError('La matrícula debe ser un número')
        .transform((value, originalValue) =>
            Statics.validatorValidNumberWithDecimals(originalValue, value)),
})