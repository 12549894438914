import { H3 } from 'components/texts'
import { columns } from './columns.js'
import { useListAulaLogic } from 'hooks'
import { Box, Container, Paper } from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'
import { ListTableHeader } from 'pages/logged/admin/components'
import { Accordion, BreadcrumbsList, Datagrid } from 'components/data_display'
import SearchFormAula, { search } from 'components/forms/display/academia/aula/SearchForm'

/**
 * Componente funcional React que renderiza un listado de aulas en un contexto de academia.
 * Utiliza un conjunto de herramientas de Material-UI y componentes personalizados para mostrar una lista interactiva de aulas.
 * Incluye búsqueda y paginación, permitiendo a los usuarios interactuar dinámicamente con los datos.
 */
export default function ListadoAulasAcademia() {
  const { items, rowCount, params, updateTable, setLoadingTable, loadingTable } = useListAulaLogic()

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <BreadcrumbsList breadcrumbsList={[
        { to: "/", label: "Inicio" },
        { to: "./", label: "Academia" }, //--> Te dirige al apartado Aulas
        { to: "./", label: "Aulas", actualItem: true },
      ]} />

      <Accordion header={<Box display={"flex"} alignItems={"center"}><SearchIcon className={"mx-5"} /> <H3 className="my-0">Buscar aulas</H3></Box>}>
        <SearchFormAula
          searchCallback={updateTable}
          onLoadingCallback={setLoadingTable} />
      </Accordion>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
        <ListTableHeader rowCount={rowCount} params={params} keyValue={"aulas"} />

        <Datagrid
          rows={items}
          columns={columns}
          loading={loadingTable}
          rowCount={rowCount}
          onPageChange={(page) => { search(page, () => setLoadingTable(true), updateTable) }}
          serverSide />
      </Paper>
    </Container>
  )
}