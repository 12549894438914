import React, { useEffect, useState } from 'react';
import { Box, Grid } from "@mui/material";
import { Datagrid } from 'components/data_display';
import { H3, Text } from 'components/texts';
import { CursoEscolarSelect } from 'components/forms/components/Select/custom';
import { COLOR } from 'theme/Colors.js'
import { Button } from 'components/forms/components';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';

const inputStyles = {
  padding: '7px 14px',
  border: '1px solid #ccc',
  borderRadius: '4px',
};

// Se ha hecho el Wrapper para validar el campo de curso escolar (que lance error cuando añada un registro sin haber seleccionado el curso escolar antes)
export default function Wrapper({ empleado, pagos, cursoEscolar, onChangeCursoEscolar, setValue, register, unregister }) {
  const methods = useForm()

  return (
    <FormProvider {...methods}>
      <PagosData empleado={empleado} pagos={pagos} cursoEscolar={cursoEscolar} onChangeCursoEscolar={onChangeCursoEscolar} setValue={setValue} register={register} unregister={unregister} />
    </FormProvider>
  )
}

function PagosData({ empleado, pagos, cursoEscolar, onChangeCursoEscolar, setValue, register, unregister }) {
  const { setError, clearErrors, formState: { errors } } = useFormContext()

  const [editedData, setEditedData] = useState(pagos || []);

  useEffect(() => {
    if (register) {
      register('pagos');
      setEditedData(pagos || []);
    }
  }, [pagos, register, unregister]);

  const addRow = () => {
    //Validacion del curso escolar
    if (!cursoEscolar) {
      setError('id_curso_escolar', {
        type: 'manual',
        message: 'Debes seleccionar un curso escolar antes de añadir pagos.',
      })
      enqueueSnackbar(<Text>Debes seleccionar un curso escolar para añadir un nuevo registro.</Text>, { variant: "error" })
      return
    }
    clearErrors('id_curso_escolar')

    const newRow = { id: editedData.length + 1, nombre_pagos: '', horas: 0, importe: '', creating: true };
    setEditedData([...editedData, newRow]);
    setValue('pagos', [...editedData, newRow]);
  };

  const handleCellEditCommit = (params) => {
    const { id, field, value } = params;
    const updatedData = editedData.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: value };
      }
      return row;
    });

    setEditedData(updatedData);
    setValue('pagos', updatedData);
  };

  const handleCursoEscolarChangeInternal = (cursoEscolar) => {
    if (onChangeCursoEscolar) {
      onChangeCursoEscolar(cursoEscolar);
    }
  };

  const columns = [
    {
      field: "fecha",
      headerName: "Fecha Inicio",
      maxWidth: 200,
      editable: true,
      flex: 1,
      renderCell: (params) => (
        <input
          type="date"
          style={inputStyles}
          value={params.row.fecha}
          onChange={(e) => handleCellEditCommit({ ...params, value: e.target.value, field: 'fecha' })}
        />
      ),
    },
    { field: "nombre_pagos", headerName: "pagos", flex: 1, maxWidth: 250, editable: true },
    { field: "horas", headerName: "Horas", flex: 1, maxWidth: 100, editable: true },
    {
      field: "importe",
      headerName: "Importe",
      flex: 1,
      maxWidth: 120,
      editable: true,
    },

    {
      field: "fecha_pago",
      headerName: "Fecha Pago",
      maxWidth: 200,
      flex: 1,
      editable: true,
      renderCell: (params) => (
        <input
          style={inputStyles}
          type="date"
          value={params.row.fecha_pago}
          onChange={(e) => handleCellEditCommit({ ...params, value: e.target.value, field: 'fecha_pago' })}
        />
      ),
    },
  ];

  return (
    <Grid sx={{ width: '100%', mt: 2, display: 'flex', flexDirection: 'column' }}>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Grid item container xs={4}>
          <H3 >Cond. Económicas: </H3>
          {pagos ? <Text>contrato</Text> : <Text> Sin contrato de profesor</Text>}
          <CursoEscolarSelect
            register={register}
            label={"Curso escolar"}
            name={"id_curso_escolar"}
            onChange={({ target }) => handleCursoEscolarChangeInternal(target.value)}
            defaultValue={null}
            setValue={setValue}
            searcheable
          />
        </Grid>
      </Box>

      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <Button
          onClick={addRow}
          sx={{ maxWidth: '100px', mb: 2, backgroundColor: COLOR.standard.main }}>
          Añadir
        </Button>
      </Box>

      <Grid item xs={12}>
        <Datagrid
          rowHeight={38}
          rows={editedData}
          columns={columns}
          onCellEditCommit={handleCellEditCommit}
        />
      </Grid>
    </Grid>
  );
}
