import React, { Component } from 'react'
import { MenuItem, MenuItemGroup } from 'components/navigation'
import { 
    SportsBasketball as ActividadesIcon,
    School           as SchoolIcon,
    Article          as InscripcionesIcon,
    AccessTime       as HorariosIcon,
    Groups           as GruposIcon,
    Savings          as PagosIcon,
} from '@mui/icons-material'

export default class AlumnoMenu extends Component {

    /**
     * ComponentDidMount
     * 
     * Inicializamos los componentes necesarios del tema
     */
    componentDidMount() {
        window.KTMenu?.createInstances()
        window.KTToggle?.createInstances()
    }


	/**
	 * Render
	 * 
	 * @returns {Component}
	 */
	render() {
		return (
            <div className="menu menu-column menu-rounded menu-sub-indention px-3" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">
                {/* <MenuItemGroup title="Principal">
                </MenuItemGroup> */}
                <div className="menu-item pt-5">
                    <div className="menu-content">
                        <span className="menu-heading fw-bold text-uppercase fs-7">Menú colegios</span>
                    </div>
                </div>

                <MenuItem title="Colegios"                to="./colegios"               icon={<SchoolIcon />} exact/>
                <MenuItem title="Actividades en colegios" to="./colegios/actividades"   icon={<ActividadesIcon />}/>
                <MenuItem title="Inscripciones"           to="./colegios/inscripciones" icon={<InscripcionesIcon />}/>
                <MenuItem title="Grupos"                  to="./colegios/grupos"        icon={<GruposIcon />}/>
                <MenuItem title="Horarios"                to="./colegios/horarios"      icon={<HorariosIcon />}/>
                <MenuItem title="Pagos de colegios"       to="./colegios/pagos"         icon={<PagosIcon />}/>
            </div>
		)
	}
}
