import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { LoadingButton } from 'components/forms/components'
import { Input, DatePicker } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { RequestIncidenciaFichaje } from "objects/requests"

export default function DataFormIncidencia({ register, errors, setValue, handleSubmit, onSuccessCallback = () => {}, onErrorCallback = () => {}}) {

    const [loading, setLoading] = useState(false)

    const createIncidencia = (props) => {
        const formData = new FormData()
        formData.append('fecha',                        props.fecha)
        formData.append('hora_inicio',                  props.hora_inicio)
        formData.append('hora_fin',                     props.hora_fin)
        formData.append('comentario',                   props.comentario)
        formData.append('motivo_incidencia_fichaje_id', props.motivo_incidencia_fichaje_id)

        new RequestIncidenciaFichaje().post('', formData, onSuccessCallback, onErrorCallback)
    }

    return (
        <Grid container spacing={4} mt={2} pb={5} px={5}>
            <Grid item xs={12}>
                <ItemsSelect label={"Motivo de la incidencia*"} name={"motivo_incidencia_fichaje_id"} keyValue={"motivo_incidencia_fichaje"} initialOption={0} setValue={setValue} register={register} errors={errors} />
            </Grid>

            <Grid item xs={12}>
                <DatePicker register={register} errors={errors} className="w-100" name={"fecha"} label={"Fecha de la incidencia*"} setValue={setValue} />
            </Grid>

            <Grid item xs={6}>
                <Input type={"time"} label="Hora inicio:" register={register} errors={errors} className="w-100" name={"hora_inicio"} InputLabelProps={{ shrink: true }} uppercase />
            </Grid>

            <Grid item xs={6}>
                <Input type={"time"} label="Hora fin:" register={register} errors={errors} className="w-100" name={"hora_fin"} InputLabelProps={{ shrink: true }} uppercase />
            </Grid>

            <Grid item xs={12}>
                <Input label="Comentario" register={register} errors={errors} className="w-100" name={"comentario"} uppercase multiline minRows={6} maxRows={10} />
            </Grid>

            <Grid item xs={12} className="d-flex flex-row justify-content-end">
                <LoadingButton loading={loading} onClick={handleSubmit(createIncidencia)}>
                    Crear incidencia
                </LoadingButton>
            </Grid>
        </Grid>
    )
}
