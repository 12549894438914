import { useState } from "react"
import { H3, Text } from "components/texts"
import Request from "../../Requests/Requests"
import { Line } from "components/data_display"
import { Box, Grid, Stack } from "@mui/material"
import { Button } from "components/forms/components"
import { LoadingButton } from 'components/forms/components'
import { Edit as EditIcon, InsertDriveFile as PreliminarIcon } from '@mui/icons-material'

export default function SingleHeader({ claseParticular, navigate }) {
    const [loading, setLoading] = useState(false)
    
    const getPreliminar = () => {
        setLoading(true)

        new Request().getPreliminar(claseParticular.id, () => {}, () => setLoading(false)) 
    }

    return (
        <>
            <Box px={4} py={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Stack direction="row">
                    <H3 className={"m-0"}>Clase particular</H3>
                    <Text className="standard mx-5">{"#" + claseParticular.id}</Text>
                </Stack>

                <Stack direction="row" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {claseParticular ? (
                        <Grid item xs={2} mr={2} display={"flex"} justifyContent={"end"}>
                            <LoadingButton color="success" startIcon={<PreliminarIcon />} loading={loading} onClick={getPreliminar}>Vista preliminar</LoadingButton>
                        </Grid>
                    ) : null}

                    {claseParticular ? (
                        <Grid item xs={1} display={"flex"} justifyContent={"end"} alignItems={"start"}>
                            <Button variant={"outlined"} startIcon={<EditIcon />} onClick={() => { navigate('./editar') }}>Editar inscripción</Button>
                        </Grid>
                    ) : null}
                </Stack>
            </Box>
            
            <Line />
        </>
    )
}