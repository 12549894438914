import { Grid } from '@mui/material';
import { Text } from 'components/texts'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import validationSchema from "./validation"
import Statics from 'objects/common/Statics';
import { SearchButtons } from '../../../components';
import { Select } from 'components/forms/components'
import { yupResolver } from '@hookform/resolvers/yup'
import { RequestInscripcionColegio } from 'objects/requests'
import { itemsSearch, itemsOrder } from './searchAndOrderOptions'

let prepareParamsGlobal = null

//Formulario de busqueda de alumnos
export default function SearchFormAlumno({ onLoadingCallback, searchCallback, onChangeSearchIndex }) {

    //Form
	const { register, handleSubmit, formState: { errors }, getValues, setValue, reset } = useForm({ resolver: yupResolver(validationSchema) })

    //State
    const [resetFormState, setResetFormState] = React.useState(false);
    const [searchIndex,    setSearchIndex]    = React.useState(0);

    useEffect(() => {
        if(resetFormState == true) {
            resetForm(reset)
            setResetFormState(false)
        }
    })

    //-------------------------------------------------------
    //---------------------- FUNCTIONS ----------------------
    //-------------------------------------------------------

    /**
     * Controlla cuando se hace un cambio en el select de busqueda
     * 
     * @param {Object} event
     */
    const handleChange = (event) => {
        const index = event.target.value

        setSearchIndex(index)
        setResetFormState(true)

        if(onChangeSearchIndex)
            onChangeSearchIndex(index, itemsSearch[index].columnKey)
    }


    /**
     * Prepara los parametros que se van a usar
     * 
     * @param {Int} page
     */
    const prepareParams = (page) => {
        const params     = Statics.objectPropsToUppercase(Statics.cleanObject(getValues()));
        params.relations = 'alumno.inscripciones_colegios.subvencion_ppaa,grupo,pagos,venta_libro,venta_libro_asociado,forma_recogida,forma_conocimiento,curso_escolar,colegio,actividad,horario,motivo_baja'
        params.page      = page

        return params
    }

    prepareParamsGlobal = prepareParams

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    const ActualTemplate = itemsSearch[searchIndex].template
    return (
        <Grid container p={2}>
            <Grid container>
                <Grid item xs={8} md={4}>
                    <Text fontWeight={'bold'} marginBottom={6}>Buscar por:</Text><br />
                    <Select width={"80%"} items={itemsSearch} defaultValue={itemsSearch[0].value} onChange={handleChange}/>
                </Grid>

                <Grid item xs={4} md={4}>
                    <Text fontWeight={'bold'} marginBottom={6}>Ordenar resultados por:</Text><br />
                    <Select width={"60%"} name={"order"} items={itemsOrder} defaultValue={itemsOrder[0].value} register={register} errors={errors}/>
                </Grid>
            </Grid>
            
            <ActualTemplate reset={resetFormState} register={register} errors={errors} setValue={setValue}/>
            
            <SearchButtons searchText={"Buscar inscripciones"} onResetClick={() => {setResetFormState(true)}} onSubmitClick={handleSubmit(() => {search(1, onLoadingCallback, searchCallback)})}/>
        </Grid>
    )
}



/**
 * Hace la busqueda de alumnos
 * 
 * @param {Int}      page
 * @param {Function} onLoadingCallback
 * @param {Function} searchCallback
 * @param {Function} prepareParams
 */
export const search = (page = 1, onLoadingCallback, searchCallback) => {
    //Callback para controlar cuando esta cargando
    if(onLoadingCallback)
        onLoadingCallback(true)

    const params = prepareParamsGlobal(page)
    new RequestInscripcionColegio().search(params, (res) => {
        if(searchCallback)
            searchCallback(res.items.data, res.items.total, page, params)
    }, () => {
        searchCallback([], 0, page, params)
    })
}


/**
 * Resetea todo el formulario
 * 
 * @param {Function} reset
 */ 
const resetForm = (reset) => {
    reset({
        nombre: "",
        apellido1: "",
        apellido2: "",
        clave: "",
        municipio: "",
        colegio: "",
        clave_grupo: "",
    })
}