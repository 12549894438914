import { BreadcrumbsList, MoveButtons, Line } from 'components/data_display'
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Paper, Stack } from '@mui/material'
import Headers from 'components/headers/index'
import Requests from './Requests/Requests.js'
import { useSingleAulaLogic } from 'hooks'
import { AulaData } from './parts'
import React from 'react'

export default function SingleAula() {
  const navigationParams = useParams()
  const navigate = useNavigate()
  const { id_aula, setAula, updateId, aula } = useSingleAulaLogic(navigationParams, navigate, new Requests())

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>

      <Stack direction="row" justifyContent={"space-between"} marginX={8}>
        <BreadcrumbsList mx={0} breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../", label: "Academia" }, //--> Te dirige al apartado Aulas
          { to: "./../", label: "Aulas" },
          { to: "./", label: "Aula #" + (id_aula ?? "0000"), actualItem: true },
        ]} />

        <MoveButtons
          actualItemId={id_aula}
          beforeFetchCallback={() => setAula(null)}
          onUpdateActualItem={updateId} />
      </Stack>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>

        {/* Consultar datos del aula: {aula ? <Header aula={aula} /> : null} */}

        <Headers
          template={'single'}
          title={"Consultar Aula"}
          actividad={aula?.denominacion}
        />

        <Line className={"my-5"} />

        <AulaData aula={aula} />
        {/* {aula ?
          <AulaData aula={aula} />
          : <SkeletonAulaData />} */}
      </Paper>

    </Container>
  )
}
