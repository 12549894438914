import { SnackbarProvider } from 'notistack'
import Requests from './Requests/Requests.js'
import { Container, Paper } from '@mui/material'
import { CreateHeader } from 'pages/logged/admin/components'
import { BreadcrumbsList, Line } from 'components/data_display'
import { useColegioActividadCreateLogic } from 'hooks/index.js'
import { DataFormActividadColegio } from 'components/forms/display'

/**
 * Componente principal para la creación de actividades en colegios.
 * Renderiza un formulario para ingresar los datos de la nueva actividad y enviarlos a la API.
 * Utiliza el hook personalizado useLogic para su lógica interna y manejo del estado del formulario.
 */
export default function CreateActividadesColegio() {
  const { isMounted, errors, headerRef, register, handleSubmit, createActividad } = useColegioActividadCreateLogic({ requests: new Requests() })

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(createActividad)}>
        <BreadcrumbsList breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../../", label: "Colegios" },
          { to: "./../", label: "Actividades" },
          { to: "./", label: "Crear actividad", actualItem: true },
        ]} />

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <CreateHeader
            ref={headerRef}
            title={"Crear actividad"}
            btnText={"Guardar"} />

          <Line />

          <DataFormActividadColegio register={register} errors={errors} isMounted={isMounted} />
        </Paper>

        <SnackbarProvider autoHideDuration={3000} />
      </form>
    </Container>
  )
}