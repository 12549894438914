import { RequestInscripcionColegio } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Hace la petición para crear el colegio
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getInscripcion(id, callbackOk = () => {}, callbackError = () => {}) {
        const params = {relations: 'alumno.inscripciones_colegios.subvencion_ppaa,grupo.horario.profesor_actual,pagos,venta_libro,venta_libro_asociado,forma_recogida,forma_conocimiento,curso_escolar,colegio,actividad,horario,motivo_baja' }
        new RequestInscripcionColegio().get(id, params, (res) => { callbackOk(res.inscripcion ?? null) }, callbackError)
    }
}
