import { Modal } from '@mui/material'
import React, { Component } from 'react'
import { Line } from '../data_display'
import Box from '@mui/material/Box'
import { H3 } from '../texts';
import { HighlightOff as CloseIcon } from '@mui/icons-material'
import { COLOR } from '../../theme/Colors';
import './style.css'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "80%",
    bgcolor: 'background.paper',
    borderRadius: 2,
};

/**
 * Modal custom basado en el de MUI
 */
export default class CustomModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            open: false,
        }
    }


    /**
     * Cierra el modal
     */
    close = () => {
        this.setState({open: false}, () => {
            if(this.props.onDismiss)
                this.props.onDismiss()
        })
    }


    /**
     * Abre el modal
     */
    open = () => {
        this.setState({open: true})
    }

    
    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <Modal
                open             = {this.state.open}
                onClose          = {this.close}
                aria-labelledby  = {this.props.ariaLabel ?? "modal-modal-title"}
                aria-describedby = {this.props.ariaDescribe ?? "modal-modal-description"}>
                    <Box sx={[style, this.props.style ?? {}]} className={"focus-visible-none"}>
                        <div className="w-100 d-flex justify-content-center align-items-center p-5">
                            <H3 className="m-0">{this.props.title ?? ""}</H3>

                            <div className="modal-header-close" onClick={this.close}>
                                <CloseIcon style={{ color: COLOR.standard.main }} fontSize="large"/>
                            </div>
                        </div>
                        
                        <Line />

                        {this.props.children ?? null}
                    </Box>
            </Modal>
        )
    }
}
