import store from 'store/store'
import { useAlert } from 'hooks'
import { useForm } from 'react-hook-form'
import Statics from 'objects/common/Statics'
import { useNavigate } from 'react-router-dom'
import { useContext, useEffect, useRef, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from 'components/forms/display/clases-particulares/DataForm/validation.js'
import { EmpleadoListContext } from 'contexts/employee/ListContext'

/**
 * Hook personalizado para manejar la lógica de creación de clases particulares.
 * Incluye la inicialización de formularios con react-hook-form, navegación,
 * gestión de estados y comunicación con la API para crear clases particulares.
 * 
 * @returns {Object} Objeto con propiedades y funciones para gestionar la creación de clases particulares.
 */
export default function useClaseParticularCreateLogic({ requests }) {

    const updateAlert = useAlert()

    /**
     * Se obtiene la ID del usuario logueado, para poder enviar dicha id en la peticion de creacion de clase particular.
     * La ID de la persona logueada será el valor del campo empleado_id
     * (o el operador, el que ha dado de alta la isncripcion de la clase particular)
     */
    const { userState } = store.getState()
    const operadorId = userState.user[0].id
    const operadorName = userState.user[0].nombre
    const { addItem } = useContext(EmpleadoListContext)
    const [ alumnoSelected, setAlumnoSelected ] = useState({})

    const { register, handleSubmit, formState: { errors }, getValues, setValue, control, clearErrors } = useForm({ resolver: yupResolver(validationSchema) })

    const navigate = useNavigate()

    const [tab, setTab] = useState("1")
    const [isMounted, setIsMounted] = useState(false)

    const header = useRef()

    useEffect(() => {
        setIsMounted(true)
    }, [])

    /**
     * Función para manejar errores en la validación del formulario.
     * Cambia la pestaña activa dependiendo del primer campo con error encontrado.
     * 
     * @param {Object} errors - Objeto que contiene los errores de validación del formulario.
     */
    const onError = (errors) => {
        const tabPagos = ["precio_hora"]
        let tab = "2"

        for (const key of Object.keys(errors)) {
            if (!tabPagos.find(item => item == key)) {
                tab = "1"
                break
            }
        }

        setTab(tab)
        updateAlert('Rellena todos los campos obligatorios.')
    }

    /**
     * Función para crear una clase particular a través de una petición a la API.
     * Tras la creación exitosa, realiza llamadas adicionales para crear pagos y libros asociados.
     * 
     * @param {Object} values - Datos del formulario para crear la clase particular.
     */
    const createClaseParticular = (values) => {
        header.current.button.setLoading(true)

        requests.createClaseParticular(values, operadorId, (res) => {
            const promisePagos = new Promise((resolve, reject) => {
                requests.createPagos(res.item.id, values.pagos, resolve, reject)
            })

            const promiseLibro = new Promise((resolve, reject) => {
                requests.crudLibro(res.item, values, resolve, reject)
            })

            const promiseLibroAsociado = new Promise((resolve, reject) => {
                requests.crudLibroAsociado(res.item, values, resolve, reject)
            })

            Promise.allSettled([promisePagos, promiseLibro, promiseLibroAsociado]).then(async (values) => {
                for (const fail of values.filter(item => item.status == "rejected"))
                    updateAlert(fail.reason)

                header.current.button.setLoading(false)
                updateAlert('Se ha guardado la inscripción correctamente.Redirigiendo...', 'success')
                await Statics.delay(1500).then(() => navigate('./../' + res.item.id)) //Redirección al single
            })

            // Añadimos nueva clase particular al contexto
            addItem(res.item)

        }, (res) => {
            //TODO: IDEA. ASOCIAR UN CÓDIGO DE LETRAS Y NUMERO ALEATORIO Y UNICO PARA CADA CALLBACKERROR, POR EJEMPLO: OIFHN493WY8NC. ASI EN CASO DE QUE SALGA EL ERROR, BUSCANDO EN VSC DIRECTAMENTE IREMOS AL ARCHIVO DONDE OCURRE EL ERROR
            updateAlert('No se ha podido crear la clase particular.')
            header.current.button.setLoading(false)
        })
    }

    return {alumnoSelected, setAlumnoSelected, operadorName, header, tab, setTab, isMounted, register, handleSubmit, errors, getValues, setValue, control, clearErrors, createClaseParticular, onError }
}