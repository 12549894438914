import React, { Component } from 'react'
import { COLOR } from '../../../theme/Colors'

export default class Mini extends Component {

  /**
   * Render
   * 
   * @returns {Component}
   */
  render() {
    return (
      <span className='mini-text'
        style={this.props.style ?? {
          color: this.props.color ?? COLOR.cancel.main,
        }}>{this.props.children ?? ""}</span>
    )
  }
}
