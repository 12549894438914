import * as Yup from 'yup'

const Regex = "^[A-Za-z0-9]+$"
const RegexNombres = /^[A-Za-zÁ-ú]+(?:\s[A-Za-zÁ-ú]+)*$/
const RegexCorreo = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const validationSchema = Yup.object().shape({
    apellido1_alumno:        Yup.string().matches(RegexNombres, {message: 'Formato inválido para una dirección de e-mail', excludeEmptyString: true}),
    apellido2_alumno:        Yup.string().matches(RegexNombres, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
    nombre_alumno:           Yup.string().matches(RegexNombres, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
})

export default validationSchema