import { Box } from '@mui/material'
import React, { Component } from 'react'
import { Modal } from 'components/modals'
import { Button } from 'components/forms/components'
import { RequestAlumnoResponsable } from 'objects/requests'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Modal con el formulario de añadir responsable
 */
export default class ChooseTipoRelacion extends Component {

    constructor(props) {
        super(props)

        this.state = {
            relationSelected: null,
            responsable: null,
            alumno: null,
            // Propiedad update para determinar si es una operación de creación de relación o de actualización
            update: null,
        }
    }


    /**
     * Actualiza el estado y abre el modal
     * 
     * @param {Object} alumno 
     * @param {Object} responsable 
     * @param {Boolean} update
     */
    open = (alumno, responsable, update = false) => {
        this.setState({alumno, responsable, update}, () => {
            this.childModal.open()
        })
    }


    /**
     * Hace la petición para guardar la relación de ambos modelos
     */
    saveRelation = () => {
        var formData = new FormData()
        formData.append('responsable_id',   this.state.responsable.id)
        formData.append('alumno_id',        this.state.alumno.id)
        formData.append('tipo_relacion_id', this.state.relationSelected)
        // Usamos un condicional para divir operaciones. Si es para actualizar la relación será update = true, si es para crear será false
        if(this.state.update) {
            // Actualizar relación de tutor-alumno
            new RequestAlumnoResponsable().update(formData, () => {
                this.childModal.close()
                if(this.props.callbackOk)
                    this.props.callbackOk("Relación con tutor actualizada correctamente.")
            }, (res) => {
                if(this.props.callbackError)
                    this.props.callbackError(res)
            })
        } else {
            // Añadir alumno a tutor
            new RequestAlumnoResponsable().create(formData, () => {
                this.childModal.close()
                if(this.props.callbackOk)
                    this.props.callbackOk("Se ha asociado el tutor correctamente.")
            }, (res) => {
                if(this.props.callbackError)
                    this.props.callbackError(res)
            })
        }   
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <Modal ref={(ref) => this.childModal = ref} ariaLabel={"child-modal-title"} ariaDescribe={"child-modal-description"} title={"Elige el tipo de relación del tutor con el alumno"} style={{minWidth: "50%"}}>
                <Box p={10}>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <ItemsSelect name={"tipo_relacion_id"} keyValue={"tipo_relacion"} onChange={(e) => {this.setState({relationSelected: e.target.value})}}/>
                    </Box>
                    <Box display={"flex"} justifyContent={"end"} alignItems={"center"}>
                        <Button 
                            className = "my-5" 
                            onClick   = {this.saveRelation}
                            disabled  = {!this.state.relationSelected}>
                                
                            Aceptar
                        </Button>
                    </Box>
                </Box>
            </Modal>
        )
        
    }
}