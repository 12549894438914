import React, { useState } from 'react'
import { Box, Grid } from "@mui/material";
import { Button } from 'components/forms/components';
import { Datagrid } from 'components/data_display'


const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
        field: 'fechaInicio',
        headerName: 'Fecha Inicio',
        width: 200,
        editable: true,
    },
    {
        field: 'fechaFin',
        headerName: 'Fecha Fin',
        width: 200,
        editable: true,
    },
    {
        field: 'cantidad',
        headerName: 'Cantidad',
        type: 'number',
        width: 150,
        editable: true,
    },
    {
        field: 'retencion',
        headerName: 'Retención',
        type: 'number',
        width: 150,
        editable: true,
    },
    {
        field: 'puesto',
        headerName: 'Puesto',
        type: 'number',
        width: 200,
        editable: true,
    },

];

export default function ContratosAcademia({ empleado, register, errors, setValue, getValues }) {
    const [rows, setRows] = useState([
        { id: 1, fechaInicio: 'Snow', fechaFin: '', cantidad: 0, retencion: 0, formaCond: 0 },
    ]);
    const addRow = () => {
        const newRow = { id: rows.length + 1, fechaInicio: '', fechaFin: '', cantidad: 0, retencion: 0, formaCond: 0 };
        setRows([...rows, newRow]);
    };

    return (
        <Grid container>
            <Grid item xs={12} marginTop={2} marginBottom={2}>
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        onClick={addRow}
                        variant="contained"
                        color="primary"
                        sx={{ maxWidth: '100px', mb: 2 }} >
                        Añadir
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Datagrid
                    rows={rows}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[50, 100]}
                />
            </Grid>
        </Grid >
    )
}