import Statics from 'objects/common/Statics'

export const columns = [
    { field: "profesor", headerName: "Profesor", flex: 1,       valueGetter: (params) => { return ((params.row.empleado?.nombre ?? "") + " " + (params.row.empleado?.apellido1 ?? "") + " " + (params.row.empleado?.apellido2 ?? ""))} },
    { field: "grupo",    headerName: "Grupo",    flex: 1,       valueGetter: (params) => { return params.row.grupo?.clave } },
    { field: "alta",     headerName: "Alta",     maxWidth: 100, valueGetter: (params) => { return params.row.fecha_alta ? Statics.formatDate(params.row.fecha_alta) : null}},
    { field: "baja",     headerName: "Baja",     maxWidth: 100, valueGetter: (params) => { return params.row.fecha_baja ? Statics.formatDate(params.row.fecha_baja) : null}},
    { field: "actual",   headerName: "Actual",   maxWidth: 50,  valueGetter: (params) => { return params.row.actual ? "Si" : "No"}},
    { field: "alumnos",  headerName: "Alumnos",  maxWidth: 50,  valueGetter: (params) => { return "Pendiente"}},
]
