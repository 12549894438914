import { useEffect, useRef, useState } from "react"

export default function usePagoDataFormLogic(actividad, setValue) {
    const cantidadInputRef = useRef()
    const cantidadMatriculaInputRef = useRef()

    const [updateSelect, setUpdateSelect] = useState({ update: false, value: 0 })
    const [firstRender, setFirstRender] = useState(true)

    useEffect(() => {
        if (updateSelect.update)
            setUpdateSelect({ update: false, value: 0 })
    }, [updateSelect.update])

    useEffect(() => {
        if (!firstRender) {
            setValue("cantidad_pago", actividad?.precio ?? 0)
            cantidadInputRef.current.setValue(actividad?.precio ?? 0)

            setValue("matricula", actividad?.matricula ?? 0)
            cantidadMatriculaInputRef.current.setValue(actividad?.matricula ?? 0)

            console.log(actividad)
        }
    }, [actividad])

    useEffect(() => {
        if (firstRender)
            setFirstRender(false)
    }, [])

    return { cantidadInputRef, cantidadMatriculaInputRef, updateSelect, setUpdateSelect }
}