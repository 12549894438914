import { columns } from './columns'
import { H3, Text } from 'components/texts'
import { enqueueSnackbar } from 'notistack'
import Requests from '../../Requests/Requests'
import { Datagrid } from 'components/data_display'
import React, { useState, useEffect, useRef } from 'react'

export default function DetallePagoGrid({ pago, detallePagoId }) {

    const [detallesActividades, setDetallesActividades] = useState([])
    const [loading,             setLoading]             = useState(false)

    const prevPagoIdRef = useRef(null)
    const detallesCacheRef = useRef({})

    useEffect(() => {
        if (detallePagoId) {
            if (detallesCacheRef.current[detallePagoId]) {
                // Si los detalles ya están en el caché, los recuperamos del caché
                setDetallesActividades(detallesCacheRef.current[detallePagoId])
            } else {
                setLoading(true)
                // Hacemos la petición para obtener los detalles y los almacenamos en el caché
                new Requests().getDetalleFacturacionColegio(
                detallePagoId,
                ({ detalles }) => {
                    setDetallesActividades(detalles)
                    detallesCacheRef.current[detallePagoId] = detalles
                    setLoading(false)
                },
                (err) => {
                    setDetallesActividades([])
                    enqueueSnackbar(<Text>{err}</Text>, { variant: "error" })
                    setLoading(false)
                }
                )
            }
        } else {
            setDetallesActividades([])
        }
    }, [detallePagoId])

    useEffect(() => {
        if (pago && pago.id !== prevPagoIdRef.current) {
            prevPagoIdRef.current = pago.id
            setDetallesActividades([])
        } else {
            prevPagoIdRef.current = null
        }
    }, [pago])

    return (
        <React.Fragment>
            <H3>Detalle de las Actividades:</H3>
            <Datagrid
                rows               = {detallesActividades}
                columns            = {columns()}
                pageSize           = {100}
                rowsPerPageOptions = {[100]}
                rowHeight          = {40}
                heightWithContent  = {500}
                loading            = {loading} />
        </React.Fragment>
    )
}