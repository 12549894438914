import React, { Component } from 'react'
import { LoadingButton } from 'components/forms/components';
import { H3 } from 'components/texts';
import { Grid } from '@mui/material';

/**
 * Header para la creacion
 * 
 * El botón de IconButton pertenece a la funcionalidad del módulo Libros -> Generar envíos.
 * 
 */
export default class CreateHeader extends Component {

  constructor(props) {
    super(props)
  }


  /**
   * Callback para el envío del formulario
   * 
   * @param {Event} e 
   */
  handleSubmit = (e) => {
    if (this.props.handleSubmit)
      this.props.handleSubmit(e)
  }

  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
      <Grid container py={2} px={4}>
        <Grid item xs={10} display={"flex"} alignItems={"center"}>
          <H3 className={"m-0"}>{this.props.title ?? ""}</H3>
        </Grid>
        <Grid item xs={2} display={"flex"} alignItems={"center"} justifyContent={"end"}>
          <button style={{ border: 'none', background: 'none' }} type='submit'>
            <>
              <LoadingButton
                ref={(ref) => this.button = ref}
                color={'success'}
                size={"small"}
                loading={this.props.loading}
                onClick={this.handleSubmit}>
                {this.props.btnText ?? "Guardar"}
              </LoadingButton>
            </>
          </button>
        </Grid>
      </Grid>
    )
  }
}
