import { responses as itemsResponses } from 'constants/Responses'
import React, { createContext, useEffect, useState } from 'react'
import {
  RequestAlumno, RequestEmpleado, RequestClasesParticulares, RequestAula,
  RequestGrupoAcademia, RequestIncidencia, RequestResponsable, RequestTutoria, RequestDatosColegio, RequestRegistroActividad,
  RequestInscripcionAcademia, RequestColegio, RequestLibro, RequestGrupoColegio, RequestInscripcionColegio
} from 'objects/requests'

// Mapeo de clases Request a sus respectivos tipos de contexto.
const requests = {
  "alumnos": RequestAlumno,
  "empleados": RequestEmpleado,
  "responsables": RequestResponsable,
  "tutorias": RequestTutoria,
  "incidencias": RequestIncidencia,
  "datos-colegio": RequestDatosColegio,
  "colegios": RequestColegio,
  "libros": RequestLibro,
  "aulas": RequestAula,
  "inscripcionesAcademias": RequestInscripcionAcademia,
  "actividad": RequestRegistroActividad,
  "actividades-colegio": RequestRegistroActividad,
  "grupos-colegio": RequestGrupoColegio,
  "inscripciones-colegio": RequestInscripcionColegio,
  'clases-particulares': RequestClasesParticulares,
  "grupo-academia": RequestGrupoAcademia
}

// Mapeo de nombres de respuestas a sus correspondientes claves de array.
const responses = {
  "alumnos": itemsResponses.alumnos.arrayName,
  "empleados": itemsResponses.empleados.arrayName,
  "responsables": itemsResponses.responsables.arrayName,
  "tutorias": itemsResponses.tutorias.arrayName,
  "incidencias": itemsResponses.incidencias.arrayName,
  "datos-colegio": itemsResponses.datosColegio.arrayName,
  "colegios": itemsResponses.colegios.arrayName,
  "libros": itemsResponses.libros.arrayName,
  "aulas": itemsResponses.aulas.arrayName,
  "inscripcionesAcademias": itemsResponses.inscripcionesAcademias.arrayName,
  "actividad": itemsResponses.actividadAcademia.arrayName,
  "actividades-colegio": itemsResponses.actividad.arrayName,
  "grupos-colegio": itemsResponses.gruposColegio.arrayName,
  "inscripciones-colegio": itemsResponses.inscripcionesColegio.arrayName,
  'clases-particulares': "items",
  "grupo-academia": itemsResponses.grupoAcademia.arrayName,
}

// Estado inicial para el contexto.
const initialState = {
  alumnos: { items: [], rowCount: 0, params: {}, page: 1 },
  responsables: { items: [], rowCount: 0, params: {}, page: 1 },
  'datos-colegio': { items: [], rowCount: 0, params: {}, page: 1 },
  tutorias: { items: [], rowCount: 0, params: {}, page: 1 },
  incidencias: { items: [], rowCount: 0, params: {}, page: 1 },
  libros: { items: [], rowCount: 0, params: {}, page: 1 },
  empleados: { items: [], rowCount: 0, params: {}, page: 1 },
  colegios: { items: [], rowCount: 0, params: {}, page: 1 },
  'grupos-colegio': { items: [], rowCount: 0, params: {}, page: 1 },
  'actividades-colegio': { items: [], rowCount: 0, params: {}, page: 1 },
  'inscripciones-colegio': { items: [], rowCount: 0, params: {}, page: 1 },
  inscripcionesAcademias: { items: [], rowCount: 0, params: {}, page: 1 },
  aulas: { items: [], rowCount: 0, params: {}, page: 1 },
  actividadesAcademias: { items: [], rowCount: 0, params: {}, page: 1 },
  'clases-particulares': { items: [], rowCount: 0, params: {}, page: 1 },
  'grupo-academia': { items: [], rowCount: 0, params: {}, page: 1 },
}

// Creación del contexto.
const EmpleadoListContext = createContext();

/**
 * Proveedor del contexto diseñado para gestionar y compartir el estado de listas de elementos
 * (como alumnos, empleados, etc.) a lo largo de la aplicación. 
 * 
 * @param {Object} props - Propiedades pasadas al proveedor, incluyendo los hijos del componente y el tipo de contexto.
 */
const EmpleadoListProvider = ({ children, tipoContexto }) => {
  const [state, setState] = useState(initialState)

  /**
   * Actualiza el número de la página actual en el estado para el tipo de contexto especificado.
   * Esta función es particularmente útil para controlar la paginación de las listas en la UI,
   * permitiendo a los usuarios navegar entre diferentes páginas de datos (por ejemplo, una lista de alumnos).
   *
   * @param {number} newPage El número de la nueva página que se desea visualizar. Este número es usado
   * para actualizar el estado global correspondiente al tipo de lista actual (tipoContexto), afectando
   * directamente los datos que se solicitan y muestran en la UI.
   */
  const setPage = (newPage) => {
    setState(prevState => ({
      ...prevState,
      [tipoContexto]: { ...prevState[tipoContexto], page: newPage },
    }))
  }

  /**
   * Añade un nuevo ítem al estado actual del tipo de contexto especificado. Esta función es útil
   * para agregar dinámicamente nuevos elementos a la lista sin necesidad de realizar una nueva
   * solicitud a la API para obtener la lista actualizada. Esto puede mejorar la experiencia del
   * usuario al permitir la visualización inmediata de los nuevos elementos agregados.
   *
   * @param {Object} nuevoItem El nuevo elemento a ser añadido a la lista. Este objeto debe contener
   * todas las propiedades necesarias que se esperan mostrar en la UI para el tipo de lista actual
   * (tipoContexto). Por ejemplo, si estamos manejando una lista de 'alumnos', 'nuevoItem' debería
   * tener propiedades como 'nombre', 'apellido', 'id', etc., según se requiera para su visualización.
   */
  const agregarItem = (nuevoItem) => {
    setState((prevState) => {
      const estadoActualizado = { ...prevState }

      const estadoActual = estadoActualizado[tipoContexto] || {
        items: [],
        rowCount: 0,
        params: {},
        page: 1,
      }

      // Añade el nuevo ítem al array de ítems.
      estadoActualizado[tipoContexto] = {
        ...estadoActual,
        // Añade el nuevo ítem al principio de la lista (puedes cambiar esto para añadirlo al final si lo prefieres).
        items: [nuevoItem, ...estadoActual.items],
        // Aumenta el rowCount para reflejar el nuevo ítem añadido.
        rowCount: estadoActual.rowCount + 1,
      }

      return estadoActualizado;
    })
  }

  /**
   * Actualiza la lista de items basada en el tipo de contexto actual. Esta función es crucial para mantener
   * el estado sincronizado con los datos más recientes obtenidos, ya sea de la API o de interacciones dentro
   * de la aplicación. Se asegura de que cualquier cambio en los items, conteo de items, parámetros de búsqueda
   * o paginación sea reflejado en el estado global del contexto.
   *
   * @param {number} rowCount - El número total de items disponibles en el servidor, útil para la paginación.
   * @param {Array} items - Los items actuales a mostrar, obtenidos de la respuesta de la API.
   * @param {Object} params - Los parámetros de búsqueda o filtrado aplicados en la solicitud de los items.
   * @param {number} page - La página actual de items mostrados, importante para el control de la paginación.
   */
  const actualizarItems = (rowCount, items, params, page) => {
    setState(prevState => ({
      ...prevState,
      [tipoContexto]: { items, rowCount, params, page }
    }))
  }

  /**
   * Actualiza un item específico dentro del estado global. Esta función permite modificar el estado de un
   * solo item basado en su ID sin necesidad de refrescar toda la lista, optimizando el rendimiento y mejorando
   * la experiencia del usuario al evitar recargas innecesarias.
   *
   * @param {Object} updatedItem - El item con sus datos actualizados.
   */
  const actualizarItem = (updatedItem) => {
    setState(prevState => {
      const currentItems = prevState[tipoContexto].items
      const index = currentItems.findIndex(item => item.id === updatedItem.id)
      if (index !== -1) {
        const newItems = [...currentItems]
        newItems[index] = updatedItem
        return {
          ...prevState,
          [tipoContexto]: { ...prevState[tipoContexto], items: newItems },
        }
      }
      return prevState
    })
  }

  /**
   * Realiza una solicitud paginada para obtener items basados en el tipo de contexto actual. Utiliza la clase
   * de solicitud correspondiente al contexto para hacer una petición a la API y actualiza el estado con los
   * nuevos items obtenidos. Esta función es clave para implementar la funcionalidad de paginación en la aplicación.
   *
   * @param {number} newPage - El número de la nueva página a obtener.
   * @param {Function} onFetch - Callback opcional que se ejecuta con los items obtenidos, permitiendo acciones adicionales.
   */
  const fetchByPage = (newPage, onFetch = () => { }) => {
    const currentParams = state[tipoContexto].params
    let contextClass = requests[tipoContexto]

    if (contextClass) {
      let request = new contextClass()
      request.search({ ...currentParams, page: newPage }, (res) => {
        const queryItems = res[responses[tipoContexto]]
        actualizarItems(queryItems.total, queryItems.data, currentParams, newPage)
        if (onFetch) {
          onFetch(queryItems.data)
        }
      })
    }
  }

  /**
   * Restablece el estado del contexto al estado inicial para el tipo de contexto dado. Es útil para limpiar el estado
   * cuando se cambia de contexto o cuando es necesario reiniciar el estado por cualquier otro motivo, asegurando que
   * los datos mostrados sean consistentes y relevantes al contexto actual.
   */
  const resetContextState = () => {
    setState(prevState => ({
      ...prevState,
      [tipoContexto]: { items: [], rowCount: 1, params: {}, page: 0 },
    }))
  }

  // Efecto para inicializar o restablecer el estado para el tipo de contexto dado.
  useEffect(() => {
    if (!state[tipoContexto])
      actualizarItems(0, [], {}, 1)
  }, [tipoContexto])

  /**
   * Determina el estado actual para el tipo de contexto especificado. Esta línea es crucial para asegurar
   * que siempre haya un estado válido y consistente disponible para el contexto en uso. Al intentar acceder
   * al estado específico para el tipo de contexto (`tipoContexto`) proporcionado, se realiza una comprobación:
   * si existe un estado definido para ese tipo de contexto en el estado global (`state`), se utiliza ese estado;
   * de lo contrario, se recurre al estado inicial definido para ese tipo de contexto (`initialState[tipoContexto]`).
   * 
   * Esta operación garantiza que el componente consumidor siempre tenga acceso a los datos más relevantes y actuales,
   * incluso si el estado aún no ha sido inicializado o actualizado explícitamente para el tipo de contexto actual.
   * Es una medida de seguridad para manejar casos donde el estado puede no estar sincronizado o inicializado
   * adecuadamente en el momento del acceso, previniendo errores de acceso a propiedades de undefined o similares.
   */
  const listState = state[tipoContexto] || initialState[tipoContexto]

  // Objeto de valor de contexto que expone el estado y funciones para su manipulación.
  const contextValue = {
    ...listState,
    setPage,
    update: actualizarItems,
    updateItem: actualizarItem,
    addItem: agregarItem,
    resetContextState,
    fetchByPage,
  }

  // Renderiza el proveedor del contexto
  return (
    <EmpleadoListContext.Provider value={contextValue}>
      {children}
    </EmpleadoListContext.Provider>
  )
}

export { EmpleadoListProvider, EmpleadoListContext };
