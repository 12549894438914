import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import { Text } from 'components/texts'

export default function CoordinadoresTemplate({ register, unregister }) {
    const destinatario = "coordinador"

    useEffect(() => {
        register(destinatario)
        
        return () => unregister(destinatario)
    }, [])

    return (
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={4}>
            <Grid item xs={12}>
                <Text>Se enviará el mensaje a todos los coordinadores.</Text>
            </Grid>
        </Grid>
    )
}
