import { Grid } from "@mui/material";
import { Checkbox, Input } from "components/forms/components";
import { ItemsSelect } from "components/forms/components/Select/custom";
import { Text } from "components/texts";

/**
 * Componente `LibroTemplate` que define la estructura del formulario para la información de un libro.
 * Este componente se utiliza para crear o editar un libro, proporcionando campos como nombre, editorial, ISBN y estado activo.
 * 
 * @param {object} register - Función de registro de campos del formulario.
 * @param {object} errors - Objeto que contiene errores de validación.
 * @param {function} reset - Función para restablecer el formulario.
 * @returns {JSX.Element} - El formulario de información de un libro.
 */
export default function LibroTemplate({ register, errors, reset }) {
    return (
        <>
            <Grid container mt={2} mb={1}>
                <Text fontWeight={'bold'}>Según datos de:</Text><br />
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Input label="Libro" width={"100%"} name={"nombre"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect
                        name={"id_editorial"}
                        keyValue={"editorial"}
                        register={register}
                        errors={errors}
                        wantToReset={reset} />
                </Grid>

                <Grid item xs={6}>
                    <Input label="ISBN" width={"100%"} name={"isbn"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Checkbox name={"activo"} label={"Activo"} register={register} />
                </Grid>
            </Grid>
        </>
    )
}