import * as Yup from 'yup';
import { REGEX } from 'constants/Regex';

// const onlyNumbersSentence = 'Solo puede contener números.';
// const allRequiredSentence = 'Todos los campos son obligatorios si alguno está lleno.';
// const onlyNumbers = value => !value || /^\d+$/.test(value);

// const hasResponsables = (responsables) => responsables.length > 0

const phoneObject = { message: REGEX.telefono.message, excludeEmptyString: true };

// Función para determinar si alguno de los campos IBAN o los campos relacionados están llenos
const isAnyBankFieldFilled = ({ digitos_control, iban_2, iban_3, iban_4, iban_5, iban_6, primer_recibo_emitido, nombre_deudor, fecha_mandato }) =>
    primer_recibo_emitido || [digitos_control, iban_2, iban_3, iban_4, iban_5, iban_6, nombre_deudor, fecha_mandato].some(value => value)

// Esquema de validación completo
export const validationSchema = (responsables) => Yup.object().shape({

    nombre: Yup.string().required('El nombre es obligatorio.'),
    apellido1: Yup.string().required('El primer apellido es obligatorio.'),
    email: Yup.string().required('El email es obligatorio').email('El formato del email no es correcto.'),
    email2: Yup.string().email('El formato del email no es correcto..'),
    numero_documento: Yup.string().required('El número de documento es obligatorio.'),
    //numero_documento: Yup.string('El número de documento es obligatorio.'),                                           PEDIENTE DE CONFIRMAR 
    tipo_documento_id: Yup.string().required('El tipo de documento es obligatorio.'),
    //tipo_documento_id: Yup.string('El tipo de documento es obligatorio.'),                                            PENDIENTE DE CONFIRMAR
    sexo_id: Yup.string().required('El género es obligatorio.'),
    telefono1: Yup.string().required('El teléfono principal es obligatorio.').matches(REGEX.telefono.validator, phoneObject),
    telefono2: Yup.string().matches(REGEX.telefono.validator, phoneObject),
    tlfno_movil1: Yup.string().matches(REGEX.telefono.validator, phoneObject),
    tlfno_movil2: Yup.string().matches(REGEX.telefono.validator, phoneObject),
    cod_postal: Yup.string().matches(REGEX.codigo_postal.validator, { message: REGEX.codigo_postal.message, excludeEmptyString: true }),
    fecha_nacimiento: Yup.string().required('La fecha de nacimiento es obligatoria.'),
    /**
     * Validación condicional para los campos del responsable
     * TODO: revisar, no sé si se están usando estas validaciones
     */
    responsable_nombre: Yup.string(),

    responsable_apellido1: Yup.string().when('responsable_nombre', {
        is: nombre => nombre && nombre.length > 0,
        then: Yup.string().required('El primer apellido del responsable es obligatorio.'),
        otherwise: Yup.string()
    }),

    responsable_email: Yup.string().when('responsable_nombre', {
        is: nombre => nombre && nombre.length > 0,
        then: Yup.string().required('El correo del responsable es obligatorio.'),
        otherwise: Yup.string()
    }),

    responsable_tipo_documento_id: Yup.string().when('responsable_nombre', {
        is: nombre => nombre && nombre.length > 0,
        then: Yup.string().required('El tipo de documento del responsable es obligatorio.'),
        otherwise: Yup.string().email('El formato del email no es correcto.')
    }),

    responsable_numero_documento: Yup.string().when(['responsable_nombre', 'numero_documento'], {
        is: (responsable_nombre, numero_documento) => responsable_nombre && responsable_nombre.length > 0,
        then: Yup.string()
            .required('El número de documento del responsable es obligatorio.')
            .notOneOf([Yup.ref('numero_documento')], 'El número de documento del responsable no puede ser igual al número de documento del alumno.'),
        otherwise: Yup.string()
    }),

    fecha_mandato: Yup.string().test(
        'fecha_mandato',
        'La fecha de mandato es obligatoria.',
        function (value) {
            if (isAnyBankFieldFilled(this.parent) && !value)
                return false
            return true
        }
    ),

    nombre_deudor: Yup.string().test(
        'nombre_deudor',
        'El nombre del deudor es obligatorio.',
        function (value) {
            if (isAnyBankFieldFilled(this.parent) && !value)
                return false
            return true
        }
    )

    // ...bankFieldsValidation,
});