import RequestMaster from '../RequestMaster'

export default class RequestAlumnoResponsable extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/alumno_responsable'
    }


    /**
     * Hace el create de la relacion entre responsable y alumno
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    create(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.post(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Actualiza la relación entre responsable y alumno
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    update(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "update"
        this.put(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Hace el borrado de la relacion entre un responsable y un alumno
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    erase(id, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "" + id
        this.delete(path, params, callback, callbackError, callbackFinally)
    }
}
