import Statics from "objects/common/Statics"
import { RequestFacturacionColegio, RequestDetalleFacturacionColegio } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    getFacturacionColegio(getValues, callbackOk = () => { }, callbackError = () => { }) {
        const params = {
            curso_escolar_id: getValues('curso_escolar_id'),
            colegio_id: getValues('colegio_id'),
            mes: getValues('mes'),
            relations: 'detalles,colegio'
        }

        new RequestFacturacionColegio().search(params, (res) => {
            res.items?.data && res.items?.data.length > 0 ? callbackOk(res.items.data[0]) : callbackError(res)
        }, callbackError)
    }


    getDetalleFacturacionColegio(id, callbackOk = () => { }, callbackError = () => { }) {
        const params = { relations: 'detalles' }

        new RequestDetalleFacturacionColegio().get(id, params, (res) => {
            res.item ? callbackOk(res.item) : callbackError(res)
        }, callbackError)
    }


    editDetalles(detallesToEdit, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()
        if (detallesToEdit.length == 1) {
            formData.append('num_alumnos', detallesToEdit[0].num_alumnos)
            new RequestDetalleFacturacionColegio().put(detallesToEdit[0].id, formData, callbackOk, callbackError)
        } else if (detallesToEdit.length >= 1) {
            for (const index in detallesToEdit) {
                formData.append(`detalles[${index}][id]`, detallesToEdit[index].id)
                formData.append(`detalles[${index}][num_alumnos]`, detallesToEdit[index].num_alumnos)
            }

            new RequestDetalleFacturacionColegio().editMultiple(formData, callbackOk, callbackError)
        }
    }

    updateFacturacionColegio(pago_id, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()
        formData.append('forma_pago_id', getValues("forma_pago_id"))
        formData.append('pagado', getValues("pagado") ? 1 : 0)

        const fecha_pago = getValues("fecha_pago")
        formData.append('fecha_pago', Statics.datePickerTimeFormat(fecha_pago))

        new RequestFacturacionColegio().put(pago_id, formData, callbackOk, callbackError)
    }
}
