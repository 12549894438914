import Modal from 'components/modals/Modal'
import React from 'react'
import { Text } from 'components/texts'
import { Line } from 'components/data_display'
import { Stack, Grid } from '@mui/material'
import { Button } from 'components/forms/components'
import { SeguimientoTrimestralGrupoColegioForm } from 'components/forms/display'
import Requests from './Requests/Requests'
import { TrimestreSelect } from 'components/forms/components/Select/custom'
import { useSeguimientoReportLogic } from 'hooks'

/**
 * Modal con el formulario de busqueda de los grupos de colegios
 * 
 * @param {Boolean} open - Indica el estado visible o no visible del modal
 * @param {Object} grupo - Objeto con la informacion del grupo
 * @param {Object} inscripcion - Objeto con la informacion de la inscripcion
 * @param {String} tipoGrupo - Indica el tipo de grupo (colegio, academia)
 * 
 * @returns {JSX.Element}
 */
export default function SeguimientoTrimestralModal({ open = false, grupo, inscripcion, tipoGrupo }) {
    const { trimestre, setTrimestre, register, handleSubmit, errors, modalRef, createSeguimiento, alumno, unregisterFormFields } = useSeguimientoReportLogic({ open, inscripcion, tipoGrupo, requests: new Requests() })

    return (
        <Modal ref={modalRef} title={"Boletín Informativo Trimestral de los Colegios"}>
            <Grid container p={5} spacing={2}>
                <Grid item xs={4}>
                    <Stack display={"flex"} flexDirection={"row"} columnGap={1}>
                        <Text fontWeight="bold">Clave:</Text>
                        <Text> {grupo?.clave ?? ""}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={4}>
                    <Stack display={"flex"} flexDirection={"row"} columnGap={1}>
                        <Text fontWeight="bold">Curso escolar:</Text>
                        <Text> {grupo?.curso_escolar?.valor ?? ""}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={4}>
                    <Stack display={"flex"} flexDirection={"row"} columnGap={1}>
                        <Text fontWeight="bold">Profesor:</Text>
                        <Text> {(grupo?.profesor_actual?.nombre ?? "") + " " + (grupo?.profesor_actual?.apellido1 ?? "") + " " + (grupo?.profesor_actual?.apellido2 ?? "")}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={4}>
                    <Stack display={"flex"} flexDirection={"row"} columnGap={1}>
                        <Text fontWeight="bold">Alumno:</Text>
                        <Text> {(alumno?.nombre ?? "") + " " + (alumno?.apellido1 ?? "") + " " + (alumno?.apellido2 ?? "")}</Text>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <Stack display={"flex"} flexDirection={"row"} columnGap={1}>
                        <Text fontWeight="bold">Faltas de asistencia este trimestre:</Text>
                        <Text> {inscripcion?.faltas_asistencia.length ?? 0}</Text>
                    </Stack>
                </Grid>
                {tipoGrupo == 'colegio' ?
                    <Grid item xs={4}>
                        <Stack display={"flex"} flexDirection={"row"} columnGap={1}>
                            <Text fontWeight="bold">Colegio:</Text>
                            <Text> {grupo?.colegio?.nombre ?? ""}</Text>
                        </Stack>
                    </Grid> : null}
            </Grid>

            <Grid container>
                <Line />
            </Grid>

            <Grid container my={5} px={5}>
                <TrimestreSelect register={register} errors={errors} onChange={({ target }) => {
                    unregisterFormFields()
                    setTrimestre(target.value + "")
                }} />
            </Grid>

            <Grid container>
                <Line />
            </Grid>

            <SeguimientoTrimestralGrupoColegioForm
                key={Math.random()}
                register={register}
                errors={errors}
                trimestre={inscripcion?.seguimiento_trimestral.find(item => item.trimestre == trimestre) ?? null} />

            <Grid container pb={5} px={5} display={"flex"} justifyContent={"end"}>
                <Button onClick={handleSubmit(createSeguimiento)}>Guardar cambios</Button>
            </Grid>
        </Modal>
    )
}    