import React from 'react'
import { H3, Text } from 'components/texts'
import { Grid, Stack } from '@mui/material'
import { Line } from 'components/data_display'
import { ProgressSheetTable } from 'components/data_display'

/**
 * Formulario con los datos de los idiomas del grupo
 */
export default function IdiomaGrupoColegioData({ grupo }) {
    
    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container py={5}>
            <Grid container spacing={2} px={5}>
                <Grid item xs={4}>
                    <Stack>
                        <Text fontWeight={"bold"}>Libro principal</Text>
                        <Text>{grupo?.libro?.nombre ?? "Sin especificar"}</Text>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <Stack>
                        <Text fontWeight={"bold"}>Libro asociado</Text>
                        <Text>{grupo?.libro?.libro_asociado?.nombre ?? "Sin especificar"}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={4}>
                    <Stack>
                        <Text fontWeight={"bold"}>Usa libro asociado</Text>
                        <Text>{grupo?.usar_libro_asociado ? "Sí" : "No"}</Text>
                    </Stack>
                </Grid>
            </Grid>

            <Grid container my={3} px={5}>
                <Line />
            </Grid>

            <Grid container px={5}>
                <Grid item xs={12}>
                    <H3>Progress sheet</H3>
                </Grid>

                <Grid item xs={12}>
                    <Stack>
                        <Text fontWeight={"bold"}>Se usa Progress Sheet</Text>
                        <Text>{grupo?.usar_progress_sheet ? "Sí" : "No"}</Text>
                    </Stack>
                </Grid>

                <ProgressSheetTable />
            </Grid>
        </Grid>
    )
}
