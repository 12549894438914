import Skeleton from './skeleton'
import React, { useRef } from 'react'
// import { ASSETS } from 'constants/Assets'
import Statics from 'objects/common/Statics'
import { Link } from 'components/data_display'
import { H1, H3, Text } from 'components/texts'
import { Avatar, Box, Grid } from '@mui/material'
import { Button } from 'components/forms/components'
import { DeleteResponsableModal } from 'components/modals'
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { useImageLoader } from 'hooks'
import { DEFAULT_IMAGES_URL } from 'constants/DefaultImagesUR'

export default function ResponsableData({ responsable, navigate }) {
  const deleteResponsableModal = useRef()

  const { imageSrc } = useImageLoader(responsable, 'foto_logo', DEFAULT_IMAGES_URL.responsable, true)

  return (
    <Grid container>
      {!responsable ? <Skeleton /> : (
        <>
          <Grid item xs={2}>
            <Avatar
              alt={"Foto de: " + (responsable?.nombre ?? "")}
              // src={`${ASSETS.images + (responsable.foto_logo ? responsable.foto_logo : 'private/responsable/perfiles/0/default.png')}`}
              src={imageSrc}
              sx={{ width: "80%", height: "100%", borderRadius: 2 }} />
          </Grid>

          <Grid item xs={8}>
            <H1 className="profile-id-text">{"#" + responsable.id}</H1>

            <Box mt={2}>
              <H3>{responsable.nombre + " " + (responsable.apellido1 ?? "") + " " + (responsable.apellido2 ?? "")}</H3>

              <Box display={"flex"}>
                <Text fontWeight={"bold"}>Documento de identidad:</Text>
                <Text className="mx-2">{responsable.numero_documento + " - " + responsable.tipo_documento?.nombre}</Text>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={2} display={"flex"} justifyContent={"end"} alignItems={"start"}>
            <Link to="editar">
              <Button variant={"outlined"} startIcon={<EditIcon />} className={"mx-2"}>
                Editar
              </Button>
            </Link>

            <Link>
              <Button variant={"outlined"} color={"cancel"} startIcon={<DeleteIcon />} onClick={() => { deleteResponsableModal.current.open(responsable) }}>
                Eliminar
              </Button>
            </Link>
          </Grid>

          <Grid item xs={12} mt={5}>
            <H3>Datos de contacto:</H3>
          </Grid>

          <Grid container mt={5}>
            <Grid item xs={4} display={"flex"} flexDirection={"column"}>
              <Text fontWeight={"bold"}>Profesión</Text>
              <Text>{responsable.profesion ?? "Sin rellenar"}</Text>
            </Grid>

            <Grid item xs={4} display={"flex"} flexDirection={"column"}>
              <Text fontWeight={"bold"}>Teléfono móvil</Text>
              <Text>{responsable.telefono_movil ?? "Sin rellenar"}</Text>
            </Grid>

            <Grid item xs={4} display={"flex"} flexDirection={"column"}>
              <Text fontWeight={"bold"}>Otro teléfono</Text>
              <Text>{responsable.telefono_otros ?? "Sin rellenar"}</Text>
            </Grid>
          </Grid>

          <Grid container mt={1}>
            <Grid item xs={4} display={"flex"} flexDirection={"column"}>
              <Text fontWeight={"bold"}>Correo electrónico</Text>
              <Text>{responsable.email ?? "Sin rellenar"}</Text>
            </Grid>

            <Grid item xs={4} display={"flex"} flexDirection={"column"}>
              <Text fontWeight={"bold"}>Teléfono trabajo</Text>
              <Text>{responsable.telefono_trabajo ?? "Sin rellenar"}</Text>
            </Grid>
          </Grid>

          <DeleteResponsableModal
            ref={(deleteResponsableModal)}
            onDeleteMessage={"Se ha borrado el tutor correctamente. Redirigiendo.."}
            onDeleteCallback={async () => {
              await Statics.delay(1500)
              navigate('./../')
            }} />
        </>
      )}
    </Grid>
  )
}