import { Link } from 'components/data_display'
import { auxFieldName } from 'constants/Responses'
import { Button } from 'components/forms/components'

export const basicColumns = [
    {
        field: "actions",
        type: 'actions',
        headerName: "Acciones",
        minWidth: 200,
        getActions: (params) => [<Link to={"" + params.row.id}><Button size={"small"}>Ver</Button></Link>, <Link to={"" + params.row.id + "/editar"}><Button size={"small"} variant={"outlined"}>Editar</Button></Link>]
    },

    { field: "id_alumno", headerName: "ID Alumn.", maxWidth: 65, sortable: false, filterable: false  },

]

export const columnsAlumnosColegios = [
    { field: "colegio",       headerName: "Colegio",  minWidth: 100, flex: 1, valueGetter: (params) => { return params.row.colegio?.nombre }, sortable: false, filterable: false  },
    { field: "alumno",        headerName: "Alumno",   minWidth: 100, flex: 1, valueGetter: (params) => { 
        return params.row.alumno?.nombre + " " + params.row.alumno?.apellido1 + " " + params.row.alumno?.apellido2
    }, sortable: false, filterable: false },
    { field: "curso_escolar", headerName: "Año",      minWidth: 100, valueGetter: (params) => (params.row.curso_escolar?.valor ?? ""), sortable: false, filterable: false },
    { field: "curso_colegio", headerName: "Curso",    maxWidth: 100, valueGetter: (params) => (params.row.curso_colegio?.valor ?? ""), sortable: false, filterable: false },
]

export const columnsGrupoColegio = [
    { field: "grupo_colegio", headerName: "Grupo", minWidth: 100, flex: 1, sortable: false, filterable: false, valueGetter: (params) => { return params?.row?.clave }  },
    { field: "alumno",        headerName: "Alumno",    minWidth: 100, flex: 1, sortable: false, filterable: false  },
    { field: "colegio",       headerName: "Colegio",  minWidth: 100, flex: 1, sortable: false, filterable: false  },
    { field: "curso_escolar", headerName: "Año",      minWidth: 100, sortable: false, filterable: false },
    { field: "curso_colegio", headerName: "Curso",    maxWidth: 100, sortable: false, filterable: false },
]

export const columnsGrupoCL = [
    { field: "grupo_colegio", headerName: "Grupo",  minWidth: 100, flex: 1, sortable: false, filterable: false  },
    { field: "alumno",        headerName: "Alumno",    minWidth: 100, flex: 1, sortable: false, filterable: false  },
    { field: "colegio",       headerName: "Colegio",  minWidth: 100, flex: 1, sortable: false, filterable: false  },
    { field: "curso_escolar", headerName: "Año",      minWidth: 100, sortable: false, filterable: false },
    { field: "curso_colegio", headerName: "Curso",    maxWidth: 100, sortable: false, filterable: false },
]