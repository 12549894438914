import { Component } from 'react'
import { RequestAlumno, RequestDomiciliacionBancaria } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests extends Component {

    /**
     * Hace la petición para editar el usuario
     * 
     * @param {Int}      id 
     * @param {Object}   values 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createAlumno(values, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()
        formData.append("nombre", values.nombre.toUpperCase())
        formData.append("apellido1", values.apellido1.toUpperCase())
        formData.append("apellido2", values.apellido2.toUpperCase())
        formData.append("email", values.email.toUpperCase())
        formData.append("email2", values.email2.toUpperCase())
        formData.append("numero_documento", values.numero_documento.toUpperCase())
        formData.append("tipo_documento_id", values.tipo_documento_id)
        formData.append("nombre_via", values.nombre_via.toUpperCase())
        formData.append("tipo_via_id", values.tipo_via_id)
        formData.append("cod_postal", values.cod_postal)
        formData.append("pais_nacimiento", values.pais_nacimiento.toUpperCase())
        formData.append("provincia_id", values.provincia_id)
        formData.append("sexo_id", values.sexo_id)
        formData.append("provincia_nacimiento_id", values.provincia_nacimiento_id)
        formData.append("municipio", values.municipio.toUpperCase())
        formData.append("municipio_nacimiento", values.municipio_nacimiento.toUpperCase())
        formData.append("fecha_nacimiento", values.fecha_nacimiento ?? "")
        formData.append("telefono1", values.telefono1)
        formData.append("telefono2", values.telefono2)
        formData.append("tlfno_movil1", values.tlfno_movil1)
        formData.append("tlfno_movil2", values.tlfno_movil2)
        if (values.foto_logo[0])
            formData.append("foto_logo", values.foto_logo[0])

        new RequestAlumno().create(formData, callbackOk, callbackError)
    }

    /**
     * Hace la peticion para editar los datos bancarios del usuario
     * 
     * @param {Int}      id 
     * @param {Object}   values 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createDatosBancarios(id, values, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()
        
        if (values.iban_2) {
            formData.append("codigo_pais", "ES")
            formData.append("digitos_control", values.digitos_control)
            formData.append("referencia_mandato", `CL_` + id)
            formData.append("ultimo_recibo", values.ultimo_recibo)
            formData.append("primer_recibo_emitido", values.primer_recibo_emitido ? 1 : 0)
            formData.append("nombre_deudor", values.nombre_deudor.toUpperCase())
            formData.append("fecha_mandato", values.fecha_mandato)
            formData.append("domiciliacion_bancaria_ibanable_id", id) // ID del alumno
            formData.append("domiciliacion_bancaria_ibanable_type", 'alumno') // tipo de modelo

            const iban = `${values.iban_2}${values.iban_3}${values.iban_4}${values.iban_5}${values.iban_6}`
            formData.append("numero_cuenta", iban)

            new RequestDomiciliacionBancaria().create(formData, callbackOk, callbackError)
        }

    }
}
