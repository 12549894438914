import * as Yup from 'yup'
import moment from 'moment'

const validationSchema = Yup.object().shape({
    motivo_incidencia_fichaje_id:   Yup.string().required('El motivo de la incidencia es obligatorio.'),
    fecha:                          Yup.string().required('La fecha es obligatoria'),
    hora_inicio:                    Yup.string()
                                        .test("hora-fin-not-empty", "Tienes que rellenar la hora de inicio también", function(value) {
                                            const { hora_fin } = this.parent;
                                            return !(hora_fin != '' && value == '')
                                        }),
    hora_fin:                       Yup.string()
                                        .test("hora-inicio-not-empty", "Tienes que rellenar la hora de fin también", function(value) {
                                            const { hora_inicio } = this.parent;
                                            return !(hora_inicio != '' && value == '')
                                        })
                                        .test("is-greater", "La hora de fin no puede ser menor que la hora de inicio", function(value) {
                                            const { hora_inicio } = this.parent;
                                            if(hora_inicio == '') return true
                                            
                                            return moment(value, "HH:mm").isSameOrAfter(moment(hora_inicio, "HH:mm"));
                                        })
})

export default validationSchema