import Statics from 'objects/common/Statics'

export const columns = [
    { field: "tipo_documento", headerName: "Tipo de documento", minWidth: 100, valueGetter: (params) => { return params.row.empleado?.tipo_documento?.nombre } },
    { field: "numero_documento", headerName: "Número documento", minWidth: 100, valueGetter: (params) => { return params.row.empleado?.numero_documento } },
    { field: "docente", headerName: "Docente", minWidth: 250, valueGetter: (params) => {
        const { empleado } = params.row
        return (empleado.nombre ?? "") + " " + (empleado.apellido1 ?? "") + " " + (empleado.apellido2 ?? "")
    } },
    { field: "dia", headerName: "Día", minWidth: 100, valueGetter: (params) => {
        return params.row.dia ? Statics.formatDate(params.row.dia) : ""
    }},
    { field: "tramo-horario", headerName: "Tramo horario", minWidth: 150, valueGetter: (params) => {
        return (params.row.hora_inicio ?? "") + " - " + (params.row.hora_fin ?? "")
    }},
    { field: "localizacion", headerName: "Localización", minWidth: 150, renderCell: (params) => {
        const { coordenadas } = params.row
        return <a target="_blank" style={{color: "#7F8790", textDecoration: "underline" }} href={`https://www.google.com/maps/search/?api=1&query=${coordenadas.latitud},${coordenadas.longitud}`}>Ver en maps</a>
    }},
]