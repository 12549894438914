import { Grid } from '@mui/material'
import React, { Component } from 'react'
import Skeleton from '@mui/material/Skeleton'

/**
 * Informacion básica del alumno
 */
export default class SkeletonGroupAcademiaData extends Component {

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {

    return (
      <Grid container spacing={2} mt={2} p={4}>

        <Grid item xs={6}>
          <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"30%"} />
        </Grid>

        <Grid item xs={6}>
          <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"80%"} />
          <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"80%"} />
        </Grid>

        <Grid item xs={12}>
          <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"80%"} />
        </Grid>

        <Grid item xs={2}>
          <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"80%"} />
        </Grid>

      </Grid>
    )
  }
}
