import React, { useState, useEffect, useContext} from 'react'
import { Grid, Box, Container, Tab, Tabs } from "@mui/material";
import { Text } from 'components/texts/index.js'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { ItemsSelect } from 'components/forms/components/Select/custom';
import {
    HorasExtra, Partidos, Sustituciones, FaltasAsistencia
} from './parts';
import { COLOR } from 'theme/Colors.js'
import { TabContext } from '@mui/lab'

export default function AsistenciaYextras({ register, errors, setValue, horasExtra, getValues, onChangeCursoEscolar, unregister, sustituciones, partido }) {
    const [tab, setTab] = useState("1")

    return (
        <Grid container p={3}>
            <Container maxWidth={false} sx={{ mt: 2 }}>

                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
                        <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                            <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>FALTAS DE ASISTENCIA</Text>} value={"1"} />
                            <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>SUSTITUCIONES</Text>} value={"2"} />
                            <Tab label={<Text fontWeight={tab === "3" ? "bold" : null} color={tab === "3" ? COLOR.standard.main : null}>HORAS EXTRAS</Text>} value={"3"} />
                            <Tab label={<Text fontWeight={tab === "4" ? "bold" : null} color={tab === "4" ? COLOR.standard.main : null}>PARTIDOS</Text>} value={"4"} />
                        </Tabs>
                    </Box>

                    <TabPanel value={"1"}>
                        <FaltasAsistencia setValue={setValue} errors={errors} register={register} getValues={getValues}
                            onChangeCursoEscolar={onChangeCursoEscolar} />
                    </TabPanel>

                    <TabPanel value={"2"}>
                        <Sustituciones sustituciones={sustituciones} setValue={setValue} errors={errors} register={register} getValues={getValues}
                            onChangeCursoEscolar={onChangeCursoEscolar} 
                            // cursoEscolar={tab === "2" ? cursoEscolar : null}
                             />
                    </TabPanel>

                    <TabPanel value={"3"}>
                        <HorasExtra
                            horasExtra={horasExtra}
                            unregister={unregister}
                            // cursoEscolar={tab === "3" ? cursoEscolar : null}
                            onChangeCursoEscolar={onChangeCursoEscolar}
                            setValue={setValue}
                            errors={errors}
                            register={register}
                            getValues={getValues} />
                    </TabPanel>

                    <TabPanel value={"4"}>
                        <Partidos partido={partido} 
                        // cursoEscolar={cursoEscolar} 
                        onChangeCursoEscolar={onChangeCursoEscolar} setValue={setValue} errors={errors}
                            register={register} getValues={getValues} />
                    </TabPanel>

                </TabContext>
            </Container>
        </Grid>
    )
}

