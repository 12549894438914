import './style.css'
import { v4 as uuid } from 'uuid'
// import TableColumn from '../TableColumn'
import React, { useEffect, useState } from 'react'
import { erase, add, edit } from '../CRUD'
import { H3, Text } from 'components/texts'
import { enqueueSnackbar } from 'notistack'
import Statics from 'objects/common/Statics'
import AddIcon from '@mui/icons-material/Add'
import { libroPedidoObject } from './constants'
import RemoveIcon from '@mui/icons-material/Remove'
import { Button, TableColumn } from 'components/forms/components'

/**
 * Componente `LibrosPedidos` que proporciona una interfaz de usuario para gestionar una lista de libros pedidos.
 * Permite añadir, editar y eliminar libros de la lista, además de visualizar el total de unidades pedidas y llegadas.
 * 
 * Template usado para el create y el edit
 * 
 * @param {Object} props - Propiedades del componente.
 * @param {Array} props.librosPedidosProp - La lista inicial de libros pedidos.
 * @param {String} props.cursoEscolarProp - El curso escolar actual.
 * @param {Function} props.onUpdate - Función para actualizar el estado en el componente padre.
 * 
 * @returns {JSX.Element} - Una tabla que muestra los libros pedidos con opciones para editar, eliminar y añadir nuevos libros.
 */
export default function LibrosPedidos({ cursoEscolar: cursoEscolarProp, librosPedidos: librosPedidosProp, onUpdate }) {
    const [librosPedidos, setLibrosPedidos] = useState(librosPedidosProp ?? [])
    const [cursoEscolar, setCursoEscolar] = useState(cursoEscolarProp)

    useEffect(() => {
        if (cursoEscolarProp !== cursoEscolar) {
            setCursoEscolar(cursoEscolarProp)
        }
        if (librosPedidosProp !== librosPedidos) {
            setLibrosPedidos(librosPedidosProp)
        }
    }, [cursoEscolarProp, librosPedidosProp])

    const updateLibrosPedidos = (librosPedidos) =>
        onUpdate([...librosPedidos])

    const librosToRender = cursoEscolar ? librosPedidos?.filter(item => item.id_curso_escolar == cursoEscolar && item.deleted != true) ?? [] : []
    const sumPedidas = Statics.sumParamsOfObjectArray(librosToRender, "cantidad_pedida")
    const sumLlegadas = Statics.sumParamsOfObjectArray(librosToRender, "cantidad_llegada")

    return (
        <>
            <H3>Libros pedidos: </H3>
            <table className='libros-pedidos-table'>
                <thead>
                    <tr>
                        <th></th>
                        <th><Text fontWeight={"bold"}>Fecha</Text></th>
                        <th><Text fontWeight={"bold"}>Unidades pedidas</Text></th>
                        <th><Text fontWeight={"bold"}>Unidades llegadas</Text></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className='no-border'>
                    {librosToRender.map((item, index, array) => (
                        <tr key={uuid()}>
                            <td className='bg-white'>
                                <Button style={{ borderRadius: 200 }} color={"error"} onClick={() => {
                                    const librosPedidosLocal = erase(item, librosPedidos)
                                    updateLibrosPedidos(librosPedidosLocal)
                                }} iconType>
                                    <RemoveIcon />
                                </Button>
                            </td>

                            <TableColumn defaultValue={item.fecha} type={"date"} onEdit={(value) => {
                                const librosPedidosLocal = edit(item, value, "fecha", librosPedidos)
                                updateLibrosPedidos(librosPedidosLocal)
                            }} />

                            <TableColumn defaultValue={item.cantidad_pedida} onEdit={(value) => {
                                const librosPedidosLocal = edit(item, value, "cantidad_pedida", librosPedidos)
                                updateLibrosPedidos(librosPedidosLocal)
                            }} />

                            <TableColumn defaultValue={item.cantidad_llegada} onEdit={(value) => {
                                const librosPedidosLocal = edit(item, value, "cantidad_llegada", librosPedidos)
                                updateLibrosPedidos(librosPedidosLocal)
                            }} />

                            {array.length === index + 1 && cursoEscolar ? (
                                <td className='bg-white'>
                                    <Button iconType onClick={() => {
                                        const librosPedidosLocal = add(librosToRender, librosPedidos, cursoEscolar, libroPedidoObject)
                                        librosPedidosLocal ? updateLibrosPedidos(librosPedidosLocal) : enqueueSnackbar(<Text>Rellena el pedido anterior para añadir más.</Text>, { variant: "error" })
                                    }}>
                                        <AddIcon />
                                    </Button>
                                </td>
                            ) : null}
                        </tr>
                    ))}

                    {librosToRender.length == 0 && cursoEscolar ? (
                        <tr>
                            <td className='bg-white' colSpan={4}></td>
                            <td className='bg-white'>
                                <Button iconType onClick={() => {
                                    console.log('click button');
                                    const librosPedidosLocal = add(librosToRender, librosPedidos, cursoEscolar, libroPedidoObject)
                                    librosPedidosLocal ? updateLibrosPedidos(librosPedidosLocal) : enqueueSnackbar(<Text>Rellena el pedido anterior para añadir más.</Text>, { variant: "error" })
                                }}>
                                    <AddIcon />
                                </Button>
                            </td>
                        </tr>
                    ) : null}

                    <tr className="total-unidades">
                        <td colSpan={2} className='bg-white'><Text fontWeight="bold">Total unidades</Text></td>
                        <td><Text>{sumPedidas}</Text></td>
                        <td><Text>{sumLlegadas}</Text></td>
                    </tr>
                </tbody>
            </table>

        </>
    )
}