import { H3 } from 'components/texts'
import { Box, Container, Paper } from '@mui/material'
import { useAcademiaInscripcionListLogic } from 'hooks'
import { Search as SearchIcon } from '@mui/icons-material'
import { ListTableHeader } from 'pages/logged/admin/components'
import { Accordion, BreadcrumbsList, Datagrid } from 'components/data_display'
import { basicColumns, grupoColumns, testNivelColumns, empresaColumns } from './columns.js'
import SearchFormInscripcionesAcademias, { search } from 'components/forms/display/academia/inscripciones/SearchForm/index.jsx'

/**
 * Componente funcional que renderiza el listado de inscripciones de las academias.
 * Utiliza el hook `useLogic` para su lógica interna y maneja la presentación y filtrado de los datos.
 * 
 * @returns {JSX.Element}
 */
export default function ListadoInscripcionesAcademia() {
  const { items, rowCount, params, setLoadingTable, updateTable, onChangeSearchIndex, columns, loadingTable } = useAcademiaInscripcionListLogic({ basicColumns, grupoColumns, testNivelColumns, empresaColumns })

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <BreadcrumbsList breadcrumbsList={[
        { to: "/", label: "Inicio" },
        { label: "Academia" },
        { to: "./", label: "Inscripciones", actualItem: true },
      ]} />

      <Accordion header={<Box display={"flex"} alignItems={"center"}><SearchIcon className={"mx-5"} /> <H3 className="my-0">Buscar inscripciones</H3></Box>}>
        <SearchFormInscripcionesAcademias
          searchCallback={updateTable}
          onChangeSearchIndex={onChangeSearchIndex}
          onLoadingCallback={setLoadingTable} />
      </Accordion>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
        <ListTableHeader rowCount={rowCount} params={params} keyValue={"inscripcion_academia"} />

        <Datagrid
          rows={items}
          columns={columns}
          loading={loadingTable}
          rowCount={rowCount}
          onPageChange={(page) => { search(page, () => setLoadingTable(true), updateTable) }}
          serverSide />
      </Paper>
    </Container>
  )
}