import { Component } from 'react'
import { RequestAula } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests extends Component {

    /**
     * Hace la petición para crear las aulas
     * 
     * @param {Function} values 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createAula(values, callbackOk = () => {}, callbackError = () => {}) {
        const formData = new FormData()
        formData.append("denominacion", values.denominacion.toUpperCase())
        formData.append("capacidad",    values.capacidad)

        new RequestAula().create(formData, callbackOk, callbackError)
    }
}
