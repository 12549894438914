import { EmpleadoListContext } from "contexts/employee/ListContext"
import { useContext, useEffect, useState } from "react"

/**
 * Hook personalizado para gestionar la lógica de búsqueda y actualización en un componente de datatable.
 * Mantiene el estado de los datos de la tabla, el conteo de filas, el estado de carga y los parámetros de búsqueda.
 * Proporciona una función `updateTable` para actualizar los datos de la tabla después de una petición,
 * manejando también el conteo de filas y la paginación.
 *
 * @returns {Object} Objeto con los siguientes elementos:
 * - data: Array de los datos actuales de la tabla.
 * - updateTable: Función para actualizar los datos de la tabla, el conteo de filas y los parámetros de búsqueda.
 *                Debe ser llamada con los nuevos datos, conteo de filas, número de página y parámetros después de una petición.
 * - loadingTable: Estado booleano para indicar si la tabla está cargando datos.
 * - setLoadingTable: Función para establecer el estado de carga de la tabla.
 * - params: Objeto con los parámetros actuales de búsqueda en la tabla.
 *
 * Ejemplo de uso:
 * const { data, updateTable, loadingTable, setLoadingTable, params } = useSearchLogic();
 * // Utilizar `updateTable` para actualizar los datos después de una petición.
 */
export default function useSearchLogic() {
    const { items, rowCount, params, update } = useContext(EmpleadoListContext)
    const [loadingTable, setLoadingTable] = useState(false)

    useEffect(() => {
        setLoadingTable(false)
    }, [items])

    /**
     * Actualiza los datos de la tabla y el contexto global con los resultados obtenidos de la búsqueda.
     * 
     * @param {Array} data - Los datos obtenidos para llenar la tabla.
     * @param {number} rowCount - El número total de filas encontradas para la búsqueda realizada.
     * @param {number} page - La página actual de los resultados.
     * @param {Object} params - Los parámetros de búsqueda utilizados.
     */
    const updateTable = (newData, newRowCount, newPage, newParams) => update(newRowCount, newData, newParams, newPage)

    return { items, rowCount, updateTable, loadingTable, setLoadingTable, params }
}