import React from 'react'
import { H3, Text } from 'components/texts'
import { Box, Stack } from '@mui/material'
import { Line } from 'components/data_display'
import { Button } from 'components/forms/components'
import Requests from '../../Requests/Requests'
import { enqueueSnackbar } from 'notistack'

export default function SinglePagosColegio({ handleSubmit, getValues, getValues2, onPagoFetched }) {

  const getPagos = () => {
    new Requests().getFacturacionColegio(getValues, onPagoFetched, (err) => {
      enqueueSnackbar(<Text>{err}</Text>, { variant: "error" })
    })
  }

  const test = () => {
    console.log('Realizar función para actualizar pagos')
  }

  return (
    <Box>
      <Stack px={5} py={2} display={"flex"} flexDirection={"row"} alignItems="center" justifyContent={"space-between"}>
        <H3 className="mb-0">Pagos de los colegios</H3>

        <Stack display={"flex"} flexDirection={"row"} alignItems="center" columnGap={2}>
          <Button size={"small"} onClick={handleSubmit(getPagos)}>Ver pagos</Button>
          <Button size={"small"} variant={"outlined"} onClick={handleSubmit(test)}>Actualizar pagos</Button>
        </Stack>
      </Stack>

      <Line />
    </Box>
  )
}