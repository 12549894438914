import { H3, Text } from 'components/texts';
import React from 'react'
import { Grid, Stack } from '@mui/material';
import { Input, Checkbox, Button } from 'components/forms/components';
import Statics from 'objects/common/Statics'

/**
 * Formulario con los datos del libro de la inscripcion a un colegio
 */
export default function LibroInscripcionColegioData({ inscripcion }){
    const { venta_libro, venta_libro_asociado } = inscripcion

    return (
        <Grid container p={3}>
            <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={12}>
                    <H3>Libro del grupo: </H3>
                </Grid>

                <Grid item xs={8}>
                    <Input className="w-100" defaultValue={venta_libro && venta_libro.libro ? venta_libro.libro.nombre : "..."} uppercase disabled />
                </Grid>


                <Grid item xs={2}>
                    <Checkbox name={"libro_solicitado"} label={"Libro solicitado"} defaultChecked={venta_libro && venta_libro.id ? true : false} disabled/>
                </Grid>

                <Grid item xs={2}>
                    <Stack>
                        <Text fontWeight={"bold"}>Fecha solicitado</Text>
                        <Text>{venta_libro?.fecha_peticion ? Statics.formatDate(venta_libro.fecha_peticion) : "Sin rellenar"}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={2}>
                    <Input label={"Precio"} name={"precio"} className="w-100" defaultValue={venta_libro?.precio ?? ""} type={"number"} disabled />
                </Grid>

                <Grid item xs={3}>
                    <Text fontWeight={"bold"}>Fecha de pago: </Text>
                    <Text>{venta_libro?.fecha_pago ? Statics.formatDate(venta_libro.fecha_pago) : "Sin rellenar"}</Text>
                </Grid>

                <Grid item xs={3}>
                    <Text fontWeight={"bold"}>Fecha de entrega: </Text>
                    <Text>{venta_libro?.fecha_entrega ? Statics.formatDate(venta_libro.fecha_entrega) : "Sin rellenar"}</Text>
                </Grid>

                <Grid item xs={2}>
                    <Input 
                        label        = {"Unidades en el almacén"} 
                        name         = {"unidades"} 
                        className    = "w-100" 
                        defaultValue = {venta_libro?.libro?.unidades_disponibles != null ? venta_libro?.libro?.unidades_disponibles : ""} 
                        disabled 
                        useValue />
                </Grid>
                <Grid item xs={2}>
                    <Button>
                        Detalles
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={5} alignItems={"center"}>
                <Grid item xs={12}>
                    <H3>Libro asociado: </H3>
                </Grid>

                <Grid item xs={8}>
                    <Input name="libro_asociado" className="w-100" defaultValue={venta_libro?.libro?.libro_asociado ? venta_libro.libro.libro_asociado.nombre : "..."} uppercase disabled />
                </Grid>


                <Grid item xs={2}>
                    <Checkbox name={"libro_asociado_solicitado"} label={"Libro solicitado"} defaultChecked={venta_libro_asociado && venta_libro_asociado.id ? true : false} disabled/>
                </Grid>

                <Grid item xs={2}>
                    <Stack>
                        <Text fontWeight={"bold"}>Fecha solicitado</Text>
                        <Text>{venta_libro_asociado?.fecha_peticion ? Statics.formatDate(venta_libro_asociado.fecha_peticion) : "Sin rellenar"}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={2}>
                    <Input label={"Precio"} name={"precio_asociado"} className="w-100" defaultValue={venta_libro_asociado?.precio ?? ""} type={"number"} disabled/>
                </Grid>

                <Grid item xs={3}>
                    <Text fontWeight={"bold"}>Fecha de pago: </Text>
                    <Text>{venta_libro_asociado?.fecha_pago ? Statics.formatDate(venta_libro_asociado.fecha_pago) : "Sin rellenar"}</Text>
                </Grid>

                <Grid item xs={3}>
                    <Text fontWeight={"bold"}>Fecha de entrega: </Text>
                    <Text>{venta_libro_asociado?.fecha_entrega ? Statics.formatDate(venta_libro_asociado.fecha_entrega) : "Sin rellenar"}</Text>
                </Grid>

                <Grid item xs={2}>
                    <Input 
                        label        = {"Unidades en el almacén"} 
                        name         = {"unidades_asociado"} 
                        className    = "w-100" 
                        defaultValue = {venta_libro_asociado?.libro?.unidades_disponibles != null ? venta_libro_asociado?.libro?.unidades_disponibles : ""} 
                        uppercase 
                        disabled />
                </Grid>
                <Grid item xs={2}>
                    <Button>
                        Detalles
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}