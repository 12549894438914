import React from 'react'
import { Grid } from '@mui/material'
import { ItemsSelect, MesSelect, CursoEscolarSelect } from 'components/forms/components/Select/custom'

export default function PagosPPAA({ register, errors, setValue }) {

    return (
        <Grid container mt={2} px={4} spacing={2}>
            <Grid item xs={4}>
                <CursoEscolarSelect name={`curso_escolar_id`} register={register} errors={errors} defaultValue={null} setValue={setValue} searcheable />
            </Grid>

            <Grid item xs={4} >
                <MesSelect register={register} errors={errors} />
            </Grid>

            <Grid item xs={4} >
                <ItemsSelect name={"colegio_id"} keyValue={"colegio"} register={register} errors={errors} searcheable allOption/>
            </Grid>
        </Grid>
    )
}