import { ConsultaAlumnos } from './parts'
import React from 'react'
import { Container, Paper, Stack } from '@mui/material'
import { useForm } from 'react-hook-form';
import { BreadcrumbsList } from 'components/data_display'
import { SnackbarProvider } from 'notistack';

/**
 * Componente `SingleConsultas` que muestra una interfaz para la visualización de listados y reportes relacionados con libros.
 * Utiliza `useForm` de `react-hook-form` para manejar los datos del formulario y las acciones de envío.
 * Presenta una lista de migas de pan (`BreadcrumbsList`) para la navegación dentro de la sección de libros y renderiza
 * el componente `ConsultaAlumnos` que maneja la lógica específica de los informes y consultas.
 *
 * @returns {JSX.Element} - Un contenedor que incluye las migas de pan y el papel (`Paper`) que encapsula el componente de consultas.
 */
export default function SingleConsultas() {

  const { register, getValues, handleSubmit } = useForm({})

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>

      <Stack direction="row" justifyContent={"space-between"} marginX={8}>
        <BreadcrumbsList mx={0} breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../", label: "Libros" },
          { to: "./", label: "Listados", actualItem: true },
        ]} />
      </Stack>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
        <ConsultaAlumnos
          getValues={getValues}
          register={register}
          handleSubmit={handleSubmit}
        />
      </Paper>

      <SnackbarProvider autoHideDuration={3000} />
    </Container>
  )
}
