import React from 'react'
import store from 'store/store.js'
import { COLOR } from 'theme/Colors'
import { Text } from 'components/texts'
import { useDispatch } from "react-redux"
import { persistStore } from 'redux-persist'
import LogoutIcon from '@mui/icons-material/Logout'
import { deleteUser } from 'store/actions/userActions'
import { MenuItem } from 'components/navigation'

/**
 * LogoutButton
 */
export default function LogoutButton() {
	const dispatch = useDispatch()

  const persistor = persistStore(store)
        

  /**
   * Hace el logout del usuario
   */
  const logOut = () => {
    persistor.purge()

    dispatch(deleteUser())
  }

  return (
    <MenuItem title="Cerrar sesión" onClick={logOut} ignoreSelected icon={<LogoutIcon />} />
  )
}
