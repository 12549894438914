import React, { Component } from 'react'
import { Modal } from 'components/modals'
import DataFormEvent from '../form'
import Requests from '../../Requests'
import { enqueueSnackbar } from 'notistack'
import { Text } from 'components/texts'

/**
 * Modal para editar eventos
 */
export default class EditEventModal extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            evento: null,
            loading: false,
        }

        this.requests = new Requests()
    }


    /**
     * Abre el modal y actualiza la evento
     * 
     * @param {String} event
     */
    open = (evento) => {
        this.setState({evento, loading: false}, () => {
            this.modal.open()
        })
    }


    /**
     * Cierra el modal
     */
    close = () => {
        this.setState({evento: null, loading: false}, () => {
            this.modal.close()
        })
    }

    
    /**
     * Callback para cuando se vaya a crear un evento
     * 
     * @param {Array} values
     */
    onEdit = (values) => {
        this.setState({loading: true}, () => {
            this.requests.editEvento(this.state.evento.id, values, (res) => {
                this.close()
                enqueueSnackbar(<Text>Se ha editado el evento correctamente.</Text>, { variant: "success" })
                
                if(this.props.onEditEvento)
                    this.props.onEditEvento(res.evento)
            }, (res) => {
                this.setState({loading: false})
                enqueueSnackbar(<Text>{"No se ha podido editar el evento: " + res}</Text>, { variant: "error" })
            })
        })
    }


    render() {
        return (
            <Modal ref={(ref) => this.modal = ref} title={"Editar evento del día: " + (this.state.evento ? this.state.evento.date.split('-').reverse().join('/') : "")} style={{minWidth: "60%"}}>
                <DataFormEvent onClick={this.onEdit} btnText={"Editar evento"} evento={this.state.evento} loading={this.state.loading}/>
            </Modal>
        )
    }
}
