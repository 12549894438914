import React, { Component } from 'react'

export default class H3 extends Component {

  /**
   * Render
   * 
   * @returns {Component}
   */
  render() {
    return (
      <h3 className={'custom-h3 bold ' + (this.props.className ?? "")} style={this.props.style ?? {}}>{this.props.children ?? ""}</h3>
    )
  }
}
