import RequestMaster from '../RequestMaster'

export default class RequestRegistroActividad extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/registro-actividad'
    }

    /**
     * Obtiene todos los registros de actividad
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    all(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "all"
        this.get(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Obtiene todos los registros de actividad de la academia
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    allAcademia(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "all/academia"
        this.get(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Hace la busqueda de los colegios
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    search(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.get(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Crea un usuario
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    create(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.post(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Hace el edit del usuario
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    edit(id, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "" + id
        this.put(path, params, callback, callbackError, callbackFinally)
    }
}
