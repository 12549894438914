import { EmpleadoListContext } from "contexts/employee/ListContext"
import { useAlert } from "hooks"
import { useContext, useEffect, useState } from "react"

/**
 * Hook `useWrapperLogic` centraliza la lógica y el estado relacionados con la recuperación y manejo
 * de la información de una tutoría específica, utilizando el contexto de empleados y alertas.
 * 
 * @param {Object} navigationParams - Parámetros de navegación que incluyen el ID de la tutoría.
 * @returns {Object} - Estados y funciones para gestionar la información de la tutoría.
 */
export default function useWrapperLogic(requests, navigationParams) {
    const { items, updateItem } = useContext(EmpleadoListContext)
    const [tutoria, setTutoria] = useState(null)
    const [id_tutoria, setIdTutoria] = useState(navigationParams.id_tutoria)
    const updateAlert = useAlert()

    /**
     * Carga los detalles de la tutoría desde el servidor o usa el caché si está disponible.
     */
    const getTutoria = (getFromServer = false) => {
        const item = items.find(item => item?.id == id_tutoria)
        if (items.length == 0 || !item || getFromServer) {
            requests.getTutoria(id_tutoria, (tutoria) => {
                setTutoria(tutoria)
                updateItem(tutoria)
            }, () => updateAlert("No se ha podido recuperar los datos del colegio."))
        } else {
            setTutoria(item)
        }
    }

    useEffect(getTutoria, [id_tutoria])

    return { id_tutoria, tutoria, setIdTutoria, setTutoria, updateAlert, getTutoria }
}