import { ChangesContext } from "contexts/ChangesContext"
import useErrorHandler from "hooks/utils/useErrorHandler"
import { validateLibrosFields } from "pages/logged/admin/libros/libro/functions/validateLibrosFields"
import { useContext, useRef } from "react"
import { useNavigate } from "react-router-dom"

/**
 * Hook personalizado que encapsula la lógica para la edición de libros y operaciones relacionadas.
 *
 * @param {number} id_libro - El ID del libro que se está editando.
 * @param {function} getValues - Función para obtener los valores del formulario (generalmente de react-hook-form).
 * @param {function} updateAlert - Función para actualizar las alertas o notificaciones.
 * @param {function} onSaveFinish - Función que se llama cuando se completa la operación de edición.
 * @param {function} reset - Función para resetear el estado del formulario (generalmente de react-hook-form).
 * @param {function} setIdLibro - Función para actualizar el ID del libro actual.
 * 
 * @returns {Object} - Un objeto que contiene funciones y referencias para gestionar la edición de libros.
 */
export default function useLogic(id_libro, getValues, updateAlert, onSaveFinish, reset, setIdLibro, requests) {
    const { updateChanges } = useContext(ChangesContext)

    const navigate = useNavigate()

    const handleError = useErrorHandler()

    const headerRef = useRef()

    const promises = () => {
        const promiseCreateLibrosPedidos = new Promise((resolve, reject) => {
            requests.createLibrosPedidos(id_libro, getValues, resolve, reject)
        })

        const promiseEditLibrosPedidos = new Promise((resolve, reject) => {
            requests.editLibrosPedidos(id_libro, getValues, resolve, reject)
        })

        const promiseDeleteLibrosPedidos = new Promise((resolve, reject) => {
            requests.deleteLibrosPedidos(getValues, resolve, reject)
        })

        const promiseCreateLibrosDevueltos = new Promise((resolve, reject) => {
            requests.createLibrosDevueltos(id_libro, getValues, resolve, reject)
        })

        const promiseEditLibrosDevueltos = new Promise((resolve, reject) => {
            requests.editLibrosDevueltos(id_libro, getValues, resolve, reject)
        })

        const promiseDeleteLibrosDevueltos = new Promise((resolve, reject) => {
            requests.deleteLibrosDevueltos(getValues, resolve, reject)
        })

        const promiseEditLibroNiveles = new Promise((resolve, reject) => {
            requests.updateNiveles(id_libro, getValues, resolve, reject)
        })

        return [promiseCreateLibrosPedidos, promiseEditLibrosPedidos, promiseDeleteLibrosPedidos, promiseEditLibroNiveles, promiseCreateLibrosDevueltos, promiseEditLibrosDevueltos, promiseDeleteLibrosDevueltos]
    }

    /**
     * Realiza la edición del libro actual y gestiona las promesas relacionadas con las operaciones mencionadas.
     */
    const editLibro = () => {
        // Valida los campos de control de almacén antes de proceder. Si la validación falla, detiene la ejecución
        if (!validateLibrosFields(updateAlert, getValues))
            return

        headerRef.current.button.setLoading(true)

        requests.editLibro(id_libro, getValues, (res) => {
            updateAlert("Se ha editado el libro correctamente.", "success")

            Promise.allSettled(promises())
                .then(async (values) => {
                    const errors = values.filter(item => item.status == "rejected")
                    for (const fail of errors)
                        handleError(fail.reason)

                    headerRef.current.button.setLoading(false)
                    reset({}, { keepValues: true })
                    updateChanges(false)
                    onSaveFinish()
                })
        }, (err) => {
            handleError(err)
        })
    }

    /**
     * Actualiza el identificador del libro y navega a la página de edición correspondiente.
     *
     * @param {number} id - El identificador del libro.
     */
    const updateIdLibro = (id) => {
        navigate(`../libros/${id}/editar`)
        setIdLibro(id)
    }

    return { editLibro, updateIdLibro, headerRef, updateChanges }
}