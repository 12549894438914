import React, { Component } from "react";
import Skeleton from "@mui/material/Skeleton";
import { Grid } from "@mui/material";

/**
 * Informacion básica del alumno
 */
export default class SkeletonAlumno extends Component {
	/**
	 * Render
	 *
	 * @returns {Component}
	 */
	render() {
		return (
			<Grid container p={4} spacing={1}>
				<Grid item xs={2}>
					<Skeleton variant="rounded" width={"80%"} height={"100%"} />
				</Grid>
				<Grid item xs={8}>
					<Skeleton
						variant="text"
						sx={{ fontSize: "2.3em", marginBottom: ".8rem" }}
						width={"50%"}
					/>
					<Skeleton
						variant="text"
						sx={{ fontSize: "1em" }}
						width={"35%"}
					/>
					<Skeleton
						variant="text"
						sx={{ fontSize: "1em" }}
						width={"35%"}
					/>
					<Skeleton
						variant="text"
						sx={{ fontSize: "1em" }}
						width={"35%"}
					/>
					<Skeleton
						variant="text"
						sx={{ fontSize: "1em" }}
						width={"35%"}
					/>
				</Grid>
			</Grid>
		);
	}
}
