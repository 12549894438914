import { Grid, Stack } from "@mui/material";
import { H3, Text } from 'components/texts';
import { Checkbox, Button } from 'components/forms/components';
import SkeletonLibrosDataAcademia from './SkeletonLibrosDataAcademia'

export default function libroDataAcademia({ inscripcion, errors, register }) {

    return (
        <Grid container p={3}>
            {!inscripcion ?
                <SkeletonLibrosDataAcademia />
                : (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <H3>Libro del grupo: </H3>
                            </Grid>

                            <Grid item xs={8}>
                                <Stack>
                                    <Text>{inscripcion?.grupo_academia?.libro?.nombre ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2}>
                                <Checkbox name={"libro_solicitado"} label={"Libro solicitado"} register={register} errors={errors}
                                    disabled defaultChecked={inscripcion?.grupo_academia?.libro?.libro_solicitado ? true : false} />
                            </Grid>

                            <Grid item xs={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Fecha solicitado:</Text>
                                    <Text>{inscripcion?.grupo_academia?.libro?.fecha_solicitado ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Precio:</Text>
                                    <Text>{inscripcion?.grupo_academia?.libro?.pvp ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={3}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Fecha pago:</Text>
                                    <Text>{inscripcion?.grupo_academia?.libro?.fecha_pago ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={3}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Fecha de entrega:</Text>
                                    <Text>{inscripcion?.grupo_academia?.libro?.fecha_entrega ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Unidades en el almacén:</Text>
                                    <Text>{inscripcion?.grupo_academia?.libro?.unidades_disponibles ?? ""}</Text>
                                </Stack>
                            </Grid>

                            {/* <Grid item xs={2}>
                                <Button disabled >
                                    Detalles
                                </Button>
                            </Grid> */}

                        </Grid>

                        <Grid container spacing={2} mt={5}>
                            <Grid item xs={12}>
                                <H3>Libro asociado: </H3>
                            </Grid>

                            <Grid item xs={8}>
                                <Stack>
                                    <Text>{inscripcion?.libro?.libro_asociado?.nombre ?? ""}</Text>
                                </Stack>
                            </Grid>


                            <Grid item xs={2}>
                                <Checkbox disabled name={"libro_asociado_solicitado"} label={"Libro solicitado"}
                                    register={register} errors={errors} defaultChecked={inscripcion?.libro?.libro_asociado?.libro_solicitado ? true : false} />
                            </Grid>

                            <Grid item xs={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Fecha asociado petición:</Text>
                                    <Text>{inscripcion?.libro?.libro_asociado?.fecha_asociado ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Precio asociado:</Text>
                                    <Text>{inscripcion?.libro?.libro_asociado?.pvp ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={3}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Fecha pago asociado:</Text>
                                    <Text>{inscripcion?.libro?.libro_asociado?.fecha_pago_asociado ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={3}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Fecha entrega:</Text>
                                    <Text>{inscripcion?.libro?.libro_asociado?.fecha_entrega_asociado ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Unidades en el almacén:</Text>
                                    <Text>{inscripcion?.libro?.libro_asociado?.unidades_almacen ?? ""}</Text>
                                </Stack>
                            </Grid>
                            {/* <Grid item xs={2}>
                                <Button disabled>
                                    Detalles
                                </Button>

                            </Grid> */}
                        </Grid>
                    </>
                )
            }
        </Grid>
    )
}