import React, { Component } from 'react'
import { EmpleadoListSelector } from '../../../../custom_selectors'
import Modal from '../../../Modal.jsx'

/**
 * Modal con el formulario de busqueda del empleado
 * 
 * //TODO: convertir a function
 */
export default class SearchEmpleadoModal extends Component {

    constructor(props) {
        super(props)
    }


    /**
     * Callback pra cuando se elija un empleado
     * 
     * @param {Object} empleado 
     */
    onSelectedCallback = (empleado) => {
        if(this.props.onSelectedCallback) {
            this.modal.close()
            this.props.onSelectedCallback(empleado)
        }
    }
    

    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <Modal ref={(ref) => this.modal = ref} title={this.props.title ?? "Buscar empleado"}>
                <EmpleadoListSelector onSelectedCallback={this.onSelectedCallback}/>
            </Modal>
        )
    }
}
