import React, { Component } from 'react'
import { Modal } from 'components/modals'
import DeleteEventModal from '../DeleteEventModal'
import EventoTemplate from './template'

/**
 * Modal para ver los datos del evento
 */
export default class ShowEventModal extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            evento: null
        }
    }


    /**
     * Abre el modal y actualiza la evento
     * 
     * @param {String} event
     */
    open = (evento) => {
        this.setState({evento}, () => {
            this.modal.open()
        })
    }


    /**
     * Cierra el modal
     */
    close = () => {
        this.setState({evento: null}, () => {
            this.modal.close()
        })
    }


    /**
     * Callback para cuando se borra
     */
    onDeleteEvento = () => {
        if(this.props.onDeleteEvento) {
            this.props.onDeleteEvento(this.state.evento)
            this.close()
        }
    }


    /**
     * Callback para cuando se clicka el boton de editar
     */
    onEditClick = () => {
        if(this.props.onEditClick) {
            this.props.onEditClick(this.state.evento)
            this.close()
        }
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <Modal 
                ref              = {(ref) => this.modal = ref} 
                title            = {"Evento para el día: " + (this.state.evento ? this.state.evento.date.split('-').reverse().join('/') : "")} 
                aria-labelledby  = "parent-modal-title"
                aria-describedby = "parent-modal-description">

                <EventoTemplate 
                    evento        = {this.state.evento} 
                    onDeleteClick = {() => {this.deleteModal.open(this.state.evento)}}
                    onEditClick   = {this.onEditClick}
                    canEdit       = {this.props.loggedUserId == this.state.evento?.empleado?.id}/>
                
                <DeleteEventModal ref={(ref) => this.deleteModal = ref} onDelete={this.onDeleteEvento}/>
            </Modal>
        )
    }
}
