import { Text } from "components/texts"
import { useForm } from "react-hook-form"
import { enqueueSnackbar } from "notistack"
import { useCheckDirtyFields } from "hooks"
import useAlert from "hooks/utils/useAlert"
import Statics from 'objects/common/Statics'
import { useNavigate } from "react-router-dom"
import { useErrorHandler } from "hooks/index.js"
import { yupResolver } from "@hookform/resolvers/yup"
import { useContext, useEffect, useRef } from "react"
import { ChangesContext } from "contexts/ChangesContext"
import { EmpleadoListContext } from "contexts/employee/ListContext"
import { validationSchema } from 'components/forms/display/academia/actividades/DataForm/validation'


/**
 * `useLogic` es un hook personalizado que encapsula la lógica de formulario y manejo de estados para la creación de actividades en el contexto académico.
 * Utiliza 'react-hook-form' para el manejo de formularios y la validación de campos basada en un esquema de validación Yup.
 * 
 * @returns {Object} - Retorna los métodos y estados necesarios para el control del formulario y la creación de actividades:
 * - handleSubmit: Una función de 'react-hook-form' preparada para manejar el envío del formulario.
 * - createActividad: Una función que se ejecuta al enviar el formulario, realiza la petición de creación de la actividad.
 * - headerRef: Una referencia a un componente de encabezado que contiene un botón de acción con estado de carga.
 * - register: Método de 'react-hook-form' para registrar los inputs del formulario.
 * - errors: Objeto de 'react-hook-form' que contiene los errores de validación del formulario.
 */
export default function useLogic(requests) {
    const { register, handleSubmit, formState: { errors, dirtyFields } } = useForm({ resolver: yupResolver(validationSchema) })

    const updateAlert = useAlert()

    const navigate = useNavigate()

    const handleError = useErrorHandler()

    const headerRef = useRef()

    const { updateChanges } = useContext(ChangesContext)

    const { addItem } = useContext(EmpleadoListContext)

    useCheckDirtyFields(dirtyFields, updateChanges)

    /**
     * Hace la petición para crear los datos del Academia
     */
    const createActividad = (values) => {
        headerRef.current.button.setLoading(true)

        requests.createActividad(values, async (res) => {
            // enqueueSnackbar(<Text>Se ha creado la actividad correctamente. Redirigiendo a la pagina de edición..</Text>, { variant: "success" })
            updateAlert('Se ha creado la actividad correctamente. Redirigiendo a la pagina de visualización...', 'success')
            updateChanges(false)

            //Añadir la nueva actividad
            addItem(res.actividad)

            updateId(res?.actividad?.id)
        }, (err) => {
            headerRef.current.button.setLoading(false)
            handleError(err)
        })
    }

    const updateId = async (id) => {
        // navigate(`../academias/actividades/${id}`);
        await Statics.delay(1500).then(() => { navigate(`../academias/actividades/${id}`) }) //Modificada la URL, para ir al single
        // setIdActividad(id);
    }

    return { handleSubmit, createActividad, headerRef, register, errors }
}