import React, { Component } from 'react'
import { MenuItem } from 'components/navigation'
import { 
    Send     as SendIcon,
    Toc      as DatosColegioIcon,
    MenuBook as LibrosIcon,
    Savings  as PagosIcon,
    KeyboardReturn  as DevolucionIcon,
} from '@mui/icons-material'

export default class AlumnoMenu extends Component {

    /**
     * ComponentDidMount
     * 
     * Inicializamos los componentes necesarios del tema
     */
    componentDidMount() {
        window.KTMenu?.createInstances()
        window.KTToggle?.createInstances()
    }


	render() {
		return (
            <div className="menu menu-column menu-rounded menu-sub-indention px-3" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">
                <div className="menu-item pt-5">
                    <div className="menu-content">
                        <span className="menu-heading fw-bold text-uppercase fs-7">Menú libros</span>
                    </div>
                </div>

                <MenuItem title="Control de libros" to='./libros'                icon={<LibrosIcon />} exact/>
                <MenuItem title="Generar envíos"    to="./libros/generar-envios" icon={<SendIcon />}/>
                <MenuItem title="Gestión de cobros" to="./libros/gestion-cobros" icon={<PagosIcon />}/>
                {/* <MenuItem title="Gestión de cobros (en desarrollo)" to="#" icon={<PagosIcon />}/> */}
                <MenuItem title="Listados"          to="./libros/listados"       icon={<DatosColegioIcon />} />
                <MenuItem title="Devoluciones"      to="./libros/devolucion"     icon={<DevolucionIcon />} />
            </div>
		)
	}
}
