import { Container, Paper } from "@mui/material"
import { BreadcrumbsList, Line } from 'components/data_display'
import { Header, Message } from "./parts"
import { SnackbarProvider } from "notistack"
import { useDevolucionesLogic } from "hooks"

/**
 * Componente principal de la sección de Devoluciones de libros.
 *
 * Este componente renderiza la página principal de la sección de Devoluciones y gestiona la generación de devoluciones.
 *
 * @returns {JSX.Element} - Un elemento JSX que representa la página de Devoluciones.
 */
export default function Devoluciones() {
    const { isLoading, generateDevoluciones } = useDevolucionesLogic()

    return (
        <Container maxWidth={false} sx={{ mt: 5 }}>
            <BreadcrumbsList breadcrumbsList={[
                { to: "/", label: "Inicio" },
                { to: "./../", label: "Libros" },
                { to: "./", label: "Devoluciones", actualItem: true },
            ]} />

            <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
                <Header isLoading={isLoading} generateDevoluciones={generateDevoluciones} />
                <Line />
                <Message />
            </Paper>

            <SnackbarProvider autoHideDuration={3000} />
        </Container>
    )
}