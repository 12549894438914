import { H3 } from 'components/texts';
import React, { Component } from 'react'
import { Grid, TextField } from '@mui/material';
import { Input, Checkbox, DatePicker, Button } from 'components/forms/components';

/**
 * Formulario con los datos del libro de la inscripcion a un colegio
 */
export default class LibroInscripcionColegioDataForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            venta_libro: this.props.inscripcion?.venta_libro ?? {},
            venta_libro_asociado: this.props.inscripcion?.venta_libro_asociado ?? {}
        }
    }


    componentDidUpdate(prevProps) {
        if(this.props.grupo && (this.props.grupo?.id != prevProps.grupo?.id))
            this.setGrupo(this.props.grupo)
    }

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Actualiza el libro del grupo
     * 
     * @param {Object} grupo: {libro: Object}
     */
    setGrupo = ({ libro }) => {
        const { venta_libro, venta_libro_asociado } = this.state
        venta_libro.libro          = libro ?? null
        venta_libro_asociado.libro = libro?.libro_asociado ?? null

        this.setState({ venta_libro, venta_libro_asociado }, () => {
            this.props.setValue("id_libro",          libro?.id ?? null)
            this.props.setValue("id_libro_asociado", libro?.libro_asociado?.id ?? null)

            this.unidades_disponibles.setValue(libro?.unidades_disponibles != null ? libro?.unidades_disponibles : "")
            this.unidades_disponibles_asociado.setValue(libro?.libro_asociado?.unidades_disponibles != null ? libro?.libro_asociado?.unidades_disponibles : "")

            this.libro.setValue(libro ? libro.nombre : "")
            this.libro_asociado.setValue(libro && libro.libro_asociado ? libro.libro_asociado.nombre : "")
        })
    }


    /**
     * Render
     *
     * @returns {Component}
     */
    render() {
        const { venta_libro, venta_libro_asociado } = this.state

        return (
            <Grid container p={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <H3>Libro del grupo: </H3>
                    </Grid>
    
                    <Grid item xs={8}>
                        <Input ref={(ref) => this.libro = ref} className="w-100" defaultValue={venta_libro && venta_libro.libro ? venta_libro.libro.nombre : "..."} uppercase disabled useValue/>
                        <TextField type={"hidden"} value={venta_libro.libro?.id ?? ""} variant={"standard"} {...this.props.register ? this.props.register("id_libro") : {}} />
                    </Grid>
    
    
                    <Grid item xs={2}>
                        <Checkbox name={"libro_solicitado"} label={"Libro solicitado"} register={this.props.register} errors={this.props.errors} defaultChecked={venta_libro && venta_libro.id ? true : false}/>
                    </Grid>
    
                    <Grid item xs={2}>
                        <DatePicker register={this.props.register} errors={this.props.errors} className="w-100" name={"fecha_peticion"} label={"Fecha solicitado"} defaultValue={venta_libro?.fecha_peticion ?? ""} setValue={this.props.setValue} clearable/>
                    </Grid>
    
                    <Grid item xs={2}>
                        <Input register={this.props.register} errors={this.props.errors} label={"Precio"} name={"precio"} className="w-100" defaultValue={venta_libro?.precio ?? ""} type={"number"} />
                    </Grid>
    
                    <Grid item xs={3}>
                        <DatePicker register={this.props.register} errors={this.props.errors} className="w-100" name={"fecha_pago"} label={"Fecha de pago"} defaultValue={venta_libro?.fecha_pago ?? ""} setValue={this.props.setValue} clearable/>
                    </Grid>
    
                    <Grid item xs={3}>
                        <DatePicker register={this.props.register} errors={this.props.errors} className="w-100" name={"fecha_entrega"} label={"Fecha de entrega"} defaultValue={venta_libro?.fecha_entrega ?? ""} setValue={this.props.setValue} clearable/>
                    </Grid>
    
                    <Grid item xs={2}>
                        <Input 
                            ref          = {(ref) => this.unidades_disponibles = ref}
                            register     = {this.props.register} 
                            errors       = {this.props.errors} 
                            label        = {"Unidades en el almacén"} 
                            name         = {"unidades"} 
                            className    = "w-100" 
                            defaultValue = {venta_libro?.libro?.unidades_disponibles != null ? venta_libro?.libro?.unidades_disponibles : ""} 
                            disabled 
                            useValue />
                    </Grid>
                    <Grid item xs={2}>
                        <Button>
                            Detalles
                        </Button>
                    </Grid>
                </Grid>
    
                <Grid container spacing={2} mt={5}>
                    <Grid item xs={12}>
                        <H3>Libro asociado: </H3>
                    </Grid>
    
                    <Grid item xs={8}>
                        <Input ref={(ref) => this.libro_asociado = ref} className="w-100" defaultValue={venta_libro?.libro?.libro_asociado ? venta_libro.libro.libro_asociado.nombre : "..."} uppercase disabled useValue/>
                        <TextField 
                            type    = {"hidden"} 
                            value   = {venta_libro_asociado.libro?.id ?? venta_libro?.libro?.libro_asociado?.id ?? ""} 
                            variant = {"standard"} 
                            {...this.props.register ? this.props.register("id_libro_asociado") : {}} />
                    </Grid>
    
    
                    <Grid item xs={2}>
                        <Checkbox name={"libro_asociado_solicitado"} label={"Libro solicitado"} register={this.props.register} errors={this.props.errors} defaultChecked={venta_libro_asociado && venta_libro_asociado.id ? true : false}/>
                    </Grid>
    
                    <Grid item xs={2}>
                        <DatePicker register={this.props.register} errors={this.props.errors} className="w-100" name={"fecha_asociado_peticion"} label={"Fecha solicitado"} defaultValue={venta_libro_asociado?.fecha_peticion ?? ""} setValue={this.props.setValue} clearable/>
                    </Grid>
    
                    <Grid item xs={2}>
                        <Input register={this.props.register} errors={this.props.errors} label={"Precio"} name={"precio_asociado"} className="w-100" defaultValue={venta_libro_asociado?.precio ?? ""} uppercase type={"number"}/>
                    </Grid>
    
                    <Grid item xs={3}>
                        <DatePicker register={this.props.register} errors={this.props.errors} className="w-100" name={"fecha_pago_asociado"} label={"Fecha de pago"} defaultValue={venta_libro_asociado?.fecha_pago ?? ""} setValue={this.props.setValue} clearable/>
                    </Grid>
    
                    <Grid item xs={3}>
                        <DatePicker register={this.props.register} errors={this.props.errors} className="w-100" name={"fecha_entrega_asociado"} label={"Fecha de entrega"} defaultValue={venta_libro_asociado?.fecha_entrega ?? ""} setValue={this.props.setValue} clearable/>
                    </Grid>
    
                    <Grid item xs={2}>
                        <Input 
                            ref          = {(ref) => this.unidades_disponibles_asociado = ref}
                            register     = {this.props.register} 
                            errors       = {this.props.errors} 
                            label        = {"Unidades en el almacén"} 
                            name         = {"unidades_asociado"} 
                            className    = "w-100" 
                            defaultValue = {venta_libro_asociado?.libro?.unidades_disponibles != null ? venta_libro_asociado?.libro?.unidades_disponibles : ""} 
                            uppercase 
                            disabled
                            useValue />
                    </Grid>
                    <Grid item xs={2}>
                        <Button>
                            Detalles
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    
}
