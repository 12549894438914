import React, { useState, useEffect } from "react";
import { Grid, Box, Stack, FormControlLabel, TextField } from "@mui/material";
import { CursoEscolarSelect, ItemsSelect } from "components/forms/components/Select/custom";
import { Text } from "components/texts";
import { Checkbox, CustomFixedSizeList } from "components/forms/components";
import { RequestColegio } from "objects/requests";

const requestColegio = new RequestColegio();
const destinatario = "colegios"

export default function ColegiosTemplate({ errors, register, reset, setValue, unregister }) {
  const itemCount = 100; // Número total de elementos
  const itemsPerColumn = 6;
  const itemWidth = 110;

  const [colegios, setColegios] = useState([]);
  const [filter, setFilter] = useState({ searchTerm: "", colegiosFilter: [] });
  const [colegiosSeleccionados, setColegiosSeleccionados] = useState([]);

  useEffect(() => {
    setValue(destinatario + '.colegiosIDs', colegiosSeleccionados ?? [])
  }, [colegiosSeleccionados.length])

  const handleCheckboxClick = (colegioId) => {
    if (colegiosSeleccionados.includes(colegioId)) {
      // Si está, quitarlo
      const nuevosColegiosSeleccionados = colegiosSeleccionados.filter(id => id !== colegioId);
      setColegiosSeleccionados(nuevosColegiosSeleccionados);
    } else {
      // Si no está, añadirlo
      const nuevosColegiosSeleccionados = [...colegiosSeleccionados, colegioId];
      setColegiosSeleccionados(nuevosColegiosSeleccionados);
    }
  };


  const handleCheckboxChange = (name, newChecked) => {
    if (newChecked && !filter.colegiosFilter.includes(name)) {
      const newColegiosFilter = [...filter.colegiosFilter, name]
      setFilter({ searchTerm: filter.searchTerm, colegiosFilter: newColegiosFilter })
    } else if (!newChecked && filter.colegiosFilter.includes(name)) {
      const newColegiosFilter = filter.colegiosFilter.filter(colegioName => colegioName !== name);
      setFilter({ searchTerm: filter.searchTerm, colegiosFilter: newColegiosFilter })
    }
  };

  //TODO:
  // const { items } = useContext(EmpleadoListContext)

  useEffect(() => {
    if (!colegios || colegios.length === 0) {
      requestColegio.all({}, (response) => {
        setColegios(response["colegios"]);
      });
    }
  }, []);

  const filteredColegios = colegios.filter((colegio) => {
    const nombre = colegio.nombre.toLowerCase();
    const prefijoMatches = filter.colegiosFilter.some(palabraClave => nombre.includes(palabraClave.toLowerCase()));
    const nameMatches = filter.colegiosFilter.length > 0 && filter.searchTerm == "" ? false : nombre.includes(filter.searchTerm.toLowerCase())

    return nameMatches || prefijoMatches;
  });

  const renderItem = (index) => {
    const colegio = filteredColegios[index];
    if (colegio) {
      return (
        <FormControlLabel
          sx={{ margin: 0.5, width: 250, paddingLeft: 4 }}
          key={index}
          control={<Checkbox checked={colegiosSeleccionados.includes(colegio.id)} onClick={() => handleCheckboxClick(colegio.id)} />}
          label={colegio?.nombre}
        />

      );
    } else { return null; }

  };

  return (
    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={4}>
      <Grid item xs={3}>
        <CursoEscolarSelect name={`${destinatario}.id_curso_escolar`} register={register} errors={errors} wantToReset={reset} defaultValue={null} setValue={setValue} searcheable />
      </Grid>

      <Grid item xs={3}>
        <ItemsSelect
          allOption
          name={`${destinatario}.id_tipo_actividad`}
          keyValue={"tipo_actividad"}
          register={register}
          errors={errors}
          unregister={unregister}
        />
      </Grid>

      <Grid item xs={3}>
        <ItemsSelect
          name={`${destinatario}.id_actividad`}
          keyValue={"actividad"}
          register={register}
          errors={errors}
          wantToReset={reset}
          searcheable
          allOption
        />
      </Grid>

      <Grid item xs={3}>
        <ItemsSelect
          name={`${destinatario}.id_modalidad_cursos`}
          keyValue={"modalidad_cursos"}
          register={register}
          errors={errors}
          wantToReset={reset}
          searcheable
          unregister={unregister}
          allOption
        />
      </Grid>

      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} p={2.5}>
        <Grid item xs={3} mt={2}>
          <ItemsSelect
            register={register}
            errors={errors}
            name={`${destinatario}.nivel_grupo_id`}
            keyValue={"nivel_grupo"}
            allOption
          />
        </Grid>

        <Grid item xs={9} container>
          <Box
            component="fieldset"
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Stack mt={1.5} mr={2.5} ml={1}>
              <Text>Filtros: </Text>
            </Stack>
            <Stack mt={2} mr={4}>
              <Checkbox
                name={"ceip"}
                label={"C.E.I.P"}
                onClick={(newChecked) =>
                  handleCheckboxChange("ceip", newChecked)
                }
              />
            </Stack>
            <Stack mt={2} mr={4}>
              <Checkbox
                name={"ies"}
                label={"I.E.S"}
                onClick={(newChecked) =>
                  handleCheckboxChange("ies", newChecked)
                }
              />
            </Stack>
            <Stack mt={2} mr={4}>
              <Checkbox
                name={"ave"}
                label={"AVE"}
                onClick={(newChecked) =>
                  handleCheckboxChange("ave", newChecked)
                }
              />
            </Stack>
            <Stack mt={2} mr={4}>
              <Checkbox
                name={"colegio"}
                label={"Colegio"}
                onClick={(newChecked) =>
                  handleCheckboxChange("colegio", newChecked)
                }
              />
            </Stack>
            <Stack mt={2} mr={4}>
              <Checkbox
                name={"san_santa"}
                label={"San/Santa"}
                onClick={(newChecked) =>
                  handleCheckboxChange("san/santa", newChecked)
                }
              />
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} p={2.5}>
        <Grid item xs={3}>
          <TextField
            label="Buscar colegio por nombre..."
            size="small"
            className="w-100"
            type="text"
            value={filter.searchTerm}
            onChange={(event) => setFilter({ searchTerm: event.target.value, colegiosFilter: filter.colegiosFilter })}
          />
        </Grid>

        <Grid item xs={9} container>
          <CustomFixedSizeList
            itemCount={itemCount}
            itemsPerColumn={itemsPerColumn}
            itemWidth={itemWidth}
            renderItem={renderItem} />
        </Grid>
      </Grid>
    </Grid>
  );
}
