import { Box, Container, Paper } from "@mui/material";
import { Accordion, BreadcrumbsList, Datagrid } from "components/data_display";
import { H2, H3 } from "components/texts";
import SearchFormClasesParticulares, { search } from "components/forms/display/clases-particulares/SearchForm";
import { useEffect, useContext, useState } from "react";
import { columns as allColumns, basicColumns } from './columns.js'
import { Link } from "react-router-dom";
import { Button } from 'components/forms/components'
import { Add as AddIcon, Search as SearchIcon } from '@mui/icons-material'
import { COLOR } from 'theme/Colors'
import { EmpleadoListContext } from 'contexts/employee/ListContext'

export default function ListadoClasesParticulares() {
  const [loadingTable, setLoadingTable] = useState(false)
  const [columns, setColumns] = useState(allColumns.alumnos)

  const { items, rowCount, params, update } = useContext(EmpleadoListContext)

  useEffect(() => {
    setLoadingTable(false)
  }, [items])

  /**
   * Callback para actualizar la tabla una vez hecha la petición
   * 
   * @param {Array} data - Datos de todos los alumnos que se reflejan en la tabla Datagrid
   * @param {Int}   rowCount 
   * @param {Int}   page 
   * @param {Int}   params - Parámetros de búsqueda procedentes de SearchFormAlumno
   */
  const updateTable = (newData, newRowCount, newPage, newParams) => update(newRowCount, newData, newParams, newPage)

  const onChangeSearchIndex = (_, keyColumn) => {
    setColumns(allColumns[keyColumn] ?? basicColumns)
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <BreadcrumbsList
        breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./", label: "Clases particulares", actualItem: true },
        ]}
      />

      <Accordion
        header={
          <Box display={"flex"} alignItems={"center"}>
            <SearchIcon className={"mx-5"} />
            <H3 className="my-0">Buscar clases particulares</H3>
          </Box>
        }
      >
        <SearchFormClasesParticulares
          searchCallback={updateTable}
          onChangeSearchIndex={onChangeSearchIndex}
          onLoadingCallback={setLoadingTable}
        />
      </Accordion>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
        <Box display={"flex"} justifyContent={"space-between"} mb={5}>
          <H2>Listado de clases particulares</H2>

          <Link to={"./crear"}>
            <Button color={"success"} startIcon={<AddIcon style={{ color: COLOR.white.main }} />} >
              Añadir nueva clase particular
            </Button>
          </Link>
        </Box>

        <Datagrid
          rows={items}
          columns={columns}
          loading={loadingTable}
          rowCount={rowCount}
          onPageChange={(page) => { search(page, () => setLoadingTable(true), updateTable) }}
          serverSide />
      </Paper>

    </Container>
  );
}