import Statics from 'objects/common/Statics'
import { RequestGrupoColegio, RequestSeguimientoTrimestralGrupoColegio, RequestGrupoEmpleado } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Hace la petición para crear el grupo
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createGrupo(getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData
        formData.append("clave", getValues("clave").toUpperCase())
        formData.append("id_curso_escolar", getValues("id_curso_escolar"))
        formData.append("id_colegio", getValues("id_colegio"))
        formData.append("id_registro_actividad", getValues("id_registro_actividad"))
        formData.append("id_descripcion_horario", getValues("id_descripcion_horario"))
        formData.append("id_nivel_grupo", getValues("id_nivel_grupo") ?? "")
        formData.append("curso_escolar_string", getValues("curso_escolar") ? getValues("curso_escolar").toUpperCase() : "")
        formData.append("num_grupo", getValues("num_grupo") ?? "")
        formData.append("fecha_inicio", Statics.datePickerTimeFormat(getValues("fecha_inicio")) ?? "")
        formData.append("fecha_fin", Statics.datePickerTimeFormat(getValues("fecha_fin")) ?? "")
        formData.append("min_alumnos", getValues("min_alumnos") ?? "")
        formData.append("max_alumnos", getValues("max_alumnos") ?? "")

        formData.append("observaciones", getValues("observaciones") ? getValues("observaciones").toUpperCase() : "")
        if (getValues("image")[0])
            formData.append("image", getValues("image")[0])

        //IDIOMAS
        formData.append("id_libro", getValues("id_libro") ?? "")
        formData.append("usar_libro_asociado", getValues("usar_libro_asociado") ? 1 : 0)
        formData.append("usar_progress_sheet", getValues("usar_progress_sheet") ? 1 : 0)

        new RequestGrupoColegio().create(formData, callbackOk, callbackError)
    }


    createSeguimientoTrimestralGrupo(id_grupo, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData

        formData.append("id_grupo_colegio", id_grupo)

        const appendSeguimientoData = (trimestre) => {
            const fecha_entrega = getValues(`fecha_entrega_t${trimestre}`);
            formData.append(`fecha_entrega_t${trimestre}`, Statics.datePickerTimeFormat(fecha_entrega) ?? "");
            formData.append(`objetivos_alcanzados_t${trimestre}`, getValues(`objetivos_alcanzados_t${trimestre}`) ? getValues(`objetivos_alcanzados_t${trimestre}`).toUpperCase() : "");
            formData.append(`evolucion_alumnos_t${trimestre}`, getValues(`evolucion_alumnos_t${trimestre}`) ? getValues(`evolucion_alumnos_t${trimestre}`).toUpperCase() : "");
            formData.append(`conclusiones_observaciones_t${trimestre}`, getValues(`conclusiones_observaciones_t${trimestre}`) ? getValues(`conclusiones_observaciones_t${trimestre}`).toUpperCase() : "");
        };

        appendSeguimientoData(1);
        appendSeguimientoData(2);
        appendSeguimientoData(3);

        new RequestSeguimientoTrimestralGrupoColegio().create(formData, callbackOk, callbackError)
    }


    createEmpleadoGrupo(id_grupo, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const id_empleado = getValues("id_empleado")
        if (id_empleado && id_empleado != "") {
            const formData = new FormData
            formData.append("grupo_id", id_grupo)
            formData.append("grupo_type", "grupo-colegio")
            formData.append("empleado_id", id_empleado)
            formData.append("actual", 1) // Lo pongo en 1 para que aparezca reflejado el profesor en front, si no el scope de la API no lo devuelve.
            // Se podria prescindir de este campo y usar algo como la fecha de baja, para ver si es nulo que es actual,
            // y si tiene valor datetime que se haya sobrepasado ponerlo como no actual
            new RequestGrupoEmpleado().create(formData, callbackOk, callbackError)
        } else {
            callbackOk()
        }

    }
}
