import moment from 'moment'
import styles from './styles.js'
import { Line } from 'components/data_display'
import { Grid, TextField } from '@mui/material'
import React from 'react'
import { LibrosPedidos, LibrosDevueltos } from './parts'
import { CursoEscolarSelect } from 'components/forms/components/Select/custom'
import { useControlAlmacenLogic } from 'hooks/index.js'
import Almacen from '../Almacen/index.jsx'

const cursoActual = moment().subtract(1, 'year').format('YYYY') + "-" + moment().format('YYYY')

/**
 * Componente `DataFormLibroControlAlmacen` proporciona un formulario para la gestión del control de almacén de libros.
 * Permite visualizar y actualizar la información de libros pedidos y devueltos, así como la gestión de inventario.
 * 
 * Utiliza hooks de estado para mantener la información actualizada de los libros pedidos y devueltos.
 * Incluye efectos secundarios para establecer los valores de estos en el formulario.
 * 
 * @param {Object} libro - Objeto con la información del libro a gestionar.
 * @param {function} register - Función de registro de `react-hook-form` para el control de los inputs.
 * @param {boolean} isMounted - Indica si el componente está montado para renderizar condicionalmente elementos.
 * @param {function} setValue - Función de `react-hook-form` para establecer dinámicamente los valores de los campos.
 * @param {Object} errors - Objeto de `react-hook-form` que contiene los errores de validación del formulario.
 * @param {function} reset - Función de `react-hook-form` para resetear el estado del formulario.
 * 
 * @returns {JSX.Element} - Un componente que contiene los campos y controles para la gestión del almacén de libros.
 */
export default function DataFormLibroControlAlmacen({ libro, librosPedidosProp, librosDevueltosProp, register, isMounted = false, setValue, errors, reset }) {
  const { librosDevueltos, librosPedidos, updateLibrosPedidos, updateLibrosDevueltos, cursoEscolar, setCursoEscolar } = useControlAlmacenLogic(librosPedidosProp, librosDevueltosProp, setValue)
  
  return (
    <Grid container px={3}>

      <Grid container mb={2} mt={4}>
        {isMounted ?
          <CursoEscolarSelect
            name={"id_curso_escolar"}
            register={register}
            setValue={setValue}
            errors={errors}
            className={"w-50"}
            initialOptionByLabel={cursoActual}
            defaultValue={null}
            onChange={({ target }) => setCursoEscolar(target.value)}
            wantToReset={reset}
            searcheable /> : null}
      </Grid>

      <Line className={"mt-5"} />

      <Grid container my={3}>
        <Grid item xs={12} xl={6}>
          <LibrosPedidos cursoEscolar={cursoEscolar} librosPedidos={librosPedidos} onUpdate={updateLibrosPedidos} />
        </Grid>

        <Grid item xs={12} xl={6} sx={styles.librosDevueltosGrid}>
          <LibrosDevueltos cursoEscolar={cursoEscolar} librosDevueltos={librosDevueltos} onUpdate={updateLibrosDevueltos} />
        </Grid>
      </Grid>

      <Line />

      <Grid container my={3}>
        <Almacen cursoEscolar={cursoEscolar} librosDevueltos={librosDevueltos} librosPedidos={librosPedidos} libro={libro} />
      </Grid>

      <TextField type={"hidden"} variant={"standard"} {...register ? register("librosPedidos") : {}} />
      <TextField type={"hidden"} variant={"standard"} {...register ? register("librosDevueltos") : {}} />
    </Grid>
  )
}