import Skeleton from './skeleton'
import React, { Component } from 'react'
import { ExtraMini, H3, Text } from 'components/texts'
import { Alert, Grid, Stack } from '@mui/material'
import Standard from 'components/texts/Standard'

/**
 * Informacion de la domiciliacion bancaria del alumno
 */
export default class DomiciliacionData extends Component {

  constructor(props) {
    super(props)

    this.state = {
      domiciliacionBancaria: this.props.domiciliacionBancaria ?? null
    }
  }


  /**
   * ComponentDidUpdate
   * 
   * @param {Object} prevProps 
   */
  componentDidUpdate(prevProps) {
    if (this.props.domiciliacionBancaria != prevProps.domiciliacionBancaria)
      this.setState({ domiciliacionBancaria: this.props.domiciliacionBancaria })
  }

  //-------------------------------------------------------
  //-------------------- CLASS FUNCTIONS ------------------
  //-------------------------------------------------------


  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    let { domiciliacionBancaria } = this.state

    const iban = domiciliacionBancaria.length > 0 ? (domiciliacionBancaria[0]?.codigo_pais + domiciliacionBancaria[0]?.digitos_control + domiciliacionBancaria[0]?.numero_cuenta)?.match(/.{1,4}/g).join(' ') : null
    const numero_cuenta = domiciliacionBancaria[0]?.numero_cuenta?.match(/.{1,4}/g).join(' ')

    return (
      <Grid container>
        {!domiciliacionBancaria ? <Skeleton /> : (
          <>
            {domiciliacionBancaria.length == 0 ? <Alert severity="info" className='w-100'><H3 className="standard">Este alumno no tiene ninguna domiciliación bancaria.</H3></Alert> : null}

            {domiciliacionBancaria[0] ? (
              <>
                <Grid container gap={1} item xs={12}>
                  {/**MODIFICADO A LO QUE PIDIO SAMUEL EN EL DOCUMENTO */}
                  {/*<H3>Datos de pago</H3>*/}
                  <H3>Datos de la domiciliación</H3>
                  {/* <Standard fontStyle="italic">{domiciliacionBancaria[0]?.referencia_mandato}</Standard> */}
                </Grid>

                <Grid item xs={3}>
                  <Stack>
                    <Text fontWeight={"bold"}>Referencia</Text>
                    <Text fontWeight={"bold"}>Titular</Text>
                    {/**COMENTADO PARA ARREGLAR ERRORES DEL DOCUMENTO */}
                    {/* <Text fontWeight={"bold"}>Cuenta bancaria</Text> */} 
                    <Text fontWeight={"bold"}>IBAN</Text>
                    {/**COMENTADO PARA ARREGLAR ERRORES DEL DOCUMENTO */}
                    {/* <Text fontWeight={"bold"}>Número de control</Text> */}
                  </Stack>
                </Grid>
                
                <Grid item xs={3}>
                  <Stack>
                    <Text>{domiciliacionBancaria[0]?.referencia_mandato}</Text>
                    <Text>{domiciliacionBancaria[0]?.nombre_deudor ?? "Sin rellenar"}</Text>
                    {/* <Text>{domiciliacionBancaria[0].cuenta1 ?? domiciliacionBancaria[0].cuenta2 ?? domiciliacionBancaria[0].cuenta3 ?? domiciliacionBancaria[0].cuenta4 ?? domiciliacionBancaria[0].cuenta5 ?? "Sin rellenar"}</Text> */}
                    {/**COMENTADO PARA ARREGLAR ERRORES DEL DOCUMENTO */}
                    {/* <Text>{numero_cuenta}</Text> */}
                    <Text>{iban ?? "Sin rellenar"}</Text>
                    {/**COMENTADO PARA ARREGLAR ERRORES DEL DOCUMENTO */}
                    {/* <Text>{domiciliacionBancaria[0].numero_control ?? "Sin rellenar"}</Text> */}
                    {/* <Text>{domiciliacionBancaria[0]?.digitos_control ?? "Sin rellenar"}</Text> */}
                  </Stack>
                </Grid>

                <Grid item xs={3}>
                  <Stack>
                    <Text fontWeight={"bold"}>Fecha de mandato</Text>
                    {/**COMENTADO PARA ARREGLAR ERRORES DEL DOCUMENTO */}
                    {/* <Text fontWeight={"bold"}>Fecha del último recibo</Text> */}
                    <Text fontWeight={"bold"}>Primer recibo emitido</Text>
                  </Stack>
                </Grid>

                <Grid item xs={3}>
                  <Stack>
                    <Text>{domiciliacionBancaria[0]?.fecha_mandato ? domiciliacionBancaria[0].fecha_mandato.split("-").reverse().join("/") : "Sin rellenar"}</Text>
                    {/**COMENTADO PARA ARREGLAR ERRORES DEL DOCUMENTO */}
                    {/* <Text>{domiciliacionBancaria[0]?.ultimo_recibo ? domiciliacionBancaria[0].ultimo_recibo.split("-").reverse().join("/") : "Sin rellenar"}</Text> */} 
                    <Text>{domiciliacionBancaria[0]?.primer_recibo_emitido ? "Sí" : "No"}</Text>
                  </Stack>
                </Grid>
              </>
            ) : null}
          </>
        )}
      </Grid>
    )
  }
}
