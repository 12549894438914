import React from 'react'
import { Grid } from '@mui/material'
import { H3 } from 'components/texts'
import { Checkbox, DatePicker, Input } from 'components/forms/components'
import { IBANInput } from 'components/forms/components/Input/custom'

/**
 * Formulario con los datos del alumno
 */
export default function DataDomiciliacionBancariaForm({ domiciliacion_bancaria, register, errors, setValue }) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <H3 className={"m-0"}>Datos bancarios</H3>
            </Grid>

            <Grid item xs={12} alignContent={"center"}>
                <IBANInput register={register} errors={errors} dosDigitos={domiciliacion_bancaria?.digitos_control} defaultValue={domiciliacion_bancaria?.numero_cuenta ?? ""} />
            </Grid>

            {/* <Grid item xs={4}>
                <Input label="Código de país" register={register} errors={errors} className="w-100" name={"codigo_pais"} defaultValue={domiciliacion_bancaria?.codigo_pais ?? ""} />
            </Grid> */}

            {/* <Grid item xs={4}>
                <Input label="Número de control" register={register} errors={errors} className="w-100" name={"digitos_control"} defaultValue={domiciliacion_bancaria?.digitos_control ?? ""} />
            </Grid> */}

            {/* <Grid item xs={4}>
                <Input label="Cuenta" register={register} errors={errors} className="w-100" name={"numero_cuenta"} defaultValue={domiciliacion_bancaria?.numero_cuenta ?? ""} />
            </Grid> */}

            <Grid item xs={4}>
                <Input label="Nombre deudor" register={register} errors={errors} className="w-100" name={"nombre_deudor"} defaultValue={domiciliacion_bancaria?.nombre_deudor ?? ""} uppercase />
            </Grid>

            {/**
             * Se ha puesto este campo como none, a petición de Samuel.
             */}
            <Grid item xs={4} style={{ display:'none' }}>
                <DatePicker disabled register={register} errors={errors} className="w-100" name={"ultimo_recibo"} label={"Último recibo"} setValue={setValue} defaultValue={domiciliacion_bancaria?.ultimo_recibo ?? ""} clearable />
            </Grid>

            <Grid item xs={4}>
                <DatePicker register={register} errors={errors} className="w-100" name={"fecha_mandato"} label={"Fecha de mandato"} setValue={setValue} defaultValue={domiciliacion_bancaria?.fecha_mandato ?? ""} clearable />
            </Grid>

            {/**
             *  Desactivamos el uso del checkbox.
             */}
            <Grid item xs={4}>
                <Checkbox label="Primer recibo emitido" register={register} errors={errors} className="w-100" name="primer_recibo_emitido" defaultChecked={domiciliacion_bancaria?.primer_recibo_emitido ?? false} disabled />
            </Grid>

            {domiciliacion_bancaria ?
                <Grid item xs={4}>
                    <Input label="Referencia mandato" register={register} errors={errors} className="w-100" name={"referencia_mandato"} defaultValue={domiciliacion_bancaria?.referencia_mandato ?? ""} disabled />
                </Grid> : null}
        </Grid>
    )
}
