import React from 'react'
import { Grid } from '@mui/material'
import { ItemsSelect, CursoEscolarSelect } from 'components/forms/components/Select/custom'

export default function ParticularesTemplate({ register, errors, reset, setValue }) {
    return (
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={4}>
            <Grid item xs={3}>
                <CursoEscolarSelect name={"id_curso_escolar"} register={register} errors={errors} wantToReset={reset} defaultValue={null} setValue={setValue} searcheable />
            </Grid>
            <Grid item xs={3}>
                <ItemsSelect name={"id_tipo_actividad"} keyValue={"tipo_actividad"} register={register} errors={errors} />
            </Grid>
            <Grid item xs={3}>
                <ItemsSelect name={"id_actividades"} keyValue={"actividad"} register={register} errors={errors} wantToReset={reset} searcheable />
            </Grid>
            <Grid item xs={3}>
                <ItemsSelect name={"id_modalidad_cursos"} keyValue={"modalidad_cursos"} register={register} errors={errors} wantToReset={reset} searcheable />
            </Grid>
        </Grid>
    )
}
