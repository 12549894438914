import Modal from 'components/modals/Modal'
import React, { useEffect, useRef, useState } from 'react'
import { Text } from 'components/texts'
import { Stack, Grid } from '@mui/material'
import { Button, Select } from 'components/forms/components'

/**
 * Hook `useLogic` que gestiona la lógica de un modal para generar informes basados en la selección de un mes.
 * 
 * @param {boolean} open - Controla la visibilidad del modal.
 * @param {Function} generarInforme - Función a ejecutar para generar el informe del mes seleccionado.
 * 
 * @returns {Object} - Retorna un objeto con referencias y funciones necesarias para la UI del modal:
 * - modalRef: Referencia al modal para control directo (ej. abrir).
 * - options: Opciones de los meses disponibles para la selección.
 * - handleSelectChange: Función para manejar el cambio de selección del mes.
 * - isDisabled: Estado booleano que indica si el botón de generar informe está habilitado.
 * - handleGenerateReport: Función que invoca generarInforme con el mes seleccionado.
 * 
 * Utiliza dos `useEffect` para manejar la apertura del modal y la habilitación del botón basándose en si se ha seleccionado un mes.
 */
function useLogic(open, generarInforme) {
    const modalRef = useRef()
    const [selectedMonth, setSelectedMonth] = useState(null)
    const [isDisabled, setIsDisabled] = useState(true)

    useEffect(() => {
        if (open)
            modalRef.current.open()
    }, [open])

    // useEffect para controlar cuando habilitar el botón de Generar informe.
    useEffect(() => {
        if (selectedMonth)
            setIsDisabled(false)
    }, [selectedMonth])

    const options = [
        { value: "septiembre", label: "Septiembre" },
        { value: "octubre", label: "Octubre" },
        { value: "noviembre", label: "Noviembre" },
        { value: "diciembre", label: "Diciembre" },
        { value: "enero", label: "Enero" },
        { value: "febrero", label: "Febrero" },
        { value: "marzo", label: "Marzo" },
        { value: "abril", label: "Abril" },
        { value: "mayo", label: "Mayo" },
        { value: "junio", label: "Junio" },
        { value: "julio", label: "Julio" },
        { value: "agosto", label: "Agosto" }
    ]

    const handleSelectChange = (event) =>
        setSelectedMonth(event.target.value)

    const handleGenerateReport = () =>
        generarInforme(selectedMonth)

    return { modalRef, options, handleSelectChange, isDisabled, handleGenerateReport }
}

/**
 * Componente `ModalSelectMeses` que presenta un modal con un formulario para seleccionar un mes y generar un informe.
 * 
 * @param {boolean} open - Prop para controlar la apertura del modal desde el componente padre.
 * @param {Function} generarInforme - Prop que recibe la función para generar el informe basada en la selección del mes.
 * 
 * Utiliza el hook `useLogic` para manejar su lógica interna y el estado. Dentro de un Grid de Material-UI, dispone
 * un Stack que contiene un componente de texto para el título, un Select con las opciones de los meses y un botón que,
 * al hacer clic, generará el informe del mes seleccionado. El botón estará deshabilitado hasta que se seleccione un mes.
 */
export default function ModalSelectMeses({ open = false, generarInforme }) {

    const { modalRef, options, handleSelectChange, isDisabled, handleGenerateReport } = useLogic(open, generarInforme)

    return (
        <Modal ref={modalRef} title={"Selecciona el mes"}>
            <Grid container justifyContent={"center"} p={5} spacing={2}>
                <Grid item xs={4}>
                    <Stack display={"flex"} flexDirection={"row"} alignItems={'center'} justifyContent={'center'} columnGap={2}>
                        <Text fontWeight="bold">Mes:</Text>

                        <Select items={options} onChange={handleSelectChange} />

                        <Button disabled={isDisabled} onClick={handleGenerateReport}>
                            Generar informe
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </Modal>
    )
}    