import { yupResolver } from "@hookform/resolvers/yup"
import Statics from "objects/common/Statics"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"

let prepareParamsGlobal = null

/**
 * Este hook personalizado gestiona la lógica detrás del formulario de búsqueda de datos de colegio.
 * Utiliza react-hook-form para el manejo del formulario y yup para las validaciones de entrada.
 * Permite cambiar dinámicamente el contenido del formulario basado en la selección del usuario y preparar parámetros para la búsqueda.
 *
 * @param {Function} onChangeSearchIndex - Función proporcionada por el componente padre para manejar cambios en el índice de búsqueda seleccionado.
 * @param {Function} validationSchema
 * 
 * @returns {Object} Un conjunto de propiedades y funciones para ser utilizadas por el componente del formulario.
 */
export default function useAlumnoDatosColegioSearchFormLogic({ onChangeSearchIndex, validationSchema, itemsSearch }) {
    const { register, handleSubmit, formState: { errors }, getValues, setValue, reset } = useForm({ resolver: yupResolver(validationSchema) })

    const [resetFormState, setResetFormState] = useState(false)
    const [searchIndex, setSearchIndex] = useState(0)

    // Efecto para resetear el formulario cuando sea necesario.
    useEffect(() => {
        if (resetFormState == true) {
            resetForm(reset)
            setResetFormState(false)
        }
    })

    /**
     * Controlla cuando se hace un cambio en el select de busqueda.
     * Maneja el cambio en la selección de búsqueda y notifica al componente padre.
     * 
     * @param {Object} event
     */
    const handleChange = (event) => {
        const index = event.target.value

        setResetFormState(true)
        setSearchIndex(index)

        if (onChangeSearchIndex)
            onChangeSearchIndex(index, itemsSearch[index].columnKey)
    }


    /**
     * Prepara los parametros que se van a usar
     * 
     * @param {Int} page
     */
    const prepareParams = (page) => {
        const params = Statics.objectPropsToUppercase(Statics.cleanObject(getValues()))
        params.relations = 'curso_escolar,curso_colegio,colegio,alumno'
        params.page = page

        return params
    }

    prepareParamsGlobal = prepareParams

    const ActualTemplate = itemsSearch[searchIndex].template

    return { prepareParamsGlobal, resetFormState, setResetFormState, searchIndex, errors, register, setValue, handleSubmit, handleChange, ActualTemplate }
}

/**
 * Función diseñada para resetear todos los campos del formulario a sus valores por defecto.
 * Este método es esencial para permitir al usuario limpiar el formulario con facilidad, preparándolo
 * para una nueva búsqueda o simplemente para deshacer cualquier entrada previa no deseada.
 *
 * @param {Function} reset - Función proporcionada por react-hook-form que se utiliza para resetear el formulario.
 *                            Se le pasa un objeto con los valores iniciales para cada campo del formulario.
 *
 * El uso de esta función mejora la experiencia del usuario al proporcionar una manera rápida y efectiva de
 * limpiar el formulario, manteniendo la interfaz limpia y fácil de usar.
 */
const resetForm = (reset) => {
    reset({
        apellido1: "",
        apellido2: "",
        nombre: "",
        nombre_colegio: "",
        nombre_curso: "",
        curso_colegio: "",
        clave_grupo_colegio: "",
        clave_grupo_cl: ""
    })
}