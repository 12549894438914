import { Grid } from '@mui/material';
import { H3 } from 'components/texts';
import { NIÑO, ADULTO } from './constants'
import { TextField } from "@mui/material";
import { RequestNivel } from 'objects/requests';
import React, { useEffect, useState } from 'react'
import { Checkbox } from 'components/forms/components';
import styles from './styles.js'

/**
 * Formulario con los datos de un libro
 */
export default function DataFormLibroNiveles({ libro, register, setValue, getValues }) {

  const [niveles, setNiveles] = useState([])

  useEffect(() => {
    new RequestNivel().all({}, (res) => { setNiveles(res.niveles) }, (res) => { setNiveles([]) })

    if(libro)
      setValue("niveles", libro.niveles_libro.map(item => item.id_nivel))
  }, [])

  const adulto = niveles.filter(item => item.edad == ADULTO)
  const niño   = niveles.filter(item => item.edad == NIÑO)

  /**
   * Actualiza los niveles del formulario
   * 
   * @param {Boolean} checked
   * @param {Boolean} checked
   */
  const updateNiveles = (checked, id) => {
    const niveles = getValues('niveles')

    if(checked) {
      niveles.push(id)
    } else {
      const index = niveles.indexOf(id)
      if(index != -1)
        niveles.splice(index, 1)
    }

    setValue('niveles', niveles, {shouldDirty: true})
  }

  /**
   * Render
   *
   * @returns {Component}
   */
  return (
      <Grid container px={3}>
          <Grid container item xs={12} xl={6} p={3}>
            <Grid item xs={12}><H3>Niveles para adultos: </H3></Grid>

            {adulto.map(item => (
              <Grid key={item.id} item xs={4}>
                <Checkbox 
                  name           = {item.valor} 
                  label          = {item.valor} 
                  defaultChecked = {libro?.niveles_libro.find(nivel_libro => nivel_libro.id_nivel == item.id) ? true : false} 
                  onClick        = {(checked) => {updateNiveles(checked, item.id)}} />
              </Grid>))}
          </Grid>

          <Grid container item xs={12} xl={6} p={3} sx={styles.nivelesNiñoGrid}>
            <Grid item xs={12}><H3>Niveles para niños: </H3></Grid>

            {niño.map(item => (
              <Grid key={item.id} item xs={4}>
                <Checkbox 
                  name           = {item.valor} 
                  label          = {item.valor} 
                  defaultChecked = {libro?.niveles_libro.find(nivel_libro => nivel_libro.id_nivel == item.id) ? true : false} 
                  onClick        = {(checked) => {updateNiveles(checked, item.id)}} />
              </Grid>))}
          </Grid>

          <TextField type={"hidden"} variant={"standard"} {...register ? register("niveles") : {}} />
      </Grid>
  )
}