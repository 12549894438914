import axios from 'objects/common/axiosconfig'

/**
 * Clase Request para gestionar peticiones HTTP a la API.
 * Utiliza axios para las peticiones y maneja la autenticación y errores globalmente.
 */
export default class Request {
    /**
     * Realiza una petición GET a la API.
     * 
     * @param {String} url URL del endpoint.
     * @param {Object} [params={}] Parámetros de la petición.
     * @param {Function} [callback=() => {}] Función a ejecutar con la respuesta.
     * @param {Function} [callbackError=() => {}] Función a ejecutar en caso de error.
     * @param {Function} [callbackFinally=() => {}] Función a ejecutar al finalizar la petición.
     */
    get(url, params = {}, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        axios.get(url, { params })
            .then(response => {
                callback(response.data);
            })
            .catch(error => {
                callbackError(this.handleError(error));
            })
            .finally(() => {
                if (callbackFinally) callbackFinally();
            });
    }


    /**
     * Realiza una petición para descargar archivos de la API.
     * 
     * @param {String} url URL del endpoint.
     * @param {Object} [params={}] Parámetros de la petición.
     * @param {Function} [callback=() => {}] Función a ejecutar con la respuesta.
     * @param {Function} [callbackError=() => {}] Función a ejecutar en caso de error.
     * @param {Function} [callbackFinally=() => {}] Función a ejecutar al finalizar la petición.
     */
    file(url, params = {}, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        axios.get(url, { params, responseType: 'blob' })
            .then(response => {
                callback(response.data);
            })
            .catch(error => {
                callbackError(this.handleError(error));
            })
            .finally(() => {
                if (callbackFinally) callbackFinally();
            });
    }


    /**
     * Realiza una petición POST a la API.
     * 
     * @param {String} url URL del endpoint.
     * @param {Object} [params={}] Cuerpo de la petición.
     * @param {Function} [callback=() => {}] Función a ejecutar con la respuesta.
     * @param {Function} [callbackError=() => {}] Función a ejecutar en caso de error.
     * @param {Function} [callbackFinally=() => {}] Función a ejecutar al finalizar la petición.
     */
    post(url, params = {}, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        console.log('params', params);
        axios.post(url, params)
            .then(response => {
                callback(response.data);
            })
            .catch(error => {
                callbackError(this.handleError(error));
            })
            .finally(() => {
                if (callbackFinally) callbackFinally();
            });
    }


    /**
     * Realiza una petición PUT a la API utilizando el método POST y un parámetro _method adicional.
     * 
     * @param {String} url URL del endpoint.
     * @param {Object} [params={}] Cuerpo de la petición.
     * @param {Function} [callback=() => {}] Función a ejecutar con la respuesta.
     * @param {Function} [callbackError=() => {}] Función a ejecutar en caso de error.
     * @param {Function} [callbackFinally=() => {}] Función a ejecutar al finalizar la petición.
     */
    put(url, params = {}, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        params.append("_method", "put")

        axios.post(url, params)
            .then(response => {
                callback(response.data);
            })
            .catch(error => {
                callbackError(this.handleError(error));
            })
            .finally(() => {
                if (callbackFinally) callbackFinally();
            });
    }


    /**
     * Realiza una petición DELETE a la API utilizando el método POST y un parámetro _method adicional.
     * 
     * @param {String} url URL del endpoint.
     * @param {Object} [params={}] Cuerpo de la petición.
     * @param {Function} [callback=() => {}] Función a ejecutar con la respuesta.
     * @param {Function} [callbackError=() => {}] Función a ejecutar en caso de error.
     * @param {Function} [callbackFinally=() => {}] Función a ejecutar al finalizar la petición.
     */
    delete(url, params = {}, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        params.append("_method", "delete")

        axios.post(url, params)
            .then(response => {
                callback(response.data);
            })
            .catch(error => {
                callbackError(this.handleError(error));
            })
            .finally(() => {
                if (callbackFinally) callbackFinally();
            });
    }

    /**
     * Maneja los errores de las respuestas HTTP, devolviendo un mensaje adecuado.
     * 
     * @param {Object} error Objeto de error de Axios.
     * @returns {String} Mensaje de error.
     */
    handleError(error) {
        // Si hay una respuesta del servidor y no es un error 500, usa el mensaje de error del servidor
        if (error.response) {
            return error.response.status !== 500 ? error.response.data.error ?? error.response.data.errors : "Error del servidor.";
        } else {
            // Si no hay respuesta del servidor, devuelve un mensaje de error genérico
            return "Error de conexión o respuesta no recibida del servidor.";
        }
    }
}
