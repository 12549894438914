import { Grid } from '@mui/material'
import { Text } from 'components/texts'
import { itemsActual } from '../searchAndOrderOptions'
import { Input, Select } from 'components/forms/components'
import { CursoEscolarSelect } from 'components/forms/components/Select/custom'

/**
 * Renderiza un formulario específico para la búsqueda de inscripciones de grupo en una academia.
 * Ofrece campos para filtrar las inscripciones por clave de grupo, año escolar y actualidad del grupo.
 * 
 * @param {Object} props Propiedades del componente.
 * @param {Function} props.register Función de react-hook-form para registrar campos del formulario.
 * @param {Object} props.errors Objeto de errores de react-hook-form para este formulario.
 * @param {Function} props.reset Función de react-hook-form para resetear el formulario a valores iniciales.
 * @param {Function} props.setValue Función de react-hook-form para establecer valores de los campos.
 * 
 * @returns {JSX.Element} Formulario de búsqueda de inscripciones de grupo.
 */
export default function InscripcionGruposAcademiaTemplate({ register, errors, reset, setValue }) {

    return (
        <>
            <Grid container mt={2} mb={1}>
                <Text fontWeight={'bold'}>Según datos de:</Text><br />
            </Grid>
            <Grid container spacing={2} mb={2}>

                <Grid item xs={4}>
                    <Input label="Clave grupo" width={"100%"} name={"clave_grupo"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <CursoEscolarSelect label={"Año"} name={"curso_escolar_id"} wantToReset={reset} register={register} errors={errors} defaultValue={null} setValue={setValue} searcheable />
                </Grid>

                <Grid item xs={4}>
                    <Select register={register} errors={errors} name="actual" items={itemsActual} defaultValue={itemsActual[0].value} />
                </Grid>
            </Grid>
        </>
    )
}





