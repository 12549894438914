import { useState } from 'react'

/**
 * Hook personalizado `useLogic` para manejar el estado y la lógica del formulario de control de almacén de libros.
 * Agrupa la gestión del estado del curso escolar, libros pedidos y libros devueltos en un solo lugar,
 * facilitando su manejo y actualización.
 * 
 * @param {Object} libro - Objeto que contiene la información inicial del libro, incluyendo las devoluciones y pedidos.
 * @returns {Object} Objeto que contiene los estados y funciones para actualizar el curso escolar, los libros pedidos y devueltos.
 */
export default function useDataLibroAlmacenLogic(libro) {
    const [cursoEscolar, setCursoEscolar] = useState(null)
    const [librosDevueltos, setLibrosDevueltos] = useState(libro?.devoluciones_libro ?? [])
    const [librosPedidos, setLibrosPedidos] = useState(libro?.pedidos_libro ?? [])

    return { cursoEscolar, setCursoEscolar, librosDevueltos, setLibrosDevueltos, librosPedidos, setLibrosPedidos }
}