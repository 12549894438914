import { COLOR } from "theme/Colors"
import React, { useState } from "react"
import { H3, Text } from "components/texts"
import { Grid, Stack } from "@mui/material"
import { Line } from "components/data_display"
import InfoIcon from '@mui/icons-material/Info'
import { AlumnoPicker } from 'components/forms/components/custom'
import { DatePicker, Input, Checkbox, Button } from "components/forms/components"
import { CreateIncidenciaModal, EditIncidenciasNoResueltasModal, DatosColegioModal } from "components/modals"
import { CursoEscolarSelect, ItemsSelect } from "components/forms/components/Select/custom"
import { RequestDatosColegio } from "objects/requests"
import { useErrorHandler } from "hooks"

/**
 * Este hook `useLogic` maneja la lógica de la funcionalidad relacionada con la gestión de datos de los alumnos y la visualización
 * de la información del colegio. Incluye estados para controlar la apertura y cierre de modales, datos del alumno seleccionado,
 * datos del colegio, y funciones para obtener los datos del colegio asociados a un alumno.
 *
 * @returns {Object} - Objeto que contiene los estados y funciones relacionadas con la lógica de la aplicación.
 */
function useLogic() {
    // Estados para controlar la apertura y cierre de modales, y los datos del alumno seleccionado y del colegio
    const [openIncidenciaModal, setOpenIncidenciaModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [alumnoSelected, setAlumnoSelected] = useState({});
    const [datosColegio, setDatosColegio] = useState([]);
    const handleError = useErrorHandler();

    /**
     * Función para obtener los datos del colegio asociados a un alumno específico.
     *
     * @param {string} idAlumno - Identificador del alumno del que se desean obtener los datos del colegio.
     */
    const getDatosColegio = (idAlumno) => {
        // Realiza una solicitud para obtener los datos del colegio del alumno específico
        new RequestDatosColegio().get(`?id_alumno=${idAlumno}`, {}, (res) => { setDatosColegio(res?.items?.data) }, (err) => handleError(err))
    }

    // Retorna los estados y funciones relevantes para la lógica de la aplicación
    return {
        datosColegio,
        getDatosColegio,
        alumnoSelected,
        setAlumnoSelected,
        openIncidenciaModal,
        setOpenIncidenciaModal,
        openEditModal,
        setOpenEditModal
    }
}


/**
 * Este componente presenta un formulario estructurado para capturar y editar la información relevante
 * a las inscripciones de los alumnos en diferentes grupos o actividades académicas. Se organiza en secciones
 * claramente definidas para facilitar la entrada de datos por parte del usuario y la visualización de la información.
 *
 * @param {Function} onChangeActividad - Función para manejar el cambio en la selección de la actividad.
 * @param {Function} onChangeCursoEscolar - Función para manejar el cambio en la selección del curso escolar.
 * @param {Object} inscripcion - Objeto con los datos previos de la inscripción, usado para inicializar valores predeterminados.
 * @param {Function} register - Función de react-hook-form para registrar los campos de entrada del formulario.
 * @param {Object} errors - Objeto que contiene los errores de validación de los campos del formulario.
 * @param {Function} setValue - Función de react-hook-form para establecer programáticamente los valores de los campos.
 * @param {Function} clearErrors - Función de react-hook-form para limpiar los errores de validación de un campo específico.
 * @param {boolean} isMounted - Indica si el componente está montado, utilizado para controlar la renderización condicional.
 * @param {Array} incidenciasNoResueltas - Array de incidencias no resueltas asociadas al alumno, usado para mostrar advertencias o acciones necesarias.
 * @param {Function} onSaveFinish - Función callback que se llama después de guardar con éxito los datos del formulario.
 *
 * Incorpora campos para la selección de alumnos, cursos escolares, modalidades de recogida y conocimiento, entre otros. 
 * También proporciona interacciones modales para gestionar incidencias relacionadas con el alumno.
 * 
 * @returns {JSX.Element}
 */
export default function DataFormInscripcionAcademias({
    navigate = null,
    onChangeActividad,
    onChangeCursoEscolar,
    onAlumnoSelected = () => { },
    inscripcion,
    register,
    errors,
    setValue,
    clearErrors,
    isMounted,
    incidenciasNoResueltas,
    onSaveFinish,
    operador = "Sin datos",
}) {
    const { datosColegio, getDatosColegio, alumnoSelected, setAlumnoSelected, openIncidenciaModal, setOpenIncidenciaModal, openEditModal, setOpenEditModal } = useLogic()

    const [openDatosColegioModal, setOpenDatosColegioModal] = useState(false)

    return (
        <Grid container>
            <Grid container px={5}>
                <Grid item xs={6} py={3} pr={2}>
                    <AlumnoPicker
                        label={"Alumno"}
                        editLabel={"Cambiar alumno"}
                        addLabel={"Añadir alumno"}
                        deleteLabel={"Borrar alumno"}
                        register={register}
                        errors={errors}
                        alumno={inscripcion?.alumno ?? null}
                        setValue={setValue}
                        name={'alumno_id'}
                        clearErrors={clearErrors}
                        onAlumnoSelected={onAlumnoSelected}
                        // Si está editando ya no se puede cambiar el alumno
                        canEdit={inscripcion ? false : true} />
                </Grid>

                <Grid item xs={6} style={{ borderLeft: "1px solid #D9D9D9" }} py={3} pl={3}>
                    <Stack spacing={2}>
                        <Grid item xs={12} pr={2} style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                            <Text>Operador</Text>
                            <Input defaultValue={operador} disabled />
                        </Grid>

                        <CursoEscolarSelect
                            name={"curso_escolar_id"}
                            onChange={({ target }) => onChangeCursoEscolar(target.value)}
                            register={register}
                            errors={errors}
                            defaultValue={inscripcion?.curso_escolar_id ?? null}
                            setValue={setValue}
                            searcheable />

                        <ItemsSelect
                            name={"forma_recogida_id"}
                            keyValue={"forma_recogida"}
                            register={register} errors={errors}
                            defaultValue={inscripcion?.forma_recogida_id ?? ""} />

                        <ItemsSelect
                            name={"forma_conocimiento_id"}
                            keyValue={"forma_conocimiento"}
                            register={register}
                            errors={errors}
                            defaultValue={inscripcion?.forma_conocimiento_id ?? ""} />

                        <ItemsSelect
                            name={"registro_actividad_id"}
                            searcheable
                            keyValue={"registro_actividad_academia"}
                            register={register}
                            errors={errors}
                            onChange={(_, actividad) => onChangeActividad(actividad)}
                            defaultValue={inscripcion?.registro_actividad_id ?? ""}
                            renderItem={(item) => {
                                return { value: item.id, label: item.actividad?.valor + " - " + item.modalidad_cursos?.nombre }
                            }} />
                    </Stack>
                </Grid>
            </Grid>

            <Grid container>
                <Line />
            </Grid>

            <Grid container px={5} py={3} spacing={2}>
                <Grid item xs={7}>
                    <ItemsSelect name={"empresa_id"} keyValue={"empresa"} register={register} errors={errors} defaultValue={inscripcion?.empresa_id ?? ""} />
                </Grid>

                <Grid item xs={2}>
                    <Checkbox name={"alumno_colectivo"} label={"Alumno de Colectivo"} register={register} defaultChecked={inscripcion?.alumno_colectivo ? true : false} />
                </Grid>
            </Grid>

            <Grid container>
                <Line />
            </Grid>

            <Grid container px={5} mt={2} spacing={2}>
                <Grid item xs={12}>
                    <H3>Preferencia de Horario</H3>
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect name={"preferencia_horario_id"} keyValue={"preferencia_horario"} register={register} errors={errors}
                        defaultValue={inscripcion?.preferencia_horario_id ?? ""} />
                </Grid>

                <Grid item xs={6}>
                    <Checkbox name={"avisado_inicio_clases"} label={"Avisado del comienzo de las clases"}
                        register={register} defaultChecked={inscripcion?.avisado_inicio_clases ? true : false} />
                </Grid>

                <Grid item xs={12}>
                    <Input label={"Comentarios Horario"} register={register} errors={errors} className="w-100" name={"comentarios_horario"}
                        defaultValue={inscripcion?.comentarios_horario ?? null} multiline minRows={6} maxRows={10} uppercase />
                </Grid>
            </Grid>

            <Grid container mt={4}>
                <Line />
            </Grid>

            <Grid container mt={4} spacing={1} px={5} justifyContent={'space-between'}>

                <Grid item xs={2}>
                    <Input label="Test de Nivel" width={"100%"} name={"nivel"} register={register} errors={errors} uppercase defaultValue={inscripcion?.nivel} />
                </Grid>


                <Grid item xs={1}>
                    <Checkbox name={"hermanos"} label={"Hermanos"} register={register} defaultChecked={inscripcion?.hermanos ? true : false} />
                </Grid>

                <Grid item xs={1}>
                    <Checkbox name={"aa"} label={"Antiguo Alumno"} register={register} defaultChecked={inscripcion?.aa ? true : false} />
                </Grid>
                {/**
                 *      Estos tres botones antes eran outline, modifique los estilos de estos para que sigan el patró de la aplicación,
                 *      el voton de incidencias no resultas esta outline, el de nueva incidencia para cambiarle el color hay que usar la propiedad
                 *      color y en datos de colegio le quitamos la propiedad variant para que coga por defecto contained
                 */}
                {incidenciasNoResueltas?.length > 0 ?
                    <Grid item xs={2}>
                        <Button onClick={() => setOpenEditModal(true)} variant="outlined">
                            Incidencias no resueltas
                        </Button>
                    </Grid> : null}

                <Grid item xs={2}>
                    <Button
                        className=""
                        onClick={() => setOpenIncidenciaModal(true)}
                        variant="outlined"
                        disabled={
                            !((alumnoSelected && alumnoSelected.hasOwnProperty('id')) || (inscripcion && inscripcion.alumno))}
                    >
                        Nueva incidencia
                    </Button>
                </Grid>

                <Grid item xs={2}>
                    <Stack alignItems={"center"}>
                        <Button
                            onClick={() => setOpenDatosColegioModal(true)}
                            variant="outlined"
                            disabled={
                                !((alumnoSelected && alumnoSelected.hasOwnProperty('id')) || (inscripcion && inscripcion.alumno))}
                        >
                            Datos del Colegio
                        </Button>
                    </Stack>
                </Grid>

            </Grid>

            <Grid container px={5} mt={2} spacing={2}>

                <Grid item xs={2.5} >
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_inscripcion"} label={"Fecha de inscripción"}
                        defaultValue={inscripcion?.fecha_inscripcion} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={2.5} >
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_incorporacion"} label={"Fecha de incorporación"}
                        defaultValue={inscripcion?.fecha_incorporacion ?? ""} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={2.5}>
                    <DatePicker register={register} errors={errors} uppercase className="w-100" name={"fecha_baja"} label={"Fecha baja"}
                        defaultValue={inscripcion?.fecha_baja ?? ""} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={4.5} >
                    <ItemsSelect
                        name={"motivo_baja_id"}
                        keyValue={"motivo_baja"}
                        register={register}
                        clearOption
                        errors={errors}
                        defaultValue={inscripcion?.motivo_baja_id ?? ""} />
                </Grid>
            </Grid>

            <CreateIncidenciaModal
                isMounted={isMounted}
                open={openIncidenciaModal}
                setOpen={setOpenIncidenciaModal}
                alumno={alumnoSelected?.hasOwnProperty('id') ? alumnoSelected : (inscripcion ? inscripcion.alumno : null)}
                onSaveFinish={onSaveFinish} />

            <EditIncidenciasNoResueltasModal
                isMounted={isMounted}
                incidencias={incidenciasNoResueltas}
                alumno={alumnoSelected?.hasOwnProperty('id') ? alumnoSelected : (inscripcion ? inscripcion.alumno : null)}
                open={openEditModal}
                setOpen={setOpenEditModal}
                onSaveFinish={onSaveFinish} />

            <DatosColegioModal
                open={openDatosColegioModal}
                setOpen={setOpenDatosColegioModal}
                // getDatosColegio > Función para obtener los datos del colegio asociados a un alumno específico.
                getDatosColegio={getDatosColegio}
                datosColegio={datosColegio}
                alumno={alumnoSelected?.hasOwnProperty('id') ? alumnoSelected : (inscripcion ? inscripcion.alumno : null)}
            />
        </Grid>
    )
}