import React, { useState, useRef } from 'react'
import { Container, Paper, Stack, Grid } from '@mui/material'
import { Button, LoadingButton } from 'components/forms/components';
import { BreadcrumbsList } from 'components/data_display'
import { Checkbox, Input } from 'components/forms/components'
import { GroupButtons } from 'components/data_display'
import { Radio, FormControlLabel, RadioGroup, Tab, Tabs, Box } from '@mui/material'
import { H3, Text } from 'components/texts'
import { useForm, Controller } from 'react-hook-form'
import { COLOR } from 'theme/Colors'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { TabContext } from '@mui/lab'
import { grupo_1, grupo_2, grupo_3, grupo_4, grupo_5, tabItems, placeholderText } from './constants';
// import { validationSchema } from 'components/forms/display/comunicaciones/parts/validation'
// import { yupResolver } from '@hookform/resolvers/yup'
import AttachFile from '@mui/icons-material/AttachEmail';
import Requests from './Requests/Requests'
import { enqueueSnackbar, SnackbarProvider } from 'notistack'

export default function ComunicacionesAlumnos() {
    const [tab, setTab] = useState("1")
    const [isLoading, setIsloading] = useState(false)
    const [lastActiveTab, setLastActiveTab] = useState("1");
    const [selectedButtons, setSelectedButtons] = useState(['Academia']);
    const inputC = useRef(null)
    const inputFileC = useRef(null)
    const accept = ".txt,.pdf,.docx,.doc"
    const { handleSubmit, setValue, getValues, register, unregister, control, formState: { errors }, reset } = useForm()

    const sendComunicaciones = () => {
        setIsloading(true)
        new Requests().sendComunicacion(getValues, (res) => {
            setIsloading(false)
            reset();
            enqueueSnackbar(<Text>Las comunicaciones se han enviado correctamente</Text>, { variant: 'success' });
        }, (res) => {
            enqueueSnackbar(<Text>{res}</Text>, { variant: "error" })
        })
    }

    const showFile = (field) => {
        const file = getValues(field)[0]
        if (file) {
            const tmppath = URL.createObjectURL(file)
            window.open(tmppath, '_blank')
        }
    }

    const updateInput = (value, ref) => {
        const array = value.split("\\")
        const newValue = array[array.length - 1]

        ref.setValue(newValue)
    }

    const handleButtonChange = (button) => {
        const index = selectedButtons.indexOf(button.label);

        if (index === -1) {
            selectedButtons.push(button.label);
        } else {
            selectedButtons.splice(index, 1);
        }

        setSelectedButtons([...selectedButtons]);

        if (button.isSelected === true) {
            setTab(button.id);
            setLastActiveTab(button.id);
        } else if (button.isSelected === false) {
            // Si el botón se deshabilita, encuentra el último botón habilitado
            const lastEnabledButton = selectedButtons.slice().reverse().find(label => {
                const tabItem = tabItems.find(item => item.title === label);
                return tabItem && tabItem.content !== null;
            });
            // Establece el tab en función del último botón habilitado
            if (lastEnabledButton) {
                const correspondingTab = tabItems.find(item => item.title === lastEnabledButton);
                setTab(correspondingTab.id);
                setLastActiveTab(correspondingTab.id);
            }
            unregister(button.label.toLowerCase())
        }
    };

    const sortTabs = () => {
        const selected    = tabItems.filter(obj => selectedButtons.includes(obj.title)).sort((a, b) => a.title.localeCompare(b.title))
        const notSelected = tabItems.filter(obj => !selectedButtons.includes(obj.title)).sort((a, b) => a.title.localeCompare(b.title))

        return [...selected, ...notSelected]
    }

    const tabsSorted = sortTabs()

    return (
        <>
            <Container maxWidth={false} sx={{ mt: 5 }}>

                <Stack direction="row" justifyContent={"space-between"} marginX={8}>
                    <BreadcrumbsList mx={0} breadcrumbsList={[
                        { to: "/", label: "Inicio" },
                        { to: "./../", label: "Comunicaciones", actualItem: true },
                    ]} />
                </Stack>

                <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
                    <Grid container spacing={1.5}>
                        <Grid item xs={12} >
                            <H3 fontWeight='bold'>DESTINATARIOS</H3>
                        </Grid>
                        <Grid item xs={2.5}>
                            <GroupButtons labels={grupo_1} onChange={handleButtonChange} />
                        </Grid>

                        <Grid item xs={2.5} >
                            <GroupButtons labels={grupo_2} onChange={handleButtonChange} />
                        </Grid>

                        <Grid item xs={2.5}>
                            <GroupButtons labels={grupo_3} disabled={true} onChange={handleButtonChange} />
                        </Grid>

                        <Grid item xs={2}>
                            <GroupButtons labels={grupo_4} disabled={true} onChange={handleButtonChange} />
                        </Grid>

                        <Grid item xs={2}>
                            <GroupButtons labels={grupo_5} disabled={true} onChange={handleButtonChange} />
                        </Grid>

                    </Grid>

                    <Grid container mt={4}>
                        <Grid item xs={3}>
                            <Controller
                                name="tipoGrupo"
                                control={control}
                                defaultValue={1}
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        sx={{ m: 1 }}
                                        onChange={(event) => {
                                            field.onChange(event);
                                        }}
                                        row>
                                        <FormControlLabel
                                            value={1}
                                            control={<Radio />}
                                            label="Todos" 
                                            defaultChecked />

                                        <FormControlLabel
                                            sx={{ ml: 1.5 }}
                                            value={0}
                                            control={<Radio />}
                                            label="Actuales" />
                                    </RadioGroup>
                                )} />
                        </Grid>

                        <Grid item xs={3} mt={1}>
                            <Checkbox name={"p_mandato"} label={"Pdte. Mandato"} register={register} />
                        </Grid>

                        <Grid item xs={6}>
                            <Text fontWeight='bold'>Destinatarios</Text>
                            <Stack mt={2.5}>
                                <Text>302 destinatarios ejemplo</Text>
                            </Stack>
                        </Grid>

                    </Grid>
                </Paper>

                <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <TabContext value={tab}>
                                <Box sx={{ borderBottom: 0, borderColor: 'divider' }} mx={0}>
                                    <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }} variant="scrollable">
                                        {tabsSorted
                                            .map((item) => {
                                                const correspondingButton = selectedButtons.find(button => button === item.title);
                                                return (
                                                    <Tab
                                                        key={item.id}
                                                        label={<Text fontWeight={tab === item.id ? "bold" : null}
                                                            color={
                                                                tab === item.id ?
                                                                    (COLOR.standard.main)
                                                                    :
                                                                    (!(correspondingButton) ? '#F0F0F0' : 'grey')
                                                            }>
                                                            {item.title}
                                                        </Text>}
                                                        value={item.id}
                                                        disabled={!(correspondingButton)}
                                                    />
                                                );
                                            })}
                                    </Tabs>

                                    {tabItems.map((item) => {
                                        const Template = item.content
                                        const correspondingButton = selectedButtons.find(button => button === item.title);
                                        if (correspondingButton !== undefined) {
                                            return (<TabPanel key={item.id} value={item.id}>
                                                <Template register={register} errors={errors} getValues={getValues} setValue={setValue} unregister={unregister} />
                                            </TabPanel>)
                                        } else {
                                            return null
                                        }

                                    })}
                                </Box>
                            </TabContext>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
                    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} rowSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} mb={1}>
                            <H3>Mensaje</H3>
                        </Grid>
                        <Grid item xs={6}>
                            <Input label="Asunto" register={register} errors={errors} className="w-100" name={"asunto"} uppercase />
                        </Grid>

                        <Grid item xs={3} xl={3}>
                            <Stack>
                                <Input ref={inputC} className="w-100" disabled useValue label={'Adjuntar Archivo'} />
                                <Input ref={inputFileC} className="w-100" type={"file"} register={register} name={"archivo"} style={{ display: "none" }}
                                    inputProps={{ onChange: ({ target }) => updateInput(target.value, inputC.current), accept }} />
                            </Stack>
                        </Grid>

                        <Grid item xs={2} xl={3} display={"flex"} alignItems={"end"}>
                            <Stack direction={"row"} spacing={2} className={"w-100"}>
                                <Button className={"w-100 py-3"} onClick={() => { inputFileC.current.input.click() }} size={'small'} >
                                    <AttachFile style={{ marginLeft: '-8px' }} />
                                </Button>
                                <Button className={"w-100 py-3"} color={"cancel"} onClick={() => showFile("archivo")}>
                                    Ver archivo
                                </Button>
                            </Stack>
                        </Grid>

                        <Grid item xs={12}>
                            <Input placeholder={placeholderText} defaultValue={placeholderText}
                                register={register} errors={errors}
                                className="w-100" name={"mensaje"} multiline minRows={6} maxRows={10} />
                        </Grid>
                    </Grid>
                    <Grid item container direction={'row-reverse'} mt={4}>
                        <LoadingButton loading={isLoading} color='standard' onClick={handleSubmit(sendComunicaciones)}>
                            Enviar
                        </LoadingButton>
                    </Grid>
                </Paper>
                <SnackbarProvider autoHideDuration={5000} />
            </Container>
        </>
    )
}

