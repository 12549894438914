import { RequestGrupoColegio } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Devuelve el colegio
     * 
     * @param {Int}      id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getGrupo(id, callbackOk = () => {}, callbackError = () => {}) {
        const params = {relations: 'seguimiento_trimestral,profesor_actual,libro,colegio,actividad,profesores,horario,curso_escolar,registro_actividad,descripcion_horario,nivel'}
        new RequestGrupoColegio().get(id, params, (res) => {
            res.grupo ? callbackOk(res.grupo) : callbackError(res)
        })
    }
}
