import React from 'react'
import { Grid } from '@mui/material';
import { H3 } from 'components/texts';
import { DatePicker, Input } from 'components/forms/components';

/**
 * Formulario con los datos generales del grupo
 */
export default function SeguimientoTrimestralDataForm({ grupo, register, errors, setValue }) {

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container p={5}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <H3>1º Trimestre</H3>
                </Grid>
                
                <Grid item xs={12}>
                    <DatePicker register={register} errors={errors} className="w-50" name={"fecha_entrega_t1"} label={"Fecha de entrega"} defaultValue={grupo?.seguimiento_trimestral?.fecha_entrega_t1 ?? null} setValue={setValue} />
                </Grid>

                <Grid item xs={4}>
                    <Input label={"Objetivos alcanzados"} register={register} errors={errors} className="w-100" name={"objetivos_alcanzados_t1"} defaultValue={grupo?.seguimiento_trimestral?.objetivos_alcanzados_t1 ?? null} multiline minRows={6} maxRows={10} />
                </Grid>
                <Grid item xs={4}>
                    <Input label={"Evolución de los alumnos"} register={register} errors={errors} className="w-100" name={"evolucion_alumnos_t1"} defaultValue={grupo?.seguimiento_trimestral?.evolucion_alumnos_t1 ?? null} multiline minRows={6} maxRows={10} />
                </Grid>
                <Grid item xs={4}>
                    <Input label={"Conclusiones y observaciones"} register={register} errors={errors} className="w-100" name={"conclusiones_observaciones_t1"} defaultValue={grupo?.seguimiento_trimestral?.conclusiones_observaciones_t1 ?? null} multiline minRows={6} maxRows={10} />
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                    <H3>2º Trimestre</H3>
                </Grid>
                
                <Grid item xs={12}>
                    <DatePicker register={register} errors={errors} className="w-50" name={"fecha_entrega_t2"} label={"Fecha de entrega"} defaultValue={grupo?.seguimiento_trimestral?.fecha_entrega_t2 ?? null} setValue={setValue} />
                </Grid>

                <Grid item xs={4}>
                    <Input label={"Objetivos alcanzados"} register={register} errors={errors} className="w-100" name={"objetivos_alcanzados_t2"} defaultValue={grupo?.seguimiento_trimestral?.objetivos_alcanzados_t2 ?? null} multiline minRows={6} maxRows={10} />
                </Grid>
                <Grid item xs={4}>
                    <Input label={"Evolución de los alumnos"} register={register} errors={errors} className="w-100" name={"evolucion_alumnos_t2"} defaultValue={grupo?.seguimiento_trimestral?.evolucion_alumnos_t2 ?? null} multiline minRows={6} maxRows={10} />
                </Grid>
                <Grid item xs={4}>
                    <Input label={"Conclusiones y observaciones"} register={register} errors={errors} className="w-100" name={"conclusiones_observaciones_t2"} defaultValue={grupo?.seguimiento_trimestral?.conclusiones_observaciones_t2 ?? null} multiline minRows={6} maxRows={10} />
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                    <H3>3º Trimestre</H3>
                </Grid>
                
                <Grid item xs={12}>
                    <DatePicker register={register} errors={errors} className="w-50" name={"fecha_entrega_t3"} label={"Fecha de entrega"} defaultValue={grupo?.seguimiento_trimestral?.fecha_entrega_t3 ?? null} setValue={setValue} />
                </Grid>

                <Grid item xs={4}>
                    <Input label={"Objetivos alcanzados"} register={register} errors={errors} className="w-100" name={"objetivos_alcanzados_t3"} defaultValue={grupo?.seguimiento_trimestral?.objetivos_alcanzados_t3 ?? null} multiline minRows={6} maxRows={10} />
                </Grid>
                <Grid item xs={4}>
                    <Input label={"Evolución de los alumnos"} register={register} errors={errors} className="w-100" name={"evolucion_alumnos_t3"} defaultValue={grupo?.seguimiento_trimestral?.evolucion_alumnos_t3 ?? null} multiline minRows={6} maxRows={10} />
                </Grid>
                <Grid item xs={4}>
                    <Input label={"Conclusiones y observaciones"} register={register} errors={errors} className="w-100" name={"conclusiones_observaciones_t3"} defaultValue={grupo?.seguimiento_trimestral?.conclusiones_observaciones_t3 ?? null} multiline minRows={6} maxRows={10} />
                </Grid>
            </Grid>
        </Grid>
    )
}
