import Statics from 'objects/common/Statics'
import Request from '../common/requests/Request'
import { API_URL } from 'constants/Api'

export default class RequestMaster {

    constructor() {
        this.request = new Request()
        this.URL_BASE = ''
    }


    /**
     * GET
     * 
     * @param {String}      path             Puede ser un id o un conjunto de ids del siguiente formato 1&2&3
     * @param {Object}      params           Parámetros para el método get
     * @param {Function}    callback         Función de correcta
     * @param {Function}    callbackError    Función de erronea
     * @param {Function}    callbackFinally  Función de respuesta que siempre se ejecuta
     */
    get(path = "", params = {}, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const url = this.generateURL(path)
        this.request.get(url, params, callback, callbackError, callbackFinally)
    }

    /**
     * Búsqueda
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    search(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.get(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * GET para obtener PDFS
     * 
     * @param {String}      path             Puede ser un id o un conjunto de ids del siguiente formato 1&2&3
     * @param {Object}      params           Parámetros para el método get
     * @param {Function}    callback         Función de correcta
     * @param {Function}    callbackError    Función de erronea
     */
    pdf(path = "", params = {}, callbackError = () => { }, callbackFinally = () => { }) {
        const url = this.generateURL(path)
        this.request.file(url, params, (data) => {
            Statics.openFileInExplorer(data, "application/pdf")
        }, callbackError, callbackFinally)
    }


    /**
     * GET para obtener excels
     * 
     * @param {String}      path             Puede ser un id o un conjunto de ids del siguiente formato 1&2&3
     * @param {Object}      params           Parámetros para el método get
     * @param {Function}    callback         Función de correcta
     * @param {Function}    callbackError    Función de erronea
     */
    excel(params = {}, fileName = "excel", callbackOk = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const url = this.generateURL("excel")
        this.request.file(url, params, (data) => {
            Statics.downloadFileInExplorer(data, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", fileName)
            callbackOk(data)
        }, callbackError, callbackFinally)
    }

    /**
     * GET para obtener excels
     * 
     * @param {String}      path             Puede ser un id o un conjunto de ids del siguiente formato 1&2&3
     * @param {Object}      params           Parámetros para el método get
     * @param {Function}    callback         Función de correcta
     * @param {Function}    callbackError    Función de erronea
     */
    zip(path = "", params = {}, fileName = 'file', callbackOk = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const url = this.generateURL(path)
        this.request.file(url, params, (data) => {
            Statics.downloadZip(data, fileName)
            callbackOk(data)
        }, callbackError, callbackFinally)
    }


    /**
     * GET para obtener PDFS
     * 
     * @param {String}      path             Puede ser un id o un conjunto de ids del siguiente formato 1&2&3
     * @param {Object}      params           Parámetros para el método get
     * @param {Function}    callback         Función de correcta
     * @param {Function}    callbackError    Función de erronea
     */
    file(path = "", type = null, params = {}, callbackOk = () => { }, callbackError = () => { }) {
        const url = this.generateURL(path)
        if (type) {
            this.request.file(url, params, (data) => {
                callbackOk(data)
            }, callbackError)
        } else {
            window.open(url, '_blank')
        }
    }


    create(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.post(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * POST
     * 
     * @param {String}      path             Puede ser un id o un conjunto de ids del siguiente formato 1&2&3
     * @param {Object}      params           Parámetros para el método get
     * @param {Function}    callback         Función de correcta
     * @param {Function}    callbackError    Función de erronea
     * @param {Function}    callbackFinally  Función de respuesta que siempre se ejecuta
     */
    post(path = "", params = new FormData(), callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const url = this.generateURL(path)
        this.request.post(url, params, callback, callbackError, callbackFinally)
    }


    /**
     * PUT
     * 
     * @param {String}      path             Puede ser un id o un conjunto de ids del siguiente formato 1&2&3
     * @param {Object}      params           Parámetros para el método get
     * @param {Function}    callback         Función de correcta
     * @param {Function}    callbackError    Función de erronea
     * @param {Function}    callbackFinally  Función de respuesta que siempre se ejecuta
     */
    put(path = "", params = new FormData(), callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const url = this.generateURL(path)
        this.request.put(url, params, callback, callbackError, callbackFinally)
    }


    /**
     * DELETE
     * 
     * @param {String}      path             Puede ser un id o un conjunto de ids del siguiente formato 1&2&3
     * @param {Object}      params           Parámetros para el método get
     * @param {Function}    callback         Función de correcta
     * @param {Function}    callbackError    Función de erronea
     * @param {Function}    callbackFinally  Función de respuesta que siempre se ejecuta
     */
    delete(path = "", params = new FormData(), callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const url = this.generateURL(path)
        this.request.delete(url, params, callback, callbackError, callbackFinally)
    }


    /**
     * Genera la url
     * 
     * @param {String} path
     */
    generateURL = (path) => {
        return this.URL_BASE + (path === "" ? "" : "/" + path)
    }
}
