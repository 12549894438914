import { Grid } from '@mui/material'
import React, { Component } from 'react'
import { Text } from 'components/texts'
import { Input } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Esta es la plantilla para cuando se busque por los datos basicos del alumno
 */
export default function CandidatosTemplate({ errors, register, reset }) {

    return (
        <Grid container>
            <Grid container mt={2} mb={1}>
                <Text fontWeight={'bold'}>Según datos de:</Text><br />
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Input label="Nombre" width={"100%"} name={"nombre"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <Input label="Primer apellido" width={"100%"} name={"apellido1"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <Input label="Segundo apellido" width={"100%"} name={"apellido2"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <Input label="Email" width={"100%"} name={"email"} register={register} errors={errors} />
                </Grid>

                <Grid item xs={2}>
                    <ItemsSelect name={"tipo_documento_id"} keyValue={"tipo_documento"} register={register} errors={errors} wantToReset={reset} />
                </Grid>

                <Grid item xs={4}>
                    <Input label="Número" width={"100%"} name={"numero_documento"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={2}>
                    <ItemsSelect name={"sexo_id"} keyValue={"sexo"} register={register} errors={errors} />
                </Grid>
            </Grid>
        </Grid>
    )
}

