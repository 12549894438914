import React, { Component } from "react";
import Skeleton from "@mui/material/Skeleton";
import { Grid } from "@mui/material";

/**
 * Informacion básica del alumno
 */
export default class SkeletonAlumno extends Component {
	//-------------------------------------------------------
	//-------------------- CLASS FUNCTIONS ------------------
	//-------------------------------------------------------

	//-------------------------------------------------------
	//------------------- RENDER FUNCTIONS ------------------
	//-------------------------------------------------------

	/**
	 * Render
	 *
	 * @returns {Component}
	 */
	render() {
		return (
			<Grid container spacing={2}>
				<Grid container item mt={1}>
					<Grid item xs={10}>
						<Skeleton
							variant="text"
							sx={{ fontSize: "2em" }}
							width={"25%"}
						/>
					</Grid>
					<Grid item xs={2} justifyContent={"end"}>
						<Skeleton
							variant="rounded"
							width={"100%"}
							height={30}
						/>
					</Grid>
				</Grid>

				<Grid container item mt={3}>
					<Grid container item xs={4} alignItems={"center"}>
						<Grid item xs={2}>
							<Skeleton
								variant="rounded"
								width={"100%"}
								height={"100%"}
							/>
						</Grid>
						<Grid xs={10} spacing={2}>
							<Skeleton
								variant="text"
								sx={{ fontSize: "1em", marginBottom: "7px" }}
								width={"70%"}
							/>
							<Skeleton
								variant="text"
								sx={{ fontSize: "1em", marginBottom: "7px" }}
								width={"70%"}
							/>
							<Skeleton
								variant="text"
								sx={{ fontSize: "1em", marginBottom: "7px" }}
								width={"70%"}
							/>
						</Grid>
					</Grid>
					<Grid container item xs={4} alignItems={"center"}>
						<Grid item xs={2}>
							<Skeleton
								variant="rounded"
								width={"100%"}
								height={"100%"}
							/>
						</Grid>
						<Grid xs={10} spacing={2}>
							<Skeleton
								variant="text"
								sx={{ fontSize: "1em", marginBottom: "7px" }}
								width={"70%"}
							/>
							<Skeleton
								variant="text"
								sx={{ fontSize: "1em", marginBottom: "7px" }}
								width={"70%"}
							/>
							<Skeleton
								variant="text"
								sx={{ fontSize: "1em", marginBottom: "7px" }}
								width={"70%"}
							/>
						</Grid>
					</Grid>
					<Grid container item xs={4} alignItems={"center"}>
						<Grid item xs={2}>
							<Skeleton
								variant="rounded"
								width={"100%"}
								height={"100%"}
							/>
						</Grid>
						<Grid xs={10} spacing={2}>
							<Skeleton
								variant="text"
								sx={{ fontSize: "1em", marginBottom: "7px" }}
								width={"70%"}
							/>
							<Skeleton
								variant="text"
								sx={{ fontSize: "1em", marginBottom: "7px" }}
								width={"70%"}
							/>
							<Skeleton
								variant="text"
								sx={{ fontSize: "1em", marginBottom: "7px" }}
								width={"70%"}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}
