import Statics from 'objects/common/Statics'
import { RequestInscripcionColegio, RequestPagoInscripcion, RequestSubvencionPPAA, RequestVentaLibro } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Hace la petición para crear el colegio
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getInscripcion(id, callbackOk = () => {}, callbackError = () => {}) {
        const params = {relations: 'alumno.inscripciones_colegios.subvencion_ppaa,grupo,pagos,venta_libro,venta_libro_asociado'}
        new RequestInscripcionColegio().get(id, params, (res) => { callbackOk(res.inscripcion ?? null) }, callbackError)
    }


    /**
     * Hace la petición para actualizar una inscripcion
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editInscripcion(id_inscripcion, getValues, callbackOk = () => {}, callbackError = () => {}) {
        //-- GENERAL --//
        const formData = new FormData
        formData.append('id_curso_escolar',       getValues('curso_escolar_id'))
        formData.append('id_forma_recogida',      getValues('id_forma_recogida'))
        formData.append('id_forma_conocimiento',  getValues('id_forma_conocimiento'))
        formData.append('id_colegio',             getValues('id_colegio'))
        formData.append('id_registro_actividad',  getValues('id_registro_actividad'))
        formData.append('id_descripcion_horario', getValues('id_descripcion_horario'))
        formData.append('hermanos',               getValues('hermanos') ? 1 : 0)
        formData.append('encuestado',             getValues('encuestado') ? 1 : 0)
        formData.append('test_nivel',             getValues('test_nivel').toUpperCase())

        const fecha_inscripcion = getValues("fecha_inscripcion")
        formData.append("fecha_inscripcion", Statics.datePickerTimeFormat(fecha_inscripcion))

        const fecha_incorporacion = getValues("fecha_incorporacion")
        formData.append("fecha_incorporacion", Statics.datePickerTimeFormat(fecha_incorporacion))

        const fecha_baja = getValues("fecha_baja")
        formData.append("fecha_baja", Statics.datePickerTimeFormat(fecha_baja))

        //-- GRUPO --//
        formData.append('id_grupo',             getValues('id_grupo_colegio'))
        formData.append('id_motivo_baja',       getValues('id_motivo_baja'))
        formData.append('comentarios_profesor', getValues('comentarios_profesor').toUpperCase())
        formData.append('observaciones',        getValues('observaciones').toUpperCase())
        
        //-- PAGO --//
        formData.append('id_forma_pago',        getValues('id_forma_pago'))
        formData.append('id_tipo_matricula',    getValues('id_tipo_matricula'))
        formData.append('cantidad_matricula',   getValues('cantidad_matricula'))
        formData.append('id_tipo_pago',         getValues('id_tipo_pago'))
        formData.append('cantidad',             getValues('cantidad'))
        formData.append('incluido_seneca',      getValues('incluido_seneca') ? 1 : 0)
        formData.append('aplicar_subvencion',   getValues('aplicar_subvencion') ? 1 : 0)
        formData.append('observaciones_pagos',  getValues('observaciones_pagos').toUpperCase())

        const fecha_pago_matricula = getValues("fecha_pago_matricula")
        formData.append("fecha_pago_matricula", Statics.datePickerTimeFormat(fecha_pago_matricula))

        //-- LIBRO --//
        formData.append('id_libro',         getValues('id_libro'))
        formData.append('libro_solicitado', getValues('libro_solicitado') ? 1 : 0)
        formData.append('precio',           getValues('precio'))

        formData.append('id_libro_asociado',         getValues('id_libro_asociado'))
        formData.append('libro_asociado_solicitado', getValues('libro_asociado_solicitado') ? 1 : 0)
        formData.append('precio_asociado',           getValues('precio_asociado'))
        
        const fecha_solicitud          = getValues("fecha_solicitud")
        const fecha_pago               = getValues("fecha_pago")
        const fecha_entrega            = getValues("fecha_entrega")
        const fecha_asociado_solicitud = getValues("fecha_asociado_solicitud")
        const fecha_pago_asociado      = getValues("fecha_pago_asociado")
        const fecha_entrega_asociado   = getValues("fecha_entrega_asociado")

        formData.append("fecha_solicitud",          Statics.datePickerTimeFormat(fecha_solicitud))
        formData.append("fecha_pago",               Statics.datePickerTimeFormat(fecha_pago))
        formData.append("fecha_entrega",            Statics.datePickerTimeFormat(fecha_entrega))
        formData.append("fecha_asociado_solicitud", Statics.datePickerTimeFormat(fecha_asociado_solicitud))
        formData.append("fecha_pago_asociado",      Statics.datePickerTimeFormat(fecha_pago_asociado))
        formData.append("fecha_entrega_asociado",   Statics.datePickerTimeFormat(fecha_entrega_asociado))

        new RequestInscripcionColegio().edit(id_inscripcion, formData, callbackOk, callbackError)
    }


    /**
     * Metodo para crear/editar los pagos de una inscripcion
     * 
     * @param {Int}      id_inscripcion 
     * @param {Object}   pagos 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createPagos(id_inscripcion, pagos, callbackOk = () => {}, callbackError = () => {}, callbackFinally = () => {}) {
        const formData = new FormData
        for (const index in pagos) {
            formData.append(`pagos[${index}][mes]`,          pagos[index].mes ?? "")
            formData.append(`pagos[${index}][fecha]`,        pagos[index].fecha ?? "")
            formData.append(`pagos[${index}][cantidad]`,     pagos[index].cantidad ?? "")
            formData.append(`pagos[${index}][pagado]`,       pagos[index].pagado ?? "")
            formData.append(`pagos[${index}][otros_cargos]`, pagos[index].otros_cargos ?? "")
            formData.append(`pagos[${index}][subvencion]`,   pagos[index].subvencion ?? "")
            formData.append(`pagos[${index}][total]`,        pagos[index].total ?? "")
        }

        formData.append('inscripcion_type', "inscripcion-colegio")
        formData.append('inscripcion_id',    id_inscripcion)

        new RequestPagoInscripcion().updateMultiple(formData, callbackOk, callbackError, callbackFinally)
    }

    
    /**
     * Metodo para crear/actualizar la subvención de un alumno
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createSubvencion(getValues, callbackOk = () => {}, callbackError = () => {}) {
        const formData = new FormData
        formData.append('id_alumno',             getValues('id_alumno'))
        formData.append('id_curso_escolar',      getValues('curso_escolar_id'))
        formData.append('subvencionado',         getValues('subvencionado') ? 1 : 0)
        formData.append('id_tipo_subvencion',    getValues('id_tipo_subvencion'))
        formData.append('comprobado',            getValues('comprobado') ? 1 : 0)
        formData.append('subvencion_definitiva', getValues('definitiva') ? 1 : 0)

        const fecha_ppaa = getValues("fecha_ppaa")
        formData.append("fecha_ppaa", Statics.datePickerTimeFormat(fecha_ppaa))
        
        new RequestSubvencionPPAA().create(formData, callbackOk, callbackError)
    }


    /**
     * Metodo para crear un registro de una venta de un libro
     * 
     * @param {Int}      id 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editVentaLibro(id, getValues, callbackOk = () => {}, callbackError = () => {}) {
        const formData = this.ventaLibroFormData(getValues)
        new RequestVentaLibro().edit(id, formData, callbackOk, callbackError)
    }

    
    /**
     * Metodo para crear un registro de una venta de un libro
     * 
     * @param {Int}      id 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editVentaLibroAsociado(id, getValues, callbackOk = () => {}, callbackError = () => {}) {
        const formData = this.ventaLibroFormData(getValues, true)
        new RequestVentaLibro().edit(id, formData, callbackOk, callbackError)
    }


    /**
     * Metodo para borrar un registro de la venta de un libro
     * 
     * @param {Int}      id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    deleteVentaLibro = (id, callbackOk = () => {}, callbackError = () => {}) => {
        new RequestVentaLibro().erase(id, new FormData(), callbackOk, callbackError)
    }


    /**
     * Metodo para crear un registro de una venta de un libro
     * 
     * @param {Int}      id_inscripcion 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createVentaLibro(id_inscripcion, getValues, callbackOk = () => {}, callbackError = () => {}) {
        const formData = this.ventaLibroFormData(getValues)
        formData.append('inscripcion_id',   id_inscripcion)

        new RequestVentaLibro().create(formData, callbackOk, callbackError)
    }
    
    
    /**
     * Metodo para crear un registro de una venta de un libro
     * 
     * @param {Int}      id_inscripcion 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createVentaLibroAsociado(id_inscripcion, getValues, callbackOk = () => {}, callbackError = () => {}) {
        const formData = this.ventaLibroFormData(getValues, true)
        formData.append('inscripcion_id',   id_inscripcion)

        new RequestVentaLibro().create(formData, callbackOk, callbackError)
    }


    /**
     * Comprueba que peticion tiene que hacer
     */
    crudLibro = (inscripcion, getValues, resolve, reject) => {
        const libroSolicitado = getValues('libro_solicitado');

        if(inscripcion.venta_libro) {
            libroSolicitado ? this.editVentaLibro(inscripcion.venta_libro.id, getValues, resolve, reject) : this.deleteVentaLibro(inscripcion.venta_libro.id, resolve, reject)
        } else if(libroSolicitado) {
            this.createVentaLibro(this.state.inscripcion.id, getValues, resolve, reject)
        } else {
            resolve()
        }
    }


    /**
     * Comprueba que peticion tiene que hacer
     */
    crudLibroAsociado = (inscripcion, getValues, resolve, reject) => {
        const libroSolicitado = getValues('libro_asociado_solicitado');

        if(inscripcion.venta_libro_asociado) {
            libroSolicitado ? this.editVentaLibroAsociado(inscripcion.venta_libro_asociado.id, getValues, resolve, reject) : this.deleteVentaLibro(inscripcion.venta_libro_asociado.id, resolve, reject)
        } else if(libroSolicitado) {
            this.createVentaLibroAsociado(inscripcion.id, getValues, resolve, reject)
        } else {
            resolve()
        }
    }


    /**
     * Crea y devuelve el FormData para las ventaLibro
     * 
     * @param {Function} getValues 
     */
    ventaLibroFormData = (getValues, asociado = false) => {
        const sufix = asociado ? "_asociado" : ""

        const formData = new FormData
        formData.append('curso_escolar_id', getValues('id_curso_escolar'))
        formData.append('libro_id',         getValues(`id_libro${sufix}`) ?? "")
        formData.append('precio',           getValues(`precio${sufix}`))
        formData.append('inscripcion_type', 'inscripcion-colegio')
        if(asociado)
            formData.append('asociado', 1)
        
        const fecha_peticion = getValues(`fecha${sufix}_peticion`)
        formData.append("fecha_peticion", Statics.datePickerTimeFormat(fecha_peticion))

        const fecha_pago = getValues(`fecha_pago${sufix}`)
        formData.append("fecha_pago", Statics.datePickerTimeFormat(fecha_pago))

        const fecha_entrega = getValues(`fecha_entrega${sufix}`)
        formData.append("fecha_entrega",Statics.datePickerTimeFormat(fecha_entrega))

        return formData
    }
}
