import React, { useRef } from "react";
import { H3, Text } from "../../../texts";
import { LoadingButton, Input } from "../../components"
import { publicRoutes } from "../../../../router/routes.js";
import { RequestAuth } from "../../../../objects/requests";
import { SnackbarAlert, Link } from "../../../data_display";
//VALIDACION FORMULARIO
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import validationSchema from "./validation"
//REDUX
import { useDispatch } from "react-redux"
import { updateUser } from "../../../../store/actions/userActions"

/**
 * Formulario de logueo
 */
export default function LoginForm({ snackBarAlert }) {
	// Métodos para controlar los formularios
	const { register, handleSubmit, formState: { errors }, getValues } = useForm({ resolver: yupResolver(validationSchema) })

	//REFS
	const buttonRef = useRef(null);
	const snackBarAlertRef = useRef(null);

	const dispatch = useDispatch()

	/**
	 * Obtiene los datos del estado y hace la petición del login
	 */
	const login = () => {
		buttonRef.current.setLoading(true)

		var formData = new FormData()
		formData.append("numero_documento", getValues('numero_documento'))
		formData.append("password", 		getValues('password'))

		var requestAuth = new RequestAuth()
		requestAuth.login(formData, (res) => {
			dispatch(updateUser({
				user: res.usuario,
				permissions: res.authorization.permissions,
				roles: res.authorization.roles,
				token: res.authorization.token
			}))

			buttonRef.current.setLoading(false)
		}, (res) => {
			buttonRef.current.setLoading(false)

			snackBarAlertRef.current.updateState({ message: res, severity: "error", open: true })
		})
	}


	// RETURN
	return (
		<form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
			<H3>Iniciar sesión</H3>
			<div className="d-flex flex-column">
				<Input label="Numero de documento" className="my-2" register={register} errors={errors} name={"numero_documento"} />

				<Input type="password" label="Contraseña" className="my-2" register={register} errors={errors} name={"password"} />

				{/* <Checkbox label="Mantener mi sesión iniciada." /> */}

				<LoadingButton
					ref={buttonRef}
					className={"mt-5 align-self-start"}
					size="large"
					onClick={handleSubmit(login)}>

					Iniciar sesión
				</LoadingButton>

				<div className={"mt-5"}>
					<Text>
						¿Has olvidado tu contraseña? <Link to={publicRoutes.RECOVER_PASSWORD}>Recupérala aquí</Link>.
					</Text>
				</div>
			</div>

			<SnackbarAlert ref={snackBarAlertRef} />
		</form>
	);
}
