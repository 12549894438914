import { Component } from "react"
import { Grid } from "@mui/material"
import { Input } from "components/forms/components"
import { Text } from "components/texts"
import { CursoEscolarSelect, ItemsSelect } from "components/forms/components/Select/custom"

export default class ClasesParticularesPendientesProfesorTemplate extends Component {
    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props) {
        super(props)
    }

    /**
     * Render
     *
     * @returns {Component}
     */
    render() {
        const { register, errors, setValue } = this.props
        return (
            <>
                <Grid container mt={2} mb={1}>
                    <Text fontWeight={"bold"}>Según datos de:</Text>
                    <br />
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <CursoEscolarSelect name={"curso_escolar_id"} wantToReset={this.props.reset} register={register} errors={errors} defaultValue={null} setValue={setValue} searcheable />
                    </Grid>

                    <Grid item xs={4}>
                        <ItemsSelect label={"Materia"} name={"actividad_id"} keyValue={"actividad"} register={register} errors={errors} wantToReset={this.props.reset} searcheable />
                    </Grid>

                    <Grid item xs={4}>
                        <ItemsSelect name={"lugar_clase_id"} keyValue={"lugar_clase"} register={register} errors={errors} wantToReset={this.props.reset} />
                    </Grid>

                    <Grid item xs={6}>
                        <ItemsSelect label={"Origen del Prof."} name={"origen_profesor_id"} keyValue={"origen_empleado"} register={register} errors={errors} wantToReset={this.props.reset} />
                    </Grid>

                    <Grid item xs={6}>
                        <ItemsSelect name={"sexo_profesor_id"} keyValue={"sexo"} register={register} errors={errors} wantToReset={this.props.reset} />
                    </Grid>


                </Grid>
            </>
        )
    }
}