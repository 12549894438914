import { useForm } from 'react-hook-form'
import RequestsEdit from './Requests/Requests.js'
import RequestsCreate from '../create/Requests/Requests.js'
import { useParams, useNavigate } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangesContext } from 'contexts/ChangesContext'
import { EditHeader } from 'pages/logged/admin/components'
import { Box, Container, Paper, Stack } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import React, { useEffect, useRef, useState, useContext } from 'react'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
import { validationSchema } from 'components/forms/display/alumno/DataForm/validation.js'
import { DataFormAlumno, DataDomiciliacionBancariaFormAlumno } from 'components/forms/display'
import AlumnoDataSkeleton from 'pages/logged/admin/alumnos/alumno/single/parts/AlumnoData/skeleton'
import AlumnoDomiciliacionDataSkeleton from 'pages/logged/admin/alumnos/alumno/single/parts/DomiciliacionData/skeleton'
import ResponsableData from './parts/ResponsableData/index.jsx'
import { useErrorHandler, useCheckMinorAge, useEditAlumno, useCheckDirtyFields, useAlumnoEditWrapperLogic } from 'hooks'

/**
 * Componente Wrapper que utiliza el hook `useWrapperLogic` para manejar la lógica de recuperación y actualización
 * de los datos del alumno específico para su edición. Proporciona un entorno para el componente `EditAlumnos`,
 * pasando los estados y funciones necesarios para su funcionamiento. Además, integra el `SnackbarProvider` para
 * la gestión de notificaciones dentro del flujo de edición del alumno.
 * 
 * Utiliza `useParams` para obtener los parámetros de la ruta actual, principalmente el ID del alumno, que se pasa
 * a `useWrapperLogic` para inicializar y gestionar el estado del alumno a editar.
 * 
 * @returns {JSX.Element} Un fragmento de React que encapsula el componente `EditAlumnos` y provee de un proveedor de Snackbar
 * para las notificaciones. Se pasa a `EditAlumnos` todo el estado y funciones necesarias para su operación,
 * incluyendo un callback `onSaveFinish` que permite recargar los datos del alumno desde el servidor tras una edición exitosa.
 */
export default function Wrapper() {
  const navigationParams = useParams()

  const { updateAlert, id_alumno, setIdAlumno, alumno, setAlumno, getAlumno } = useAlumnoEditWrapperLogic(new RequestsEdit(), navigationParams)

  return (
    <React.Fragment>
      <EditAlumnos key={alumno?.id ?? 0} alumno={alumno} id_alumno={id_alumno} setIdAlumno={setIdAlumno} setAlumno={setAlumno} updateAlert={updateAlert} onSaveFinish={() => getAlumno(true)} />

      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}

/**
 * Componente `EditAlumnos` gestiona la interfaz de usuario para la edición de datos de un alumno específico.
 * Incorpora múltiples funcionalidades como la edición de información personal, la gestión de responsables
 * en caso de alumnos menores de edad, y la edición de datos de domiciliación bancaria.
 * 
 * @param {Object} alumno - Datos del alumno actual a editar.
 * @param {string} id_alumno - ID del alumno actual, utilizado para realizar operaciones específicas de edición.
 * @param {Function} setIdAlumno - Función para actualizar el ID del alumno en el estado del componente padre.
 * @param {Function} setAlumno - Función para actualizar los datos del alumno en el estado del componente padre.
 * @param {Function} updateAlert - Función para mostrar alertas o notificaciones al usuario.
 * @param {Function} onSaveFinish - Callback que se invoca después de guardar los cambios satisfactoriamente, permitiendo recargar la información del alumno desde el servidor.
 * @returns {JSX.Element} Renderiza los formularios y elementos UI necesarios para la edición de un alumno.
 */
function EditAlumnos({ alumno, id_alumno, setIdAlumno, setAlumno, updateAlert, onSaveFinish }) {
  const headerRef = useRef()
  const navigate = useNavigate()
  const handleError = useErrorHandler()

  const [isResponsableChosen, setIsResponsableChosen] = useState(false)

  const [responsables, setResponsables] = useState(alumno && alumno.responsables ? alumno.responsables : null)

  const { updateChanges } = useContext(ChangesContext)

  const { register, handleSubmit, getValues, watch, formState: { errors, dirtyFields }, reset, setValue } = useForm({ resolver: yupResolver(validationSchema(responsables, updateAlert)) })

  const { esMenorDeEdad } = useCheckMinorAge(watch)

  const { editAlumno } = useEditAlumno(new RequestsCreate(), new RequestsEdit(), reset, updateChanges, onSaveFinish, headerRef, updateAlert, alumno, responsables, esMenorDeEdad, isResponsableChosen, id_alumno, handleError)

  useCheckDirtyFields(dirtyFields, updateChanges)

  useEffect(() => {
    setResponsables(alumno && alumno.responsables ? alumno.responsables : null)
  }, [alumno])

  const updateIdAlumno = (id) => {
    navigate(`../alumnos/${id}/editar`)
    setIdAlumno(id)
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(editAlumno)}>

        <Stack direction="row" justifyContent={"space-between"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/", label: "Inicio" },
            { to: "./../../", label: "Alumnos" },
            { to: "./../", label: "Alumno #" + (id_alumno ?? "0000") },
            { to: "./", label: "Editar alumno", actualItem: true },
          ]} />

          <MoveButtons
            actualItemId={id_alumno}
            beforeFetchCallback={() => setAlumno(null)}
            onUpdateActualItem={updateIdAlumno} />
        </Stack>

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <EditHeader
            ref={headerRef}
            title={"Editar alumno"}
            item={alumno} />

          <Line className={"mb-5"} />

          {alumno ? <DataFormAlumno alumno={alumno} register={register} errors={errors} setValue={setValue} /> : <Box px={5}><AlumnoDataSkeleton /></Box>}
        </Paper>

          {/* 11/04/2024 - Se modifica la lógica debido a que ahora los alumnos pueden tener tutor aunque sean mayor de edad */}
          <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
            <ResponsableData
              register={register}
              getValues={getValues}
              setValue={setValue}
              errors={errors}
              reset={reset}
              isResponsableChosen={isResponsableChosen}
              setIsResponsableChosen={setIsResponsableChosen}
              responsables={responsables}
              setResponsables={setResponsables}
              alumno={alumno}
              reload={() => onSaveFinish()} />
          </Paper>

        <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
          {alumno ? <DataDomiciliacionBancariaFormAlumno domiciliacion_bancaria={alumno?.domiciliacion_bancaria?.[0] ?? null} register={register} errors={errors} setValue={setValue} /> : <AlumnoDomiciliacionDataSkeleton />}
        </Paper>
      </form>
    </Container>
  )
}