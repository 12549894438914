import React, { Component } from 'react'
import { MenuItem } from 'components/navigation'
import { MapsHomeWork  as ClaseParticularIcon } from '@mui/icons-material'
import { employeeRoutes } from 'router/routes'

export default class ComunicacionesMenu extends Component {

    /**
     * ComponentDidMount
     * 
     * Inicializamos los componentes necesarios del tema
     */
    componentDidMount() {
        window.KTMenu?.createInstances()
        window.KTToggle?.createInstances()
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <div className="menu menu-column menu-rounded menu-sub-indention px-3" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">

                <div className="menu-item pt-5">
                    <div className="menu-content">
                        <span className="menu-heading fw-bold text-uppercase fs-7">Clases particulares</span>
                    </div>
                </div>

                <MenuItem title="Clases particulares" to={employeeRoutes.CLASES_PARTICULARES} icon={<ClaseParticularIcon />} exact/>
            </div>
        )
    }
}
