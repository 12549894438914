import { enqueueSnackbar } from "notistack"
import { useEffect, useState } from "react"
import { RequestAlumno } from 'objects/requests'
import { Text } from "components/texts"

// src\pages\logged\admin\alumnos\historico\single\index.jsx
export default function useHistoricoAlumno({ getValues }) {
    const [historico, setHistorico] = useState({})
    const [selectedAlumno, setSelectedAlumno] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const updateSelectedAlumno = (alumno) => setSelectedAlumno(alumno)

    const updateHistorico = (historico) => setHistorico(historico)

    useEffect(() => {
        if (selectedAlumno)
            doQuery()
    }, [selectedAlumno])

    const doQuery = () => {
        setIsLoading(true)
        setHistorico({})

        const params = {}
        if (getValues('curso_escolar_id'))
            params.curso_escolar_id = getValues('curso_escolar_id')

        new RequestAlumno().historico(selectedAlumno.id, params, onSuccess, onError)
    }

    const onSuccess = (res) => {
        setHistorico(res)
        setIsLoading(false)
    }

    const onError = (error) => {
        setIsLoading(false)
        enqueueSnackbar(<Text>{error}</Text>, { variant: "error" })
    }

    const handleCursoChange = () => {
        if (selectedAlumno !== null)
            doQuery()
        else
            enqueueSnackbar(<Text>Debe indicar el alumno</Text>, { variant: "error" })
    }

    return { historico, isLoading, updateSelectedAlumno, updateHistorico, handleCursoChange }
}