import React from 'react'
import Requests from './request/Request'
import { SnackbarProvider } from 'notistack'
import { useCreateActividadLogic } from 'hooks'
import { Container, Paper } from '@mui/material'
import { CreateHeader } from 'pages/logged/admin/components'
import { BreadcrumbsList, Line } from 'components/data_display'
import { DataFormActividadAcademia } from 'components/forms/display'

/**
 * El componente `CreateActividadesAcademia` es responsable de renderizar el formulario para crear una nueva actividad académica.
 * Utiliza el hook `useLogic` para la lógica del formulario y la interacción con la API a través del objeto `Requests`.
 * Presenta migas de pan para la navegación y utiliza 'SnackbarProvider' para mostrar notificaciones de feedback al usuario.
 * 
 * @returns {JSX.Element} - Un contenedor que incluye el formulario de creación de actividad y su lógica asociada.
 */
export default function CreateActividadesAcademia() {
  const { handleSubmit, createActividad, headerRef, register, errors } = useCreateActividadLogic(new Requests())

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(createActividad)}>
        <BreadcrumbsList breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../", label: "Academia" }, //--> Te dirige al apartado Actividades
          { to: "./../", label: "Actividades" },
          { to: "./", label: "Crear actividad", actualItem: true },
        ]} />

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <CreateHeader
            ref={headerRef}
            title={"Crear actividad"} />

          <Line />

          <DataFormActividadAcademia register={register} errors={errors} isMounted={true} />
        </Paper>

        <SnackbarProvider autoHideDuration={3000} />
      </form>
    </Container>
  )
}
