import { H3 } from 'components/texts'
import { Box, Paper } from '@mui/material'
import Container from '@mui/material/Container'
import React, { useContext, useEffect, useState } from 'react'
import { Search as SearchIcon } from '@mui/icons-material'
import { ListTableHeader } from 'pages/logged/admin/components'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { Accordion, BreadcrumbsList, Datagrid } from 'components/data_display'
import { basicColumns, columnsDatosPersonales, columnsDomicilio } from './columns.js'
import SearchFormAlumno, { search } from 'components/forms/display/alumno/SearchForm'

export default function ListadoAlumnos() {
  const { items, rowCount, params, update } = useContext(EmpleadoListContext)
  const [columns, setColumns] = useState(basicColumns.concat(columnsDatosPersonales))
  const [loadingTable, setLoadingTable] = useState(false)

  useEffect(() => {
    setLoadingTable(false)
  }, [items])

  /**
   * Callback para actualizar la tabla una vez hecha la petición
   * 
   * @param {Array} data - Datos de todos los alumnos que se reflejan en la tabla Datagrid
   * @param {Int}   rowCount 
   * @param {Int}   page 
   * @param {Int}   params - Parámetros de búsqueda procedentes de SearchFormAlumno
   */
  const updateTable = (newData, newRowCount, newPage, newParams) => update(newRowCount, newData, newParams, newPage)

  /**
   * Callback para cuando se cambia el search
   *
   * @param {Int} index 
   */
  const onChangeSearchIndex = (index) => setColumns(basicColumns.concat(index == 0 ? columnsDatosPersonales : columnsDomicilio))

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <BreadcrumbsList breadcrumbsList={[
        { to: "/", label: "Inicio" },
        { to: "./", label: "Alumnos", actualItem: true },
      ]} />

      <Accordion header={<Box display={"flex"} alignItems={"center"}><SearchIcon className={"mx-5"} /> <H3 className="my-0">Buscar alumnos</H3></Box>}>
        <SearchFormAlumno
          searchCallback={updateTable}
          onChangeSearchIndex={onChangeSearchIndex}
          onLoadingCallback={setLoadingTable} />
      </Accordion>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
        <ListTableHeader rowCount={rowCount} params={params} keyValue={"alumnos"} />

        <Datagrid
          rows={items}
          columns={columns}
          loading={loadingTable}
          rowCount={rowCount}
          onPageChange={(page) => { search(page, () => setLoadingTable(true), updateTable) }}
          serverSide />
      </Paper>
    </Container>
  )
}
