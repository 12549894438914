import store from 'store/store'
import { useForm } from "react-hook-form"
import Statics from "objects/common/Statics"
import { useNavigate } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import { useAlert, useCheckDirtyFields, useErrorHandler } from "hooks"
import { ChangesContext } from "contexts/ChangesContext"
import { useContext, useEffect, useRef, useState } from "react"
import { validationSchema } from 'components/forms/display/academia/inscripciones/DataForm/validation'
import { EmpleadoListContext } from 'contexts/employee/ListContext'

/**
 * Hook personalizado para gestionar la lógica de creación de inscripciones de academia.
 * Utiliza react-hook-form para el manejo de formularios y yup para la validación de esquemas.
 * Permite navegar entre diferentes vistas, gestionar el estado de carga y manejar las peticiones de la API.
 * 
 * @returns {Object}
 */
export default function useAcademiaInscripcionCreateLogic({ requests }) {
    /**
     * Se obtiene la ID del usuario logueado desde el estado global de la aplicación,
     * para poder enviar dicha ID en la petición de creación de la inscripción.
     * La ID del usuario logueado se utilizará como el valor del campo `operador_id`,
     * identificando quién ha realizado el alta de la inscripción.
     */
    const { userState } = store.getState()
    const operadorId = userState.user[0].id
    const operadorName = userState.user[0].nombre

    const updateAlert = useAlert()
    const handleError = useErrorHandler()

    const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, setValue, control, clearErrors } = useForm({ resolver: yupResolver(validationSchema) })

    const navigate = useNavigate()

    const headerRef = useRef()

    const [isMounted, setIsMounted] = useState(false)
    const [tab, setTab] = useState("1")
    const [actividad, setActividad] = useState(null)
    const [grupo, setGrupo] = useState(null)
    const [cursoEscolar, setCursoEscolar] = useState(null)
    const [alumnoSelected, setAlumnoSelected] = useState({})

    
    /**
     * Contexto para gestionar los cambios en el formulario y notificar al usuario
     * sobre cambios no guardados al intentar navegar fuera del formulario.
    */
   const { updateChanges } = useContext(ChangesContext)
   
   const { addItem } = useContext(EmpleadoListContext)

    useEffect(() => setIsMounted(true), [])

    /**
     * Hook personalizado para comprobar si existen campos 'sucios',
     * para controlar cuando mostrar el aviso de cambio de 'No se han guardado los cambios'
     * al intentar navear a otra parte de la aplicacion web
     * 
     * @param {Object} dirtyFields 
     * @param {Function} updateChanges 
     */
    useCheckDirtyFields(dirtyFields, updateChanges)

    /**
     * Función para actualizar el estado de la cabecera y mostrar una alerta.
     * Utiliza una referencia al componente de cabecera para cambiar su estado de carga
     * y llama al hook de alerta para mostrar un mensaje al usuario.
     * 
     * @param {Object} message Información del mensaje y variante (éxito, error, etc.)
     * @param {boolean} loading Indica si se debe mostrar el estado de carga.
     */
    const updateHeaderAndAlert = ({ message, variant = "error" }, loading = false) => {
        headerRef.current.button.setLoading(loading)
        updateAlert(message, variant)
    }

    /**
     * Función para crear una inscripción en la academia.
     * Realiza llamadas a la API para crear la inscripción, pagos asociados, y libros.
     * Gestiona el estado de carga y notifica al usuario el resultado de la operación.
     * 
     * @param {Object} values Valores del formulario de inscripción.
     */
    const createInscripcionAcademia = (values) => {

        headerRef.current.button.setLoading(true)

        requests.createInscripcion(getValues, operadorId, (res) => {
            const promisePagos = new Promise((resolve, reject) => {
                requests.createPagos(res.id, values.pagos, resolve, reject);
            })

            const promiseLibro = new Promise((resolve, reject) => {
                requests.crudLibro(res, getValues, resolve, reject)
            })

            const promiseLibroAsociado = new Promise((resolve, reject) => {
                requests.crudLibroAsociado(res, getValues, resolve, reject)
            })

            Promise.allSettled([promisePagos, promiseLibro, promiseLibroAsociado]).then(async (values) => {
                for (const fail of values.filter(item => item?.status == "rejected")) {
                    updateAlert(fail.reason.message)
                }

                headerRef.current.button.setLoading(false)
                updateChanges(false)
                updateAlert('Se ha creado la inscripción. Redirigiendo a la pagina de visualización...', 'success') //Modifiación del mensaje que muestra
                await Statics.delay(1500).then(() => { navigate('./../' + res.id ) }) //Modificación de la redirección.
            })

            addItem(res)
        }, (err) => {
            const message = Statics.errorArrayResponseToString(err)
            handleError( message )

            headerRef.current.button.setLoading(false)
        })
    }

    /**
     * Devuelve el alumno
     * 
     * @param {Int} id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    const getAlumnoFromServer = (id, callbackOk = () => { }, callbackError = () => { }) => {
        const params = { relations: 'datos_colegio' }

        requests.getAlumno(id, params, callbackOk, callbackError)
    }

    return { alumnoSelected, setAlumnoSelected, getAlumnoFromServer, navigate, operadorName, updateAlert, grupo, actividad, setGrupo, setActividad, isMounted, clearErrors, cursoEscolar, setCursoEscolar, getValues, headerRef, tab, setTab, register, handleSubmit, errors, setValue, control, createInscripcionAcademia }
}