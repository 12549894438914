import { Grid } from '@mui/material'
import { Text } from 'components/texts'
import React, { Component } from 'react'
import { Input, Checkbox } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { CursoEscolarSelect} from 'components/forms/components/Select/custom'
import { Select } from 'components/forms/components'

/**
 * Esta es la plantilla para cuando se busque por los datos basicos del alumno
 */
export default class IncidenciaTemplate extends Component {
    
    /**
     * Constructor
     * 
     * @param {Object} props 
     */
    constructor(props) {
        super(props)
    }

    /**
     * Render
     *
     * @returns {Component}
     */
    render() {
        const { register, errors, setValue } = this.props
        return (
            <Grid container>
                <Grid container mt={2} mb={1}>
                    <Text fontWeight={'bold'}>Según datos de:</Text><br />
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Input label="Nombre" width={"100%"} name={"nombre"} register={register} errors={errors} uppercase/>
                    </Grid>

                    <Grid item xs={4}>
                        <Input label="Primer apellido" width={"100%"} name={"apellido1"} register={register} errors={errors} uppercase/>
                    </Grid>

                    <Grid item xs={4}>
                        <Input label="Segundo apellido" width={"100%"} name={"apellido2"} register={register} errors={errors} uppercase/>
                    </Grid>

                    <Grid item xs={4}>
                        <CursoEscolarSelect
                         label={"Año"}
                         name={"id_curso_escolar"}
                         wantToReset={this.props.reset}
                         register={register} 
                         errors={errors} 
                         defaultValue={null}
                         setValue={setValue} 
                         searcheable
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <ItemsSelect 
                            name         = {"id_tipo_incidencia"}
                            keyValue     = {"tipo_incidencia"}
                            register     = {register} 
                            errors       = {errors}
                            wantToReset  = {this.props.reset} />
                    </Grid>

                    <Grid item xs={4}>
                        <Select 
                            name={"solucionada"} 
                            label={"Solucionada"} 
                            register={register} 
                            wantToReset  = {this.props.reset}   
                            defaultValue={"2"}  
                            items={[
                                {value: "1", label: "Sí"},
                                {value: "0", label: "No"},
                                {value: "2", label: "Todas"}
                            ]}
                        />
                    </Grid>
                </Grid>

            </Grid>
        )
    }
}
