import RequestMaster from '../RequestMaster'

export default class RequestGrupoColegio extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/grupo-colegio'
    }


    /**
     * Hace la busqueda de los colegios
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    search(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.get(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Crea un grupo
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    create(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.post(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Devuelve la vista preliminar del grupo
     * 
     * @param {Int}      id 
     * @param {Function} callback 
     * @param {Function} callbackError 
     * @param {Function} callbackFinally 
     */
    preliminar(id, callbackError = () => { }) {
        const path = id + "/preliminar"
        this.pdf(path, {}, callbackError)
    }

    /**
     * Devuelve la vista del listado de asistencia del grupo
     * 
     * @param {Object}   params 
     * @param {Function} callback 
     * @param {Function} callbackError 
     * @param {Function} callbackFinally 
     */
    informe(params, callbackError = () => { }) {
        const path = "listado/preliminar?mes=" + params.month + "&grupo=" + params.grupoId
        this.pdf(path, {}, callbackError)
    }

    /**
     * Edita un grupo
     *
     * @param {Int}      id
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    edit(id, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "" + id
        this.put(path, params, callback, callbackError, callbackFinally)
    }
}
