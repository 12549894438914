import Statics from 'objects/common/Statics'
import { RequestInscripcionAcademia, RequestVentaLibro, RequestPagosClasesParticulares, RequestAlumno } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Hace la petición para crear la inscripción
     * 
     * @param {Object}   getValues 
     * @param {Number}   operadorId ID del empleado que ha dado de alta la inscripcion de la academia
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createInscripcion(getValues, operadorId, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData
        //-- GENERAL --//
        //TODO: la creacion del formData es igual en el create tambien. Habria que ver alguna forma de optimizarlo para evitar repeticion de codigo.
        formData.append('operador_id', operadorId)
        formData.append('alumno_id', getValues('alumno_id'))
        formData.append('curso_escolar_id', getValues('curso_escolar_id'))

        formData.append('forma_recogida_id', getValues('forma_recogida_id'))
        formData.append('forma_conocimiento_id', getValues('forma_conocimiento_id'))
        formData.append('empresa_id', getValues('empresa_id'))
        formData.append('registro_actividad_id', getValues('registro_actividad_id'))
        formData.append('preferencia_horario_id', getValues('preferencia_horario_id'))
        formData.append('comentarios_horario', getValues('comentarios_horario').toUpperCase())
        formData.append('alumno_colectivo', getValues('alumno_colectivo') ? 1 : 0)
        formData.append('avisado_inicio_clases', getValues('avisado_inicio_clases') ? 1 : 0)
        formData.append('nivel', getValues('nivel').toUpperCase())
        formData.append('hermanos', getValues('hermanos') ? 1 : 0)
        formData.append('aa', getValues('aa') ? 1 : 0)
        formData.append('num_reserva', getValues('num_reserva'))
        formData.append('motivo_baja_id', getValues('motivo_baja_id'))
        formData.append("fecha_inscripcion", Statics.datePickerTimeFormat(getValues("fecha_inscripcion")))
        formData.append("fecha_incorporacion", Statics.datePickerTimeFormat(getValues("fecha_incorporacion")))
        formData.append("fecha_baja", Statics.datePickerTimeFormat(getValues("fecha_baja")))

        //DATA FORM GRUPO
        formData.append('grupo_academia_id', getValues('grupo_academia_id'))
        formData.append('comentario_profesor_ap', getValues('comentario_profesor_ap').toUpperCase())
        formData.append('comentarios_generales', getValues('comentarios_generales').toUpperCase())

        //DATA FORM PAGOS
        formData.append('forma_pago_id', getValues('forma_pago_id'))
        formData.append('tipo_matricula_id', getValues('tipo_matricula_id'))
        formData.append('matricula', getValues('matricula'))
        formData.append("fecha_pago_matricula", Statics.datePickerTimeFormat(getValues("fecha_pago_matricula")))
        formData.append('tipo_pago_id', getValues('tipo_pago_id'))
        formData.append('cantidad_pago', getValues('cantidad_pago'))
        formData.append('contrato_emitido', getValues('contrato_emitido') ? 1 : 0)
        formData.append('contrato_recibido', getValues('contrato_recibido') ? 1 : 0)
        formData.append('observaciones', getValues('observaciones').toUpperCase())
        formData.append('empresa_colectivo', getValues('empresa_colectivo') ? 1 : 0)

        new RequestInscripcionAcademia().create(formData, callbackOk, callbackError)
    }

    /**
     * crudLibro
     */
    crudLibro(inscripcion, getValues, resolve, reject) {
        const libroSolicitado = getValues("libro_solicitado")

        libroSolicitado ? this.createVentaLibro(inscripcion.id, getValues, resolve, reject) : resolve()
    }

    /**
     * Comprueba que petición tiene que hacer (para los libros asociados)
     */
    crudLibroAsociado(inscripcion, getValues, resolve, reject) {
        const libroAsociadoSolicitado = getValues("libro_asociado_solicitado")

        libroAsociadoSolicitado ? this.createVentaLibroAsociado(inscripcion.id, getValues, resolve, reject) : resolve()
    }

    /**
     * Método para crear un registro de una venta de un libro
     * 
     * @param {Int}      inscripcion_id 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createVentaLibro(inscripcion_id, getValues, callbackOk = () => { }, callbackError = () => { }) {
        let formData = this.ventaLibroFormdata(getValues)
        formData.append('id', inscripcion_id)

        new RequestVentaLibro().create(formData, callbackOk, callbackError)
    }

    /**
     * Método para crear un registro de una venta de un libro asociado
     * 
     * @param {Int}      inscripcion_id
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createVentaLibroAsociado(inscripcion_id, getValues, callbackOk = () => { }, callbackError = () => { }) {
        let formData = this.ventaLibroFormdata(getValues, true)
        formData.append('inscripcion_id', inscripcion_id)

        new RequestVentaLibro().create(formData, callbackOk, callbackError)
    }

    /**
     * Crea y devuelve el FormData para las ventaLibro
     * 
     * @param {Function} getValues 
     */
    ventaLibroFormdata(getValues, asociado = false) {
        const sufix = asociado ? "_asociado" : ""

        let formData = new FormData
        formData.append('curso_escolar_id', getValues('curso_escolar_id'))
        formData.append('libro_id', getValues(`libro${sufix}_id`) ?? "")
        formData.append('precio', getValues(`precio${sufix}`))
        formData.append('inscripcion_type', 'inscripcion-academia')
        if (asociado)
            formData.append('asociado', 1)

        const fecha_peticion = getValues(`fecha${sufix}_peticion`)
        formData.append("fecha_peticion", Statics.datePickerTimeFormat(fecha_peticion))

        const fecha_pago = getValues(`fecha_pago${sufix}`)
        formData.append("fecha_pago", Statics.datePickerTimeFormat(fecha_pago))

        const fecha_entrega = getValues(`fecha_entrega${sufix}`)
        formData.append("fecha_entrega", Statics.datePickerTimeFormat(fecha_entrega))

        return formData
    }


    /**
     * Método para crear un registro de una venta de un libro
     * 
     * @param {Int}      id_inscripcion 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createVentaLibro(id_inscripcion, getValues, callbackOk = () => { }, callbackError = () => { }) {
        let formData = this.ventaLibroFormdata(getValues)
        formData.append('inscripcion_id', id_inscripcion)

        new RequestVentaLibro().create(formData, callbackOk, callbackError)
    }

    /**
     * Método para crear un registro de una venta de un libro asociado
     * 
     * @param {Int}      id_inscripcion 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createVentaLibroAsociado(id_ventaLibro, getValues, callbackOk = () => { }, callbackError = () => { }) {
        let formData = this.ventaLibroFormdata(getValues, true)
        formData.append('inscripcion_id', id_ventaLibro)

        new RequestVentaLibro().create(formData, callbackOk, callbackError)
    }

    /**
  * Método para crear los pagos de una clase particular
  * 
  * @param {Int}      id_inscripcion 
  * @param {Object}   pagos 
  * @param {Function} callbackOk 
  * @param {Function} callbackError 
  */
    createPagos(id_inscripcion, pagos, callbackOk = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const formData = new FormData
        formData.append('inscripcion_type', 'inscripcion-academia')
        formData.append('inscripcion_id', id_inscripcion)

        for (const index in pagos) {
            formData.append(`pagos[${index}][mes]`, pagos[index].mes)
            formData.append(`pagos[${index}][fecha]`, pagos[index].fecha ?? "")
            formData.append(`pagos[${index}][cantidad]`, pagos[index].cantidad ?? "")
            formData.append(`pagos[${index}][pagado]`, pagos[index].pagado ?? "")
            formData.append(`pagos[${index}][otros_cargos]`, pagos[index].otros_cargos ?? "")
            formData.append(`pagos[${index}][total]`, pagos[index].total ?? 0)
        }

        new RequestPagosClasesParticulares().createMultiple(formData, callbackOk, callbackError, callbackFinally)
    }

    /**
     * Devuelve el alumno
     * 
     * @param {Int} id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getAlumno(id, params, callbackOk = () => { }, callbackError = () => { }) {

        new RequestAlumno().get(id, params, (res) => {
            res.alumno ? callbackOk(res.alumno) : callbackError(res)
        })
    }
}
