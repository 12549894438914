import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { responses } from 'constants/Responses'

/**
 * Hook `useLogic` para gestionar la lógica de navegación y el estado de una incidencia específica de un alumno.
 * Carga los detalles de la incidencia desde el servidor y actualiza el estado local del componente.
 * 
 * @param {Object} navigationParams - Parámetros de navegación que incluyen el ID de la incidencia.
 * @returns {Object} Contiene el ID de la incidencia, los detalles de la incidencia, y funciones para gestionar el estado.
 */
export default function useLogic(requests, navigationParams) {
    const navigate = useNavigate()

    const [id_incidencia, setIdIncidencia] = useState(navigationParams.id_incidencia)
    const [incidencia, setIncidencia] = useState(null)

    /**
     * Función para cargar los detalles de la incidencia específica desde el servidor.
     * Utiliza la constante `responses` para manejar respuestas estandarizadas.
     */
    const getIncidencia = () => {
        const params = { relations: 'alumno' }

        requests.get(id_incidencia, params, (res) => {
            if (res[responses.incidencias.singleName])
                setIncidencia(res[responses.incidencias.singleName])
        })
    }

    /**
     * Función para actualizar el ID de la incidencia en la URL y el estado local, facilitando la navegación.
     * 
     * @param {number} id - Nuevo ID de la incidencia para cargar.
     */
    const updateId = (id) => {
        navigate(`../alumnos/incidencias/${id}`)
        setIdIncidencia(id);
    }

    useEffect(getIncidencia, [id_incidencia])

    return { id_incidencia, incidencia, setIncidencia, updateId }
}