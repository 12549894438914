import { v4 as uuid } from 'uuid'
import { TabContext } from '@mui/lab'
import { COLOR } from 'theme/Colors.js'
import { Text } from 'components/texts'
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import Requests from './Requests/Requests.js'
import { EditHeader } from 'pages/logged/admin/components'
import { EmpleadoListContext } from 'contexts/employee/ListContext.jsx'
import { Box, Container, Paper, Tab, Tabs, Stack } from '@mui/material'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
import { useAcademiaInscripcionEditLogic, useAcademiasInscripcionEditWrapperLogic } from 'hooks'
import SkeletonGroupData from 'components/forms/display/academia/inscripciones/DataForm/GroupData/SkeletonGroupData'
import SkeletonDataFormGeneral from 'components/forms/display/academia/inscripciones/DataForm/GeneralDataForm/skeleton'
import SkeletonDataFormGeneralPagos from 'components/forms/display/academia/inscripciones/DataForm/PagoDataForm/skeleton'
import SkeletonDataFormLibros from 'components/forms/display/academia/inscripciones/DataForm/LibroDataForm/SkeletonDataFormLibros'
import { DataFormInscripcionAcademias, DataFormGrupoAcademias, PagoInscripcionAcademiaDataForm, LibroInscripcionAcademiaDataForm } from 'components/forms/display'
import Headers from 'components/headers/index'
import Skeleton from './Skeleton.jsx'

/**
 * Wrapper es un componente que sirve como contenedor para la edición de inscripciones a la academia.
 * Utiliza el hook `useAcademiasInscripcionEditWrapperLogic` para gestionar la lógica específica asociada
 * con la edición de una inscripción, incluyendo la obtención de los datos necesarios y la manipulación
 * del estado de la inscripción. Este componente es responsable de renderizar el formulario de edición
 * y proveer el contexto necesario para las operaciones de edición.
 *
 * @returns {React.Fragment} Fragmento que contiene el componente EditInscripcionesAcademia y un proveedor de notificaciones Snackbar.
 */
export default function Wrapper() {
  const navigationParams = useParams()

  const { items, updateItem } = useContext(EmpleadoListContext)

  const { datosColegio, inscripcion, id_inscripcion, alumno,
    setIdInscripcion, setInscripcion, getInscripcion, setAlumno } = useAcademiasInscripcionEditWrapperLogic(navigationParams, items, updateItem)

  return (
    <React.Fragment>
      <EditInscripcionesAcademia key={uuid()} inscripcion={inscripcion} datosColegio={datosColegio} id_inscripcion={id_inscripcion} alumno={alumno}
        setIdInscripcion={setIdInscripcion} setInscripcion={setInscripcion} setAlumno={setAlumno} onSaveFinish={() => getInscripcion(true)} /> 

      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}

/**
 * EditInscripcionesAcademia es el componente encargado de renderizar el formulario de edición
 * para una inscripción específica en la academia. Ofrece un conjunto de pestañas (Tabs) para
 * organizar la información en secciones diferenciadas, facilitando la edición de distintos aspectos
 * de la inscripción como datos generales, grupo, pagos y libros.
 *
 * @param {Object} props - Propiedades pasadas al componente para controlar su comportamiento.
 * @param {Object} props.datosColegio - Datos del Colegio asociados al alumno de la inscripción que se va a editar.
 * @param {Object} props.inscripcion - Datos actuales de la inscripción que se va a editar.
 * @param {number} props.id_inscripcion - Identificador de la inscripción que se está editando.
 * @param {Function} props.setInscripcion - Función para actualizar el estado de la inscripción.
 * @param {Function} props.setIdInscripcion - Función para actualizar el identificador de la inscripción.
 * @param {Function} props.onSaveFinish - Callback que se ejecuta después de una operación de guardado exitosa.
 * 
 * @returns {JSX.Element} Un contenedor con el formulario de edición de inscripciones y sus diferentes secciones.
 */
function EditInscripcionesAcademia({ datosColegio, inscripcion, id_inscripcion, alumno, setInscripcion, setIdInscripcion, setAlumno, onSaveFinish }) {
  const { navigate, operador, grupo, setGrupo, headerRef, control, tab, setTab, cursoEscolar,
    setCursoEscolar, actividad, setActividad, getValues, handleSubmitCustom, updateId, setValue,
    register, errors, isMounted, incidenciasNoResueltas } = useAcademiaInscripcionEditLogic({ setIdInscripcion, inscripcion, id_inscripcion, onSaveFinish, requests: new Requests() })

  return (
    inscripcion ? (<Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmitCustom}>
        <Stack direction="row" justifyContent={"space-between"} alignItems="center" marginX={8}>

          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/", label: "Inicio" },
            { to: "./../../", label: "Academia" }, //--> Te dirige al apartado Inscripciones
            { to: "./../../", label: "Inscripciones" },
            { to: "./../", label: "Inscripción #" + (id_inscripcion ?? "0000") },
            { to: "./", label: "Editar", actualItem: true },
          ]} />

          <MoveButtons
            actualItemId={id_inscripcion}
            beforeFetchCallback={() => setInscripcion(null)}
            onUpdateActualItem={updateId} />
        </Stack>

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <Headers
            headerRef={headerRef}
            template={'edit'}
            title={"Editar Inscripción"}
            actividad={inscripcion?.alumno?.apellido2 + ' ' + inscripcion?.alumno?.apellido1 + ',' + inscripcion?.alumno?.nombre}
          />
          <Line />

          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2} mt={2}>
              <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>DATOS DE LA INSCRIPCIÓN</Text>} value={"1"} />
                <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>DATOS DEL GRUPO</Text>} value={"2"} />
                <Tab label={<Text fontWeight={tab === "3" ? "bold" : null} color={tab === "3" ? COLOR.standard.main : null}>DATOS DE LOS PAGOS</Text>} value={"3"} />
                <Tab label={<Text fontWeight={tab === "4" ? "bold" : null} color={tab === "4" ? COLOR.standard.main : null}>LIBROS</Text>} value={"4"} />

              </Tabs>
            </Box>

            <TabPanel value={"1"}>
              {inscripcion ?
                <DataFormInscripcionAcademias
                  datosColegioUrlType={'edit'} //sirve para saber a qué url redirigir al usuario al hacer click en el botón de Datos Colegio
                  datosColegio={datosColegio}
                  navigate={navigate}
                  operador={operador}
                  inscripcion={inscripcion}
                  incidenciasNoResueltas={incidenciasNoResueltas}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  isMounted={isMounted}
                  control={control}
                  onChangeCursoEscolar={setCursoEscolar}
                  onChangeActividad={setActividad}
                  onSaveFinish={onSaveFinish}
                /> : <SkeletonDataFormGeneral />}
            </TabPanel>

            <TabPanel value={"2"}>
              {inscripcion ?
                <DataFormGrupoAcademias
                  inscripcion={inscripcion}
                  onGrupoChange={setGrupo}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  isMounted={isMounted}
                  control={control}
                  actividad={actividad}
                  cursoEscolar={cursoEscolar}
                /> : <SkeletonGroupData />}
            </TabPanel>

            <TabPanel value={"3"}>
              {inscripcion ?
                <PagoInscripcionAcademiaDataForm
                  inscripcion={inscripcion}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  errors={errors}
                  isMounted={isMounted}
                  control={control}
                  actividad={actividad}
                  alumno={alumno}
                /> : <SkeletonDataFormGeneralPagos />}
            </TabPanel>

            <TabPanel value={"4"}>
              {inscripcion ?
                <LibroInscripcionAcademiaDataForm
                  inscripcion={inscripcion}
                  register={register}
                  setValue={setValue}
                  grupo={grupo}
                  errors={errors}
                  isMounted={isMounted}
                  control={control} /> : <SkeletonDataFormLibros />}
            </TabPanel>
          </TabContext>
        </Paper>

        <SnackbarProvider autoHideDuration={3000} />
      </form>
    </Container>) :
      (<Skeleton />)
  )
}