import { Grid } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'

export default function SkeletonDataFormSeguimientoTrimestralGrupoColegio() {
    return (
        <Grid container p={5}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Skeleton variant="rounded" width={"30%"} height={30} />
                </Grid>
                
                <Grid item xs={12}>
                    <Skeleton variant="rounded" width={"40%"} height={40} />
                </Grid>

                <Grid item xs={4}>
                    <Skeleton variant="rounded" width={"100%"} height={100} />
                </Grid>
                <Grid item xs={4}>
                    <Skeleton variant="rounded" width={"100%"} height={100} />
                </Grid>
                <Grid item xs={4}>
                    <Skeleton variant="rounded" width={"100%"} height={100} />
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                    <Skeleton variant="rounded" width={"30%"} height={30} />
                </Grid>
                
                <Grid item xs={12}>
                    <Skeleton variant="rounded" width={"40%"} height={40} />
                </Grid>

                <Grid item xs={4}>
                    <Skeleton variant="rounded" width={"100%"} height={100} />
                </Grid>
                <Grid item xs={4}>
                    <Skeleton variant="rounded" width={"100%"} height={100} />
                </Grid>
                <Grid item xs={4}>
                    <Skeleton variant="rounded" width={"100%"} height={100} />
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                    <Skeleton variant="rounded" width={"30%"} height={30} />
                </Grid>
                
                <Grid item xs={12}>
                    <Skeleton variant="rounded" width={"40%"} height={40} />
                </Grid>

                <Grid item xs={4}>
                    <Skeleton variant="rounded" width={"100%"} height={100} />
                </Grid>
                <Grid item xs={4}>
                    <Skeleton variant="rounded" width={"100%"} height={100} />
                </Grid>
                <Grid item xs={4}>
                    <Skeleton variant="rounded" width={"100%"} height={100} />
                </Grid>
            </Grid>
        </Grid>
)
}
