import { Text } from 'components/texts'
// import { ASSETS } from 'constants/Assets'
import { Link } from 'components/data_display'
import { Avatar, Box, Grid, Paper, Tooltip } from '@mui/material'
import { Visibility as SeeIcon, OpenInNewOff as RemoveAsociationIcon, SyncAlt as EditAsociationIcon } from '@mui/icons-material'
import { useImageLoader } from 'hooks'
import { DEFAULT_IMAGES_URL } from 'constants/DefaultImagesUR'
import { COLOR } from 'theme/Colors'

/**
 * Componente `AlumnoItem` para mostrar la información básica de un alumno en un formato de tarjeta.
 * Incluye funcionalidades opcionales para ver detalles del alumno y borrar la relación con el mismo.
 * 
 * @param {Object} props - Propiedades pasadas al componente.
 * @param {boolean} hideSeeIcon - Controla la visibilidad del icono para ver detalles del alumno.
 * @param {boolean} hideDeleteIcon - Controla la visibilidad del icono para borrar la relación del alumno.
 * @param {Object} alumno - Objeto que contiene la información del alumno a mostrar.
 * @param {Function} openDeleteRelacionModal - Función para abrir el modal de confirmación al borrar la relación del alumno.
 * @returns {JSX.Element} Un elemento Grid que contiene la tarjeta del alumno con su información y acciones disponibles.
 */
export default function AlumnoItem({ hideSeeIcon, hideDeleteIcon, hideEditRelationIcon, alumno, openEditRelacionModal, openDeleteRelacionModal }) {
    const { imageSrc } = useImageLoader(alumno, 'foto_logo', DEFAULT_IMAGES_URL.alumno, true)

    return (
        <Grid item xs={6} xl={4}>
            <Paper elevation={0} sx={{ px: 3, pt: 2, pb: 3, borderRadius: 2, border: "1px solid var(--greyLight)" }}>
                <Box justifyContent={"end"} alignItems={"center"} display={"flex"}>
                    {hideSeeIcon ? null :
                        (<Link to={"/empleado/alumnos/" + alumno.id}>
                            <Tooltip title={<Text color={"black"}>Ver alumno</Text>}>
                                <SeeIcon color={"standard"} className={"mx-2"} />
                            </Tooltip>
                        </Link>)
                    }

                    {/* Definición de nuevo tooltip para acción de editar la relación de tutor-alumno */}
                    {hideEditRelationIcon ? null :
                        (<Tooltip title={<Text color={"black"}>Editar relación</Text>}>
                            <div onClick={() => openEditRelacionModal(alumno)}>
                                <EditAsociationIcon sx={{ color: COLOR.grey.main}} className={"clickable mx-2"} />
                            </div>
                        </Tooltip>)
                    }

                    {hideDeleteIcon ? null :
                        (<Tooltip title={<Text color={"black"}>Borrar relación</Text>}>
                            <div onClick={() => openDeleteRelacionModal(alumno)}>
                                <RemoveAsociationIcon color={"error"} className={"clickable mx-2"} />
                            </div>
                        </Tooltip>)
                    }
                </Box>

                <Box alignItems={"center"} display={"flex"}>
                    {/* <Avatar alt={alumno.nombre ?? ""} src={`${ASSETS.images + (alumno.foto_logo ?? 'private/alumno/perfiles/0/default.png')}`} sx={{ width: 75, height: 75 }} /> */}
                    <Avatar alt={alumno.nombre ?? ""} src={imageSrc} sx={{ width: 75, height: 75 }} />

                    <Box mx={3} display={"flex"} flexDirection={"column"}>
                        <Text>{(alumno.nombre ?? "") + " " + (alumno.apellido1 ?? "") + " " + (alumno.apellido2 ?? "")}</Text>
                        <Text>{(alumno.numero_documento ?? "") + " - " + (alumno.tipo_documento ? alumno.tipo_documento.nombre : "")}</Text>
                        {alumno.tipo_relacion ? <Text>{"Relación con el alumno: " + (alumno.tipo_relacion ?? "")}</Text> : null}
                    </Box>
                </Box>
            </Paper>
        </Grid>
    )
}