import React, { useState } from 'react'
import { Box, Container, Paper, Tab, Tabs, Grid } from '@mui/material'
import { BreadcrumbsList } from 'components/data_display'
import { COLOR } from 'theme/Colors.js'
import { Text } from 'components/texts/index.js'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { TabContext } from '@mui/lab'
import { InscripcionesAcademiaTemplate, InscripcionesColegioTemplate, ClasesParticularesTemplate, CursosInmersionTemplate, ExamenesOficialesTemplate } from './parts'
import { AlumnoPicker } from 'components/forms/components/custom'
import { useForm } from 'react-hook-form'
import { CursoEscolarSelect, ItemsSelect } from 'components/forms/components/Select/custom'
import { SnackbarProvider } from 'notistack'
import { useHistoricoAlumno } from 'hooks'

/**
 * Componente `SingleHistorico` para mostrar el historial académico de alumnos.
 * Facilita la navegación entre diferentes categorías de historial como inscripciones
 * en la academia, colegio, clases particulares, etc., y permite la selección dinámica
 * de alumnos para visualizar su historial específico.
 * 
 * Utiliza useForm para manejar interacciones de formulario y estados locales para
 * controlar la visualización de categorías específicas del historial. Se integra con
 * hooks personalizados para la gestión de datos y contexto de snackbar para notificaciones.
 */
export default function SingleHistorico() {
    const [tab, setTab] = useState("1")

    const { register, formState: { errors }, getValues, setValue, clearErrors } = useForm()

    const { historico, isLoading, updateSelectedAlumno, updateHistorico, handleCursoChange } = useHistoricoAlumno({ getValues })

    return (
        <Container maxWidth={false} sx={{ mt: 5 }}>
            <BreadcrumbsList mx={8} breadcrumbsList={[
                { to: "/", label: "Inicio" },
                { to: "./../", label: "Alumnos" },
                { to: "./", label: "Histórico", actualItem: true },
            ]} />
            <Paper elevation={2} sx={{ m: 2, mx: 8, my: 2, pb: 5, pt: 2 }}>
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
                        <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                            <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>INSCRIPCIONES EN LA ACADEMIA</Text>} value={"1"} />
                            <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>INSCRIPCIONES EN COLEGIO</Text>} value={"2"} />
                            {/* <Tab label={<Text fontWeight={tab === "3" ? "bold" : null} color={tab === "3" ? COLOR.standard.main : null}>EXÁMENES OFICIALES</Text>} value={"3"} /> */}
                            <Tab label={<Text fontWeight={tab === "4" ? "bold" : null} color={tab === "4" ? COLOR.standard.main : null}>CLASES PARTICULARES</Text>} value={"4"} />
                            {/* <Tab label={<Text fontWeight={tab === "5" ? "bold" : null} color={tab === "5" ? COLOR.standard.main : null}>CURSOS DE INMERSIÓN</Text>} value={"5"} /> */}
                        </Tabs>
                    </Box>

                    <Grid container direction="row" justifyContent="center" alignItems="center" px={3} py={2} mb={2} mt={4}>
                        <Grid item>
                            <AlumnoPicker
                                editLabel={"Cambiar alumno"}
                                addLabel={"Seleccionar alumno"}
                                // Se ha comentado el label de delete puesto que se ha suprimido el botón de delete
                                // deleteLabel={"Borrar alumno"}
                                relations={"academia"}
                                register={register}
                                errors={errors}
                                onAlumnoSelected={updateSelectedAlumno}
                                setHistorico={updateHistorico}
                                setValue={setValue}
                                name={'alumno_id'}
                                clearErrors={clearErrors}
                                canEdit />
                        </Grid>

                        {/* 20/03/2024 Se ha comentado el selector de curso por petición de Samuel */}
                        {/* <Grid item xs={4} height={180}>
                            <CursoEscolarSelect label={"Año"} name={`curso_escolar_id`} register={register} errors={errors} onChange={handleCursoChange} defaultValue={null} setValue={setValue} allOption searcheable />
                        </Grid> */}
                    </Grid>

                    <TabPanel value={"1"}>
                        <InscripcionesAcademiaTemplate isLoading={isLoading} historico={historico['academia']} />
                    </TabPanel>

                    <TabPanel value={"2"}>
                        <InscripcionesColegioTemplate historico={historico['colegio']} isLoading={isLoading} />
                    </TabPanel>

                    {/* <TabPanel value={"3"}>
                        <ExamenesOficialesTemplate />
                    </TabPanel> */}

                    <TabPanel value={"4"}>
                        <ClasesParticularesTemplate historico={historico['clase-particular']} isLoading={isLoading} />
                    </TabPanel>

                    {/* <TabPanel value={"5"}>
                        <CursosInmersionTemplate />
                    </TabPanel> */}
                </TabContext>
            </Paper>

            <SnackbarProvider autoHideDuration={5000} />
        </Container>
    )
}
