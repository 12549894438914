/**
 * Valida los campos requeridos del control de almacen en los arrays de librosPedidos y librosDevueltos.
 * 
 * @param {Function} updateAlert - Función para mostrar snackbar.
 * @param {Object}   getValues - Objeto con los valores obtenidos de getValues.
 * 
 * @returns {boolean} - Retorna true si todos los campos son válidos, de lo contrario false.
 */
export const validateLibrosFields = (updateAlert, getValues) => {
  // Valida los campos de librosPedidos
  const librosPedidos = getValues()?.librosPedidos

  if (librosPedidos)
    for (const [index, pedido] of librosPedidos?.entries()) {
      // Si el libro está marcado como eliminado, ignora este objeto y continúa con el siguiente
      if (pedido.deleted)
        continue

      // Valida los campos si el libro no está marcado como eliminado
      const isPedidoValid = pedido.fecha != null && pedido.cantidad_pedida != null && pedido.cantidad_llegada != null

      if (!isPedidoValid) {
        updateAlert('Error: Todos los campos de libros pedidos son requeridos y no pueden estar vacíos.', 'error')
        return false;
      }
    }

  // Valida los campos de librosDevueltos
  const librosDevueltos = getValues()?.librosDevueltos;

  if (librosDevueltos)
    for (const [index, devuelto] of librosDevueltos?.entries()) {
      console.log(`Validando devuelto ${index}`, devuelto)

      // Si el libro está marcado como eliminado, ignora este objeto y continúa con el siguiente
      if (devuelto.deleted)
        continue

      // Valida los campos si el libro no está marcado como eliminado
      const isDevueltoValid = devuelto.cantidad_devuelta != null && devuelto.fecha != null;

      if (!isDevueltoValid) {
        updateAlert('Error: Todos los campos de libros devueltos son requeridos y no pueden estar vacíos.', 'error');
        return false;
      }
    }

  // Todos los campos son válidos
  return true;
}