import { Grid, Skeleton } from "@mui/material";

export default function DataFormHorariosClasesParticularesSkeleton() {
    return (
        <Grid container p={5}>
            <Grid container justifyContent="space-around">
                <Grid item xs={5}>
                    <Skeleton variant="rounded" width={"100%"} height={50} />
                </Grid>

                <Grid item xs={5}>
                    <Skeleton variant="rounded" width={"100%"} height={50} />
                </Grid>
            </Grid>

            <Grid container mt={5}>
                <Skeleton variant="rounded" width={"100%"} height={500} />
            </Grid>
        </Grid>
    )
}