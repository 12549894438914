import { Horario } from 'components/data_display'
import { useAlert, useErrorHandler } from 'hooks'
import { useState, useEffect } from "react"
import Requests from './Request/request'
import { H2, H3 } from "components/texts"
import { Grid } from '@mui/material'
import {Text} from "components/texts"

/**
 * Componente que se usa para la visualización del horario en el single de un grupo, recibe dos parametros el horario de este y el grupo
 * para poder obtener las horas totales que tiene asignadas.
 * @param {*} param0 
 * @returns 
 */
export default function HorarioData({ grupo, horario, onSaveFinish = () => {} }) {

    const [horarios, setHorarios] = useState({ all: grupo?.horarios ?? [], current: null })
    const [aula, setAula] = useState(null)
    const [horasTotales, setHorasTotales] = useState(grupo?.horarios ? grupo.horarios.reduce((suma, objeto) => suma + objeto.horas_semanales, 0) : 0)

    const updateAlert = useAlert();
    const handleError = useErrorHandler()
    const request = new Requests()

    useEffect(() => {
        // Si aula esta null, no muestra el mensaje, para mejorar experiencia de usuario y evitar que salga el mensaje inmediatamente cuando renderice el componente
        if (!aula)
            return

        const horarioToShow = horarios.all.find(horario => horario.aula_id === aula)
        if (!horarioToShow) {
            // enqueueSnackbar(<Text>No se han encontrado horarios para esta aula.</Text>, { variant: "error" })
            updateAlert('No se han encontrado horarios para esta aula.')
        } else { }
        setHorarios({ all: [...horarios.all], current: horarioToShow })
    }, [aula, horarios])

    
    const deleteHorario = (id_definicion_horario) => {
        request.deleteDefinicionHorario(id_definicion_horario, (res) => {

            if (res.item) {
                //Buscar el index en horarios, donde res.item.id == horario.item, horarios[index] = res.item -> setHorarios(horarios)
                res.item.definiciones = res.item.definiciones.filter(definicion => definicion.id != id_definicion_horario)

                setHorarios({ all: [...horarios.all], current: res.item })
                horarios.all.forEach(horario => {
                    if (horario.id == res.item.id) {
                        horario.definiciones = horario.definiciones.filter(definicion => definicion.id != id_definicion_horario)

                        const existeHorario = horarios.all.some(h => h.id === horario.id);
                        if (!existeHorario)
                            setHorarios(prevHorarios => [...prevHorarios, horario]);
                    }
                });

                // enqueueSnackbar(<Text>Se ha eliminado la definición del horario correctamente.</Text>, { variant: "success" })
                updateAlert('Se ha eliminado la definición del horario correctamente.', 'success')
                setHorasTotales(res?.item?.horas_semanales)
                onSaveFinish()
            }

            //onSaveFinish()
        }, (err) => {
            // enqueueSnackbar(<Text>Ha habido un error al crear la definición del horario correctamente.</Text>, { variant: "error" })
            handleError('Ha habido un error al crear la definición del horario correctamente')
        })
    }

    //Variable que contiene la suma de las horas semanales
    //console.log('HorasTotales = ', horasTotales);

    return (
        <Grid container mt={2} px={4} spacing={2}>
            <Grid container mt={2} px={4} spacing={2}>
                <Grid item xs={12}>
                    <H3>Horas {grupo?.registro_actividad?.intensivo ? "totales" : "semanales"}</H3>
                    <Text>{horasTotales}</Text>
                </Grid>
            </Grid>
            {/* HORARIO */}
            <Grid container mt={2} px={4} spacing={2}>
                <Horario horarios={horario ? horarios.all : []} type="academia" onDeleteClick={deleteHorario} />
            </Grid>
        </Grid>
    )
}