import { RequestColegio } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Devuelve el colegio
     * 
     * @param {Int}      id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getColegio(id, callbackOk = () => {}, callbackError = () => {}) {
        const params = {relations: 'provincia,coordinador'}
        new RequestColegio().get(id, params, (res) => {
            res.colegio ? callbackOk(res.colegio) : callbackError(res)
        })
    }


    /**
     * Devuelve los trabajadores del colego
     * 
     * @param {Int}      id_colegio 
     * @param {Object}   params 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getTrabajadoresColegio(id_colegio, params, callbackOk = () => {}, callbackError = () => {}) {
        new RequestColegio().getTrabajadoresCursoEscolarColegio(id_colegio, params, (res) => {
            res.trabajadores ? callbackOk(res.trabajadores) : callbackError(res)
        }, callbackError)
    }
}
