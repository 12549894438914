import React from 'react'
import { Search as SearchIcon } from '@mui/icons-material'
import { Grid, Stack } from '@mui/material';
import { Button } from '../../../components';
import { COLOR } from '../../../../../theme/Colors';

//Botones de interaccion de los formularios de busqueda
export default class SearchButtons extends React.Component {

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    render() {
        return (
            <Grid container display="flex" justifyContent={"end"} mt={2}>
                <Stack direction="row" spacing={2}>
                    <Button 
                        variant = {"outlined"} 
                        onClick = {this.props.onResetClick}>

                        {this.props.cleanText ?? "Limpiar campos"}
                    </Button>
                    
                    <Button 
                        startIcon = {<SearchIcon style={{ color: COLOR.white.main }} />} 
                        onClick   = {this.props.onSubmitClick}>

                        {this.props.searchText ?? "Buscar"}
                    </Button>
                </Stack>
            </Grid>
        )
    }
}
