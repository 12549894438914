import React from 'react'

import Grid from '@mui/material/Grid'
import { Text } from 'components/texts'
import { Input, Select } from 'components/forms/components'
import { CursoEscolarSelect, ItemsSelect } from 'components/forms/components/Select/custom'
import { itemsActual } from '../constants'

/**
 * Esta es la plantilla para cuando se busque por los datos basicos de la actividad
 */
export default function ActividadTemplate({ register, errors, reset, setValue }) {

    return (
        <>
            <Grid container mt={2} mb={1}>
                <Text fontWeight={'bold'}>Según datos de:</Text><br />
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <ItemsSelect name={"tipo_actividad_id"} keyValue={"tipo_actividad"} register={register} errors={errors} wantToReset={reset} />
                </Grid>

                <Grid item xs={4}>
                    <ItemsSelect name={"registro_actividad_id"} keyValue={"registro_actividad_academia"} register={register} errors={errors} wantToReset={reset} searcheable/>
                </Grid>

                <Grid item xs={4}>
                    <ItemsSelect name={"modalidad_cursos_id"} keyValue={"modalidad_cursos"} register={register} errors={errors} wantToReset={reset} searcheable/>
                </Grid>

                <Grid item xs={4}>
                    <Input label="Clave Grupo" width={"100%"} name={"clave_grupo"} register={register} errors={errors} />
                </Grid>

                <Grid item xs={4}>
                    <CursoEscolarSelect
                        name={"curso_escolar_id"}
                        register={register}
                        errors={errors}
                        defaultValue={null}
                        setValue={setValue}
                        wantToReset={reset}
                        searcheable />
                </Grid>

                <Grid item xs={4}>
                    <Select register={register} errors={errors} name="actual" items={itemsActual} defaultValue={itemsActual[0].value} />
                </Grid>
            </Grid>
        </>
    )
}


