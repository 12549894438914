import { Grid } from '@mui/material'
import { COLOR } from "theme/Colors";
import React, { Component } from 'react'
import { Mini, Text } from 'components/texts'
import { Line } from 'components/data_display'
import { Button } from 'components/forms/components'
import { SearchAlumnoModal } from 'components/modals'
import { Search as SearchIcon} from '@mui/icons-material'
import { AlumnoItem } from 'components/data_display/users'

/**
 * Sección para elegir el alumno
 */
export default class AlumnoSection extends Component {

  constructor(props) {
    super(props)

    this.state = {
      alumno: null,
      valid: true,
    }
  }


  /**
   * ComponentDidUpdate
   */
  componentDidUpdate(prevProps) {
    if(this.props.wantToReset && !prevProps.wantToReset)
      this.setState({alumno: null, valid: true})
  }

  //-------------------------------------------------------
  //-------------------- CLASS FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Comprueba el alumno
   * 
   * @returns {Boolean}
   */
  isValid = () => {
    if (!this.state.alumno) {
      this.setState({ valid: false })
      return false
    }

    return true
  }


  /**
   * Devuelve el alumno
   * 
   * @return {Object|Null}
   */
  getAlumno = () => {
    return this.state.alumno
  }

  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
        <Grid container mt={2} px={4} spacing={2}>
          <Grid item xs={12} justifyContent="space-between" display={"flex"}>
            <Text fontWeight={"bold"}>{this.props.title ?? ""}</Text>

            <Button ref={(ref) => this.button = ref} size={"small"} variant={"outlined"} onClick={() => this.addAlumnoModal.modal.open()} startIcon={<SearchIcon />}>
              {this.props.buttonText ?? ""}
            </Button>
          </Grid>


          <Grid container px={2}>
            {this.state.alumno ? <AlumnoItem alumno={this.state.alumno} hideDeleteIcon hideSeeIcon hideEditRelationIcon /> : null}
          </Grid>

          {!this.state.valid ?
            (<Grid item xs={12}>
              <Mini color={COLOR.error.main}>El alumno es obligatorio.</Mini>
            </Grid>) : null
          }

          <Grid item xs={12}>
            <Line />
          </Grid>

        <SearchAlumnoModal ref={(ref) => this.addAlumnoModal = ref} onSelectedCallback={(alumno) => {
          this.setState({ alumno, valid: true })
          this.addAlumnoModal.modal.close()
          if(this.props.onSelect)
            this.props.onSelect(alumno)
        }} />
    </Grid>
    )
  }
}
