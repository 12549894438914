import React, { Component } from "react";
import Skeleton from "@mui/material/Skeleton";
import { Grid, Stack } from "@mui/material";

/**
 * Informacion básica del responsable/tutor
 */

export default function skeleton() {
	return (
		<Grid container spacing={2}>
			<Grid item xs={2}>
				<Skeleton variant="rounded" width={"80%"} height={"130%"} />
			</Grid>
			<Grid item xs={8}>
				<Skeleton
					variant="text"
					sx={{ fontSize: "2.5em", marginBottom: ".8rem" }}
					width={"8%"}
				/>
				<Skeleton
					variant="text"
					sx={{ fontSize: "2em" }}
					width={"30%"}
				/>
				<Skeleton
					variant="text"
					sx={{ fontSize: "1em" }}
					width={"30%"}
				/>
			</Grid>
			<Grid item xs={1} justifyContent={"end"}>
				<Skeleton variant="rounded" width={"100%"} height={40} />
			</Grid>
			<Grid item xs={1} justifyContent={"end"}>
				<Skeleton variant="rounded" width={"100%"} height={40} />
			</Grid>

			<Grid container item mt={8}>
				<Grid item xs={12}>
					<Skeleton
						variant="text"
						sx={{ fontSize: "2em" }}
						width={"20%"}
					/>
				</Grid>
			</Grid>

			<Grid container item mt={3}>
				<Grid item xs={4}>
					<Grid item xs={12} mb={1}>
						<Skeleton
							variant="text"
							sx={{ fontSize: "1.2em" }}
							width={"40%"}
						/>
						<Skeleton
							variant="text"
							sx={{ fontSize: "1em" }}
							width={"80%"}
						/>
					</Grid>
					<Grid item xs={12}>
						<Skeleton
							variant="text"
							sx={{ fontSize: "1.2em" }}
							width={"40%"}
						/>
						<Skeleton
							variant="text"
							sx={{ fontSize: "1em" }}
							width={"80%"}
						/>
					</Grid>
				</Grid>

				<Grid item xs={4}>
					<Grid item xs={12} mb={1}>
						<Skeleton
							variant="text"
							sx={{ fontSize: "1.2em" }}
							width={"40%"}
						/>
						<Skeleton
							variant="text"
							sx={{ fontSize: "1em" }}
							width={"60%"}
						/>
					</Grid>
					<Grid item xs={12}>
						<Skeleton
							variant="text"
							sx={{ fontSize: "1.2em" }}
							width={"40%"}
						/>
						<Skeleton
							variant="text"
							sx={{ fontSize: "1em" }}
							width={"60%"}
						/>
					</Grid>
				</Grid>

				<Grid item xs={4}>
					<Grid item xs={12} mb={1}>
						<Skeleton
							variant="text"
							sx={{ fontSize: "1.2em" }}
							width={"40%"}
						/>
						<Skeleton
							variant="text"
							sx={{ fontSize: "1em" }}
							width={"60%"}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
