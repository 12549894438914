import { Link } from 'components/data_display'
import { Button } from 'components/forms/components'

export const columns = [
    {
        field: "actions",
        type: 'actions',
        headerName: "Acciones",
        minWidth: 200,
        getActions: (params) => [<Link to={"" + params.row.id}><Button size={"small"}>Ver</Button></Link>, <Link to={"" + params.row.id + "/editar"}><Button size={"small"} variant={"outlined"}>Editar</Button></Link>]
    },

    { field: "id", headerName: "ID", width: 100, sortable: false, filterable: false },
    { field: "profesor", headerName: "Profesor", flex: 1, valueGetter: (params) => { 
        const { profesor_actual } = params?.row
        return (profesor_actual?.empleado?.nombre ?? "") + " " + (profesor_actual?.empleado?.apellido1 ?? "") + " " + (profesor_actual?.empleado?.apellido2 ?? "")
    }, sortable: false, filterable: false,},
    { field: "clave_grupo", headerName: "Clave Grupo", maxWidth: 250, valueGetter: (params) => { return params?.row?.clave_grupo }, sortable: false, filterable: false },
    { field: "curso_escolar_id", headerName: "Año", maxWidth: 250, valueGetter: (params) => { return params?.row?.curso_escolar?.valor }, sortable: false, filterable: false },
    {
        field: "registro_actividad_id", headerName: "Actividad", flex: 1, valueGetter: (params) => {
            return params?.row?.registro_actividad?.nombre
        }, 
        sortable: false, 
        filterable: false,
    },
]
