import { H3 } from 'components/texts'
import { columns } from './columns.js'
import { Box, Paper } from '@mui/material'
import Container from '@mui/material/Container'
import { Search as SearchIcon } from '@mui/icons-material'
import React, { useContext, useEffect, useState } from 'react'
import { ListTableHeader } from 'pages/logged/admin/components'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { Accordion, BreadcrumbsList, Datagrid } from 'components/data_display'
import SearchFormTutoria, { search } from 'components/forms/display/tutoria/SearchForm'

/**
 * Listado de las tutorias
 */
export default function ListadoTutorias() {
  const [loadingTable, setLoadingTable] = useState(false)
  const { items, rowCount, params, update } = useContext(EmpleadoListContext)

  useEffect(() => {
    setLoadingTable(false)
  }, [items])

  /**
   * Callback para actualizar la tabla una vez hecha la petición
   * 
   * @param {Array} data - Datos de todos los alumnos que se reflejan en la tabla Datagrid
   * @param {Int}   rowCount 
   * @param {Int}   page 
   * @param {Int}   params - Parámetros de búsqueda procedentes de SearchFormAlumno
   */
  const updateTable = (newData, newRowCount, newPage, newParams) => update(newRowCount, newData, newParams, newPage)

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <BreadcrumbsList breadcrumbsList={[
        { to: "/", label: "Inicio" },
        { to: "../alumnos", label: "Alumnos" },
        { to: "./", label: "Tutorías", actualItem: true },
      ]} />

      <Accordion header={<Box display={"flex"} alignItems={"center"}><SearchIcon className={"mx-5"} /> <H3 className="my-0">Buscar tutorías</H3></Box>}>
        <SearchFormTutoria
          searchCallback={updateTable}
          onLoadingCallback={setLoadingTable} />
      </Accordion>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
        <ListTableHeader rowCount={rowCount} params={params} keyValue={"tutorias"} />

        <Datagrid
          rows={items}
          columns={columns}
          loading={loadingTable}
          rowCount={rowCount}
          onPageChange={(page) => { search(page, () => setLoadingTable(true), updateTable) }}
          serverSide />
      </Paper>
    </Container>
  )
}
