import { SnackbarProvider } from 'notistack'
import React, { useState, useEffect } from 'react'
import { BreadcrumbsList } from 'components/data_display'
import { Container, Paper } from '@mui/material'
import { GroupInfo, AlumnosInfo } from './parts'
import { SearchGrupoColegioModal } from 'components/modals'

/**
 * //TODO: pongo por defecto "colegio" en tipoGrupo, para que el radio buton seleccionado sea el de colegios, que si tiene funcionalidad. El radio button de Grupo de CL no tiene funcionalidad de momento.
 * 
 * @returns {JSX.Element}
 */
export default function SingleSeguimientoTrimestral() {
    const [tipoGrupo, setTipoGrupo] = useState("colegio")
    const [open, setOpen] = useState(false)
    const [grupo, setGrupo] = useState(null)

    useEffect(() => {
        if (open) setOpen(false)
    }, [open])

    return (
        <Container maxWidth={false} sx={{ mt: 5 }}>
            <BreadcrumbsList breadcrumbsList={[
                { to: "/", label: "Inicio" },
                { to: "./../", label: "Seguimiento de curso" },
                { to: "./", label: "Seguimiento trimestal", actualItem: true },
            ]} />

            <Paper elevation={2} sx={{ mx: 8, my: 2, py: 3, px: 5, mt: 2 }}>
                <GroupInfo tipoGrupo={tipoGrupo} setTipoGrupo={setTipoGrupo} grupo={grupo} setOpen={setOpen} />
            </Paper>

            <Paper elevation={2} sx={{ mx: 8, my: 2, py: 3, px: 5, mt: 2 }}>
                <AlumnosInfo grupo={grupo} tipoGrupo={tipoGrupo} />
            </Paper>

            {tipoGrupo == 'academia' ? null : <SearchGrupoColegioModal open={open} onSelectedCallback={setGrupo} />}

            <SnackbarProvider autoHideDuration={3000} />
        </Container>
    )
}