import { Text } from "components/texts";
import { enqueueSnackbar } from "notistack";

/**
 * Hook `useAlert` para mostrar alertas en la aplicación.
 * Encapsula el uso de `enqueueSnackbar` para facilitar la creación de notificaciones emergentes.
 * 
 * @returns {Function} Una función `updateAlert` que acepta un mensaje y una variante para mostrar una alerta.
 */
export default function useAlert() {
    /**
     * Función `updateAlert` para mostrar una notificación emergente.
     * Acepta un mensaje y, opcionalmente, una variante que indica el estilo de la alerta (por ejemplo, "error", "success").
     * 
     * @param {string} message - El mensaje de la alerta.
     * @param {string} variant - La variante de la alerta (por ejemplo, "error", "warning", "info", "success").
     */
    const updateAlert = (message, variant = "error") => enqueueSnackbar(<Text>{message}</Text>, { variant })

    // Retorna la función `updateAlert` para su uso en otros componentes.
    return updateAlert
}