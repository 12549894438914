import Requests from './Requests'
import Agenda from 'components/agenda'
import { Text } from 'components/texts'
import { useSelector } from 'react-redux'
import { Paper, Stack } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { ShowEventModal, EditEventModal, CreateEventModal } from './modals'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import moment from 'moment'

export default function AgendaPart() {
    // const user_id = useSelector((state) => state.userState.user.id)
    const user = useSelector((state) => state.userState.user) //devuelve un array de usuarios

    const [events,       setEvents]       = useState([])
    const [actualUserId, setActualUserId] = useState(user[0]?.id) //por defecto coge el primer usuario del array

    const createEventModalRef = useRef()
    const editEventModalRef   = useRef()
    const showEventModalRef   = useRef()

    /**
     * Hace la peticion para obtener los eventos de un usuario
     */
    const getEventosUsuario = (date = new Date().toISOString().split('T')[0]) => {
        new Requests().getUserEvents({date, user_id: actualUserId}, (res) => {
            const {eventos} = res
            eventos.map(evento => {
                evento = initEvento(evento)
            })

            setEvents(eventos)
            checkRecentEvent(eventos)
        }, () => {
            setEvents([])
        })
    }

    /**
     * Inicializa un evento para el calendario
     * 
     * @param {Object} evento 
     * @returns {Object}
     */
    const initEvento = (evento) => {
        evento.start  = new Date(evento.date + (evento.hour ? " " + evento.hour : ""))
        evento.allDay = evento.hour ? false : true
        if(evento.end_hour)
            evento.end = new Date(evento.date + (evento.end_hour ? " " + evento.end_hour : ""))

        return evento
    }

    useEffect(getEventosUsuario, [actualUserId])

    const dateClick = ({ dateStr }, isTime = false) => {
        createEventModalRef.current.open(dateStr, isTime)
    }

    /**
     * Añade el evento recien creado a los demas
     * 
     * @param {Object} evento 
     */
    const onCreateEvento = (evento) => {
       const newEvents = [...events]
       
       evento = initEvento(evento)

       newEvents.push(evento)

       setEvents(newEvents)
    }

    /**
    * Actualiza el evento recien editado
    * 
    * @param {Object} evento 
    */
    const onEditEvento = (evento) => {
        const newEvents = [...events]
        const index = newEvents.findIndex(item => item.id == evento.id)
        const newEvento = initEvento(evento)
        newEvents[index] = {...newEvento}

        setEvents(newEvents)
    }

    /**
     * Elimina el evento recien borrado 
     * 
     * @param {Object} evento 
     */
    const onDeleteEvento = (evento) => {
        const newEvents = [...events]
        const index = events.findIndex(item => item.id == evento.id)
        if (index > -1)
            newEvents.splice(index, 1);

       setEvents(newEvents)
    }

        
    /**
     * Callback para cuando se hace click en el evento
     */
    const eventClick = ({event}) => {
        const eventClicked = {
            id:          event.id,
            title:       event.title,
            description: event._def.extendedProps.description,
            date:        event.startStr.split('T')[0],
            hour:        event._def.extendedProps.hour,
            end_hour:    event._def.extendedProps.end_hour,
            color:       event.borderColor,
            empleado:    event._def.extendedProps.empleado,
            compartidos: event._def.extendedProps.compartidos,
        }

        showEventModalRef.current.open(eventClicked)
    }


    /**
     * Callback para cuando se cambie de empleado en el select
     * 
     * @param {Object} target 
     */
    const onChangeEmpleado = ({target}) => {
        setActualUserId(target.value)
    }

    const checkRecentEvent = (eventos) => {
        const horaActual = moment()
        const fechaActual = moment().format('YYYY-MM-DD')

        for (const event of eventos) {
            const horaServidorMoment = moment(event.hour, 'HH:mm')
            const diferenciaMinutos = Math.abs(horaServidorMoment.diff(horaActual, 'minutes'))

            if(event.date == fechaActual && diferenciaMinutos <= 30 && diferenciaMinutos >= 0)
                enqueueSnackbar(<Text>{`Tienes el evento ${event.title} en ${diferenciaMinutos} minutos.`}</Text>, { variant: "info", autoHideDuration: 5000 })
        }
    }

    return (
        <Paper elevation={0} sx={{px: 3, pt: 2, pb: 3, borderRadius: 2, border: "1px solid var(--greyLight)"}}>
            <Stack my={2} display={"flex"} justifyContent={"space-between"}>
                <Text fontWeight={"bold"} className="mb-2">Elige a un empleado para ver sus eventos.</Text>
                <ItemsSelect 
                    keyValue     = {"empleado_cl"} 
                    params       = {{roles: 'admin'}}
                    defaultValue = {actualUserId}
                    onChange     = {onChangeEmpleado} 
                    renderItem   = {(item) => ({ value: item.id, label: item.nombre + " " + (item.apellido1 ?? "") + " - " + (item.email ?? "") })}
                    searcheable />
            </Stack>

            <Agenda 
                dateClick   = {dateClick} 
                events      = {events}
                eventClick  = {eventClick}
                onNextClick = {(date) => {
                    const newDate = new Date(date.setMonth(date.getMonth() + 1))
                    getEventosUsuario(newDate.toISOString().split('T')[0])
                }}
                onPrevClick = {(date) => {
                    const newDate = new Date(date.setMonth(date.getMonth() - 1))
                    getEventosUsuario(newDate.toISOString().split('T')[0])
                }}/>

            <CreateEventModal ref={createEventModalRef} onCreateEvento={onCreateEvento} userId={actualUserId} />
            <EditEventModal   ref={editEventModalRef}   onEditEvento={onEditEvento}/>
            <ShowEventModal   ref={showEventModalRef}   onDeleteEvento={onDeleteEvento} onEditClick={(evento) => {editEventModalRef.current.open(evento)}} loggedUserId={actualUserId}/>

            <SnackbarProvider autoHideDuration={3000} />
        </Paper>
    )
}