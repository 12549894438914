import Skeleton from './skeleton'
import { ASSETS } from 'constants/Assets'
import { Link } from 'components/data_display'
import { H1, H3, Text } from 'components/texts'
import { Avatar, Box, Grid } from '@mui/material'
import { Button } from 'components/forms/components'
import { Edit as EditIcon } from '@mui/icons-material'
import { useImageLoader } from 'hooks'
import { DEFAULT_IMAGES_URL } from 'constants/DefaultImagesUR'

export default function AlumnoData({ alumno }) {
  const { imageSrc } = useImageLoader(alumno, 'foto_logo', DEFAULT_IMAGES_URL.alumno, true)

  return (
    <Grid container>
      {!alumno ? <Skeleton /> : (
        <>
          <Grid item xs={2}>
            <Avatar
              alt={"Foto de: " + (alumno?.nombre ?? "")}
              src={imageSrc}
              sx={{ width: "80%", height: "100%", borderRadius: 2 }} />
          </Grid>

          <Grid item xs={8}>
            <H1 className="profile-id-text">{"#" + alumno.id}</H1>

            <Box mt={2}>
              <H3>{alumno.nombre + " " + (alumno.apellido1 ?? "") + " " + (alumno.apellido2 ?? "")}</H3>

              <Box display={"flex"}>
                <Text fontWeight={"bold"}>Documento de identidad:</Text>
                <Text className="mx-2">{alumno.numero_documento + " - " + alumno.tipo_documento?.nombre}</Text>
              </Box>

              <Box display={"flex"}>
                <Text fontWeight={"bold"}>Género:</Text>
                <Text className="mx-2">{alumno.sexo?.valor}</Text>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={2} display={"flex"} justifyContent={"end"} alignItems={"start"}>
            <Link to="editar">
              <Button variant={"outlined"} startIcon={<EditIcon />}>
                Editar
              </Button>
            </Link>
          </Grid>

          <Grid item xs={12} mt={5}>
            <H3>Datos de contacto:</H3>
          </Grid>

          <Grid container mt={5}>
            <Grid item xs={3} display={"flex"} flexDirection={"column"}>
              <Text fontWeight={"bold"}>Dirección</Text>
              <Text>{(alumno.nombre_via ?? "") + (alumno.tipo_via ? " - " + alumno.tipo_via.valor : "")}</Text>
              <Text>{alumno.cod_postal ?? ""}</Text>
              <Text>{(alumno.municipio ?? "") + (alumno.provincia ? ", " + alumno.provincia.nombre : "")}</Text>
            </Grid>

            <Grid item xs={3} display={"flex"} flexDirection={"column"}>
              <Text fontWeight={"bold"}>Fecha de nacimiento</Text>
              <Text>{alumno.fecha_nacimiento ? alumno.fecha_nacimiento.split('-').reverse().join("/") : "Sin rellenar"}</Text>
            </Grid>

            <Grid item xs={3} display={"flex"} flexDirection={"column"}>
              <Text fontWeight={"bold"}>Número de teléfono</Text>
              <Text>{alumno.telefono1 ?? "Sin rellenar"}</Text>
            </Grid>

            <Grid item xs={3} display={"flex"} flexDirection={"column"}>
              <Text fontWeight={"bold"}>Lugares de nacimiento</Text>
              <Text>Pais: {alumno.pais_nacimiento ?? "Sin rellenar"}</Text>
              <Text>Provincia: {alumno.provincia_nacimiento && alumno.provincia_nacimiento.nombre ? alumno.provincia_nacimiento.nombre : "Sin rellenar"}</Text>
              <Text>Municipio: {alumno.municipio_nacimiento ?? "Sin rellenar"}</Text>
            </Grid>

            <Grid item xs={3} display={"flex"} flexDirection={"column"}></Grid>

            <Grid item xs={3} display={"flex"} flexDirection={"column"}>
              <Text fontWeight={"bold"}>Correo electrónico</Text>
              <Text>{alumno.email ?? "Sin rellenar"}</Text>
            </Grid>

            <Grid item xs={3} display={"flex"} flexDirection={"column"}>
              <Text fontWeight={"bold"}>Número de teléfono adicional</Text>
              <Text>{alumno.telefono2 ?? "Sin rellenar"}</Text>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )

}