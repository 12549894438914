import { yupResolver } from "@hookform/resolvers/yup"
import { useAlert } from "hooks"
import { useState } from "react"
import { useForm } from "react-hook-form"

/**
 * Hook personalizado `useLogic` que maneja las interacciones del formulario y los estados de carga.
 * Proporciona métodos para registrar controles de formulario, manejar el envío de formulario y realizar acciones 
 * al hacer clic en los botones de la tarjeta.
 *
 * @returns {Object} - Retorna métodos de 'react-hook-form' y una función para manejar clics en botones de reporte.
 */
export default function useLogic(requests, validationSchema) {
    const updateAlert = useAlert()

    const [loadingButtonId, setLoadingButtonId] = useState(null)

    const { register, getValues, handleSubmit, formState: { errors }, setValue } = useForm({ resolver: yupResolver(validationSchema) })

    const handleClick = (action, buttonId) => {
        updateAlert('Descargando PDF...', 'info')
        setLoadingButtonId(buttonId)

        const cursoEscolarId = getValues("curso_escolar_id")

        requests[action](cursoEscolarId, (res) => {
            setLoadingButtonId(null)
        }, (res) => {
            setLoadingButtonId(null)
        })
    }

    return { loadingButtonId, register, handleSubmit, errors, setValue, handleClick }
}