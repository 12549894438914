import React, { useEffect, useRef } from 'react'
import Modal from 'components/modals/Modal'
import { Text } from 'components/texts'
import { Button } from 'components/forms/components'
import { Box, Stack } from '@mui/material'

const dias = {
    1: "lunes", 
    2: "martes", 
    3: "miércoles", 
    4: "jueves", 
    5: "viernes", 
    6: "sábado", 
}

export default function DeleteHorarioModal({ deleteStatus, onDeleteClick }) {

    const modalRef = useRef()
    const { open, eventToDelete } = deleteStatus

    useEffect(() => {
        if(open)
            modalRef.current.open()
    }, [open])

    const onDelete = () => {
        onDeleteClick(eventToDelete?.id)
        modalRef.current.close()
    }

    return (
        <Modal ref={modalRef} title={"Eliminar horario"}>
            <Box px={10} py={5}>
                <Stack display={"flex"} justifyContent={"center"} alignItems={"center"} direction={"column"}>
                    <Text>
                        ¿Estás seguro de que quieres borrar el tramo horario <Text fontWeight={"bold"}>{eventToDelete?.hora_inicio + " - " + eventToDelete?.hora_fin} del {dias[eventToDelete?.dia ?? 1]}</Text>?
                    </Text>
                </Stack>
                
                <Box display={"flex"} justifyContent={"end"} mt={2}>
                    <Button onClick={onDelete} color={"error"}>
                        Eliminar
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}
