import React, { createContext, useState } from 'react'

const ChangesContext = createContext()

const ChangesProvider = ({ children }) => {
  const [hasChanges, setHasChanges] = useState(false)

  const updateChanges = (status) => {
    setHasChanges(status)
  }

  return (
    <ChangesContext.Provider value={{ hasChanges, updateChanges }}>
      {children}
    </ChangesContext.Provider>
  )
}

export { ChangesProvider, ChangesContext }
