import { RequestLibro } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Devuelve el libro
     * 
     * @param {Int}      id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getLibro(id, callbackOk = () => {}, callbackError = () => {}) {
        new RequestLibro().get(id, {relations: 'pedidos_libro,devoluciones_libro,niveles_libro', atributtes: 'numVentasCurso'}, (res) => {
            res.libro ? callbackOk(res.libro) : callbackError(res)
        })
    }
}
