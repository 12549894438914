import { Link } from 'components/data_display'
import { Button } from 'components/forms/components'

export const basicColumns = [
    {
        field: "actions",
        type: 'actions',
        headerName: "Acciones",
        minWidth: 200,
        getActions: (params) => [<Link to={"" + params.row.id}><Button size={"small"}>Ver</Button></Link>, <Link to={"" + params.row.id + "/editar"}><Button size={"small"} variant={"outlined"}>Editar</Button></Link>]
    },

    { field: "id", headerName: "ID", maxWidth: 65, sortable: false, filterable: false },
    { field: "tipo_documento", headerName: "Tipo de documento", minWidth: 100, valueGetter: (params) => { return params.row.tipo_documento?.nombre }, sortable: false, filterable: false },
    { field: "numero_documento", headerName: "Número", minWidth: 100, sortable: false, filterable: false },
]

export const columnsDatosPersonales = [
    { field: "apellido1", headerName: "1. Apellido", minWidth: 100, flex: 1, sortable: false, filterable: false },
    { field: "apellido2", headerName: "2. Apellido", minWidth: 100, flex: 1, sortable: false, filterable: false },
    { field: "nombre", headerName: "Nombre", minWidth: 100, flex: 1, sortable: false, filterable: false },
    { field: "email", headerName: "Email", minWidth: 100, flex: 1, sortable: false, filterable: false },
    { field: "sexo", headerName: "Sexo", minWidth: 100, valueGetter: (params) => { return params.row.sexo?.valor }, sortable: false, filterable: false },
]

export const columnsDomicilio = [
    { field: "nombre_completo", headerName: "Nombre", minWidth: 100, flex: 1, valueGetter: (params) => { return (params.row.nombre ?? "") + " " + (params.row.apellido1 ?? "") + " " + (params.row.apellido2 ?? "") }, sortable: false, filterable: false },
    { field: "nombre_via", headerName: "Domicilio", minWidth: 100, flex: 1, sortable: false, filterable: false },
    { field: "cod_postal", headerName: "C.P.", minWidth: 100, sortable: false, filterable: false },
    { field: "municipio", headerName: "Municipio", minWidth: 100, flex: 1, sortable: false, filterable: false },
    { field: "provincia", headerName: "Provincia", minWidth: 100, valueGetter: (params) => { return params.row.provincia?.nombre }, flex: 1, sortable: false, filterable: false },
    { field: "tipo_via", headerName: "Tipo vía", minWidth: 100, valueGetter: (params) => { return params.row.tipo_via?.valor }, flex: 1, sortable: false, filterable: false },
]