import { useForm } from 'react-hook-form'
import Statics from 'objects/common/Statics'
import Requests from './Requests/Requests.js'
import { useNavigate } from "react-router-dom"
import { responses } from 'constants/Responses'
import { Container, Paper } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangesContext } from 'contexts/ChangesContext'
import { DataFormTutoria } from 'components/forms/display'
import React, { useState, useEffect, useRef, useContext } from 'react'
import { SnackbarProvider } from 'notistack'
import { BreadcrumbsList, Line } from 'components/data_display'
import { CreateHeader, AlumnoSection } from 'pages/logged/admin/components'
import { validationSchema } from 'components/forms/display/tutoria/DataForm/validation.js'
import { useCheckDirtyFields, useAlert } from 'hooks'
import { EmpleadoListContext } from 'contexts/employee/ListContext.jsx'

export default function CreateTutoria() {
  const updateAlert = useAlert();

  const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, control, setValue } = useForm({ resolver: yupResolver(validationSchema) })
  const navigate = useNavigate()

  const headerRef = useRef()
  const alumnoSectionRef = useRef()

  const [isMounted, setIsMounted] = useState(false)

  const { updateChanges } = useContext(ChangesContext)
  const { addItem } = useContext(EmpleadoListContext)

  useEffect(() => setIsMounted(true), [])

  useCheckDirtyFields(dirtyFields, updateChanges)

  const onValid = () => {
    if (alumnoSectionRef.current.isValid())
      createTutoria()
  }

  const onError = () => {
    alumnoSectionRef.current.isValid()
  }

  const createTutoria = () => {
    headerRef.current.button.setLoading(true)

    new Requests().createTutoria(getValues, alumnoSectionRef.current.getAlumno().id, async (res) => {
      const { id } = res[responses.tutorias.singleName]
      updateAlert('Se ha creado la tutoría correctamente. Redirigiendo a la pagina de edición..', 'success')
      updateChanges(false)
      addItem(res[responses.tutorias.singleName])
      await Statics.delay(1500).then(() => { navigate('./../' + id) })
    }, (res) => {
      headerRef.current.button.setLoading(false)
      updateAlert('Se ha producido un error.')
    })
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(onValid, onError)}>
        <BreadcrumbsList breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../../", label: "Alumnos" },
          { to: "./../", label: "Tutorías" },
          { to: "./", label: "Crear", actualItem: true },
        ]} />

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <CreateHeader
            ref={headerRef}
            title={"Crear tutoría"} />

          <Line />

          <AlumnoSection
            ref={alumnoSectionRef}
            title={"Escoge un alumno para crearle una tutoría: "}
            buttonText={"Elegir alumno para la nueva tutoría"}
            onSelect={() => updateChanges(true)} />

          <DataFormTutoria
            register={register}
            setValue={setValue}
            errors={errors}
            isMounted={isMounted}
            control={control}
            hideButton />
        </Paper>
      </form>
      <SnackbarProvider autoHideDuration={3000} />
    </Container>
  )
}