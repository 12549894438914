import { useForm } from "react-hook-form"
import { useEffect, useState } from "react"
import Statics from "objects/common/Statics"
import { yupResolver } from "@hookform/resolvers/yup"

let prepareParamsGlobal = null

/**
 * Hook personalizado que maneja la lógica del formulario de búsqueda de inscripciones de academia.
 * Utiliza react-hook-form para gestionar el formulario y yup para la validación según un esquema definido.
 * Permite al usuario seleccionar criterios de búsqueda y ordenamiento específicos para filtrar las inscripciones.
 *
 * @param {Function} onChangeSearchIndex - Función callback que se invoca cuando se cambia el tipo de búsqueda.
 * 
 * @returns {Object} - Retorna un conjunto de propiedades y funciones para ser utilizadas en el componente.
 */
export default function useAcademiaInscripcionSearchFormLogic({ itemsSearch, onChangeSearchIndex, validationSchema }) {
    const { register, handleSubmit, formState: { errors }, getValues, setValue, reset } = useForm({ resolver: yupResolver(validationSchema) })

    const [resetFormState, setResetFormState] = useState(false)
    const [searchIndex, setSearchIndex] = useState(0)

    /**
     * Función para manejar cambios en el selector de tipo de búsqueda.
     * Actualiza el índice de búsqueda y, opcionalmente, notifica al componente padre para ajustar otros elementos UI según la selección.
     *
     * @param {Object} event - El evento generado por el cambio en el elemento select.
     */
    const handleChange = (event) => {
        const index = event.target.value

        setSearchIndex(index)
        setResetFormState(true)

        if (onChangeSearchIndex)
            onChangeSearchIndex(index)

        // onChangeSearchIndex(index, itemsSearch[index].columnKey)
    }

    /**
     * Efecto para resetear el formulario cuando el estado 'resetFormState' es verdadero.
     */
    useEffect(() => {
        if (resetFormState == true) {
            resetForm(reset)
            setResetFormState(false) // Reestablece el estado para evitar resets adicionales.
        }
    })

    /**
     * Prepara los parámetros para la petición de búsqueda basándose en los valores actuales del formulario.
     * Incluye limpieza y transformación de los datos del formulario para ajustarse a las necesidades del backend.
     *
     * @param {Int} page - Número de página para la paginación de resultados.
     * @returns {Object} - Parámetros preparados para la petición de búsqueda.
     */
    const prepareParams = (page) => {
        const params = Statics.objectPropsToUppercase(Statics.cleanObject(getValues()));
        params.appends = ['actividad', 'grupo_academia', 'forma_pago', 'forma_recogida', 'forma_conocimiento', 'motivo_baja', 'operador', 'alumno', 'curso_escolar', 'preferencia_horario', 'tipo_matricula', 'tipo_pago']
        params.page = page

        return params
    }

    const ActualTemplate = itemsSearch[searchIndex].template

    prepareParamsGlobal = prepareParams

    return { searchIndex, prepareParamsGlobal, setResetFormState, resetFormState, setValue, errors, register, handleSubmit, ActualTemplate, handleChange }
}

/**
 * Función para resetear el formulario a sus valores predeterminados.
 * Este método se utiliza para limpiar todos los campos del formulario, estableciendo
 * los valores iniciales o vacíos para cada uno, listo para una nueva búsqueda.
 * 
 * @param {Function} reset - Función proporcionada por react-hook-form para resetear el formulario.
 */
const resetForm = (reset) => {
    // Llama a la función reset de react-hook-form con un objeto que especifica los valores iniciales
    // para los campos del formulario, limpiando cualquier dato ingresado previamente por el usuario.
    reset({
        nombre: "",
        apellido1: "",
        apellido2: "",
        clave_grupo: "",
        test_nivel: ""
    })
}