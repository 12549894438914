import { TabContext } from '@mui/lab'
import { COLOR } from 'theme/Colors.js'
import { SnackbarProvider } from 'notistack'
import Requests from './Requests/Requests.js'
import { Text } from 'components/texts/index.js'
import { useAcademiaInscripcionCreateLogic } from 'hooks'
import { CreateHeader } from 'pages/logged/admin/components'
import { BreadcrumbsList, Line } from 'components/data_display'
import { Box, Container, Paper, Tab, Tabs } from '@mui/material'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { DataFormInscripcionAcademias, DataFormGrupoAcademias } from 'components/forms/display'
import PagoInscripcionAcademiaDataForm from 'components/forms/display/academia/inscripciones/DataForm/PagoDataForm/index.jsx'
import LibroInscripcionAcademiaDataForm from 'components/forms/display/academia/inscripciones/DataForm/LibroDataForm/index.jsx'

/**
 * Componente funcional para crear inscripciones de academia.
 * Utiliza el hook personalizado useAcademiaInscripcionCreateLogic para obtener la lógica y los estados necesarios.
 * Renderiza un formulario con múltiples secciones gestionadas a través de pestañas.
 * 
 * @returns {JSX.Element}
 */
export default function CreateInscripcionesAcademia() {
  const { alumnoSelected, setAlumnoSelected, getAlumnoFromServer, navigate, operadorName, updateAlert, grupo, actividad, setGrupo, setActividad, isMounted, clearErrors, cursoEscolar,
    setCursoEscolar, getValues, headerRef, tab, setTab, register, handleSubmit, errors, setValue, control, createInscripcionAcademia } = useAcademiaInscripcionCreateLogic({ requests: new Requests() })
  
    const onAlumnoSelected = (alumno) => {
    setAlumnoSelected(alumno)
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={(handleSubmit(createInscripcionAcademia, (errors) => {
        updateAlert('Por favor, complete todos los campos requeridos.')
      }))}>

        <BreadcrumbsList breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../", label: "Academia" },//--> Te dirige al apartado Inscripciones
          { to: "./../", label: "Inscripciones" },
          { to: "./", label: "Crear inscripción", actualItem: true },
        ]} />

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <CreateHeader
            ref={headerRef}
            title={"Crear inscripción para la academia"} />

          <Line />

          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
              <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>DATOS DE LA INSCRIPCIÓN</Text>} value={"1"} />
                <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>DATOS DEL GRUPO</Text>} value={"2"} />
                <Tab label={<Text fontWeight={tab === "3" ? "bold" : null} color={tab === "3" ? COLOR.standard.main : null}>DATOS DE LOS PAGOS</Text>} value={"3"} />
                <Tab label={<Text fontWeight={tab === "4" ? "bold" : null} color={tab === "4" ? COLOR.standard.main : null}>LIBROS</Text>} value={"4"} />
              </Tabs>
            </Box>

            <TabPanel value={"1"}>
              <DataFormInscripcionAcademias
                datosColegioUrlType={'create'} //sirve para saber a qué url redirigir al usuario al hacer click en el botón de Datos Colegio
                navigate={navigate}
                operador={operadorName}
                getValues={getValues}
                register={register}
                setValue={setValue}
                errors={errors}
                clearErrors={clearErrors}
                isMounted={isMounted}
                onChangeCursoEscolar={setCursoEscolar}
                onChangeActividad={setActividad}
                onAlumnoSelected={onAlumnoSelected}
                onSaveFinish={getAlumnoFromServer}
                control={control} />
            </TabPanel>

            <TabPanel value={"2"}>
              <DataFormGrupoAcademias
                getValues={getValues}
                register={register}
                errors={errors}
                clearErrors={clearErrors}
                isMounted={isMounted}
                setValue={setValue}
                onGrupoChange={setGrupo}
                actividad={actividad?.id}
                cursoEscolar={cursoEscolar} />
            </TabPanel>

            <TabPanel value={"3"}>
              <PagoInscripcionAcademiaDataForm
                alumno={alumnoSelected}
                getValues={getValues}
                register={register}
                errors={errors}
                clearErrors={clearErrors}
                isMounted={isMounted}
                setValue={setValue}
                actividad={actividad}
                control={control} />
            </TabPanel>

            <TabPanel value={"4"}>
              <LibroInscripcionAcademiaDataForm
                grupo={grupo}
                getValues={getValues}
                register={register}
                errors={errors}
                clearErrors={clearErrors}
                isMounted={isMounted}
                setValue={setValue}
                control={control} />
            </TabPanel>
          </TabContext>

        </Paper>

        <SnackbarProvider autoHideDuration={3000} />
      </form>
    </Container>
  )
}