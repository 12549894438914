import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests.js'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Container, Paper, Stack } from '@mui/material'
import { EditHeader } from 'pages/logged/admin/components'
import React, { useRef, useState, useEffect, useContext } from 'react'
import { BreadcrumbsList, Line, MoveButtons, SnackbarAlert } from 'components/data_display'
import { Text } from 'components/texts'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { EmpleadoListContext } from 'contexts/employee/ListContext'

//FORM
import { PagosAlumnosDataForm } from 'components/forms/display'
import SkeletonPagosAlumnos from 'components/forms/display/pagos-alumnos/DataForm/skeleton.jsx'
import { validationSchema } from 'components/forms/display/academia/aula/DataForm/validation.js'

export default function Wrapper() {

  const navigationParams = useParams()

  const [pagos, setPagos] = useState(null)
  const [id_pagos, setIdPagos] = useState(navigationParams.id_pagos)

  const { items } = useContext(EmpleadoListContext)

  useEffect(() => {
    const pagos = items.find(item => item.id == id_pagos)
    setPagos(pagos)
  }, [id_pagos])

  return (
    <React.Fragment>
      <EditPagosAlumnos key={pagos?.id ?? 0} pagos={pagos} id_pagos={id_pagos} setIdPagos={setIdPagos} setPagos={setPagos} />
      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}

function EditPagosAlumnos({ pagos, id_pagos, setIdPagos, setPagos }) {
  const { updateItem } = useContext(EmpleadoListContext)

  const navigate = useNavigate()

  const { register, handleSubmit, formState: { errors }, getValues, setValue, control } = useForm({ resolver: yupResolver(validationSchema) })
  const headerRef = useRef()


  const updateHeaderAndAlert = ({ message, variant = "error" }, loading = false) => {
    headerRef.current.button.setLoading(loading)
    enqueueSnackbar(<Text>{message}</Text>, { variant })
  }


  /**
   * Hace la petición para editar los datos del Pagos
   */
  const editPagos = () => {
    headerRef.current.button.setLoading(true)
    new Requests().editPagos(id_pagos, getValues, (res) => {
      updateItem(res.item)
      updateHeaderAndAlert({ message: "Se ha editado el pagos correctamente.", variant: "success" })
    }, (res) => {
      updateHeaderAndAlert({ message: res })
    })
  }

  const updateId = (id) => {
    navigate(`../academias/pagos-alumnos/${id}/editar`);
    setIdPagos(id);
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(editPagos)}>

        <Stack direction="row" justifyContent={"space-between"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/", label: "Inicio" },
            { to: "./../", label: "Pagos" },
            { to: "./../../", label: "Editar Pagos", actualItem: true },
          ]} />

          <MoveButtons
            actualItemId={id_pagos}
            beforeFetchCallback={() => setPagos(null)}
            onUpdateActualItem={updateId} />
        </Stack>

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <EditHeader
            ref={headerRef}
            title={"Editar Pagos"}
            item={pagos} />

          <Line />

          {pagos ?
            <PagosAlumnosDataForm
              pagos={pagos}
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
              isMounted
              hideButton />
            : <SkeletonPagosAlumnos />}
        </Paper>
      </form>
    </Container>
  )
}