import { Grid, Skeleton } from "@mui/material";
import { Container, Paper, Stack, Box, Tabs, Tab } from '@mui/material';

export default function InscripcionesAlumnosSkeleton() {
    return (
        <Container maxWidth={false} sx={{ mt: 5 }}>
            <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
                <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} marginX={8}>
                    {/* BreadcrumbsList - Muestra solo el esqueleto de los breadcrumbs */}
                    <Stack direction="row" spacing={1}>
                        <Skeleton variant="text" width={100} height={30} />
                        <Skeleton variant="text" width={100} height={30} />
                        <Skeleton variant="text" width={100} height={30} />
                        <Skeleton variant="text" width={150} height={30} />
                    </Stack>
                    {/* MoveButtons - Muestra solo el esqueleto de los botones de mover */}
                    <Stack direction="row" spacing={1}>
                        <Skeleton variant="rectangular" width={100} height={30} />
                        <Skeleton variant="rectangular" width={100} height={30} />
                    </Stack>
                </Stack>
                <Paper elevation={2} sx={{ mx: 8, my: 2 }}>
                    <Stack py={2} px={5} display={"flex"} flexDirection={"row"} alignItems="center" justifyContent={"space-between"}>
                        {/* Título de la inscripción */}
                        <Skeleton variant="text" width={200} height={30} />
                        {/* Botón de editar inscripción */}
                        <Skeleton variant="rectangular" width={150} height={30} />
                    </Stack>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
                        <Tabs value="1" TabIndicatorProps={{ style: { backgroundColor: 'transparent' } }}>
                            {/* Etiquetas de las pestañas */}
                            <Tab label={<Skeleton variant="text" width={150} height={30} />} value="1" />
                            <Tab label={<Skeleton variant="text" width={150} height={30} />} value="2" />
                            <Tab label={<Skeleton variant="text" width={150} height={30} />} value="3" />
                            <Tab label={<Skeleton variant="text" width={150} height={30} />} value="4" />
                        </Tabs>
                    </Box>

                    <Grid container px={5}>
                        <Grid item xs={12} md={6} py={3} pr={2}>
                            <Skeleton variant="rectangular" width="100%" height={200} />
                        </Grid>

                        <Grid item xs={12} md={6} sx={{ borderLeft: { xs: "none", md: "1px solid #D9D9D9" } }} py={3} pl={3}>
                            <Stack sx={{ flexDirection: { xs: "row", md: "column" } }}>
                                <Stack sx={{ mx: { xs: 2, md: 0 }, my: { xs: 0, md: 2 } }}>

                                    <Skeleton variant="text" width="50%" />
                                </Stack>

                                <Stack sx={{ mx: { xs: 2, md: 0 }, my: { xs: 0, md: 2 } }}>

                                    <Skeleton variant="text" width="50%" />
                                </Stack>

                                <Stack sx={{ mx: { xs: 2, md: 0 }, my: { xs: 0, md: 2 } }}>

                                    <Skeleton variant="text" width="50%" />
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Skeleton variant="rectangular" width="100%" height={1} />
                    </Grid>

                    <Grid container px={5} mt={2} spacing={3} pb={3}>
                        {[...Array(10)].map((_, index) => (
                            <Grid item xs={6} md={3} key={index}>
                                <Stack>
                                    <Skeleton variant="text" width="50%" />
                                </Stack>
                            </Grid>
                        ))}
                    </Grid>
                </Paper>

            </form>
        </Container>
    );
}
