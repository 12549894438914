import { RequestEmpleado } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
* Devuelve grupo
* 
* @param {Int}      id 
* @param {Function} callbackOk 
* @param {Function} callbackError 
*/
    getEmpleado(id, callbackOk = () => { }, callbackError = () => { }) {
        new RequestEmpleado().get(id, {}, (res) => {
            res ? callbackOk(res) : callbackError(res)
        })
    }

}

