import { ChangesContext } from "contexts/ChangesContext"
import Statics from "objects/common/Statics"
import { useContext, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { RequestAlumnoResponsable, RequestResponsable } from 'objects/requests'
import useErrorHandler from "hooks/utils/useErrorHandler"
import { useAlert } from "hooks"
import { EmpleadoListContext } from "contexts/employee/ListContext"
//import validateIbanAcount  from "pages/logged/admin/alumnos/alumno/validateIbanAcount"

/**
 * Hook personalizado para la creación de alumnos.
 * Encapsula la lógica de negocio para agregar un nuevo alumno en el sistema,
 * incluyendo la validación de campos específicos, la creación de responsables si es necesario,
 * y la asociación de datos bancarios.
 *
 * @param {Function} request Instancia de la clase de solicitud para interactuar con la API.
 * @param {Boolean} esMenorDeEdad Indica si el alumno a crear es menor de edad.
 * @param {Array} responsables Lista de responsables (tutores) asociados al nuevo alumno.
 * @returns {Object} Objeto que expone funciones y referencias para ser utilizadas en el componente.
 */
export default function useCreateAlumno(request, esMenorDeEdad, responsables) {
    const navigate = useNavigate()
    const { updateChanges } = useContext(ChangesContext)
    const { addItem } = useContext(EmpleadoListContext)
    const headerRef = useRef()
    const handleError = useErrorHandler()
    const updateAlert = useAlert()

    /**
     * Procesa con éxito la creación del alumno.
     * Finaliza la indicación de carga, muestra una alerta de éxito y redirige a la página de edición del alumno.
     * 
     * @param {Number} idAlumno ID del alumno recién creado.
     */
    const handleSuccessRequest = async (idAlumno) => {
        // headerRef.current.button.setLoading(false)
        updateAlert('Se ha creado el alumno. Redirigiendo...', 'success')
        updateChanges(false)

        //Redirección a SINGLE del nuevo alumno creado.
        await Statics.delay(3000).then(() => { navigate('./../' + idAlumno) })
    }

    /**
     * Inicia la creación del alumno junto con los datos bancarios si están presentes.
     * Esta función es un punto intermedio que invoca la creación de datos bancarios después de crear al alumno.
     * 
     * @param {Number} idAlumno ID del alumno recién creado.
     * @param {Object} values Valores del formulario incluyendo los datos bancarios.
     */
    const checkFieldsBeforeCreateDomiciliacion = (idAlumno, values) => {
        createDatosBancarios(idAlumno, values)
    }

    /**
     * Lógica principal para la creación del alumno.
     * Valida condiciones específicas como la edad del alumno y la presencia de responsables antes de proceder.
     * 
     * @param {Object} values Valores del formulario para la creación del alumno.
     */
    const createAlumno = (values) => {
        // Filtro inicial de responsables basado en la creación o selección.
        const responsablesFiltered = responsables.filter(responsable => responsable.creating || responsable.selectedFromList)

        // Validación específica para menores de edad sin responsables asociados.
        if (esMenorDeEdad && responsablesFiltered.length === 0 && responsables.length === 0) {
            updateAlert('Se debe crear o asociar un responsable a este alumno.', 'warning')
            return
        }

        headerRef.current.button.setLoading(true)

        // Creación del alumno y posterior manejo de responsables y datos bancarios.
        request.createAlumno(values, async (res) => {
            const idAlumno = res.alumno.id

            try {
                //Si el alumno es menor de edad, se crean los responsables con sus relaciones
                // 11/04/2024 - Se modifica la lógica debido a que ya los responsables pueden ser asignados aunque el alumno sea mayor de edad
                createResponsablesAndRelations(idAlumno)

                // Comprueba si hay datos bancarios rellenos, para crearlos
                checkFieldsBeforeCreateDomiciliacion(idAlumno, values)

                // Si todo ha ido bien, muestra el mensaje de éxito y redirige
                handleSuccessRequest(idAlumno)
            } catch (err) {
                handleError(err)
                headerRef.current.button.setLoading(false)
            }

            /**
             * 11/04/2024
             * //TODO: Al crear un alumno asociandole un responsable, redirige al single con la info del repsonsalbe correctamente, pero al pulsar en Ediatar, desaparece, no hay responsables asociados a ese alumno. res.alumno viene con el array de responsables vacio.
             * 
             * Añadir el alumno recien creado al listado de items de ListContext (EmpleadoListContext)
             */
            // addItem(res.alumno)
        }, (err) => {
            handleError(err)
            headerRef.current.button.setLoading(false)
        })
    }

    /**
     * Las siguientes funciones, `createResponsablesAndRelations`, `createResponsable`, `createRelation`, y `createDatosBancarios`,
     * manejan respectivamente la creación de responsables y sus relaciones con el alumno, y la asociación de datos bancarios.
     */

    const createResponsablesAndRelations = async (idAlumno) => {
        const responsablesSelectedFromList = responsables.filter(responsable => responsable.creating && responsable.selectedFromList)
        const responsablesCreated = responsables.filter(responsable => responsable.creating && !responsable.selectedFromList)

        try {
            if (responsablesSelectedFromList.length > 0) {
                // Si se ha seleccionado un responsable de la lista de responsables existentes, crea solo la relacion
                const createPromises = responsablesSelectedFromList.map(responsableData => createRelation(responsableData, idAlumno))

                // Esperamos a que todos los responsables se creen
                await Promise.all(createPromises)
            }
            if (responsablesCreated.length > 0) {
                if (responsablesCreated.length !== 0) {
                    // Inicializar un array para guardar los resultados junto con el tipo_relacion_id
                    const responsablesConRelaciones = []

                    // Crear los responsables y guardar en el array junto con su tipo_relacion_id
                    // Esto se hace debido a que el valor de tipo de relacion se encuentra en el array de responsablesFiltered y no en responsablesResults,
                    // por lo que la llamada a createRelation falla.
                    for (const responsableData of responsablesCreated) {
                        const createResult = await createResponsable(responsableData)
                        responsablesConRelaciones.push({
                            responsableId: createResult.responsable.id, // Asegúrate de que este es el camino correcto para acceder al ID en tu objeto de respuesta
                            tipoRelacionId: responsableData.tipo_relacion_id
                        })
                    }

                    // Se crean las relaciones después de crear los responsables.
                    for (const { responsableId, tipoRelacionId } of responsablesConRelaciones)
                        await createRelation([], idAlumno, responsableId, tipoRelacionId)
                }
            }
        } catch (err) {
            handleError(err)
            headerRef.current.button.setLoading(false)
        }

    }

    const createResponsable = async (responsableData) => {
        const formData = new FormData()
        formData.append("nombre", responsableData.nombre.toUpperCase())
        formData.append("apellido1", responsableData.apellido1.toUpperCase())
        formData.append("apellido2", responsableData.apellido2?.toUpperCase() ?? null)
        formData.append("email", responsableData.email)
        formData.append("numero_documento", responsableData.numero_documento.toUpperCase())
        formData.append("tipo_documento_id", responsableData.tipo_documento_id)
        formData.append("profesion", responsableData.profesion.toUpperCase())
        formData.append("telefono_movil", responsableData.telefono_movil)
        formData.append("telefono_trabajo", responsableData.telefono_trabajo)
        formData.append("telefono_otros", responsableData.telefono_otros)

        return new Promise((resolve, reject) => {
            new RequestResponsable().create(formData, resolve, reject)
        })
    }

    const createRelation = async (responsableData, idAlumno, idResponsable = null, tipoRelacionId = null) => {
        const formData = new FormData()
        formData.append("responsable_id", responsableData.id ?? idResponsable)
        formData.append("alumno_id", idAlumno)
        formData.append("tipo_relacion_id", responsableData.tipo_relacion_id ?? tipoRelacionId)

        return new Promise((resolve, reject) => {
            new RequestAlumnoResponsable().create(formData, resolve, reject)
        })
    }

    const createDatosBancarios = async (idAlumno, values) => {
        return new Promise((resolve, reject) => {
            request.createDatosBancarios(idAlumno, values, resolve, reject)
        })
    }

    return { createAlumno, headerRef, updateChanges }
}