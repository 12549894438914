import { Grid, Stack } from "@mui/material"
import { Text, H3 } from "components/texts"
import { Line } from 'components/data_display';
import SkeletonPersonalEmpleado from './skeleton'

/**
 * Formulario con los datos del curriculum del Empleado
 * 
 */
export default function DataPersonalEmpleado({ empleado }) {
    return (
        <Grid container p={3}>
            {!empleado ?
                <SkeletonPersonalEmpleado />
                : (
                    <>
                        <Grid container item xs={12} px={4} mt={2} >

                            <Grid item xs={3} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Documento Identificador</Text>
                                    <Text>{empleado?.numero_documento ?? ""}</Text>
                                </Stack>

                                <Grid container direction="row" spacing={2}>

                                    <Grid item >
                                        <Text fontWeight={"bold"}>Tipo Documento</Text>
                                    </Grid>

                                    <Grid item>
                                        <Text>{empleado?.tipo_documento ?? ""}</Text>
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid item xs={2} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Nombre</Text>
                                    <Text>{empleado?.nombre ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2.4} mt={2} >
                                <Stack>
                                    <Text fontWeight={"bold"}>1. Apellido</Text>
                                    <Text>{empleado?.apellido1 ?? ""}</Text>
                                </Stack>
                            </Grid>
                            <Grid item xs={2.4} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>2. Apellido</Text>
                                    <Text>{empleado?.apellido2 ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Sexo</Text>
                                    <Text>{empleado?.sexo ?? ""}</Text>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                <Line />
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} px={4} mt={2} >

                            <Grid item xs={12}>
                                <H3>Datos de Nacimiento</H3>
                            </Grid>

                            <Grid item xs={3} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Fecha</Text>
                                    <Text>{empleado?.fecha_nacimiento ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Municipio</Text>
                                    <Text>{empleado?.municipio_nacimiento ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2.4} mt={2} >
                                <Stack>
                                    <Text fontWeight={"bold"}>Provincia</Text>
                                    <Text>{empleado?.provincia ?? ""}</Text>
                                </Stack>
                            </Grid>
                            <Grid item xs={2.4} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>País</Text>
                                    <Text>{empleado?.pais_nacimiento ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <Line />
                            </Grid>

                        </Grid>


                        <Grid container item xs={12} px={4} mt={2} >
                            <Grid item xs={12}>
                                <H3>Otros datos</H3>
                            </Grid>

                            <Grid item xs={2} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Dirección</Text>
                                    <Text>{empleado?.direccion ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={1} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>C. Postal</Text>
                                    <Text>{empleado?.cod_postal ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Municipio</Text>
                                    <Text>{empleado?.municipio ?? ""}</Text>
                                </Stack>
                            </Grid>
                            <Grid item xs={2} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Provincia</Text>
                                    <Text>{empleado?.provincia ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2.5} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Correo electrónico Principal</Text>
                                    <Text>{empleado?.email ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2.5} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Correo electrónico Adicional</Text>
                                    <Text>{empleado?.email2 ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={2} mt={2} >
                                <Stack>
                                    <Text fontWeight={"bold"}>Teléfono</Text>
                                    <Text>{empleado?.telefono ?? ""}</Text>
                                </Stack>
                            </Grid>

                            <Grid item xs={1} mt={2}>
                                <Stack>
                                    <Text fontWeight={"bold"}>Móvil</Text>
                                    <Text>{empleado?.movil ?? ""}</Text>
                                </Stack>
                            </Grid>


                        </Grid>

                    </>
                )
            }
        </Grid>
    )
}