import React from 'react'
import { Stack } from '@mui/material'
import { H3, H1 } from 'components/texts'
import { Link } from 'components/data_display'
import { Button } from 'components/forms/components'
import { Edit as EditIcon } from '@mui/icons-material'

export default function HeaderRegistroActividad({ actividad }) {

  return (
    <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <Stack flexDirection={"row"} alignItems={"center"}>
            <H1 className="profile-id-text">{"#" + (actividad?.id ?? "0000")}</H1>
            <H3 className="mx-10">{actividad?.nombre ?? ""}</H3>
        </Stack>

        <Link to="editar">
            <Button variant={"outlined"} startIcon={<EditIcon />}>Editar</Button>
        </Link>
    </Stack>
  )
}
