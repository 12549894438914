import React from 'react'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary, Box } from '@mui/material'

/**
 * Esta es la plantilla para cuando se busque por los datos del domicilio del alumno
 */
export default function Accordion({
    mx=8,
    my=2,
    header=null,
    children = null,
    defaultExpanded = false
}) {
    
    return (
        <Box my={my} mx={mx}>
            <MuiAccordion defaultExpanded={defaultExpanded}>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" className='border-bottom align-items-center' expandIcon={<ExpandMoreIcon />}>
                    {header}
                </AccordionSummary>
                <AccordionDetails>
                    {children}
                </AccordionDetails>
            </MuiAccordion>
        </Box>
    )
}
