import React, { useRef, useEffect, useState } from 'react'
import { Grid, Stack } from "@mui/material";
import { Checkbox, Button, Input } from 'components/forms/components';
import { H3, Text } from 'components/texts';
import { ItemsSelect } from 'components/forms/components/Select/custom'
import Modal from 'components/modals/Modal'


export default function FormaDePago({ empleado, register, errors, setValue, getValues, isSingle }) {
    const [open, setOpen] = useState(false)

    const modalRef = useRef()

    useEffect(() => {
        if (open) {
            modalRef.current.open()
            setOpen(false)
        }
    }, [open])

    return (
        <Grid container mt={2}>

            <Grid item xs={3}>
                <Stack>
                    <Checkbox name={"jornada_parcial"} disabled={isSingle} label={"Jornada Parcial"} register={register} errors={errors} color={"success"}
                        defaultChecked={empleado?.alumno_colectivo ? true : false} />
                </Stack>
            </Grid>

            <Grid item xs={3}>
                <Stack>
                    <Checkbox name={"retener_sueldo"} disabled={isSingle} label={"Retener Sueldo"} register={register} errors={errors} color={"error"}
                        defaultChecked={empleado?.alumno_colectivo ? true : false} />
                </Stack>
            </Grid>

            <Grid item xs={6} container alignItems="center" direction="column">
                <Text>Materia que se imparte: </Text>
                <Grid item container justifyContent="center">
                    <Grid item>
                        <Stack>
                            <Checkbox
                            disabled={isSingle}
                                name={"idiomas"}
                                label={"Idiomas"}
                                register={register}
                                 errors={errors}
                                color={"success"}
                                defaultChecked={empleado?.idiomas ? true : false}
                            />
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack>
                            <Checkbox
                            disabled={isSingle}
                                name={"otras_actividades"}
                                label={"Otras Actividades"}
                                register={register}
                                 errors={errors}
                                color={"error"}
                                defaultChecked={empleado?.otras_actividades ? true : false}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={3} mt={2} mr={2}>
                <ItemsSelect
                disabled={isSingle}
                    name={"id_forma_pago"}
                    keyValue={"forma_pago"}
                    register={register}
                    errors={errors} />
            </Grid>

            <Grid item xs={3} mt={2}>
                <ItemsSelect
                disabled={isSingle}
                    name={"id_tipo_talon"}
                    keyValue={"tipo_talon"}
                    label={'Forma del talon'}
                    register={register}
                    errors={errors} />

            </Grid>

            <Grid container alignItems="center" mt={4} >

                <Grid item xs={4} mr={2}>
                    <Input className="w-100" label="Nº De Cuenta" register={register} errors={errors} name={"num_cuenta"}
                    defaultValue={empleado?.nombre ?? ""} uppercase disabled={isSingle}/>
                </Grid>

                <Grid item xs={3}>
                    <Button onClick={() => setOpen(true)} disabled={isSingle}>
                        Modificar
                    </Button>

                    {/* MODAL PARA ABRIR LA MODIFICACIÓN DE CUENTA BANCARIA */}
                    <Modal ref={modalRef} title={"Datos bancarios del Alumno"}>

                        <Grid container p={2} direction="column" flexDirection={'row'}>

                            <Grid item xs={12} mb={4}>
                                <H3 style={{ color: 'blue' }}>Nombre del Alumno</H3>
                            </Grid>

                            <Grid item xs={12} mb={4}>
                                <Text>Datos de la domiciliación:</Text>
                            </Grid>

                            <Grid item xs={2}>
                                <Input disabled={isSingle} className="w-80" label="Referencia" register={register} errors={errors} name={"referencia"} defaultValue={empleado?.referencia ?? ""} uppercase />
                            </Grid>

                            <Grid item xs={2}>
                                select cuentas
                            </Grid>

                            <Grid item xs={2}>
                                <Input className="w-80" label="Nº De Cuenta" register={register} errors={errors} disabled={isSingle}
                                name={"num_cuenta"} defaultValue={empleado?.nombre ?? ""} uppercase />
                            </Grid>

                            <Grid item xs={2}>
                                <Input className="w-80" label="IBAN" register={register} errors={errors} name={"iban"} 
                                defaultValue={empleado?.nombre ?? ""} uppercase disabled={isSingle}/>
                            </Grid>

                            <Grid item container justifyContent="end" p={2} mt={4}>

                                <Grid item mr={4}>
                                    <Stack>
                                        <Button>Aceptar</Button>
                                    </Stack>
                                </Grid>

                                <Grid item>
                                    <Stack>
                                        <Button color={'cancel'}>Cancelar</Button>
                                    </Stack>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Modal>

                </Grid>

                <Grid item xs={3}>
                    <Input disabled={isSingle} className="w-100" label="Referencia" register={register} errors={errors} name={"referencia"} defaultValue={empleado?.referencia ?? ""} uppercase />
                </Grid>

            </Grid>

            <Grid item xs={12} mt={4}>
                <Input placeholder={"Comentarios..."} register={register} errors={errors} disabled={isSingle}
                    className="w-100" name={"comentarios"} defaultValue={empleado?.observaciones_grupo ?? null} multiline minRows={6} maxRows={10} uppercase />
            </Grid>
        </Grid>
    )
}