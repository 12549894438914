import { useEffect, useRef, useState } from "react";
import { Button } from 'components/forms/components'
import Skeleton from 'components/skeletons/alumnos/alumno/responsableDataSkeleton'
import { Alert, Avatar, Grid, Paper, Tooltip, Box } from '@mui/material'
import { Add as AddIcon, OpenInNewOff as RemoveAsociationIcon, SyncAlt as ChangeResponsable } from '@mui/icons-material'
import { H3, Text } from 'components/texts'
import { ASSETS } from "constants/Assets";
import AddResponsableModalEdit from "components/modals/custom/responsable/AddResponsableEdit";
import { ChangeRelationModal, DeleteRelacionModal } from "components/modals";
import { useImageLoader } from "hooks";
import { DEFAULT_IMAGES_URL } from "constants/DefaultImagesUR";

export default function ResponsableData({ setIsResponsableChosen, alumno, reload, register, getValues, setValue, errors, reset, responsables, setResponsables }) {
  const [open, setOpen] = useState(false)
  const [openChangeRelationModal, setOpenChangeRelationModal] = useState(false)
  const [responsableToChangeRelation, setResponsableToChangeRelation] = useState(null)
  const [alumnoToChangeRelation, setAlumnoToChangeRelation] = useState(null)

  const addResponsableModalEditRef = useRef()
  const deleteRelacionModal = useRef()
  const changeRelationModal = useRef()

  useEffect(() => {
    setResponsables(responsables)
  }, [responsables])

  const handleOpenChangeRelationModal = (responsable) => {
    setOpenChangeRelationModal(true)
    setResponsableToChangeRelation(responsable)
    setAlumnoToChangeRelation(alumno)
  }

  return (
    <Grid container>
      <Grid item xs={6} display={"flex"} alignItems={"center"}>
        <H3 className={"m-0"}>Tutores:</H3>
      </Grid>

      <Grid item xs={6} display={"flex"} justifyContent={"end"} alignItems={"center"}>
        {responsables ? (
          <Button variant={"outlined"} startIcon={<AddIcon />} onClick={() => { setOpen(true) }}>
            Crear tutor
          </Button>) : null}
      </Grid>

      {!responsables ? <Skeleton /> : (
        <Grid container mt={2} spacing={2}>
          {responsables.map(item => (
            <ResponsableItem
              key={Math.random()}
              responsable={item}
              setResponsables={setResponsables}
              openChangeRelationModal={handleOpenChangeRelationModal}
              openDeleteRelacionModal={(responsable) => deleteRelacionModal.current.open(responsable, alumno)} />
          ))}

          {responsables.length == 0 ?
            <Grid item xs={12}>
              <Alert severity="info" className='mt-10 w-100'>
                <H3 className="standard">Este alumno no tiene ningún tutor.</H3>
              </Alert>
              <Alert severity="warning" className='mt-10 w-100'>
                <H3 className="standard">Para los alumnos menores de edad, será necesario asignarles obligatoriamente un tutor.</H3>
              </Alert>
            </Grid> : null}
        </Grid>
      )}

      <AddResponsableModalEdit
        register={register}
        getValues={getValues}
        setValue={setValue}
        errors={errors}
        reset={reset}
        setIsResponsableChosen={setIsResponsableChosen}
        setResponsables={setResponsables}
        addResponsableModalEditRef={addResponsableModalEditRef}
        open={open}
        setOpen={setOpen} /> {/* alumno={this.props.alumno} onCreateCallback={this.props.reload} */}

      <DeleteRelacionModal ref={deleteRelacionModal} onDeleteCallback={reload} />

      <ChangeRelationModal
        open={openChangeRelationModal}
        setOpen={setOpenChangeRelationModal}
        responsableToChangeRelation={responsableToChangeRelation}
        alumnoToChangeRelation={alumnoToChangeRelation}
        changeRelationModal={changeRelationModal}
        reload={reload} />
    </Grid>
  )

}

function ResponsableItem({ responsable, setResponsables, openDeleteRelacionModal, openChangeRelationModal }) {
  const { imageSrc } = useImageLoader(responsable, 'foto_logo', DEFAULT_IMAGES_URL.responsable, true)

  const handleDelete = () => {
    if (!responsable.creating) {
      //Si el responsable es uno que viene del servidor, abre el modal y realiza la pedition de delete relation
      openDeleteRelacionModal(responsable)
      return
    }

    //Si es un responsable que acabo de crear yo, lo elimino del estado
    setResponsables(currentResponsables => currentResponsables.filter(responsableItem => responsableItem !== responsable))
  }

  const handleChangeRelation = () =>
    openChangeRelationModal(responsable)

  return (
    <Grid item xs={6} xl={4}>
      <Paper elevation={0} sx={{ px: 3, pt: 2, pb: 3, borderRadius: 2, border: "1px solid var(--greyLight)" }}>
        <Box justifyContent={"end"} alignItems={"center"} display={"flex"}>
          {!responsable.creating ?
            <Tooltip title={<Text color={"black"}>Cambiar relación</Text>}>
              <div onClick={handleChangeRelation}>
                <ChangeResponsable color="standard" className={"clickable mx-2"} />
              </div>
            </Tooltip> : null}

          <Tooltip title={<Text color={"black"}>Borrar responsable</Text>}>
            <div onClick={handleDelete}>
              <RemoveAsociationIcon color={"error"} className={"clickable mx-2"} />
            </div>
          </Tooltip>
        </Box>

        <Box alignItems={"center"} display={"flex"}>
          {/* <Avatar alt={responsable.nombre ?? ""} src={`${ASSETS.images + (responsable.foto_logo ? responsable.foto_logo : 'private/responsable/perfiles/0/default.png')}`} sx={{ width: 75, height: 75 }} /> */}
          <Avatar alt={responsable.nombre ?? ""} src={imageSrc} sx={{ width: 75, height: 75 }} />

          <Box mx={3} display={"flex"} flexDirection={"column"}>
            <Text>{(responsable.nombre ?? "") + " " + (responsable.apellido1 ?? "") + " " + (responsable.apellido2 ?? "")}</Text>
            <Text>{responsable.email ?? ""}</Text>
            {!responsable.creating ?
              <Text>{(responsable.numero_documento ?? "") + (" - ") + (responsable.tipo_documento?.nombre ?? "")}</Text> : <Text>{responsable.numero_documento ?? ""}</Text>}
            <Text>{(responsable.tipo_relacion ?? "")}</Text>
          </Box>
        </Box>

        {responsable.creating ?
          <>
            <Alert variant="outlined" severity="info" className='mt-2 w-100'>
              <Text>Este tutor aún no está confirmado. Al guardar cambios, los detalles del tutor se registrarán de forma segura.</Text>
            </Alert>
            <Alert variant="outlined" severity="warning" className='mt-2 w-100'>
              <Text>No podrás cambiar el tipo de relación hasta guardar los cambios.</Text>
            </Alert>
          </>
          : null}
      </Paper>
    </Grid>
  )
}