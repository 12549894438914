import { useEffect } from 'react';

/**
 * Hook personalizado para comprobar si existen campos 'sucios',
 * para controlar cuando mostrar el aviso de cambio de 'No se han guardado los cambios'
 * al intentar navear a otra parte de la aplicacion web
 * 
 * @param {Object} dirtyFields 
 * @param {Function} updateChanges 
 */
export default function useCheckDirtyFields(dirtyFields, updateChanges) {
    useEffect(() => {
        if (Object.keys(dirtyFields).length > 0)
            updateChanges(true)
    }, [Object.keys(dirtyFields).length])
}