import * as Yup from 'yup'

/**
 * Validación para los campos de libro,
 * de modo que cuando se selecciona el checkbox de Libro solicitado, hjace requeridos los campos de precio y fecha solicitado
 */
export const validation = {
    libro_solicitado: Yup.boolean(),
    fecha_peticion: Yup.date()
        .nullable()
        .when('libro_solicitado', {
            is: true, // Solo cuando libro_solicitado es true
            then: Yup.date().required('La fecha de solicitud es obligatoria'), // Hace el campo requerido
        }),
    precio: Yup.number()
        .nullable()
        .when('libro_solicitado', {
            is: true, // Solo cuando libro_solicitado es true
            then: Yup.number().required('El precio es obligatorio').positive('El precio debe ser un número positivo'),
            otherwise: Yup.number().nullable(),
        }),

    libro_asociado_solicitado: Yup.boolean(),
    fecha_asociado_peticion: Yup.date()
        .nullable()
        .when('libro_asociado_solicitado', {
            is: true, // Solo cuando libro_asociado_solicitado es true
            then: Yup.date().required('La fecha de solicitud del libro asociado es obligatoria'), // Hace el campo requerido
        }),
    precio_asociado: Yup.number()
        .nullable()
        .when('libro_asociado_solicitado', {
            is: true, // Solo cuando libro_asociado_solicitado es true
            then: Yup.number().required('El precio del libro asociado es obligatorio').positive('El precio debe ser un número positivo'),
        }),
}

export const validationSchema = Yup.object().shape(validation)