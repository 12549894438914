import { createReducer } from '@reduxjs/toolkit'
import { updateUser, deleteUser } from '../actions/userActions'

const initialState = {
    user:        null,
    permissions: [],
    roles:       [],
    token:       null,
}

const userReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(updateUser, (state, action) => {
            state.user        = action.payload.user
            state.permissions = action.payload.permissions
            state.roles       = action.payload.roles
            state.token       = action.payload.token
        })
        .addCase(deleteUser, (state, action) => {
            return state = initialState
        })
})

export default userReducer