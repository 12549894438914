import React, { Component } from 'react'

export default class Footer extends Component {

  /**
   * Render
   * 
   * @returns {Component}
   */
  render() {
    return (
        <div className="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6" id="kt_app_sidebar_footer">

        </div>
    )
  }
}
