import { EmpleadoListContext } from "contexts/employee/ListContext"
import { useAlert } from "hooks"
import { useContext, useEffect, useState } from "react"

/**
 * Hook `useWrapperLogic` encapsula la lógica para recuperar y gestionar la información de un alumno específico.
 * Se utiliza en el contexto de empleados para aprovechar un estado global y facilitar la interacción con la API.
 * 
 * @param {Object} requests - Objeto que contiene métodos de llamadas a la API.
 * @param {Object} navigationParams - Parámetros de navegación, incluyendo el ID del alumno.
 * @returns {Object} - Retorna un conjunto de estados y funciones para interactuar con la información del alumno.
 */
export default function useWrapperLogic(requests, navigationParams) {
    const { items, updateItem } = useContext(EmpleadoListContext)

    const [alumno, setAlumno] = useState(null)
    const [id_alumno, setIdAlumno] = useState(navigationParams.id_alumno)

    const updateAlert = useAlert()

    /**
     * Función `getAlumno` intenta recuperar la información del alumno desde el contexto global o desde el servidor.
     * 
     * @param {boolean} getFromServer - Indica si se debe forzar la recuperación de datos desde el servidor.
     */
    const getAlumno = (getFromServer = false) => {
        const item = items.find(item => item?.id == id_alumno)
        if (items.length == 0 || !item || getFromServer) {
            requests.get(id_alumno, (alumno) => {
                setAlumno(alumno)
                updateItem(alumno)
            }, () => updateAlert("No se ha podido recuperar los datos del alumno."))
        } else {
            setAlumno(item)
        }
    }

    useEffect(getAlumno, [id_alumno])

    return { updateAlert, id_alumno, setIdAlumno, alumno, setAlumno, getAlumno }
}