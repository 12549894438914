
import React from 'react'
import { AlumnoData, IncidenciaData } from './parts'
import { RequestIncidencia } from 'objects/requests'
import { Container, Paper, Stack } from '@mui/material'
import { useParams } from 'react-router-dom'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
import { useIncidenciaSingleLogic } from 'hooks'

/**
 * Componente `SingleIncidenciaAlumno` para mostrar los detalles de una incidencia específica de un alumno.
 * Utiliza el hook `useLogic` para cargar y gestionar los datos de la incidencia.
 * 
 * @returns {JSX.Element} Estructura del componente con los detalles de la incidencia y el alumno asociado.
 */
export default function SingleIncidenciaAlumno() {
  const navigationParams = useParams()

  const { id_incidencia, incidencia, setIncidencia, updateId } = useIncidenciaSingleLogic(new RequestIncidencia(), navigationParams)

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>

      <Stack direction="row" justifyContent={"space-between"} marginX={8}>
        <BreadcrumbsList mx={0} breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../../", label: "Alumnos" },
          { to: "./../", label: "Incidencias" },
          { to: "./", label: "Incidencia #" + (id_incidencia ?? "0000"), actualItem: true },
        ]} />

        <MoveButtons
          actualItemId={id_incidencia}
          beforeFetchCallback={() => setIncidencia(null)}
          onUpdateActualItem={updateId} />
      </Stack>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>

        <AlumnoData incidencia={incidencia} />

        <Line className={"my-10"} />

        <IncidenciaData incidencia={incidencia} />

      </Paper>
    </Container>
  )
}
