import RequestMaster from '../RequestMaster'

export default class RequestNivelLibro extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/nivel-libro'
    }

    /**
     * Devuelve todos los colegios
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    createMultiple(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "multiple"
        this.post(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Devuelve todos los colegios
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    updateMultiple(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "multiple"
        this.put(path, params, callback, callbackError, callbackFinally)
    }
}
