import { useForm } from "react-hook-form"
import Statics from "objects/common/Statics"
import { useNavigate } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import { useAlert, useCheckDirtyFields } from "hooks"
import { ChangesContext } from "contexts/ChangesContext"
import { useContext, useEffect, useRef, useState } from "react"
import { validationSchema } from 'components/forms/display/actividades-colegio/DataForm/validation.js'

/**
 * Hook personalizado para manejar la lógica del formulario de creación de actividades.
 * Utiliza react-hook-form para la gestión de formularios y yup para la validación de esquemas.
 * Incluye navegación programática y manejo de estados de montaje y cambios en el formulario.
 * 
 * @returns {Object} Objeto con estados y funciones para el formulario.
 */
export default function useColegioActividadCreateLogic({ requests }) {
    const updateAlert = useAlert()

    const { register, handleSubmit, formState: { errors, dirtyFields }, getValues } = useForm({ resolver: yupResolver(validationSchema) })

    const navigate = useNavigate()

    const headerRef = useRef()

    const [isMounted, setIsMounted] = useState(false)

    const { updateChanges } = useContext(ChangesContext)

    useEffect(() => setIsMounted(true), [])

    useCheckDirtyFields(dirtyFields, updateChanges)

    /**
     * Función para crear una nueva actividad. Realiza una petición a la API y maneja la respuesta.
     * En caso de éxito, redirige al usuario a la página de edición de la actividad recién creada.
     */
    const createActividad = () => {
        headerRef.current.button.setLoading(true)

        requests.createActividad(getValues, async (res) => {
            updateAlert('Se ha creado la actividad correctamente. Redirigiendo a la pagina de edición...', 'success')
            updateChanges(false)

            await Statics.delay(1500).then(() => { navigate('./../' + res.actividad.id + "/editar") })
        }, (res) => {
            headerRef.current.button.setLoading(false)
            updateAlert(res)
        })
    }
    return { isMounted, errors, headerRef, register, handleSubmit, createActividad }
}