import { COLOR } from 'theme/Colors.js'
import { H3, Text } from 'components/texts'
import React, { useState, useEffect, useContext } from 'react'
import { CursoEscolarSelect, ItemsSelect, MesSelect } from 'components/forms/components/Select/custom'
import { Grid, Stack } from '@mui/material'
import { RequestCondicionColegio } from 'objects/requests'
import Statics from 'objects/common/Statics'

export default function SearchFormPagosColegio({ register, errors, setValue }) {

    const [colegioID,      setColegioID]      = useState(null)
    const [cursoEscolarID, setCursoEscolarID] = useState(null)
    const [condicion,      setCondicion]      = useState(null)

    useEffect(() => {
        if(cursoEscolarID && colegioID)
            getCondicionColegio()
    }, [cursoEscolarID, colegioID])

    const getCondicionColegio = () => {
        const params = { colegio_id: colegioID, curso_escolar_id: cursoEscolarID }
        new RequestCondicionColegio().search(params, (res) => {
            if(res.items?.data?.[0])
                setCondicion(res.items.data[0])
        }, () => setCondicion(null))
    }

    return (
        <Grid container spacing={2} p={4}>
            <Grid item xs={4}>
                <ItemsSelect name={"colegio_id"} keyValue={"colegio"} register={register} errors={errors} searcheable onChange={({target}) => setColegioID(target.value)}/>
            </Grid>

            <Grid item xs={4}>
                <CursoEscolarSelect name={"curso_escolar_id"} register={register} errors={errors} onChange={({target}) => setCursoEscolarID(target.value)} defaultValue={null} setValue={setValue} searcheable />
            </Grid>

            <Grid item xs={4}>
                <MesSelect register={register} errors={errors} />
            </Grid>

            {condicion ?
                <Grid item xs={12}>
                    <Stack display="flex" flexDirection="row" columnGap={2}>
                        <Text>Forma de las condiciones:</Text>
                        <Text fontWeight={"bold"}>{condicion?.tipo_condicion_colegio?.valor ?? ""}</Text>
                    </Stack>

                    <Stack display="flex" flexDirection="row" columnGap={2}>
                        <Text>Condiciones (€):</Text>
                        <Text fontWeight={"bold"}>{condicion?.condiciones ? Statics.changeDotAndComma(condicion.condiciones) : ""}</Text>
                    </Stack>
                </Grid>
                : null
            }
        </Grid>
    )
}