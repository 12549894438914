import { RequestInscripcionAcademia } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
    * Devuelve los datos de la inscripción
    * 
    * @param {Int}      id 
    * @param {Function} callbackOk 
    * @param {Function} callbackError 
    */
    getInscripcion(id, callbackOk = () => { }, callbackError = () => { }) {
        const params = {}
        params.appends = [
            'actividad', 
            'grupo_academia', 
            'forma_pago', 
            'forma_recogida',
            'forma_conocimiento', 
            'motivo_baja', 
            'operador', 
            'alumno', 
            'curso_escolar', 
            'preferencia_horario', 
            'tipo_matricula',
            'tipo_pago', 
            'pagos'
        ]
        // new RequestInscripcionAcademia().get(id, params, (res) => {
        //     res ? callbackOk(res) : callbackError(res)
        // })

        new RequestInscripcionAcademia().get(id, params, callbackOk, callbackError)
    }
}

