import { ClaseParticularData, ClaseParticularLibros, ClaseParticularPagos, ClaseParticularAnotaciones, ClaseParticularHorarios } from "./parts/index.js";
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { Box, Container, Paper, Stack, Tab, Tabs } from "@mui/material"
import { BreadcrumbsList, MoveButtons } from 'components/data_display'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react"
import Requests from './Requests/Requests.js'
import { Text } from "components/texts";
import { TabContext } from "@mui/lab";
import { SingleHeader } from './parts'
import { COLOR } from "theme/Colors";

/**
 * Pagina del single de clase particular
 * 
 * @returns {JSX.Element}
 */
export default function SingleClasesParticulares() {

    //PARAMS
    const { id_clase_particular } = useParams()

    const { items } = useContext(EmpleadoListContext)

    //NAVIGATE
    const navigate = useNavigate()

    //STATE
    const [tab, setTab] = useState("1")
    const [claseParticular, setClaseParticular] = useState(null)
    const [idClaseParticular, setIdClaseParticular] = useState(id_clase_particular)

    //REQUESTS
    const requests = new Requests()

    const getClaseParticular = () => {
        const item = items.find(item => item?.id == idClaseParticular)
        if (items.length == 0 || !item) {
            requests.getClaseParticular(idClaseParticular, setClaseParticular, (res) => enqueueSnackbar(<Text>{"No se ha podido cargar la clase particular: " + res}</Text>, { variant: "error" }))
        } else {
            setClaseParticular(item)
        }
    }

    useEffect(getClaseParticular, [idClaseParticular])

    const updateId = (id) => {
        navigate(`../clases-particulares/${id}`);
        setIdClaseParticular(id);
    }

    return (
        <Container maxWidth={false} sx={{ mt: 5 }}>
            <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} marginX={8}>
                <BreadcrumbsList mx={0} breadcrumbsList={[
                    { to: "/", label: "Inicio" },
                    { to: "./../", label: "Clases particulares" },
                    { to: "./", label: "Clase particular #" + idClaseParticular, actualItem: true },
                ]} />

                <MoveButtons
                    actualItemId={idClaseParticular}
                    beforeFetchCallback={() => setClaseParticular(null)}
                    onUpdateActualItem={updateId} />
            </Stack>

            <Paper elevation={2} sx={{ mx: 8, mt: 2, py: 2 }}>
                <TabContext value={tab}>
                    {claseParticular ? <SingleHeader claseParticular={claseParticular} navigate={navigate} /> : null}

                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2} mt={2}>
                        <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                            <Tab label={<Text fontWeight={tab == "1" ? "bold" : null} color={tab == "1" ? COLOR.standard.main : null}>DATOS DE LA INSCRIPCIÓN</Text>} value={"1"} />
                            <Tab label={<Text fontWeight={tab == "2" ? "bold" : null} color={tab == "2" ? COLOR.standard.main : null}>DATOS DE LOS PAGOS</Text>} value={"2"} />
                            <Tab label={<Text fontWeight={tab == "3" ? "bold" : null} color={tab == "3" ? COLOR.standard.main : null}>LIBROS</Text>} value={"3"} />
                            <Tab label={<Text fontWeight={tab == "4" ? "bold" : null} color={tab == "4" ? COLOR.standard.main : null}>ANOTACIONES</Text>} value={"4"} />
                            <Tab label={<Text fontWeight={tab == "5" ? "bold" : null} color={tab == "5" ? COLOR.standard.main : null}>HORARIOS</Text>} value={"5"} />
                        </Tabs>
                    </Box>

                    <TabPanel value={"1"}>
                        <ClaseParticularData claseParticular={claseParticular} navigate={navigate} />
                    </TabPanel>

                    <TabPanel value={"2"}>
                        <ClaseParticularPagos claseParticular={claseParticular} navigate={navigate} />
                    </TabPanel>

                    <TabPanel value={"3"}>
                        <ClaseParticularLibros claseParticular={claseParticular} navigate={navigate} />
                    </TabPanel>

                    <TabPanel value={"4"}>
                        <ClaseParticularAnotaciones claseParticular={claseParticular} navigate={navigate} />
                    </TabPanel>

                    <TabPanel value={"5"}>
                        <ClaseParticularHorarios claseParticular={claseParticular} navigate={navigate} />
                    </TabPanel>

                </TabContext>
            </Paper>

            <SnackbarProvider autoHideDuration={3000} />
        </Container>
    )
}