import { useContext, useEffect, useState } from "react"
import { EmpleadoListContext } from "contexts/employee/ListContext"

/**
 * Hook personalizado para manejar la lógica de visualización y filtrado del listado de inscripciones.
 * Permite interactuar con el contexto de empleados y controlar el estado de la tabla de datos.
 */
export default function useAcademiaInscripcionListLogic({ basicColumns, grupoColumns, testNivelColumns, empresaColumns }) {
    const [loadingTable, setLoadingTable] = useState(false)

    const [columns, setColumns] = useState(basicColumns)

    const { items, rowCount, params, update } = useContext(EmpleadoListContext)

    useEffect(() => {
        setLoadingTable(false)
    }, [items])

    /**
     * Actualiza los datos de la tabla y el contexto global con los resultados obtenidos de la búsqueda.
     * 
     * @param {Array} data - Los datos obtenidos para llenar la tabla.
     * @param {number} rowCount - El número total de filas encontradas para la búsqueda realizada.
     * @param {number} page - La página actual de los resultados.
     * @param {Object} params - Los parámetros de búsqueda utilizados.
     */
    const updateTable = (newData, newRowCount, newPage, newParams) => update(newRowCount, newData, newParams, newPage)

    /**
     * Cambia las columnas de la tabla según el índice de búsqueda seleccionado.
     * 
     * @param {number} index - Índice que representa el tipo de búsqueda seleccionado.
     */
    const onChangeSearchIndex = (index) => {
        const map = {
            0: [],
            1: grupoColumns,
            2: testNivelColumns,
            3: empresaColumns,
            4: testNivelColumns
        }

        setColumns(basicColumns.concat(map[index]))
    }

    return { items, rowCount, params, setLoadingTable, updateTable, onChangeSearchIndex, columns, loadingTable }
}