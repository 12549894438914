import { Container, Paper } from '@mui/material'
import { BreadcrumbsList, Line } from 'components/data_display'
import { DataFormLibroGestionCobros } from 'components/forms/display'
import { Text } from 'components/texts'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { RequestAcreedor, RequestRemesa } from 'objects/requests'
import { CreateHeader } from 'pages/logged/admin/components'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

export default function GestionCobros() {

    const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm()

    const [cursoEscolar, setCursoEscolar] = useState(null)
    const [acreedor, setAcreedor] = useState({})

    const onChangeAcreedor = (acreedorId) => {
        new RequestAcreedor().show(acreedorId, new FormData(), (res) => {
            setAcreedor(res)
            setValue('acreedor_nombre', res.nombre)
            setValue('acreedor_nif', res.nif)
            setValue('acreedor_sufijo', res.sufijo)
            setValue('acreedor_iban', res.iban)
            setValue('acreedor_identificador', res.identificador)
        }, (err) => enqueueSnackbar(<Text>Ha habido un error al obtener el acreedor.</Text>, { variant: "error" }))
    }

    const saveCobro = () => {
        console.log('getValues()', getValues())
        const params = {
            remesa: 'Lib24-1',
            fecha: getValues('fecha'),
            descripcion: getValues('descripcion'),
        }
        new RequestRemesa().store(params, (res) => console.log('res', res), (err) => console.log('err', err))
    }

    return (
        <Container maxWidth={false} sx={{ mt: 5 }}>
            <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(saveCobro)}>
                <BreadcrumbsList breadcrumbsList={[
                    { to: "/", label: "Inicio" },
                    { to: "./../", label: "Libros" },
                    { to: "./", label: "Gestión de cobros", actualItem: true },
                ]} />

                <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
                    <CreateHeader
                        // ref={(ref) => this.header = ref}
                        title={"Pagos de libros"}
                        btnText={"Realizar"} />
                    <Line />

                    <DataFormLibroGestionCobros
                        // isMounted={this.state.isMounted}
                        register={register}
                        errors={errors}
                        setValue={setValue}
                        onChangeAcreedor={onChangeAcreedor}
                        acreedor={acreedor}
                        setAcreedor={setAcreedor}
                        setCursoEscolar={setCursoEscolar}
                    />
                </Paper>

                <SnackbarProvider autoHideDuration={3000} />
            </form>
        </Container>
    )
}