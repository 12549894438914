import * as Yup from 'yup'
import { validation as validationGeneral } from './GeneralDataForm/validation'
import { validation as validationPago } from './PagoDataForm/validation'
import { validation as validationHorario } from './HorariosDataForm/validation'
import { validation as validationLibro } from './LibroDataForm/validation'

export const validationSchema = Yup.object().shape({
    ...validationGeneral,
    ...validationPago,
    ...validationHorario,
    ...validationLibro
})