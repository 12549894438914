import { H3 } from 'components/texts'
import { Box, Paper } from '@mui/material'
import Container from '@mui/material/Container'
import { Search as SearchIcon } from '@mui/icons-material'
import { ListTableHeader } from 'pages/logged/admin/components'
import { useAlumnoDatosColegioListLogic } from 'hooks/index.js'
import { Accordion, BreadcrumbsList, Datagrid } from 'components/data_display'
import SearchFormDatosColegio, { search } from 'components/forms/display/datos-colegio/SearchForm'
import { basicColumns, columnsAlumnosColegios, columnsGrupoColegio, columnsGrupoCL } from './columns.js'

/**
 * keyColumns mapea identificadores de categorías de búsqueda a sus respectivas columnas de visualización.
 * Este objeto es esencial para configurar dinámicamente las columnas mostradas en el Datagrid basándose en la selección del usuario.
 * - "alumnos": Muestra columnas relevantes para inscripciones de alumnos en colegios.
 * - "colegios": Similar a "alumnos", enfocado en inscripciones a nivel de colegios.
 * - "grupos_colegio": Específico para grupos dentro de colegios, mostrando datos relevantes para esta categoría.
 * - "grupos_cl": Columnas configuradas para mostrar información sobre grupos de colectivos de aprendizaje.
 */
const keyColumns = {
  "alumnos": columnsAlumnosColegios,
  "colegios": columnsAlumnosColegios,
  "grupos_colegio": columnsGrupoColegio,
  "grupos_cl": columnsGrupoCL,
}

/**
 * Componente ListadoDatosColegio que renderiza la interfaz de usuario para el listado de datos de colegios.
 * Utiliza el hook useLogic para obtener datos y funciones necesarios para su operación, incluyendo:
 * - Renderizado de breadcrumbs para navegación.
 * - Un acordeón que contiene el formulario de búsqueda, permitiendo al usuario filtrar los datos mostrados.
 * - Un componente Datagrid que muestra los datos de colegios según los criterios de búsqueda, con paginación y ordenamiento.
 * 
 * @returns {JSX.Element}
 */
export default function ListadoDatosColegio() {
  const { items, rowCount, setLoadingTable, params, loadingTable, columns, updateTable, updateColumns } = useAlumnoDatosColegioListLogic({ keyColumns, basicColumns, columnsAlumnosColegios, columnsGrupoColegio, columnsGrupoCL })

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <BreadcrumbsList breadcrumbsList={[
        { to: "/", label: "Inicio" },
        { to: "../alumnos", label: "Alumnos" },
        { to: "./", label: "Datos en colegios", actualItem: true },
      ]} />

      <Accordion header={<Box display={"flex"} alignItems={"center"}><SearchIcon className={"mx-5"} /> <H3 className="my-0">Buscar datos en colegios</H3></Box>}>
        <SearchFormDatosColegio
          searchCallback={updateTable}
          onChangeSearchIndex={updateColumns}
          onLoadingCallback={setLoadingTable} />
      </Accordion>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
        <ListTableHeader rowCount={rowCount} params={params} keyValue={"datos-colegio"} />

        <Datagrid
          rows={items}
          columns={columns}
          loading={loadingTable}
          rowCount={rowCount}
          onPageChange={(page) => { search(page, () => setLoadingTable(true), updateTable) }}
          serverSide />
      </Paper>
    </Container>
  )
}