import { Link } from 'components/data_display'
import { Button } from 'components/forms/components'

export const basicColumns = [
    {
        field: "actions",
        type: 'actions',
        headerName: "Acciones",
        minWidth: 200,
        getActions: (params) => [<Link to={"" + params.row.id}><Button size={"small"}>Ver</Button></Link>, <Link to={"" + params.row.id + "/editar"}><Button size={"small"} variant={"outlined"}>Editar</Button></Link>]
    },

    { field: "id", headerName: "ID", maxWidth: 65, sortable: false, filterable: false },

    { field: "año", headerName: "Año", minWidth: 100, flex: 1, valueGetter: (params) => { return params.row.curso_escolar?.valor ?? "" }, sortable: false, filterable: false },
]

export const materiaColumns = [
    { field: "materia", headerName: "Materia", minWidth: 100, flex: 1, valueGetter: (params) => { return params.row.registro_actividad?.actividad?.valor ?? "" }, sortable: false, filterable: false },
]

export const alumnoColumns = [
    { field: "alumno", headerName: "Alumno", minWidth: 100, flex: 1, valueGetter: (params) => { return (params.row.alumno?.nombre + ' ' + params.row.alumno?.apellido1 + ' ' + params.row.alumno?.apellido2) ?? "" }, sortable: false, filterable: false },
]

export const profesorColumns = [
    { field: "profesor", headerName: "Profesor", minWidth: 100, flex: 1, valueGetter: (params) => { return params.row.profesor != null ? (params.row.profesor?.nombre + ' ' + params.row.profesor?.apellido1 + ' ' + params.row.profesor?.apellido2) : "Sin asignar"; }, sortable: false, filterable: false },
]

export const pendienteProfesorColumns = [
    { field: "lugar_clases", headerName: "Lugar Clases", minWidth: 100, flex: 1, valueGetter: (params) => { return params.row.lugar_clase?.valor ?? "" }, sortable: false, filterable: false },
    { field: "origen_prof", headerName: "Origen Prof.", minWidth: 100, flex: 1, valueGetter: (params) => { return params.row.origen_profesor?.valor ?? "" }, sortable: false, filterable: false },
    { field: "sexo_empleado", headerName: "Sexo Prof.", minWidth: 100, flex: 1, valueGetter: (params) => { return params.row.sexo?.valor ?? "" }, sortable: false, filterable: false },
]

export const empresaColumn = [
    { field: "empresa", headerName: "Empresa", minWidth: 100, flex: 1, valueGetter: (params) => { return params.row.empresa?.valor ?? "" }, sortable: false, filterable: false },
]


export const columns = {
    alumnos:    [...basicColumns, ...alumnoColumns, ...materiaColumns],
    materias:   [...basicColumns, ...materiaColumns, ...alumnoColumns],
    pendientes: [...basicColumns, ...materiaColumns, ...alumnoColumns],
    profesores: [...basicColumns, ...profesorColumns, ...alumnoColumns, ...materiaColumns],
    pendientes_profesor: [...basicColumns, ...materiaColumns, ...alumnoColumns, ...pendienteProfesorColumns],
    colectivos: [...basicColumns, ...alumnoColumns, ...empresaColumn],
}