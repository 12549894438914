import { Text } from 'components/texts'
import { useForm } from 'react-hook-form'
import Statics from 'objects/common/Statics'
import Requests from './Requests/Requests.js'
import { useNavigate } from "react-router-dom"
import { responses } from 'constants/Responses'
import { Container, Paper } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangesContext } from 'contexts/ChangesContext'
import { DataFormIncidencia } from 'components/forms/display'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { BreadcrumbsList, Line } from 'components/data_display'
import React, { useState, useRef, useEffect, useContext } from 'react'
import { CreateHeader, AlumnoSection } from 'pages/logged/admin/components'
import { validationSchema } from 'components/forms/display/incidencia/DataForm/validation.js'
import { EmpleadoListContext } from 'contexts/employee/ListContext.jsx'
import { useCheckDirtyFields, useAlert } from 'hooks'

/**
 * Pagina para crear las incidencias
 */
export default function CreateIncidencia() {
  const updateAlert = useAlert()

  const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, control, setValue } = useForm({ resolver: yupResolver(validationSchema) })
  const navigate = useNavigate()

  const headerRef = useRef()
  const alumnoSectionRef = useRef()

  const [isMounted, setIsMounted] = useState(false)

  const { updateChanges } = useContext(ChangesContext)
  const { addItem } = useContext(EmpleadoListContext)

  useEffect(() => setIsMounted(true), [])

  useCheckDirtyFields(dirtyFields, updateChanges)

  const onValid = () => {
    if (alumnoSectionRef.current.isValid())
      createIncidencia()
  }

  const onError = () => {
    alumnoSectionRef.current.isValid()
  }

  const createIncidencia = () => {
    headerRef.current.button.setLoading(true)

    new Requests().createIncidencia(getValues, alumnoSectionRef.current.getAlumno().id, async (res) => {
      const { id } = res[responses.incidencias.singleName]
      // enqueueSnackbar(<Text>Se ha creado la incidencia correctamente. Redirigiendo a la pagina de edición..</Text>, { variant: "success" })
      updateAlert('Se ha creado la incidencia correctamente. Redirigiendo a la pagina de edición..', 'success') //Añadido mensaje de error
      updateChanges(false)
      addItem(res[responses.incidencias.singleName])
      await Statics.delay(1500).then(() => { navigate('./../' + id) }) //Modificada la URL, para ir al single
    }, (res) => {
      headerRef.current.button.setLoading(false)
      // enqueueSnackbar(<Text>{res}</Text>, { variant: "error" })
      updateAlert({ res }, 'error') //Añadido mensaje de error
    })
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(onValid, onError)}>
        <BreadcrumbsList breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../../", label: "Alumnos" },
          { to: "./../", label: "Incidencias" },
          { to: "./", label: "Crear", actualItem: true },
        ]} />

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <CreateHeader
            ref={headerRef}
            title={"Crear incidencia"} />

          <Line />

          <AlumnoSection
            ref={alumnoSectionRef}
            title={"Escoge un alumno para crearle una incidencia: "}
            buttonText={"Elegir alumno para la nueva incidencia"}
            onSelect={() => updateChanges(true)} />

          <DataFormIncidencia
            setValue={setValue}
            register={register}
            errors={errors}
            isMounted={isMounted}
            hideButton />
        </Paper>

        <SnackbarProvider autoHideDuration={3000} />
      </form>
    </Container>
  )
}
