import React, { useEffect, useState, useContext } from 'react';
import { Box, Grid } from "@mui/material";
import { Datagrid } from 'components/data_display';
import { H3, Text } from 'components/texts';
import { CursoEscolarSelect, ItemsSelect } from 'components/forms/components/Select/custom';
import { COLOR } from 'theme/Colors.js'
import { Button } from 'components/forms/components';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import { CursoEscolarContext } from 'contexts/CursoEscolarContext';

const inputStyles = {
    padding: '7px 14px',
    border: '1px solid #ccc',
    borderRadius: '4px',
};

// Se ha hecho el Wrapper para validar el campo de curso escolar (que lance error cuando añada un registro sin haber seleccionado el curso escolar antes)
export default function Wrapper({ empleado, contratosProfesores, setValue, register, unregister, onChangeCursoEscolar }) {
    const methods = useForm()

    return (
        <FormProvider {...methods}>
            <ContratosProfesores empleado={empleado} contratosProfesores={contratosProfesores} setValue={setValue} register={register} unregister={unregister} />
        </FormProvider>
    )
}

function ContratosProfesores({ empleado, contratosProfesores, setValue, register, unregister }) {
    const { setError, clearErrors, formState: { errors } } = useFormContext()
    const { cursoEscolar, setCursoEscolar } = useContext(CursoEscolarContext);

    const [editedData, setEditedData] = useState(contratosProfesores || []);

    useEffect(() => {
        if (register) {
            register('contratosProfesores');
            setEditedData(contratosProfesores || []);
        }
    }, [contratosProfesores, register, unregister]);

    const addRow = () => {
        //Validacion del curso escolar
        if (!cursoEscolar) {
            setError('id_curso_escolar', {
                type: 'manual',
                message: 'Debes seleccionar un curso escolar antes de añadir contratos.',
            })
            enqueueSnackbar(<Text>Debes seleccionar un curso escolar para añadir un nuevo registro.</Text>, { variant: "error" })
            return
        }
        clearErrors('id_curso_escolar')

        const newRow = {
            id: editedData.length + 1,
            fecha_inicio: '',
            fecha_fin: '',
            cantidad: 0,
            retencion: 0,
            formaCond: 0,
            creating: true,
        };

        setEditedData([...editedData, newRow]);
        setValue('contratosProfesores', [...editedData, newRow]);
    };

    const handleCellEditCommit = (params) => {
        const { id, field, value } = params;
        const updatedData = editedData.map((row) => {
            if (row.id === id) {
                return { ...row, [field]: value };
            }
            return row;
        });

        setEditedData(updatedData);
        setValue('contratosProfesores', updatedData);
    };

    const handleCursoEscolarChangeInternal = (cursoEscolar) => {
        setCursoEscolar(cursoEscolar)
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: "fecha_inicio",
            headerName: "Fecha Inicio",
            maxWidth: 150,
            flex: 1,
            editable: true,
            renderCell: (params) => (
                <input
                    style={inputStyles}
                    type="date"
                    value={params.row.fecha_inicio}
                    onChange={(e) => handleCellEditCommit({ ...params, value: e.target.value, field: 'fecha_inicio' })}
                />
            ),
        },
        {
            field: "fecha_fin",
            headerName: "Fecha Fin",
            maxWidth: 150,
            flex: 1,
            editable: true,
            renderCell: (params) => (
                <input
                    style={inputStyles}
                    type="date"
                    value={params.row.fecha_fin}
                    onChange={(e) => handleCellEditCommit({ ...params, value: e.target.value, field: 'fecha_fin' })}
                />
            ),
        },
        {
            field: 'cantidad',
            headerName: 'Cantidad',
            type: 'number',
            width: 100,
            editable: true,
        },
        {
            field: 'retencion',
            headerName: 'Retención',
            type: 'number',
            width: 100,
            editable: true,
        },
        {
            field: 'formaCond',
            headerName: 'Forma Cond.',
            type: 'number',
            width: 200,
            flex: 1,
            editable: true,
            renderCell: (params) => (
                <ItemsSelect
                    name={"forma_condiciones"}
                    searcheable
                    keyValue={"forma_condiciones"}
                    register={register}
                    errors={errors}
                    onChange={(e) => handleCellEditCommit({ ...params, value: e.target.value, field: 'fecha_fin' })}
                />

            )
        },
    ];

    return (
        <Grid sx={{ width: '100%', mt: 2, display: 'flex', flexDirection: 'column' }}>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <Grid item container xs={4}>
                    <H3 >Cond. Económicas: </H3>
                    {contratosProfesores ? <Text>contrato</Text> : <Text> Sin contrato de profesor</Text>}
                    <CursoEscolarSelect
                        register={register}
                        label={"Curso escolar"}
                        name={"id_curso_escolar"}
                        onChange={({ target }) => handleCursoEscolarChangeInternal(target.value)}
                        defaultValue={null}
                        setValue={setValue}
                        searcheable
                    />
                </Grid>
            </Box>

            <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Button
                    onClick={addRow}
                    sx={{ maxWidth: '100px', mb: 2, backgroundColor: COLOR.standard.main }}>
                    Añadir
                </Button>
            </Box>


            <Grid item xs={12}>
                <Datagrid
                    rowHeight={38}
                    rows={editedData}
                    columns={columns}
                    onCellEditCommit={handleCellEditCommit}
                />
            </Grid>
        </Grid>
    );
}
