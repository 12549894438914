import React, { useState } from 'react'
import { ChooseTipoRelacionModalCreate } from 'components/modals'
import { ResponsableListSelector } from 'components/custom_selectors'

export default function ChooseListCreate({ addResponsableModalCreateRef, register, setResponsables, callbackOk, callbackError }) {
    const [openRelationModal, setOpenRelationModal] = useState(false)

    const [selectedResponsable, setSelectedResponsable] = useState(null)

    const handleSelection = (responsable) => {
        // setResponsables([responsable])
        setSelectedResponsable(responsable)
        setOpenRelationModal(true)
    }

    return (
        <React.Fragment>
            <ResponsableListSelector
                onSelectedCallback={handleSelection} />

            <ChooseTipoRelacionModalCreate
                addResponsableModalCreateRef={addResponsableModalCreateRef}
                selectedResponsable={selectedResponsable}
                openRelationModal={openRelationModal}
                setOpenRelationModal={setOpenRelationModal}
                register={register}
                setResponsables={setResponsables}
                callbackOk={callbackOk}
                callbackError={callbackError} />
        </React.Fragment>
    )
}