import React from 'react'
import { Grid, Stack } from '@mui/material'
import { H3, Text } from 'components/texts'
import { Line } from 'components/data_display'
import { EmpleadoPicker } from 'components/forms/components/custom'
import SkeletonGeneralData from './skeletonGeneralData'

export default function GeneralData({ grupo }) {
    return (
        <Grid container px={5}>
            {!grupo ?
                <SkeletonGeneralData />
                : (
                    <>
                        <Grid container spacing={1} mt={2} >

                            <Grid item xs={12}>
                                <Grid item container spacing={3} >
                                    <Grid item xs={3}>
                                        <Stack>
                                            <Text fontWeight={"bold"}>Clave del Grupo:</Text>
                                            <Text>{grupo?.clave_grupo ?? ""}</Text>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Stack>
                                            <Text fontWeight={"bold"}>Número de alumnos:</Text>
                                            <Text>{grupo?.num_alumnos ?? ""}</Text>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Stack>
                                            <Text fontWeight={"bold"}>Curso escolar:</Text>
                                            <Text>{grupo?.curso_escolar?.valor ?? ""}</Text>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Stack>
                                            <Text fontWeight={"bold"}>Actividades Academias:</Text>
                                            <Text>{grupo?.registro_actividad?.actividad?.valor ?? ""}</Text>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Stack>
                                            <Text fontWeight={"bold"}>Nivel del Grupo:</Text>
                                            <Text>{((grupo?.nivel_grupo?.valor ?? "") + " - " + (grupo?.nivel_grupo?.edad ?? "")) ?? ""}</Text>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Stack>
                                            <Text fontWeight={"bold"}>Número de Grupo:</Text>
                                            <Text>{grupo?.num_grupo ?? ""}</Text>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Stack>
                                            <Text fontWeight={"bold"}>Fecha inicio:</Text>
                                            <Text>{grupo?.fecha_inicio ?? ""}</Text>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Stack>
                                            <Text fontWeight={"bold"}>Fecha fin:</Text>
                                            <Text>{grupo?.fecha_fin ?? ""}</Text>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Stack>
                                            <Text fontWeight={"bold"}>Mínimo de alumnos:</Text>
                                            <Text>{grupo?.min_alumnos ?? ""}</Text>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <Stack>
                                            <Text fontWeight={"bold"}>Máximo de alumnos:</Text>
                                            <Text>{grupo?.max_alumnos ?? ""}</Text>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container mt={4}>
                            <Line />
                        </Grid>

                        <Grid container>
                            <Grid item xs={6} p={3}>
                                <EmpleadoPicker
                                    label={"Profesor del Grupo"}
                                    editLabel={"Cambiar profesor"}
                                    addLabel={"Añadir profesor"}
                                    deleteLabel={"Borrar profesor"}
                                    canEdit={false}
                                    empleado={grupo?.profesor_actual?.empleado ?? null} 
                                />
                            </Grid>

                            <Grid item xs={6} style={{ borderLeft: "1px solid #D9D9D9" }} p={3}>
                                <H3>Observaciones del Grupo:</H3>
                                <Text className="w-100" name={"observaciones"} multiline minRows={6} maxRows={10} uppercase >
                                    {grupo?.observaciones_grupo ?? null}
                                </Text>
                            </Grid>
                        </Grid>
                    </>
                )
            }
        </Grid>
    )
}


