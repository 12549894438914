import React, { Component } from 'react'
import Skeleton from '@mui/material/Skeleton';
import { Grid } from '@mui/material';

/**
 * SKELETON
 */
export default class SkeletonDatosColegio extends Component {

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
        <Grid container px={5} spacing={2} mt={2}>
            <Grid item xs={4}>
                <Skeleton variant="rounded" width={"100%"} height={40}/>
            </Grid>

            <Grid item xs={4}>
                <Skeleton variant="rounded" width={"100%"} height={40}/>
            </Grid>

            <Grid item xs={4}>
                <Skeleton variant="rounded" width={"100%"} height={40}/>
            </Grid>

            <Grid item xs={8}>
                <Skeleton variant="rounded" width={"100%"} height={40} />
            </Grid>

            <Grid item xs={4}>
                <Skeleton variant="rounded" width={"100%"} height={40} />
            </Grid>
        </Grid>
    )
  }
}
