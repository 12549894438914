import { Grid } from '@mui/material'
import React, { Component } from 'react'
import { Text } from 'components/texts'
import { Input } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Componente de React para renderizar el template de un responsable.
 * Este componente se utiliza para capturar y mostrar los campos relacionados con los datos
 * de un responsable, como nombre, apellidos, email, y detalles del documento.
 * 
 * Utiliza el sistema de Grid de Material UI para un layout responsivo, y componentes personalizados
 * para los inputs y selectores, facilitando la integración con `react-hook-form` para la gestión de formularios
 * y validación de campos.
 * 
 * @param {Function} register - Función de `react-hook-form` para registrar los inputs.
 * @param {Object} errors - Objeto que contiene los errores de validación de `react-hook-form`.
 * @param {Function} reset - Función de `react-hook-form` para resetear el formulario.
 * @returns {JSX.Element} - Un componente de formulario para los datos del responsable.
 */
export default function ResponsableTemplate({ register, errors, reset }) {
    return (
        <Grid container>
            <Grid container mt={2} mb={1}>
                <Text fontWeight={'bold'}>Según datos de:</Text><br />
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Input label="Nombre" width={"100%"} name={"nombre"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <Input label="Primer apellido" width={"100%"} name={"apellido1"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <Input label="Segundo apellido" width={"100%"} name={"apellido2"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <Input label="Email" width={"100%"} name={"email"} register={register} errors={errors} />
                </Grid>

                <Grid item xs={4}>
                    <ItemsSelect name={"tipo_documento_id"} keyValue={"tipo_documento"} register={register} errors={errors} wantToReset={reset} />
                </Grid>

                <Grid item xs={4}>
                    <Input label="Número" width={"100%"} name={"numero_documento"} register={register} errors={errors} uppercase />
                </Grid>
            </Grid>
        </Grid>
    )
}