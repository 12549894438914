import React from "react"
import { Grid } from "@mui/material"
import { Text } from 'components/texts'
import { Input } from 'components/forms/components'
import { CursoEscolarSelect, ItemsSelect } from 'components/forms/components/Select/custom'

export default function InscripcionesColectivosAcademia({ register, errors, reset, setValue }) {

    return (
        <>
            <Grid container mt={2} mb={1}>
                <Text fontWeight={'bold'}>Según datos de:</Text><br />
            </Grid>
            <Grid container spacing={2} mb={2}>

                <Grid item xs={6}>
                    <ItemsSelect name={"empresa_id"} keyValue={"empresa"} register={register} errors={errors} wantToReset={reset} searcheable/>
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect name={"actividad_id"} keyValue={"actividad"} register={register} errors={errors} wantToReset={reset} searcheable/>
                </Grid>

                <Grid item xs={6}>
                    <CursoEscolarSelect label={"Año"} name={"curso_escolar_id"} wantToReset={reset} register={register} errors={errors} defaultValue={null} setValue={setValue} searcheable />
                </Grid>

            </Grid>
        </>
    )
}