import React from 'react'
import { Box, Grid } from "@mui/material";
import { Datagrid } from 'components/data_display'
import moment from 'moment'

const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    {
        field: 'concepto',
        headerName: 'Concepto',
        width: 450,
        editable: true,
    },
    {
        field: 'cantidad',
        headerName: 'Cantidad',
        width: 100,
        editable: true,
    },
    {
        field: 'observacion',
        headerName: 'Observaciones',
        width: 250,
        editable: true,
    },
    {
        field: 'condiciones',
        headerName: 'Condiciones',
        width: 250,
        editable: true,
    },
    {
        field: 'formaCond',
        headerName: 'Forma Cond.',
        width: 200,
        editable: true,
    }
];

const cursoActual = moment().subtract(1, 'year').format('YYYY') + "-" + moment().format('YYYY')

export default function DetallesPagos({ register, errors, setValue, getValues, pagos }) {
    return (
        <Grid container>
            <Grid item xs={12} mt={4}>
                <Datagrid
                    rows={pagos}
                    columns={columns}
                    rowCount={10}
                    serverSide
                />
            </Grid>
        </Grid>
    )
}