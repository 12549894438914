import React from 'react'
import styles from './styles.js'
import { Grid, Skeleton } from '@mui/material'


/**
 * Formulario con los datos de un libro
 */
export default function DataFormLibroNivelesSkeleton() {

  return (
      <Grid container px={3}>
          <Grid container spacing={2} item xs={12} xl={6} p={3}>
            <Grid item xs={12}>
              <Skeleton variant="rounded" width={"100%"} height={30}/>
            </Grid>

            {Array.from({ length: 20 }).map(item => (
              <Grid key={Math.random()} item xs={4}>
                  <Skeleton variant="rounded" width={"100%"} height={20}/>
              </Grid>)
            )}
          </Grid>

          <Grid container spacing={2} item xs={12} xl={6} p={3} sx={styles.nivelesNiñoGrid}>
            <Grid item xs={12}>
              <Skeleton variant="rounded" width={"100%"} height={30}/>
            </Grid>

            {Array.from({ length: 20 }).map(item => (
              <Grid key={Math.random()} item xs={4}>
                  <Skeleton variant="rounded" width={"100%"} height={20}/>
              </Grid>)
            )}
          </Grid>
      </Grid>
  )
}