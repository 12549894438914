import { enqueueSnackbar } from "notistack";

/**
 * Hook personalizado para manejar el guardado de un responsable dentro del formulario rápido de edición.
 * Se encarga de recolectar y filtrar los valores del formulario que pertenecen a un responsable, 
 * para luego actualizar el estado global de responsables o modificar uno existente.
 * 
 * @param {object} addResponsableModalEditRef - Referencia al modal de edición de responsables para poder cerrarlo tras el guardado.
 * @param {function} getValues - Función de react-hook-form para obtener los valores del formulario.
 * @param {function} setResponsables - Función para actualizar el estado de responsables.
 * @returns {object} Objeto que contiene la función handleSave para ser usada en el formulario.
 */
export default function useHandleSave({ addResponsableModalCreateRef = null, addResponsableModalEditRef = null, getValues, setResponsables }) {
    const handleSave = () => {
        /**
         * Obtención de todos los valores del formulario
         */
        const allValues = getValues();

        /**
         * Filtrado de los valores que comienzan con "responsable_"
         */
        const responsableValues = Object.keys(allValues)
            .filter(key => key.startsWith('responsable_'))
            .reduce((obj, key) => {
                const newKey = key.replace('responsable_', '')
                obj[newKey] = allValues[key]
                return obj
            }, {})

        /**
         * En caso de que no se hayan rellenado todos los campos requeridos,
         * aparecerá un snackbar indicándolo
         */
        if (validateFields({ responsableValues })) {
            /**
             * Para controlar qué responsables se deben crear en el request, y diferenciarlos de los que vienen del servidor 
             * Ya hacemos lo mismo al seleccionar un responsable ya creado desde la lista
             */
            responsableValues['creating'] = true

            /**
             * Actualiza el estado con los valores filtrados
             */
            const newResponsable = responsableValues
            const uniqueIdentifier = newResponsable.numero_documento

            setResponsables((currentResponsables) => {
                // Verifica si ya existe un responsable con el mismo identificador
                const existingIndex = currentResponsables.findIndex((responsable) => responsable.numero_documento === uniqueIdentifier)

                if (existingIndex !== -1) {
                    // Si ya existe, actualiza ese responsable
                    const updatedResponsables = [...currentResponsables]
                    updatedResponsables[existingIndex] = newResponsable
                    return updatedResponsables
                }

                // Si no existe, añade el nuevo responsable
                return [...currentResponsables, newResponsable]
            })

            //Cierra el modal
            if (addResponsableModalCreateRef)
                addResponsableModalCreateRef.current.close()

            if (addResponsableModalEditRef)
                addResponsableModalEditRef.current.close()
        }
    }

    /**
     * Función de validación de los campos requeridos del responsable en el formulario de creación rápida
     * 
     * @param {Array} responsableValues - Array con los datos del formulario de creación rápida de responsable 
     * @returns {Boolean}
     */
    const validateFields = ({ responsableValues }) => {
        const requiredFields = [
            'nombre',
            'apellido1',
            'apellido2',
            'email',
            'tipo_documento_id',
            'numero_documento',
            'tipo_relacion_id'
        ];

        // Verifica si todos los campos requeridos están rellenos
        const isAllRequiredFilled = requiredFields.every(field => {
            const value = responsableValues[field];
            const stringValue = typeof value === 'number' ? value.toString() : value
            return value && stringValue.trim() !== '';
        });

        // Si no todos los campos requeridos están rellenos, muestra un mensaje.
        if (!isAllRequiredFilled) {
            enqueueSnackbar('Debe rellenar todos los campos obligatorios', { variant: 'warning' });
            return false;
        }

        if (!validateEmail(responsableValues?.email)) {
            enqueueSnackbar('El campo email debe ser una dirección de correo válida.', { variant: 'error' });
            return false;
        }

        if (!validateDocument(responsableValues?.tipo_documento_id, responsableValues?.numero_documento)) {
            enqueueSnackbar('Debe introducir un número de documento válido.', { variant: 'error' });
            return false;
        }

        return true;
    };

    const validateEmail = (value) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        console.log('regex.test(value)', regex.test(value));
        return regex.test(value)
    }

    const validateDocument = (type, value) => {
        const documentTypes = {
            1: /^(X|Y|Z)?\d{7,8}[A-HJ-NP-TV-Z]$/, //DNI
            2: /^[ABCDEFGHJKLMNPQRSUVW]\d{7}[0-9A-J]$/, //CIF
            3: /^[A-Z]{2}\d{6}$/, //Pasaporte
            4: /^[A-Z0-9]{5,10}$/, //Visado
            5: /^[A-Z0-9\- ]{5,20}$/, //Cert. Res
            6: /^[XYZ]\d{7}[A-HJ-NP-TV-Z]$/ //NIE
        }

        const regex = documentTypes[type];
        return regex ? regex.test(value) : false;
    }


    return { handleSave }
}