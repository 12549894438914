import RequestMaster from '../RequestMaster'

export default class RequestPagoInscripcion extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/pago-inscripcion'
    }

    /**
     * Store Multiple
     * 
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    createMultiple(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "multiple"
        this.post(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Update Multiple
     * 
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    updateMultiple(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "multiple"
        this.put(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Obtiene el recibo
     * 
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    recibo(params, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "recibo?" + params
        this.pdf(path, {}, callbackError, callbackFinally)
    }

}
