import { Button } from 'components/forms/components'
import { Link } from 'components/data_display'

export const basicColumns = [
    {
        field: "actions",
        type: 'actions',
        headerName: "Acciones",
        minWidth: 200,
        getActions: (params) => [<Link to={`${params.row.id}`}><Button size={"small"}>Ver</Button></Link>, <Link to={`${params.row.id}/editar`}><Button size={"small"} variant={"outlined"}>Editar</Button></Link>]
    },

    { field: "id", headerName: "ID", maxWidth: 65, sortable: false, filterable: false  },
    { field: "alumno", headerName: "Alumno", minWidth: 100, flex: 1, valueGetter: (params) => {
        const { alumno } = params.row
        return (alumno?.nombre ?? "") + " " + (alumno?.apellido1 ?? "") + " " + (alumno?.apellido2 ?? "")
    }, sortable: false, filterable: false },
    { field: "año",       headerName: "Año",       minWidth: 100, flex: 1, valueGetter: (params) => { return params.row.curso_escolar?.valor ?? ""}, sortable: false, filterable: false },
    { field: "actividad", headerName: "Actividad", minWidth: 100, flex: 1, valueGetter: (params) => { return params.row.actividad?.valor ?? ""}, sortable: false, filterable: false },
    { field: "colegio",   headerName: "Colegio",   minWidth: 100, flex: 1, valueGetter: (params) => { return params.row.colegio?.nombre ?? ""}, sortable: false, filterable: false },
]

export const grupoColumns = [
    { field: "grupo", headerName: "Grupo", minWidth: 100, flex: 1, valueGetter: (params) => { return params.row.grupo?.clave ?? ""}, sortable: false, filterable: false },
]

export const columns = {
    alumnos:        [...basicColumns],
    pendientes:     [...basicColumns],
    grupos:         [...basicColumns, ...grupoColumns],
    actividades:    [...basicColumns],
    subvencionadas: [...basicColumns],
    pago_cero:      [...basicColumns],
    tipo_pago:      [...basicColumns],
}