import { PagosAlumnosData } from './parts'
import React, { useState, useEffect, useContext } from 'react'
import { Container, Paper, Stack } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { BreadcrumbsList, MoveButtons } from 'components/data_display'
import { EmpleadoListContext } from 'contexts/employee/ListContext'

export default function SinglePagosAlumnos() {
  const navigationParams = useParams()
  const navigate = useNavigate()

  const { items } = useContext(EmpleadoListContext)

  const [id_pagos_alumnos, setIdpagos_alumnos] = useState(navigationParams.id_pagos_alumnos)
  const [pagos_alumnos, setpagos_alumnos] = useState(null)

  const updateId = (id) => {
    navigate(`../alumnos/pagos-alumnos/${id}/editar`);
    setIdpagos_alumnos(id);
  }

  useEffect(() => {
    const pagos_alumnos = items.find(item => item?.id === id_pagos_alumnos)
    setpagos_alumnos(pagos_alumnos)

  }, [id_pagos_alumnos])

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>

      <Stack direction="row" justifyContent={"space-between"} marginX={8}>
        <BreadcrumbsList mx={0} breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../", label: "Alumnos" },
          { to: "./", label: "Pagos Alumnos #" + (id_pagos_alumnos ?? "0000"), actualItem: true },
        ]} />

        <MoveButtons
          actualItemId={id_pagos_alumnos}
          beforeFetchCallback={() => setpagos_alumnos(null)}
          onUpdateActualItem={updateId} />
      </Stack>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
        <PagosAlumnosData pagos_alumnos={pagos_alumnos} />
      </Paper>

    </Container>
  )
}
