import { Grid } from '@mui/material';
import { Text } from 'components/texts'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import validationSchema from "./validation"
import Statics from 'objects/common/Statics';
import { SearchButtons } from 'components/forms/display/components'
import { Select } from 'components/forms/components'
import { yupResolver } from '@hookform/resolvers/yup'
import { RequestInscripcionAcademia } from 'objects/requests'
import { itemsSearch, itemsOrder } from './searchAndOrderOptions'
import { columns } from 'pages/logged/admin/colegios/colegio/single/parts/ActividadesData/parts/columns';

let prepareParamsGlobal = null

//Formulario de busqueda para inscripciones academias 
export default function SearchFormFichaCandidato({ onLoadingCallback, searchCallback, onChangeSearchIndex }) {

    //Form
    const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) })

    //State
    const [resetFormState, setResetFormState] = React.useState(false);
    const [searchIndex, setSearchIndex] = React.useState(0);
    const [selectedTemplate, setSelectedTemplate] = React.useState(itemsSearch[0].value);


    /**
     * Controla cuando se hace un cambio en el select de busqueda
     * 
     * @param {Object} event
     */
    const handleChange = (event) => {
        const index = event.target.value
        setSelectedTemplate(index);
        setSearchIndex(index)
        setResetFormState(true)

        if (onChangeSearchIndex)
            onChangeSearchIndex(index)

        // onChangeSearchIndex(index, itemsSearch[index].columnKey)
    }

    useEffect(() => {
        if (resetFormState == true) {
            resetForm(reset)
            setResetFormState(false)
        }
    })

    //-------------------------------------------------------
    //---------------------- FUNCTIONS ----------------------
    //-------------------------------------------------------



    /**
     * ES NECESARIO REEMPLAZAR ESTO POR LA PETICIÓN DE CANDIDATOS
     * 
     * @param {Int} page
     */
    const prepareParams = (page) => {
        const params = Statics.objectPropsToUppercase(Statics.cleanObject(getValues()));
        params.appends = ['actividad', 'grupo_academia', 'forma_pago']
        params.page = page

        return params
    }
    const ActualTemplate = itemsSearch[searchIndex].template

    prepareParamsGlobal = prepareParams


    const filteredItemsOrder = itemsOrder.filter(item => {
        if (selectedTemplate === itemsSearch[1].value) {
            return item.label === "Nombre";
        } else {
            return true;
        }
    });

    return (
        <Grid container p={2}>
            <Grid container >
                <Grid item xs={6} marginRight={4}>
                    <Text fontWeight={'bold'} marginBottom={6}>Buscar por:</Text><br />
                    <Select width={"100%"} items={itemsSearch} defaultValue={itemsSearch[0].value} onChange={handleChange} />
                </Grid>

                <Grid item xs={4} >
                    <Text fontWeight={'bold'} marginBottom={6}>Ordenar resultados por:</Text><br />
                    <Select width={"60%"} name={"order"} items={filteredItemsOrder} defaultValue={filteredItemsOrder[0].value} register={register} errors={errors} />
                </Grid>
            </Grid>

            <ActualTemplate reset={resetFormState} register={register} errors={errors} />

            <SearchButtons searchText={"Buscar Candidatos"} onResetClick={() => { setResetFormState(true) }}
                onSubmitClick={handleSubmit(() => { search(1, onLoadingCallback, searchCallback) })} />
        </Grid>
    )
}



/**
 * Hace la busqueda de alumnos
 * 
 * @param {Int}      page
 * @param {Function} onLoadingCallback
 * @param {Function} searchCallback
 * @param {Function} prepareParams
 */
export const search = (page = 1, onLoadingCallback, searchCallback) => {
    //Callback para controlar cuando esta cargando
    if (onLoadingCallback)
        onLoadingCallback(true)

    const params = prepareParamsGlobal(page)
    new RequestInscripcionAcademia().search(params, (res) => {
        if (searchCallback)
            searchCallback(res.data, res.data.total, page, params)
    }, () => {
        searchCallback([], 0, page, params)
    })
}


/**
 * Resetea todo el formulario
 * 
 * @param {Function} reset
 */
const resetForm = (reset) => {
    reset({
        nombre: "",
        apellido1: "",
        apellido2: "",
        academia: "",
        testNivel: ""
    })
}