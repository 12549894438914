import { useForm } from 'react-hook-form'
import { Text } from 'components/texts'
import Requests from './Requests/Requests.js'
import { useNavigate, useParams } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangesContext } from 'contexts/ChangesContext'
import { Container, Paper, Stack } from '@mui/material'
import { EditHeader } from 'pages/logged/admin/components'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { AlumnoFullItem } from 'components/data_display/users'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import React, { useRef, useState, useEffect, useContext } from 'react'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
import AlumnoSkeleton from './skeleton.jsx'

//FORM
import { DataFormIncidencia } from 'components/forms/display'
import Skeleton from 'components/forms/display/incidencia/DataForm/skeleton.jsx'
import { validationSchema } from 'components/forms/display/incidencia/DataForm/validation.js'
import { useAlert } from 'hooks'
import Statics from 'objects/common/Statics.js'

const Wrapper = () => {
  const navigationParams = useParams()

  const updateAlert = useAlert()

  const { items, updateItem } = useContext(EmpleadoListContext)

  const [incidencia, setIncidencia] = useState(null)
  const [id_incidencia, setIdIncidencia] = useState(navigationParams.id_incidencia)

  const getIncidencia = (getFromServer = false) => {
    const item = items.find(item => item?.id == id_incidencia)
    if (items.length == 0 || !item || getFromServer) {
      new Requests().getIncidencia(id_incidencia, (incidencia) => {
        setIncidencia(incidencia)
        updateItem(incidencia)
      }, () => updateAlert("No se ha podido recuperar la incidencia."))
    } else {
      setIncidencia(item)
    }
  }

  useEffect(getIncidencia, [id_incidencia])

  return (
    <React.Fragment>
      <EditIncidencia key={incidencia?.id ?? 0} incidencia={incidencia} id_incidencia={id_incidencia} setIdIncidencia={setIdIncidencia} setIncidencia={setIncidencia} updateAlert={updateAlert} onSaveFinish={() => getIncidencia(true)} />

      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}
export default Wrapper

function EditIncidencia({ incidencia, id_incidencia, setIdIncidencia, setIncidencia, updateAlert, onSaveFinish }) {
  const navigate = useNavigate()

  const { updateChanges } = useContext(ChangesContext)

  const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, setValue, reset } = useForm({ resolver: yupResolver(validationSchema) })

  const headerRef = useRef()

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0)
      updateChanges(true)
  }, [Object.keys(dirtyFields).length])

  /**
   * Hace la petición para editar los datos basicos del responsable
   */
  const editIncidencia = () => {
    headerRef.current.button.setLoading(true)

    new Requests().editIncidencia(id_incidencia, getValues, async (res) => {
      reset({}, { keepValues: true })
      updateChanges(false)
      updateId(id_incidencia) //Añadida la ejecución de la función
      updateAlert('Se ha editado la incidencia correctamente.', 'success')
      onSaveFinish()
    }, (err) => {
      updateAlert('Ha habido un error al editar la incidencia.')
    })
  }

  const updateId = async (id) => {
    // navigate(`../alumnos/incidencias/${id}`)
    await Statics.delay(1500).then(() => { navigate(`../alumnos/incidencias/${id}`) }) //Modificada la URL, para ir al single
    setIdIncidencia(id)
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(editIncidencia)}>
        <Stack direction="row" justifyContent={"space-between"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/", label: "Inicio" },
            { to: "./../../../", label: "Alumnos" },
            { to: "./../../", label: "Incidencias" },
            { to: "./../", label: "Incidencia del alumno #" + (incidencia?.id_alumno ?? "0000") },
            { to: "./", label: "Editar incidencia #" + (id_incidencia ?? "0000"), actualItem: true },
          ]} />

          <MoveButtons
            actualItemId={id_incidencia}
            beforeFetchCallback={() => setIncidencia(null)}
            onUpdateActualItem={updateId} />
        </Stack>

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 2 }}>
          <EditHeader title="Alumno de la incidencia" />

          <Line />

          {incidencia?.alumno ? <AlumnoFullItem alumno={incidencia.alumno} containerClassName="m-5" /> : <AlumnoSkeleton />}
        </Paper>


        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <EditHeader
            ref={headerRef}
            title={"Editar incidencia"}
            item={incidencia} />

          <Line />

          {incidencia ?
            <DataFormIncidencia
              incidencia={incidencia}
              setValue={setValue}
              register={register}
              errors={errors}
              isMounted={true}
              hideButton /> : <Skeleton />}
        </Paper>

      </form>
      <SnackbarProvider autoHideDuration={3000} />
    </Container>
  )
}