import { v4 as uuid } from 'uuid'
import React, { Component, useEffect, useRef, useState } from 'react'
import { Select } from 'components/forms/components'
import { RequestRegistroActividad } from 'objects/requests'

export default function RegistroActividadColegioSelect({ 
    initialLabel = "Elige un colegio para cargar las actividades.",
    wantToReset,
    id_colegio,
    id_curso_escolar,
    onChange,
    extraParams = {},
    width,
    className,
    defaultValue,
    onDefaultValueLoaded,
    register,
    disabled,
    name,
    errors,
    label = "Actividad colegio"
}) {
    const [actividades, setActividades] = useState({ toShow: [{ value: "", label: initialLabel }], raw: [] })

    const selectRef = useRef()

    useEffect(() => {
        if(wantToReset)
            setActividades({ toShow: [{ value: "", label: initialLabel }], raw: [] })
    }, [wantToReset])

    const onChangeSelect = (event) => {
        if (onChange) {
            const itemSelected = actividades.raw.find(item => item.id == event.target.value)
            onChange(event, itemSelected)
        }
    }

    const getActividadesColegio = (id_colegio, id_curso_escolar = null) => {
        const params = { entity_id: id_colegio, entity_type: 'colegio', limit: 100, ...extraParams }
        if(id_curso_escolar)
            params.curso_escolar_id = id_curso_escolar

        new RequestRegistroActividad().search(params, (res) => {
            const actividadesToShow = res.actividades.data.map(item => ({ value: item.id, label: item.nombre }))
            setActividades({ toShow: actividadesToShow, raw: res.actividades.data })
        }, (res) => {
            setActividades({ toShow: [{ value: "", label: "Este colegio no tiene ninguna actividad creada." }], raw: [] })
        })
    }

    useEffect(() => {
        if(id_colegio) {
            if(id_curso_escolar === undefined)
                getActividadesColegio(id_colegio)
            else if(id_curso_escolar) {
                getActividadesColegio(id_colegio, id_curso_escolar)
            }
        }
    }, [id_colegio, id_curso_escolar])

    const labelId = "registro_actividad_colegio_select-id-" + uuid()
        return (
            <Select
                ref                  = {selectRef}
                width                = {width ?? "100%"}
                items                = {actividades.toShow}
                className            = {className}
                defaultValue         = {defaultValue}
                onDefaultValueLoaded = {onDefaultValueLoaded}
                onChange             = {onChangeSelect}
                register             = {register}
                disabled             = {disabled}
                name                 = {name ?? "id_registro_actividad"}
                errors               = {errors}
                labelId              = {labelId}
                wantToReset          = {wantToReset}
                label                = {label} />
        )
}