import React, { useEffect, useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all'
import listWeek from '@fullcalendar/list'
import './style.css'

const MODE_DAY  = "day"
const MODE_TIME = "time"

/**
 * Calendario.
 *
 * @param {String}      [locale="es"]                   Codigo de idioma para la tabla.
 * @param {String}      [initialView="dayGridMonth"]    Codigo de como se va a mostrar (por mes, semana, dia o agenda).
 *      @example Mes: "dayGridMonth", Semana: "timeGridWeek", Dia: "timeGridDay", Agenda: "listWeek"'
 *
 * @param {Boolean}     [weekends=true]                 Establece si se van a ver los findes de semana.
 * @param {Function}    [dateClick]                     Funcion que se ejecuta cuando clickas en una fecha
 * @param {Object}      [headerToolbar]                 Sirve para establecer que opciones quieres ver en el header
 *      @example { left: 'prev,next,today', center: 'title', right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek' }
 *
 * @param {Array}       [events]                        Un array que contiene objetos con la descripcion de los eventos.
 *       @example [{ title: 'Evento 1', date: '2021-07-21 10:00:00' }, { title: 'Evento 2', date: '2021-07-22' }]
 */
export default function Calendar({ events = [], locale = "es", customButtons, onPrevClick, onNextClick, headerToolbar, dateClick, eventClick }){

    const [mode, setMode] = useState(MODE_TIME) 
    const calendarRef = useRef()

    useEffect(() => {
        const api = calendarRef.current.getApi()
        api.changeView(mode == MODE_TIME ? "timeGridWeek" : "dayGridMonth")
    }, [mode])

    const defaultHeaderToolBar = mode == MODE_TIME ? { left: 'prev,next today', center: 'title', right: 'customMode,timeGridWeek,timeGridDay' } : { left: 'prev,next today', center: 'title', right: 'customMode,dayGridMonth,dayGridWeek,dayGridDay' }
    const plugins              = mode == MODE_TIME ? [timeGridPlugin, dayGridPlugin] : [timeGridPlugin, dayGridPlugin]
    return (
        <FullCalendar
            ref             = {calendarRef}
            locales         = {allLocales}
            locale          = {locale}
            plugins         = {[...plugins, interactionPlugin]}
            eventDisplay    = {"list-item"}
            allDaySlot      = {false}
            navLinks        = {true}
            events          = {events}
            slotDuration    = {{ minutes: 15 }}
            slotLabelFormat = {{
                hour: '2-digit',
                minute: '2-digit',
                omitZeroMinute: false, // Esto asegura que los minutos siempre se muestren, incluso si son "00"
                hour12: false // Esto asegura que se muestren en formato de 24 horas
            }}
            customButtons  = {customButtons ??
                {
                    prev: {
                        click: () => {
                            const api = calendarRef.current.getApi()
                            if(onPrevClick)
                                onPrevClick(api.getDate())

                            api.prev()
                        }
                    },
                    next: {
                        click: () => {
                            const api = calendarRef.current.getApi()
                            if(onNextClick)
                                onNextClick(api.getDate())

                            api.next()
                        }
                    },
                    customMode: {
                        text: mode == MODE_TIME ? "Vista día" : "Vista horas",
                        click: () => setMode(mode == MODE_TIME ? MODE_DAY : MODE_TIME)
                    }
                }
            }
            eventTimeFormat = {{
                hour: 'numeric',
                minute: '2-digit',
                meridiem: false
            }}
            headerToolbar = {headerToolbar ?? defaultHeaderToolBar}
            dateClick={(e) => dateClick(e, mode == MODE_TIME ? true : false)}
            eventClick={eventClick} />
    )
}
