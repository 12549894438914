import { Popover } from '@mui/material'
import React, { Component } from 'react'
  
/**
 * Plantilla para mostrar la información del evento
 */
export default class PopOver extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            open: false,
            anchorEl: null
        }
    }


    /**
     * Abre o cierra el tooltip segun el parámetro
     * 
     * @param {Boolean} open 
     */
    open = (e, open = true) => {
        this.setState({open, anchorEl: e ? e.target : null})
    }
      

    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <Popover
                open         = {this.state.open}
                anchorEl     = {this.state.anchorEl}
                onClose      = {() => {this.open(null, false)}}
                anchorOrigin={{
                    vertical: 'bottom', 
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>

                {this.props.content ?? null}
            </Popover>
        )
    }
}