import Statics from "objects/common/Statics"

export function columns() {
    return [
        { 
            field: "precio",
            headerName: "Precio",
            flex: 1, 
            align:'center',
            headerAlign: 'center',
            valueGetter: (params) => { return params.row.importe ? Statics.changeDotAndComma(params.row.importe) : "" },
            sortable: false, 
            filterable: false,
        },
        { 
            field: "num_alumnos",      
            headerName: "Alumnos",       
            maxWidth: 100,
            align:'center',
            type: 'number',
            headerAlign: 'center',   
            sortable: false, 
            filterable: false,
        },
        { 
            field: "pendiente_pago",    
            headerName: "Pdte Pago", 
            maxWidth: 100, 
            type: 'number',
            align:'center',
            headerAlign: 'center',
            valueGetter: (params) => { return params.row.pendiente_pago ? "Sí" : "No" },
            sortable: false, 
            filterable: false,
        },
    ]
}