import React from 'react'
import { useForm } from 'react-hook-form'
import { Container, Paper } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { CreateHeader } from 'pages/logged/admin/components'
import { SnackbarProvider } from 'notistack'
import { BreadcrumbsList, Line } from 'components/data_display'
import { DataFormLibroGenerarEnvio } from 'components/forms/display'
import validationSchema from 'components/forms/display/libro/generar-envios/DataForm/validation.js'
import { useGenerarEnviosLogic } from 'hooks'

/**
 * Componente `GenerarEnvioLibros` que proporciona una interfaz para generar envíos de libros.
 * Incluye formulario y lógica de validación para la generación de pedidos.
 * 
 * Utiliza el hook `useForm` de `react-hook-form` para manejar el formulario y su validación con `yup`.
 * 
 * @returns {JSX.Element} - Interfaz de usuario para la generación de pedidos de libros, incluyendo cabecera, formulario y validaciones.
 */
export default function GenerarEnvioLibros() {
  const { register, handleSubmit, formState: { errors }, getValues, setValue } = useForm({ resolver: yupResolver(validationSchema) })

  const { headerRef, isMounted, generarPedidos } = useGenerarEnviosLogic(getValues)

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(generarPedidos)}>
        <BreadcrumbsList breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../", label: "Libros" },
          { to: "./", label: "Generar envíos", actualItem: true },
        ]} />

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <CreateHeader
            ref={headerRef}
            title={"Generar envío de libros"}
            btnText={"Generar pedidos"} />

          <Line />

          <DataFormLibroGenerarEnvio
            isMounted={isMounted}
            register={register}
            errors={errors}
            setValue={setValue} />
        </Paper>

        <SnackbarProvider autoHideDuration={5000} />
      </form>
    </Container>
  )

}