import Skeleton from './skeleton'
import React, { Component } from 'react'
import { Grid, Stack } from '@mui/material'
import { H2, H3, Text } from 'components/texts'
import { Edit as EditIcon } from '@mui/icons-material'
import { EmpleadoBasicItem } from 'components/data_display/users'
import { Button, Checkbox, Select } from 'components/forms/components'


/**
 * Pagina con los datos del colegio
 */
export default class ColegioData extends Component {

  constructor(props) {
    super(props)

    this.state = {
      colegio: this.props.colegio ?? null
    }
  }


  componentDidUpdate(prevProps) {
    if (prevProps.colegio != this.props.colegio)
      this.setState({ colegio: this.props.colegio })
  }

  //-------------------------------------------------------
  //-------------------- CLASS FUNCTIONS ------------------
  //-------------------------------------------------------

  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    const { colegio } = this.state
    return (
      <Grid container mt={2} p={5}>
        {colegio ? (
          <>
            <Grid item xs={5}>
              <Stack>
                <H2 className="profile-id-text">{"#" + colegio.id}</H2>
                <H3>{colegio.nombre ?? ""}</H3>
              </Stack>
            </Grid>

            <Grid item xs={5}>
              <Stack>
                <Select
                  label="Centro de puertas abiertas:"
                  items={[{ value: 1, label: "Sí" }, { value: 2, label: "No" }]}
                  width={"60%"}
                  defaultValue={colegio.centro_pa ? 1 : 2}
                  disabled />

                <Checkbox label={"Colegio activo"} className="mt-2" defaultChecked={colegio.activo ? true : false} disabled />
              </Stack>
            </Grid>

            <Grid item xs={2} display={"flex"} justifyContent={"end"} alignItems={"start"}>
              <Button variant={"outlined"} startIcon={<EditIcon />} onClick={() => { this.props.navigate('./editar') }}>
                Editar colegio
              </Button>
            </Grid>

            <Grid item xs={12} mt={3}>
              <H3>Datos de contacto: </H3>
            </Grid>

            <Grid container mt={2} spacing={2}>
              <Grid item xs={3} display={"flex"} flexDirection={"column"}>
                <Text fontWeight={"bold"}>Dirección</Text>
                <Text>{(colegio.direccion ?? "")}</Text>
                <Text>{colegio.cod_postal ?? ""}</Text>
                <Text>{(colegio.municipio ?? "") + (colegio.provincia ? ", " + colegio.provincia.nombre : "")}</Text>
              </Grid>

              <Grid item xs={3} display={"flex"} flexDirection={"column"}>
                <Text fontWeight={"bold"}>Director</Text>
                <Text>{colegio.director ?? "Sin rellenar"}</Text>
              </Grid>

              <Grid item xs={3} display={"flex"} flexDirection={"column"}>
                <Text fontWeight={"bold"}>Número de teléfono</Text>
                <Text>{colegio.telefono1 ?? "Sin rellenar"}</Text>
              </Grid>

              <Grid item xs={3} display={"flex"} flexDirection={"column"}>
                <Text fontWeight={"bold"}>Correo electrónico</Text>
                <Text>{colegio.email ?? "Sin rellenar"}</Text>
              </Grid>

              <Grid item xs={3} display={"flex"} flexDirection={"column"}></Grid>

              <Grid item xs={3} display={"flex"} flexDirection={"column"}>
                <Text fontWeight={"bold"}>Persona de contacto</Text>
                <Text>{colegio.persona_contacto ?? "Sin rellenar"}</Text>
              </Grid>

              <Grid item xs={3} display={"flex"} flexDirection={"column"}>
                <Text fontWeight={"bold"}>Número de teléfono adicional</Text>
                <Text>{colegio.telefono2 ?? "Sin rellenar"}</Text>
              </Grid>

              <Grid item xs={3} display={"flex"} flexDirection={"column"}>
                <Text fontWeight={"bold"}>Correo electrónico adicional</Text>
                <Text>{colegio.email2 ?? "Sin rellenar"}</Text>
              </Grid>
            </Grid>

            <Grid container mt={4} spacing={2}>
              <Grid item xs={6} display={"flex"} flexDirection={"column"}>
                <Text fontWeight={"bold"}>Coordinador del colegio</Text>

                {colegio.coordinador != null ? (<EmpleadoBasicItem empleado={colegio.coordinador} />) : null}
              </Grid>

              <Grid item xs={3} display={"flex"} flexDirection={"column"}>
                <Text fontWeight={"bold"}>Clave</Text>
                <Text>{colegio.clave ?? "Sin rellenar"}</Text>
              </Grid>

              <Grid item xs={3} display={"flex"} flexDirection={"column"}>
                <Text fontWeight={"bold"}>AMPA</Text>
                <Text>{colegio.ampa ?? "Sin rellenar"}</Text>
              </Grid>
            </Grid>
          </>
        ) : <Skeleton />}
      </Grid>
    )
  }
}
