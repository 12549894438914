import './style.css'
import { Box } from "@mui/material";
import { TableColumn } from "components/forms/components";
import { H3, Text } from "components/texts";
// import TableColumn from "../TableColumn";
import { useAlmacenLogic } from "hooks";

/**
 * El componente `LibrosDevueltos` muestra información relacionada con el almacén de libros.
 * Proporciona una visión general de las unidades en el almacén, unidades vendidas y otros detalles relacionados.
 * 
 * Se encuentra dentro de src\components\forms\display\libro\libro\Almacen\index.jsx debido a que
 * tanto para el single como para el edit y el create, se usa el mismo codigo
 *
 * @param {Object} cursoEscolar - Información sobre el curso escolar.
 * @param {Array} librosDevueltos - Un array de libros devueltos.
 * @param {Array} librosPedidos - Un array de libros pedidos.
 * @param {Object} libro - Información específica del libro a gestionar.
 * 
 * @returns {JSX.Element} - Un componente que muestra información sobre el almacén de libros.
 */
export default function Almacen({ cursoEscolar, librosDevueltos, librosPedidos, libro }) {
    const { getTotal } = useAlmacenLogic(cursoEscolar, librosDevueltos, librosPedidos)

    return (
        <Box px={2}>
            <H3>Almacén</H3>
            <table className='almacen-table'>
                <tbody>
                    <tr className='total-unidades'>
                        <td className='bg-white'><Text>Total de unidades en el almacén:</Text></td>
                        <TableColumn key={Math.random()} defaultValue={getTotal()} disabled />

                        <td className='bg-white' colSpan={2}></td>
                        <td className='bg-white'><Text>Total de unidades vendidas:</Text></td>
                        <TableColumn key={Math.random()} defaultValue={libro?.ventas && cursoEscolar ? libro?.ventas[cursoEscolar] : 0} disabled />
                    </tr>
                </tbody>
            </table>
        </Box>
    )
}