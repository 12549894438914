import { Component } from 'react'
import Statics from 'objects/common/Statics'
import { RequestTutoria } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests extends Component {

    /**
     * Hace la petición para editar la tutoría
     * 
     * @param {Int} id 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editTutoria(id, getValues, callbackOk = () => {}, callbackError = () => {}) {
        var formData = new FormData()
        let fecha = getValues("fecha")
        
        formData.append("motivo",                getValues("motivo").toUpperCase())
        formData.append("fecha",                 Statics.datePickerTimeFormat(fecha))
        formData.append("solucionada",           getValues("solucionada") ? 1 : 0)
        formData.append("solucion",              getValues("solucion").toUpperCase())
        formData.append("seguimiento_adicional", getValues("seguimiento_adicional").toUpperCase())
        formData.append("otros_comentarios",     getValues("otros_comentarios").toUpperCase())
        formData.append("id_curso_escolar",      getValues("id_curso_escolar"))
        formData.append("id_causa",              getValues("id_causa"))
        formData.append("id_origen",             getValues("id_origen"))

        new RequestTutoria().edit(id, formData, callbackOk, callbackError)
    }


    /**
     * Devuelve la tutoría
     * 
     * @param {Int}      id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getTutoria(id, callbackOk = () => {}, callbackError = () => {}) {
        const params = { relations: "alumno" }
        new RequestTutoria().get(id, params, (res) => {
            res.tutoria ? callbackOk(res.tutoria) : callbackError(res)
        })
    }
}
