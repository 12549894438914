import { RequestHorario } from 'objects/requests'

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {
    /** 
     * Hace la petición para crear un horario
     * 
     * @param {Object}   values 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createHorario(grupo_id, values, callbackOk = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const formData = new FormData()
        const dias = ['l', 'm', 'x', 'j', 'v', 's']

        dias.forEach((valor, indice) => {
            if (values[valor])
                formData.append("dias[]", indice + 1)
        })
        formData.append("curso_escolar_id", values.curso_escolar_id)
        formData.append("aula_id",          values.aula_id)
        formData.append("entity_id",        grupo_id)
        formData.append("entity_type",      "academia")
        formData.append("color",            values.color.toUpperCase())
        formData.append("hora_inicio",      values.hora_inicio)
        formData.append("hora_fin",         values.hora_fin)
        formData.append("lugar_clase_id",   1)

        new RequestHorario().create(formData, callbackOk, callbackError, callbackFinally)
    }

    /** 
     * Hace la petición para eliminar una definicion del horario
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    deleteDefinicionHorario(id, callbackOk, callbackError) {
        new RequestHorario().deleteDefinicionHorario(id, {}, callbackOk, callbackError)
    }
}