import Statics from "objects/common/Statics"

/**
 * Hook `useLogic` se encarga de filtrar los libros pedidos por el curso escolar actual y de calcular
 * tanto la suma de unidades pedidas como la de unidades llegadas.
 * 
 * @param {Array} librosPedidos - Array de libros pedidos.
 * @param {String} cursoEscolar - Año del curso escolar seleccionado para filtrar los libros.
 * @returns {Object} Contiene libros filtrados y sumas de unidades pedidas y llegadas.
 */
export default function useLibrosPedidosSingleLogic(librosPedidos, cursoEscolar) {
    const librosToRender = cursoEscolar ? librosPedidos.filter(item => item.id_curso_escolar == cursoEscolar && item.deleted != true) ?? [] : []
    const sumPedidas = Statics.sumParamsOfObjectArray(librosToRender, "cantidad_pedida")
    const sumLlegadas = Statics.sumParamsOfObjectArray(librosToRender, "cantidad_llegada")

    return { librosToRender, sumPedidas, sumLlegadas }
}