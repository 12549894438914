import { Grid, Stack } from "@mui/material"
import { Line } from 'components/data_display';
import Skeleton from '@mui/material/Skeleton'

/**
 * Formulario con los datos del curriculum del candidato
 * 
 */
export default function SkeletonPersonalCandidato({ candidato }) {

    return (
        <>
            <Grid container item xs={12} px={4} mt={0}>

                <Grid item xs={3} mt={2}>
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                    </Stack>
                </Grid>

                <Grid item xs={2} mt={2}>
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                    </Stack>
                </Grid>

                <Grid item xs={2.4} mt={2} >
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                    </Stack>
                </Grid>
                <Grid item xs={2.4} mt={2}>
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                    </Stack>
                </Grid>

                <Grid item xs={2} mt={2}>
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                    </Stack>
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Line />
                </Grid>
            </Grid>

            <Grid container item xs={12} px={4} mt={2} >

                <Grid item xs={3} mt={2}>
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                    </Stack>
                </Grid>

                <Grid item xs={2} mt={2}>
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                    </Stack>
                </Grid>

                <Grid item xs={2.4} mt={2} >
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                    </Stack>
                </Grid>
                <Grid item xs={2.4} mt={2}>
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                    </Stack>
                </Grid>

                <Grid item xs={12} mt={2}>
                    <Line />
                </Grid>

            </Grid>


            <Grid container item xs={12} px={4} mt={2} >
                <Grid item xs={2} mt={2}>
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                    </Stack>
                </Grid>

                <Grid item xs={1} mt={2}>
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                    </Stack>
                </Grid>

                <Grid item xs={2} mt={2}>
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                    </Stack>
                </Grid>
                <Grid item xs={2} mt={2}>
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                    </Stack>
                </Grid>

                <Grid item xs={2.5} mt={2}>
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                    </Stack>
                </Grid>

                <Grid item xs={2.5} mt={2}>
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                    </Stack>
                </Grid>

                <Grid item xs={2} mt={2} >
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                    </Stack>
                </Grid>

                <Grid item xs={1} mt={2}>
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                    </Stack>
                </Grid>

                <Grid item xs={2} mt={2}>
                    <Stack>
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                        <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"60%"} />
                    </Stack>
                </Grid>

            </Grid>
        </>
    )
}