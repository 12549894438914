import { Edit as EditIcon } from '@mui/icons-material'
import { Button } from 'components/forms/components'
import { Link } from 'components/data_display'
import { H2, Text } from 'components/texts'
import { Stack } from '@mui/material'

/**
 * Componente single que se usa en el header para visualizar el boton deeditar.
 * title -> Es el titulo de la acción, es decir, si quiere editar una inscripción el title seria: Consultar inscripción.
 * data -> Los datos suelen ser el nombre de esta inscripción, por ejemplo, si es un alumno seria su apellido2 apellido1, nombre
 */
export default function Single({ title, data, margin }) {
    return (
        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} py={margin ? 2 : 0 } px={margin ? 5 : 0}>
            <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                <Text style={{ fontSize: "25px", fontWeight: "bold", marginRight: "8px", textAlign: "center" }}>{title}:</Text>
                <H2 style={{ fontSize: "25px", fontWeight: "lighter", textAlign: "center", marginBottom: '0' }}>{data}</H2>
            </Stack>
            <Link to="editar">
                <Button variant={"outlined"} startIcon={<EditIcon />}>Editar</Button>
            </Link>
        </Stack>
    )
}