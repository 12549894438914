import { useContext, useEffect, useState } from "react"
import { EmpleadoListContext } from "contexts/employee/ListContext"

//TODO: SI ESTE CODIGO SE REPITE EN EL RESTO DE LISTS, HACER UN HOOK GENERAL

/**
 * Hook personalizado que encapsula la lógica para el listado de actividades académicas.
 * Mantiene el estado de los datos de la tabla, la carga de datos y los parámetros de búsqueda.
 * Proporciona la función `updateTable` para actualizar la tabla cuando se reciben nuevos datos.
 *
 * @returns {Object} - Retorna los estados y funciones para controlar la tabla y la carga de datos:
 * - data: Objeto que contiene los datos actuales de la tabla y el conteo total de filas.
 * - loadingTable: Estado booleano que indica si la tabla está en proceso de carga de datos.
 * - params: Estado que contiene los parámetros actuales de búsqueda.
 * - updateTable: Función que actualiza los datos de la tabla y el contexto global con el nuevo conteo de filas, los datos y los parámetros de búsqueda.
 */
export default function useLogic() {
    const [loadingTable, setLoadingTable] = useState(false)

    const { items, rowCount, params, update } = useContext(EmpleadoListContext)

    useEffect(() => {
        setLoadingTable(false)
    }, [items])

    /**
     * Actualiza los datos de la tabla y el contexto global con los resultados obtenidos de la búsqueda.
     * 
     * @param {Array} data - Los datos obtenidos para llenar la tabla.
     * @param {number} rowCount - El número total de filas encontradas para la búsqueda realizada.
     * @param {number} page - La página actual de los resultados.
     * @param {Object} params - Los parámetros de búsqueda utilizados.
     */
    const updateTable = (newData, newRowCount, newPage, newParams) => update(newRowCount, newData, newParams, newPage)

    return { items, rowCount, updateTable, setLoadingTable, params, loadingTable, updateTable }
}