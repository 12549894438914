import RequestMaster from '../RequestMaster'

export default class RequestGrupoAcademia extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/grupo-academia'
    }


    /**
     * Hace la busqueda de los grupos de la academia
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    search(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.get(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Crea una academia
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    create(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.post(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Edita una academia
     *
     * @param {Int}      id
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    edit(id, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "" + id
        this.put(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Devuelve la vista preliminar del grupo
     * 
     * @param {Int}      id 
     * @param {Function} callback 
     * @param {Function} callbackError 
     * @param {Function} callbackFinally 
     */
    preliminar(id, params = {}, callbackError = () => { }, callbackFinally = () => { }) {
        const path = id + "/preliminar"
        this.pdf(path, params, callbackError, callbackFinally)
    }


    /**
     * Devuelve el informe de asistencia del grupo
     * 
     * @param {Int}      id 
     * @param {Function} callback 
     * @param {Function} callbackError 
     * @param {Function} callbackFinally 
     */
    informeAsistencia(params = {}, callbackError = () => { }, callbackFinally = () => { }) {
        // const path = id + "/preliminar"
        const path = "informe-asistencia"
        this.pdf(path, params, callbackError, callbackFinally)
    }
}
