import React, { Component } from "react";
import Skeleton from "@mui/material/Skeleton";
import { Grid, Stack, Container } from "@mui/material";

/**
 * Skeleton de carga para los breadcrumbs y posibles botones de navegación
 *
 * @param {Integer} numberLinks - número de links que se mostrarán en el breadcrumbs
 * @param {Boolean} navigation - indica si se mostrará la navegación
 * @returns {Object} skeleton para breadcrumbs
 */
export default function breadcrumbSkeleton({ numberLinks, navigation }) {
	return (
		<Stack direction="row" justifyContent={"space-between"} alignItems={"center"} marginX={8}>
			{/* BreadcrumbsList - Esqueleto para los links del breadcrumbs */}
			<Stack direction="row" spacing={1}>
				{Array.from({ length: numberLinks }, (_, index) => (
					<Skeleton variant="text" width={80} height={30} key={index}/>
				))}
			</Stack>
			{/* NavigationButtons - Esqueleto para los links de navegación entre registros*/}
			{navigation && (
				<Stack direction="row" spacing={0.2}>
					<Skeleton variant="rounded" width={50} height={30} />
					<Skeleton variant="rounded" width={50} height={30} />
					<Skeleton variant="rounded" width={50} height={30} />
					<Skeleton variant="rounded" width={50} height={30} />
				</Stack>
			)}
		</Stack>
	);
}
