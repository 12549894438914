import { Component } from 'react'
import { RequestComunicaciones } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests extends Component {

    /**
     * Hace la petición para enviar las comunicaciones
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    sendComunicacion(getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()
        formData.append("mensaje",              getValues("mensaje"))
        formData.append("asunto",               getValues("asunto"))
        formData.append("p_mandato",            getValues("p_mandato") ? 1 : 0)
        formData.append("tipo_comunicacion_id", 2)
        formData.append("role",                 "admin")
        formData.append("all",                  getValues("tipoGrupo"))
        formData.append("archivo",              getValues("archivo"))

        if (getValues('academia')) {
            this.getAcademiaParams(formData, getValues('academia'));
        }

        if (getValues('director')) {
            formData.append("destinatarios[director]", "")
        }

        if (getValues('coordinador')) {
            formData.append("destinatarios[coordinador]", "")
        }

        if (getValues('colegios')) {
            this.getColegioParams(formData, getValues('colegios'))
        }

        if (getValues('profesorado')) {
            this.getProfesoradoParams(formData, getValues('profesorado'))
        }
        new RequestComunicaciones().create(formData, callbackOk, callbackError)
    }

    getAcademiaParams(formData, params) {
        formData.append("destinatarios[academia][query][actividad_id]", params.actividad_id)
        formData.append("destinatarios[academia][query][curso_escolar_id]", params.curso_escolar_id)
        formData.append("destinatarios[academia][query][modalidad_curso_id]", params.modalidad_curso_id)
        formData.append("destinatarios[academia][query][tipo_actividad_id]", params.tipo_actividad_id)
        formData.append("destinatarios[academia][query][nivel_grupo_id]", params.nivel_grupo_id)
    }

    getColegioParams(formData, params) {
        formData.append('destinatarios[colegio][query][id_curso_escolar]', params.id_curso_escolar)
        formData.append('destinatarios[colegio][query][id_tipo_actividad]', params.id_tipo_actividad)
        formData.append('destinatarios[colegio][query][id_modalidad_cursos]', params.id_modalidad_cursos)
        formData.append('destinatarios[colegio][query][id_actividad]', params.id_actividad)
        formData.append('destinatarios[colegio][query][nivel_grupo_id]', params.nivel_grupo_id)

        if (Array.isArray(params.colegiosIds)) {
            for (const colegioId of params.colegiosIDs) {
                formData.append('destinatarios[colegio][query][colegiosIDs][]', colegioId)
            }
        } else {
            console.error('colegiosIDs no es un array válido');
        }
    }

    getProfesoradoParams(formData, params) {
        formData.append("destinatarios[profesorado]", "")

        formData.append("destinatarios[profesorado][query][id_curso_escolar]", params.id_curso_escolar)
        formData.append("destinatarios[profesorado][query][id_modalidad_cursos]", params.id_modalidad_cursos)
        formData.append("destinatarios[profesorado][query][id_tipo_actividad]", params.id_tipo_actividad)
        formData.append("destinatarios[profesorado][query][id_registro_actividad]", params.id_actividades)

        formData.append("destinatarios[profesorado][query][empresa]", params.empresa)
        formData.append("destinatarios[profesorado][query][particular]", params.particular)
        formData.append("destinatarios[profesorado][query][id_empresa]", params.id_empresa)
        formData.append("destinatarios[profesorado][query][id_colegio]", params.id_colegio)

        formData.append("destinatarios[profesorado][query][academia]", params.academia_cb)
        formData.append("destinatarios[profesorado][query][colegio]", params.colegios_cb)

        formData.append("destinatarios[profesorado][query][id_nivel_grupo_academia]", params.academia_cb == true ? params.id_nivel_grupo_academia : "")
        formData.append("destinatarios[profesorado][query][id_nivel_grupo_colegio]", params.colegios_cb == true ? params.id_nivel_grupo_colegio : "")
    }
}