import React from 'react'
import { Text } from 'components/texts'
import '../style.css'
import DeleteIcon from '@mui/icons-material/Delete'
import Statics from 'objects/common/Statics'

/**
 * Componente que representa un un horario de un grupo de la academia / clase particular
 *
 * @param {String} props.day - El día en el que se encuentra el evento.
 * @param {Object} props.event - La información detallada del evento académico o la clase particular.
 * @param {Function} props.calculateSteps - Función para calcular la cantidad de filas que debe ocupar el evento en el calendario.
 * @param {Function} props.onDeleteClick - Función que se ejecuta al hacer clic en el evento para eliminarlo (opcional, por defecto es una función vacía).
 * @param {Boolean} props.isSingle - Indica si el evento es único o forma parte de un grupo de eventos en el mismo día y hora.
 * @param {Number} props.offset - Valor opcional para ajustar la cantidad de filas que ocupa el evento en el calendario. Está puesto por si el horario empezaba por la mañana y continua por la tarde
 *
 * @returns {Component} - El componente de representación visual del evento en el calendario.
 */
export default function TemplateAcademiaClasesParticulares({ day, event, calculateSteps, onDeleteClick = () => { }, isSingle, offset = 0 }) {
    const txtColor = Statics.detectTextColorByBackgroundColor(event.color)

    const steps = calculateSteps(event.hora_inicio, event.hora_fin)
    return (
        <td key={day.value} rowSpan={steps - offset} style={{ backgroundColor: event.color, position: "relative", cursor: "pointer" }} onClick={() => onDeleteClick(event.id, event)}>
            { isSingle ? null : <DeleteIcon style={{ color: txtColor, position: 'absolute', top: 16, right: 16 }} /> }

            <Text color={txtColor}>{event.lugar_clase ?? ""}</Text>
        </td>
    )
}