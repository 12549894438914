import React, { useState, useEffect } from 'react'
import { RequestResponsable } from 'objects/requests'
import { useNavigate, useParams } from 'react-router'
import { AlumnoData, ResponsableData } from './parts'
import { Container, Paper, Stack } from '@mui/material'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
import BreadcrumbSkeleton from 'components/skeletons/navigation/navigationSkeleton.jsx'

/**
 * Hook useLogic para gestionar la lógica de negocio en la página de detalle del responsable.
 * Incluye la obtención de datos del responsable desde el servidor y la actualización del ID del responsable.
 * 
 * @param {Object} navigationParams - Parámetros obtenidos de la URL a través de useParams().
 * @param {Function} navigate - Función de react-router para realizar la navegación programática.
 * @returns {Object} Retorna un objeto con las funciones y estados necesarios para el componente.
 */
function useLogic(navigationParams, navigate) {
  const [id_responsable, setIdResponsable] = useState(navigationParams.id_responsable)
  const [responsable, setResponsable] = useState(null)

  /**
   * Realiza una solicitud al servidor para obtener la información detallada del responsable.
   * Utiliza el ID del responsable para realizar la petición y actualiza el estado con los datos obtenidos.
   */
  const getResponsable = () => {
    const params = { relations: 'alumnos,tipo_documento' }

    new RequestResponsable().get(id_responsable, params, (res) => {
      if (res.responsable)
        setResponsable(res.responsable)
    })
  }

  useEffect(getResponsable, [id_responsable])

  /**
   * Actualiza el ID del responsable en el estado y navega a la ruta correspondiente.
   * Esta función permite cambiar dinámicamente el responsable mostrado sin recargar la página.
   * 
   * @param {number} id - El nuevo ID del responsable a mostrar.
   */
  const updateId = (id) => {
    navigate(`../alumnos/tutores/${id}`);
    setIdResponsable(id);
  }

  return { getResponsable, id_responsable, updateId, responsable, setResponsable }
}

/**
 * Componente SingleResponsable para mostrar los detalles de un responsable específico.
 * Utiliza el hook useLogic para obtener y gestionar los datos del responsable.
 * 
 * @returns {JSX.Element} Estructura del componente para mostrar los detalles del responsable.
 */
export default function SingleResponsable() {
  const navigate = useNavigate()

  const navigationParams = useParams()

  const { getResponsable, id_responsable, updateId, responsable, setResponsable } = useLogic(navigationParams, navigate)

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>

      {/** //TODO: estaria guay hacer de este Stack un componente dinamico para todas las paginas */}
      {!responsable ? <BreadcrumbSkeleton numberLinks={3} navigation /> : (
        <Stack direction="row" justifyContent={"space-between"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/", label: "Inicio" },
            { to: "./../", label: "Tutores" },
            { to: "./", label: "Tutor #" + (id_responsable ?? "0000"), actualItem: true },
          ]} />

          <MoveButtons
            actualItemId={id_responsable}
            beforeFetchCallback={() => setResponsable(null)}
            onUpdateActualItem={updateId} />
        </Stack>
      )}

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
        <ResponsableData responsable={responsable} navigate={navigate} />

        <Line className={"my-10"} />

        <AlumnoData alumnos={responsable && responsable.alumnos ? responsable.alumnos : null} responsable={responsable} reload={getResponsable} />
      </Paper>
    </Container>
  )
}