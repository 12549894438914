import Statics from "objects/common/Statics"
import { RequestClasesParticulares, RequestVentaLibro, RequestPagosClasesParticulares } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {
    /**
     * Hace la petición para obtener la clase particular
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getClaseParticular(id, callbackOk = () => { }, callbackError = () => { }) {
        const params = {
            appends: ['venta_libro', 'venta_libro_asociado', 'empresa', 'empleado', 'registro_actividad', 'alumno', 'horarios', 'lugar_clase', 'profesor', 'forma_conocimiento', 'forma_recogida', 'libro', 'origen_profesor', 'sexo', 'pagos', 'curso_escolar']
        }
        
        new RequestClasesParticulares().get(id, params, (res) => { callbackOk(res.item ?? null) }, callbackError)
    }

    /**
     * Hace la petición para actualizar una clase particular
     * 
     * @param {Number}   id_clase_particular
     * @param {Object}   getValues
     * @param {Function} callbackOk
     * @param {Function} callbackError
     */
    editClaseParticular(id_clase_particular, getValues, callbackOk = () => {}, callbackError = () => {}) {        
        const formData = new FormData()

        //-- GENERAL --//
        formData.append('forma_recogida_id',        getValues("id_forma_recogida"))
        formData.append('forma_conocimiento_id',    getValues("id_forma_conocimiento"))
        formData.append('alumno_id',                getValues("alumno_id"))
        formData.append('curso_escolar_id',         getValues("id_curso_escolar"))
        formData.append('registro_actividad_id',    getValues("registro_actividad_id"))
        formData.append('precio_hora',              getValues("precio_hora"))
        formData.append('profesor_id',              getValues("profesor_id"))
        formData.append('encuestado',               getValues("encuesta_telefonica") ? 1 : 0)
        formData.append('alumno_colectivo',         getValues("alumno_colectivo") ? 1 : 0)
        formData.append('empresa_id',               getValues("id_empresa"))
        formData.append('avisado_inicio_clases',    getValues("avisado_comienzo_clases") ? 1 : 0)
        formData.append('observaciones',            getValues("observaciones").toUpperCase())
        formData.append('origen_profesor_id',       getValues("origen_profesor_id"))
        formData.append('sexo_id',                  getValues("sexo_id"))
        formData.append('clase_online',             getValues("clase_online") ? 1 : 0)
        formData.append('lugar_clase_id',           getValues("lugar_clase_id"))
        formData.append('comentarios',              getValues("comentarios").toUpperCase())
        formData.append('cancelaciones',            getValues("cancelaciones").toUpperCase())

        const fecha_inscripcion = getValues("fecha_inscripcion")
        formData.append('fecha_alta', Statics.datePickerTimeFormat(fecha_inscripcion))

        const fecha_inicio = getValues("fecha_inicio")
        formData.append('fecha_inicio', Statics.datePickerTimeFormat(fecha_inicio))

        const fecha_baja = getValues("fecha_baja")
        formData.append('fecha_baja', Statics.datePickerTimeFormat(fecha_baja))

        //-- PAGOS --//
        formData.append('forma_pago_id',        getValues("id_forma_pago"))
        formData.append('observaciones_pagos',  getValues("observaciones_pagos").toUpperCase())
        formData.append('contrato_emitido',     getValues("contrato_emitido") ? 1 : 0)
        formData.append('contrato_recibido',    getValues("contrato_recibido") ? 1 : 0)

        //-- LIBROS --//
        formData.append('libro_id', getValues("libro_id"))

        new RequestClasesParticulares().edit(id_clase_particular, formData, callbackOk, callbackError)
    }

    /**
     * Comprueba que peticion tiene que hacer
     */
    crudLibro(claseParticular, getValues, resolve, reject) {
        const libroSolicitado = getValues("libro_solicitado")

        if(claseParticular.venta_libro) {
            libroSolicitado ? this.editVentaLibro(claseParticular.venta_libro.id, getValues, resolve, reject) : this.deleteVentaLibro(claseParticular.venta_libro.id, resolve, reject)
        } else if (libroSolicitado) {
            this.createVentaLibro(claseParticular.id, getValues, resolve, reject)
        } else {
            resolve()
        }
    }
    
    /**
     * Comprueba que petición tiene que hacer (para los libros asociados)
     */
    crudLibroAsociado(claseParticular, getValues, resolve, reject) {
        const libroAsociadoSolicitado = getValues("libro_asociado_solicitado")

        if(claseParticular.venta_libro_asociado) {
            libroAsociadoSolicitado ? this.editVentaLibroAsociado(claseParticular.venta_libro_asociado.id, getValues, resolve, reject) : this.deleteVentaLibro(claseParticular.venta_libro_asociado.id, resolve, reject)
        } else if (libroAsociadoSolicitado) {
            this.createVentaLibroAsociado(claseParticular.id, getValues, resolve, reject)
        } else {
            resolve()
        }
    }

    /**
     * Método para crear un registro de una venta de un libro
     * 
     * @param {Int}      id_clase_particular 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createVentaLibro(id_clase_particular, getValues, callbackOk = () => {}, callbackError = () => {}) {
        let formData = this.ventaLibroFormdata(getValues)
        formData.append('inscripcion_id',   id_clase_particular)
        
        new RequestVentaLibro().create(formData, callbackOk, callbackError)
    }

    /**
     * Método para crear un registro de una venta de un libro asociado
     * 
     * @param {Int}      id_inscripcion 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createVentaLibroAsociado(id_clase_particular, getValues, callbackOk = () => {}, callbackError = () => {}) {
        let formData = this.ventaLibroFormdata(getValues, true)
        formData.append('inscripcion_id', id_clase_particular)

        new RequestVentaLibro().create(formData, callbackOk, callbackError)
    }

    /**
     * Método para editar un registro de una venta de un libro
     * 
     * @param {Int}      id 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editVentaLibro(id, getValues, callbackOk = () => {}, callbackError = () => {}) {
        const formData = this.ventaLibroFormdata(getValues)
        new RequestVentaLibro().edit(id, formData, callbackOk, callbackError)
    }

    /**
     * Método para editar un registro de una venta de un libro asociado
     * 
     * @param {Int}      id 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editVentaLibroAsociado(id, getValues, callbackOk = () => {}, callbackError = () => {}) {
        const formData = this.ventaLibroFormdata(getValues, true)
        new RequestVentaLibro().edit(id, formData, callbackOk, callbackError)
    }

    /**
     * Método para borrar un registro de la venta de un libro
     * 
     * @param {Int}      id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    deleteVentaLibro(id, callbackOk = () => {}, callbackError = () => {}) {
        new RequestVentaLibro().erase(id, new FormData(), callbackOk, callbackError)
    }

    /**
     * Crea y devuelve el FormData para las ventaLibro
     * 
     * @param {Function} getValues 
     */
    ventaLibroFormdata(getValues, asociado = false) {
        const sufix = asociado ? "_asociado" : ""

        let formData = new FormData
        formData.append('curso_escolar_id', getValues('id_curso_escolar'))
        formData.append('libro_id',         getValues(`libro${sufix}_id`) ?? "")
        formData.append('precio',           getValues(`precio${sufix}`))
        formData.append('inscripcion_type', 'inscripcion-clase-particular')
        if(asociado)
            formData.append('asociado', 1)
        
        const fecha_peticion = getValues(`fecha${sufix}_peticion`)
        formData.append("fecha_peticion", Statics.datePickerTimeFormat(fecha_peticion))

        const fecha_pago = getValues(`fecha_pago${sufix}`)
        formData.append("fecha_pago", Statics.datePickerTimeFormat(fecha_pago))

        const fecha_entrega = getValues(`fecha_entrega${sufix}`)
        formData.append("fecha_entrega", Statics.datePickerTimeFormat(fecha_entrega))

        return formData
    }

    /**
     * Método para crear los pagos de una clase particular
     * 
     * @param {Int}      id_inscripcion 
     * @param {Object}   pagos 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createPagos(id_clase_particular, pagos, callbackOk = () => {}, callbackError = () => {}, callbackFinally = () => {}) {
        const formData = new FormData
        formData.append('inscripcion_type', 'inscripcion-clase-particular')
        formData.append('inscripcion_id',   id_clase_particular)

        for (const index in pagos) {
            formData.append(`pagos[${index}][mes]`,          pagos[index].mes)
            formData.append(`pagos[${index}][fecha]`,        pagos[index].fecha ?? "")
            formData.append(`pagos[${index}][cantidad]`,     pagos[index].cantidad ?? "")
            formData.append(`pagos[${index}][pagado]`,       pagos[index].pagado ?? "")
            formData.append(`pagos[${index}][otros_cargos]`, pagos[index].otros_cargos ?? "")
            formData.append(`pagos[${index}][total]`,        pagos[index].total ?? 0)

        }

        new RequestPagosClasesParticulares().createMultiple(formData, callbackOk, callbackError, callbackFinally)
    }
}