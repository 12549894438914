import { useForm } from 'react-hook-form'
import { Text, H3 } from 'components/texts'
import { PagosPPAA } from 'components/forms/display'
import { yupResolver } from '@hookform/resolvers/yup'
import { RequestSubvencionPPAA } from 'objects/requests'
import { CreateHeader } from 'pages/logged/admin/components'
import { Container, Paper, Grid, Stack } from '@mui/material'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { ChangesContext } from 'contexts/ChangesContext/index'
import { BreadcrumbsList, Line } from 'components/data_display'
import React, { useRef, useEffect, useContext, useState } from 'react'
import validationSchema from 'components/forms/display/seguimiento-curso/PagosPPAA/Dataform/validation.js'

export default function CreatePagosPPAA() {
    const headerRef = useRef()

    const { register, handleSubmit, formState: { errors, dirtyFields }, setValue } = useForm({ resolver: yupResolver(validationSchema) })
    const { updateChanges } = useContext(ChangesContext)

    const [loading, setLoading] = useState(false)
    const [subvencionesInfo, setSubvencionesInfo] = useState({ cantidad: null, numSubvenciones: null })

    useEffect(() => {
        if (Object.keys(dirtyFields).length > 0)
            updateChanges(true)
    }, [Object.keys(dirtyFields).length])

    const doPagos = (values) => {
        setLoading(true)

        const formData = new FormData()
        formData.append('curso_escolar_id', values.curso_escolar_id)
        formData.append('mes', values.mes)

        if (values.colegio_id != 0)
            formData.append('colegio_id', values.colegio_id)

        new RequestSubvencionPPAA().pagos(formData, (res) => {
            setSubvencionesInfo({ cantidad: null, numSubvenciones: res.num_subvenciones })
            enqueueSnackbar(<Text>{res.ok}</Text>, { variant: "success" })
        }, (err) => {
            enqueueSnackbar(<Text>{err}</Text>, { variant: "error" })
            setSubvencionesInfo({ cantidad: 0, numSubvenciones: 0 })
        }, () => setLoading(false))
    }

    return (
        <Container maxWidth={false} sx={{ mt: 5 }}>
            <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(doPagos)}>
                <BreadcrumbsList breadcrumbsList={[
                    { to: "/", label: "Inicio" },
                    { to: "./../", label: "Seguimiento de curso" },
                    { to: "./", label: "Pagos PP.AA", actualItem: true },
                ]} />

                <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 4 }}>
                    <CreateHeader ref={headerRef} title={"Pagos PP.AA"} btnText={"Realizar"} loading={loading} />

                    <Line />

                    <PagosPPAA register={register} errors={errors} setValue={setValue} />

                    <SubvencionResult {...subvencionesInfo} />
                </Paper>

                <SnackbarProvider autoHideDuration={3000} />
            </form>
        </Container>
    )
}


function SubvencionResult({ numSubvenciones, cantidad }) {
    return (
        <Grid container mt={2} px={4} spacing={2}>
            <Grid item xs={12} mb={1}>
                <Stack>
                    <H3>Subvenciones Actualizadas</H3>
                </Stack>
            </Grid>

            <Grid item xs={6} >
                <Stack>
                    <Text fontWeight={"bold"}>Nº Subvenciones:</Text>
                    <Text>{numSubvenciones ?? "..."}</Text>
                </Stack>
            </Grid>

            <Grid item xs={6}>
                <Stack>
                    <Text fontWeight={"bold"}>Cantidad:</Text>
                    <Text>{cantidad ?? "..."}</Text>
                </Stack>
            </Grid>
        </Grid>
    )
}