import Statics from "objects/common/Statics"
import { useEffect, useState } from "react"

/**
 * Función `useLogic` utilizada para la lógica de preparación de parámetros y restablecimiento del formulario.
 * 
 * @param {function} resetForm - Función para restablecer el formulario.
 * @param {function} getValues - Función para obtener los valores del formulario.
 * @param {function} reset - Función para restablecer el formulario.
 * @returns {object} - Objeto que contiene el estado `resetFormState` y la función `setResetFormState`.
 */
export default function useSearchFormlogic(resetForm, getValues, reset) {
    let prepareParamsGlobal = null

    const [resetFormState, setResetFormState] = useState(false)

    useEffect(() => {
        if (resetFormState == true) {
            resetForm(reset)
            setResetFormState(false)
        }
    })

    /**
     * Prepara los parámetros que se van a usar en la búsqueda.
     * 
     * @param {Int} page - Página actual.
     * @returns {object} - Objeto con los parámetros de búsqueda preparados.
     */
    const prepareParams = (page) => {
        const params = Statics.objectPropsToUppercase(Statics.cleanObject(getValues()))
        params.activo = params.activo ? 1 : 0
        params.relations = 'pedidos_libro,devoluciones_libro,niveles_libro,editorial'
        params.atributtes = 'numVentasCurso'
        params.page = page

        return params
    }

    prepareParamsGlobal = prepareParams

    return { resetFormState, setResetFormState, prepareParamsGlobal }
}