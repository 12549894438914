import { Grid } from "@mui/material";
import { Horario } from 'components/data_display'
import { useEffect, useState } from "react";
import { ItemsSelect } from "components/forms/components/Select/custom";
import Skeleton from './skeleton'

const LUGAR_CLASE_CL        = 1
const LUGAR_CLASE_DOMICILIO = 2

/**
 * Pagina con los datos de los horarios de la clase particular
 */
export default function ClaseParticularHorarios({ claseParticular }) {
    //STATE
    const [lugarClaseId,  setLugarClaseId]  = useState(claseParticular?.lugar_clase_id ?? LUGAR_CLASE_DOMICILIO)
    const [aulaId,        setAulaId]        = useState(null)
    const [horarioToShow, setHorarioToShow] = useState([])

    const horariosArrayCL           = claseParticular?.horarios ? ( (claseParticular?.horarios).filter(horario => horario.aula_id != null) ) : []
    const horariosArrayDomicilio    = claseParticular?.horarios ? ( (claseParticular?.horarios).filter(horario => horario.aula_id == null) ) : []

    useEffect(() => {
        if (lugarClaseId == LUGAR_CLASE_DOMICILIO) {
            setHorarioToShow(horariosArrayDomicilio)
        } else {
            if(aulaId)
                setHorarioToShow(horariosArrayCL.filter(horario => horario.aula_id == aulaId))
        }
    }, [lugarClaseId, aulaId])


    return (
        <Grid container p={5}>
            {claseParticular ?
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <ItemsSelect
                                name         = {"lugar_clase_id"}
                                keyValue     = {"lugar_clase"}
                                defaultValue = {lugarClaseId}
                                onChange     = {
                                    (_, item) => { setLugarClaseId(item.id) }
                                } />
                        </Grid>

                        {lugarClaseId == LUGAR_CLASE_DOMICILIO ? null :
                            <Grid item xs={6}>
                                <ItemsSelect
                                    name         = {"aula_id"}
                                    keyValue     = {"aula"}
                                    defaultValue = {claseParticular?.aula_id ?? ""}
                                    onChange     = {
                                        (_, item) => { setAulaId(item.id) }
                                    } />
                            </Grid>
                        }
                    </Grid>

                    <Grid container mt={5}>
                        <Horario horarios={horarioToShow} type="clases-particulares" isSingle />
                    </Grid>
                </>
                : <Skeleton />}
        </Grid>
    )
}