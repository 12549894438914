import { Text } from "components/texts"
import { EmpleadoListContext } from "contexts/employee/ListContext"
import { enqueueSnackbar } from "notistack"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

/**
 * Hook personalizado para manejar la lógica de una actividad académica individual.
 * Obtiene parámetros de navegación y utiliza el contexto 'EmpleadoListContext' para buscar una actividad específica.
 * Si la actividad no está en el contexto, hace una solicitud a la API para recuperarla.
 * También maneja la navegación a diferentes actividades usando el método 'updateId'.
 *
 * @param {Object} navigationParams - Parámetros de navegación que contienen el 'id_actividad'.
 * @returns {Object} - Retorna el estado y las funciones para interactuar con los detalles de la actividad.
 */
export default function useLogic(navigationParams, requests) {
    const navigate = useNavigate()

    const { items } = useContext(EmpleadoListContext)

    const [id_actividad, setIdActividad] = useState(navigationParams.id_actividad)
    const [actividad, setActividad] = useState(null)

    const updateId = (id) => {
        navigate(`../academias/actividades/${id}`);
        setIdActividad(id);
    }

    useEffect(() => {
        const actividad = items.find(item => item?.id == id_actividad)
        if (items.length == 0 || !actividad) {
            requests.getActividad(id_actividad, setActividad, () => enqueueSnackbar(<Text>No se ha podido recuperar las actividades de la actividad.</Text>, { variant: "error" }))
        } else {
            setActividad(actividad)
        }
    }, [id_actividad])

    return { id_actividad, setActividad, actividad, updateId }
}