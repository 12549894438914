import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import { CardButton } from 'components/forms/components';
import Requests from './Requests'
import { useParams } from 'react-router'
import { enqueueSnackbar, SnackbarProvider } from 'notistack'
import { Text } from 'components/texts';
import { MesSelect } from 'components/forms/components/Select/custom'
import * as yup from 'yup';

const schema = yup.object().shape({
    mesSeleccionado: yup.number().test('isRequired', 'El mes es obligatorio', (value) => {
        return value !== undefined && value !== '';
    }),
});

/**
 * Template para obtener los listados 
 */
export default function ListadosDataForm({ register, errors, isSingle }) {
    const navigationParams = useParams()
    const [id_empleado, setIdEmpleado] = useState(navigationParams.id_empleado)
    const [valorSeleccionado, setValorSeleccionado] = useState(null);

    const handleValorSeleccionado = (value) => {
        if (value) {
            setValorSeleccionado(value.target.value);
        } else {
            enqueueSnackbar(<Text>Debe indicar el mes.</Text>, { variant: "error" })
        }
    }

    const getListadoGrupoProfesor = () => {
        new Requests().getListadoEmpleado(id_empleado, {}, () => {
        }, (res) => {
            enqueueSnackbar(<Text>{res}</Text>, { variant: "error" })
        })
    }

    const getHorarioEmpleado = () => {
        let params = { mes: valorSeleccionado }
        new Requests().getHorarioEmpleado(id_empleado, params, () => {
        }, (res) => {
            enqueueSnackbar(<Text>{res}</Text>, { variant: "error" })
        })
    }

    return (
        <Grid container px={5} mt={4} justifyContent="center">
            <Grid item xs={6} mt={9}>
                <CardButton disabled={isSingle} title={'Grupos del profesor'} buttonOnClick={getListadoGrupoProfesor} />
            </Grid>

            <Grid item mt={3} >
                <div style={{ marginBottom: '16px' }}>
                    <MesSelect disabled={isSingle} name="mesSeleccionado" width={'95%'} register={register} errors={errors} onChange={handleValorSeleccionado} />
                </div >
                <CardButton disabled={isSingle} title={'Horario del profesor'} buttonOnClick={getHorarioEmpleado} />
            </Grid>

            <SnackbarProvider autoHideDuration={5000} />
        </Grid>
    )
}


