import React, { Component } from 'react'
import Skeleton from '@mui/material/Skeleton';
import { Grid } from '@mui/material';

/**
 * SKELETON
 */
export default class SkeletonDatosEmpleado extends Component {

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
      <Grid container px={5} spacing={2} mt={2}>

        <Grid item xs={4}>
          <Skeleton variant="rounded" width={"50%"} height={100} />
        </Grid>

        <Grid item xs={4}>
          <Skeleton variant="rounded" width={"90%"} height={25} />
          <Skeleton variant="rounded" width={"90%"} height={25} sx={{ mt: 1 }} />
        </Grid>

        <Grid item xs={4}>
          <Skeleton variant="rounded" width={"90%"} height={25} />
          <Skeleton variant="rounded" width={"90%"} height={25} sx={{ mt: 1 }} />
        </Grid>

        <Grid item xs={4}>
          <Skeleton variant="rounded" width={"90%"} height={25} />
        </Grid>

        <Grid item xs={4}>
          <Skeleton variant="rounded" width={"90%"} height={25} />
        </Grid>

        <Grid item xs={4}>
          <Skeleton variant="rounded" width={"90%"} height={25} />
        </Grid>

        <Grid item xs={4} >
          <Skeleton variant="rounded" width={"90%"} height={25} />
          <Skeleton sx={{ mt: 1 }} variant="rounded" width={"90%"} height={25} />
        </Grid>

        <Grid item xs={4} >
          <Skeleton variant="rounded" width={"90%"} height={25} />
          <Skeleton sx={{ mt: 1 }} variant="rounded" width={"90%"} height={25} />
        </Grid>

        <Grid item xs={4} >
          <Skeleton variant="rounded" width={"90%"} height={25} />
          <Skeleton sx={{ mt: 1 }} variant="rounded" width={"90%"} height={25} />
        </Grid>

        <Grid item xs={4} >
          <Skeleton variant="rounded" width={"90%"} height={25} />
          <Skeleton sx={{ mt: 1 }} variant="rounded" width={"90%"} height={25} />
        </Grid>

        <Grid item xs={4} >
          <Skeleton variant="rounded" width={"90%"} height={25} />
          <Skeleton sx={{ mt: 1 }} variant="rounded" width={"90%"} height={25} />
        </Grid>

        <Grid item xs={4} >
          <Skeleton variant="rounded" width={"90%"} height={25} />
          <Skeleton sx={{ mt: 1 }} variant="rounded" width={"90%"} height={25} />
        </Grid>

      </Grid>
    )
  }
}
