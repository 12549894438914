import { Grid } from '@mui/material'
import React from 'react'
import { Text } from 'components/texts'
import { Input } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * `ActividadTemplate` es un componente de formulario que ofrece campos de entrada para buscar actividades académicas por datos básicos.
 * Incluye campos para el nombre de la actividad, tipo de actividad, identificador de actividad, modalidad de los cursos y nombre de la academia.
 *
 * @param {function} register - Método de 'react-hook-form' para registrar los componentes de entrada.
 * @param {Object} errors - Objeto que contiene los errores de validación del formulario proporcionados por 'react-hook-form'.
 * @param {boolean} reset - Indica si los campos de selección deben restablecer su estado.
 *
 * @returns {JSX.Element} - Un conjunto de campos de entrada y selección agrupados en una cuadrícula para la búsqueda de actividades.
 */
export default function ActividadTemplate({ register, errors, reset }) {

    return (
        <>
            <Grid container mt={2} mb={1}>
                <Text fontWeight={'bold'}>Según datos de:</Text><br />
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Input label="Nombre" width={"100%"} name={"nombre"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <ItemsSelect name={"tipo_actividad_id"} keyValue={"tipo_actividad"} register={register} errors={errors} wantToReset={reset} />
                </Grid>

                <Grid item xs={4}>
                    <ItemsSelect name={"actividad_id"} keyValue={"actividad"} register={register} errors={errors} wantToReset={reset} />
                </Grid>

                <Grid item xs={4}>
                    <ItemsSelect name={"modalidad_cursos_id"} keyValue={"modalidad_cursos"} register={register} errors={errors} wantToReset={reset} />
                </Grid>
            </Grid>
        </>
    )
}