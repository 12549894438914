import { Grid } from '@mui/material'
import React, { Component } from 'react'
import Skeleton from '@mui/material/Skeleton'

/**
 * Informacion básica del alumno
 */
export default class SkeletonResponsableData extends Component {

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
        <Grid container spacing={2} mt={2}>
            <Grid item xs={2}>
                <Skeleton variant="text" sx={{ fontSize: '2em' }} width={300}/>
            </Grid>

            <Grid container item mt={5}>
                <Grid item xs={3}>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"}/>
                </Grid>
                
                <Grid item xs={3}>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"}/>
                </Grid>
                
                <Grid item xs={3}>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"}/>
                </Grid>

                <Grid item xs={3}>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"}/>
                </Grid>
            </Grid>
        </Grid>
    )
  }
}
