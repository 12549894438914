import { useAlert } from "hooks"
import { RequestLibro } from "objects/requests"
import { useEffect, useRef, useState } from "react"

/**
 * Este hook se encarga de gestionar la lógica específica para el manejo de libros en el formulario de idiomas
 * de un grupo de academia. Incluye la selección de libros, el manejo de libros asociados, y la carga dinámica
 * de información de libros desde el servidor.
 * 
 * @param {Function} onChangeLibro - Callback para actualizar el libro seleccionado en el componente padre.
 * @param {Object} grupo - Objeto con la información actual del grupo, incluyendo el libro seleccionado.
 * 
 * @returns {Object} - Objeto con estados y funciones para su uso en el formulario de idiomas.
 */
export default function useIdiomaDataFormLogic({ onChangeLibro, grupo }) {
    const updateAlert = useAlert()

    const libroAsociadoCheck = useRef()

    const [libroAsociado, setLibroAsociado] = useState(grupo?.libro?.libro_asociado ?? null)
    const [libro, setLibro] = useState(grupo?.libro ?? null)
    const [updateSelect, setUpdateSelect] = useState({ update: false, value: 0 })
    const [loadingLibroAsociado, setLoadingLibroAsociado] = useState(false)

    /**
     * Maneja cambios en la selección del libro principal.
     * Actualiza la información del libro y su asociado de ser necesario.
     * 
     * @param {*} event - El evento de cambio.
     * @param {Object} libro - El libro seleccionado.
     */
    function handleChangeLibro(event, libro) {
        if (libro)
            refreshLibroData(libro)

        if (!libro || !libro.libro_asociado || !libroAsociadoCheck.current.getChecked())
            libroAsociadoCheck.current.setChecked(false)

        setLibro(libro)
        onChangeLibro(libro)
    }

    /**
     * Obtiene y actualiza la información del libro asociado desde el servidor.
     * Utiliza el objeto RequestLibro para hacer la petición y manejar la respuesta.
     * 
     * @param {Object} libro - El libro para el cual se obtendrá la información asociada.
     */
    const refreshLibroData = (libro) => {
        setLoadingLibroAsociado(true)
        const params = { nombre: libro?.nombre, activo: libro?.activo }

        new RequestLibro().search(params, (res) => {
            const updatedLibroAsociado = res.items.data[0].libro_asociado
            setLibroAsociado(updatedLibroAsociado)
            setLoadingLibroAsociado(false)
        }, (err) => {
            updateAlert('Ha habido un error al obtener el libro asociado.')
            setLoadingLibroAsociado(false)
        })
    }

    useEffect(() => {
        if (updateSelect.update)
            setUpdateSelect({ update: false, value: 0 })
    }, [updateSelect.update])

    return { libroAsociado, loadingLibroAsociado, handleChangeLibro, updateSelect, libroAsociadoCheck }
}