

import './style.css'
import { v4 as uuid } from 'uuid';
import { months } from './constants'
import { H3, Text } from 'components/texts';
import { Checkbox } from 'components/forms/components/';
import React, { Component, useRef, useState } from 'react';
import { enqueueSnackbar } from 'notistack';

const pagoInscripcionObject = {
    id: null,
    mes: null,
    fecha: null,
    cantidad: null,
    pagado: 0,
    otros_cargos: null,
    subvencion: null,
    total: null,
    id_inscripcion_colegio: null,
}

const initializeArray = () => {
    return months.map(month => ({ ...pagoInscripcionObject, mes: month.value }))
}

const TableRow = ({ pagos, backgroundClass, name, arrayItemName, formatter = null, type = "number", updateState = () => { } }) => {

    const [updatedRows, setUpdatedRows] = useState([])

    // const onEdit = (newValue, month) => {
    //     const index = pagos.findIndex(item => item.mes == month.value)
    //     if (index != -1) {
    //         // pagos[index][arrayItemName] = newValue ? (type == "number" ? parseFloat(newValue) : newValue) : ("")
    //         pagos[index][arrayItemName] = newValue ? (type === "number" ? parseFloat(newValue) : newValue) : null
    //         pagos[index]['total'] = (pagos[index]['otros_cargos'] ?? 0) + (pagos[index]['cantidad'] ?? 0)


    //         const updatedPagosInfo = {
    //             mes: month.label,
    //             total: pagos[index]['total']
    //         }

    //         setUpdatedRows(prevUpdatedRows => {
    //             let newUpdatedRows = [...prevUpdatedRows];
    //             const existingMonthIndex = newUpdatedRows.findIndex(item => item.mes === updatedPagosInfo.mes);

    //             if (existingMonthIndex !== -1) {
    //                 // Si el total es 0 y el valor es nulo, elimina el mes del array.
    //                 if (pagos[index]['total'] === 0 && pagos[index][arrayItemName] === null) {
    //                     newUpdatedRows.splice(existingMonthIndex, 1);
    //                 } else {
    //                     // De lo contrario, actualiza la información existente.
    //                     newUpdatedRows[existingMonthIndex] = updatedPagosInfo;
    //                 }
    //             } else if (pagos[index]['total'] !== 0) {
    //                 // Si el mes no existía y el total no es 0, agrega la nueva actualización.
    //                 newUpdatedRows.push(updatedPagosInfo);
    //             }
    //             // Llama a updateState aquí, con la nueva lista de pagos actualizados.
    //             updateState(pagos, newUpdatedRows);
    //             return newUpdatedRows;
    //         })
    //     }
    // }

    const onEdit = (newValue, month) => {
        const index = pagos.findIndex(item => item.mes == month.value);
        if (index != -1) {
            // Convierte el valor a flotante o lo establece como nulo si es vacío.
            pagos[index][arrayItemName] = newValue ? (type === "number" ? parseFloat(newValue) : newValue) : null
            // Calcula el total correcto.
            pagos[index]['total'] = (pagos[index]['otros_cargos'] ?? 0) + (pagos[index]['cantidad'] ?? 0)

            // Prepara la información actualizada del pago para este mes.
            const updatedPagosInfo = {
                mes: month.value,
                total: pagos[index]['total']
            }

            // Llama directamente a updateState con la información actualizada.
            updateState(pagos, pagos.map(pago =>
                pago.mes === month.value ? updatedPagosInfo : pago
            ).filter(pago => pago.total !== 0 || pago[arrayItemName] !== null))
        }
    }

    return (
        <tr className={backgroundClass}>
            <td style={{ minWidth: 150 }}><Text fontWeight={"bold"}>{name}</Text></td>
            {months.map(month => {
                const pago = pagos.find(item => item.mes == month.value)
                const value = pago ? pago[arrayItemName] : ""
                const disabled = pagos.find(item => item.mes == month.value)?.pagado ? true : false
                return <TableColumn key={uuid()} itemName={arrayItemName} defaultValue={formatter && value ? formatter(value) : value} onEdit={(newValue) => onEdit(newValue, month)} type={type} disabled={disabled} />
            })}
        </tr>
    )
}

const TableColumn = ({ defaultValue, onEdit, type, disabled = false, itemName }) => {
    const input = useRef()
    const [value, setValue] = useState(defaultValue ?? "")
    const [previousValue, setPreviousValue] = useState(defaultValue) // Estado para rastrear el valor anterior

    const checkDisabled = () => {
        if (disabled)
            enqueueSnackbar(<Text>Este mes ya está pagado y no se puede modificar.</Text>, { variant: "error" })
    }

    return (
        <td onClick={() => checkDisabled()}>
            <input
                ref={input}
                type={type}
                value={value}
                onChange={(e) => {
                    setValue(e.target.value)

                    if (type === "date")
                        onEdit(e.target.value)
                }}
                // onBlur={() => onEdit(value)}
                onBlur={() => {
                    // Llama onEdit solo si el valor ha cambiado
                    if (value !== previousValue) {
                        onEdit(value)
                        setPreviousValue(value) // Actualiza el valor anterior
                    }
                }}
                onKeyDown={(event) => {
                    if (event.code === 'Enter')
                        input.current.blur()

                    if (["e", "E", "+", "-"].includes(event.key))
                        event.preventDefault()
                }}
                disabled={disabled} />
        </td>
    )
}

export default class MonthsTable extends Component {

    constructor(props) {
        super(props)

        this.state = {
            pagos: this.initializePagos(),
        }
    }

    componentDidMount() {
        this.props.setValue('pagos', this.state.pagos)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.inscripcion != this.props.inscripcion) {
            this.updatePagos(this.initializePagos())
        }
    }

    initializePagos = () => {
        let pagos = []
        if (!this.props.inscripcion || !this.props.inscripcion.pagos || this.props.inscripcion.pagos.length == 0)
            return initializeArray()

        pagos = months.map(month => {
            const exists = this.props.inscripcion.pagos.find(item => item.mes == month.value)
            return exists ?? { ...pagoInscripcionObject, mes: month.value }
        })

        return pagos
    }

    getPagos = () => {
        return this.state.pagos
    }

    updatePagos = (pagos = [], updatedPagos = {}) => {
        this.setState({ pagos })
        if (this.props.setValue && pagos) {
            this.props.setValue("pagos", pagos)
        }
        if (this.props.onUpdateCallback)
            this.props.onUpdateCallback(updatedPagos)
    }

    checkForDecimals = (number) => {
        const decimalRegex = /^\d+\.\d+$/;
        return decimalRegex.test(number) ? number.toFixed(2) : number;
    }

    render() {
        const { pagos } = this.state

        return (
            <table className='meses-table'>

                <tbody className='no-border'>

                    {/** ******************** MES PAGADO ******************** */}
                    <tr>
                        <td className='no-border' style={{ minWidth: 150 }}><Text fontWeight={"bold"}>Mes pagado:</Text></td>
                        {months.map(month => (
                            <td className='no-border' key={uuid()}>
                                <Checkbox
                                    name={month.label}
                                    register={this.props.register}
                                    defaultChecked={pagos.find(item => item.mes == month.value)?.pagado ? true : false}
                                    onClick={(checked) => {
                                        const index = pagos.findIndex(item => item.mes == month.value)
                                        if (index != -1) {
                                            pagos[index]['pagado'] = checked ? 1 : 0
                                            this.updatePagos(pagos)
                                            if (this.props.setValue)
                                                this.props.setValue("pagos", pagos)
                                        }
                                    }} />
                            </td>
                        ))}
                    </tr>

                    {/** ************************* MESES ********************** */}
                    <tr>
                        <td className='no-border'></td>
                        {months.map(month => (
                            <td key={uuid()}><H3>{month.label}</H3></td>
                        ))}
                    </tr>

                    {/** ********************* MENSUALIDAD ******************** */}
                    <TableRow pagos={pagos} backgroundClass="red-bg" name="Mensualidad" arrayItemName={"cantidad"} updateState={this.updatePagos} />

                    {/** ******************** OTROS CARGOS ******************** */}
                    <TableRow pagos={pagos} backgroundClass="green-bg" name="Otros cargos" arrayItemName={"otros_cargos"} updateState={this.updatePagos} />

                    {/** ************************ TOTAL *********************** */}
                    <tr className={"yellow-bg"}>
                        <td style={{ minWidth: 150 }}><Text fontWeight={"bold"}>Total</Text></td>
                        {months.map(month => {
                            const pago = pagos.find(item => item.mes == month.value)

                            let value = pago && pago["cantidad"] ? parseFloat(pago["cantidad"]) : 0
                            if (pago && pago["otros_cargos"])
                                value += parseFloat(pago["otros_cargos"])

                            let total = value
                            if (pago && total)
                                total = this.checkForDecimals(total)
                            return <TableColumn key={uuid()} defaultValue={total} type={"number"} disabled={true} />
                        })}
                    </tr>

                    <tr>
                        <td colSpan={13} className='no-border' style={{ paddingTop: 10, paddingBottom: 10 }} />
                    </tr>

                    {/** ******************** FECHA DE PAGO ******************** */}
                    <TableRow pagos={pagos} name="Fecha de pago" arrayItemName={"fecha"} type={"date"} />
                </tbody>

            </table>
        );
    }
}; 