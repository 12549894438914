import { useEffect, useState } from "react"
import useAlert from "./useAlert"
import { RequestImage } from 'objects/requests'

/**
 * Hook `useImageLoader` para cargar dinámicamente imágenes de diferentes entidades.
 *
 * @param {Object} item - Objeto que contiene los datos del item, incluyendo la ruta de la imagen y el tipo.
 * @param {string} imageKey - La clave en el objeto item que tiene la ruta de la imagen.
 * @param {string} defaultImagePath - La ruta de la imagen por defecto en caso de que item[imageKey] no exista.
 * @param {boolean} returnAsUrl - Si es verdadero, convierte los Blobs a URLs de objeto.
 * @returns {Object} Retorna un objeto con la imagen cargada para ser utilizada en el componente.
 */
export default function useImageLoader(item, imageKey, defaultImagePath, returnAsUrl = false) {
    const updateAlert = useAlert()
    const [imageSrc, setImageSrc] = useState(null)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        // Solo hacer la solicitud si la imagen no ha sido cargada o si el identificador de la imagen cambia.
        // Solo hacer la solicitud si item existe 
        if (item && item[imageKey]) {
                const imagePath = item[imageKey]
                getImage(imagePath)
        } else {
            setImageSrc(defaultImagePath)
        }
    }, [item])

    const getImage = (path) => {
        new RequestImage().get(path, {}, (res) => {
            if (res instanceof Blob && returnAsUrl) {
                const objectURL = URL.createObjectURL(res)
                setImageSrc(objectURL);
                setLoaded(true); // Marcar la imagen como cargada
            } else {
                setImageSrc(res);
                setLoaded(true); // Marcar la imagen como cargada
            }
        }, (err) => {
            updateAlert('Ha habido un error al cargar la imagen.');
            setLoaded(false); // Marcar la imagen como no cargada si hay un error
        });
    };

    // Efecto de limpieza para revocar la URL de objeto cuando el componente se desmonta
    useEffect(() => {
        return () => {
            if (imageSrc && returnAsUrl) {
                URL.revokeObjectURL(imageSrc);
            }
        };
    }, [imageSrc, returnAsUrl]);

    return { imageSrc }
}