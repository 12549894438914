import { Box, Grid, Stack } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { Line } from 'components/data_display';

export default function SkeletonDataFormIdiomaGrupoColegio() {
    return (
        <Grid container py={5}>
            <Grid container spacing={2} px={5}>
                <Grid item xs={6}>
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                    
                </Grid>
                <Grid item xs={6}>
                    <Skeleton variant="rounded" width={"100%"} height={40} />
                </Grid>
            </Grid>

            <Grid container spacing={2} px={5}>
                <Grid item xs={6} />

                <Grid item xs={6} mt={1}>
                    <Skeleton variant="rounded" width={"20%"} height={20} />
                </Grid>
            </Grid>
            
            <Grid container my={3} px={5}>
                <Line />
            </Grid>

            <Grid container px={5}>
                <Grid item xs={12}>
                    <Stack spacing={2}>
                        <Skeleton variant="rounded" width={"30%"} height={30} />
                        <Skeleton variant="rounded" width={"20%"} height={20} />
                    </Stack>
                </Grid>

                <Grid item xs={12} mt={2}>
                    <Skeleton variant="rounded" width={"100%"} height={200} />
                </Grid>
            </Grid>
        </Grid>
    )
}
