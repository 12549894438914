import { v4 as uuid } from 'uuid'
import React, { Component } from 'react'
import { Select } from 'components/forms/components'

const options = [
    {value: 1,  label: "Enero"},
    {value: 2,  label: "Febrero"},
    {value: 3,  label: "Marzo"},
    {value: 4,  label: "Abril"},
    {value: 5,  label: "Mayo"},
    {value: 6,  label: "Junio"},
    {value: 7,  label: "Julio"},
    {value: 8,  label: "Agosto"},
    {value: 9,  label: "Septiembre"},
    {value: 10, label: "Octubre"},
    {value: 11, label: "Noviembre"},
    {value: 12, label: "Diciembre"},
]

export default class MesSelect extends Component {

    /**
     * Constructor
     * 
     * @param {Object} props 
     */
    constructor(props) {
        super(props)
    }

    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        const labelId = "mes_select-id-" + uuid()
        return (
            <Select 
                ref          = {(ref) => this.select = ref}
                width        = {this.props.width ?? "100%"}
                minWidth     = {this.props.minWidth}
                items        = {options}
                register     = {this.props.register}
                defaultValue = {this.props.defaultValue}
                onChange     = {this.props.onChange}
                className    = {this.props.className}
                name         = {this.props.name ?? "mes"}
                errors       = {this.props.errors}
                wantToReset  = {this.props.wantToReset}
                disabled     = {this.props.disabled}
                labelId      = {labelId} 
                label        = {this.props.label ?? "Mes"}/>
        )
    }
}
