import { RequestLibro, RequestNivelLibro, RequestPedidoLibro, RequestDevolucionLibro } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Hace la petición para crear el colegio
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createLibro(getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()
        formData.append("nombre", getValues("nombre").toUpperCase())
        formData.append("isbn", getValues("isbn").toUpperCase())
        formData.append("id_editorial", getValues("id_editorial"))
        formData.append("abreviatura", getValues("abreviatura").toUpperCase())
        formData.append("activo", getValues("activo") ? 1 : 0)
        formData.append("id_libro", getValues("id_libro"))
        formData.append("precio_editorial", getValues("precio_editorial"))
        formData.append("pvp", getValues("pvp"))

        formData.append("programacion_1t", getValues("programacion_1t")[0] ?? "")
        formData.append("programacion_2t", getValues("programacion_2t")[0] ?? "")
        formData.append("programacion_3t", getValues("programacion_3t")[0] ?? "")

        new RequestLibro().post("", formData, callbackOk, callbackError)
    }


    /**
     * Hace la petición para crear los niveles
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createNiveles(id_libro, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const niveles = getValues("niveles")
        if (niveles.length > 0) {
            const formData = new FormData()
            formData.append("id_libro", id_libro)

            for (const nivel of niveles)
                formData.append("ids_niveles[]", nivel)

            new RequestNivelLibro().createMultiple(formData, callbackOk, callbackError)
        } else {
            callbackOk()
        }
    }


    /**
     * Hace la petición para crear los libros pedidos
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createLibrosPedidos(libroId, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const librosPedidos = getValues("librosPedidos")?.filter(item => ((item.cantidad_llegada || item.cantidad_pedida) && item.deleted != true && item.new == true))

        if (librosPedidos?.length > 0) {
            const formData = new FormData()

            librosPedidos.forEach((libro, index) => {
                formData.append(`pedidos[${index}][id_curso_escolar]`, libro.id_curso_escolar)
                formData.append(`pedidos[${index}][id_libro]`, libroId)
                formData.append(`pedidos[${index}][cantidad_pedida]`, libro.cantidad_pedida)
                formData.append(`pedidos[${index}][cantidad_llegada]`, libro.cantidad_llegada)
                formData.append(`pedidos[${index}][fecha]`, libro.fecha)
            })

            new RequestPedidoLibro().create(formData, callbackOk, callbackError)
        } else {
            callbackOk()
        }
        // if (librosPedidos.length > 0) {
        //     const formData = new FormData()
        //     formData.append("id_libro", id_libro)

        //     for (const libro of librosPedidos)
        //         formData.append("pedidos[]", JSON.stringify(libro))

        //     new RequestPedidoLibro().createMultiple(formData, callbackOk, callbackError)
        // } else {
        //     callbackOk()
        // }
    }


    /**
     * Hace la petición para crear los libros devueltos
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createLibrosDevueltos(libroId, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const librosDevueltos = getValues("librosDevueltos")?.filter(item => (item.cantidad_devuelta && item.deleted != true && item.edit != true && item.new == true))

        if (librosDevueltos?.length > 0) {
            const formData = new FormData()

            librosDevueltos.forEach((libro, index) => {
                formData.append(`devoluciones[${index}][id_curso_escolar]`, libro.id_curso_escolar)
                formData.append(`devoluciones[${index}][id_libro]`, libroId)
                formData.append(`devoluciones[${index}][cantidad_devuelta]`, libro.cantidad_devuelta)
                formData.append(`devoluciones[${index}][fecha]`, libro.fecha)
            })

            new RequestDevolucionLibro().create(formData, callbackOk, callbackError)
        } else {
            callbackOk()
        }
        // if (librosDevueltos.length > 0) {
        //     const formData = new FormData()
        //     formData.append("id_libro", id_libro)

        //     for (const libro of librosDevueltos)
        //         formData.append("devoluciones[]", JSON.stringify(libro))

        //     new RequestDevolucionLibro().createMultiple(formData, callbackOk, callbackError)
        // } else {
        //     callbackOk()
        // }
    }
}
