import Requests from './Requests/Requests.js'
import { useParams } from 'react-router'
import { Container, Paper, Stack } from '@mui/material'
import { EditHeader } from 'pages/logged/admin/components'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
import { useResponsableEditLogic, useResponsableEditWrapperLogic } from 'hooks'
import { DataFormResponsable } from 'components/forms/display'
import Skeleton from 'components/forms/display/responsable/DataForm/skeleton.jsx'

/**
 * Wrapper que gestiona la edición de un responsable.
 * 
 * Este componente utiliza la lógica encapsulada en useWrapperLogic para manejar el estado del responsable
 * y realizar solicitudes para obtener su información. Luego, renderiza el componente EditResponsable
 * para mostrar el formulario de edición del responsable.
 * 
 * @returns {JSX.Element} - El componente Wrapper para editar un responsable.
 */
const Wrapper = () => {
	const navigationParams = useParams()

	const { getResponsable, updateAlert, id_responsable, responsable, setResponsable, setIdResponsable } = useResponsableEditWrapperLogic(navigationParams, new Requests())

	return (
		<React.Fragment>
			<EditResponsable key={responsable?.id ?? 0} id_responsable={id_responsable} responsable={responsable} setIdResponsable={setIdResponsable} setResponsable={setResponsable} updateAlert={updateAlert} onSaveFinish={() => getResponsable(true)} />

			<SnackbarProvider autoHideDuration={3000} />
		</React.Fragment>
	)
}
export default Wrapper

/**
 * Página para editar un responsable.
 * 
 * Este componente se encarga de mostrar el formulario de edición del responsable.
 * 
 * @param {Object} responsable - La información del responsable a editar.
 * @param {number} id_responsable - El ID del responsable.
 * @param {Function} setIdResponsable - Función para actualizar el ID del responsable.
 * @param {Function} setResponsable - Función para actualizar la información del responsable.
 * @param {Function} updateAlert - Función para mostrar alertas.
 * @param {Function} onSaveFinish - Función que se ejecuta al finalizar la operación de guardado.
 * 
 * @returns {JSX.Element} - El formulario de edición del responsable.
 */
function EditResponsable({ responsable, id_responsable, setIdResponsable, setResponsable, updateAlert, onSaveFinish }) {
	const { headerRef, updateId, register, handleSubmit, errors, editResponsable } = useResponsableEditLogic(setIdResponsable, id_responsable, updateAlert, onSaveFinish, new Requests())

	return (
		<Container maxWidth={false} sx={{ mt: 5 }}>
			<form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(editResponsable)}>

				<Stack direction="row" justifyContent={"space-between"} marginX={8}>
					<BreadcrumbsList mx={0} breadcrumbsList={[
						{ to: "/", label: "Inicio" },
						{ to: "./../../", label: "Tutores" },
						{ to: "./../", label: "Tutor #" + (id_responsable ?? "0000") },
						{ to: "./", label: "Editar", actualItem: true },
					]} />

					<MoveButtons
						actualItemId={id_responsable}
						beforeFetchCallback={() => setResponsable(null)}
						onUpdateActualItem={updateId} />
				</Stack>

				<Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
					<EditHeader
						ref={headerRef}
						title={"Editar tutor"}
						item={responsable} />

					<Line className={"mb-5"} />

					{responsable ? <DataFormResponsable responsable={responsable} register={register} errors={errors} /> : <Skeleton />}
				</Paper>
			</form>
			<SnackbarProvider autoHideDuration={3000} />
		</Container>
	)
}