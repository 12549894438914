import { LoadingButton } from 'components/forms/components/'
import { H3, Text } from 'components/texts'
import { Grid } from '@mui/material'

/**
 * Componente edit que se usa en el header para visualizar el boton de guardar cambios.
 * title -> Es el titulo de la acción, es decir, si quiere editar una inscripción el title seria: Editar inscripción.
 * data -> Los datos suelen ser el nombre de esta inscripción, por ejemplo, si es un alumno seria su apellido2 apellido1, nombre
 * href -> Es lo que hace que funcione el botón
 */
export default function Edit({ headerRef, title, data }) {
    return (
        <Grid container alignItems={"center"} px={4} py={2}>
            <Grid item xs={10} display={"flex"}>
                <H3 className={"m-0"}>{title ?? ""}</H3>
                <Text className="standard mx-5">{data}</Text>
            </Grid>
            <Grid item xs={2} display={"flex"} justifyContent={"end"}>
                <button style={{ border: 'none', background: 'none' }} type='submit'>
                    <LoadingButton ref={headerRef} color={'success'} size={"small"}>
                        Guardar cambios
                    </LoadingButton>
                </button>
            </Grid>
        </Grid>
    )
}