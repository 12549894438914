import React from 'react'
import Skeleton from './skeleton'
// import { ASSETS } from 'constants/Assets'
import { H3, Text } from 'components/texts'
import { Avatar, Box, Grid } from '@mui/material'
import { useImageLoader } from 'hooks'
import { DEFAULT_IMAGES_URL } from 'constants/DefaultImagesUR'

/**
 * Componente `DatosColegioAlumnoData` para visualizar la información del alumno asociada a datos específicos de colegio.
 * Muestra información detallada del alumno como su foto, nombre completo, documento de identidad y género.
 * En caso de que los datos no estén disponibles, muestra un esqueleto de carga.
 * 
 * @param {Object} datosColegio - Objeto que contiene los datos del colegio y la información del alumno asociado.
 * @returns {JSX.Element} Estructura de la UI para mostrar los detalles del alumno o un esqueleto de carga.
 */
export default function DatosColegioAlumnoData({ datosColegio }) {
	const alumno = datosColegio?.alumno ?? {}

	const { imageSrc } = useImageLoader(alumno, 'foto_logo', DEFAULT_IMAGES_URL.alumno, true)

	return (
		<Grid container>
			{!datosColegio ?
				<Skeleton />
				:
				(
					<React.Fragment>
						<Grid item xs={12} mb={5}>
							<H3>Alumno de los datos:</H3>
						</Grid>

						<Grid item xs={2}>
							<Avatar
								alt={"Foto de: " + (alumno?.nombre ?? "")}
								// src={`${ASSETS.images + (alumno.foto_logo ? alumno.foto_logo : 'private/alumno/perfiles/0/default.png')}`}
								src={imageSrc}
								sx={{ width: "80%", height: "100%", borderRadius: 2 }} />
						</Grid>

						<Grid item xs={8}>
							<Box mt={2}>
								<H3>{alumno.nombre + " " + (alumno.apellido1 ?? "") + " " + (alumno.apellido2 ?? "")}</H3>

								<Box display={"flex"}>
									<Text fontWeight={"bold"}>Documento de identidad:</Text>
									<Text className="mx-2">{alumno.numero_documento + " - " + alumno.tipo_documento?.nombre}</Text>
								</Box>

								<Box display={"flex"}>
									<Text fontWeight={"bold"}>Género:</Text>
									<Text className="mx-2">{alumno.sexo?.valor}</Text>					
								</Box>
							</Box>
						</Grid>
					</React.Fragment>
				)
			}
		</Grid>
	)
}
