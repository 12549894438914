import { Grid } from "@mui/material";
import { LoadingButton } from "components/forms/components";
import { H3 } from "components/texts";

/**
 * Componente para mostrar el encabezado de la sección de Devoluciones y permitir la generación de devoluciones.
 *
 * @param {Function} generateDevoluciones - Función para generar devoluciones de libros (callback al hacer clic en el botón).
 * @param {boolean} isLoading - Indica si se está cargando un proceso (para mostrar un indicador de carga en el botón).
 */
export default function DevolucionesHeader({ generateDevoluciones, isLoading }) {
    return (
        <Grid container py={2} px={4}>
            <Grid item xs={10} display={"flex"} alignItems={"center"}>
                <H3 className={"m-0"}>Devoluciones</H3>
            </Grid>
            <Grid item xs={2} display={"flex"} alignItems={"center"} justifyContent={"end"}>
                <LoadingButton
                    color={'success'}
                    size={"small"}
                    loading={isLoading}
                    onClick={generateDevoluciones}
                >
                    Generar devoluciones
                </LoadingButton>
            </Grid>
        </Grid>
    )
}