// import moment from 'moment'
import styles from './styles.js'
import { Grid } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import { Line } from 'components/data_display'
import { LibrosPedidos, LibrosDevueltos } from './parts'
import { CursoEscolarSelect } from 'components/forms/components/Select/custom'
// import { ItemsSelect } from 'components/forms/components/Select/custom'
import { Almacen } from 'components/forms/display/index.js'
import { useDataLibroAlmacenLogic } from 'hooks/index.js'

// const cursoActual = moment().subtract(1, 'year').format('YYYY') + "-" + moment().format('YYYY')

/**
 * El componente `DataFormLibroControlAlmacen` proporciona un formulario para gestionar la información
 * relacionada con el control de almacén de un libro específico, incluyendo los libros pedidos y devueltos,
 * así como el inventario del almacén.
 * 
 * @param {Object} libro - Objeto con la información detallada del libro a gestionar.
 * @param {function} register - Función de `react-hook-form` para registrar los inputs del formulario.
 * @param {function} setValue - Función de `react-hook-form` para establecer los valores de los campos del formulario.
 * @param {Object} errors - Objeto de `react-hook-form` que contiene los errores de validación del formulario.
 * @param {function} reset - Función de `react-hook-form` para resetear el formulario a su estado inicial.
 * 
 * Utiliza el estado de React para manejar el curso escolar seleccionado, los libros pedidos y los libros devueltos.
 * 
 * @returns {JSX.Element} - Un formulario que permite la gestión de los libros en el almacén, visualizando y actualizando
 * la información de los libros pedidos y devueltos, y gestionando el inventario del almacén.
 */
export default function DataFormLibroControlAlmacen({ libro, register, setValue, errors, reset }) {
  const { cursoEscolar, setCursoEscolar, librosDevueltos, setLibrosDevueltos, librosPedidos, setLibrosPedidos } = useDataLibroAlmacenLogic(libro)

  return (
    <Grid container px={3}>

      <Grid container mb={2} mt={4}>
        <CursoEscolarSelect
          name={"id_curso_escolar"}
          register={register}
          setValue={setValue}
          errors={errors}
          className={"w-50"}
          wantToReset={reset}
          defaultValue={null}
          onChange={({ target }) => setCursoEscolar(target.value)}
          searcheable />

      </Grid>

      <Line className={"mt-5"} />

      <Grid container my={3}>
        <Grid item xs={12} xl={6}>
          <LibrosPedidos cursoEscolar={cursoEscolar} librosPedidos={librosPedidos} onUpdate={setLibrosPedidos} />
        </Grid>

        <Grid item xs={12} xl={6} sx={styles.librosDevueltosGrid}>
          <LibrosDevueltos cursoEscolar={cursoEscolar} librosDevueltos={librosDevueltos} onUpdate={setLibrosDevueltos} />
        </Grid>
      </Grid>

      <Line />

      <Grid container my={3}>
        <Almacen cursoEscolar={cursoEscolar} librosDevueltos={librosDevueltos} librosPedidos={librosPedidos} libro={libro} />
      </Grid>

      <SnackbarProvider autoHideDuration={5000} />
    </Grid>
  )
}