import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { responses } from 'constants/Responses'

/**
 * Hook `useLogic` gestiona la lógica para cargar y actualizar la información de una tutoría específica.
 * Incluye la carga de la tutoría desde el servidor y la actualización del ID de la tutoría para la navegación.
 * 
 * @param {Object} navigationParams - Parámetros de navegación que incluyen el ID de la tutoría.
 * @returns {Object} Retorna los estados y funciones para interactuar con la tutoría en el componente.
 */
export default function useLogic(requests, navigationParams) {
    const navigate = useNavigate()

    const [id_tutoria, setIdTutoria] = useState(navigationParams.id_tutoria)
    const [tutoria, setTutoria] = useState(null)

    /**
     * Carga la información de la tutoría desde el servidor utilizando su ID.
     * Utiliza el objeto de respuestas para acceder dinámicamente al nombre singular de la tutoría.
     */
    const getTutoria = () => {
        const params = { relations: 'alumno' }

        requests.get(id_tutoria, params, (res) => {
            if (res[responses.tutorias.singleName])
                setTutoria(res[responses.tutorias.singleName])
        })
    }

    /**
     * Actualiza el ID de la tutoría en el estado y la URL para reflejar el cambio en la interfaz de usuario.
     * Permite la navegación dinámica entre las tutorías sin recargar la página.
     * 
     * @param {number} id - El nuevo ID de la tutoría a visualizar.
     */
    const updateId = (id) => {
        navigate(`../alumnos/tutorias/${id}`);
        setIdTutoria(id);
    }

    useEffect(getTutoria, [id_tutoria])

    return { updateId, id_tutoria, tutoria, setTutoria }
}