import { yupResolver } from "@hookform/resolvers/yup"
import { ChangesContext } from "contexts/ChangesContext"
import { useCheckDirtyFields, useErrorHandler } from "hooks"
import { useContext, useRef } from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { validationSchema } from 'components/forms/display/responsable/DataForm/validation.js'
import Statics from 'objects/common/Statics.js'
import { useAlert } from 'hooks'

/**
 * Hook personalizado que encapsula la lógica compartida entre el componente Wrapper y EditResponsable.
 * 
 * Este hook proporciona funciones y datos compartidos entre ambos componentes, como la lógica para manejar errores,
 * registrar campos del formulario, enviar solicitudes de edición del responsable y actualizar el estado del formulario.
 * 
 * @param {Function} setIdResponsable - Función para actualizar el ID del responsable.
 * @param {number} id_responsable - El ID del responsable.
 * @param {Function} updateAlert - Función para mostrar alertas.
 * @param {Function} onSaveFinish - Función que se ejecuta al finalizar la operación de guardado.
 * @returns {Object} - Objeto con funciones y datos compartidos.
 */
export default function useLogic(setIdResponsable, id_responsable, updateAlert, onSaveFinish, requests) {
    const navigate = useNavigate()

    const updateAlertMesaje = useAlert()

    const handleError = useErrorHandler()

    const { updateChanges } = useContext(ChangesContext)

    const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) })

    useCheckDirtyFields(dirtyFields, updateChanges)

    const headerRef = useRef()

    // const updateHeaderAndAlert = ({ message, severity = "error" }, loading = false) => {
    //     headerRef.current.button.setLoading(loading)
    //     updateAlert(message, severity)
    // }

    /**
     * Hace la petición para editar los datos básicos del responsable.
     */
    const editResponsable = () => {
        headerRef.current.button.setLoading(true)

        requests.editResponsable(id_responsable, getValues, () => {
            // updateHeaderAndAlert({ message: "Se ha editado el tutor correctamente.", severity: "success" })
            reset({}, { keepValues: true })
            updateChanges(false)
            updateId(id_responsable) //Añadida la ejecución de la función, con la que modificamos la URL
            updateAlertMesaje('Se ha editador correctamente. Redirigiendo...', 'success')
            onSaveFinish()
        }, (err) => {
            // updateHeaderAndAlert({ message: res })
            headerRef.current.button.setLoading(false)
            handleError(err)
            updateAlertMesaje('Ha habido un error al editar el tutor.')
        })
    }

    const updateId = async (id) => {
        // navigate(`../alumnos/tutores/${id}`);
        await Statics.delay(1500).then(() => { navigate(`../alumnos/tutores/${id}`) }) //Modificada la URL, para ir al single
        setIdResponsable(id);
    }

    return { headerRef, updateId, register, handleSubmit, errors, editResponsable }
}