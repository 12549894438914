import { Box } from '@mui/material'
import { Button } from "components/forms/components"
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { Modal } from "components/modals"
import { Text } from 'components/texts'
import { enqueueSnackbar } from 'notistack'
import { RequestAlumnoResponsable } from 'objects/requests'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

/**
 * Componente para realizar un cambio de relacion del responsable asociado a un alumno
 * 
 * @returns Component
 */
export default function ChangeRelation({ changeRelationModal, open, setOpen, responsableToChangeRelation, alumnoToChangeRelation, reload }) {
    const { register } = useForm()

    const [relationSelected, setRelationSelected] = useState(null)

    useEffect(() => {
        if (open) {
            changeRelationModal.current.open()
            setOpen(false)
        }
    }, [open])

    const editRelation = () => {
        const formData = new FormData()
        formData.append("responsable_id", responsableToChangeRelation.id)
        formData.append("alumno_id", alumnoToChangeRelation.id)
        formData.append("tipo_relacion_id", relationSelected)

        for (var pair of formData.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }

        new RequestAlumnoResponsable().update(formData, (res) => {

            reload()
            changeRelationModal.current.close()
            enqueueSnackbar(<Text>Relación actualizada con éxito.</Text>, { variant: "success" })

        }, (err) => enqueueSnackbar(<Text>Ha habido un error al actualizar la relación.</Text>, { variant: "error" }))
    }

    return (
        <Modal ref={changeRelationModal} ariaLabel={"child-modal-title"} ariaDescribe={"child-modal-description"} title={"Elige el tipo de relación del tutor con el alumno"} style={{ minWidth: "50%" }}>
            <Box p={10}>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <ItemsSelect
                        register={register}
                        name={"responsable_tipo_relacion_id"}
                        keyValue={"tipo_relacion"}
                        defaultValue={responsableToChangeRelation?.pivot?.tipo_relacion_id ?? ""}
                        onChange={(e) => setRelationSelected(e.target.value)} />
                </Box>
                <Box display={"flex"} justifyContent={"end"} alignItems={"center"}>
                    <Button
                        className="my-5"
                        onClick={editRelation}
                        disabled={!relationSelected}>
                        Aceptar
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}