import React, { useState, useEffect } from 'react'
import { Grid, Box, Container, Tab, Tabs } from "@mui/material";
import { Text } from 'components/texts/index.js'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import {
    FormaDePago, ContratosProfesores, ContratosAcademia,
    GruposColegio, GruposAcademia, ClasesParticulares
} from './parts';
import { COLOR } from 'theme/Colors.js'
import { TabContext } from '@mui/lab'



export default function DatosContratosForm({
    gruposEmpleadoClasesParticulares, register, errors, setValue, isSingle,
    getValues, contratoEmpleado, onChangeCursoEscolar, cursoEscolar, gruposEmpleadoColegio, gruposEmpleadoAcademia
}) {
    const [tab, setTab] = useState("1")
    return (
        <Grid container p={3}>
            <Container maxWidth={false} sx={{ mt: 2 }}>

                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
                        <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                            <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>FORMA DE PAGO</Text>} value={"1"} />
                            <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>CONTRATOS PROFESORES</Text>} value={"2"} />
                            <Tab label={<Text fontWeight={tab === "3" ? "bold" : null} color={tab === "3" ? COLOR.standard.main : null}>CONTRATOS ACADEMIA</Text>} value={"3"} />
                            <Tab label={<Text fontWeight={tab === "4" ? "bold" : null} color={tab === "4" ? COLOR.standard.main : null}>GRUPOS COLEGIO</Text>} value={"4"} />
                            <Tab label={<Text fontWeight={tab === "5" ? "bold" : null} color={tab === "5" ? COLOR.standard.main : null}>GRUPOS ACADEMIA</Text>} value={"5"} />
                            <Tab label={<Text fontWeight={tab === "6" ? "bold" : null} color={tab === "6" ? COLOR.standard.main : null}>CLASES PARTICULARES</Text>} value={"6"} />
                        </Tabs>
                    </Box>

                    <TabPanel value={"1"}>
                        <FormaDePago register={register} errors={errors} setValue={setValue} getValues={getValues} isSingle={isSingle} />
                    </TabPanel>

                    <TabPanel value={"2"}>
                        <ContratosProfesores contratoEmpleado={contratoEmpleado} isSingle={isSingle} register={register} setValue={setValue} getValues={getValues}/>
                    </TabPanel>

                    <TabPanel value={"3"}>
                        <ContratosAcademia />
                    </TabPanel>

                    <TabPanel value={"4"}>
                        <GruposColegio onChangeCursoEscolar={onChangeCursoEscolar} cursoEscolar={tab === "4" ? cursoEscolar : null}
                            gruposEmpleadoColegio={gruposEmpleadoColegio} isSingle />
                    </TabPanel>

                    <TabPanel value={"5"}>
                        <GruposAcademia onChangeCursoEscolar={onChangeCursoEscolar} cursoEscolar={tab === "5" ? cursoEscolar : null}
                            gruposEmpleadoAcademia={gruposEmpleadoAcademia} isSingle />
                    </TabPanel>

                    <TabPanel value={"6"}>
                        <ClasesParticulares onChangeCursoEscolar={onChangeCursoEscolar} cursoEscolar={tab === "6" ? cursoEscolar : null}
                            gruposEmpleadoClasesParticulares={gruposEmpleadoClasesParticulares} isSingle />
                    </TabPanel>
                </TabContext>
            </Container>
        </Grid>
    )
}

