import { Component } from 'react'
import { RequestEmpleado } from "objects/requests"

export default class Requests extends Component {

    /**
     * Hace la petición para crear al empleado
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createEmpleado(getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()
        formData.append("nombre", getValues("nombre").toUpperCase())
        formData.append("apellido1", getValues("apellido1").toUpperCase())
        formData.append("apellido2", getValues("apellido2").toUpperCase())
        formData.append("email", getValues("email"))
        formData.append("email2", getValues("email2"))
        formData.append("sexo_id", getValues("sexo_id"))
        formData.append("movil", getValues("movil"))
        formData.append("telefono", getValues("telefono"))
        formData.append("comentarios", getValues("comentarios_datos_personales"))
        formData.append("fecha_nacimiento", getValues("fecha_nacimiento"))

        formData.append("numero_documento", getValues("numero_documento").toUpperCase())
        formData.append("tipo_documento_id", getValues("tipo_documento_id"))
        formData.append("cod_postal", getValues("cod_postal"))
        formData.append("provincia_id", getValues("provincia_id"))
        formData.append("municipio", getValues("municipio"))
        formData.append("direccion", getValues("direccion"))
        formData.append("tipo_via_id", getValues("tipo_via_id"))

        formData.append("pais_nacimiento", getValues("pais_nacimiento"))
        formData.append("provincia_nacimiento_id", getValues("provincia_nacimiento_id"))
        formData.append("municipio_nacimiento", getValues("municipio_nacimiento"))
        formData.append("certificado_penales", getValues("certificado_penales") ? 1 : 0)
        formData.append("retener_sueldo", 0)
        formData.append("es_candidato", 0)
        formData.append("otras_actividades", getValues("otras_actividades"))

        if (getValues("foto_perfil_url")[0])
            formData.append("foto_perfil_url", getValues("foto_perfil_url")[0])

        // for (var pair of formData.entries()) {
        //     console.log(pair[0] + ', ' + pair[1]);
        // }

        new RequestEmpleado().create(formData, callbackOk, callbackError)
    }

    /**
     * Crea el curriculum del empleado
     * 
     * @param {Number} empleadoId 
     * @param {Object} values 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createCurriculum(empleadoId, values, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()
        formData.append("empleado_id", empleadoId)
        formData.append("idioma_principal_id", getValues("idioma_principal_id"))
        formData.append("entrega_curriculum", getValues("entrega_curriculum") ? 1 : 0)
        formData.append("seleccionar", getValues("seleccionar") ? 1 : 0)
        formData.append("fecha_entrada", getValues("fecha_entrada"))
        formData.append("codigo_curriculum", empleadoId)
        if (getValues("archivo")[0])
            formData.append("path", getValues("archivo")[0])

        const actividadesCurriculum = values["actividades-curriculum"] ?? []
        const otrasActividadesCurriculum = values["otras-curriculum"] ?? []
        const datosActividadesCurriculum = values["datosActividadesCurriculum"] ?? []
        const idiomas = values["idiomas_ids"] ?? []

        for (const idioma of idiomas)
            formData.append("idiomas_ids[]", idioma)

        const finalActividades = actividadesCurriculum.concat(otrasActividadesCurriculum).concat(datosActividadesCurriculum)
        for (const actividad of finalActividades)
            formData.append("caracteristicas_ids[]", actividad.id)

        // for (var pair of formData.entries()) {
        //     console.log(pair[0] + ', ' + pair[1]);
        // }

        new RequestEmpleado().createCurriculum(formData, callbackOk, callbackError)
    }

    /**
     * Crea los detalles del curriculum del empleado asociado
     * 
     * @param {Number} curriculumId 
     * @param {Function} getValues 
     * @param {Array} nuevosDetalles En caso de que estemos en editar, pero se cree un nuevo registro, nuevosDetalles sera un Array con los nuevos registros
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createCurriculumDetalle(curriculumId, getValues, nuevosDetalles = [], callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()
        formData.append(`curriculum_id`, curriculumId)

        const datosCurriculum = nuevosDetalles.length > 0 ? nuevosDetalles : getValues('datos_curriculum')
        datosCurriculum.forEach((item, index) => {
            formData.append(`datos_curriculum[${index}][descripcion]`, item.descripcion)
            formData.append(`datos_curriculum[${index}][comentarios]`, item.comentarios)
            formData.append(`datos_curriculum[${index}][fecha_inicio]`, item.fecha_inicio)
            formData.append(`datos_curriculum[${index}][fecha_fin]`, item.fecha_fin)
        })

        new RequestEmpleado().createCurriculumDetalle(formData, callbackOk, callbackError)
    }

    /**
     * Crea las horas extras del empleado
     * 
     * @param {Number}   empleadoId 
     * @param {Number}   cursoEscolar 
     * @param {Array}    horasExtra 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createHorasExtra(empleadoId, cursoEscolar, horasExtra, callbackOk = () => { }, callbackError = () => { }) {
        const formDataHorasExtra = new FormData();

        horasExtra.forEach((horaExtra, index) => {
            formDataHorasExtra.append(`horas_extra[${index}][empleado_id]`, empleadoId);
            formDataHorasExtra.append(`horas_extra[${index}][curso_escolar_id]`, cursoEscolar);
            formDataHorasExtra.append(`horas_extra[${index}][fecha]`, horaExtra.fecha);
            formDataHorasExtra.append(`horas_extra[${index}][horas]`, horaExtra.horas);
            formDataHorasExtra.append(`horas_extra[${index}][importe]`, horaExtra.importe);
            formDataHorasExtra.append(`horas_extra[${index}][fecha_pago]`, horaExtra.fecha_pago);
            formDataHorasExtra.append(`horas_extra[${index}][motivo]`, horaExtra.motivo);
            formDataHorasExtra.append(`horas_extra[${index}][liquidacion_id]`, Math.random()); //TODO: Cuando se sepa de donde viene esta variable y a donde va cambiar
        });

        new RequestEmpleado().createHorasExtra(formDataHorasExtra, callbackOk, callbackError)
    }

    /**
     * Hace la petición para crear el contrato del empleado
     * 
     * @param {Int} id 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createContratoEmpleado(getValues, values, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()

        formData.append("idioma_principal_id", getValues("idioma_principal_id"))
        new RequestEmpleado().createContratoEmpleado(formData, callbackOk, callbackError)

    }

    /**
     * Hace la petición para crear los partidos del empleado
     * 
     * @param {Number}   empleadoId 
     * @param {Number}   cursoEscolar 
     * @param {Array}    partidos 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createPartidos(empleadoId, cursoEscolar, partidos, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()

        partidos.forEach((partido, index) => {
            formData.append(`partidos[${index}][empleado_id]`, empleadoId)
            formData.append(`partidos[${index}][curso_escolar_id]`, cursoEscolar)
            formData.append(`partidos[${index}][fecha]`, partido.fecha)
            formData.append(`partidos[${index}][horas]`, partido.horas)
            formData.append(`partidos[${index}][importe]`, partido.importe)
            formData.append(`partidos[${index}][fecha_pago]`, partido.fecha_pago)
            formData.append(`partidos[${index}][nombre_partido]`, partido.nombre_partido)
            formData.append(`partidos[${index}][liquidacion_id]`, '321') //TODO: Cuando se sepa de donde viene esta variable y a donde va cambiar
        });

        new RequestEmpleado().createPartidos(formData, callbackOk, callbackError)
    }

    /**
     * Hace la petición para crear los partidos del empleado
     * 
     * @param {Number}   empleadoId 
     * @param {Number}   cursoEscolar 
     * @param {Array}    partidos 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createSustituciones(empleadoId, cursoEscolar, sustituciones, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()

        sustituciones.forEach((sustitucion, index) => {
            formData.append(`sustituciones[${index}][curso_escolar_id]`, cursoEscolar)
            formData.append(`sustituciones[${index}][empleado_id]`, empleadoId)
            formData.append(`sustituciones[${index}][centro_id]`, sustitucion.centro_id)
            formData.append(`sustituciones[${index}][horas]`, sustitucion.horas)
            formData.append(`sustituciones[${index}][importe]`, sustitucion.importe)
            formData.append(`sustituciones[${index}][fecha_pago]`, sustitucion.fecha_pago)
            formData.append(`sustituciones[${index}][liquidacion_id]`, '123') //TODO: Cuando se sepa de donde viene esta variable y a donde va cambiar
        });

        new RequestEmpleado().createSustituciones(formData, callbackOk, callbackError)
    }

}
