import React from 'react'
import { Grid, Stack } from '@mui/material'
import { H3, Text } from 'components/texts'
import { Input } from 'components/forms/components'
import { Checkbox } from 'components/forms/components'
import { CursoEscolarSelect, ItemsSelect } from 'components/forms/components/Select/custom'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

import { Line } from 'components/data_display'

export default function PagosAlumnosDataForm({ pagos, isMounted, register, errors, wantToReset, setValue }) {

    return (
        <Grid container spacing={2} p={5}>
            <React.Fragment>
                <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                    <H3>Datos de los pagos de los Alumnos:</H3>
                </Grid>

                <Grid item xs={6}>
                    {isMounted ? <CursoEscolarSelect name={`curso_escolar`} register={register} errors={errors} defaultValue={pagos?.curso_escolar ?? null} setValue={setValue} wantToReset={wantToReset} searcheable /> : false}
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect name={"ordenante"} keyValue={"ordenante"}
                        register={register} errors={errors} defaultValue={pagos?.ordenante ?? ""} wantToReset={wantToReset} errorLabel={'No se ha podido cargar le ordenante'} />
                </Grid>

                <Grid item xs={4}>
                    <Stack>
                        <Input label="Mes" register={register} errors={errors} className="w-100" name={"mes"} defaultValue={pagos?.mes ?? ""} uppercase />
                    </Stack>
                </Grid>

                <Grid item xs={4}>
                    <Stack>
                        <Input label="NIF/CIF" register={register} errors={errors} className="w-100" name={"documentacion"} defaultValue={pagos?.documentacion ?? ""} uppercase />
                    </Stack>
                </Grid>

                <Grid item xs={4}>
                    <Stack>
                        <Input label="Sufijo" register={register} errors={errors} className="w-100" name={"sufijo"} defaultValue={pagos?.sufijo ?? ""} uppercase />
                    </Stack>
                </Grid>

                <Grid item xs={4}>
                    <Stack>
                        <Input label="Fecha Adeudo" register={register} errors={errors} className="w-100" name={"fecha_adeudo"} defaultValue={pagos?.mes ?? ""} uppercase />
                    </Stack>
                </Grid>

                <Grid item xs={4}>
                    <Stack>
                        <Input label="IBAN" register={register} errors={errors} className="w-100" name={"iban"} defaultValue={pagos?.mes ?? ""} uppercase />

                    </Stack>
                </Grid>

                <Grid item xs={4}>
                    <Stack>
                        <Input label="Identificador" register={register} errors={errors} className="w-100" name={"identificador"} defaultValue={pagos?.mes ?? ""} uppercase />
                    </Stack>
                </Grid>

                <Grid item >
                    <Line />
                </Grid>

                <Grid item xs={12}>
                    <H3> Inscripciones Actualizadas </H3>
                </Grid>

                <Grid item xs={12}>

                    <FormControl>
                        <FormLabel id="domiciliaciones"><Text fontWeight={"bold"}>Domiciliaciones a pasar:</Text></FormLabel>
                        <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                            <FormControlLabel value="colegio" disabled control={<Radio />} label="Colegio" />
                            <FormControlLabel value="todas" disabled control={<Radio />} label="Todas" />
                        </RadioGroup>
                    </FormControl>

                </Grid>

                <Grid item xs={4}>
                    <Stack>
                        <Input label="Nº Domiciliciaciones" register={register} errors={errors} className="w-100" name={"num_domiciliciaciones"} defaultValue={pagos?.mes ?? ""} uppercase />

                    </Stack>
                </Grid>

                <Grid item xs={4}>
                    <Stack>
                        <Input label="Fichero" register={register} errors={errors} className="w-100" name={"fichero"} defaultValue={pagos?.mes ?? ""} uppercase />

                    </Stack>
                </Grid>

                <Grid item xs={4}>
                    <Stack>
                        <Input label="Fecha de Inmersissones" register={register} errors={errors} className="w-100" name={"fecha_inmersiones"} defaultValue={pagos?.mes ?? ""} uppercase />

                    </Stack>
                </Grid>

                <Grid item xs={2}>
                    <Stack>
                        <Checkbox name={"academia"} label={"Academia"} className="mt-2" defaultChecked={pagos?.academia ? true : false} />
                    </Stack>
                </Grid>
                <Grid item xs={2}>
                    <Stack>
                        <Checkbox name={"colegios"} label={"Colegios"} className="mt-2" defaultChecked={pagos?.colegios ? true : false} />
                    </Stack>
                </Grid>
                <Grid item xs={2}>
                    <Stack>
                        <Checkbox name={"particulares"} label={"Particulares"} className="mt-2" defaultChecked={pagos?.ninos ? true : false} />
                    </Stack>
                </Grid>
                <Grid item xs={2}>
                    <Stack>
                        <Checkbox name={"empresas"} label={"Empresas"} className="mt-2" defaultChecked={pagos?.ninos ? true : false} />
                    </Stack>
                </Grid>
                <Grid item xs={2}>
                    <Stack>
                        <Checkbox name={"exOficiales"} label={"Ex. Oficiales"} className="mt-2" defaultChecked={pagos?.ninos ? true : false} />
                    </Stack>
                </Grid>
                <Grid item xs={2}>
                    <Stack>
                        <Checkbox name={"inmersiones"} label={"Inmersiones"} className="mt-2" defaultChecked={pagos?.ninos ? true : false} />
                    </Stack>
                </Grid>

                <Grid item xs={8}>
                    <Stack>
                        <Input label="Colegio" register={register} errors={errors} className="w-100" name={"colegio"} defaultValue={pagos?.mes ?? ""} uppercase />

                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <Stack>
                        <Input label="Cantidad" register={register} errors={errors} className="w-100" name={"cantidad"} defaultValue={pagos?.mes ?? ""} uppercase />

                    </Stack>
                </Grid>

            </React.Fragment >
        </Grid >
    )
}

