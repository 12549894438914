import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { RequestAlumno } from 'objects/requests'

/**
 * Hook personalizado para obtener y gestionar los datos de un alumno específico.
 * 
 * @param {Object} navigationParams - Parámetros de navegación que incluyen el ID del alumno.
 * @returns {Object} Objeto con el alumno actual, su ID, y funciones para obtener, actualizar el alumno y su ID.
 */
export default function useGetAlumno({ navigationParams }) {
  const [idAlumno, setIdAlumno] = useState(navigationParams.id_alumno)
  const [alumno, setAlumno] = useState(null)
  const navigate = useNavigate()

  /**
   * Función para realizar una petición HTTP y obtener los datos del alumno específico basado en `idAlumno`.
   * Incluye relaciones adicionales para una carga detallada del alumno.
   */
  const getAlumno = () => {
    const params = { relations: 'sexo,tipo_documento,provincia,tipo_via,provincia_nacimiento,responsables,domiciliacion_bancaria' }

    new RequestAlumno().get(idAlumno, params, (res) => {
      if (res.alumno)
        setAlumno(res.alumno)
    })
  }

  /**
   * Actualiza los datos del alumno en el estado.
   * 
   * @param {Object} alumno - Los nuevos datos del alumno para actualizar.
   */
  const updateAlumno = (alumno) => setAlumno(alumno)

  /**
   * Actualiza el ID del alumno y navega a la ruta correspondiente al nuevo ID.
   * 
   * @param {number|string} id - El nuevo ID del alumno.
   */
  const updateIdAlumno = (id) => {
    navigate(`../alumnos/${id}`)
    setIdAlumno(id)
  }

  return { alumno, idAlumno, getAlumno, updateIdAlumno, updateAlumno }
}