const options = {
    "curso_escolar": {
        label: "Curso escolar",
        arrayName: "cursos_escolares",
        campoName: "valor",
        url: "aux/curso_escolar/all",
        queryKey: "curso_escolar-all",
        errorLabel: "Ha habido un error al descargar los cursos de los escolares.",
        allLabel: "Todos los cursos",
    },
    "curso_colegio": {
        label: "Curso colegio",
        arrayName: "cursos_colegio",
        campoName: "valor",
        url: "aux/curso_colegio/all",
        queryKey: "curso_colegio-all",
        errorLabel: "Ha habido un error al descargar los cursos de los colegios.",
    },
    "editorial": {
        label: "Editorial",
        arrayName: "items",
        campoName: "nombre",
        url: "editorial/all",
        queryKey: "editorial-all",
        errorLabel: "Ha habido un error al descargar las editoriales."
    },
    "provincia": {
        label: "Provincia",
        arrayName: "provincias",
        campoName: "nombre",
        url: "aux/provincia/all",
        queryKey: "provincia-all",
        errorLabel: "Ha habido un error al descargar las provincias."
    },
    "tipos_condicion_actividad": {
        label: "Tipo condición actividad",
        arrayName: "tipos_condicion",
        campoName: "valor",
        url: "aux/tipo_condicion_actividad/all",
        queryKey: "tipos_condicion_actividad-all",
        errorLabel: "Ha habido un error al descargar los tipos de condición."
    },
    "colegio": {
        label: "Colegio",
        arrayName: "colegios",
        campoName: "nombre",
        url: "colegio/all",
        queryKey: "colegio-all",
        errorLabel: "Ha habido un error al descargar los colegios."
    },
    "libro": {
        label: "Libro",
        arrayName: "libros",
        campoName: "nombre",
        url: "libro/all",
        queryKey: "libro-all",
        errorLabel: "Ha habido un error al descargar los libros."
    },
    "forma_recogida": {
        label: "Forma de recogida",
        arrayName: "formas",
        campoName: "nombre",
        url: "aux/forma_recogida/all",
        queryKey: "forma_recogida-all",
        errorLabel: "Ha habido un error al descargar las formas de recogida",
    },
    "forma_pago": {
        label: "Forma de pago",
        arrayName: "formas",
        campoName: "valor",
        url: "aux/forma_pago/all",
        queryKey: "forma_pago-all",
        errorLabel: "Ha habido un error al descargar las formas de pago",
    },
    "forma_pago_inscripcion": {
        label: "Forma de pago",
        arrayName: "",
        campoName: "valor",
        url: "aux/forma_pago_inscripcion/all",
        queryKey: "forma_pago_inscripcion-all",
        errorLabel: "Ha habido un error al descargar las formas de pago",
    },
    "actividad": {
        label: "Actividad",
        arrayName: "actividades",
        campoName: "valor",
        url: "aux/actividad/all",
        queryKey: "actividad-all",
        errorLabel: "Ha habido un error al descargar las actividades",
    },
    "registro_actividad_academia": {
        label: "Actividad academia",
        arrayName: "items",
        campoName: "nombre",
        url: "registro-actividad/all/academia",
        queryKey: "registro_actividad_academia-all",
        errorLabel: "Ha habido un error al descargar las actividades de la academia",
    },
    "forma_conocimiento": {
        label: "Forma de conocimiento",
        arrayName: "formas",
        campoName: "nombre",
        url: "aux/forma_conocimiento/all",
        queryKey: "forma_conocimiento-all",
        errorLabel: "Ha habido un error al descargar las formas de conocimiento",
    },
    "forma_condiciones": {
        label: "Forma de condiciones",
        arrayName: "",
        campoName: "nombre",
        url: "aux/forma-condiciones/empleado",
        queryKey: "forma_conocimiento-all",
        errorLabel: "Ha habido un error al descargar las formas de condiciones",
    },
    "lugar_clase": {
        label: "Lugar de clase",
        arrayName: "items",
        campoName: "valor",
        url: "aux/lugar_clase/all",
        queryKey: "lugar_clase-all",
        errorLabel: "Ha habido un error al descargar los lugares de clase",
    },
    "empresa": {
        label: "Empresa",
        arrayName: "items",
        campoName: "valor",
        url: "aux/empresa/all",
        queryKey: "empresa-all",
        errorLabel: "Ha habido un error al descargar las empresas",
    },
    "sexo": {
        label: "Sexo",
        arrayName: "sexos",
        campoName: "valor",
        url: "aux/sexo/all",
        queryKey: "sexo-all",
        errorLabel: "Ha habido un error al descargar los sexos",
    },
    "tipo_matricula": {
        label: "Matrícula",
        arrayName: "tipos",
        campoName: "nombre",
        url: "aux/tipo_matricula/all",
        queryKey: "tipo_matricula-all",
        errorLabel: "Ha habido un error al descargar los tipos de matrícula",
    },
    "tipo_pago": {
        label: "Tipo de pago",
        arrayName: "tipos",
        campoName: "nombre",
        url: "aux/tipo_pago/all",
        queryKey: "tipo_pago-all",
        errorLabel: "Ha habido un error al descargar los tipos de pago",
    },
    "tipo_subvencion": {
        label: "Subvención",
        arrayName: "tipos",
        campoName: "nombre",
        url: "aux/tipo_subvencion/all",
        queryKey: "tipo_subvencion-all",
        errorLabel: "Ha habido un error al descargar los tipos de subvención",
    },
    "origen_empleado": {
        label: "Origen empleado",
        arrayName: "items",
        campoName: "valor",
        url: "aux/origen_empleado/all",
        queryKey: "origen_empleado-all",
        errorLabel: "Ha habido un error al descargar los origenes de empleado",
    },
    "origen": {
        label: "Origen",
        arrayName: "origenes",
        campoName: "nombre",
        url: "aux/origen/all",
        queryKey: "origen-all",
        errorLabel: "Ha habido un error al descargar los origenes",
    },
    "causa": {
        label: "Causa",
        arrayName: "causas",
        campoName: "nombre",
        url: "aux/causa/all",
        queryKey: "causa-all",
        errorLabel: "Ha habido un error al descargar las causas",
    },
    "idiomas": {
        label: "idiomas",
        arrayName: "",
        campoName: "nombre",
        url: "aux/idiomas/todos",
        queryKey: "idiomas-all",
        errorLabel: "Ha habido un error al descargar los idiomas",
    },
    "tipo_incidencia": {
        label: "Tipo incidencia",
        arrayName: "tipos_incidencia",
        campoName: "valor",
        url: "aux/tipo_incidencia/all",
        queryKey: "tipos_incidencia-all",
        errorLabel: "Ha habido un error al descargar los tipos de incidencia",
    },
    "tipo_via": {
        label: "Tipo vía",
        arrayName: "tipos_via",
        campoName: "valor",
        url: "aux/tipo_via/all",
        queryKey: "tipo_via-all",
        errorLabel: "Ha habido un error al descargar los tipos de vía",
    },
    "tipo_talon": {
        label: "Tipo talón",
        arrayName: "",
        campoName: "nombre",
        url: "aux/forma-talon",
        queryKey: "forma-talon-all",
        errorLabel: "Ha habido un error al descargar los tipos de talón",
    },
    "tipo_documento": {
        label: "Tipo documento",
        arrayName: "tipos_documento",
        campoName: "nombre",
        url: "aux/tipo_documento/all",
        queryKey: "tipo_documento-all",
        errorLabel: "Ha habido un error al descargar los tipos de documento",
    },
    "tipo_relacion": {
        label: "Tipo relación",
        arrayName: "tipos_relacion",
        campoName: "valor",
        url: "aux/tipo_relacion/all",
        queryKey: "tipo_relacion-all",
        errorLabel: "Ha habido un error al descargar los tipos de relación",
    },
    "tipo_actividad": {
        label: "Tipo actividad",
        arrayName: "tipos_actividad",
        campoName: "valor",
        url: "aux/tipo_actividad/all",
        queryKey: "tipo_actividad-all",
        errorLabel: "Ha habido un error al descargar los tipos de actividades",
    },
    "modalidad_cursos": {
        label: "Modalidad",
        arrayName: "modalidades",
        campoName: "nombre",
        url: "aux/modalidad/all",
        queryKey: "modalidad_cursos-all",
        errorLabel: "Ha habido un error al descargar los tipos de modalidad",
    },
    "empleado_cl": {
        label: "Empleado",
        arrayName: "empleados.data",
        campoName: "nombre",
        url: "empleado",
        queryKey: "empleado-all",
        errorLabel: "Ha habido un error al descargar los empleados",
    },
    "nivel_grupo": {
        label: "Nivel",
        arrayName: "niveles",
        campoName: "valor",
        url: "aux/nivel/all",
        queryKey: "nivel_grupo-all",
        errorLabel: "Ha habido un error al descargar los tipos de nivel",
    },
    "nivel_grupo_niño": {
        label: "Nivel",
        arrayName: "niveles",
        campoName: "valor",
        url: "aux/nivel/niño",
        queryKey: "nivel_grupo-niño",
        errorLabel: "Ha habido un error al descargar los tipos de nivel",
    },
    "nivel_grupo_adulto": {
        label: "Nivel",
        arrayName: "niveles",
        campoName: "valor",
        url: "aux/nivel/adulto",
        queryKey: "nivel_grupo-adulto",
        errorLabel: "Ha habido un error al descargar los tipos de nivel",
    },
    "motivo_baja": {
        label: "Motivo de baja",
        arrayName: "motivos",
        campoName: "nombre",
        url: "aux/motivo_baja/all",
        queryKey: "motivo_baja-all",
        errorLabel: "Ha habido un error al descargar los motivos de baja",
    },
    "aula": {
        label: "Aula",
        arrayName: "items",
        campoName: "denominacion",
        url: "aula/all",
        queryKey: "aula-all",
        errorLabel: "Ha habido un error al descargar las aula",
    },
    "preferencia_horario": {
        label: "Preferencia de horario",
        arrayName: "items",
        campoName: "valor",
        url: "aux/preferencia_horario/all",
        queryKey: "aux-preferencia-horario-all",
        errorLabel: "Ha habido un error al descargar las preferencias de horario",
    },
    "motivo_incidencia_fichaje": {
        label: "Motivo de la incidencia",
        arrayName: "items",
        campoName: "valor",
        url: "aux/motivo_incidencia_fichaje/all",
        queryKey: "aux-motivo_incidencia_fichaje-all",
        errorLabel: "Ha habido un error al descargar los motivos de incidencia",
    }
    ,
    "examenes": {
        label: "Examen",
        arrayName: "items",
        campoName: "valor",
        url: "aux/examenes_oficiales/all",
        queryKey: "aux-examenes_oficiales-all",
        errorLabel: "Ha habido un error al descargar los examenes oficiales",
    },
    "acreedor": {
        label: "Acreedor",
        arrayName: "",
        campoName: "nombre_completo",
        url: "acreedor",
        queryKey: "acreedor-all",
        errorLabel: "Ha habido un error al descargar los acreedores",
    }
}

export default options