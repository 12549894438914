import { Grid } from '@mui/material'
import { Button, ImagePicker, Input } from 'components/forms/components'
import { H3 } from 'components/texts/index.js'
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { useFastCreateFormLogic, useFastFormEditSaveLogic, useResetForm } from 'hooks'

/**
 * Componente para la creación o edición rápida de responsables.
 * Proporciona un formulario con campos específicos para ingresar o modificar la información de un responsable.
 * Integra funcionalidades de guardado y reseteo mediante el uso de hooks personalizados.
 * 
 * @param {React.RefObject} addResponsableModalEditRef - Referencia al modal de edición de responsables.
 * @param {function} register - Función de react-hook-form para registrar los campos del formulario. Se utiliza para conectar cada input con el formulario global, permitiendo su validación y manejo.
 * @param {Object} errors - Objeto que contiene los errores de validación de cada campo, proporcionado por react-hook-form.
 * @param {function} getValues - Función de react-hook-form para obtener los valores actuales de todos los campos del formulario.
 * @param {function} reset - Función de react-hook-form para resetear el formulario a valores iniciales.
 * @param {function} setResponsables - Setter del estado para actualizar la lista de responsables. Se utiliza para manejar la adición o actualización de responsables en el estado global.
 * @returns {JSX.Element} - Retorna el formulario para la creación o edición de responsables.
 */
export default function FastCreateFormEdit({ addResponsableModalEditRef, register, errors, getValues, reset, setResponsables }) {
    useResetForm(reset)

    const { handleSave } = useFastCreateFormLogic({ addResponsableModalEditRef, getValues, setResponsables })

    return (
        <Grid container pt={2} spacing={2}>
            <Grid item xs={12} md={12} container spacing={2} px={2}>
                <Grid item xs={6}>
                    <Input label="Nombre" register={register} errors={errors} name={"responsable_nombre"} className="w-100" uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Primer apellido" register={register} errors={errors} name={"responsable_apellido1"} className="w-100" uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Segundo apellido" register={register} errors={errors} name={"responsable_apellido2"} className="w-100" uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Email" register={register} errors={errors} name={"responsable_email"} className="w-100" />
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect name={"responsable_tipo_documento_id"} keyValue={"tipo_documento"} register={register} errors={errors} />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Numero de documento" register={register} errors={errors} name={"responsable_numero_documento"} className="w-100" uppercase />
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect name={"responsable_tipo_relacion_id"} keyValue={"tipo_relacion"} register={register} errors={errors} />
                </Grid>
            </Grid>


            <Grid item xs={12} md={12} container spacing={2} px={2}>
                <Grid item xs={12}>
                    <H3 className={"m-0"}>Otros datos</H3>
                </Grid>

                <Grid item xs={6}>
                    <Input label="Profesion" register={register} errors={errors} className="w-100" name={"responsable_profesion"} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Teléfono móvil" register={register} errors={errors} className="w-100" name={"responsable_telefono_movil"} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Teléfono trabajo" register={register} errors={errors} className="w-100" name={"responsable_telefono_trabajo"} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Otro teléfono" register={register} errors={errors} className="w-100" name={"responsable_telefono_otros"} uppercase />
                </Grid>
            </Grid>

            <Grid item xs={12} display={"flex"} justifyContent={"end"}>
                <Button onClick={handleSave}>
                    Guardar
                </Button>
            </Grid>
        </Grid>
    )
}