import { Grid, Stack } from "@mui/material";
import { H3, Text } from "components/texts";
import Skeleton from './skeleton'
import { Line } from "components/data_display";
import { AlumnoPicker } from "components/forms/components/custom";
import { Input, Button } from "components/forms/components";
import { useState, useEffect, useRef } from "react"
import { CreateIncidenciaModal, EditIncidenciasNoResueltasModal, DatosColegioModal } from "components/modals"
import { RequestDatosColegio } from "objects/requests"
import { useErrorHandler } from "hooks"
import Requests from '../../../../clases-particulares/edit/Requests/Requests.js'
import { enqueueSnackbar } from 'notistack';

/**
 * Este hook `useLogic` maneja la lógica de la funcionalidad relacionada con la gestión de datos de los alumnos y la visualización
 * de la información del colegio. Incluye estados para controlar la apertura y cierre de modales, datos del alumno seleccionado,
 * datos del colegio, y funciones para obtener los datos del colegio asociados a un alumno.
 *
 * @returns {Object} - Objeto que contiene los estados y funciones relacionadas con la lógica de la aplicación.
 */
function useLogic(claseProp) {
    // Estados para controlar la apertura y cierre de modales, y los datos del alumno seleccionado y del colegio
    const [openIncidenciaModal, setOpenIncidenciaModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [alumnoSelected, setAlumnoSelected] = useState({});
    const [datosColegio, setDatosColegio] = useState([]);
    const handleError = useErrorHandler();
    const [isMounted, setIsMounted] = useState(false) //Se usa para los modales de incidencias (crear incidencia / seleccionar y editar incidencias no resueltas)
    const [claseParticularState, setClaseParticularState] = useState({})


    /**
     * Función para obtener los datos del colegio asociados a un alumno específico.
     *
     * @param {string} idAlumno - Identificador del alumno del que se desean obtener los datos del colegio.
     */
    const getDatosColegio = (idAlumno) => {
        // Realiza una solicitud para obtener los datos del colegio del alumno específico
        new RequestDatosColegio().get(`?id_alumno=${idAlumno}`, {}, (res) => { setDatosColegio(res?.items?.data) }, (err) => handleError(err))
    }

    /**
     * Efecto que marca el hook como montado. Esto se utiliza para controlar la renderización condicional
     * o efectos que sólo deben ejecutarse una vez que el componente ha sido completamente montado.
     */
    useEffect(() => {
        setIsMounted(true)
        setClaseParticularState(claseProp)
    }, [claseProp])

    /**
     * Devuelve el alumno
     * 
     * @param {Int} id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    const updateClaseParticularState = () => {
        new Requests().getClaseParticular(claseProp?.id, (clase) => {
            setClaseParticularState(clase)
        }, () => enqueueSnackbar("No se ha podido obtener la clase particular"))
    }

    // Retorna los estados y funciones relevantes para la lógica de la aplicación
    return {
        claseParticularState,
        updateClaseParticularState,
        isMounted,
        datosColegio,
        getDatosColegio,
        alumnoSelected,
        setAlumnoSelected,
        openIncidenciaModal,
        setOpenIncidenciaModal,
        openEditModal,
        setOpenEditModal
    };
}

/**
 * Pagina con los datos de inscripción de la clase particular
 */
export default function ClaseParticularData({
    claseParticular,

}) {
    const operadorName = claseParticular?.empleado?.nombre
    const { claseParticularState, updateClaseParticularState, isMounted, datosColegio, getDatosColegio, alumnoSelected, setAlumnoSelected } = useLogic(claseParticular)
    const [alumno, setAlumno] = useState(claseParticular?.alumno ?? null)
    const [openIncidenciaModal, setOpenIncidenciaModal] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openDatosColegioModal, setOpenDatosColegioModal] = useState(false)

    useEffect(() => {
        setAlumnoSelected(claseParticular?.alumno)
    })

    return (
        <Grid container px={5} pb={5}>
            {claseParticular ?
                <>
                    <Grid container>
                        <Grid item xs={6} py={3} pr={2}>
                            <Stack>
                                <H3 fontWeight={"bold"}>Alumno</H3>
                                <AlumnoPicker
                                    alumno={claseParticular?.alumno ?? null}
                                    canEdit={false}
                                    hiddenFieldStyles={{ width: 0 }}
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={6} style={{ borderLeft: "1px solid #D9D9D9" }} py={3} pl={3}>
                            <Grid item my={2}>
                                <Text fontWeight={"bold"}>Curso escolar:</Text>
                                <Text> {claseParticular?.curso_escolar?.valor ?? "Sin rellenar"}</Text>
                            </Grid>

                            <Grid item my={2}>
                                <Text fontWeight={"bold"}>Forma de recogida:</Text>
                                <Text> {claseParticular?.forma_recogida?.nombre ?? "Sin rellenar"}</Text>
                            </Grid>

                            <Grid item my={2}>
                                <Text fontWeight={"bold"}>Forma de conocimiento:</Text>
                                <Text> {claseParticular?.forma_conocimiento?.nombre ?? "Sin rellenar"}</Text>
                            </Grid>

                            <Grid item my={2}>
                                <Text fontWeight={"bold"}>Edad:</Text>
                                <Text> {claseParticular?.alumno?.edad ?? "Sin rellenar"}</Text>
                            </Grid>

                            <Grid item my={1}>
                                {/**
                                 * Ponemos el operador en la parte superior del form como indica Samuel, en vez de input como en el original lo cambiamos a tipo texto, para
                                 * seguir con el patrón de los elementos superiores.
                                 */}
                                <Text fontWeight={"bold"}>Operador: </Text>
                                <Text>{operadorName}</Text>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Line />
                    </Grid>

                    <Grid container mt={2} spacing={2}>
                        <Grid item xs={4} md={4}>
                            <Grid item>
                                <Text fontWeight={"bold"}>Empresa:</Text>
                                <Text> {claseParticular?.empresa?.valor ?? "Sin rellenar"}</Text>
                            </Grid>
                        </Grid>

                        <Grid item xs={4} md={4}>
                            <Grid item>
                                <Text fontWeight={"bold"}>Actividad:</Text>
                                <Text> {(claseParticular?.registro_actividad?.actividad?.valor + " - " + claseParticular?.registro_actividad?.modalidad_cursos?.nombre) ?? "Sin rellenar"}</Text>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <Grid item>
                                <Text fontWeight={"bold"}>Lugar de la clase:</Text>
                                <Text> {claseParticular?.lugar_clase?.valor ? (claseParticular?.lugar_clase?.valor).toUpperCase() : "Sin rellenar"}</Text>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container mt={4}>
                        <Line />
                    </Grid>

                    <Grid container mt={2} spacing={2}>
                        <Grid item xs={7}>
                            <Stack>
                                <Text fontWeight={"bold"}>Observaciones:</Text>
                                <Text> {claseParticular?.observaciones ? (claseParticular?.observaciones).toUpperCase() : "Sin rellenar"}</Text>
                            </Stack>
                        </Grid>

                        <Grid item xs={5}>
                            <Grid item>
                                <Text fontWeight={"bold"}>Encuesta telefónica:</Text>
                                <Text> {claseParticular?.encuestado ? "Sí" : "No"}</Text>
                            </Grid>

                            <Grid item my={1}>
                                <Text fontWeight={"bold"}>Alumno del Colectivo:</Text>
                                <Text> {claseParticular?.alumno_colectivo ? "Sí" : "No"}</Text>
                            </Grid>

                            <Grid item>
                                <Text fontWeight={"bold"}>Avisado del comienzo de las clases:</Text>
                                <Text> {claseParticular?.encuestado ? "Sí" : "No"}</Text>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container mt={4}>
                        <Line />
                    </Grid>

                    <Grid container mt={2} spacing={2}>
                        <Grid item xs={6} md={4}>
                            <Text fontWeight={"bold"}>Fecha de inscripción:</Text>
                            <Text> {claseParticular?.fecha_alta ?? "Sin rellenar"}</Text>
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <Text fontWeight={"bold"}>Fecha de inicio:</Text>
                            <Text> {claseParticular?.fecha_inicio ?? "Sin rellenar"}</Text>
                        </Grid>

                        <Grid item xs={6} md={4}>
                            <Text fontWeight={"bold"}>Fecha de baja:</Text>
                            <Text> {claseParticular?.fecha_baja ?? "Sin rellenar"}</Text>
                        </Grid>
                    </Grid>

                    <Grid container mt={4}>
                        <Line />
                    </Grid>

                    <Grid container mt={2} spacing={2}>
                        <Grid item xs={6} py={3} pr={2}>
                            <Stack>
                                <H3 fontWeight={"bold"}>Profesor de la clase particular</H3>
                                <Text>
                                    {claseParticular?.profesor?.nombre && claseParticular?.profesor?.apellido1 && claseParticular.profesor?.apellido2
                                        ? `${claseParticular.profesor.nombre} ${claseParticular.profesor.apellido1} ${claseParticular.profesor.apellido2}`
                                        : "Sin rellenar"}
                                </Text>
                            </Stack>
                        </Grid>

                        <Grid item xs={6} py={3} pr={2}>
                            <Grid item>
                                <Text fontWeight={"bold"}>Sexo:</Text>
                                <Text> {claseParticular?.profesor?.sexo?.valor ?? "Sin rellenar"} </Text>
                            </Grid>

                            <Grid item>
                                <Text fontWeight={"bold"}>Origen del profesor:</Text>
                                <Text> {claseParticular?.origen_profesor?.valor ?? "Sin rellenar"} </Text>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container mt={4}>
                        <Line />
                    </Grid>

                    <Grid container px={5} mt={2} spacing={2} style={{ justifyContent: 'center' }}>
                        {claseParticularState?.alumno?.incidencias.filter(incidencia => incidencia.solucionada === 0)?.length > 0 ?
                            <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                                <Button onClick={() => setOpenEditModal(true)} variant="outlined">
                                    Incidencias no resueltas
                                </Button>
                            </Grid> : null}

                        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                            <Button className="" onClick={() => setOpenIncidenciaModal(true)} variant="outlined">Nueva incidencia</Button>
                        </Grid>

                        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>

                            <Button
                                onClick={() => setOpenDatosColegioModal(true)}
                                variant="outlined"
                                disabled={!((alumnoSelected && alumnoSelected.hasOwnProperty('id')) || (alumno))}
                            >
                                Datos del Colegio
                            </Button>

                        </Grid>

                        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                            <Button disabled className="" variant="outlined">Nueva Tutoría</Button>
                        </Grid>
                    </Grid>
                </>

                : <Skeleton />}

            <CreateIncidenciaModal
                isMounted={isMounted}
                open={openIncidenciaModal}
                setOpen={setOpenIncidenciaModal}
                alumno={alumnoSelected?.hasOwnProperty('id') ? alumnoSelected : (claseParticular ? claseParticular?.alumno : null)}
                onSaveFinish={updateClaseParticularState}
            />
            <EditIncidenciasNoResueltasModal
                isMounted={isMounted}
                incidencias={claseParticularState?.alumno?.incidencias.filter(incidencia => incidencia.solucionada === 0)}
                alumno={alumnoSelected?.hasOwnProperty('id') ? alumnoSelected : (claseParticular ? claseParticular?.alumno : null)}
                open={openEditModal}
                setOpen={setOpenEditModal}
                onSaveFinish={updateClaseParticularState}
            />

            <DatosColegioModal
                open={openDatosColegioModal}
                setOpen={setOpenDatosColegioModal}
                // getDatosColegio > Función para obtener los datos del colegio asociados a un alumno específico.
                getDatosColegio={getDatosColegio}
                datosColegio={datosColegio}
                alumno={alumnoSelected?.hasOwnProperty('id') ? alumnoSelected : alumno}
            />
        </Grid>
    )
}