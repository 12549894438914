

import React from 'react'
import { Text } from 'components/texts'
import '../style.css'
import DeleteIcon from '@mui/icons-material/Delete'
import Statics from 'objects/common/Statics'

export default function TemplateColegio({ day, event, calculateSteps, onDeleteClick = () => { }, offset=0 }) {
    const txtColor = Statics.detectTextColorByBackgroundColor(event.color)

    const steps = calculateSteps(event.hora_inicio, event.hora_fin)
    return (
        <td key={day.value} rowSpan={steps - offset} style={{ backgroundColor: event.color, position: "relative", cursor: "pointer" }} onClick={() => onDeleteClick(event.id, event)}>
            <DeleteIcon style={{ color: txtColor, position: 'absolute', top: 16, right: 16 }} />

            <Text color={txtColor}>{event.actividad ?? ""}</Text><br />
            <Text color={txtColor}>Clave: {event.clave ?? ""}</Text>
        </td>
    )
}