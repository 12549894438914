import { Avatar } from '@mui/material';
import React, { Component } from 'react';
import '../style.css';

export default class FileImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            image: this.props.image ?? null,
            renderImage: this.getRenderImage(this.props.image),
        }

    }

    getRenderImage = (image) => {
        // Si la imagen es un Blob, crea una URL de objeto
        if (image instanceof Blob) {
            return URL.createObjectURL(image);
        }
        // Si no, la imagen debe ser una cadena (URL), así que la retorna directamente
        return image;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.image !== this.props.image) {
            // Si previamente había un Blob, revoca la URL de objeto
            if (prevProps.image instanceof Blob) {
                URL.revokeObjectURL(this.state.renderImage);
            }
            // Actualiza la imagen renderizada
            this.setState({ image: this.props.image, renderImage: this.getRenderImage(this.props.image) });
        }
    }

    componentWillUnmount() {
        // Revoca la URL de objeto si la imagen es un Blob
        if (this.state.image instanceof Blob) {
            URL.revokeObjectURL(this.state.renderImage);
        }
    }

    render() {
        return <Avatar src={this.state.renderImage} sx={[{ width: "100%", height: "100%", borderRadius: 1 }]} imgProps={{ sx: { objectFit: "contain" } }} />
    }
}
