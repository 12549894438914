import { EmpleadoListContext } from "contexts/employee/ListContext"
import { useAlert } from "hooks"
import { useContext, useEffect, useState } from "react"

/**
 * Hook `useWrapperLogic` que se encarga de la lógica para obtener y actualizar la información de un libro específico.
 * Utiliza el contexto `EmpleadoListContext` para trabajar con una lista de libros y métodos para actualizar un libro.
 * Maneja el estado del libro actual, la pestaña seleccionada en la interfaz de usuario y el ID del libro,
 * y proporciona funciones para actualizar alertas y obtener datos del libro desde el servidor si es necesario.
 *
 * @param {Object} navigationParams - Parámetros de navegación que contienen el ID del libro actual.
 * @returns {Object} - Retorna el estado y las funciones para interactuar con los detalles del libro y la UI.
 */
export default function useWrapperLogic(requests, navigationParams) {
    const { items, updateItem } = useContext(EmpleadoListContext)

    const [libro, setLibro] = useState(null)

    const [tab, setTab] = useState("1")

    const [id_libro, setIdLibro] = useState(navigationParams.id_libro)
    
    const updateAlert = useAlert()

    const getLibro = (getFromServer = false) => {
        const item = items.find(item => item?.id == id_libro)
        if (items.length == 0 || !item || getFromServer) {
            requests.getLibro(id_libro, (libro) => {
                setLibro(libro)
                updateItem(libro)
            }, () => updateAlert("No se ha podido recuperar el libro."))
        } else {
            setLibro(item)
        }
    }

    useEffect(getLibro, [id_libro])

    return { libro, setLibro, tab, setTab, id_libro, setIdLibro, updateAlert, getLibro }
}