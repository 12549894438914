import React, { Component } from 'react'
import Modal from 'components/modals/Modal.jsx'
import { Text } from 'components/texts/index.js'
import { Button } from 'components/forms/components/index.js'
import { Grid, Stack } from '@mui/material'

/**
 * Modal para confirmar que se quiere continuar
 */
export default class WarningNotSaved extends Component {

    constructor(props) {
        super(props)
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <Modal ref={(ref) => this.modal = ref} title={"No se han guardado los cambios"}>
                <Grid container px={10} py={5}>
                    <Grid item xs={12} display="flex" justifyContent={"center"}>
                        <Text>{this.props.text ?? "¿Estás seguro de que quieres continuar? Aún no has guardado los cambios realizados"}</Text>
                    </Grid>

                    <Grid item xs={12} display="flex" justifyContent={"center"}>
                        <Stack display="flex" flexDirection={"row"} mt={2}>
                            <Button color={"cancel"} className="mx-2" onClick={this.props.onGoBack}>
                                Volver
                            </Button>

                            <Button className="mx-2" onClick={this.props.onContinue}>
                                Continuar
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Modal>
        )
    }
}
