import { COLOR } from 'theme/Colors'
import { Text } from 'components/texts'
import React, { useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { RequestTempCL } from 'objects/requests'
import { LoadingButton } from 'components/forms/components'
import { Storage as StorageIcon } from '@mui/icons-material'

export default function ImportCLEmpleadosButton() {
  const [loading, setLoading] = useState(false)

  const importEmpleados = () => {
    setLoading(true)

    new RequestTempCL().importEmpleados(() => {
      enqueueSnackbar(<Text>{"Se ha hecho la importación correctamente"}</Text>, {variant: "success"})
    }, (err = "Error al importar") => {
        enqueueSnackbar(<Text>{err}</Text>, {variant: "error"})
    }, () => {
        setLoading(false)
    })
  }


  return (
    <LoadingButton className="me-5" startIcon={<StorageIcon style={{ color: COLOR.white.main }} />} loading={loading} onClick={importEmpleados}>
        Importar base de datos CL
    </LoadingButton>
  )
}
