import Skeleton from './skeleton'
import { H3 } from 'components/texts'
import React, { useRef } from 'react'
import { Alert, Grid } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { Button } from 'components/forms/components'
import { SnackbarAlert } from 'components/data_display'
import { AlumnoItem } from 'components/data_display/users'
import { DeleteRelacionModal, SearchAlumnoModal, ChooseTipoRelacionModal } from 'components/modals'

/**
 * Hook para manejar las referencias a los modales involucrados en la gestión de alumnos.
 * Inicializa y expone referencias a modales de asociar alumno, eliminar relación, mostrar alertas
 * y elegir tipo de relación, permitiendo su acceso y manipulación desde el componente principal.
 * 
 * @returns {Object} Objeto con las referencias a los distintos modales y al Snackbar de alertas.
 */
function useHandleRefs() {
  const addAlumnoModal = useRef()
  const deleteRelacionModal = useRef()
  const snackBarAlertRef = useRef()
  const chooseTipoRelacionModal = useRef()

  return { addAlumnoModal, deleteRelacionModal, snackBarAlertRef, chooseTipoRelacionModal }
}

/**
 * Componente `AlumnoData` para visualizar y gestionar los datos de los alumnos asociados a un responsable.
 * Permite la asociación de nuevos alumnos, eliminación de relaciones existentes y visualización de la lista de alumnos.
 * 
 * @param {Object} responsable - Objeto que contiene la información del responsable.
 * @param {Array} alumnos - Lista de alumnos asociados al responsable.
 * @param {Function} reload - Función para recargar los datos desde el servidor.
 * @returns {JSX.Element} Estructura del componente que incluye la visualización de alumnos, acciones posibles y modales correspondientes.
 */
export default function AlumnoData({ responsable, alumnos, reload }) {
  const { addAlumnoModal, deleteRelacionModal, snackBarAlertRef, chooseTipoRelacionModal } = useHandleRefs()

  return (
    <Grid container>
      
      {!alumnos ? <Skeleton /> : (
        <>
          <Grid item xs={6} display={"flex"} alignItems={"center"}>
            <H3 className={"m-0"}>Alumnos a su cargo:</H3>
          </Grid>

          <Grid item xs={6} display={"flex"} justifyContent={"end"} alignItems={"center"}>
            {alumnos ? (
              <Button variant={"outlined"} startIcon={<AddIcon />} onClick={() => { addAlumnoModal.current.modal.open() }}>
                Asociar alumno
              </Button>) : null}
          </Grid>
          <Grid container mt={2} spacing={2}>
            {alumnos.map(item => (<AlumnoItem key={Math.random()} alumno={item} openEditRelacionModal={(alumno) => { chooseTipoRelacionModal.current.open(alumno, responsable, true) }}
                openDeleteRelacionModal={(alumno) => { deleteRelacionModal.current.open(responsable, alumno) }} />))}

            {alumnos.length == 0 ? <Alert severity="info" className='mt-10 w-100'><H3 className="standard">Este tutor no tiene ningún alumno a su cargo.</H3></Alert> : null}
          </Grid>
        </>
      )}

      {/** MODALES */}
      <SearchAlumnoModal ref={addAlumnoModal} onSelectedCallback={(alumno) => { chooseTipoRelacionModal.current.open(alumno, responsable) }} />
      <DeleteRelacionModal ref={deleteRelacionModal} onDeleteCallback={reload} />
      <ChooseTipoRelacionModal
        ref={chooseTipoRelacionModal}
        callbackOk={(msg) => {
          snackBarAlertRef.current.updateState({ message: msg, severity: "success", open: true })
          addAlumnoModal.current.modal.close()
          reload()
        }}
        callbackError={(res) => {
          snackBarAlertRef.current.updateState({ message: res, severity: "error", open: true })
        }} />

      <SnackbarAlert ref={snackBarAlertRef} />
    </Grid>
  )
}
