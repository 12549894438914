import RequestMaster from '../RequestMaster'

export default class RequestRemesa extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/remesa'
    }

    /**
     * Crear nueva remesa
     * 
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    store(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.post(path, params, callback, callbackError, callbackFinally)
    }
}
