import React from 'react'
import Skeleton from './skeleton'
import { H3, Text } from 'components/texts'
import Statics from 'objects/common/Statics'
import { Stack, Grid } from '@mui/material'
import { Link } from 'components/data_display'
import { auxFieldName } from 'constants/Responses'
import { Button } from 'components/forms/components'
import { Edit as EditIcon } from '@mui/icons-material'

/**
 * Componente `IncidenciaAlumnoData` que presenta información detallada sobre una incidencia asociada a un alumno.
 * Muestra datos como la fecha de la incidencia, su descripción, si ha sido solucionada, el curso escolar relacionado
 * y el tipo de incidencia. En caso de que los datos de la incidencia no estén disponibles, se muestra un componente
 * de esqueleto (`Skeleton`) como indicador de carga.
 * 
 * @param {Object} incidencia - Objeto que contiene la información de la incidencia.
 * @returns {JSX.Element} - La UI del componente con los datos de la incidencia o un indicador de carga.
 */
export default function IncidenciaAlumnoData({ incidencia }) {

	return (
		<Grid container spacing={5}>
			{!incidencia ?
				<Skeleton />
				:
				(
					<React.Fragment>
						<Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
							<H3>Datos de la incidencia:</H3>

							<Link to="editar">
								<Button variant={"outlined"} startIcon={<EditIcon />}>
									Editar
								</Button>
							</Link>
						</Grid>

						<Grid item xs={4}>
							<Stack>
								<Text fontWeight={"bold"}>Fecha:</Text>
								<Text>{incidencia.fecha ? Statics.formatDate(incidencia.fecha) : ""}</Text>
							</Stack>
						</Grid>

						<Grid item xs={4}>
							<Stack>
								<Text fontWeight={"bold"}>Incidencia:</Text>
								<Text>{incidencia.incidencia ?? ""}</Text>
							</Stack>
						</Grid>

						<Grid item xs={4}>
							<Stack>
								<Text fontWeight={"bold"}>Solucionada:</Text>
								<Text>{incidencia.solucionada ? "Sí" : "No"}</Text>
							</Stack>
						</Grid>


						<Grid item xs={4}>
							<Stack>
								<Text fontWeight={"bold"}>Curso escolar:</Text>
								<Text>{incidencia?.curso_escolar[auxFieldName.cursoEscolar] ?? ""}</Text>
							</Stack>
						</Grid>

						<Grid item xs={4}>
							<Stack>
								<Text fontWeight={"bold"}>Tipo de incidencia:</Text>
								<Text>{incidencia.tipo_incidencia ? incidencia.tipo_incidencia[auxFieldName.tipoIncidencia] : ""}</Text>
							</Stack>
						</Grid>
					</React.Fragment>
				)
			}
		</Grid>
	)
}
