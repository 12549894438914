import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests.js'
import { Container, Paper } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { CreateHeader } from 'pages/logged/admin/components'
import { useState } from 'react'
import { SnackbarProvider } from 'notistack'
import { BreadcrumbsList, Line } from 'components/data_display'
import { validationSchema } from 'components/forms/display/alumno/DataForm/validation.js'
import { DataFormAlumno, DataDomiciliacionBancariaFormAlumno } from 'components/forms/display'
import { useCheckDirtyFields, useCheckMinorAge, useCreateAlumno } from 'hooks'
import ResponsableData from './parts/ResponsableData/index.jsx'

/**
 * Componente de React para crear alumnos.
 * Este componente utiliza varios hooks y librerías para gestionar formularios,
 * validaciones, estados y manejo de errores. También integra componentes de UI
 * para la visualización de datos y navegación.
 * 
 * Utiliza `react-hook-form` para el manejo de formularios y `yup` para la validación
 * de esquemas. El estado y la lógica específica de la aplicación se manejan mediante
 * hooks personalizados y el contexto proporcionado por `notistack` para las notificaciones.
 * 
 * @returns {JSX.Element} Un componente que renderiza el formulario de creación de alumnos,
 * incluyendo campos de formulario dinámicos basados en la edad del alumno y la gestión
 * de responsables legales en caso de ser menor de edad.
 */
export default function CreateAlumnos() {
  const [responsables, setResponsables] = useState([])

  const { register, handleSubmit, getValues, watch, formState: { errors, dirtyFields }, setValue } = useForm({ resolver: yupResolver(validationSchema(responsables)) })

  const { esMenorDeEdad } = useCheckMinorAge(watch)

  const { createAlumno, headerRef, updateChanges } = useCreateAlumno(new Requests(), esMenorDeEdad, responsables)

  useCheckDirtyFields(dirtyFields, updateChanges)

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(createAlumno)}>
        <BreadcrumbsList breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../", label: "Alumnos" },
          { to: "./", label: "Crear alumno", actualItem: true },
        ]} />

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <CreateHeader
            ref={headerRef}
            title={"Crear alumno"} />

          <Line />

          <DataFormAlumno register={register} errors={errors} setValue={setValue} />
        </Paper>

        <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
            <ResponsableData
              register={register}
              getValues={getValues}
              errors={errors}
              responsables={responsables}
              setResponsables={setResponsables} />
          </Paper>

        {/* {esMenorDeEdad &&
          <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
            <ResponsableData
              register={register}
              getValues={getValues}
              errors={errors}
              responsables={responsables}
              setResponsables={setResponsables} />
          </Paper>
        } */}

        <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
          <DataDomiciliacionBancariaFormAlumno register={register} errors={errors} setValue={setValue} />
        </Paper>

        <SnackbarProvider autoHideDuration={3000} />
      </form>
    </Container>
  )
}