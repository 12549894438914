import React from 'react'
import Skeleton from './skeleton'
// import { ASSETS } from 'constants/Assets'
import { H3, Text } from 'components/texts'
import { Avatar, Box, Grid } from '@mui/material'
import { useImageLoader } from 'hooks'
import { DEFAULT_IMAGES_URL } from 'constants/DefaultImagesUR'

/**
 * Componente `IncidenciaAlumnoData` muestra la información del alumno relacionada con una incidencia.
 * Utiliza un hook personalizado `useImageLoader` para cargar de manera eficiente la imagen del alumno.
 * En caso de que la incidencia no esté cargada, muestra un esqueleto como indicador de carga.
 * 
 * @param {Object} incidencia - Objeto que contiene la información de la incidencia y el alumno asociado.
 * @returns {JSX.Element} Estructura de la UI para mostrar datos del alumno en el contexto de una incidencia.
 */
export default function IncidenciaAlumnoData({ incidencia }) {
	const alumno = incidencia?.alumno ?? {}

	const { imageSrc } = useImageLoader(alumno, 'foto_logo', DEFAULT_IMAGES_URL.alumno, true)

	return (
		<Grid container>
			{!incidencia ?
				<Skeleton />
				:
				(
					<React.Fragment>
						<Grid item xs={12} mb={5}>
							<H3>Alumno de la tutoría:</H3>
						</Grid>

						<Grid item xs={2}>
							<Avatar
								alt={"Foto de: " + (alumno?.nombre ?? "")}
								// src={`${ASSETS.images + (alumno.foto_logo ? alumno.foto_logo : 'private/alumno/perfiles/0/default.png')}`}
								src={imageSrc}
								sx={{ width: "80%", height: "100%", borderRadius: 2 }} />
						</Grid>

						<Grid item xs={8}>
							<Box mt={2}>
								<H3>{alumno.nombre + " " + (alumno.apellido1 ?? "") + " " + (alumno.apellido2 ?? "")}</H3>

								<Box display={"flex"}>
									<Text fontWeight={"bold"}>Documento de identidad:</Text>
									<Text className="mx-2">{alumno.numero_documento + " - " + alumno.tipo_documento?.nombre}</Text>
								</Box>

								<Box display={"flex"}>
									<Text fontWeight={"bold"}>Género:</Text>
									<Text className="mx-2">{alumno.sexo?.valor}</Text>
								</Box>
							</Box>
						</Grid>
					</React.Fragment>
				)
			}
		</Grid>
	)
}
