import { useContext, useRef } from "react"
import { useNavigate } from "react-router-dom"
import Statics from 'objects/common/Statics.js'
import { ChangesContext } from "contexts/ChangesContext"
import { useDatosColegioAgeCheck, useErrorHandler } from "hooks"

/**
 * Hook para manejar la lógica de edición de los datos del colegio.
 * Incluye funciones para actualizar el encabezado, realizar la petición de edición y manejar el ID del colegio.
 * 
 * @param {Function} updateAlert - Función para mostrar alertas en la interfaz.
 * @param {Function} getValues - Función de `react-hook-form` para obtener los valores del formulario.
 * @param {Function} reset - Función de `react-hook-form` para reiniciar el formulario.
 * @param {number} id_datos_colegio - ID actual de los datos del colegio.
 * @param {Function} setIdDatosColegio - Función para actualizar el ID de los datos del colegio.
 * @param {Function} onSaveFinish - Callback para ejecutar acciones adicionales después de guardar los datos.
 * @returns {Object} Objeto con funciones y referencias para la lógica de edición.
*/
export default function useEditDatosColegioLogic(requests, updateAlert, getValues, reset, id_datos_colegio, setIdDatosColegio, onSaveFinish, datosColegio) {
    const navigate = useNavigate()

    const handleError = useErrorHandler()

    const { updateChanges } = useContext(ChangesContext)

    const headerRef = useRef()
    
    const {checkEdad} = useDatosColegioAgeCheck({alumnoEdad: datosColegio?.alumno?.edad})
    
    /**
     * Función para editar los datos del colegio. Realiza una petición al servidor y maneja la respuesta.
    */
   const editDatosColegio = () => {
       headerRef.current.button.setLoading(true)
       requests.editDatosColegio(id_datos_colegio, getValues, callbackOk, callbackError)
    }
    
    /**
     * Función de callback que se ejecuta cuando la petición al servidor para editar los datos del colegio es exitosa.
     * 
     * @param {object} res 
     */
    const callbackOk = (res) => {
        reset({}, { keepValues: true })
        updateChanges(false)
        onSaveFinish()
        updateId(id_datos_colegio)
        updateAlert('Se ha editado correctamente. Redirigiendo...', 'success')
        checkEdad(res.item)
    }

    const callbackError = (err) => {
        headerRef.current.button.setLoading(false)
        // updateAlert('Ha habido un error al editar la incidencia.')
        handleError(err)
    }

    /**
     * //TODO: seria interesante hacer un hook dinamico con esta funcion 
     * 
     * Actualiza el ID de los datos del colegio en la URL y el estado local para la navegación.
     */
    const updateId = async (id) => {
        await Statics.delay(3000).then(() => { navigate(`../alumnos/datos-colegio/${id}`) }) //Modificada la URL, para ir al single
        setIdDatosColegio(id)
    }

    return { headerRef, updateChanges, updateId, editDatosColegio }
}