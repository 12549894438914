import React from 'react'
import { Text, H3 } from 'components/texts'
import { Grid, Stack } from '@mui/material'
import { Line } from 'components/data_display'
import { Button } from 'components/forms/components'
import { Visibility as EyeIcon } from "@mui/icons-material"

export default function GrupoInscripcionColegioData({ inscripcion }){
    const { grupo } = inscripcion
    return (
        <Grid container py={3}>
            <Grid container px={4} mt={2}>
                <Grid item xs={12}>
                    <H3>Información del grupo</H3>
                </Grid>

                <Grid item xs={6} md={4} mt={2}>
                    <Stack>
                        <Text fontWeight={"bold"}>Grupo</Text>
                        <Text>{grupo?.clave ?? ""}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={6} md={4} mt={2}>
                    <Stack>
                        <Text fontWeight={"bold"}>Alumnos</Text>
                        <Text>{grupo?.numAlumnos ?? ""}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={6} md={4} mt={2}>
                    <Stack>
                        <Text fontWeight={"bold"}>Horario</Text>
                        <Text>{grupo && grupo.horario ? grupo.horario.horario_formatted : ""}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={6} md={4} mt={2}>
                    <Stack>
                        <Text fontWeight={"bold"}>Profesor</Text>
                        <Text>
                            {grupo?.profesor_actual?.empleado ? 
                                (grupo.profesor_actual.empleado.nombre ?? "") + " " + (grupo.profesor_actual.empleado.apellido1 ?? "") + " " + (grupo.profesor_actual.empleado.apellido2 ?? "") 
                            : ""}
                        </Text>
                    </Stack>
                </Grid>

                <Grid item xs={6} md={4} mt={2}>
                    <Stack>
                        <Text fontWeight={"bold"}>Libro</Text>
                        <Text>{grupo?.libro?.nombre ?? ""}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={6} md={4} mt={2}>
                    <Button color={"cancel"} startIcon={<EyeIcon />}>
                        Composición del grupo
                    </Button>
                </Grid>
            </Grid>
                            
            <Grid container mt={3}>
                <Line />
            </Grid>

            <Grid container mt={3} px={4} spacing={2}>
                <Grid item xs={6}>
                    <Stack>
                        <Text fontWeight={"bold"}>Comentarios del profesor</Text>
                        <Text>{inscripcion?.comentarios_profesor ?? ""}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={6}>
                    <Stack>
                        <Text fontWeight={"bold"}>Observaciones</Text>
                        <Text>{inscripcion?.observaciones ?? ""}</Text>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    )
}
