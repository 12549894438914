import { formatearDefiniciones, formatearDefinicionesColegios } from './functions'
import { TemplateColegio, TemplateAcademiaClasesParticulares } from './templates'

export const days  = [
    {value: 1, label: 'Lunes',     short: "L"},
    {value: 2, label: 'Martes',    short: "M"},
    {value: 3, label: 'Miércoles', short: "X"},
    {value: 4, label: 'Jueves',    short: "J"},
    {value: 5, label: 'Viernes',   short: "V"},
    {value: 6, label: 'Sábado',    short: "S"},
];


export const types = {
    "colegios":            {formatter: formatearDefinicionesColegios, template: TemplateColegio },
    "academia":            {formatter: formatearDefiniciones,         template: TemplateAcademiaClasesParticulares},
    "clases-particulares": {formatter: formatearDefiniciones,         template: TemplateAcademiaClasesParticulares}, 
}

export const morningHours = [
    '07:00', '07:15', '07:30', '07:45',
    '08:00', '08:15', '08:30', '08:45',
    '09:00', '09:15', '09:30', '09:45',
    '10:00', '10:15', '10:30', '10:45',
    '11:00', '11:15', '11:30', '11:45',
    '12:00', '12:15', '12:30', '12:45',
    '13:00', '13:15', '13:30', '13:45',
    '14:00', '14:15', '14:30', '14:45',
];
  
export const afternoonHours = [
    '15:00', '15:15', '15:30', '15:45',
    '16:00', '16:15', '16:30', '16:45',
    '17:00', '17:15', '17:30', '17:45',
    '18:00', '18:15', '18:30', '18:45',
    '19:00', '19:15', '19:30', '19:45',
    '20:00', '20:15', '20:30', '20:45',
    '21:00'
];