import React, { Component } from 'react'
import { Modal } from 'components/modals'
import DataFormEvent from '../form'
import Requests from '../../Requests'
import { enqueueSnackbar } from 'notistack'
import { Text } from 'components/texts'

/**
 * Modal para crear/editar eventos
 */
export default class EventModal extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            date: this.props.date ?? null,
            hour: false,
            loading: false,
        }

        this.requests = new Requests()
    }


    componentDidUpdate(prevProps) {
        if(this.props.date != prevProps.date)
            this.setState({date: this.props.date})
    }


    /**
     * Abre el modal y actualiza la fecha
     * 
     * @param {String} date
     */
    open = (date, isTime) => {
        const finalDate = isTime ? date.split('T')[0] : date
        const finalHour = isTime ? date.split('T')[1].substring(0, 5) : null

        this.setState({date: finalDate, hour: finalHour}, () => {
            this.modal.open()
        })
    }


    /**
     * Cierra el modal
     */
    close = () => {
        this.setState({date: null, hour: null}, () => {
            this.modal.close()
        })
    }

    
    /**
     * Callback para cuando se vaya a crear un evento
     * 
     * @param {Array} values
     */
    onCreate = (values) => {
        values.date = this.state.date

        this.requests.createEvento(values, (res) => {
            this.close()
            enqueueSnackbar(<Text>Se ha creado el evento correctamente.</Text>, { variant: "success" })

            if(this.props.onCreateEvento)
                this.props.onCreateEvento(res.evento)
        }, (res) => {
            enqueueSnackbar(<Text>{"No se ha podido crear el evento: " + res}</Text>, { variant: "error" })
        })
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <Modal ref={(ref) => this.modal = ref} title={"Crear evento para el día: " + (this.state.date ? this.state.date.split('-').reverse().join('/') : "")} style={{minWidth: "60%"}}>
                <DataFormEvent key={Math.random()} onClick={this.onCreate} btnText={"Crear evento"} evento={{hour: this.state.hour}} userId={this.props.userId} />
            </Modal>
        )
    }
}
