import RequestMaster from "../RequestMaster";

export default class RequestListadosLibro extends RequestMaster {
    constructor() {
        super();
        this.URL_BASE = this.URL_BASE + "/libro/listados";
    }

    /**
     * Devuelve un PDF con inventario de los libros en PDF
     *
     * @param {FormData} params
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    inventario(params, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "inventario?curso_escolar_id=" + params
        this.pdf(path, {}, callbackError, callbackFinally)
    }

    /**
     * Devuelve un PDF con los detalles de los libros en PDF
     *
     * @param {FormData} params
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    detalle(params, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "detalle?curso_escolar_id=" + params
        this.pdf(path, {}, callbackError, callbackFinally)
    }

    /**
     * Devuelve un PDF con lis libros pendientes de entregar
     *
     * @param {FormData} params
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    pendienteEntrega(params, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "pendiente_entrega?curso_escolar_id=" + params
        this.pdf(path, {}, callbackError, callbackFinally)
    }

    /**
     * Devuelve un PDF con el stock de los libros
     *
     * @param {FormData} params
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    stockLibros(params, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "stock?curso_escolar_id=" + params
        this.pdf(path, {}, callbackError, callbackFinally)
    }

    /**
     * Devuelve la contabilidad de los libros
     *
     * @param {FormData} params
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    contabilidad(params, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "contabilidad?curso_escolar_id=" + params
        this.pdf(path, {}, callbackError, callbackFinally)
    }

    /**
     * Devuelve un PDF con los libros pendientes de pago,
     * agrupados por nombre de colegio / academia y alumno
     *
     * @param {FormData} params
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    pendientePago(params, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "pendiente_pago?curso_escolar_id=" + params
        this.pdf(path, {}, callbackError, callbackFinally)
    }

    /**
     * Devuelve un PDF con la estadistica de los libros
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    estadistica(params, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "estadistica?curso_escolar_id=" + params;
        this.pdf(path, {}, callbackError, callbackFinally)
    }

    /**
     * Devuelve un PDF con las devoluciones de los libros
     *
     * @param {FormData} params
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    devoluciones(params, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "devoluciones?curso_escolar_id=" + params
        this.pdf(path, {}, callbackError, callbackFinally)
    }

    /**
     * Devuelve un PDF con el resumen de las cuentas de los libros
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    resumenCuentas(params, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "resumen_cuentas?curso_escolar_id=" + params
        this.pdf(path, {}, callbackError, callbackFinally)
    }

    /**
     * Devuelve un PDF con el precio de los libros activos
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    precio(params, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "precio?curso_escolar_id=" + params;
        this.pdf(path, {}, callbackError, callbackFinally)
    }

}
