import RequestMaster from '../RequestMaster'

export default class RequestLibro extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/libro'
    }

    
    /**
     * Devuelve todos los libros
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    search(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.get(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Devuelve todos los libros
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    all(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "all"
        this.get(path, params, callback, callbackError, callbackFinally)
    }
}
