import { RequestGrupoAcademia } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
    * Devuelve grupo
    * 
    * @param {Int}      id 
    * @param {Function} callbackOk 
    * @param {Function} callbackError 
    */
    getGrupo(id, callbackOk = () => { }, callbackError = () => { }) {
        const params = {
            appends: ['curso_escolar', 'registro_actividad', 'nivel_grupo', 'horarios', 'profesor_actual', 'libro']
        }

        new RequestGrupoAcademia().get(id, params, (res) => {
            res ? callbackOk(res) : callbackError(res)
        })
    }

    getPreliminar(id, callbackError = () => { }, callbackFinally = () => {}) {
        new RequestGrupoAcademia().preliminar(id, {}, callbackError, callbackFinally)
    }
}

