import { Text } from 'components/texts'
import { useNavigate } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import React, { useState, useEffect, useContext } from 'react'
import { EmpleadoListContext } from 'contexts/employee/ListContext'

/**
 * Hook personalizado `useLogic` para manejar la lógica de navegación y estado de la página.
 * 
 * @param {Object} navigationParams - Parámetros de navegación obtenidos a través de `useParams`.
 * @returns {Object} - Objeto con propiedades para manejar el estado y la lógica de navegación.
 */
export default function useSingleLogic(navigationParams, requests) {
    const navigate = useNavigate()

    const { items } = useContext(EmpleadoListContext)

    const [id_libro, setIdLibro] = useState(navigationParams.id_libro)
    const [libro, setLibro] = useState(null)
    const [tab, setTab] = useState("1")

    /**
     * Realiza una petición para obtener la información del libro basada en el `id_libro`.
     * Utiliza `Requests` para la petición y maneja errores con `enqueueSnackbar` para notificaciones.
     */
    const getLibro = () => {
        const item = items.find(item => item?.id == id_libro)
        if (items.length == 0 || !item) {
            requests.getLibro(id_libro, setLibro, () => enqueueSnackbar(<Text>No se ha podido recuperar el libro.</Text>, { variant: "error" }))
        } else {
            setLibro(item)
        }
    }

    /**
     * Actualiza el ID del libro en la URL y el estado local cuando se selecciona un nuevo libro.
     * 
     * @param {string} id - ID del libro seleccionado.
     */
    const updateIdAlumno = (id) => {
        navigate(`../libros/${id}`)
        setIdLibro(id)
    }

    useEffect(getLibro, [id_libro])

    return { libro, setLibro, tab, setTab, updateIdAlumno, id_libro }
}