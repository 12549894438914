import { SnackbarProvider } from 'notistack'
import Requests from './Requests/Requests.js'
import { Container, Paper } from '@mui/material'
import { DataFormDatosColegio } from 'components/forms/display'
import { BreadcrumbsList, Line } from 'components/data_display'
import { useAlumnoDatosColegioCreateLogic } from 'hooks/index.js'
import { CreateHeader, AlumnoSection } from 'pages/logged/admin/components'

/**
 * Componente funcional para crear datos del colegio de un alumno.
 * Utiliza el hook personalizado useLogic para su lógica interna y maneja el estado del formulario,
 * incluyendo la validación y la presentación de datos relacionados con el alumno.
 */
export default function CreateDatosColegio() {
  const { setValue, isMounted, errors, updateChanges, headerRef, alumnoSectionRef, register, handleSubmit, onError, onValid } = useAlumnoDatosColegioCreateLogic({ requests: new Requests() })

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(onValid, onError)}>
        <BreadcrumbsList breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../../", label: "Alumnos" },
          { to: "./../", label: "Datos en colegios" },
          { to: "./", label: "Crear", actualItem: true },
        ]} />

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <CreateHeader
            ref={headerRef}
            title={"Crear datos"} />

          <Line />

          <AlumnoSection
            ref={alumnoSectionRef}
            title={"Elige el alumno para el que se van a crear los datos del colegio: "}
            buttonText={"Elegir alumno para los nuevos datos"}
            onSelect={() => updateChanges(true)} />

          <DataFormDatosColegio
            register={register}
            errors={errors}
            isMounted={isMounted}
            setValue={setValue}
            hideButton />
        </Paper>

        <SnackbarProvider autoHideDuration={3000} />
      </form>
    </Container>
  )
}