import { Grid } from '@mui/material';
import { Text } from 'components/texts'
import React, { useEffect, useState } from 'react'
import { LibroTemplate } from './parts';
import { useForm } from 'react-hook-form'
import Statics from 'objects/common/Statics';
import { RequestLibro } from 'objects/requests'
import { Select } from 'components/forms/components'
import { yupResolver } from '@hookform/resolvers/yup'
import validationSchema from "./validation"
import { SearchButtons } from 'components/forms/display/components';
import { useSearchFormlogic } from 'hooks';

const itemsOrder = [{ value: '{"nombre": "ASC"}', label: "Libro" }, { value: '{"id_editorial": "ASC"}', label: "Editorial" }]

/**
 * Componente `SearchFormLibros` que define un formulario de búsqueda para libros.
 * El formulario incluye campos de ordenamiento, filtro y botones de búsqueda y reinicio.
 * 
 * @param {function} onLoadingCallback - Función para controlar la carga de resultados.
 * @param {function} searchCallback - Función para manejar los resultados de la búsqueda.
 * @returns {JSX.Element} - El formulario de búsqueda de libros.
 */
export default function SearchFormLibros({ onLoadingCallback, searchCallback }) {
    const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) })

    const { resetFormState, setResetFormState, prepareParamsGlobal } = useSearchFormlogic(resetForm, getValues, reset)

    return (
        <Grid container p={2}>
            <Grid container>
                <Grid item xs={4} md={4}>
                    <Text fontWeight={'bold'} marginBottom={6}>Ordenar resultados por:</Text><br />
                    <Select width={"60%"} name={"order"} items={itemsOrder} defaultValue={itemsOrder[0].value} register={register} errors={errors} />
                </Grid>
            </Grid>

            <LibroTemplate reset={resetFormState} register={register} errors={errors} />

            <SearchButtons searchText={"Buscar libros"} onResetClick={() => { setResetFormState(true) }} onSubmitClick={handleSubmit(() => { search(1, onLoadingCallback, searchCallback, prepareParamsGlobal) })} />
        </Grid>
    )
}

/**
 * Función `search` que realiza una búsqueda de libros.
 * 
 * @param {Int}      page - Página de resultados.
 * @param {Function} onLoadingCallback - Función para controlar la carga de resultados.
 * @param {Function} searchCallback - Función para manejar los resultados de la búsqueda.
 * @param {Function} prepareParamsGlobal - Función para preparar los parámetros de búsqueda.
 */
export const search = (page = 1, onLoadingCallback, searchCallback, prepareParamsGlobal = () => { }) => {
    //Callback para controlar cuando esta cargando
    if (onLoadingCallback)
        onLoadingCallback(true)

    const params = prepareParamsGlobal(page)
    new RequestLibro().search(params, (res) => {
        if (searchCallback)
            searchCallback(res.items.data, res.items.total, page, params)
    }, () => {
        searchCallback([], 0, page, params)
    })
}


/**
 * Función `resetForm` que restablece todo el formulario.
 * 
 * @param {Function} reset - Función para restablecer el formulario.
 */
const resetForm = (reset) => {
    reset({ nombre: "" })
}