import RequestMaster from '../../RequestMaster'

export default class RequestCursoColegio extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/aux/curso_colegio'
    }


    /**
     * Hace el logueo del usuario
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    all(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "all"
        this.get(path, params, callback, callbackError, callbackFinally)
    }
}
