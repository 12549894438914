import { Grid } from '@mui/material'
import { Text } from 'components/texts'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ActividadTemplate } from './parts'
import validationSchema from "./validation"
import Statics from 'objects/common/Statics'
import { SearchButtons } from '../../../components'
import { Select } from 'components/forms/components'
import { yupResolver } from '@hookform/resolvers/yup'
import { RequestRegistroActividad } from 'objects/requests'

// Define un arreglo para el ordenamiento de los resultados de búsqueda.
const itemsOrder = [
    {
        value: '{"registro_actividad.nombre": "ASC"}', label: "Actividad"
    },
    {
        value: '{"modalidad_cursos.nombre": "ASC"}', label: "Modalidad"
    },
    {
        value: '{"tipo_actividad.valor": "ASC"}', label: "Tipo de Actividad"
    },
    // {
    //     value: '{"nombre": "ASC"}', label: "Academias"
    // }
]

// Variable global para preparar los parámetros de la petición de búsqueda.
let prepareParamsGlobal = null

/**
 * Componente `SearchFormActividadesAcademia` que ofrece una interfaz de usuario para buscar actividades académicas.
 * Utiliza `react-hook-form` para la gestión del formulario y la validación basada en un esquema Yup.
 * Incluye un campo de selección para ordenar resultados y el componente `ActividadTemplate` para los criterios de búsqueda.
 *
 * @param {Function} onLoadingCallback - Función que se llama cuando el estado de carga cambia.
 * @param {Function} searchCallback - Función que se ejecuta al realizar la búsqueda, recibiendo los resultados.
 *
 * @returns {JSX.Element} - El formulario de búsqueda con campos para especificar los criterios de búsqueda y ordenación.
 */
export default function SearchFormActividadesAcademia({ onLoadingCallback, searchCallback }) {
    const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) })

    const [resetFormState, setResetFormState] = React.useState(false);

    useEffect(() => {
        if (resetFormState == true) {
            resetForm(reset)
            setResetFormState(false)
        }
    })

    /**
     * Prepara los parámetros para la petición de búsqueda.
     * 
     * @param {number} page - Página actual para la paginación de resultados.
     * 
     * @returns {Object} Parámetros para la petición.
     */
    const prepareParams = (page) => {
        const params = Statics.objectPropsToUppercase(Statics.cleanObject(getValues()))
        params.entity_type = 'academia'
        params.relations = 'tipo_actividad,modalidad_cursos,actividad'
        params.page = page

        return params
    }

    prepareParamsGlobal = prepareParams

    return (
        <Grid container p={2}>
            <Grid container>
                <Grid item xs={4} md={4}>
                    <Text fontWeight={'bold'} marginBottom={6}>Ordenar resultados por:</Text><br />
                    <Select width={"60%"} name={"order"} items={itemsOrder} defaultValue={itemsOrder[0].value} register={register} errors={errors} />
                </Grid>
            </Grid>

            <ActividadTemplate reset={resetFormState} register={register} errors={errors} />

            <SearchButtons searchText={"Buscar actividades"} onResetClick={() => { setResetFormState(true) }} onSubmitClick={handleSubmit(() => { search(1, onLoadingCallback, searchCallback) })} />
        </Grid>
    )
}

/**
 * Realiza la búsqueda de actividades académicas.
 * Llama a `RequestRegistroActividad.search` con los parámetros de búsqueda y maneja los resultados y errores.
 *
 * @param {number} page - Número de página para la paginación de resultados.
 * @param {Function} onLoadingCallback - Función llamada cuando se inicia o finaliza la carga de datos.
 * @param {Function} searchCallback - Función llamada con los resultados de la búsqueda.
 */
export const search = (page = 1, onLoadingCallback, searchCallback) => {
    //Callback para controlar cuando esta cargando
    if (onLoadingCallback)
        onLoadingCallback(true)

    const params = prepareParamsGlobal(page)
    new RequestRegistroActividad().search(params, (res) => {
        if (searchCallback)
            searchCallback(res.actividades.data, res.actividades.total, page, params)
    }, () => {
        searchCallback([], 0, page, params)
    })
}

/**
 * Resetea el formulario de búsqueda a su estado inicial.
 * Limpia los campos del formulario utilizando la función `reset` de `react-hook-form`.
 *
 * @param {Function} reset - Función `reset` de `react-hook-form` para limpiar el formulario.
 */
const resetForm = (reset) => {
    reset({
        nombre: "",
        nombre_academia: "",
    })
}