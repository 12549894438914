import { v4 as uuid } from "uuid";
import { COLOR } from 'theme/Colors'
import React, { Component } from "react";
import { H3, Text } from 'components/texts';
import Statics from 'objects/common/Statics';
import { RequestGrupoColegio } from "objects/requests";
import { Visibility as EyeIcon } from "@mui/icons-material";
import { Button } from 'components/forms/components';
import { Grid, ListItemButton, ListItemText, Stack, List, Box, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";

export default class GrupoColegioPicker extends Component {

    constructor(props) {
        super(props)

        this.state = {
            grupos: [],
            selected: null,
            defaultId: this.props.defaultValue ?? null
        }
    }

    componentDidMount() {
        if (this.props.defaultValue)
            this.props.setValue(this.props.name ?? "id_grupo_colegio", this.props.defaultValue)

        if (this.props.id_horario) {
            this.getGrupos(this.props.id_horario)
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.id_horario != prevProps.id_horario) {
            this.getGrupos(this.props.id_horario)
        }
    }

    /**
     * Devuelve los grupos a partir del horario
     * 
     * @param {Int} id_horario 
     */
    getGrupos = (id_descripcion_horario) => {
        const params = { id_descripcion_horario, relations: 'profesor_actual,libro,actividad,horario,inscripciones' }
        new RequestGrupoColegio().search(params, (res) => {
            this.setState({ grupos: res.grupos.data, selected: this.state.defaultId ? res.grupos.data.find(item => item.id == this.state.defaultId) : null })
        }, (res) => {
            this.setState({ grupos: [], selected: null })
        })
    }


    /**
     * Resetea el valor
     */
    reset = () => {
        this.setState({ selected: null }, () => { this.props.setValue(this.props.name ?? "id_grupo_colegio", "") })
    }


    /**
     * Actualiza el item seleccionado
     * 
     * @param {Object} selected 
     */
    onSelect = (selected) => {
        this.setState({ selected }, () => {
            this.props.setValue(this.props.name ?? "id_grupo_colegio", selected?.id ?? "", { shouldDirty: true })

            if (this.props.onGrupoChange)
                this.props.onGrupoChange(selected)
        })
    }

    /**
     * Abre ventana PDF con la composicion del grupo
     */
    handleComposicionGrupo = () => {
        const selectedGrupoId = this.state.selected?.id
        if (!selectedGrupoId) {
            enqueueSnackbar(<Text>Seleccione un grupo antes de generar su composición.</Text>, { variant: 'info' })
            return
        }
        new RequestGrupoColegio().preliminar(selectedGrupoId)
    }

    /**
     * Render
     *
     * @returns {Component}
     */
    render() {
        const { selected } = this.state

        return (
            <Grid container>
                <Grid item xs={12} sm={3} display={"flex"} flexDirection={"column"}>
                    <Box sx={{ backgroundColor: COLOR.grey.light, minHeight: 150, maxHeight: 200, overflowY: "auto", borderRadius: 3, flexGrow: 1 }} px={2}>
                        <List dense>
                            {this.state.grupos.map(item => {
                                return (
                                    <ListItemButton
                                        key={uuid()}
                                        sx={{ backgroundColor: COLOR.white.main, borderRadius: 2, my: 1 }}
                                        selected={selected?.id === item.id}
                                        onClick={(e) => { this.onSelect(item) }}
                                        disabled={item.max_alumnos && item.max_alumnos <= item.inscripciones.length}>

                                        <ListItemText primary={item.clave} />
                                    </ListItemButton>
                                )
                            })}
                        </List>
                    </Box>

                    <Button variant={"outlined"} sx={{ mt: 1, alignSelf: "end" }} onClick={this.reset}>
                        Limpiar
                    </Button>

                    <TextField sx={{ width: 0 }} type={"hidden"} value={selected?.id ?? ""} variant={"standard"} {...this.props.register ? this.props.register(this.props.name ?? "id_grupo_colegio") : {}} />
                </Grid>
                <Grid container item xs={12} md={9} px={4} mt={2}>
                    <Grid item xs={12}>
                        <H3>Información del grupo</H3>
                    </Grid>

                    <Grid item xs={6} md={4} mt={2}>
                        <Stack>
                            <Text fontWeight={"bold"}>Grupo</Text>
                            <Text>{selected?.clave ?? ""}</Text>
                        </Stack>
                    </Grid>

                    <Grid item xs={6} md={4} mt={2}>
                        <Stack>
                            <Text fontWeight={"bold"}>Alumnos</Text>
                            <Text>{selected ? selected.inscripciones.length : ""}</Text>
                        </Stack>
                    </Grid>

                    <Grid item xs={6} md={4} mt={2}>
                        <Stack>
                            <Text fontWeight={"bold"}>Horario</Text>
                            <Text>{selected && selected.horario ? selected.horario.horario_formatted : ""}</Text>
                        </Stack>
                    </Grid>

                    <Grid item xs={6} md={4} mt={2}>
                        <Stack>
                            <Text fontWeight={"bold"}>Profesor</Text>
                            <Text>
                                {selected?.profesor_actual?.empleado ?
                                    (selected.profesor_actual.empleado.nombre ?? "") + " " + (selected.profesor_actual.empleado.apellido1 ?? "") + " " + (selected.profesor_actual.empleado.apellido2 ?? "")
                                    : ""}
                            </Text>
                        </Stack>
                    </Grid>

                    <Grid item xs={6} md={4} mt={2}>
                        <Stack>
                            <Text fontWeight={"bold"}>Libro</Text>
                            <Text>{selected?.libro?.nombre ?? ""}</Text>
                        </Stack>
                    </Grid>

                    <Grid item xs={6} md={4} mt={2}>
                        <Button color={"cancel"} startIcon={<EyeIcon />} onClick={this.handleComposicionGrupo}>
                            Composición del grupo
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
