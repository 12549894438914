import React from 'react'
import Skeleton from './skeleton'
import { H3, Text } from 'components/texts'
import Statics from 'objects/common/Statics'
import { Stack, Grid } from '@mui/material'
import { Link } from 'components/data_display'
import { auxFieldName } from 'constants/Responses'
import { Button } from 'components/forms/components'
import { Edit as EditIcon } from '@mui/icons-material'

export default function DatosColegioData({ datosColegio }) {

	return (
		<Grid container spacing={5}>
			{!datosColegio ?
					<Skeleton /> 
				: 
					(
					<React.Fragment>
						<Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
							<H3>Datos en el colegio</H3>

							<Link to="editar">
								<Button variant={"outlined"} startIcon={<EditIcon />}>
									Editar
								</Button>
							</Link>
						</Grid>
						
						<Grid item xs={4}>
							<Stack>
								<Text fontWeight={"bold"}>Colegio</Text>
								<Text>{datosColegio?.colegio?.nombre ?? "No definido"}</Text>
							</Stack>
						</Grid>

						<Grid item xs={4}>
							<Stack>
								<Text fontWeight={"bold"}>Curso escolar</Text>
								<Text>{datosColegio.curso_escolar[auxFieldName.cursoEscolar] ?? "No definido"}</Text>
							</Stack>
						</Grid>

						<Grid item xs={4}>
							<Stack>
								<Text fontWeight={"bold"}>Curso</Text>
								<Text>{datosColegio?.curso_colegio?.valor ?? "No definido"}</Text>
							</Stack>
						</Grid>

						<Grid item xs={4}>
							<Stack>
								<Text fontWeight={"bold"}>Letra</Text>
								<Text>{datosColegio.num_curso ?? "No definido"}</Text>
							</Stack>
						</Grid>

						<Grid item xs={4}>
							<Stack>
								<Text fontWeight={"bold"}>Libro</Text>
								<Text>{datosColegio?.libro?.nombre ?? "No definido"}</Text>
							</Stack>
						</Grid>

						<Grid item xs={4}>
							<Stack>
								<Text fontWeight={"bold"}>Nota final</Text>
								<Text>{datosColegio.nota_final ? Statics.changeDotAndComma(datosColegio.nota_final) : "No definido"}</Text>
							</Stack>
						</Grid>

					</React.Fragment>
				)
			}
		</Grid>
  )
}
