import { v4 as uuid } from 'uuid'
import { Grid } from '@mui/material'
import { Text } from 'components/texts'
import validationSchema from "./validation"
import { SearchButtons } from '../../../components'
import { Select } from 'components/forms/components'
import { RequestInscripcionAcademia } from 'objects/requests'
import { useAcademiaInscripcionSearchFormLogic } from 'hooks'
import { itemsSearch, itemsOrder } from './searchAndOrderOptions'

/**
 * Componente que renderiza el formulario de búsqueda para las inscripciones de academia.
 * Proporciona selects para elegir el tipo de búsqueda y ordenamiento, y muestra dinámicamente campos adicionales basados en la selección.
 *
 * @param {Object} props - Propiedades pasadas al componente, incluyendo callbacks para la carga y realización de búsquedas.
 * @param {Function} onLoadingCallback - Callback que se invoca para notificar el inicio y fin del proceso de carga de los datos de búsqueda. Es útil para mostrar indicadores de carga en la UI.
 * @param {Function} searchCallback - Callback que se llama con los resultados obtenidos de la búsqueda. Se utiliza para procesar y mostrar los datos obtenidos en la interfaz de usuario.
 * @param {Function} onChangeSearchIndex - Callback que se invoca cuando el usuario cambia el criterio de búsqueda a través del selector. Permite al componente padre ajustar la UI o lógica basada en la selección del usuario.

 * @returns {JSX.Element} - El formulario de búsqueda como elemento JSX.
 */
export default function SearchFormInscripcionesAcademias({ onLoadingCallback, searchCallback, onChangeSearchIndex }) {
    const { searchIndex, prepareParamsGlobal, setResetFormState, resetFormState, setValue, errors, register, handleSubmit, ActualTemplate, handleChange } = useAcademiaInscripcionSearchFormLogic({ itemsOrder, itemsSearch, onChangeSearchIndex, validationSchema })
    console.log('itemsOrder[searchIndex]',itemsOrder[searchIndex])
    console.log('itemsOrder[searchIndex][0].value',itemsOrder[searchIndex][0].value)
    return (
        <Grid container p={2}>
            <Grid container >
                <Grid item xs={6} marginRight={4}>
                    <Text fontWeight={'bold'} marginBottom={6}>Buscar por:</Text><br />
                    <Select width={"100%"} items={itemsSearch} defaultValue={itemsSearch[0].value} onChange={handleChange} />
                </Grid>

                <Grid item xs={4} >
                    <Text fontWeight={'bold'} marginBottom={6}>Ordenar resultados por:</Text><br />
                    <Select key={uuid()} width={"60%"} name={"order"} items={itemsOrder[searchIndex]} defaultValue={itemsOrder[searchIndex][0].value} register={register} errors={errors} />
                </Grid>
            </Grid>

            <ActualTemplate reset={resetFormState} register={register} errors={errors} setValue={setValue} />

            <SearchButtons searchText={"Buscar inscripciones"} onResetClick={() => { setResetFormState(true) }} onSubmitClick={() => handleSubmit(search(1, onLoadingCallback, searchCallback, prepareParamsGlobal))} />
        </Grid>
    )
}

/**
 * Realiza la búsqueda de inscripciones de acuerdo con los criterios especificados en el formulario.
 * Este método se activa al hacer clic en el botón de búsqueda y prepara los parámetros de búsqueda
 * basándose en los valores ingresados en el formulario. Luego, hace una llamada a la API para obtener
 * los resultados de la búsqueda y utiliza el callback searchCallback para manejar los datos recibidos.
 * 
 * @param {number} page - Especifica el número de la página de resultados que se desea obtener, lo que permite la implementación de la paginación en los resultados de búsqueda.
 * @param {Function} onLoadingCallback - Función que se llama para indicar el inicio y fin de la carga de datos.
 * @param {Function} searchCallback - Función callback que se invoca con los resultados de la búsqueda.
 * @param {Function} prepareParamsGlobal - Una referencia a la función `prepareParams` definida en el hook `useAcademiaInscripcionSearchFormLogic`. Esta función prepara y devuelve los parámetros de búsqueda que se enviarán en la solicitud de búsqueda, basándose en los valores actuales del formulario y en el número de página especificado.
 */
export const search = (page = 1, onLoadingCallback, searchCallback, prepareParamsGlobal) => {
    //Callback para controlar cuando esta cargando
    if (onLoadingCallback)
        onLoadingCallback(true)

    const params = prepareParamsGlobal(page)
    new RequestInscripcionAcademia().search(params, (res) => {
        if (searchCallback)
            searchCallback(res.data, res.total, page, params)
    }, () => {
        searchCallback([], 0, page, params)
    })
}