import { Stack } from "@mui/material"
import { Text } from 'components/texts'
import { useParams } from 'react-router'
import { useForm } from 'react-hook-form'
import useAlert from "hooks/utils/useAlert"
import Requests from './Requests/Requests.js'
import { useNavigate } from 'react-router-dom'
import Statics from 'objects/common/Statics.js'
import { yupResolver } from '@hookform/resolvers/yup'
import { Container, Paper, Box } from '@mui/material'
import { EditHeader } from 'pages/logged/admin/components'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { ChangesContext } from 'contexts/ChangesContext/index'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { DataFormActividadAcademia } from "components/forms/display"
import React, { useState, useContext, useEffect, useRef } from "react"
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
import { validationSchema } from 'components/forms/display/academia/actividades/DataForm/validation.js'
import SkeletonActividadesAcademia from "pages/logged/admin/academias/actividad/single/parts/ActividadesAcademiaData/skeletonActividadesAcademia.jsx"

import Headers from 'components/headers/index'
import { useErrorHandler } from "hooks/index.js"

export default function Wrapper() {
  const updateAlert = useAlert()

  const navigationParams = useParams()

  const { items, updateItem } = useContext(EmpleadoListContext)

  const [actividad, setActividad] = useState(null)
  const [id_actividad, setIdActividad] = useState(navigationParams.id_actividad)

  // const updateAlert = (message, variant = "error") => {
  //   enqueueSnackbar(<Text>{message}</Text>, { variant })
  // }

  const getActividad = (getFromServer = false) => {
    const item = items.find(item => item?.id == id_actividad)
    if (items.length == 0 || !item || getFromServer) {
      new Requests().getActividad(id_actividad, (actividad) => {
        setActividad(actividad)
        updateItem(actividad)
      }, () => updateAlert("No se ha podido recuperar la actividad."))
    } else {
      setActividad(item)
    }
  }

  useEffect(getActividad, [id_actividad])

  return (
    <React.Fragment>
      <EditActividadesAcademia key={actividad?.id ?? 0} actividad={actividad} id_actividad={id_actividad} setIdActividad={setIdActividad} setActividad={setActividad} updateAlert={updateAlert} onSaveFinish={() => getActividad(true)} />
      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}

function EditActividadesAcademia({ actividad, id_actividad, setIdActividad, setActividad, updateAlert, onSaveFinish }) {
  const navigate = useNavigate()

  const handleError = useErrorHandler()

  const { register, handleSubmit, formState: { errors, dirtyFields }, reset } = useForm({ resolver: yupResolver(validationSchema) })

  const headerRef = useRef()

  const { updateChanges } = useContext(ChangesContext)

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0)
      updateChanges(true)
  }, [Object.keys(dirtyFields).length])

  // const updateHeaderAndAlert = ({ message, severity = "error" }, loading = false) => {
  //   headerRef.current.button.setLoading(loading)
  //   updateAlert(message, severity)
  // }

  /**
   * Hace la petición para editar los datos del Academia
   */
  const editActividad = (values) => {
    headerRef.current.setLoading(true)

    new Requests().editActividad(id_actividad, values, () => {
      // updateHeaderAndAlert({ message: "Se ha editado la actividad correctamente.", severity: "success" })
      updateAlert('Se ha editado correctamente. Redirigiendo a la visualización...', 'success')
      reset({}, { keepValues: true })
      updateChanges(false)
      onSaveFinish()
      updateId(id_actividad)
    }, (err) => {
      handleError(err)
      // updateHeaderAndAlert({ message: res })
    })
  }

  const updateId = async (id) => {
    // navigate(`../academias/actividades/${id}`);
    await Statics.delay(1500).then(() => { navigate(`../academias/actividades/${id}`) }) //Modificada la URL, para ir al single
    setIdActividad(id);
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(editActividad, (err) => { console.log(err) })}>

        <Stack direction="row" justifyContent={"space-between"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/", label: "Inicio" },
            { to: "./../../../", label: "Academia" },
            { to: "./../../", label: "Actividades" },
            { to: "./../", label: "Actividad #" + id_actividad },
            { to: "./", label: "Editar", actualItem: true },
          ]} />

          <MoveButtons
            actualItemId={id_actividad}
            beforeFetchCallback={() => setActividad(null)}
            onUpdateActualItem={updateId} />
        </Stack>

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          {/*
            COMPONENTE ANTIGUO
            <EditHeader
            ref={headerRef}
            title={"Editar Actividad"}
            item={actividad}
            btnText={"Guardar cambios"} 
          /> */}
          <Headers
            template={'edit'}
            title={"Editar Actividad"}
            actividad={actividad?.nombre}
            headerRef={headerRef}
          />
          <Line />

          {actividad ?
            <DataFormActividadAcademia
              actividad={actividad}
              register={register}
              errors={errors}
              isMounted
              hideButton />
            :
            <SkeletonActividadesAcademia />
          }
        </Paper>
        <SnackbarProvider autoHideDuration={3000} />
      </form>
    </Container>
  )
}