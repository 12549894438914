import { Grid, Box } from '@mui/material'
import { Line } from 'components/data_display'
import { DatePicker, Input, Select } from 'components/forms/components'
import { CursoEscolarSelect, ItemsSelect } from 'components/forms/components/Select/custom'
import { H3, Text } from 'components/texts'
import moment from 'moment'

const cursoActual = moment().subtract(1, 'year').format('YYYY') + "-" + moment().format('YYYY')

//TODO: FALTA INFORMACION. SE QUEDA PENDIENTE HACER LA FUNCIONALIDAD DE ESTA PARTE HASTA QUE NO ME LO ACLARE SAMUEL
export default function DataFormLibroGestionCobros({ register, errors, setValue, setCursoEscolar, onChangeAcreedor }) {

    return (
        <Grid container>
            <Grid container px={5} py={3}>
                <Grid item xs={6} pr={2}>
                    <ItemsSelect
                        label={"Año"}
                        name={"curso_escolar_id"}
                        keyValue={"curso_escolar"}
                        register={register}
                        errors={errors}
                        initialOptionByLabel={cursoActual}
                        onChange={({ target }) => setCursoEscolar(target.value)}
                        setValue={setValue} />
                </Grid>

                <Grid item xs={6}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha"} label={"Fecha de adeudo"} setValue={setValue} />
                </Grid>
            </Grid>

            <Grid container>
                <Line />
            </Grid>

            <Grid container px={5}>

                <Grid container xs={6} py={3} pr={2} spacing={2}>

                    <Grid item xs={12}>
                        <H3>Datos del ordenante</H3>
                    </Grid>

                    <Grid item xs={12}>
                        <ItemsSelect
                            label={"Acreedor"}
                            name={"acreedor_id"}
                            keyValue={"acreedor"}
                            register={register}
                            errors={errors}
                            onChange={({ target }) => onChangeAcreedor(target?.value)}
                            setValue={setValue} />
                    </Grid>

                    <Grid item xs={12}>
                        <Input name="acreedor_nombre" register={register} className="w-100" disabled />
                    </Grid>

                    <Grid item xs={6}>
                        <Input name="acreedor_nif" register={register} className="w-100" disabled />
                    </Grid>

                    <Grid item xs={6}>
                        <Input name="acreedor_sufijo" register={register} className="w-100" disabled />
                    </Grid>

                    <Grid item xs={6}>
                        <Input name="acreedor_iban" register={register} className="w-100" disabled />
                    </Grid>

                    <Grid item xs={6}>
                        <Input name="acreedor_identificador" register={register} className="w-100" disabled />
                    </Grid>

                </Grid>

                <Grid item style={{ borderLeft: "1px solid #D9D9D9" }} />

                <Grid container xs={6} py={3} pl={2} spacing={2}>

                    <Grid item xs={12}>
                        <H3>Inscripciones actualizadas</H3>
                    </Grid>

                    <Grid item xs={12}>
                        <Text>2022-2023</Text>
                    </Grid>

                    <Grid item xs={6}>
                        <Text>Nº Domiciliaciones</Text>
                    </Grid>

                    <Grid item xs={6}>
                        <Text>Fichero</Text>
                    </Grid>

                </Grid>

            </Grid>

            <Grid container>
                <Line />
            </Grid>

            <Grid container px={5} spacing={2} py={3}>

                <Grid item xs={12}>
                    <H3>Remesa</H3>
                </Grid>

                <Grid item xs={12}>
                    <Input name={'descripcion'} register={register} className="w-100" placeholder="Introduzca la descripción de la remesa." />
                </Grid>

            </Grid>

        </Grid>
    )
}