import * as Yup from 'yup'

/**
 * Validación para el formulario de búsqueda de actividades.
 * 27/02/24 Se ha modificado RegexNombres para que permita también caracteres especiales
 */
const Regex = "^[A-Za-z0-9]+$"
// const RegexNombres = /^[A-Za-zÁ-ú]+(?:\s[A-Za-zÁ-ú]+)*$/
const RegexNombres = /^[A-Za-zá-úÁ-Ú0-9\s.,'¡¿?@#\$%\^&\*\(\)_\+=\{\}\[\]:;"`~<>\\\/|\-!]+$/

const validationSchema = Yup.object().shape({
    tipo_actividad:      Yup.string().matches(Regex, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
    actividad:           Yup.string().matches(Regex, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
    nombre:              Yup.string().matches(RegexNombres, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
    modalidad:           Yup.string().matches(Regex, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
    matricula:           Yup.string().matches(Regex, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
})

export default validationSchema