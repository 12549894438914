
import React from 'react'
import { Grid } from '@mui/material'
import { Input } from 'components/forms/components'
import { GrupoAcademiaPicker } from 'components/forms/components/custom'


/**
 * Formulario con los datos generales de la grupo-academia
 */
export default function DataFormGrupoAcademias({ inscripcion, setValue, register, errors, onGrupoChange, actividad, cursoEscolar }) {

    return (
        <Grid container p={3}>
            <GrupoAcademiaPicker
                register={register} cursoEscolar={cursoEscolar} actividad={actividad} setValue={setValue} onGrupoChange={onGrupoChange}
                defaultValue={inscripcion?.grupo_academia_id ?? null} name="grupo_academia_id"/>

            <Grid container mt={3} spacing={2}>

                <Grid item xs={6}>
                    <Input placeholder={"Comentarios del profesor"}
                        register={register} errors={errors} className="w-100"
                        name={"comentario_profesor_ap"} defaultValue={inscripcion?.comentario_profesor_ap ?? null} multiline minRows={6} maxRows={10} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input placeholder={"Comentarios generales"}
                        register={register} errors={errors} className="w-100" name={"comentarios_generales"}
                        defaultValue={inscripcion?.comentarios_generales ?? null} multiline minRows={6} maxRows={10} uppercase />
                </Grid>

            </Grid>
        </Grid>
    )
}