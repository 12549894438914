import { columns } from './columns.js'
import React from 'react'
import { H3 } from 'components/texts'
import { ListTableHeader } from 'pages/logged/admin/components'
import { Box, Container, Paper } from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'
import { Accordion, BreadcrumbsList, Datagrid } from 'components/data_display'
import SearchFormActividadAcademia, { search } from 'components/forms/display/academia/actividades/SearchForm'
import { useListActividadLogic } from 'hooks/index.js'

/**
 * Componente `ListadoActividadesAcademias` que muestra un listado de actividades de academia.
 * Utiliza el hook `useLogic` para manejar el estado y la lógica del listado.
 * Incluye una funcionalidad de búsqueda a través de un formulario y muestra los resultados en una tabla paginada.
 * Utiliza el componente `Datagrid` para mostrar los datos y gestionar la paginación de forma eficiente.
 *
 * @returns {JSX.Element} - Un contenedor que incluye el formulario de búsqueda y la tabla de listado de actividades.
 */
export default function ListadoActividadesAcademias() {
  const { items, rowCount, updateTable, setLoadingTable, params, loadingTable } = useListActividadLogic()

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <BreadcrumbsList breadcrumbsList={[
        { to: "/", label: "Inicio" },
        { to: "./", label: "Academia" }, //--> Te dirige al apartado Actividades
        { to: "./", label: "Actividades", actualItem: true },
      ]} />

      <Accordion header={<Box display={"flex"} alignItems={"center"}><SearchIcon className={"mx-5"} /> <H3 className="my-0">Buscar actividades</H3></Box>}>
        <SearchFormActividadAcademia
          searchCallback={updateTable}
          onLoadingCallback={setLoadingTable} />
      </Accordion>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
        <ListTableHeader title={"Listado de actividades de academias"} rowCount={rowCount} params={params} keyValue={"registro_actividad"} />

        <Datagrid
          rows={items}
          columns={columns}
          loading={loadingTable}
          rowCount={rowCount}
          onPageChange={(page) => { search(page, () => setLoadingTable(true), updateTable) }}
          serverSide />
      </Paper>
    </Container>
  )
}
