import Statics from "objects/common/Statics"

/**
 * Edita un elemento en un array, estableciendo el nuevo valor en el campo especificado.
 * Marca el elemento como editado, a menos que sea un elemento nuevo.
 *
 * @param {object} itemToEdit - El elemento que se va a editar.
 * @param {any} value - El nuevo valor que se establecerá en el campo.
 * @param {string} field - El nombre del campo que se va a editar.
 * @param {array} array - El array en el que se realiza la edición.
 * @returns {array} - El array actualizado después de la edición.
 */
export const edit = (itemToEdit, value, field, array) => {
    const index = array.findIndex(item => item == itemToEdit)

    if (index != -1) {
        array[index][field] = value
        //Solo se establece edit si no es un elemento nuevo
        if (!array[index].new)
            array[index].edit = true
    }

    return array
}

/**
 * Agrega un nuevo elemento al array si se cumple una condición.
 * El nuevo elemento se inicializa con valores predeterminados y se marca como nuevo.
 *
 * @param {array} arrayRendered - El array que se verifica para la condición.
 * @param {array} array - El array al que se agrega el nuevo elemento.
 * @param {any} id_curso_escolar - El valor para el campo 'id_curso_escolar' del nuevo elemento.
 * @param {object} defaultItem - El objeto que contiene los valores predeterminados para el nuevo elemento.
 * @returns {array|null} - El array actualizado después de agregar el nuevo elemento o null si no se cumple la condición.
 */
export const add = (arrayRendered, array, id_curso_escolar, defaultItem) => {
    console.log('array', array);
    const lastLibro = arrayRendered.at(-1)

    if (arrayRendered.length == 0 || Statics.checkIfOneElementIsNotEmpty(lastLibro, ['id_curso_escolar', 'id', 'new', 'edit', 'fecha'])) {
        const newElement = { ...defaultItem, id_curso_escolar, new: true }
        if (!Array.isArray(array))
            return [newElement]
        return [...array, newElement]
    }

    return null
}

/**
 * Marca un elemento en el array como eliminado, estableciendo la propiedad 'deleted' en true.
 *
 * @param {object} itemToDelete - El elemento que se va a marcar como eliminado.
 * @param {array} array - El array en el que se realiza la eliminación.
 * @returns {array} - El array actualizado después de marcar el elemento como eliminado.
 */
export const erase = (itemToDelete, array) => {
    const index = array.findIndex(item => item == itemToDelete)

    if (index != -1)
        array[index].deleted = true

    return array
}