import { useEffect, useRef, useState } from "react"
import { Button } from 'components/forms/components'
import Skeleton from 'components/skeletons/alumnos/alumno/responsableDataSkeleton'
import { Alert, Avatar, Grid, Paper, Tooltip, Box } from '@mui/material'
import { Add as AddIcon, OpenInNewOff as RemoveAsociationIcon } from '@mui/icons-material'
import { H3, Text } from 'components/texts'
import AddResponsableModalCreate from "components/modals/custom/responsable/AddResponsableCreate"
import { ASSETS } from "constants/Assets"

function useLogic(responsables, setResponsables) {
  const [open, setOpen] = useState(false)

  const addResponsableModalCreateRef = useRef()

  useEffect(() => {
    setResponsables(responsables)
  }, [responsables])

  return { addResponsableModalCreateRef, open, setOpen }
}

export default function ResponsableData({ register, getValues, errors, responsables, setResponsables }) {
  const { addResponsableModalCreateRef, open, setOpen } = useLogic(responsables, setResponsables)

  return (
    <Grid container>
      <Grid item xs={6} display={"flex"} alignItems={"center"}>
        <H3 className={"m-0"}>Tutores:</H3>
      </Grid>

      <Grid item xs={6} display={"flex"} justifyContent={"end"} alignItems={"center"}>
        {responsables ? (
          <Button variant={"outlined"} startIcon={<AddIcon />} onClick={() => { setOpen(true) }}>
            Crear tutor
          </Button>) : null}
      </Grid>

      {!responsables ? <Skeleton /> :
        <Grid container mt={2} spacing={2}>
          {responsables.map(item => (
            <ResponsableItem
              key={Math.random()}
              responsable={item}
              setResponsables={setResponsables} />))} {/*openDeleteRelacionModal={(responsable) => { this.deleteRelacionModal.open(responsable, this.props.alumno) }} */}

          {responsables.length == 0 ?
            <Grid item xs={12}>
              <Alert severity="info" className='mt-10 w-100'>
                <H3 className="standard">Este alumno no tiene ningún tutor.</H3>
              </Alert>
              <Alert severity="warning" className='mt-10 w-100'>
                <H3 className="standard">Para los alumnos menores de edad, será necesario asignarles obligatoriamente un tutor.</H3>
              </Alert>
            </Grid> : null}
        </Grid>
      }

      <AddResponsableModalCreate
        register={register}
        getValues={getValues}
        errors={errors}
        setResponsables={setResponsables}
        addResponsableModalCreateRef={addResponsableModalCreateRef}
        open={open}
        setOpen={setOpen} /> {/* alumno={this.props.alumno} onCreateCallback={this.props.reload} */}

      {/* <DeleteRelacionModal ref={(ref) => this.deleteRelacionModal = ref} onDeleteCallback={this.props.reload} /> */}
    </Grid>
  )

}

function ResponsableItem({ responsable, setResponsables }) {

  //Si es un responsable que se acaba de crear, lo elimino del estado
  const handleDelete = () =>
    setResponsables(currentResponsables => currentResponsables.filter(responsableItem => responsableItem !== responsable))

  return (
    <Grid item xs={6} xl={4}>
      <Paper elevation={0} sx={{ px: 3, pt: 2, pb: 3, borderRadius: 2, border: "1px solid var(--greyLight)" }}>
        <Box justifyContent={"end"} alignItems={"center"} display={"flex"}>
          <Tooltip title={<Text color={"black"}>Borrar responsable</Text>}>
            <div onClick={handleDelete}>
              <RemoveAsociationIcon color={"error"} className={"clickable mx-2"} />
            </div>
          </Tooltip>
        </Box>

        <Box alignItems={"center"} display={"flex"}>
          <Avatar alt={responsable.nombre ?? ""} src={`${ASSETS.images + (responsable.foto_logo ? responsable.foto_logo : 'private/responsable/perfiles/0/default.png')}`} sx={{ width: 75, height: 75 }} />

          <Box mx={3} display={"flex"} flexDirection={"column"}>
            <Text>{(responsable.nombre ?? "") + " " + (responsable.apellido1 ?? "") + " " + (responsable.apellido2 ?? "")}</Text>
            <Text>{responsable.email ?? ""}</Text>
            <Text>{(responsable.numero_documento ?? "")}</Text>
            <Text>{(responsable.tipo_relacion_id ?? "")}</Text>
          </Box>
        </Box>

        {responsable.creating ?
          <Alert variant="outlined" severity="info" className='mt-2 w-100'>
            <Text>Este tutor aún no está confirmado. Al guardar cambios, los detalles del tutor se registrarán de forma segura.</Text>
          </Alert> : null}
      </Paper>
    </Grid>
  )
}