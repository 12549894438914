import { Text } from "components/texts"
import { enqueueSnackbar } from "notistack"
import { RequestDevolucionLibro } from "objects/requests"
import { useState } from "react"

/**
 * Hook personalizado para la lógica relacionada con las devoluciones de libros.
 * Este hook proporciona funciones y estados relacionados con la generación de devoluciones de libros.
 *
 * @returns {Object} - Un objeto que contiene 'isLoading' (indicador de carga) y 'generateDevoluciones' (función para generar devoluciones).
 */
export default function useLogic() {
    const [isLoading, setIsLoading] = useState(false)

    const generateDevoluciones = () => {
        enqueueSnackbar(<Text>{`Realizando devoluciones...`}</Text>, { variant: "info" })
        setIsLoading(true)

        new RequestDevolucionLibro().generate((res) => {
            enqueueSnackbar(<Text>{`La devolución se ha realizado correctamente. Devoluciones realizadas: ${res.length}.`}</Text>, { variant: "success" })
            setIsLoading(false)
        }, (err) => {
            setIsLoading(false)
        })
    }

    return { isLoading, generateDevoluciones }
}