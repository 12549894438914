import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { useAlert, useCheckDirtyFields, useErrorHandler } from "hooks"
import { yupResolver } from "@hookform/resolvers/yup"
import { ChangesContext } from "contexts/ChangesContext"
import { useContext, useEffect, useRef, useState } from "react"
import { validationSchema } from 'components/forms/display/academia/inscripciones/DataForm/GeneralDataForm/validation.js'
import Statics from "objects/common/Statics"

/**
 * Hook personalizado para manejar la lógica de edición de inscripciones en la academia.
 * Incluye la inicialización y manejo de formularios, navegación, y operaciones asíncronas como
 * la edición de inscripciones y la gestión de pagos y libros asociados.
 * 
 * @param {Object} params - Objeto que incluye propiedades y funciones para configurar y manejar el hook.
 * @param {Function} params.setIdInscripcion - Función para actualizar el ID de inscripción en el componente padre.
 * @param {Object} params.inscripcion - Objeto que contiene los datos actuales de la inscripción.
 * @param {number} params.id_inscripcion - ID de la inscripción actual.
 * @param {Function} params.onSaveFinish - Callback que se ejecuta tras una operación de guardado exitosa.
 * @param {Object} params.requests - Objeto que contiene funciones para realizar operaciones de CRUD en la API.
 * @returns {Object} Objeto que expone estados y funciones del hook para su uso en el componente.
 */
export default function useLogic({ setIdInscripcion, inscripcion, id_inscripcion, onSaveFinish, requests }) {
    const operador = inscripcion?.operador?.nombre

    const updateAlert = useAlert()

    const handleError = useErrorHandler()

    const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, setValue, control, clearErrors } = useForm({ resolver: yupResolver(validationSchema) })

    const navigate = useNavigate()

    const [isMounted, setIsMounted] = useState(false)
    const [tab, setTab] = useState("1")
    const [actividad, setActividad] = useState(inscripcion?.registro_actividad_id ?? null)
    const [cursoEscolar, setCursoEscolar] = useState(inscripcion?.curso_escolar_id ?? null)
    const [grupo, setGrupo] = useState(null)

    const { updateChanges } = useContext(ChangesContext)

    const headerRef = useRef()

    /**
     * Efecto que marca el hook como montado. Esto se utiliza para controlar la renderización condicional
     * o efectos que sólo deben ejecutarse una vez que el componente ha sido completamente montado.
     */
    useEffect(() => {
        setIsMounted(true)
    }, [])

    useCheckDirtyFields(dirtyFields, updateChanges)

    /**
     * Realiza la petición para actualizar los datos de una inscripción. Se encarga de enviar los datos
     * del formulario y gestionar la creación de pagos y la actualización de información de libros
     * mediante operaciones asíncronas. También gestiona la visualización de alertas según el resultado
     * de estas operaciones.
     * 
     * @param {Array} pagos - Array de objetos que contienen información sobre los pagos asociados a la inscripción.
     */
    const editInscripcionAcademia = (pagos) => {
        headerRef.current.setLoading(true)

        requests.editInscripcion(id_inscripcion, getValues, (res) => {

            if (res)
                updateAlert('Se ha guardado la inscripción correctamente. Redirigiendo...', 'success')

            const promisePagos = new Promise((resolve, reject) => {
                requests.createPagos(res.id, pagos, resolve, reject);
            })

            const promiseLibro = new Promise((resolve, reject) => {
                requests.crudLibro(res, getValues, resolve, reject)
            })

            const promiseLibroAsociado = new Promise((resolve, reject) => {
                requests.crudLibroAsociado(res, getValues, resolve, reject)
            })

            Promise.allSettled([promisePagos, promiseLibro, promiseLibroAsociado]).then((values) => {
                for (const fail of values.filter(item => item?.status == "rejected")) {
                    updateAlert(fail.reason)
                }
            })

            onSaveFinish()
            updateId(res.id) //Añadida la función a ejecución
            updateChanges(false)

        }, (err) => {
            handleError(err)
            headerRef.current.setLoading(false)
        })
    }

    /**
     * Actualiza el ID de la inscripción en el estado y realiza una navegación a la ruta de edición
     * correspondiente al nuevo ID. Esto permite actualizar la URL y el estado del componente padre
     * con el nuevo ID de inscripción seleccionado.
     * 
     * @param {number} id - El nuevo ID de la inscripción para actualizar y navegar.
     */
    const updateId = async (id) => {
        // navigate(`../academias/inscripciones/${id}`)
        await Statics.delay(1500).then(() => { navigate(`../academias/inscripciones/${id}`) }) //Modificado el medoto de redirreción.
        setIdInscripcion(id)
    }

    /**
     * Función personalizada para manejar la lógica de envío del formulario. Encapsula la llamada a `handleSubmit`
     * de `react-hook-form` y ejecuta `editInscripcionAcademia` con los valores del formulario. Esta abstracción permite
     * realizar operaciones adicionales antes o después del envío del formulario.
     * 
     * @param {Event} e - El evento de envío del formulario.
     */
    const handleSubmitCustom = async (e) => {
        let handleCallbacks = handleSubmit(() => editInscripcionAcademia(getValues("pagos")))
        handleCallbacks(e)
    }

    const incidenciasNoResueltas = inscripcion?.alumno?.incidencias.filter(incidencia => incidencia.solucionada === 0)

    return { navigate, operador, grupo, setGrupo, headerRef, onSaveFinish, control, tab, setTab, cursoEscolar, setCursoEscolar, actividad, setActividad, getValues, handleSubmitCustom, updateId, setValue, register, errors, isMounted, incidenciasNoResueltas }
}