import * as Yup from 'yup'

const Regex = "^[A-Za-z0-9 ñÑ\-]+$"
const RegexNombres = /^[A-Za-zÁ-ú]+(?:\s[A-Za-zÁ-ú]+)*$/
const RegexCorreo = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const validationSchema = Yup.object().shape({
    email:            Yup.string().matches(RegexCorreo, {message: 'Formato inválido para una dirección de e-mail', excludeEmptyString: true}),
    apellido1:        Yup.string().matches(RegexNombres, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
    apellido2:        Yup.string().matches(RegexNombres, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
    nombre:           Yup.string().matches(RegexNombres, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
    numero_documento: Yup.string().matches(Regex, {message: 'No puede contener caracteres especiales', excludeEmptyString: true}),
})

export default validationSchema