/**
 * Recogemos el objeto values que tiene todos los datos de la domicialición del alumno. 
 * 
 * Una vez hecho esto, construimos el número (IBAN), ya que en el documento se especifica que tienen que ser IBAN españoles
 * por lo tanto, le añadimos el prefijo ES.
 * 
 *  -----------VALIDACIONES-----------
 *  ·Nombre del deudor no puede estar vacío.
 *  ·Fecha de mandato no puede estar vacía.
 *  ·La longitud del IBAN no puede ser distinta de 24.
 *  ·Comprobamos que el IBAN siga el formato ISO.
 *  ·Comprobamos que el prefijo del país sea ES.
 *  ·Comprobamos los dígitos de control con el algoritmo para saber si el IBAN es válido.
 * 
 *   -----------RETURN-----------
 *  ·Devolvemos un array de índice 1, este tiene dos posiciones:
 *      POSICION 0: valor booleano que indica true si es correcto, false si no pasa alguna validación.
 *      POSICION 1: mensaje de error, para mostrar en el front con userAlert porque ese IBAN no es válido.
 * @param {Object} ibanValues Objeto que contiene los datos necesarios para la validación del IBAN.
 * @returns {Array} Array con dos elementos: el primero indica si la validación fue exitosa, el segundo contiene el mensaje de error.
 */
function validateIbanAcount(ibanValues) {
    const result = {
        state: true,
        response: 'El IBAN es correcto'
    }

    if (!isAnyBankFieldFilled(ibanValues)) {
        return result
    }

    // Construir el IBAN
    const iban = `ES${ibanValues.digitos_control}${ibanValues.iban_2}${ibanValues.iban_3}${ibanValues.iban_4}${ibanValues.iban_5}${ibanValues.iban_6}`;

    // Verificar que los campos IBAN sean numéricos
    validateNumericValues(result, ibanValues)

    // Verificar longitukd del IBAN
    validateIbanLength(result, iban)

    // Verificar si el IBAN cumple con el formato esperado
    validateRegex(result, iban)

    // Verificar el código de país del IBAN
    validateCountryCode(result, iban)

    // Validar el IBAN utilizando el algoritmo de verificación
    validateControlDigits(result, iban)

    return result;
}

/**
 * Devuelve TRUE cuando alguno de los campos de la domicialización del alumno esté relleno.
 * 
 * @param {*} param0 
 */
function isAnyBankFieldFilled({ digitos_control, iban_2, iban_3, iban_4, iban_5, iban_6, nombre_deudor, fecha_mandato, primer_recibo_emitido }) {
    return (primer_recibo_emitido || [digitos_control, iban_2, iban_3, iban_4, iban_5, iban_6, nombre_deudor, fecha_mandato].some(value => value))
}

const validateNumericValues = (result, ibanValues) => {
    if (result.state) {
        // Verificar que los campos IBAN sean numéricos
        if (
            isNaN(ibanValues.digitos_control) ||
            isNaN(ibanValues.iban_2) ||
            isNaN(ibanValues.iban_3) ||
            isNaN(ibanValues.iban_4) ||
            isNaN(ibanValues.iban_5) ||
            isNaN(ibanValues.iban_6)
        ) {
            result.state = false;
            result.response = 'Todos los campos del IBAN deben ser numéricos.';
        }
        return result
    }
}

const validateIbanLength = (result, iban) => {
    if (result.state) {
        // Verificar longitud del IBAN
        if (iban.length !== 24) {
            result.state = false;
            result.response = 'La longitud del IBAN no es válida.';
        }
        return result
    }
}

const validateRegex = (result, iban) => {
    if (result.state) {
        // Expresión regular para validar el formato del IBAN
        const ibanRegex = /^[A-Z]{2}\d{22}$/;

        // Verificar si el IBAN cumple con el formato esperado
        if (!ibanRegex.test(iban)) {
            result.state = false;
            result.response = 'El formato del IBAN no es válido.';
        }
        return result
    }
}

const validateCountryCode = (result, iban) => {
    if (result.state) {
        // Verificar el código de país del IBAN
        const countryCode = iban.slice(0, 2);
        if (countryCode !== 'ES') {
            result.state = false;
            result.response = 'El código del país del IBAN no es válido.';
        }
        return result
    }
}

const validateControlDigits = (result, iban) => {
    if (result.state) {
        // Validar el IBAN utilizando el algoritmo de verificación
        /*global BigInt*/
        const ibanDigits = (iban.substring(4) + iban.substring(0, 4)).replace(/[A-Z]/g, char => char.charCodeAt(0) - 55);
        const ibanBigInt = BigInt(ibanDigits);
        const remainder = ibanBigInt % 97n;

        if (remainder !== 1n) {
            result.state = false;
            result.response = 'El dígito de control no se corresponde con este IBAN.';
        }
        return result
    }
}

export { validateIbanAcount }