import { H3 } from 'components/texts'
import { useNavigate } from 'react-router-dom'
import { Grid, TextField } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import { Input, Checkbox, DatePicker, Button } from 'components/forms/components'

const isObjectEmpty = (obj) => {
    return (Object.entries(obj).length == 0)
}

/**
 * Formulario con los datos del libro de la inscripcion a un Academia
 */
export default function LibroInscripcionAcademiaDataForm({ setValue, errors, inscripcion, register, grupo }) {
    const prevGrupoRef = useRef()

    const [solicitado, setSolicitado] = useState(false)
    const [solicitado_asociado, setSolicitado_asociado] = useState(false)
    const [ventaLibro, setVentaLibro] = useState(inscripcion?.venta_libro ?? {})
    const [ventaLibroAsociado, setVentaLibroAsociado] = useState(inscripcion?.venta_libro_asociado ?? {})

    const goToLibro = (libro_id) => {
        window.open(`/empleado/libros/${libro_id}`, '_blank', 'noopener,noreferrer')
    }

    useEffect(() => {
        if (grupo && grupo?.id !== prevGrupoRef.current?.id) {
            updateVentas(grupo)
        } else if (!grupo) {
            resetLibros()
        }
        prevGrupoRef.current = grupo
    }, [grupo])


    const resetLibros = () => {
        setVentaLibro({})
        setVentaLibroAsociado({})

        setValue("libro_id", null)
        setValue("libro_asociado_id", null)
    }

    /**
     * Actualiza el libro del grupo
     * 
     * @param {Object} grupo: {libro: Object}
     */
    const updateVentas = (grupo) => {
        ventaLibro.libro = grupo?.libro ?? null
        ventaLibroAsociado.libro = grupo?.libro?.libro_asociado ?? null

        setVentaLibro(ventaLibro)
        setVentaLibroAsociado(ventaLibroAsociado)

        setValue("libro_id", grupo?.libro?.id ?? null)
        setValue("libro_asociado_id", grupo?.libro?.libro_asociado?.id ?? null)
    }

    return (
        <Grid container p={3}>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <H3>Libro del grupo: </H3>
                </Grid>

                <Grid item xs={8}>
                    <Input key={Math.random()} label={"Libro"} className="w-100" defaultValue={ventaLibro?.libro?.nombre ?? '...'} uppercase disabled />
                    <TextField type={"hidden"} defaultValue={ventaLibro?.libro?.id ?? ''} variant={"standard"} {...register ? register("libro_id") : {}} />
                </Grid>

                <Grid item xs={2}>
                    <Checkbox name={"libro_solicitado"} label={"Libro solicitado"} register={register} errors={errors} disabled={isObjectEmpty(ventaLibro) || !ventaLibro?.libro}
                        defaultChecked={grupo?.libro?.usar_libro_asociado ? true : false}
                        onClick={(e) => {
                            setSolicitado(e)
                            e == false ? setValue("precio_solicitado", "") : console.log()
                        }} />
                </Grid>

                <Grid item xs={2}>
                    <DatePicker disabled={!solicitado} register={register} errors={errors} className="w-100" name={"fecha_peticion"}
                        label={"Fecha solicitado"} defaultValue={grupo?.libro?.fecha_peticion} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={2}>
                    <Input key={Math.random()} register={register} disabled={!solicitado} errors={errors} label={"Precio solicitado"} name={"precio"}
                        className="w-100" defaultValue={grupo?.pvp ?? ""} type={"number"} />
                    <TextField type={"hidden"} value={grupo?.libro?.libro_id ?? ''} variant={"standard"} {...register ? register("libro_id") : {}} />


                </Grid>

                <Grid item xs={3}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_pago"} disabled={!solicitado}
                        label={"Fecha de pago"} defaultValue={inscripcion?.fecha_pago ?? ""} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={3}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_entrega"} disabled={!solicitado}
                        label={"Fecha de entrega"} defaultValue={inscripcion?.fecha_entrega ?? ""} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={2}>
                    <Input
                        key={Math.random()}
                        register={register}
                        errors={errors}
                        label={"Unidades en el almacén"}
                        name={"unidades_libro"}
                        className="w-100"
                        defaultValue={ventaLibro?.libro?.unidades_disponibles ?? ""}
                        disabled />
                </Grid>
                <Grid item xs={2}>
                    <Button disabled={isObjectEmpty(ventaLibro) || !ventaLibro?.libro} onClick={() => goToLibro(ventaLibro.libro?.id ?? null)}>
                        Detalles
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={5}>
                <Grid item xs={12}>
                    <H3>Libro asociado: </H3>
                </Grid>

                <Grid item xs={8}>
                    <Input key={Math.random()} label={"Libro asociado"} className="w-100" defaultValue={ventaLibroAsociado?.libro?.nombre ?? '...'} uppercase disabled />
                    <TextField type={"hidden"} defaultValue={ventaLibro?.libro?.id ?? ''} variant={"standard"} {...register ? register("libro_asociado_id") : {}} />
                </Grid>

                <Grid item xs={2}>
                    <Checkbox onClick={(e) => {
                        setSolicitado_asociado(e)
                        e == false ? setValue("precio_asociado", "") : console.log()
                    }} name={"libro_asociado_solicitado"} label={"Libro solicitado"} register={register} errors={errors} disabled={isObjectEmpty(ventaLibroAsociado) || !ventaLibroAsociado?.libro}
                        defaultChecked={grupo?.libro?.libro_asociado} />
                </Grid>

                <Grid item xs={2}>
                    <DatePicker register={register} errors={errors} disabled={!solicitado_asociado} className="w-100" name={"fecha_asociado_solicitud"} label={"Fecha solicitado"}
                        defaultValue={grupo?.libro?.fecha_asociado_solicitud} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={2}>
                    <Input key={Math.random()} register={register} disabled={!solicitado_asociado} errors={errors} label={"Precio asociado"} name={"precio_asociado"}
                        className="w-100" defaultValue={grupo?.libro?.libro_asociado?.pvp ?? ""} type={"number"} />
                    <TextField type={"hidden"} value={grupo?.libro?.id ?? ''} variant={"standard"} {...register ? register("libro_id") : {}} />
                </Grid>

                <Grid item xs={3}>
                    <DatePicker disabled={!solicitado_asociado} register={register} errors={errors} className="w-100"
                        name={"fecha_pago_asociado"} label={"Fecha de pago"} defaultValue={inscripcion?.fecha_pago ?? ""} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={3}>
                    <DatePicker disabled={!solicitado_asociado} register={register} errors={errors} className="w-100"
                        name={"fecha_entrega_asociado"} label={"Fecha de entrega"} defaultValue={inscripcion?.libro?.fecha_entrega_asociado ?? ""} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={2}>
                    <Input
                        key={Math.random()}
                        register={register}
                        errors={errors}
                        label={"Unidades en el almacén"}
                        name={"unidades_libro_asociado"}
                        className="w-100"
                        defaultValue={ventaLibroAsociado?.libro?.unidades_disponibles ?? ""}
                        disabled />
                </Grid>
                <Grid item xs={2}>
                    <Button disabled={isObjectEmpty(ventaLibroAsociado) || !ventaLibroAsociado?.libro} onClick={() => goToLibro(ventaLibroAsociado.libro?.id ?? null)}>
                        Detalles
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

