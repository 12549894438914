import React, { Component } from 'react'
import { Grid, Skeleton } from '@mui/material'

/**
 * Informacion básica del alumno
 */
export default class SkeletonAlumno extends Component {

  //-------------------------------------------------------
  //-------------------- CLASS FUNCTIONS ------------------
  //-------------------------------------------------------

  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {

    return (
        <Grid container spacing={2} mt={2}>
            <Grid item xs={12}>
                <Skeleton variant="text" sx={{ fontSize: '2em' }} width={"20%"}/>
            </Grid>

            <Grid container item>
                <Grid item xs={3}>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"30%"}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"30%"}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"30%"}/>
                </Grid>

                <Grid item xs={3}>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"}/>
                </Grid>

                <Grid item xs={3}>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"}/>
                </Grid>

                <Grid item xs={3}>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"}/>
                    <Skeleton variant="text" sx={{ fontSize: '1em' }} width={"90%"}/>
                </Grid>
            </Grid>
         </Grid>
    )
  }
}
