import { Grid } from '@mui/material'
import React, { Component } from 'react'
import Skeleton from '@mui/material/Skeleton'

/**
 * SKELETON
 */
export default class  SkeletonColegioData extends Component {

  //-------------------------------------------------------
  //-------------------- CLASS FUNCTIONS ------------------
  //-------------------------------------------------------


  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Skeleton variant="rounded" width={"40%"} height={30} className={"my-2"}/>
                <Skeleton variant="rounded" width={"50%"} height={30} className={"my-2"}/>
                <Skeleton variant="rounded" width={"60%"} height={30} className={"my-2"}/>
            </Grid>

            <Grid item xs={4}>
                <Skeleton variant="rounded" width={"80%"} height={40} className={"my-2"}/>
                <Skeleton variant="rounded" width={"80%"} height={20} className={"my-2"}/>
            </Grid>

            <Grid item xs={2} display={"flex"} justifyContent={"end"}>
                <Skeleton variant="rounded" width={"80%"} height={40} className={"my-2"}/>
            </Grid>

            <Grid item xs={12} mt={3}>
                <Skeleton variant="rounded" width={"50%"} height={30} className={"my-2"}/>
            </Grid>

            <Grid item xs={3}>
                <Skeleton variant="rounded" width={"100%"} height={20} className={"my-2"}/>
                <Skeleton variant="rounded" width={"100%"} height={20} className={"my-2"}/>
                <Skeleton variant="rounded" width={"100%"} height={20} className={"my-2"}/>
                <Skeleton variant="rounded" width={"100%"} height={20} className={"my-2"}/>
            </Grid>

            <Grid item xs={3}>
                <Skeleton variant="rounded" width={"100%"} height={20} className={"my-2"}/>
                <Skeleton variant="rounded" width={"100%"} height={20} className={"my-2"}/>
                <Skeleton variant="rounded" width={"100%"} height={20} className={"mt-4 mb-2"}/>
                <Skeleton variant="rounded" width={"100%"} height={20} className={"my-2"}/>
            </Grid>

            <Grid item xs={3}>
                <Skeleton variant="rounded" width={"100%"} height={20} className={"my-2"}/>
                <Skeleton variant="rounded" width={"100%"} height={20} className={"my-2"}/>
                <Skeleton variant="rounded" width={"100%"} height={20} className={"mt-4 mb-2"}/>
                <Skeleton variant="rounded" width={"100%"} height={20} className={"my-2"}/>
            </Grid>

            <Grid item xs={3}>
                <Skeleton variant="rounded" width={"100%"} height={20} className={"my-2"}/>
                <Skeleton variant="rounded" width={"100%"} height={20} className={"my-2"}/>
                <Skeleton variant="rounded" width={"100%"} height={20} className={"mt-4 mb-2"}/>
                <Skeleton variant="rounded" width={"100%"} height={20} className={"my-2"}/>
            </Grid>

            <Grid item xs={12} mt={3}/>

            <Grid item xs={3}>
                <Skeleton variant="rounded" width={"80%"} height={20} className={"my-2"}/>
                <Skeleton variant="rounded" width={"80%"} height={20} className={"my-2"}/>
            </Grid>

            <Grid item xs={3}>
                <Skeleton variant="rounded" width={"30%"} height={20} className={"my-2"}/>
                <Skeleton variant="rounded" width={"80%"} height={20} className={"my-2"}/>
            </Grid>

            <Grid item xs={6}>
                <Skeleton variant="rounded" width={"30%"} height={20} className={"my-2"}/>
                <Skeleton variant="rounded" width={"80%"} height={20} className={"my-2"}/>
            </Grid>
        </Grid>
    )
  }
}
