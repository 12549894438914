import { Text } from 'components/texts'
import React, { Component } from 'react'
import { Grid, TextField } from '@mui/material'
import { Input } from 'components/forms/components'
import { CursoEscolarSelect, ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Esta es la plantilla para cuando se busque por los datos basicos del alumno en una inscripcion
 */
export default class InscripcionAlumnosTemplate extends Component {
    
    /**
     * Constructor
     * 
     * @param {Object} props 
     */
    constructor(props) {
        super(props)
    }

    /**
     * Render
     *
     * @returns {Component}
     */
    render() {
        const { register, errors, setValue } = this.props
        return (
            <>
                <Grid container mt={2} mb={1}>
                    <Text fontWeight={'bold'}>Según datos de:</Text><br />
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <ItemsSelect name={"id_tipo_actividad"} keyValue={"tipo_actividad"} register={register} errors={errors} wantToReset={this.props.reset}/>
                    </Grid>

                    <Grid item xs={4}>
                        <ItemsSelect name={"id_actividad"} keyValue={"actividad"} register={register} errors={errors} wantToReset={this.props.reset} searcheable />
                    </Grid>

                    <Grid item xs={4}>
                        <ItemsSelect name={"id_modalidad_cursos"} keyValue={"modalidad_cursos"} register={register} errors={errors} wantToReset={this.props.reset} searcheable />
                    </Grid>

                    <Grid item xs={6}>
                        <Input label="Colegio" width={"100%"} name={"colegio"} register={register} errors={errors} uppercase/>
                    </Grid>

                    <Grid item xs={6}>
                        <CursoEscolarSelect name={"id_curso_escolar"} register={register} errors={errors} wantToReset={this.props.reset} defaultValue={null} setValue={setValue} searcheable />
                    </Grid>

                    <TextField type={"hidden"} value={0} variant={"standard"} {...this.props.register ? this.props.register("id_grupo") : {}} />
            </Grid>
            </>
        )
    }
}
