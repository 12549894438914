import React, { useRef } from 'react'
import { H3 } from 'components/texts';
import { FORMATS } from './constants';
import { Grid, Stack } from '@mui/material';
import { Line } from 'components/data_display';
import { Button, Input } from 'components/forms/components';
import { RequestFile } from 'objects/requests';

/**
 * Formulario con los datos de las programaciones de un libro
 */
export default function DataFormLibroProgramaciones({ libro, register, getValues }) {
    const accept = ".txt,.pdf,.docx,.doc"

    const inputT1 = useRef(null)
    const inputFileT1 = useRef(null)
    const inputT2 = useRef(null)
    const inputFileT2 = useRef(null)
    const inputT3 = useRef(null)
    const inputFileT3 = useRef(null)

    /**
     * Actualiza el input de texto para ver el nombre del archivo seleccionado
     * 
     * @param {String} value 
     * @param {Ref} ref 
     */
    const updateInput = (value, ref) => {
        const array = value.split("\\")
        const newValue = array[array.length - 1]

        ref.setValue(newValue)
    }


    /**
     * Abre el archivo seleccionado en una pestaña nueva
     * 
     * @param {String} value 
     * @param {Ref} ref 
     */
    const showFile = (field) => {
        const file = getValues(field)[0]
        console.log(file)
        if (file) {
            const tmppath = URL.createObjectURL(file)
            window.open(tmppath, '_blank')
        } else if (libro[field]) {
            const array = libro[field].split('.')
            const type = array[array.length - 1]
            new RequestFile().file(libro[field], FORMATS["." + type], {}, (res) => {
                console.error("response", res)
            })
        }
    }


    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container px={5} pb={5}>
            <Grid container spacing={2} my={2}>
                <Grid item xs={12} xl={8}>
                    <Stack>
                        <H3>1º Trimestre</H3>
                        <Input ref={inputT1} className="w-100" defaultValue={libro?.programacion_1t ?? "..."} disabled useValue />
                        <Input ref={inputFileT1} className="w-100" type={"file"} register={register} name={"programacion_1t"} style={{ display: "none" }} inputProps={{ onChange: ({ target }) => updateInput(target.value, inputT1.current), accept }} />
                    </Stack>
                </Grid>

                <Grid item xs={12} xl={4} display={"flex"} alignItems={"end"}>
                    <Stack direction={"row"} spacing={2} className={"w-100"}>
                        <Button className={"w-100 py-3"} onClick={() => { inputFileT1.current.input.click() }}>
                            Actualizar programación
                        </Button>
                        <Button className={"w-100 py-3"} color={"cancel"} onClick={() => showFile("programacion_1t")}>
                            Ver programación
                        </Button>
                    </Stack>
                </Grid>

            </Grid>

            <Grid item xs={12} mt={2}>
                <Line />
            </Grid>

            <Grid container spacing={2} my={2}>
                <Grid item xs={12} xl={8}>
                    <Stack>
                        <H3>2º Trimestre</H3>
                        <Input ref={inputT2} className="w-100" defaultValue={libro?.programacion_2t ?? "..."} disabled useValue />
                        <Input ref={inputFileT2} className="w-100" type={"file"} register={register} name={"programacion_2t"} style={{ display: "none" }} inputProps={{ onChange: ({ target }) => updateInput(target.value, inputT2.current), accept }} />
                    </Stack>
                </Grid>

                <Grid item xs={12} xl={4} display={"flex"} alignItems={"end"}>
                    <Stack direction={"row"} spacing={2} className={"w-100"}>
                        <Button className={"w-100 py-3"} onClick={() => { inputFileT2.current.input.click() }}>
                            Actualizar programación
                        </Button>
                        <Button className={"w-100 py-3"} color={"cancel"} onClick={() => showFile("programacion_2t")}>
                            Ver programación
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Grid item xs={12} mt={2}>
                <Line />
            </Grid>

            <Grid container spacing={2} my={2}>
                <Grid item xs={12} xl={8}>
                    <Stack>
                        <H3>3º Trimestre</H3>
                        <Input ref={inputT3} className="w-100" defaultValue={libro?.programacion_3t ?? "..."} disabled useValue />
                        <Input ref={inputFileT3} className="w-100" type={"file"} register={register} name={"programacion_3t"} style={{ display: "none" }} inputProps={{ onChange: ({ target }) => updateInput(target.value, inputT3.current), accept }} />
                    </Stack>
                </Grid>

                <Grid item xs={12} xl={4} display={"flex"} alignItems={"end"}>
                    <Stack direction={"row"} spacing={2} className={"w-100"}>
                        <Button className={"w-100 py-3"} onClick={() => { inputFileT3.current.input.click() }}>
                            Actualizar programación
                        </Button>
                        <Button className={"w-100 py-3"} color={"cancel"} onClick={() => showFile("programacion_3t")}>
                            Ver programación
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    )
}