import { ItemsSelect } from "components/forms/components/Select/custom";
import { Modal } from "components/modals";
import { useEffect, useRef, useState } from "react";
import { Box } from '@mui/material'
import { Button } from "components/forms/components";

/**
 * Componente para manejar la creacion de un responsable al crear un alumno desde 0.
 * Ya existía un componente llamado ChooseTipoRelacion, que se usaba en el single, pero no me servía de primeras para hacerlo en el create
 * 
 * @param {*} 
 * @returns Component
 */
export default function ChooseTipoRelacionCreate({ addResponsableModalCreateRef, selectedResponsable, openRelationModal, setOpenRelationModal, register, setResponsables }) {
    const childModal = useRef()

    const [relationSelected, setRelationSelected] = useState(null)

    useEffect(() => {
        if (openRelationModal) {
            childModal.current.open()
            setOpenRelationModal(false)
        }
    }, [openRelationModal])

    const handleSave = () => {
        if (selectedResponsable && relationSelected) {
            // Crear un nuevo objeto con los datos del responsable y el tipo de relación
            const updatedResponsable = {
                ...selectedResponsable,
                tipo_relacion_id: relationSelected,
                creating: true, //para comprobar si es un responsable recien creado
                selectedFromList: true
            }
            // Transformar claves para que se adecuen al componente que renderiza el responsable al seleccionarlo
            const responsableValues = Object.keys(updatedResponsable).reduce((obj, key) => {
                obj[key] = updatedResponsable[key]
                return obj
            }, {})

            setResponsables((currentResponsables) => {
                const newResponsable = responsableValues
                return [...currentResponsables, newResponsable]
            })

            childModal.current.close()
            addResponsableModalCreateRef.current.close()
        }
    }

    return (
        <Modal ref={childModal} ariaLabel={"child-modal-title"} ariaDescribe={"child-modal-description"} title={"Elige el tipo de relación del tutor con el alumno"} style={{ minWidth: "50%" }}>
            <Box p={10}>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <ItemsSelect register={register} name={"responsable_tipo_relacion_id"} keyValue={"tipo_relacion"} onChange={(e) => setRelationSelected(e.target.value)} />
                </Box>
                <Box display={"flex"} justifyContent={"end"} alignItems={"center"}>
                    <Button
                        className="my-5"
                        onClick={handleSave}
                        disabled={!relationSelected}>
                        Aceptar
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}