import * as Yup from 'yup'
import { validation as validationGeneral } from './GeneralDataForm/validation'
import { validation as validationPago } from './PagoDataForm/validation'
import { validation as ValidationGroup } from './GroupData/validation'
import { validation as ValidationLibro } from './LibroDataForm/validation'

export const validationSchema = Yup.object().shape({
    ...validationGeneral,
    ...validationPago,
    ...ValidationGroup,
    ...ValidationLibro,
})