import { Grid } from "@mui/material"
import { H3, Text } from "components/texts"
import { Checkbox, ColorInput, Input, LoadingButton } from 'components/forms/components'
import { Horario } from 'components/data_display'
import { useEffect, useRef, useState } from "react"
import { ItemsSelect } from "components/forms/components/Select/custom"
import Requests from './Requests/Requests'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'

export default function DataFormHorariosClasesParticulares({ claseParticular, lugarClase, setValue, register, getValues, handleSubmit, errors, clearErrors, setLugarClase ,isMounted, control, hideButton = false, loading = false }) {
    //REF
    const loadingButton = useRef()

    //STATE
    const [hideAula, sethideAula] = useState(getValues("lugar_clase_id") == 2 ? true : false)
    const [horarios, setHorarios] = useState(claseParticular?.horarios ?? [])
    const [horario, setHorario] = useState(null)
    const [aula, setAula] = useState(null)

    //REQUEST
    const request = new Requests()

    useEffect(() => {
        sethideAula(lugarClase == 2 ? true : false)
        setAula(null)
    }, [lugarClase])

    useEffect(() => {
        const search = lugarClase == 2 ? null : (aula ?? 0)
        const horarioToShow = horarios.find(horario => horario.aula_id == search)
        setHorario(horarioToShow)
    }, [aula, lugarClase])

    useEffect(() => {
        if (isMounted && loadingButton.current)
            loadingButton.current.setLoading(loading)
    })

    //-------------------------------------------------------
    //----------------------- REQUESTS ----------------------
    //-------------------------------------------------------

    /**
     * Función para crear una definición del horario
     * 
     */
    const createHorario = () => {
        request.createHorario(claseParticular.id, getValues, (res) => {
            if (res.item) {
                setHorario(res.item)
                enqueueSnackbar(<Text>Se ha creado el horario correctamente.</Text>, { variant: "success" })
            }
        }, (res) => {
            enqueueSnackbar(<Text>{res}</Text>, { variant: "error" })
        })
    }

    /**
     * Función para eliminar una definición del horario
     * 
     * @param id_definicion_horario
     */
    const deleteHorario = (id_definicion_horario) => {
        request.deleteDefinicionHorario(id_definicion_horario, (res) => {
            if (res.item) {
                //Buscar el index en horarios, donde res.item.id == horario.item, horarios[index] = res.item -> setHorarios(horarios)
                res.item.definiciones = res.item.definiciones.filter(definicion => definicion.id != id_definicion_horario)

                setHorario(res.item)

                horarios.forEach(horario => {
                    if (horario.id == res.item.id) {
                        horario.definiciones = horario.definiciones.filter(definicion => definicion.id != id_definicion_horario)

                        const existeHorario = horarios.some(h => h.id === horario.id);
                        if (!existeHorario)
                            setHorarios(prevHorarios => [...prevHorarios, horario]);
                    }
                });

                enqueueSnackbar(<Text>Se ha eliminado la definición del horario correctamente.</Text>, { variant: "success" })
            }

        }, (res) => {
            enqueueSnackbar(<Text>Ha habido un error al crear la definición del horario correctamente.</Text>, { variant: "error" })
        })
    }

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <>
            <Grid container>

                {/* SEARCH FORM */}
                <Grid container mt={2} px={4} spacing={2}>
                    <Grid item xs={12}>
                        <H3>Generar horario</H3>
                    </Grid>

                    {/* <Grid item xs={4}>
                        {isMounted ?
                            <ItemsSelect name={"lugar_clase_id"} keyValue={"lugar_clase"} register={register} errors={errors} defaultValue={claseParticular?.lugar_clase_id ?? ""} onChange={
                                (_, item) => {
                                    item.id === 1 ? sethideAula(true) : sethideAula(false)
                                    setLugarClase(item.id)
                                    setAula(null)
                                } 
                            } />
                            : null}
                    </Grid> */}
                    
                    {hideAula ? null :
                        <Grid item xs={5}>
                            {isMounted ?
                                <ItemsSelect name={"aula_id"} keyValue={"aula"} register={register} errors={errors} defaultValue={claseParticular?.aula_id ?? ""} onChange={
                                    ((_, item) => setAula(item.id))
                                } />
                                : null}
                        </Grid>
                    }

                    <Grid item xs={2}>
                        <Checkbox name={"clase_online"} label={"Clase Online"} register={register} />
                    </Grid>

                    <Grid item xs={2}>
                        <Input type={"time"} label="Hora inicio:" register={register} errors={errors} className="w-100" name={"hora_inicio"} InputLabelProps={{ shrink: true }} inputProps={{ min: '07:00', max: '20:30', step: 1800 }} uppercase />
                    </Grid>

                    <Grid item xs={2}>
                        <Input type={"time"} label="Hora fin:" register={register} errors={errors} className="w-100" name={"hora_fin"} InputLabelProps={{ shrink: true }} inputProps={{ min: '07:30', max: '21:00', step: 1800 }} uppercase />
                    </Grid>

                    <Grid item xs={3} display={"flex"} justifyContent={"center"}>
                        <Checkbox name={"l"} label={"L"} className={"flex-column"} register={register} />
                        <Checkbox name={"m"} label={"M"} className={"flex-column"} register={register} />
                        <Checkbox name={"x"} label={"X"} className={"flex-column"} register={register} />
                        <Checkbox name={"j"} label={"J"} className={"flex-column"} register={register} />
                        <Checkbox name={"v"} label={"V"} className={"flex-column"} register={register} />
                        <Checkbox name={"s"} label={"S"} className={"flex-column"} register={register} />
                    </Grid>

                    <Grid item xs={2}>
                        <ColorInput name={"color"} register={register} errors={errors} />
                    </Grid>

                    {hideButton ? null :
                        (<Grid item xs={12} justifyContent="end" display={"flex"}>
                            <LoadingButton ref={loadingButton} onClick={handleSubmit(createHorario)}>
                                Añadir al horario
                            </LoadingButton>
                        </Grid>)
                    }
                </Grid>

                {/* HORARIO */}
                <Grid container mt={2} px={4} spacing={2}>
                    <Horario horarios={horario ? [horario] : []} type="clases-particulares" onDeleteClick={deleteHorario} />
                </Grid>
            </Grid>

            <SnackbarProvider autoHideDuration={3000} />
        </>

    )
}