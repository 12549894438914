import { Modal } from "components/modals"
import { Alert, Grid } from '@mui/material'
import { Select } from "components/forms/components"
import { useEffect, useRef, useState } from "react"
import { useAlert, useErrorHandler } from 'hooks/index.js'
import Requests from "./Requests/Requests"
import { DataFormDatosColegio } from 'components/forms/display'
import { Line } from 'components/data_display'
import { AlumnoFullItem } from "components/data_display/users"
import { validationSchema } from 'components/forms/display/datos-colegio/DataForm/validation.js'
import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"

/**
 * Este componente `DatosColegioModal` presenta un modal para mostrar y editar los datos del colegio asociados a un alumno.
 * Incluye campos para la selección de datos del colegio, un formulario para editar o crear datos del colegio, y visualización
 * de información relevante sobre el alumno.
 *
 * @param {Array} datosColegio - Array de objetos que contienen los datos del colegio asociados al alumno.
 * @param {Object} alumno - Objeto que representa al alumno del que se van a mostrar o editar los datos del colegio.
 * @param {boolean} open - Indica si el modal está abierto o cerrado.
 * @param {Function} setOpen - Función para abrir o cerrar el modal.
 * @param {Function} getDatosColegio - Función para obtener los datos del colegio asociados a un alumno específico.
 * 
 * @returns {JSX.Element} - Retorna el JSX que representa el modal de datos del colegio.
 */
export default function DatosColegioModal({ datosColegio = [], alumno, open, setOpen, getDatosColegio = () => {} }) {
    
    // Estados y funciones necesarias para la lógica del modal
    const updateAlert = useAlert();
    const handleError = useErrorHandler();
    const modalRef = useRef();
    const { register, setValue, handleSubmit, reset: resetForm, formState: { errors }, getValues } = useForm({ resolver: yupResolver(validationSchema) });
    const [selectedDatoColegio, setSelectedDatoColegio] = useState({});

    // Efecto para manejar la apertura y cierre del modal y obtener los datos del colegio al abrir el modal
    useEffect(() => {
        if (open) {
            getDatosColegio(alumno?.id);
            // Resetear formulario y restablecer el valor de la incidencia seleccionada al valor por defecto (null) antes de abrir el modal
            resetForm();
            setSelectedDatoColegio(null);

            modalRef.current.open();
            setOpen(false);
        }
    }, [open, resetForm]);

    // Efecto para actualizar los valores del formulario cuando se selecciona un nuevo dato del colegio
    useEffect(() => {
        if (selectedDatoColegio) {
            setValue('id_curso_escolar', selectedDatoColegio.id_curso_escolar);
            setValue('id_colegio', selectedDatoColegio.id_colegio);
            setValue('id_curso_colegio', selectedDatoColegio.id_curso_colegio);
            setValue('libro_id', selectedDatoColegio.libro_id ? selectedDatoColegio?.libro_id : ''); //Para evitar que reviente la consulta al traer los datos de la BD, comprobamos que venga rellenos si no los dejamos como cadena vacia
            setValue('nota_final', selectedDatoColegio.nota_final);
            setValue('num_curso', selectedDatoColegio.num_curso ? selectedDatoColegio.num_curso : ''); //Para evitar que reviente la consulta al traer los datos de la BD, comprobamos que venga rellenos si no los dejamos como cadena vacia
        }
    }, [selectedDatoColegio, setValue, resetForm]);

    // Manejador para el cambio de selección de datos del colegio
    const handleOnChange = (event) => {
        const selectedDatoColegio = datosColegio.find(datoColegio => datoColegio.id === event.target.value);
        setSelectedDatoColegio(selectedDatoColegio);
    }

    // Función para editar los datos del colegio
    const editDatosColegio = () => {
        new Requests().editDatosColegio(getValues, selectedDatoColegio?.id, (res) => {
            modalRef.current.close();
            updateAlert('Se han editado los datos del colegio correctamente.', 'success');
            getDatosColegio(alumno?.id);

        }, (err) => handleError(err));
    }
    
    // Función para crear nuevos datos del colegio
    const createDatosColegio = () => {
        new Requests().createDatosColegio(getValues, alumno?.id, (res) => {
            modalRef.current.close();
            updateAlert('Se han creado los datos del Colegio correctamente.', 'success');

        }, (err) => handleError(err));
    }

    // Mapeo de los datos del colegio para generar las opciones del select
    const items = datosColegio?.map(datoColegio => ({
        value: datoColegio?.id,
        label: datoColegio?.num_curso
    }));

    // Renderización del modal de datos del colegio
    return (
        <Modal ref={modalRef} title="Datos del colegio">
            <Line />

            <Grid sx={{ mx: 2, my: 2, pb: 2 }}>
                {alumno ? <AlumnoFullItem alumno={alumno} containerClassName="m-5" /> : null}
            </Grid>

            
            {items.length > 0 ? 
                    <Grid sx={{ mt: 4, px: 4 }}>
                        <Alert icon={false} className="mb-2 p-0 bg-white" severity="info">Selecciona un dato de colegio de la lista.</Alert>
                        <Select
                            label="Selecciona Datos del Colegio"
                            items={items}
                            onChange={handleOnChange} />
                    </Grid>
            : null}
        

            <Grid sx={{ pb: 2 }}>
                <DataFormDatosColegio
                    datosColegio={selectedDatoColegio}
                    register={register}
                    errors={errors}
                    isMounted={true}
                    setValue={setValue}
                    handleSubmit={handleSubmit}
                    onSubmit={selectedDatoColegio ? editDatosColegio : createDatosColegio} />
            </Grid>

        </Modal>    
    )
}