import React from 'react'
import { Grid } from '@mui/material';
import { Text } from 'components/texts';
import { Input } from 'components/forms/components';
import { Line } from 'components/data_display';

/**
 * Formulario con los datos del aula
 */
export default function DataFormAula({ aula, register, errors }) {
    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */

    return (

        <Grid container px={5}>

            <Grid container mt={2}>
                <Grid item xs={12}>
                    <Text fontWeight={"bold"}>Datos de las aulas de la academia:</Text>
                </Grid>
            </Grid>

            <Grid container spacing={2} py={2}>
                <Grid item xs={6}>
                    <Input label="Denominación aula" register={register} errors={errors} className="w-100" name={"denominacion"} defaultValue={aula?.denominacion ?? ""} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Capacidad aula" register={register} errors={errors} className="w-100" name={"capacidad"} defaultValue={aula?.capacidad ?? ""} uppercase />
                </Grid>

            </Grid>

            <Grid container mt={4}>
                <Line />
            </Grid>


        </Grid>
    )
}
