import { RequestClasesParticulares } from "objects/requests";

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {
    /**
     * Hace la petición para obtener la clase particular
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getClaseParticular(id, callbackOk = () => { }, callbackError = () => { }) {
        //TODO: hay varios appends que no tienen nada. El append profesor aparece null. Hay dos columnas en la tabla clase particular original de samuel: operador y profesor
        // Nosotros tenemos la columna empleado_id, que entiendo que será el profesor tmbién, asi que voy a usar la columna empleado_id para guardar el profesor de la clase particular
        const params = {
            appends: ['venta_libro', 'venta_libro_asociado', 'empresa', 'empleado', 'registro_actividad', 'alumno', 'horarios', 'lugar_clase', 'profesor', 'forma_conocimiento', 'forma_recogida', 'libro', 'origen_profesor', 'sexo', 'pagos', 'curso_escolar']
        }
        
        new RequestClasesParticulares().get(id, params, (res) => { callbackOk(res.item ?? null) }, callbackError)
    }

    /**
     * Hace la petición para obtener la vista preliminar de una clase particular
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getPreliminar(id, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "preliminar/" + id
        new RequestClasesParticulares().pdf(path, {}, callbackError, callbackFinally)
    }
}