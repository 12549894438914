import moment from 'moment'
import { Text } from 'components/texts'
import { Grid, Box } from '@mui/material'
import React, { useState } from 'react'
import { ItemsSelect } from 'components/forms/components/Select/custom'

const cursoActual = moment().subtract(1, 'year').format('YYYY') + "-" + moment().format('YYYY')

/**
 * Formulario con los datos de un libro
 */
export default function DataFormLibroGenerarEnvio({ register, errors, setValue, isMounted = false }) {

    const [libros,       setLibros]       = useState([])
    const [libro,        setLibro]        = useState(null)
    const [cursoEscolar, setCursoEscolar] = useState(null)

    /**
     * Calcula las unidades del stock y unidades perdidos
     */
    const calculateUnidades = () => {
        let unidadesPedidas = 0, unidadesStock = 0

        if(cursoEscolar != null && libro != null) {
            if(libro == 0) {
                for(const item of libros) {
                    unidadesPedidas += item.unidadesDisponiblesCurso[cursoEscolar] ?? 0
                    unidadesStock   += item.numPedidosCurso[cursoEscolar]          ?? 0
                }
            } else {
                const libroItem = libros.find(item => item.id == libro)

                unidadesPedidas = libroItem.numPedidosCurso[cursoEscolar]          ?? 0
                unidadesStock   = libroItem.unidadesDisponiblesCurso[cursoEscolar] ?? 0
            }
        }

        return { unidadesStock, unidadesPedidas }
    }

    const { unidadesStock, unidadesPedidas } = calculateUnidades()

    return (
        <Grid container px={5}>

            <Grid container spacing={2} mt={2}>
                <Grid item xs={4}>
                    {isMounted ?
                        <ItemsSelect 
                            label                = {"Año"}
                            name                 = {"id_curso_escolar"}
                            keyValue             = {"curso_escolar"}
                            register             = {register} 
                            errors               = {errors}
                            initialOptionByLabel = {cursoActual}
                            onChange             = {({target}) => setCursoEscolar(target.value)}
                            setValue             = {setValue}/> : null}
                </Grid>

                <Grid item xs={8}>
                    {isMounted ?
                        <ItemsSelect 
                            name              = {"id_libro"}
                            keyValue          = {"libro"}
                            register          = {register} 
                            params            = {{atributtes: 'numPedidosCurso,unidadesDisponiblesCurso'}}
                            onFetchedFinished = {({libros}) => setLibros(libros)}
                            errors            = {errors}
                            onChange          = {({target}) => setLibro(target.value)}
                            allOption
                            searcheable /> : null}
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={2}>
                <Grid item xs={6} display={"flex"} alignItems={"center"}>
                    <Text>Unidades en stock: </Text>

                    <Box mx={3} sx={{backgroundColor: "infoBox.main", px: 5, py: 1}}>
                        <Text>{unidadesStock}</Text>
                    </Box>
                </Grid>

                <Grid item xs={6} display={"flex"} alignItems={"center"}>
                    <Text>Unidades pedidas: </Text>

                    <Box mx={3} sx={{backgroundColor: "infoBox.main", px: 5, py: 1}}>
                        <Text>{unidadesPedidas}</Text>
                    </Box>
                </Grid>
            </Grid>

        </Grid>
    )
}
