import React from 'react';
import { CardButton } from 'components/forms/components'
import { Grid, Stack } from '@mui/material'
import { CursoEscolarSelect } from "components/forms/components/Select/custom"
import { H3 } from 'components/texts';
import { Line } from 'components/data_display';
import validationSchema from "./validation"
import { RequestListadosLibro } from "objects/requests"
import moment from 'moment';
import { useListadoLibroLogic } from 'hooks';

const cardData = [
	{ title: 'Inventario de los Libros', id: 1, action: "inventario" },
	{ title: 'Detalle de Libros', id: 2, action: "detalle" },
	{ title: 'Libros pendientes entregar', id: 3, action: "pendienteEntrega" },
	{ title: 'Stock de Libros', id: 4, action: "stockLibros" },
	{ title: 'Contabilidad Libros', id: 5, action: "contabilidad" },
	{ title: 'Pagos pendientes Libros', id: 6, action: "pendientePago" },
	{ title: 'Estadísticas libros pedidos', id: 7, action: "estadistica" },
	{ title: 'Devolución de Libros', id: 8, action: "devoluciones" },
	{ title: 'Resumen de Cuentas', id: 9, action: "resumenCuentas" },
	{ title: 'Precio de Libros Activos', id: 10, action: "precio" },
]

const cursoActual = moment().subtract(1, 'year').format('YYYY') + "-" + moment().format('YYYY')

/**
 * `ConsultasData` es un componente que muestra una serie de opciones para generar reportes
 * relacionados con los libros de un curso escolar específico. Cada opción se presenta como un botón
 * que al ser clickeado, ejecuta una acción correspondiente a la consulta seleccionada.
 * 
 * Utiliza `react-hook-form` junto con un esquema de validación Yup para gestionar el formulario y 
 * la selección del curso escolar. También gestiona el estado de carga de las solicitudes de reportes.
 * 
 * @returns {JSX.Element} - Un formulario con una lista de botones para cada tipo de reporte disponible
 * y un selector para el curso escolar actual.
 */

export default function ConsultasData() {

	const { loadingButtonId, register, handleSubmit, errors, setValue, handleClick } = useListadoLibroLogic(new RequestListadosLibro(), validationSchema)

	return (
		<form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">

			<Grid container spacing={2}>

				<Grid item xs={12} >
					<H3>Listados Libros: </H3>
				</Grid>

				<Grid item xs={12} md={4} py={3} pl={3}>
					<Stack spacing={2}>
						<CursoEscolarSelect name={`curso_escolar_id`} register={register} errors={errors} initialOptionByLabel={cursoActual} setValue={setValue} searcheable />
					</Stack>
				</Grid>

				<Grid item xs={12} >
					<H3>Consultas: </H3>
				</Grid>

				<Grid item xs={12} >
					<Line />
				</Grid>

				{cardData.map((card, index) => (
					<Grid item key={card.id} >
						<CardButton
							disabled={loadingButtonId === card.id}
							title={card.title}
							buttonOnClick={handleSubmit(() => { handleClick(card.action, card.id) })}
						/>
					</Grid>
				))}
			</Grid >
		</form>
	)
}

