import Requests from './Requests'
import { COLOR } from "theme/Colors"
import { TabContext } from '@mui/lab'
import { useForm } from 'react-hook-form'
import { Modal } from 'components/modals'
import { H3, Text } from "components/texts"
import { enqueueSnackbar } from 'notistack'
import React, { useRef, useState } from 'react'
import { RolesList, PermisosList } from './parts'
import { Button } from 'components/forms/components'
import { Grid, Tab, Tabs, Box, Stack } from "@mui/material"
import { TabPanel } from 'components/modifications/mui/TabPanel'

const PERMISOS = "PERMISOS"
const ROLES    = "ROLES"

export default function PermisosButton ({ row }) {
    const modalRef = useRef()

    const requests = new Requests()

    const [userRoles,       setUserRoles]       = useState({data: [], loading: true})
    const [userPermissions, setUserPermissions] = useState({data: [], loading: true})
    const [tab,             setTab]             = useState(PERMISOS)
    const [loadingUpdate,   setLoadingUpdate]   = useState(false)

    
	const { handleSubmit, setValue, getValues, reset } = useForm({ defaultValues: { permissions: [], roles: [] }})

    const onClick = () => {
        modalRef.current.open()

        requests.getUserPermissions(row.id, (data) => setUserPermissions({data, loading: false}), (res) => {
            setUserPermissions({data: [], loading: false})
            enqueueSnackbar(<Text>No se han podido recuperar los permisos del usuario: {res}.</Text>, { variant: "error" })
        })

        requests.getUserRoles(row.id, (data) => setUserRoles({data, loading: false}), (res) => {
            setUserRoles({data: [], loading: false})
            enqueueSnackbar(<Text>No se han podido recuperar los roles del usuario: {res}.</Text>, { variant: "error" })
        })
    }

    const updateAccess = (params) => {
        setLoadingUpdate(true)

        const promiseRoles = new Promise((resolve, reject) => {
            requests.updateUserRoles(row.id, params.roles, resolve, reject)
        })
        
        const promisePermissions = new Promise((resolve, reject) => {
            requests.updateUserPermissions(row.id, params.permissions, resolve, reject)
        })


        Promise.allSettled([promiseRoles, promisePermissions]).then((values) => {
            setLoadingUpdate(false)
            for (const result of values) {
                if(result.status != 'rejected') {
                    enqueueSnackbar(<Text>Los {result.value.permissions ? "permisos" : "roles"} se han actualizado correctamente</Text>, { variant: "success" })
                } else {
                    enqueueSnackbar(<Text>{result.reason}</Text>, { variant: "error" })
                }
            }
        })
    }

    return (
        <React.Fragment>
            <Button size={"small"} onClick={onClick}>Administrar permisos</Button>

            <Modal ref={modalRef} title={"Administrar roles y permisos"} style={{minWidth: "60%"}} onDismiss={() => reset()}>
                <Grid container>
                    <Grid item xs={12} p={5}>
                        <H3>Empleado/a: {(row.nombre ?? "") + " " + (row.apellido1 ?? "") + " " + (row.apellido2 ?? "")}</H3>
                    </Grid>

                    <Grid item xs={12}>
                        <TabContext value={tab}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={5}>
                                <Tabs value={tab} onChange={(_, tab) => setTab(tab)} TabIndicatorProps={{style: {backgroundColor: COLOR.standard.main}}}>
                                    <Tab label={<Text fontWeight={tab == PERMISOS ? "bold" : null} color={tab == PERMISOS ? COLOR.standard.main : null}>{PERMISOS}</Text>} value={PERMISOS}/>
                                    <Tab label={<Text fontWeight={tab == ROLES    ? "bold" : null} color={tab == ROLES    ? COLOR.standard.main : null}>{ROLES}</Text>}    value={ROLES}/>
                                </Tabs>
                            </Box>

                            <TabPanel value={PERMISOS}>
                               <PermisosList setValue={setValue} getValues={getValues} userPermissions={userPermissions.data} loading={userPermissions.loading}/>
                            </TabPanel>

                            <TabPanel value={ROLES}>
                                <RolesList setValue={setValue} getValues={getValues} userRoles={userRoles.data} loading={userRoles.loading}/>
                            </TabPanel>
                        </TabContext>
                    </Grid>

                    <Grid item xs={12} px={5} py={2}>
                        <Stack direction={"row"} justifyContent={"end"} columnGap={2}>
                            <Button variant={"outlined"} onClick={() => { modalRef.current.close() }}>Cancelar</Button>
                            <Button onClick={handleSubmit(updateAccess)} disabled={userRoles.loading || userPermissions.loading}>Aceptar</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Modal>
        </React.Fragment>
    )
}