import { columns } from './columns.js'
import { H3 } from 'components/texts'
import { Box, Container, Paper } from '@mui/material'
import React from 'react'
import { ListTableHeader } from 'pages/logged/admin/components'
import { Search as SearchIcon } from '@mui/icons-material'
import { Accordion, BreadcrumbsList, Datagrid } from 'components/data_display'
import SearchFormGrupos, { search } from 'components/forms/display/academia/grupos/SearchForm'
import { useGrupoListSearchLogic } from 'hooks/index.js'

/**
 * Componente React para listar y gestionar grupos de una academia.
 * Utiliza una estructura de contenedor para organizar la visualización de los grupos y ofrece funcionalidades
 * de búsqueda y paginación mediante interacciones con una API.
 * 
 * @returns {JSX.Element} Elemento JSX que representa el listado completo de grupos con funcionalidades interactivas.
 */
export default function ListadoGruposAcademia() {
  const { items, rowCount, updateTable, loadingTable, setLoadingTable, params } = useGrupoListSearchLogic()

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <BreadcrumbsList breadcrumbsList={[
        { to: "/", label: "Inicio" },
        { to: "./", label: "Academia" }, //--> Te dirige al apartado Grupos
        { to: "./", label: "Grupos", actualItem: true },
      ]} />

      <Accordion header={<Box display={"flex"} alignItems={"center"}><SearchIcon className={"mx-5"} /> <H3 className="my-0">Buscar grupo</H3></Box>}>
        <SearchFormGrupos
          searchCallback={updateTable}
          onLoadingCallback={setLoadingTable} />
      </Accordion>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
        <ListTableHeader rowCount={rowCount} params={params} keyValue={"grupos_academia"} />

        <Datagrid
          rows={items}
          columns={columns}
          loading={loadingTable}
          rowCount={rowCount}
          onPageChange={(page) => { search(page, () => setLoadingTable(true), updateTable) }}
          serverSide />
      </Paper>
    </Container>
  )
}