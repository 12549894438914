import { RequestRegistroActividad } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Devuelve la actividad
     * 
     * @param {Int}      id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getActividad(id, callbackOk = () => {}, callbackError = () => {}) {
        const params = {relations: 'tipo_actividad,modalidad_cursos'}
        new RequestRegistroActividad().get(id, params, (res) => {
            res.actividad ? callbackOk(res.actividad) : callbackError(res)
        })
    }

}
