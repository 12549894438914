import * as Yup from 'yup'

/**
 * Esquema de validación para los datos de un libro.
 *
 * Este esquema define las reglas de validación para los campos de un libro, incluyendo la editorial,
 * el nombre, el precio editorial y el precio de venta al público (PVP).
 */

// Expresión regular para validar ISBN-10 e ISBN-13 sin prefijos, permitiendo guiones.
const isbnRegex = /^(97[89][- ]?)?\d{1,5}[- ]?\d+[- ]?\d+[- ]?[\dX]$/

const validationSchema = Yup.object().shape({
    id_editorial: Yup.string().required('La editorial es obligatoria'),
    nombre: Yup.string().required('El nombre es obligatorio'),
    precio_editorial: Yup.string().required('El precio de editorial es obligatorio'),
    pvp: Yup.string().required('El PVP es obligatorio'),
    isbn: Yup.string().matches(isbnRegex, 'ISBN no válido').required('El campo ISBN es obligatorio'),
})

export default validationSchema