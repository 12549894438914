import { Edit as EditIcon } from '@mui/icons-material';
import React, { Component } from 'react'
import { v4 as uuid } from 'uuid';
import FileImage from './FileImage'
import './style.css'

const IMAGES_TYPES = ['image/jpeg', 'image/png', "image/svg+xml"];

/**
 * DragAndDrop.
 *
 * @param {String}      [name=""]           Nombre que se le va a asignar al input
 * @param {Array}       [mimeTypes=[]]      Array con los mimeTypes que se van a aceptar
 */
export default class ImagePicker extends Component {

    constructor(props) {
        super(props)
        this.state = {
            image: this.props.defaultImage ?? null,
        }

        this.id = "image-picker-" + uuid()
        this.register = this.props.register(this.props.name ?? "image")
    }

    /* ================================================================================================
    ============================================= CLASS FUNCTIONS =====================================
    ================================================================================================ */

    /**
     * Cambia la imagen
     *
     * @param {Object} newImage Contiene la imagen nueva
     */
    changeImage = (image) => {
        const { mimeTypes } = this.props

        // Si el mimetype del archivo no está permitido se sale de la función
        if (mimeTypes && mimeTypes.length > 0 && mimeTypes.indexOf(image.type) < 0)
            return

        this.setState({ image }, () => {
            if (this.props.getUpdatedFiles)
                this.props.getUpdatedFiles(this.state.image)
        })
    }


    /**
     * Devuelve la imagen actual
     *
     * @return {Object}
     */
    getImage = () => {
        return this.state.image
    }


    /**
     * Borra la imagen
     */
    resetImage = () => {
        this.setState({ image: null })
    }

    /* ================================================================================================
    =============================================== FILE EVENTS =======================================
    ================================================================================================ */

    /**
     * Detecta cuando se mete un nuevo archivo
     *
     * @param {Event} e
     */
    onChange = (e) => {
        if(!this.props.disabled) {
            const { files } = e.target
            if (files.length > 0)
                this.changeImage(files[0])
        }
    }


    /**
     * Evento cuando se clicka en el cuadrado
     */
    onClick = () => {
        document.getElementById(this.id).click()
    }

    /* ================================================================================================
    ============================================ RENDER FUNCTIONS =====================================
    ================================================================================================ */

    /**
     * Render
     *
     * @return {HTML}
     */
    render() {
        return (
            <div className={"custom-file-picker" + (this.props.className ? " " + this.props.className : "")} onClick={this.onClick} style={this.props.style}>
                <FileImage image={this.state.image} />

                {!this.props.disabled ?
                    <div className="custom-file-picker-icon d-flex justify-content-center align-items-center">
                        <EditIcon color={"white"} />
                    </div>
                : null}

                <input
                    id       = {this.id}
                    type     = "file"  
                    accept   = {this.props.mimeTypes ?? IMAGES_TYPES} 
                    multiple = {false}
                    {...this.register}
                    onChange = {(e) => {
                        this.onChange(e)
                        this.register.onChange(e)
                    }} />
            </div>
        )
    }
}