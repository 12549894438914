import React from "react"
import ReactDOM from "react-dom/client"
import { THEME } from "./theme/Theme.js"
import MainRouter from './router/MainRouter.jsx'
import { ThemeProvider } from "@mui/material/styles"
import { BrowserRouter, HashRouter } from "react-router-dom"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

//REDUX
import store from "./store/store.js"
import { Provider } from "react-redux"
import { persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"

const persistor = persistStore(store)
const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <PersistGate persistor={persistor}>
    <Provider store={store}>
      <ThemeProvider theme={THEME}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <MainRouter />
          </BrowserRouter>
        </QueryClientProvider>
      </ThemeProvider>
    </Provider>
  </PersistGate>
)
