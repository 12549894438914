import * as Yup from 'yup'


export const validation = {
    numero_documento: Yup.string()
        .required('El número de documento es requerido')
        .matches(
            /^(?:(?:\d{8}[A-Za-z])|(?:[A-Za-z]\d{7}[A-Za-z0-9])|(?:[A-Za-z]\d{8})|(?:[A-Za-z]\d{7}[A-Za-z])|(?:[A-Za-z0-9]\d{7}[A-Za-z0-9]))$/,
            'Número de documento no válido'),
    nombre: Yup.string()
        .matches(/^[A-Za-zñÑáéíóúÁÉÍÓÚ\süÜ]+$/, 'Nombre no válido.')
        .required('El nombre es requerido'),
    apellido1: Yup.string().required('El primer apellido es requerido'),
    apellido2: Yup.string(),
    direccion: Yup.string(),
    cod_postal: Yup.string(),
    municipio: Yup.string(),
    telefono: Yup.string(),
    movil: Yup.string(),
    email: Yup.string().email('Introduce una direccion válida').required('El correo electrónico es requerido'),
    email2: Yup.string().email(),
    municipio_nacimiento: Yup.string(),
    pais_nacimiento: Yup.string(),
    foto_logo: Yup.string(),
    comentarios: Yup.string(),
    sexo_id: Yup.number()
        .required('Debe indicar su género.')
        .typeError('Debe indicar su género.'),
    id_provincia: Yup.number().integer(),
    tipo_documento_id: Yup.number().required('Debe indicar el tipo de documento.')
        .typeError('Debe indicar el tipo de documento.'),
    id_provincia_nacimiento: Yup.number().integer(),
}

export const validationSchema = Yup.object().shape(validation)