import { 
    InscripcionAlumnosTemplate, 
    InscripcionGruposPendientesTemplate, 
    InscripcionGruposTemplate, 
    InscripcionActividadesTemplate, 
    InscripcionSubvencionadasTemplate, 
    InscripcionTipoPagoTemplate,
    InscripcionPagoCeroTemplate
} from "./parts"

export const itemsSearch = [
    { value: 0,  columnKey: "alumnos",        label: "Inscripciones de los colegios por Alumnos",          template: InscripcionAlumnosTemplate },
    { value: 1,  columnKey: "pendientes",     label: "Inscripciones Pendientes de Grupos de los colegios", template: InscripcionGruposPendientesTemplate },
    { value: 2,  columnKey: "grupos",         label: "Inscripciones de los colegios por Grupos",           template: InscripcionGruposTemplate },
    { value: 3,  columnKey: "actividades",    label: "Inscripciones de los colegios por Actividades",      template: InscripcionActividadesTemplate },
    { value: 4,  columnKey: "subvencionadas", label: "Inscripciones Subvencionadas de los colegios",       template: InscripcionSubvencionadasTemplate },
    { value: 5,  columnKey: "pago_cero",      label: "Alumnos con Pago Cero",                              template: InscripcionPagoCeroTemplate },
    { value: 6,  columnKey: "tipo_pago",      label: "Alumnos por tipo de pago",                           template: InscripcionTipoPagoTemplate },
]

export const itemsOrder  = [
    { value: '{"alumno.nombre": "ASC"}',  label: "Alumnos" },
]