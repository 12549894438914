import React from 'react'
// import { ASSETS } from 'constants/Assets'
import { H3, Text } from 'components/texts'
import { Avatar, Box, Grid } from '@mui/material'
import { useImageLoader } from 'hooks'
import { DEFAULT_IMAGES_URL } from 'constants/DefaultImagesUR'

/**
 * Componente `AlumnoFullItem` para mostrar información detallada de un alumno.
 * Utiliza `useImageLoader` para manejar la carga de la imagen de perfil del alumno de forma eficiente.
 * 
 * @param {Object} alumno - Datos del alumno a mostrar.
 * @param {string} containerClassName - Clase CSS adicional para el contenedor principal.
 * @param {number} imageXS - Tamaño del grid para la imagen del alumno según el sistema de grid de Material UI.
 * @param {number} contentXS - Tamaño del grid para el contenido del alumno según el sistema de grid de Material UI.
 * @returns {JSX.Element} Estructura de la tarjeta del alumno con su imagen y datos relevantes.
 */
export default function AlumnoFullItem({ alumno, containerClassName, imageXS = 2, contentXS = 8 }) {
    const { imageSrc } = useImageLoader(alumno, 'foto_logo', DEFAULT_IMAGES_URL.alumno, true)

    return (
        <Grid container spacing={2} className={containerClassName}>
            <Grid item xs={imageXS}>
                <Avatar
                    alt={"Foto de: " + (alumno?.nombre ?? "")}
                    // src = {`${ASSETS.images + (alumno?.foto_logo ? alumno?.foto_logo : 'private/alumno/perfiles/0/default.png')}`}
                    src={imageSrc}
                    sx={{ width: "80%", height: "100%", borderRadius: 2 }} />
            </Grid>

            <Grid item xs={contentXS}>
                <Box mt={2}>
                    <H3>{alumno?.nombre + " " + (alumno?.apellido1 ?? "") + " " + (alumno?.apellido2 ?? "")}</H3>

                    <Box display={"flex"}>
                        <Text fontWeight={"bold"}>Email:</Text>
                        <Text className="mx-2">{alumno?.email ?? ""}</Text>
                    </Box>

                    <Box display={"flex"}>
                        <Text fontWeight={"bold"}>Documento de identidad:</Text>
                        <Text className="mx-2">{(alumno?.numero_documento ?? "") + " - " + (alumno?.tipo_documento?.nombre ?? "")}</Text>
                    </Box>

                    <Box display={"flex"}>
                        <Text fontWeight={"bold"}>Ubicación:</Text>
                        <Text className="mx-2">{(alumno?.tipo_via?.valor ?? "Tipo vía ?") + " - " + (alumno?.nombre_via ?? "")}</Text>
                    </Box>

                    <Box display={"flex"}>
                        <Text fontWeight={"bold"}>Género:</Text>
                        <Text className="mx-2">{alumno?.sexo?.valor}</Text>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}
