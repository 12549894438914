import { RequestDatosColegio } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Hace la petición para editar el usuario
     * 
     * @param {Function} getValues 
     * @param {Int}      id_alumno 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createDatosColegio(getValues, id_alumno, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()

        formData.append("nota_final",       getValues("nota_final").replace(',', "."))
        formData.append("num_curso",        getValues("num_curso").toUpperCase())
        formData.append("id_colegio",       getValues("id_colegio"))
        formData.append("id_curso_colegio", getValues("id_curso_colegio"))
        formData.append("id_curso_escolar", getValues("id_curso_escolar"))
        formData.append("id_alumno",        id_alumno)
        formData.append("libro_id",         getValues("libro_id"))

        new RequestDatosColegio().create(formData, callbackOk, callbackError)
    }

    /**
     * Envía una petición para editar los datos del colegio asociados a un alumno específico.
     * 
     * @param {Int} id - El identificador único de los datos del colegio que se van a editar.
     * @param {Function} getValues - Una función que se utiliza para obtener los valores de los campos del formulario.
     *                               Esta función debe retornar el valor del campo especificado.
     * @param {Function} callbackOk - Función que se ejecuta si la petición se completa exitosamente.
     *                                Se espera que maneje la respuesta positiva de la petición.
     * @param {Function} callbackError - Función que se ejecuta si la petición falla.
     *                                   Se espera que maneje los errores o la respuesta negativa de la petición.
     * 
     * La función crea un objeto FormData y adjunta a este los valores obtenidos de la función getValues.
     * Luego, hace una llamada a la API utilizando una instancia de RequestDatosColegio, pasando el ID de los datos
     * del colegio, el objeto FormData, y las funciones de callback para manejar la respuesta.
     */
    editDatosColegio = (getValues, id, callbackOk = () => { }, callbackError = () => { }) => {
        const formData = new FormData()

        // Se agregan los campos del formulario al objeto formData.
        // formData.append("nota_final", getValues("nota_final")?.replace(',', "."))
        formData.append("nota_final", getValues("nota_final"))
        formData.append("num_curso", getValues("num_curso")?.toUpperCase())
        formData.append("id_colegio", getValues("id_colegio"))
        formData.append("id_curso_colegio", getValues("id_curso_colegio"))
        formData.append("id_curso_escolar", getValues("id_curso_escolar"))
        formData.append("libro_id", getValues("libro_id"))

        new RequestDatosColegio().edit(id, formData, callbackOk, callbackError)
    }
}
