import React from 'react'
import { Grid, Skeleton } from '@mui/material'

export default function DataFormLibroSkeleton() {

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container spacing={2} mt={2} px={5}>
            <Grid item xs={6}>
                <Skeleton variant="rounded" width={"100%"} height={50}/>
            </Grid>

            <Grid item xs={6}>
                <Skeleton variant="rounded" width={"100%"} height={50}/>
            </Grid>

            <Grid item xs={12}>
                <Skeleton variant="rounded" width={"100%"} height={50}/>
            </Grid>

            <Grid item xs={8}>
                <Skeleton variant="rounded" width={"100%"} height={50}/>
            </Grid>

            <Grid item xs={4}>
                <Skeleton variant="rounded" width={"100%"} height={50}/>
            </Grid>

            <Grid item xs={6}>
                <Skeleton variant="rounded" width={"100%"} height={50}/>
            </Grid>

            <Grid item xs={3}>
                <Skeleton variant="rounded" width={"100%"} height={50}/>
            </Grid>

            <Grid item xs={3}>
                <Skeleton variant="rounded" width={"100%"} height={50}/>
            </Grid>
        </Grid>
    )
}
