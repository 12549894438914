import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { useContext, useRef } from 'react'
import Statics from 'objects/common/Statics'
import useAlert from 'hooks/utils/useAlert.js'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangesContext } from 'contexts/ChangesContext'
import { useCheckDirtyFields, useErrorHandler } from 'hooks'
import { validationSchema } from 'components/forms/display/responsable/DataForm/validation.js'
import { EmpleadoListContext } from 'contexts/employee/ListContext'

/**
 * Hook personalizado que encapsula la lógica para la creación de un nuevo responsable (tutor).
 * Utiliza react-hook-form para el manejo eficiente de formularios y yup para la validación de datos.
 * Este hook integra funcionalidades de navegación, manejo de errores, y alertas para ofrecer
 * una experiencia de usuario coherente y efectiva durante el proceso de creación de un responsable.
 *
 * @returns {Object} Un objeto que expone métodos y propiedades útiles para el componente consumidor.
 */
export default function useResponsableCreateLogic({ requests }) {
    const updateAlert = useAlert()

    const { register, handleSubmit, formState: { errors, dirtyFields }, getValues } = useForm({ resolver: yupResolver(validationSchema) })

    const navigate = useNavigate()

    const headerRef = useRef()

    const { updateChanges } = useContext(ChangesContext)
    const { addItem } = useContext(EmpleadoListContext)

    const handleError = useErrorHandler()

    useCheckDirtyFields(dirtyFields, updateChanges)

    /**
     * Función que maneja la lógica de creación de un nuevo responsable.
     * Realiza una petición para crear un nuevo registro de responsable basándose en los datos del formulario.
     * Gestiona el estado de carga, maneja la respuesta satisfactoria o errores y, finalmente,
     * redirige al usuario a una página específica relacionada con el responsable creado.
     */
    const createResponsable = () => {
        headerRef.current.button.setLoading(true)

        requests.createResponsable(getValues, async (res) => {
            // headerRef.current.button.setLoading(false)
            updateAlert('Se ha creado el tutor correctamente. Redirigiendo a la pagina de edición..', 'success')
            updateChanges(false)
            addItem(res?.responsable) //Se añade el nuevo responsable creado a la lista de items del contexto
            await Statics.delay(3000).then(() => { navigate('./../' + res.responsable.id) }) //Modificada la ruta para que redirija al single.
        }, (res) => {
            // headerRef.current.button.setLoading(false)
            handleError(res)
        })
    }

    return { createResponsable, handleSubmit, headerRef, register, errors }
}