import React from 'react';
import { Card, CardContent, Typography, Button, Grid } from '@mui/material';
import { PictureAsPdfRounded as PdfIcon, } from '@mui/icons-material'
import { COLOR } from 'theme/Colors.js'
import { LoadingButton } from '@mui/lab';


export default function CardButton({ title, buttonOnClick, disabled }) {

    return (
        <Grid container spacing={2} p={2}>
            <Card
                sx={{
                    border: '1px solid #4B799E',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                }}>
                <CardContent >
                    <Typography variant="body1" color={COLOR.grey.dark}>
                        {title}
                    </Typography>
                    <LoadingButton
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '200px',
                            height: '50px',
                            backgroundColor: COLOR.standard.main,
                            marginTop: '1.5rem',
                            '&:hover': {
                                backgroundColor: COLOR.grey.main,
                            },
                        }}
                        variant="contained"
                        startIcon={<PdfIcon style={{ fontSize: '2rem' }} />}
                        onClick={buttonOnClick}
                        // disabled={disabled}
                        loading={disabled}
                    >
                    </LoadingButton>
                </CardContent>
            </Card>
        </Grid >
    )
}

