import { useAlert } from "hooks"
import { useContext, useEffect, useState } from "react"
import { EmpleadoListContext } from "contexts/employee/ListContext"

/**
 * Hook para gestionar la lógica y el estado de los datos del colegio de un alumno.
 * Incluye la carga de datos desde el servidor y actualización del estado local.
 * 
 * @param {Object} navigationParams - Parámetros de navegación que incluyen el ID de los datos del colegio.
 * @returns {Object} Retorna los estados y funciones para interactuar con los datos del colegio en el componente.
 */
export default function useWrapperLogic(requests, navigationParams) {
    const { items, updateItem } = useContext(EmpleadoListContext)
    const [datosColegio, setDatosColegio] = useState(null)
    const [id_datos_colegio, setIdDatosColegio] = useState(navigationParams.id_datos_colegio)
    const updateAlert = useAlert()

    /**
     * Carga los datos del colegio desde el servidor o utiliza el cache si está disponible.
     */
    const getDatosColegio = (getFromServer = false) => {
        const item = items.find(item => item?.id == id_datos_colegio)
        if (items.length == 0 || !item || getFromServer) {
            requests.getDatosColegio(id_datos_colegio, (datos_colegio) => {
                setDatosColegio(datos_colegio)
                updateItem(datos_colegio)
            }, () => updateAlert("No se ha podido recuperar los datos del colegio."))
        } else {
            setDatosColegio(item)
        }
    }

    useEffect(getDatosColegio, [id_datos_colegio])

    return { updateAlert, datosColegio, id_datos_colegio, setIdDatosColegio, setDatosColegio, getDatosColegio }
}