import Statics from 'objects/common/Statics'
import { RequestRegistroActividad } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Hace la petición para editar la actividad
     * 
     * @param {Int} id 
     * @param {Object}   values  
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editActividad(id, values, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()

        formData.append("nombre",              values.nombre.toUpperCase())
        formData.append("intensivo",           values.intensivo ? 1 : 0)
        formData.append("tipo_actividad_id",   values.id_tipo_actividad)
        formData.append("actividad_id",        values.id_actividad)
        formData.append("modalidad_cursos_id", values.id_modalidad_cursos)
        formData.append("precio",              Statics.changeDotAndComma(values.precio, false))
        formData.append("matricula",           Statics.changeDotAndComma(values.matricula, false))
        formData.append("precio_junio",        Statics.changeDotAndComma(values.precio_junio, false))

        new RequestRegistroActividad().edit(id, formData, callbackOk, callbackError)
    }


    /**
     * Devuelve la actividad
     * 
     * @param {Int}      id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getActividad(id, callbackOk = () => { }, callbackError = () => { }) {
        new RequestRegistroActividad().get(id, {}, (res) => {
            res.actividad ? callbackOk(res.actividad) : callbackError(res)
        })
    }
}
