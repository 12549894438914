import { useEffect, useState, useContext } from 'react'
import { EmpleadoListContext } from 'contexts/employee/ListContext'

/**
 * Hook personalizado `useLogic` para gestionar la lógica del listado de responsables.
 * Este hook encapsula el manejo del estado, como los datos de la tabla, el estado de carga,
 * y los parámetros de búsqueda. Utiliza el contexto `EmpleadoListContext` para actualizar
 * el estado global en función de las operaciones realizadas en el listado.
 * 
 * @returns {Object} Objeto con propiedades y funciones para interactuar con el estado del listado.
 */
export default function useListLogic() {
    const [loadingTable, setLoadingTable] = useState(false)
    const { items, rowCount, update, params } = useContext(EmpleadoListContext)

    useEffect(() => {
        setLoadingTable(false)
    }, [items])

    /**
     * Función `updateTable` para actualizar los datos de la tabla y el estado global.
     * Se invoca después de realizar operaciones de búsqueda o paginación.
     * 
     * @param {Array} data - Datos de la tabla.
     * @param {number} rowCount - Número total de filas disponibles.
     * @param {number} page - Página actual.
     * @param {Object} params - Parámetros de búsqueda o filtrado.
     */
    const updateTable = (newData, newRowCount, newPage, newParams) => {
        update(newRowCount, newData, newParams, newPage);
    };


    return { items, rowCount, updateTable, loadingTable, setLoadingTable, params }
}