import React, { Component } from 'react'
import Modal from 'components/modals/Modal.jsx'
import { SnackbarAlert } from 'components/data_display'
import { Text } from 'components/texts/index.js'
import { Button } from 'components/forms/components/index.js'
import { RequestResponsable } from 'objects/requests/index.js'

/**
 * Modal para confirmar el borrado de la relación de un alumno con un responsable
 */
export default class DeleteRelacionModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            responsable: null,
        }
    }


    /**
     * Abre el modal actualizando el estado
     * 
     * @param {Object} responsable
     */
    open = (responsable) => {
        this.setState({ responsable }, () => {
            this.modal.open()
        })
    }


    /**
     * Hace la petición para el borrado de la relacion
     */
    erase = () => {
        new RequestResponsable().erase(this.state.responsable.id, new FormData(), () => {
            this.snackBarAlertRef.updateState({ message: this.props.onDeleteMessage ?? "Se ha borrado el tutor correctamente.", severity: "success", open: true })
            this.modal.close()
            if (this.props.onDeleteCallback)
                this.props.onDeleteCallback()

        }, (res) => {
            this.snackBarAlertRef.updateState({ message: res, severity: "error", open: true })
        })
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <>
                <Modal ref={(ref) => this.modal = ref} title={"Eliminar relación"}>
                    <div className="py-20 px-10">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                            <Text>¿Estás seguro de que quieres borrar el tutor <Text fontWeight={"bold"}>{this.state.responsable ? (this.state.responsable.nombre ?? "") + " " + (this.state.responsable.apellido1 ?? "") + " " + (this.state.responsable.apellido2 ?? "") : ""}</Text>?</Text>
                            {this.state.responsable && this.state.responsable.alumnos.length > 0 ? <Text>Este tutor tiene {this.state.responsable.alumnos.length} alumno/s a su cargo, al borrarlo también se borrarán las asociaciones de los alumnos a este tutor.</Text> : null}
                        </div>

                        <div className="d-flex justify-content-center mt-5">
                            <Button onClick={this.erase} color={"error"}>
                                Eliminar
                            </Button>
                        </div>
                    </div>
                </Modal>
                <SnackbarAlert ref={(ref) => this.snackBarAlertRef = ref} />
            </>
        )
    }
}
