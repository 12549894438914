import { v4 as uuid } from 'uuid'
import { Grid } from '@mui/material'
import { Text } from 'components/texts'
import validationSchema from "./validation"
import { SearchButtons } from '../../components'
import { Select } from 'components/forms/components'
import { RequestDatosColegio } from 'objects/requests'
import { useAlumnoDatosColegioSearchFormLogic } from 'hooks'
import { itemsSearch, itemsOrder } from './searchAndOrderOptions'

/**
 * Componente SearchFormDatosColegio que renderiza un formulario de búsqueda para datos de colegio.
 * Incluye selectores para definir criterios de búsqueda y ordenamiento, y un botón para realizar la búsqueda.
 *
 * @param {Function} onLoadingCallback - Notifica al componente padre cuando la búsqueda está en progreso.
 * @param {Function} searchCallback - Función del componente padre para manejar los resultados de la búsqueda.
 * @param {Function} onChangeSearchIndex - Función del componente padre para manejar cambios en el índice de búsqueda.
 * 
 * @returns {JSX.Element} El formulario de búsqueda renderizado.
 */
export default function SearchFormDatosColegio({ onLoadingCallback, searchCallback, onChangeSearchIndex }) {
    const { prepareParamsGlobal, resetFormState, setResetFormState, searchIndex, errors, register, setValue, handleSubmit, handleChange, ActualTemplate } = useAlumnoDatosColegioSearchFormLogic({ onChangeSearchIndex, validationSchema, itemsSearch })

    return (
        <Grid container p={2}>
            <Grid container>
                <Grid item xs={6}>
                    <Text fontWeight={'bold'} marginBottom={6}>Buscar por:</Text><br />
                    <Select width={"80%"} items={itemsSearch} defaultValue={itemsSearch[0].value} onChange={handleChange} />
                </Grid>

                <Grid item xs={6}>
                    <Text fontWeight={'bold'} marginBottom={6}>Ordenar resultados por:</Text><br />
                    <Select key={uuid()} width={"60%"} name={"order"} items={itemsOrder[searchIndex]} defaultValue={itemsOrder[searchIndex][0].value} register={register} errors={errors} />
                </Grid>
            </Grid>

            {/* 21/03/2024 Pasamos también el parametro setValues con la constante setValues para establecer la fecha default */}
            <ActualTemplate reset={resetFormState} register={register} errors={errors} setValue={setValue} />

            <SearchButtons searchText={"Buscar datos"} onResetClick={() => { setResetFormState(true) }} onSubmitClick={handleSubmit(() => search(1, onLoadingCallback, searchCallback, prepareParamsGlobal))} />
        </Grid>
    )
}

/**
 * Realiza la búsqueda de datos de colegio con base en los criterios especificados por el usuario en el formulario.
 * Este método es crucial para interactuar con el servidor o la fuente de datos, solicitando la información relevante
 * según los parámetros formados y gestionando el estado de carga durante el proceso.
 *
 * @param {number} page - Especifica el número de página que se desea obtener, facilitando la paginación de los resultados.
 * @param {Function} onLoadingCallback - Callback invocado para indicar el inicio y el fin del proceso de carga, permitiendo
 *                                        mostrar indicadores visuales de carga en la interfaz de usuario.
 * @param {Function} searchCallback - Función callback que se ejecuta una vez se reciben los datos de la búsqueda.
 *                                     Se le pasan los resultados obtenidos para su procesamiento y visualización.
 * @param {Function} prepareParams - Función para preparar los parámetros de la búsqueda basándose en los valores actuales
 *                                    del formulario. Establece cómo se deben enviar los criterios de búsqueda al servidor.
 *
 * Este método encapsula la lógica para realizar solicitudes asíncronas de búsqueda, gestionando los estados de carga
 * y asegurando una correcta comunicación y representación de los datos obtenidos.
 */
export const search = (page = 1, onLoadingCallback, searchCallback, prepareParamsGlobal) => {
    //Callback para controlar cuando esta cargando
    if (onLoadingCallback)
        onLoadingCallback(true)

    const params = prepareParamsGlobal(page)
    new RequestDatosColegio().search(params, (res) => {
        if (searchCallback)
            searchCallback(res.items.data, res.items.total, page, params)
    }, () => {
        searchCallback([], 0, page, params)
    })
}