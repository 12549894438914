import React from 'react'
import { Text, H3 } from 'components/texts'
import { Button } from 'components/forms/components'
import { Groups as GroupIcon } from '@mui/icons-material'
import { FormControl, Radio, FormControlLabel, RadioGroup, Stack, Grid } from '@mui/material'

/**
 * //TODO: 13/03/24 - De momento  pongo disabled el Radio de Grupo de CL, ya que no tiene funcionalidad.
 * 
 * @param {*} tipoGrupo 
 * @param {*} setTipoGrupo 
 * @param {*} grupo 
 * @param {*} setOpen 
 * 
 * @returns {JSX.Element}
 */
export default function GroupInfo({ tipoGrupo, setTipoGrupo, grupo, setOpen }) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <Stack display="flex" alignItems="start" spacing={2}>
                    <H3>Filtrar por tipo de grupo: </H3>
                    <FormControl>
                        <RadioGroup onChange={({ target }) => setTipoGrupo(target.value)} value={tipoGrupo} row aria-labelledby="tipo-grupo" name="tipo-grupo">
                            <FormControlLabel value="academia" control={<Radio />} label="Grupo de CL" disabled />
                            <FormControlLabel value="colegio" control={<Radio />} label="Grupo de Colegios" />
                        </RadioGroup>
                    </FormControl>
                    <Button variant={"outlined"} startIcon={<GroupIcon />} onClick={() => setOpen(true)}>Buscar grupo</Button>
                </Stack>
            </Grid>

            <Grid item xs={8}>
                {grupo ?
                    (
                        <Stack spacing={2}>
                            <H3>Info del grupo</H3>
                            <Stack display={"flex"} flexDirection={"row"} columnGap={1}>
                                <Text fontWeight="bold">Clave:</Text>
                                <Text> {grupo.clave ?? ""}</Text>
                            </Stack>

                            <Stack display={"flex"} flexDirection={"row"} columnGap={1}>
                                <Text fontWeight="bold">Curso escolar:</Text>
                                <Text> {grupo.curso_escolar?.valor ?? ""}</Text>
                            </Stack>

                            <Stack display={"flex"} flexDirection={"row"} columnGap={1}>
                                <Text fontWeight="bold">Profesor:</Text>
                                <Text> {(grupo.profesor_actual?.nombre ?? "") + " " + (grupo.profesor_actual?.apellido1 ?? "") + " " + (grupo.profesor_actual?.apellido2 ?? "")}</Text>
                            </Stack>

                            <Stack display={"flex"} flexDirection={"row"} columnGap={1}>
                                <Text fontWeight="bold">Horario:</Text>
                                <Text> {grupo.horario?.horario_formatted ?? ""}</Text>
                            </Stack>

                            <Stack display={"flex"} flexDirection={"row"} columnGap={1}>
                                <Text fontWeight="bold">Colegio:</Text>
                                <Text> {grupo.colegio?.nombre ?? ""}</Text>
                            </Stack>
                        </Stack>
                    )
                    : null}
            </Grid>
        </Grid>
    )
}