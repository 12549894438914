import React, { Component } from 'react'
import { LogoutButton } from 'pages/components/headers/components'
import { MenuItem } from 'components/navigation'
import { employeeRoutes } from 'router/routes'
import { Home as InicioIcon } from '@mui/icons-material'
import { sidebars } from './constants'

export default class Main extends Component {

	/**
	 * Render
	 * 
	 * @returns {Component}
	 */
	render() {
		const { sidebar } = this.props
		return (
			<div className="app-sidebar-menu overflow-hidden flex-column-fluid pt-8">
				<div id="kt_app_sidebar_menu_wrapper" className="app-sidebar-wrapper hover-scroll-overlay-y my-5" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_logo,
 #kt_app_sidebar_footer" data-kt-scroll-wrappers="#kt_app_sidebar_menu" data-kt-scroll-offset="5px" data-kt-scroll-save-state="true" style={{ height: 915 }}>
					
					<div className="menu menu-column menu-rounded menu-sub-indention px-3" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">
						<MenuItem title="Inicio" to={employeeRoutes.HOME} icon={<InicioIcon />}/>
					</div>

					{sidebars[sidebar] ?? null}

					<div className="menu menu-column menu-rounded menu-sub-indention px-3" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">
						<div className="menu-item pt-5">
							<div className="menu-content">
								<span className="menu-heading fw-bold text-uppercase fs-7">Salir</span>
							</div>
						</div>

						<LogoutButton />
					</div>
				</div>
			</div>
		)
	}
}
