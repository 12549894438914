import React, { useEffect, useState } from 'react';
import { Grid, Box } from "@mui/material";
import { Line, GroupButtons } from "components/data_display";
import { Input, Select, Checkbox } from 'components/forms/components';
import { Text } from 'components/texts/index.js';
import Requests from '../Requests/Requests';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { ItemsSelect } from 'components/forms/components/Select/custom';

export default function ActividadesCurriculum({ isFormDisabled, empleado, register, errors, setValue, getValues }) {
    const [grupos, setGrupos] = useState({ "1": [], "2": [], "3": [] });
    const [caracteristicas, setCaracteristicas] = useState([]);
    const [principalesIdiomas, setPrincipalesIdiomas] = useState();
    const [curriculumInitial, setCurriculumInitial] = useState(null);

    useEffect(() => {
        if (empleado?.curriculum) {
            setCurriculumInitial(empleado?.curriculum);
        }
    }, [empleado?.curriculum]);

    const initGrupos = () => {
        const newGrupos = []

        caracteristicas.forEach((item) => {
            const catId = item.cat_car_curriculum_id;
            newGrupos[catId] ??= [];

            const curriculumCaracteristicas = empleado?.curriculum?.caracteristicas;

            const isSelected = curriculumCaracteristicas &&
                curriculumCaracteristicas.some((caracteristica) => (
                    caracteristica.cat_car_curriculum_id === catId &&
                    caracteristica.nombre === item.nombre &&
                    caracteristica.id === item.id
                ));

            newGrupos[catId].push({
                label: item.nombre,
                isSelected: isSelected,
                cat_car_curriculum_id: item.cat_car_curriculum_id,
                id: item.id,
            });
        });
        setGrupos(newGrupos);

    }

    // const getCaracteristicas = () => {
    //     new Requests().getCaracteristicas(setCaracteristicas, () => enqueueSnackbar(<Text>No se ha podido obtener el empleado.</Text>, { variant: "error" }))
    //     // setGrupos(caracteristicas)
    //     console.log('caracteristicas dentro')

    // };

    const getCaracteristicas = () => {
        new Requests().getCaracteristicas((data) => {
            if (!empleado?.curriculum) {
                const newGrupos = {
                    "1": data.filter(item => item.cat_car_curriculum_id === 1),
                    "2": data.filter(item => item.cat_car_curriculum_id === 2),
                    "3": data.filter(item => item.cat_car_curriculum_id === 3),
                };
                setGrupos(newGrupos);
            }
            setCaracteristicas(data);
        }, () => enqueueSnackbar(<Text>No se ha podido obtener el empleado.</Text>, { variant: "error" }))
    };

    const idiomas_ppl = (a) => {
        const principales = a.filter(objeto => objeto.principal === 1);
        const selectOptions = principales.map(idioma => ({
            value: idioma.id,
            label: idioma.nombre,
        }));
        setPrincipalesIdiomas(selectOptions);
    }

    useEffect(() => {
        if (caracteristicas.length > 0 && empleado?.curriculum?.caracteristicas) {
            initGrupos()
        }
    }, [caracteristicas, empleado?.curriculum])

    useEffect(() => {
        getCaracteristicas()
    }, []);

    return (
        <Grid container alignItems="center" direction={'row'} mt={4} ml={2}>
            <Grid item xs={12} mb={4}>
                <Checkbox name={"seleccionar"} label={"No Seleccionar"} className="mt-2" defaultChecked={empleado?.curriculum?.seleccionar ? true : false} register={register} disabled={isFormDisabled} />
            </Grid>

            <Grid container item xs={10}>
                <GroupButtons labels={grupos["1"]} propertyToShow={empleado?.curriculum ? "label" : "nombre"} setValue={setValue} register={register} name="datosActividadesCurriculum" disabled={isFormDisabled} />
            </Grid>

            <Grid container>
                <Grid item xs={12} mt={2}>
                    <Text>Actividades</Text>
                    <Line></Line>
                    <Box mt={2}>
                        <GroupButtons labels={grupos["2"]} propertyToShow={empleado?.curriculum ? "label" : "nombre"} register={register} setValue={setValue} name="actividades-curriculum" disabled={isFormDisabled} />
                    </Box>
                </Grid>

                <Grid item xs={4} mt={2}>
                    <Text>Idioma Principal</Text>
                    <Line></Line>
                    <Box mt={2}>
                        <Select
                            width={"60%"}
                            register={register}
                            errors={errors}
                            setValue={setValue}
                            getValues={getValues}
                            disabled={isFormDisabled}
                            items={principalesIdiomas}
                            defaultValue={empleado?.curriculum?.idioma_principal_id ?? null}
                            name={'idioma_principal_id'}
                            label={"idiomas principales"} />
                    </Box>
                </Grid>

                <Grid item xs={8} mt={2}>
                    <Text>Otros Idiomas</Text>
                    <Line />
                    <Box mt={2}>
                        <ItemsSelect
                            name={"idiomas_ids"} 
                            width={'43%'} 
                            type='chip' 
                            keyValue={"idiomas"}
                            register={register} 
                            onFetchedFinished={(a) => idiomas_ppl(a)}
                            errors={errors} 
                            defaultValue={empleado?.curriculum?.idiomasIds ?? []} 
                            searcheable={true} 
                            multiple={true}
                            disabled={isFormDisabled} />
                    </Box>
                </Grid>
            </Grid>

            <Grid container mt={4}>
                <Grid item xs={12}>
                    <Text>Otras Actividades</Text>
                    <Line></Line>
                    <Box mt={2.5}>
                        <GroupButtons labels={grupos["3"]} propertyToShow={empleado?.curriculum ? "label" : "nombre"} setValue={setValue} register={register} name="otras-curriculum" disabled={isFormDisabled} />
                    </Box>
                </Grid>
                <Grid item xs={12} mt={2.5}>
                    <Input
                        label="Otras"
                        width={'100%'}
                        name={"otras_actividades"}
                        defaultValue={empleado?.otras_actividades ?? ''}
                        register={register}
                        errors={errors}
                        disabled={isFormDisabled}
                        uppercase multiline minRows={4} maxRows={20} />
                </Grid>
            </Grid>
            <SnackbarProvider autoHideDuration={5000} />
        </Grid>
    )
}
