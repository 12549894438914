import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests.js'
import { useParams } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import { Container, Paper, Stack } from '@mui/material'
import { EditHeader } from 'pages/logged/admin/components'
import { SnackbarProvider } from 'notistack'
import { AlumnoFullItem } from 'components/data_display/users'
import React from 'react'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
import { DataFormTutoria } from 'components/forms/display'
import Skeleton from 'components/forms/display/tutoria/DataForm/skeleton.jsx'
import AlumnoSkeleton from './skeleton.jsx'
import { validationSchema } from 'components/forms/display/tutoria/DataForm/validation.js'
import { useTutoriaEditLogic, useTutoriaEditWrapperLogic } from 'hooks/index.js'

/**
 * Componente `Wrapper` actúa como un contenedor para la página de edición de tutoría.
 * Utiliza el hook `useWrapperLogic` para manejar la lógica relacionada con la carga y actualización
 * de la información de la tutoría basada en los parámetros de navegación.
 * 
 * Dentro de este componente, se gestiona el ciclo de vida de los datos de la tutoría, desde su carga inicial
 * hasta la actualización tras realizar cambios. También proporciona el contexto necesario para mostrar alertas
 * y manejar la navegación.
 * 
 * @returns {JSX.Element} Un fragmento React que encapsula el componente `EditTutoria`, pasándole
 * las props necesarias para su funcionamiento, como la información de la tutoría y las funciones
 * para actualizarla.
 */
const Wrapper = () => {
  const navigationParams = useParams()

  const { id_tutoria, tutoria, setIdTutoria, setTutoria, updateAlert, getTutoria } = useTutoriaEditWrapperLogic(new Requests(), navigationParams)

  return (
    <React.Fragment>
      <EditTutoria key={tutoria?.id ?? 0} tutoria={tutoria} id_tutoria={id_tutoria} setIdTutoria={setIdTutoria} setTutoria={setTutoria} updateAlert={updateAlert} onSaveFinish={() => getTutoria(true)} />

      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}
export default Wrapper

/**
 * Componente `EditTutoria` proporciona una interfaz para editar los detalles de una tutoría específica.
 * Incluye formularios para la edición y visualización de la información del alumno asociado.
 * 
 * @param {Object} props - Propiedades del componente, incluyendo los datos de la tutoría.
 */
function EditTutoria({ tutoria, id_tutoria, setIdTutoria, setTutoria, updateAlert, onSaveFinish }) {
  const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, control, reset, setValue } = useForm({ resolver: yupResolver(validationSchema) })

  const { headerRef, updateId, editTutoria } = useTutoriaEditLogic(new Requests(), updateAlert, setIdTutoria, reset, dirtyFields, id_tutoria, getValues, onSaveFinish)

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(editTutoria)}>
        <Stack direction="row" justifyContent={"space-between"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/", label: "Inicio" },
            { to: "./../../../", label: "Alumnos" },
            { to: "./../../", label: "Tutorias" },
            { to: "./..", label: "Tutoria del alumno #" + (tutoria?.id_alumno ?? "0000") },
            { to: "./", label: "Editar tutoria #" + (id_tutoria ?? "0000"), actualItem: true },
          ]} />

          <MoveButtons
            actualItemId={id_tutoria}
            beforeFetchCallback={() => setTutoria(null)}
            onUpdateActualItem={updateId} />
        </Stack>
        
        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 2 }}>
          <EditHeader title="Alumno de la tutoría" />

          <Line />

          {tutoria?.alumno ? <AlumnoFullItem alumno={tutoria.alumno} containerClassName="m-5" /> : <AlumnoSkeleton />}
        </Paper>

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <EditHeader
            ref={headerRef}
            title={"Editar tutoria"}
            item={tutoria} />

          <Line />

          {tutoria ? <DataFormTutoria
            tutoria={tutoria}
            register={register}
            setValue={setValue}
            errors={errors}
            control={control}
            isMounted
            hideButton /> : <Skeleton />}
        </Paper>

        
      </form>
      <SnackbarProvider autoHideDuration={3000} />
    </Container>
  )
}