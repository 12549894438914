import { H3, Text } from 'components/texts'
import React from 'react'
import { Grid, Stack } from '@mui/material'
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { Checkbox } from 'components/forms/components'
import Statics from 'objects/common/Statics'
import TableMesesPagados from './parts/TableMesesPagados'

/**
 * Formulario con los datos generales de la inscripcion a un colegio
 */
export default function PagoInscripcionColegioData({ inscripcion }) {

    return (
        <Grid container py={3}>
            <Grid container item xs={12} xl={7} py={3} spacing={2} display={"flex"} alignItems={"center"}>
                <Grid item xs={4}>
                    <Stack alignItems={"center"}>
                        <Text fontWeight={"bold"}>Forma pago</Text>
                        <Text>{inscripcion?.forma_pago?.valor ?? "Sin rellenar"}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={4}>
                    <Stack alignItems={"center"}>
                        <Text fontWeight={"bold"}>Tipo matrícula</Text>
                        <Text>{inscripcion?.tipo_matricula?.nombre ?? "Sin rellenar"}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={4}>
                    <Stack alignItems={"center"}>
                        <Text fontWeight={"bold"}>Cantidad matrícula</Text>
                        <Text>{inscripcion?.cantidad_matricula ?? "Sin rellenar"}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={4}>
                    <Stack alignItems={"center"}>
                        <Text fontWeight={"bold"}>Fecha de pago</Text>
                        <Text>{inscripcion?.fecha_pago_matricula ? Statics.formatDate(inscripcion.fecha_pago_matricula) : "Sin rellenar"}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={4}>
                    <Stack alignItems={"center"}>
                        <Text fontWeight={"bold"}>Tipo pago</Text>
                        <Text>{inscripcion?.tipo_pago?.nombre ?? "Sin rellenar"}</Text>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <Stack alignItems={"center"}>
                        <Text fontWeight={"bold"}>Cantidad</Text>
                        <Text>{inscripcion?.cantidad ?? "Sin rellenar"}</Text>
                    </Stack>
                </Grid>
            </Grid>

            <Grid container item xs={12} xl={5} style={{borderLeft: "1px solid #D9D9D9"}} p={3}>
                <Grid item xs={12}>
                    <H3>Puertas abiertas</H3>
                </Grid>

                <Grid item xs={4}>
                    <Checkbox label={"Incluido en SENECA"} defaultChecked={inscripcion?.incluido_seneca ? true : false} disabled />
                </Grid>

                <Grid item xs={4}>
                    <Checkbox label={"Aplicar subvención"} defaultChecked={inscripcion?.aplicar_subvencion ? true : false} disabled />
                </Grid>

                <Grid item xs={4} />

                <Grid item xs={4}>
                    <Checkbox label={"Subvencionado"} defaultChecked={inscripcion?.alumno?.subvencion_ppaa?.subvencionado ? true : false} disabled/>
                </Grid>

                <Grid item xs={4}>
                    <Checkbox label={"Comprobado"} defaultChecked={inscripcion?.alumno?.subvencion_ppaa?.comprobado ? true : false} disabled/>
                </Grid>

                <Grid item xs={4}>
                    <Checkbox label={"Definitiva"} defaultChecked={inscripcion?.alumno?.subvencion_ppaa?.subvencion_definitiva ? true : false} disabled/>
                </Grid>

                <Grid container mt={1} spacing={2}>
                    <Grid item xs={6}>
                        <ItemsSelect 
                            keyValue     = {"tipo_subvencion"}
                            defaultValue = {inscripcion?.alumno?.subvencion_ppaa?.id_tipo_subvencion ?? ""}
                            disabled />
                    </Grid>

                    <Grid item xs={6}>
                        <Stack>
                            <Text fontWeight={"bold"}>Fecha</Text>
                            <Text>{inscripcion?.alumno?.subvencion_ppaa?.fecha ? Statics.formatDate(inscripcion?.alumno?.subvencion_ppaa?.fecha) : "Sin rellenar"}</Text>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container mx={3} mt={5} pb={2} sx={{overflowX: "auto"}} className={"darker-scrollbar"}>
                <TableMesesPagados inscripcion={inscripcion} />
            </Grid>

            <Grid container px={3} mt={5}>
                <Stack>
                    <Text fontWeight={"bold"}>Observaciones de los pagos</Text>
                    <Text>{inscripcion?.observaciones_pagos ?? "Sin rellenar"}</Text>
                </Stack>
            </Grid>
        </Grid>
    )
}
