export const publicRoutes = {
  MAIN: "/*",
  HOME: "/",
  RECOVER_PASSWORD: "/recuperar_clave",
  RESET_PASSWORD: "/cambiar_clave/:token",
};

export const employeeRoutes = {
  MAIN: "/empleado/*",
  HOME: "/empleado/",
  /** ALUMNOS */
  LISTADO_ALUMNOS: "alumnos",
  ALUMNO_SINGLE: "alumnos/:id_alumno",
  ALUMNO_EDIT: "alumnos/:id_alumno/editar",
  ALUMNO_CREATE: "alumnos/crear",
  ALUMNO_PAGOS: "alumnos/pagos-alumnos",
  ALUMNO_PAGOS_EDITAR: "alumnos/pagos-alumnos/:id_pagos-alumnos/editar",
  ALUMNO_PAGOS_CREATE: "alumnos/pagos-alumnos/crear",

  /** HISTORICO */
  ALUMNOS_HISTORICO_SINGLE: "alumnos/historico",

  /** TUTORIAS */
  ALUMNOS_TUTORIAS: "alumnos/tutorias",
  ALUMNO_TUTORIAS: "alumnos/tutorias/:id_alumno",
  TUTORIA_SINGLE: "alumnos/tutorias/:id_tutoria",
  TUTORIA_EDIT: "alumnos/tutorias/:id_tutoria/editar",
  TUTORIA_CREATE: "alumnos/tutorias/crear",
  ALUMNO_TUTORIAS_EDITAR: "alumnos/tutorias/:id_alumno/:id_tutoria/editar",

  /** INCIDENCIAS */
  ALUMNOS_INCIDENCIAS: "alumnos/incidencias",
  ALUMNOS_INCIDENCIAS_CREATE: "alumnos/incidencias/crear",

  //ALUMNO_INCIDENCIAS: "alumnos/incidencias/:id_alumno",
  ALUMNO_INCIDENCIA_SINGLE: "alumnos/incidencias/:id_incidencia",
  ALUMNO_INCIDENCIAS_EDITAR: "alumnos/incidencias/:id_incidencia/editar",

  /** RESPONSABLES */
  LISTADO_RESPONSABLES: "alumnos/tutores",
  RESPONSABLE_EDITAR: "alumnos/tutores/:id_responsable/editar",
  RESPONSABLE_SINGLE: "alumnos/tutores/:id_responsable",
  RESPONSABLE_CREATE: "alumnos/tutores/crear",

  /** DATOS COLEGIO ALUMNO */
  DATOS_COLEGIO: "alumnos/datos-colegio",
  ALUMNO_DATOS_COLEGIO: "alumnos/datos-colegio/:id_datos_colegio",
  ALUMNO_DATOS_COLEGIO_CREATE: "alumnos/datos-colegio/crear",
  ALUMNO_DATOS_COLEGIO_EDITAR: "alumnos/datos-colegio/:id_datos_colegio/editar",

  /** LIBROS */
  LISTADO_LIBROS: "libros",
  LIBRO_CREATE: "libros/crear",
  LIBRO_SINGLE: "libros/:id_libro",
  LIBRO_EDIT: "libros/:id_libro/editar",
  LIBRO_GENERAR_ENVIO: "libros/generar-envios",
  LIBRO_GESTION_COBRO: "libros/gestion-cobros",
  LIBRO_LISTADOS: "libros/listados",
  LIBRO_DEVOLUCIONES: "libros/devolucion",


  /** COLEGIOS */
  LISTADO_COLEGIOS: "colegios",
  COLEGIO_CREATE: "colegios/crear",
  COLEGIO_SINGLE: "colegios/:id_colegio",
  COLEGIO_EDIT: "colegios/:id_colegio/editar",
  COLEGIO_GRUPOS: "colegios/grupos",
  COLEGIO_GRUPOS_CREAR: "colegios/grupos/crear",
  COLEGIO_GRUPOS_EDITAR: "colegios/grupos/:id_grupo/editar",
  COLEGIO_GRUPOS_SINGLE: "colegios/grupos/:id_grupo",

  COLEGIO_LISTADO_ACTIVIDADES: "colegios/actividades",
  CREATE_ACTIVIDADES_COLEGIO: "colegios/actividades/crear",
  EDIT_ACTIVIDADES_COLEGIO: "colegios/actividades/:id_actividad/editar",
  SINGLE_ACTIVIDADES_COLEGIO: "colegios/actividades/:id_actividad",

  COLEGIO_LISTADO_INSCRIPCIONES: "colegios/inscripciones",
  COLEGIO_INSCRIPCIONES_CREATE: "colegios/inscripciones/crear",
  COLEGIO_INSCRIPCIONES_EDITAR: "colegios/inscripciones/:id_inscripcion/editar",
  COLEGIO_INSCRIPCIONES_SINGLE: "colegios/inscripciones/:id_inscripcion",

  COLEGIO_HORARIOS: "colegios/horarios",

  COLEGIO_PAGOS_SINGLE: "colegios/pagos",

  /** EMPLEADOS */
  LISTADO_EMPLEADOS: "ficha-empleados",
  CREATE_EMPLEADO: "ficha-empleados/crear",
  SINGLE_EMPLEADO: "ficha-empleados/:id_empleado",
  EDIT_EMPLEADO: "ficha-empleados/:id_empleado/editar",

  //Fichajes
  EMPLEADOS_FICHAJE_HISTORIAL: "fichajes/historial",
  EMPLEADOS_FICHAJE_INCIDENCIAS: "fichajes/incidencias",

  //Ficha candidato
  EMPLEADOS_FICHA_CANDIDATOS_LIST: "ficha-candidato",
  EMPLEADOS_SINGLE_FICHA_CANDIDATO: "ficha-candidato/:id_candidato",
  EMPLEADOS_EDIT_FICHA_CANDIDATO: "ficha-candidato/:id_candidato/editar",
  EMPLEADOS_CREATE_FICHA_CANDIDATO: "ficha-candidato/crear",

  //Ficha empleado
  EMPLEADOS_FICHA_EMPLEADOS_LIST: "ficha-empleados",


  /** ACADEMIA */
  ACADEMIA_LISTADO_ACTIVIDADES: "academias/actividades",
  ACADEMIA_CREATE: "academias/actividades/crear",
  ACADEMIA_EDIT: "academias/actividades/:id_actividad/editar",
  ACADEMIA_ACTIVIDADES_DATA: "academias/actividades/:id_actividad",
  ACADEMIA_GENERAR_ENVIO: "academia/generar-envios",

  /** ACADEMIAS - AULAS */
  ACADEMIA_LISTADO_AULAS: "academias/aulas",
  ACADEMIA_AULA_CREATE: "academias/aulas/crear",
  ACADEMIA_AULA_EDIT: "academias/aulas/:id_aula/editar",
  ACADEMIA_AULA_SINGLE: "academias/aulas/:id_aula",

  /** ACADEMIAS - INSCRIPCIONES */

  ACADEMIA_LISTADO_INSCRIPCIONES: "academias/inscripciones",
  ACADEMIA_CREATE_INSCRIPCIONES: "academias/inscripciones/crear",
  ACADEMIA_SINGLE_INSCRIPCIONES: "academias/inscripciones/:id_inscripcion",
  ACADEMIA_EDIT_INSCRIPCIONES: "academias/inscripciones/:id_inscripcion/editar",

  /** ACADEMIAS - GRUPOS */
  ACADEMIA_LISTADO_GRUPOS: "academias/grupos",
  ACADEMIA_CREATE_GRUPOS: "academias/grupos/crear",
  ACADEMIA_EDIT_GRUPOS: "academias/grupos/:id_grupo/editar",
  ACADEMIA_SINGLE_GRUPOS: "academias/grupos/:id_grupo",

  /** CLASES PARTICULARES */
  CLASES_PARTICULARES: "clases-particulares",
  CLASES_PARTICULARES_CREATE: "clases-particulares/crear",
  CLASES_PARTICULARES_EDIT: "clases-particulares/:id_clase_particular/editar",
  CLASES_PARTICULARES_SINGLE: "clases-particulares/:id_clase_particular",

  /** SEGUIMIENTO CURSO */

  SEGUIMIENTO_CURSO: "seguimiento-curso",
  SEGUIMIENTO_CURSO_REPORT_TRIMESTRAL: "seguimiento-curso/report-trimestral",
  SEGUIMIENTO_CURSO_PAGOS_PPAA: "seguimiento-curso/actualizar-pagos-puertas-abiertas",
  SEGUIMIENTO_CURSO_COMUNICACIONES: "seguimiento-curso/comunicaciones",

  /** LISTADOS */
  LISTADOS: "listados",
  LISTADOS_ALUMNO: "listados/alumno",
  LISTADOS_ALUMNO_DATOS_ALUMNOS: "listados/alumno/datos-alumno",
  LISTADOS_ALUMNO_TUTORES: "listados/alumno/tutores",
  LISTADOS_ALUMNO_DATOS_COLEGIO: "listados/alumno/datos-colegio",
  LISTADOS_ALUMNO_INCIDENCIAS: "listados/alumno/incidencias",
};

export const alumnoRoutes = {
  MAIN: "/alumno/*",
  HOME: "/alumno/",
};

export const responsableRoutes = {};
