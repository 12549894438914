import React, { useState } from 'react'
import { columns } from './columns'
import { H3, Text } from 'components/texts'
import Statics from 'objects/common/Statics'
import { Datagrid } from 'components/data_display'
import { LoadingButton } from 'components/forms/components'
import { Stack } from '@mui/material'
import Requests from '../../Requests/Requests'
import { enqueueSnackbar } from 'notistack'

export default function DetallePagoGrid({ pago, setPago, onRowClick, handleSubmit, getValues }) {

    const [loading, setLoading] = useState(false)

    const saveChanges = () => {
        setLoading(true)

        const detallesEdited = pago?.detalles.filter(item => item.edit)
        if(detallesEdited.length != 0) {
            new Requests().editDetalles(detallesEdited, () => {
                for (const detalle of pago.detalles)
                    delete detalle.edit
                
                updateFacturacionColegio()
            }, (err) => {
                setLoading(false)
                enqueueSnackbar(<Text>{err}</Text>, { variant: "error" })
            })
        } else {
            updateFacturacionColegio(false)
        }
        
    }

    const updateFacturacionColegio = (fromDetalles = true) => {
        new Requests().updateFacturacionColegio(pago.id, getValues, () => {
            setPago(pago)
            setLoading(false)
            enqueueSnackbar(<Text>Se ha guardado correctamente</Text>, { variant: "success" })
        }, (res) => {
            setPago(pago)
            setLoading(false)
            enqueueSnackbar(<Text>{fromDetalles ? "Se han guardado los detalles corectamente pero ha habido un error al guardar la liquidación" : "Error: " + res}</Text>, { variant: "error" })
        })
    }

    const updateDetalle = ({id, field, value}) => {
        const index = pago.detalles.findIndex(item => item.id == id)
        if(index != -1) {
            pago.detalles[index][field] = value
            pago.detalles[index].edit   = true
        }
        
        setPago(Statics.copyObject(pago))
    }

    return (
        <React.Fragment>
            <Stack display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} mb={1}>
                <H3 className="m-0">Actividades:</H3>
                <LoadingButton size={"small"} loading={loading} disabled={!pago?.id} onClick={handleSubmit(saveChanges)}>Guardar cambios</LoadingButton>
            </Stack>
            
            <Datagrid
                rows               = {pago?.detalles ?? []}
                columns            = {columns()}
                pageSize           = {100}
                rowsPerPageOptions = {[100]}
                onCellEditCommit   = {updateDetalle}
                onRowClick         = {onRowClick}
                rowHeight          = {40}
                heightWithContent  = {500}/>
        </React.Fragment>
    )
}