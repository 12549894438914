import { SearcheableSelect, Select } from 'components/forms/components'
import React, { useState, useEffect, useRef } from 'react'
import { useQuery } from "@tanstack/react-query"
import { Text } from 'components/texts'
import { API_URL } from 'constants/Api'
import { v4 as uuid } from 'uuid'
import options from './options'
import store from 'store/store'
import axios from 'axios'

const getFinalDataArray = (obj, path) => {
    const keys = path.split('.')
    let value = obj

    if (path == "")
        return value

    for (let key of keys) {
        value = value[key]
        if (value === undefined)
            break
    }

    return value
}

/**
 * Esta función podrá ser llamada para forzar la recarga de los datos dentro de ItemsSelect
 * 
 * @returns {Object}
 */
export const useItemsSelectDataReload = () => {
    const [key, setKey] = useState(0)
    const reload = () => setKey(prevKey => prevKey + 1)
    return { key, reload }
}

const ItemsSelect = ({
    register,
    defaultValue,
    className,
    name,
    reloadKey,
    errors,
    label,
    width,
    disabled,
    initialLabel,
    keyValue,
    wantToReset,
    url,
    renderItem,
    initialOption,
    initialOptionByLabel,
    onDefaultValueLoaded,
    onFetchedFinished = () => { },
    onChange,
    goToPreviousOption,
    setValue,
    errorLabel,
    allOption,
    clearOption,
    clearOptionLabel = 'Borrar',
    updateValue = { update: false, value: 0 },
    params,
    searcheable = false,
    multiple = false,
    type = "standard",
    setClear = () => {},
}) => {
    const [items, setItems] = useState([{ value: '', label: initialLabel ?? 'Cargando...' }])
    const [rawItems, setRawItems] = useState([])

    const selectRef = useRef()

    const getData = async (url) => {
        const { userState } = store.getState()
        return axios.get(`${API_URL}/${url}`, { params, responseType: 'json', headers: { Authorization: `Bearer ${userState.token}` } }).then(response => { return response.data })
    }

    const query = useQuery({
        queryKey: [options[keyValue] ? options[keyValue].queryKey : ""],
        queryFn: () => getData(options[keyValue] ? options[keyValue].url : url),
        enabled: true
    })

    // * USADO PARA FIXEAR ERROR AL MOSTRAR COLEGIOS EN SELECT DE DATOS-COLEGIO
    // Efecto para actualizar items cuando la query trae nuevos datos
    useEffect(() => {
        if (query.isFetched && !query.isError) {
            const finalDataArray = getFinalDataArray(query.data, options[keyValue].arrayName);
            const newItems = finalDataArray.map(item => ({
                value: item.id,
                label: item[options[keyValue].campoName],
            }));
            // Agrega opciones adicionales aquí como 'clearOption' o 'allOption' si son necesarias
            setItems(newItems);
        } else if (query.isError) {
            setItems([{ value: '', label: options[keyValue].errorLabel }]);
        }
    }, [query.data, query.isFetched, query.isError, keyValue, clearOption])

    const onFetched = (res) => {
        const arrayName = options[keyValue].arrayName
        const campoName = options[keyValue].campoName

        const finalDataArray = getFinalDataArray(res, arrayName)

        const fetchedItems = renderItem ? finalDataArray.map(renderItem) : finalDataArray.map(item => ({ value: item.id, label: item[campoName] }))

        if (allOption)
            fetchedItems.unshift({ value: 0, label: <Text fontWeight={"bold"}>{options[keyValue].allLabel ?? "Todos"}</Text> })

        if (clearOption) {
            // fetchedItems.unshift({ value: null, label: 'clearOptionLabel' })
            selectRef.current.setValue(clearOptionLabel, () => { // Establece el valor en 'Unsigned'
                if (onChange) onChange({ target: { value: null } }); // Emitir evento onChange si es necesario
            });
            setClear(false)
        }


        if (initialOption !== undefined) {
            const value = fetchedItems[initialOption].value
            selectRef.current.setValue(value, () => {
                if (setValue) setValue(name ?? "", value)
                if (onChange) onChange({ target: { value } })
            })
        }

        if (initialOptionByLabel !== undefined) {
            const item = fetchedItems.find(item => item.label == initialOptionByLabel)
            if (item) {
                selectRef.current.setValue(item.value, () => {
                    if (setValue) setValue(name ?? "", item.value)
                    if (onChange) onChange({ target: { value: item.value } })
                })
            }
        }
        setItems(fetchedItems)
        setRawItems(finalDataArray)
    }

    const onError = () => {
        setItems([{ value: "", label: errorLabel ?? options[keyValue].errorLabel ?? "" }])
        setRawItems([])
    }

    useEffect(() => {
        const { value, update } = updateValue
        if (update) {
            selectRef.current.setValue(value, () => {
                if (setValue) setValue(name ?? "", value)
                if (onChange) onChange({ target: { value: value } })
            })
        }
    }, [updateValue.update])

    useEffect(() => {
        if (query.isFetched && !query.isError) {
            onFetched(query.data)
            onFetchedFinished(query.data)
        }
        if (query.isError) onError()
        if (!query.isFetched && !query.isFetching) query.refetch()
    }, [query.isFetched, clearOption])

    useEffect(() => {
        if (goToPreviousOption)
            selectRef.current.goToPreviousOption()
    }, [goToPreviousOption])

    const onChangeSelect = (event) => {
        if (onChange) {
            const itemSelected = rawItems.find(item => item.id == event.target.value)
            onChange(event, itemSelected)
        }
    }

    /**
     * Efecto para recargar datos cuando reloadKey cambie
     * Sirve para que todos los datos estén sincronizados, y que no haya datos recién creados que no aparezca en alguna otra parte de la aplicación
     */
    useEffect(() => {
        if (!query.isFetching)
            query.refetch()
    }, [reloadKey])

    const labelId = name + "_select-id-" + uuid()
    const SelectToRender = searcheable ? SearcheableSelect : Select
    return (
        <SelectToRender
            ref={selectRef}
            width={width ?? "100%"}
            items={items}
            className={className}
            disabled={disabled}
            defaultValue={defaultValue}
            onDefaultValueLoaded={onDefaultValueLoaded}
            register={register}
            onChange={onChangeSelect}
            name={name}
            errors={errors}
            labelId={labelId}
            wantToReset={wantToReset}
            label={label ?? options[keyValue]?.label ?? ""}
            multiple={multiple}
            type={type}
            native />
    )
}

export default ItemsSelect