import { Grid, Skeleton } from "@mui/material"

export default function SkeletonClasesParticularesLibros() {
    return (
        <Grid container p={3}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Skeleton variant="rounded" width={"20%"} height={25} />
                </Grid>

                <Grid item xs={8}>
                    <Skeleton variant="rounded" width={"100%"} height={25} />
                </Grid>


                <Grid item xs={2}>
                    <Skeleton variant="rounded" width={"100%"} height={25} />
                </Grid>

                <Grid item xs={2}>
                    <Skeleton variant="rounded" width={"100%"} height={25} />
                </Grid>

                <Grid item xs={2}>
                    <Skeleton variant="rounded" width={"100%"} height={25} />
                </Grid>

                <Grid item xs={3}>
                    <Skeleton variant="rounded" width={"100%"} height={25} />
                </Grid>

                <Grid item xs={3}>
                    <Skeleton variant="rounded" width={"100%"} height={25} />
                </Grid>

                <Grid item xs={2}>
                    <Skeleton variant="rounded" width={"100%"} height={25} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rounded" width={"100%"} height={25} />
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={5}>
                <Grid item xs={12}>
                    <Skeleton variant="rounded" width={"20%"} height={25} />
                </Grid>

                <Grid item xs={8}>
                    <Skeleton variant="rounded" width={"100%"} height={25} />
                </Grid>


                <Grid item xs={2}>
                    <Skeleton variant="rounded" width={"100%"} height={25} />
                </Grid>

                <Grid item xs={2}>
                    <Skeleton variant="rounded" width={"100%"} height={25} />
                </Grid>

                <Grid item xs={2}>
                    <Skeleton variant="rounded" width={"100%"} height={25} />
                </Grid>

                <Grid item xs={3}>
                    <Skeleton variant="rounded" width={"100%"} height={25} />
                </Grid>

                <Grid item xs={3}>
                    <Skeleton variant="rounded" width={"100%"} height={25} />
                </Grid>

                <Grid item xs={2}>
                    <Skeleton variant="rounded" width={"100%"} height={25} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rounded" width={"100%"} height={25} />
                </Grid>
            </Grid>
        </Grid>
    )
}