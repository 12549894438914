import React, { useContext, useRef, useState } from 'react'
import { COLOR } from 'theme/Colors'
import Button from '@mui/material/Button'
import { employeeRoutes } from 'router/routes'
import { useNavigate } from "react-router-dom"
import ButtonGroup from '@mui/material/ButtonGroup'
import { ChangesContext } from 'contexts/ChangesContext'
import { WarningNotSavedModal } from 'components/modals'

const buttons = [
  {label: "Alumnos",               route: employeeRoutes.LISTADO_ALUMNOS,                selectedIf: ['alumnos']},
  {label: "Academia",              route: employeeRoutes.ACADEMIA_LISTADO_INSCRIPCIONES, selectedIf: ['academias']},
  {label: "Colegios",              route: employeeRoutes.COLEGIO_LISTADO_INSCRIPCIONES,  selectedIf: ['colegios']},
  {label: "Empleados",             route: employeeRoutes.LISTADO_EMPLEADOS,              selectedIf: ['ficha-empleados', 'fichajes', 'ficha-candidato']},
  {label: "Seguimiento del curso", route: employeeRoutes.SEGUIMIENTO_CURSO,              selectedIf: ['seguimiento-curso']},
  {label: "Libros",                route: employeeRoutes.LISTADO_LIBROS,                 selectedIf: ['libros']},
  {label: "Clases Particulares",   route: employeeRoutes.CLASES_PARTICULARES,            selectedIf: ['clases-particulares']},
  {label: "Listados",              route: employeeRoutes.LISTADOS,                       selectedIf: ['listados']},
]

export default function AdminHeader () {
  const modalRef = useRef()

  const [nextRoute, setNextRoute] = useState(null)

  const { hasChanges, updateChanges } = useContext(ChangesContext) || { hasChanges: false }

  const navigate = useNavigate()
  
  const onClick = ({route}) => {
    if(hasChanges) {
      setNextRoute(route)
      modalRef.current.modal.open()
    } else {
      navigate(route)
    }
  }

  const buttonWidth = 100 / buttons.length
  const elementosURL = window.location.pathname.split("/");
  const sx = { width: `${buttonWidth}%`, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, fontSize: "1.1rem"}
  return (
    <div id="kt_app_header" className={"app-header app-header-custom"}>
      <ButtonGroup variant="contained" color={"standard"} aria-label="outlined standard button group" sx={{ height: "100%", width: "100%" }}>
        {buttons.map((button, index) => {
          
          const style = { borderColor: COLOR.standard.main }

          if(button.selectedIf.includes(elementosURL[2]))
            style.backgroundColor = COLOR.standard['super-dark']

          return <Button key={index} style={style} onClick={() => onClick(button)} sx={sx}>{button.label}</Button>
        })}
      </ButtonGroup>

      <WarningNotSavedModal 
        ref        = {modalRef} 
        onGoBack   = {() => {modalRef.current.modal.close()}}
        onContinue = {() => {
          modalRef.current.modal.close()
          updateChanges(false)
          if(nextRoute)
            navigate(nextRoute)
        }}/>
    </div>
  )
}
