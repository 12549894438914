import React, { Component } from 'react'
import { AlumnoListSelector } from '../../../../custom_selectors'
import Modal from '../../../Modal.jsx'

/**
 * Modal con el formulario de busqueda del alumno
 */
export default class SearchAlumnoModal extends Component {

    constructor(props) {
        super(props)
    }


    /**
     * Callback pra cuando se elija un usuario
     * 
     * @param {Object} alumno 
     */
    onSelectedCallback = (alumno) => {
        if(this.props.onSelectedCallback)
            this.props.onSelectedCallback(alumno)
    }
    

    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <Modal ref={(ref) => this.modal = ref} title={this.props.title ?? "Buscar alumno"}>
                <AlumnoListSelector onSelectedCallback={this.onSelectedCallback} relations={this.props.relations}/>
            </Modal>
        )
    }
}
