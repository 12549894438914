import RequestMaster from '../RequestMaster'

export default class RequestDetalleRegistroActividad extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/detalle-registro-actividad'
    }


    /**
     * Hace la busqueda de los detalles de las actividades
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    search(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.get(path, params, callback, callbackError, callbackFinally)
    }
    

    /**
     * Hace una actualización de muchos detalles de actividades
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    updateMultiple(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "updateMultiple"
        this.put(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Hace una actualización/creacion de muchos detalles de actividades
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    storeUpdateMultiple(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "multiple"
        this.post(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Borra varios detalles de una actividad
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    deleteMultiple(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "multiple"
        this.delete(path, params, callback, callbackError, callbackFinally)
    }
    

    /**
     * Hace la peticion para importar las actividades del colegio que no estén importadas este año
     * 
     * @param {Object}   params 
     * @param {Function} callback 
     * @param {Function} callbackError 
     * @param {Function} callbackFinally 
     */
    importActividades(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "importActividades"
        this.post(path, params, callback, callbackError, callbackFinally)
    }

    
    /**
     * Hace la peticion para importar las actividades del colegio que no estén importadas este año del año anterior
     * 
     * @param {Object}   params 
     * @param {Function} callback 
     * @param {Function} callbackError 
     * @param {Function} callbackFinally 
     */
    importActividadesCursoAnterior(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "importActividadesCursoAnterior"
        this.post(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Hace la peticion para importar las actividades del colegio que no estén importadas este año del año anterior
     * 
     * @param {Object}   params 
     * @param {Function} callback 
     * @param {Function} callbackError 
     * @param {Function} callbackFinally 
     */
    getNewActividades(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "newActividades"
        this.get(path, params, callback, callbackError, callbackFinally)
    }

    
    /**
     * Hace la peticion para importar las actividades del colegio que no estén importadas este año del año anterior
     * 
     * @param {Object}   params 
     * @param {Function} callback 
     * @param {Function} callbackError 
     * @param {Function} callbackFinally 
    */
    getActividadesCursoAnterior(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "actividadesCursoAnterior"
        this.get(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Hace el edit del usuario
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    edit(id, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "" + id
        this.put(path, params, callback, callbackError, callbackFinally)
    }
}
