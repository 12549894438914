import Statics from 'objects/common/Statics'
import { RequestGrupoAcademia, RequestGrupoEmpleado } from "objects/requests"

export default class Requests {

    /**
     * Hace la petición para crear el grupo-academia
     * 
     * @param {Object}   values 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createGrupo(values, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()
        formData.append("clave_grupo", values.clave.toUpperCase())
        formData.append("curso_escolar_id", values.curso_escolar_id)
        formData.append("registro_actividad_id", values.registro_actividad_id)
        formData.append("nivel_grupo_id", values.nivel_grupo_id ?? "")
        formData.append("num_grupo", values.num_grupo ?? "")
        formData.append("fecha_inicio", Statics.datePickerTimeFormat(values.fecha_inicio ?? ""))
        formData.append("fecha_fin", Statics.datePickerTimeFormat(values.fecha_fin ?? ""))
        formData.append("min_alumnos", values.min_alumnos ?? "")
        formData.append("max_alumnos", values.max_alumnos ?? "")
        formData.append("observaciones_grupo", values.observaciones_grupo ? values.observaciones_grupo.toUpperCase() : "")

        // IDIOMAS
        formData.append("libro_id", values.libro_id ?? "")
        formData.append("usar_libro_asociado", values.usar_libro_asociado ? 1 : 0)

        if (values?.programacion_1t && values?.programacion_1t.length > 0) {
            formData.append("programacion_1t_enviada", values?.programacion_1t[0] ?? "")
            formData.append("programacion_2t_enviada", values?.programacion_1t[0] ?? "")
            formData.append("programacion_3t_enviada", values?.programacion_1t[0] ?? "")
        }

        formData.append("usar_progress_sheet", values.usar_progress_sheet ? 1 : 0)

        new RequestGrupoAcademia().create(formData, callbackOk, callbackError)
    }

    // editLibro(id_libro, getValues, callbackOk = () => { }, callbackError = () => { }) {
    //     const formData = new FormData()
    //     formData.append("nombre", getValues("nombre").toUpperCase())
    //     formData.append("isbn", getValues("isbn").toUpperCase())
    //     formData.append("id_editorial", getValues("id_editorial"))
    //     formData.append("abreviatura", getValues("abreviatura").toUpperCase())
    //     formData.append("activo", getValues("activo") ? 1 : 0)
    //     formData.append("id_libro", getValues("id_libro"))
    //     formData.append("precio_editorial", getValues("precio_editorial"))
    //     formData.append("pvp", getValues("pvp"))

    //     formData.append("programacion_1t", getValues("programacion_1t")[0] ?? "")
    //     formData.append("programacion_2t", getValues("programacion_2t")[0] ?? "")
    //     formData.append("programacion_3t", getValues("programacion_3t")[0] ?? "")

    //     new RequestLibro().put(id_libro, formData, callbackOk, callbackError)
    // }

    /**
     * Hace la petición para crear el empleado del grupo
     * 
     * @param {Object}   values 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createEmpleadoGrupo(grupoId, empleadoId, callbackOk = () => { }, callbackError = () => { }) {
        if (empleadoId) {
            const formData = new FormData ()
            formData.append("grupo_id", grupoId)
            formData.append("grupo_type", "grupo-academia")
            formData.append("empleado_id", empleadoId)
            formData.append("actual", 1) // Lo pongo en 1 para que aparezca reflejado el profesor en front, si no el scope de la API no lo devuelve. Se podria prescindir de este campo y usar algo como la fecha de baja, para ver si es nulo que es actual, y si tiene valor datetime que se haya sobrepasado ponerlo como no actual

            new RequestGrupoEmpleado().create(formData, callbackOk, callbackError)
        } else {
            callbackOk()
        }
    }
}
