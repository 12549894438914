// import Statics from 'objects/common/Statics'
import { useNavigate } from 'react-router'
import { ChangesContext } from 'contexts/ChangesContext'
import { useRef, useContext } from 'react'
import { useCheckDirtyFields } from 'hooks/index.js'
import { useAlert } from 'hooks'
import Statics from 'objects/common/Statics.js'
/**
 * Hook `useLogic` encapsula la lógica específica para la edición de los datos de una tutoría,
 * incluyendo la interacción con el API para actualizar la información y la navegación.
 * 
 * @param {Function} updateAlert - Función para mostrar alertas en la UI.
 * @param {Function} setIdTutoria - Función para actualizar el ID de la tutoría en el estado.
 * @param {Function} reset - Función de react-hook-form para resetear el formulario.
 * @param {Object} dirtyFields - Campos modificados del formulario.
 * @param {string} id_tutoria - ID de la tutoría actual.
 * @param {Function} getValues - Función de react-hook-form para obtener los valores del formulario.
 * @param {Function} onSaveFinish - Callback para ejecutar tras guardar los cambios con éxito.
 * @returns {Object} - Referencias y funciones para la edición de la tutoría.
 */
export default function useLogic(requests, updateAlert, setIdTutoria, reset, dirtyFields, id_tutoria, getValues, onSaveFinish) {
    const navigate = useNavigate()

    const { updateChanges } = useContext(ChangesContext)

    const headerRef = useRef()

    useCheckDirtyFields(dirtyFields, updateChanges)

    /**
     * Gestiona la actualización de la UI y muestra alertas tras realizar operaciones.
     */
    // const updateHeaderAndAlert = ({ message, severity = "error" }, loading = false) => {
    //     headerRef.current.button.setLoading(loading)
    //     updateAlert(message, severity)
    // }

    /**
     * Ejecuta la petición para editar los datos de la tutoría y maneja la respuesta.
     */
    const editTutoria = () => {
        headerRef.current.button.setLoading(true)

        requests.editTutoria(id_tutoria, getValues, (res) => {
            // updateHeaderAndAlert({ message: "Se ha editado la tutoría correctamente.", severity: "success" })
            reset({}, { keepValues: true })
            updateChanges(false)
            updateId(id_tutoria) //Añadir la ejecución de la función que updateaba el id y hacia el navigate para poder usar la redirreción existente
            updateAlert('Se ha editado correctamente. Redirigiendo...', 'success')
            onSaveFinish()
        }, (res) => {
            // updateHeaderAndAlert({ message: res })
            updateAlert('Ha habido un error al editar la incidencia.')
        })
    }

    /**
     * Actualiza el ID de la tutoría y navega a la ruta de edición correspondiente.
     */
    const updateId = async (id) => {
        // navigate(`../alumnos/tutorias/${id}`)
        await Statics.delay(1500).then(() => { navigate(`../alumnos/tutorias/${id}`) })
        setIdTutoria(id)
    }

    return { headerRef, updateId, editTutoria }
}