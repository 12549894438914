import moment from 'moment'
import Statics from 'objects/common/Statics'
import * as Yup from 'yup'

const RegexTestNivel = /^[A-Za-z]\d{2}$/

export const validation = {
    alumno_id: Yup.string().required('Debe indicar el alumno'),
    registro_actividad_id: Yup.string().required('La actividad es obligatoria'),
    curso_escolar_id: Yup.string().required('El curso escolar es obligatorio'),
    forma_recogida_id: Yup.string().required('La forma de recogida es obligatoria'),
    forma_conocimiento_id: Yup.string().required('La forma de recogida es obligatoria'),
    preferencia_horario_id: Yup.string().required('La preferencia de horario es obligatoria'),
    nivel: Yup.string().matches(RegexTestNivel, { message: 'Formato inválido. Asegúrate de que comience con una letra seguida de exactamente dos dígitos, como "A01" o "B99".', excludeEmptyString: true }),
    grupo_academia_id: Yup.string().typeError('El grupo de la academia debe ser tipo texto'),
    motivo_baja_id: Yup.string().when('fecha_baja', {
        is: (value) => !!value,
        then: Yup.string().required('Debes indicar el motivo de la baja.'),
    }),
    fecha_inscripcion: Yup.string().required('Fecha de inscripción es requerida'),
    fecha_incorporacion: Yup.string().nullable()
        .test("is-greater-than-fecha_inscripcion", "La fecha de incorporación no puede ser menor que la fecha de inscripción", function (value) {
            if (!value || !this.parent.fecha_inscripcion)
                return true

            let fechaInscripcion = this.parent.fecha_inscripcion
            let fechaIncorporacion = value
            if (!Statics.checkDateFormat(fechaInscripcion)) {
                fechaInscripcion = new Date(fechaInscripcion)
                fechaInscripcion.setDate(fechaInscripcion.getDate() + 1);
                fechaInscripcion = fechaInscripcion.toISOString().split('T')[0]
            }

            if (fechaIncorporacion && !Statics.checkDateFormat(fechaIncorporacion)) {
                fechaIncorporacion = new Date(fechaIncorporacion)
                fechaIncorporacion.setDate(fechaIncorporacion.getDate() + 1);
                fechaIncorporacion = fechaIncorporacion.toISOString().split('T')[0]
            }

            return moment(fechaInscripcion, "YYYY-MM-DD").isSameOrBefore(moment(fechaIncorporacion, "YYYY-MM-DD"));
        }),

    fecha_baja: Yup.string().nullable()
        .test("is-greater-than-fecha_incorporacion", "La fecha de baja no puede ser menor que la fecha de incorporación", function (value) {
            if (!value || !this.parent.fecha_incorporacion)
                return true

            let fechaIncorporacion = this.parent.fecha_incorporacion
            let fechaBaja = value
            if (!Statics.checkDateFormat(fechaBaja)) {
                fechaBaja = new Date(fechaBaja)
                fechaBaja.setDate(fechaBaja.getDate() + 1);
                fechaBaja = fechaBaja.toISOString().split('T')[0]
            }

            if (fechaIncorporacion && !Statics.checkDateFormat(fechaIncorporacion)) {
                fechaIncorporacion = new Date(fechaIncorporacion)
                fechaIncorporacion.setDate(fechaIncorporacion.getDate() + 1);
                fechaIncorporacion = fechaIncorporacion.toISOString().split('T')[0]
            }

            return moment(fechaIncorporacion, "YYYY-MM-DD").isSameOrBefore(moment(fechaBaja, "YYYY-MM-DD"));
        }),
    matricula: Yup.number()
        .typeError('El valor debe ser un número')
        .positive('El valor debe ser positivo')
        .min(0, 'El valor mínimo permitido es 0')
        .max(99999.99, 'El valor máximo permitido es 99999.99')
        .test('decimal-places', 'El número debe tener máximo dos decimales', value => {
            if (!value) return true; // Permitir valores nulos o vacíos
            const decimalPlaces = (value.toString().split('.')[1] || '').length;
            return decimalPlaces <= 2;
        }),
}

export const validationSchema = Yup.object().shape(validation)