import { yupResolver } from "@hookform/resolvers/yup"
import { Grid } from "@mui/material"
import { useForm } from "react-hook-form"
import { ColorInput, Input, LoadingButton } from "components/forms/components"
import { validationSchema } from './validation'

export default function DataFormEvent({ evento, btnText, onClick = () => { }, loading = false, userId }) {
    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) })

    return (
        <Grid container p={5} spacing={3}>
            <Grid item xs={6}>
                <Input label="Nombre del evento" register={register} errors={errors} className="w-100" name={"title"} defaultValue={evento?.title ?? ""} uppercase />
            </Grid>

            <Grid item xs={2}>
                <Input type={"time"} label="Hora del inicio" register={register} errors={errors} className="w-100" name={"hour"} InputLabelProps={{ shrink: true }} defaultValue={evento?.hour ?? ""} />
            </Grid>

            <Grid item xs={2}>
                <Input type={"time"} label="Hora fin" register={register} errors={errors} className="w-100" name={"end_hour"} InputLabelProps={{ shrink: true }} defaultValue={evento?.end_hour ?? ""} />
            </Grid>

            <Grid item xs={2}>
                <ColorInput name={"color"} register={register} errors={errors} defaultValue={evento?.color ?? null} width={"100%"} />
            </Grid>

            <Grid item xs={12}>
                <Input label="Descripción del evento" register={register} errors={errors} className="w-100" name={"description"} defaultValue={evento?.description ?? ""} multiline minRows={6} maxRows={10} />
            </Grid>

            <Input style={{ display: "none" }} register={register} defaultValue={userId} name="empleado_id" />

            <Grid item xs={12} display={"flex"} justifyContent={"end"}>
                <LoadingButton onClick={handleSubmit(onClick)} loading={loading}>
                    {btnText ?? ""}
                </LoadingButton>
            </Grid>
        </Grid>
    )
}