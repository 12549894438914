import { v4 as uuid } from 'uuid'
import React, { Component } from 'react'
import { Select } from 'components/forms/components'
import { RequestRegistroActividad } from 'objects/requests'

/**
 * RegistroActividadColegioSelect. Un select que se encarga de la descarga de los cursos escolares disponibles en el servidor
 */
export default class RegistroActividadColegioSelect extends Component {

    /**
     * Constructor
     * 
     * @param {Object} props 
     */
    constructor(props) {
        super(props)

        this.state = {
            actividades: [],
            actividadesToShow: [{ value: "", label: this.props.initialLabel ?? "Elige un colegio y un curso escolar para cargar las actividades." }]
        }
    }

    componentDidMount(){
        if(this.props.id_colegio && this.props.id_curso_escolar)
            this.getActividadesColegio(this.props.id_colegio, this.props.id_curso_escolar)
    }

    /**
     * ComponentDidUpdate
     */
    componentDidUpdate(prevProps) {
        if(this.props.wantToReset && !prevProps.wantToReset)
            this.setState({actividades: []})

        if(this.props.id_colegio && this.props.id_curso_escolar && (this.props.id_colegio != prevProps.id_colegio || this.props.id_curso_escolar != prevProps.id_curso_escolar))
            this.getActividadesColegio(this.props.id_colegio, this.props.id_curso_escolar)
    }

    //-------------------------------------------------------
    //-------------------- CLASS FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Callback para cuando se cambia de opcion en el selector
     * 
     * @param {Object} event
     */
    onChange = (event) => {
        if (this.props.onChange) {
            const itemSelected = this.state.actividades.find(item => item.id == event.target.value)
            this.props.onChange(event, itemSelected)
        }
    }

        
    /**
     * Hace la peticion para obtener las actividades de un colegio en un curso escolar
     * 
     * @param {Int} id_colegio
     * @param {Int} id_curso_escolar
     */
    getActividadesColegio = (id_colegio, id_curso_escolar = null) => {
        const params = { entity_id: id_colegio, entity_type: 'colegio', limit: 100 }
        if(id_curso_escolar)
            params.curso_escolar_id = id_curso_escolar
    
        new RequestRegistroActividad().search(params, (res) => {
            const actividadesToShow = res.actividades.data.map(item => ({ value: item.id, label: item.nombre }))
            this.setState({ actividades: res.actividades.data, actividadesToShow })
        }, (res) => {
            this.setState({ actividadesToShow: [{ value: "", label: "Este colegio no tiene ninguna actividad creada." }], actividades: []})
        })
    }

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     * 
     * @returns {Component}
     */

    render() {
        const labelId = "registro_actividad_colegio_select-id-" + uuid()
        return (
            <Select
                ref          = {(ref) => this.select = ref}
                width        = {this.props.width ?? "100%"}
                items        = {this.state.actividadesToShow}
                className    = {this.props.className}
                defaultValue = {this.props.defaultValue}
                onDefaultValueLoaded = {this.props.onDefaultValueLoaded}
                onChange     = {this.onChange}
                register     = {this.props.register}
                disabled     = {this.props.disabled}
                name         = {this.props.name ?? "id_registro_actividad"}
                errors       = {this.props.errors}
                labelId      = {labelId}
                wantToReset  = {this.props.wantToReset}
                label        = {this.props.label ?? "Actividad colegio"} />
        )
    }
}
