import { Grid } from '@mui/material'
import { Button, ImagePicker, Input } from 'components/forms/components'
import { H3 } from 'components/texts/index.js'
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { useFastCreateFormCreateLogic, useFastCreateFormLogic } from 'hooks'


export default function FastCreateFormCreate({ addResponsableModalCreateRef, register, errors, getValues, setResponsables }) {
    const { handleSave } = useFastCreateFormLogic({ addResponsableModalCreateRef, setResponsables, getValues })

    return (
        <Grid container pt={2} spacing={2}>
            <Grid item xs={12} md={12} container spacing={2} px={2}>
                <Grid item xs={6}>
                    <Input label="Nombre" register={register} errors={errors} name={"responsable_nombre"} className="w-100" uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Primer apellido" register={register} errors={errors} name={"responsable_apellido1"} className="w-100" uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Segundo apellido" register={register} errors={errors} name={"responsable_apellido2"} className="w-100" uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Email" register={register} errors={errors} name={"responsable_email"} className="w-100" />
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect name={"responsable_tipo_documento_id"} keyValue={"tipo_documento"} register={register} errors={errors} defaultValue={getValues('responsable_tipo_documento_id') ? getValues('responsable_tipo_documento_id') : null} />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Numero de documento" register={register} errors={errors} name={"responsable_numero_documento"} className="w-100" uppercase />
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect name={"responsable_tipo_relacion_id"} keyValue={"tipo_relacion"} register={register} errors={errors} defaultValue={getValues('responsable_tipo_relacion_id') ? getValues('responsable_tipo_relacion_id') : null} />
                </Grid>
            </Grid>


            <Grid item xs={12} md={12} container spacing={2} px={2}>
                <Grid item xs={12}>
                    <H3 className={"m-0"}>Otros datos</H3>
                </Grid>

                <Grid item xs={6}>
                    <Input label="Profesion" register={register} errors={errors} className="w-100" name={"responsable_profesion"} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Teléfono móvil" register={register} errors={errors} className="w-100" name={"responsable_telefono_movil"} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Teléfono trabajo" register={register} errors={errors} className="w-100" name={"responsable_telefono_trabajo"} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Otro teléfono" register={register} errors={errors} className="w-100" name={"responsable_telefono_otros"} uppercase />
                </Grid>
            </Grid>

            <Grid item xs={12} display={"flex"} justifyContent={"end"}>
                <Button onClick={handleSave}>
                    Guardar
                </Button>
            </Grid>
        </Grid>
    )
}