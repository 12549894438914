import Statics from 'objects/common/Statics'
import { RequestRegistroActividad } from "objects/requests"

export default class Requests {

    /**
     * Hace la petición para editar la actividad
     * 
     * @param {Object}   values 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createActividad(values, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()
        formData.append("entity_type",         "academia")
        formData.append("nombre",              values.nombre.toUpperCase())
        formData.append("intensivo",           values.intensivo ? 1 : 0)
        formData.append("tipo_actividad_id",   values.id_tipo_actividad)
        formData.append("actividad_id",        values.id_actividad)
        formData.append("modalidad_cursos_id", values.id_modalidad_cursos)
        formData.append("precio",              Statics.changeDotAndComma(values.precio, false))
        formData.append("matricula",           Statics.changeDotAndComma(values.matricula, false))
        formData.append("precio_junio",        Statics.changeDotAndComma(values.precio_junio, false))

        new RequestRegistroActividad().create(formData, callbackOk, callbackError)
    }
}













