import { TabContext } from '@mui/lab'
import { Text } from 'components/texts'
import { COLOR } from 'theme/Colors.js'
import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests.js'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { CreateHeader } from 'pages/logged/admin/components'
import { SnackbarProvider } from 'notistack'
import { BreadcrumbsList, Line } from 'components/data_display'
import { Box, Container, Paper, Tab, Tabs } from '@mui/material'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import validationSchema from 'components/forms/display/libro/libro/DataForm/validation.js'
import { DataFormLibro, DataFormLibroControlAlmacen, DataFormLibroNiveles, DataFormLibroProgramaciones } from 'components/forms/display'
import { useCreateLogic } from 'hooks/index.js'
import { validateLibrosFields } from '../functions/validateLibrosFields.js'

/**
 * Componente `CreateLibros` para la creación de libros.
 * Utiliza el hook `useForm` para la gestión del formulario y `useCreateLogic`
 * para la lógica específica de creación de libros. Renderiza un formulario y maneja
 * la creación de libros incluyendo validación y navegación posterior.
 *
 * @returns {JSX.Element} - El formulario para la creación de un nuevo libro con soporte para múltiples secciones y validación.
 */
export default function CreateLibros() {
  const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, setValue } = useForm({ resolver: yupResolver(validationSchema), defaultValues: { niveles: [] } })

  const { isMounted, tab, setTab, createLibro, headerRef } = useCreateLogic(new Requests(), getValues, dirtyFields, validateLibrosFields)

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(createLibro)}>
        <BreadcrumbsList breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../", label: "Libros" },
          { to: "./", label: "Crear libro", actualItem: true },
        ]} />

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <CreateHeader
            ref={headerRef}
            title={"Crear libro"} />

          <Line />

          <DataFormLibro register={register} errors={errors} />

          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2} mt={5}>
              <Tabs value={tab} onChange={(_, tab) => setTab(tab)} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                <Tab label={<Text fontWeight={tab == "1" ? "bold" : null} color={tab == "1" ? COLOR.standard.main : null}>CONTROL DEL AMACÉN</Text>} value={"1"} />
                <Tab label={<Text fontWeight={tab == "2" ? "bold" : null} color={tab == "2" ? COLOR.standard.main : null}>NIVELES</Text>} value={"2"} />
                <Tab label={<Text fontWeight={tab == "3" ? "bold" : null} color={tab == "3" ? COLOR.standard.main : null}>PROGRAMACIONES</Text>} value={"3"} />
              </Tabs>
            </Box>

            <TabPanel value={"1"}>
              <DataFormLibroControlAlmacen
                setValue={setValue}
                isMounted={isMounted}
                errors={errors}
                register={register} />
            </TabPanel>

            <TabPanel value={"2"}>
              <DataFormLibroNiveles
                setValue={setValue}
                register={register}
                getValues={getValues} />
            </TabPanel>

            <TabPanel value={"3"}>
              <DataFormLibroProgramaciones
                register={register}
                getValues={getValues} />
            </TabPanel>
          </TabContext>
        </Paper>

        <SnackbarProvider autoHideDuration={5000} />
      </form>
    </Container>
  )
}