import { RequestHorario } from 'objects/requests'
  
export default class Requests {
  

    /** 
     * Hace la petición para eliminar una definicion del horario
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    deleteDefinicionHorario(id, callbackOk, callbackError) {
        new RequestHorario().deleteDefinicionHorario(id, {}, callbackOk, callbackError)
    }
}