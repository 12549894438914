import * as Yup from 'yup'

// TODO: he quitado el regex para que acepte cualquier caracter. 
// Habria que poner un regex que acepte caracteres especiales que se usan en los titulos de los libros.

/**
 * Esquema de validación Yup utilizado para validar los campos de un formulario relacionado con la información de un libro.
 * El esquema define las reglas de validación para los campos "nombre" e "isbn".
 */
const Regex = "^[A-Za-z0-9 ñÑ\-]+$"
const RegexIsbn10 = /^(?:\d{9}[\dXx])$/
const RegexIsbn13 = /^(?:\d{13})$/
const RegexNombres = /^[A-Za-zÁ-ú]+(?:\s[A-Za-zÁ-ú]+)*$/

const validationSchema = Yup.object().shape({
    nombre: Yup.string(),
    // nombre: Yup.string().matches(RegexNombres, { message: 'No puede contener caracteres especiales', excludeEmptyString: true }),
    isbn: Yup.string().matches(Regex, { message: 'No puede contener caracteres especiales', excludeEmptyString: true }),
})

export default validationSchema