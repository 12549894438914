import React from "react";
import { useParams } from "react-router";
import { useForm } from "react-hook-form";
import Requests from "./Requests/Requests";
import { SnackbarProvider } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Paper, Stack } from "@mui/material";
import { EditHeader } from "pages/logged/admin/components";
import { AlumnoFullItem } from "components/data_display/users";
import { DataFormDatosColegio } from "components/forms/display";
import { BreadcrumbsList, Line, MoveButtons } from "components/data_display";
import Skeleton from "components/forms/display/datos-colegio/DataForm/skeleton.jsx";
import AlumnoSkeleton from "./skeleton.jsx";
import { validationSchema } from "components/forms/display/datos-colegio/DataForm/validation.js";
import { useCheckDirtyFields, useDatosColegioEditLogic, useDatosColegioWrapperLogic } from "hooks";
import NavigationSkeleton from 'components/skeletons/navigation/navigationSkeleton.jsx'

/**
 * Componente principal que utiliza el hook `useWrapperLogic` para mostrar y editar
 * los datos del colegio de un alumno específico.
 *
 * @returns {JSX.Element} Un contenedor con la información de los datos del colegio y el alumno asociado.
 */
const Wrapper = () => {
	const navigationParams = useParams();

	const {
		updateAlert,
		datosColegio,
		id_datos_colegio,
		setIdDatosColegio,
		setDatosColegio,
		getDatosColegio,
	} = useDatosColegioWrapperLogic(new Requests(), navigationParams);

	return (
		<React.Fragment>
			<EditDatosColegio
				key={datosColegio?.id ?? 0}
				datosColegio={datosColegio}
				id_datos_colegio={id_datos_colegio}
				setIdDatosColegio={setIdDatosColegio}
				setDatosColegio={setDatosColegio}
				updateAlert={updateAlert}
				onSaveFinish={() => getDatosColegio(true)}
			/>

			<SnackbarProvider autoHideDuration={3000} />
		</React.Fragment>
	);
};
export default Wrapper;

/**
 * Componente `EditDatosColegio` para editar los datos del colegio de un alumno.
 * Utiliza `useEditDatosColegioLogic` para la lógica de edición y `useForm` para el manejo del formulario.
 *
 * @param {Object} props - Propiedades del componente, incluyendo datos del colegio y funciones para la gestión.
 */
function EditDatosColegio({ datosColegio, id_datos_colegio, setIdDatosColegio, setDatosColegio, updateAlert, onSaveFinish, }) {
	const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, setValue, reset, } = useForm({ resolver: yupResolver(validationSchema) });

	const { headerRef, updateChanges, updateId, editDatosColegio } =
		useDatosColegioEditLogic( new Requests(), updateAlert, getValues, reset, id_datos_colegio, setIdDatosColegio, onSaveFinish, datosColegio );

	useCheckDirtyFields(dirtyFields, updateChanges);

	return (
		<Container maxWidth={false} sx={{ mt: 5 }}>
			<form
				className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
				onSubmit={handleSubmit(editDatosColegio)}
			>
				{!datosColegio ? <NavigationSkeleton numberLinks={5} /> : (
					<Stack
						direction="row"
						justifyContent={"space-between"}
						marginX={8}
					>
						<BreadcrumbsList
							mx={0}
							breadcrumbsList={[
								{ to: "/", label: "Inicio" },
								{ to: "./../../../", label: "Alumnos" },
								{ to: "./../../", label: "Datos colegio" },
								{
									to: "./../",
									label:
										"Datos colegio #" +
										(id_datos_colegio ?? "0000"),
								},
								{ to: "./", label: "Editar", actualItem: true },
							]}
						/>

						<MoveButtons
							actualItemId={id_datos_colegio}
							beforeFetchCallback={() => setDatosColegio(null)}
							onUpdateActualItem={updateId}
						/>
					</Stack>
				)}

				<Paper elevation={2} sx={{ mx: 8, my: 2, pb: 2 }}>
					<EditHeader title="Alumno de los datos" />

					<Line />

					{datosColegio?.alumno ? (
						<AlumnoFullItem
							alumno={datosColegio.alumno}
							containerClassName="m-5"
						/>
					) : (
						<AlumnoSkeleton />
					)}
				</Paper>

				<Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
					<EditHeader
						ref={headerRef}
						title={"Editar datos colegio"}
						item={datosColegio}
					/>

					<Line />

					{datosColegio ? (
						<DataFormDatosColegio
							datosColegio={datosColegio}
							register={register}
							errors={errors}
							setValue={setValue}
							isMounted
							hideButton
						/>
					) : (
						<Skeleton />
					)}
				</Paper>
			</form>
			<SnackbarProvider autoHideDuration={3000} />
		</Container>
	);
}
