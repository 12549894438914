import React from 'react'
import { useParams } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { useSingleActividadLogic } from 'hooks'
import Requests from '../single/Request/Request'
import { Container, Paper, Stack } from '@mui/material'
import { ActividadesAcademiaData, Header } from './parts'
import { BreadcrumbsList, MoveButtons, Line } from 'components/data_display'
import Headers from 'components/headers/index'

/**
 * Componente `SingleActividades` que muestra la información detallada de una actividad académica.
 * Utiliza el hook `useLogic` para obtener y manejar los datos de la actividad.
 * Renderiza las migas de pan para la navegación y botones para moverse entre actividades.
 * Muestra un encabezado y los datos detallados de la actividad, con un 'SnackbarProvider' para notificaciones.
 *
 * @returns {JSX.Element} - Un contenedor que incluye todos los elementos de la interfaz para una actividad académica.
 */
export default function SingleActividades() {

  const navigationParams = useParams()

  const { id_actividad, setActividad, actividad, updateId } = useSingleActividadLogic(navigationParams, new Requests())

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>

      <Stack direction="row" justifyContent={"space-between"} marginX={8}>
        <BreadcrumbsList mx={0} breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../", label: "Academia" }, //--> Te dirige al apartado Actividades
          { to: "./../", label: "Actividades" },
          { to: "./", label: "Actividad #" + id_actividad, actualItem: true },
        ]} />

        <MoveButtons
          actualItemId={id_actividad}
          beforeFetchCallback={() => setActividad(null)}
          onUpdateActualItem={updateId} />
      </Stack>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
        {/* {actividad ? <Header actividad={actividad} /> : null} FORMA ANTIGUA DE NOMBRAR EL COMPONENTE*/}
        <Headers
          template={'single'}
          title={"Consultar Actividad"}
          actividad={actividad ? actividad.nombre : ' #' + id_actividad}
        />

        <Line className={"my-5"} />

        <ActividadesAcademiaData actividad={actividad} />
      </Paper>

      <SnackbarProvider autoHideDuration={3000} />
    </Container>
  )
}
