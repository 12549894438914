import { H3 } from 'components/texts'
import { columns } from './columns.js'
import { Box, Paper } from '@mui/material'
import Container from '@mui/material/Container'
import React, { useContext, useEffect, useState } from 'react'
import { ListTableHeader } from 'pages/logged/admin/components'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { Add as AddIcon, Search as SearchIcon } from '@mui/icons-material'
import { Accordion, BreadcrumbsList, Datagrid, Link } from 'components/data_display'
import SearchFormIncidencia, { search } from 'components/forms/display/incidencia/SearchForm'

/**
 * Listado de las incidencias
 */
export default function ListadoIncidencias() {
  const [loadingTable, setLoadingTable] = useState(false)
  const { items, rowCount, params, update } = useContext(EmpleadoListContext)

  useEffect(() => {
    setLoadingTable(false)
  }, [items])

  /**
   * Actualiza los datos de la tabla y el contexto global con los resultados obtenidos de la búsqueda.
   * 
   * @param {Array} data - Los datos obtenidos para llenar la tabla.
   * @param {number} rowCount - El número total de filas encontradas para la búsqueda realizada.
   * @param {number} page - La página actual de los resultados.
   * @param {Object} params - Los parámetros de búsqueda utilizados.
   */
  const updateTable = (newData, newRowCount, newPage, newParams) => update(newRowCount, newData, newParams, newPage)

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <BreadcrumbsList breadcrumbsList={[
        { to: "/", label: "Inicio" },
        { to: "../alumnos", label: "Alumnos" },
        { to: "./", label: "Incidencias", actualItem: true },
      ]} />

      <Accordion header={<Box display={"flex"} alignItems={"center"}><SearchIcon className={"mx-5"} /> <H3 className="my-0">Buscar incidencias</H3></Box>}>
        <SearchFormIncidencia
          searchCallback={updateTable}
          onLoadingCallback={setLoadingTable} />
      </Accordion>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
        <ListTableHeader rowCount={rowCount} params={params} keyValue={"incidencias"} />

        <Datagrid
          rows={items}
          columns={columns}
          loading={loadingTable}
          rowCount={rowCount}
          onPageChange={(page) => { search(page, () => setLoadingTable(true), updateTable) }}
          serverSide />
      </Paper>
    </Container>
  )
}
