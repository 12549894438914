
import { AlumnoData, TutoriaData } from './parts'
import { RequestTutoria } from 'objects/requests'
import React from 'react'
import { Container, Paper, Stack } from '@mui/material'
import { useParams } from 'react-router-dom'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
import { useTutoriaLogic } from 'hooks'

/**
 * Componente `SingleTutoria` para visualizar la información detallada de una tutoría específica.
 * Utiliza el hook `useLogic` para manejar la carga y actualización de la información de la tutoría.
 * Presenta la información de la tutoría y del alumno asociado a esta tutoría.
 * 
 * @returns {JSX.Element} Estructura del componente que incluye la visualización de la tutoría y el alumno asociado.
 */
export default function SingleTutoria() {
  const navigationParams = useParams()

  const { updateId, id_tutoria, tutoria, setTutoria } = useTutoriaLogic(new RequestTutoria(), navigationParams)

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>

      <Stack direction="row" justifyContent={"space-between"} marginX={8}>
        <BreadcrumbsList mx={0} breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../../", label: "Alumnos" },
          { to: "./../", label: "Tutorías" },
          { to: "./", label: "Tutoría #" + (id_tutoria ?? "0000"), actualItem: true },
        ]} />

        <MoveButtons
          actualItemId={id_tutoria}
          beforeFetchCallback={() => setTutoria(null)}
          onUpdateActualItem={updateId} />
      </Stack>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>

        <AlumnoData tutoria={tutoria} />

        <Line className={"my-10"} />

        <TutoriaData tutoria={tutoria} />

      </Paper>
    </Container>
  )
}
