import { ChangesContext } from "contexts/ChangesContext"
import { useAlert, useCheckDirtyFields, useErrorHandler } from "hooks"
import Statics from "objects/common/Statics"
import { useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

/**
 * Hook personalizado para gestionar la lógica de creación de libros.
 * Utiliza varios hooks y contextos para manejar el estado, la navegación,
 * y la validación de los datos del formulario.
 * 
 * @param {*} requests - Instancia de la clase Requests con las peticiones correspondientes de libros
 * @param {function} getValues - Función para obtener los valores actuales del formulario.
 * @param {object} dirtyFields - Objeto que indica los campos del formulario que han sido modificados.
 * @param {function} validateLibrosFields - Función para validar los campos requeridos del control de almacen en los arrays de librosPedidos y librosDevueltos.
 * 
 * @returns {object} - Objeto que contiene funciones específicas para la creación de libros y el manejo de formularios.
 */
export default function useCreateLogic(requests, getValues, dirtyFields, validateLibrosFields) {
    const handleError = useErrorHandler()

    const updateAlert = useAlert()

    const navigate = useNavigate()

    const headerRef = useRef()

    const { updateChanges } = useContext(ChangesContext)

    const [tab, setTab] = useState("1")

    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => setIsMounted(true), [])

    useCheckDirtyFields(dirtyFields, updateChanges)

    /**
     * Genera promesas para crear diferentes entidades relacionadas con el libro.
     * 
     * @param {number} libro_id - El ID del libro para el que se crean entidades relacionadas.
     * @returns {Array} Array de promesas para la creación de libros pedidos, libros devueltos y niveles.
     */
    const promises = (libro_id) => {
        const promiseLibrosPedidos = new Promise((resolve, reject) => {
            requests.createLibrosPedidos(libro_id, getValues, resolve, reject)
        })

        const promiseLibrosDevueltos = new Promise((resolve, reject) => {
            requests.createLibrosDevueltos(libro_id, getValues, resolve, reject)
        })

        const promiseNiveles = new Promise((resolve, reject) => {
            requests.createNiveles(libro_id, getValues, resolve, reject)
        })

        return [promiseLibrosPedidos, promiseLibrosDevueltos, promiseNiveles]
    }

    /**
     * Maneja la creación de un nuevo libro ejecutando la llamada a la API y gestionando la respuesta.
     * Utiliza `enqueueSnackbar` para mostrar notificaciones al usuario sobre el resultado de la operación.
     */
    const createLibro = () => {
        // Valida los campos de control de almacén antes de proceder. Si la validación falla, detiene la ejecución
        if (!validateLibrosFields(updateAlert, getValues))
            return

        headerRef.current.button.setLoading(true)

        requests.createLibro(getValues, (res) => {
            Promise.allSettled(promises(res.libro.id))
                .then(async (values) => {
                    updateAlert('Se ha creado el libro. Redirigiendo a la página de edición...', 'success')

                    for (const fail of values.filter(item => item.status == "rejected"))
                        handleError(fail.reason)

                    updateChanges(false)
                    await Statics.delay(1500).then(() => navigate('./../' + res.libro.id + "/editar"))
                })
        }, (res) => {
            headerRef.current.button.setLoading(false)
            handleError(res)
        })
    }

    return { isMounted, tab, setTab, createLibro, headerRef }
}