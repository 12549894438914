import { TabContext } from '@mui/lab'
import { Text } from 'components/texts'
import { COLOR } from 'theme/Colors.js'
import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests.js'
import { useParams } from 'react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import { EditHeader } from 'pages/logged/admin/components'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { Box, Container, Paper, Stack, Tab, Tabs } from '@mui/material'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
import validationSchema from 'components/forms/display/libro/libro/DataForm/validation.js'
import { DataFormLibro, DataFormLibroControlAlmacen, DataFormLibroNiveles, DataFormLibroProgramaciones } from 'components/forms/display'
import { DataFormLibroSkeleton, DataFormLibroNivelesSkeleton, DataFormControlAlmacenSkeleton, DataFormLibroProgramacionesSkeleton } from 'components/forms/display/libro/libro/skeletons'
import { useEditWrapperLogic, useCheckDirtyFields, useEditLogic } from 'hooks/index.js'

/**
 * Componente `Wrapper` que envuelve la lógica y la interfaz de usuario para editar un libro.
 * Utiliza el hook `useWrapperLogic` para manejar el estado y las interacciones relacionadas con la edición del libro.
 * Encapsula y renderiza el componente `EditLibros`, pasándole las props necesarias para su funcionamiento.
 *
 * @returns {JSX.Element} - Un fragmento que contiene el componente `EditLibros` y el proveedor de notificaciones `SnackbarProvider`.
 */
export default function Wrapper() {
  const navigationParams = useParams()

  const { libro, setLibro, tab, setTab, id_libro, setIdLibro, updateAlert, getLibro } = useEditWrapperLogic(new Requests(), navigationParams)

  return (
    <React.Fragment>
      <EditLibros key={libro?.id ?? 0} libro={libro} id_libro={id_libro} setIdLibro={setIdLibro} setLibro={setLibro} tab={tab} setTab={setTab} updateAlert={updateAlert} onSaveFinish={() => getLibro(true)} />

      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}

/**
 * Componente `EditLibros` que proporciona una interfaz de usuario para editar la información de un libro.
 * Contiene lógica para actualizar la información del libro y manejar diferentes secciones de edición a través de pestañas.
 * 
 * @param {Object} libro - Objeto que contiene la información del libro actual.
 * @param {number} id_libro - ID del libro actual.
 * @param {Function} setIdLibro - Función para actualizar el ID del libro.
 * @param {Function} setLibro - Función para actualizar la información del libro.
 * @param {Function} updateAlert - Función para mostrar alertas.
 * @param {string} tab - La pestaña actual seleccionada en la UI.
 * @param {Function} setTab - Función para cambiar la pestaña seleccionada.
 * @param {Function} onSaveFinish - Función que se ejecuta al finalizar la operación de guardado.
 *
 * @returns {JSX.Element} - El formulario de edición del libro junto con las pestañas para diferentes secciones de edición.
 */
function EditLibros({ libro, id_libro, setIdLibro, setLibro, updateAlert, tab, setTab, onSaveFinish }) {
  const { register, handleSubmit, formState: { errors, dirtyFields }, getValues, setValue, reset } = useForm({ resolver: yupResolver(validationSchema), defaultValues: { niveles: [] } })

  const { editLibro, updateIdLibro, headerRef, updateChanges } = useEditLogic(id_libro, getValues, updateAlert, onSaveFinish, reset, setIdLibro, new Requests())

  //TODO: sustituir en todo el codigo el useEffect de dirtyFields por el hook personalizado useCheckDirtyFields
  useCheckDirtyFields(dirtyFields, updateChanges)

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(editLibro)}>
        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/", label: "Inicio" },
            { to: "./../../", label: "Libros" },
            { to: "./../", label: "Libro #" + (id_libro ?? "0000") },
            { to: "./", label: "Editar", actualItem: true },
          ]} />

          <MoveButtons
            actualItemId={id_libro}
            beforeFetchCallback={() => setLibro(null)}
            onUpdateActualItem={updateIdLibro} />
        </Stack>

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          <EditHeader
            ref={headerRef}
            title={"Editar libro"}
            item={libro} />

          <Line />

          {libro ? <DataFormLibro register={register} errors={errors} libro={libro} /> : <DataFormLibroSkeleton />}

          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2} mt={5}>
              <Tabs value={tab} onChange={(_, tab) => setTab(tab)} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                <Tab label={<Text fontWeight={tab == "1" ? "bold" : null} color={tab == "1" ? COLOR.standard.main : null}>CONTROL DEL AMACÉN</Text>} value={"1"} />
                <Tab label={<Text fontWeight={tab == "2" ? "bold" : null} color={tab == "2" ? COLOR.standard.main : null}>NIVELES</Text>} value={"2"} />
                <Tab label={<Text fontWeight={tab == "3" ? "bold" : null} color={tab == "3" ? COLOR.standard.main : null}>PROGRAMACIONES</Text>} value={"3"} />
              </Tabs>
            </Box>

            <TabPanel value={"1"}>
              {libro ?
                <DataFormLibroControlAlmacen
                  setValue={setValue}
                  librosPedidosProp={libro?.pedidos_libro ?? []}
                  librosDevueltosProp={libro?.devoluciones_libro ?? []}
                  libro={libro}
                  errors={errors}
                  register={register}
                  isMounted /> : <DataFormControlAlmacenSkeleton />}
            </TabPanel>

            <TabPanel value={"2"}>
              {libro ?
                <DataFormLibroNiveles
                  setValue={setValue}
                  register={register}
                  libro={libro}
                  getValues={getValues} /> : <DataFormLibroNivelesSkeleton />}
            </TabPanel>

            <TabPanel value={"3"}>
              {libro ?
                <DataFormLibroProgramaciones
                  libro={libro}
                  register={register}
                  getValues={getValues} /> : <DataFormLibroProgramacionesSkeleton />}
            </TabPanel>
          </TabContext>
        </Paper>
      </form>
    </Container>
  )
}