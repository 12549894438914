import { Component } from "react"
import { Grid } from "@mui/material"
import { Text } from "components/texts"
import { CursoEscolarSelect, ItemsSelect } from "components/forms/components/Select/custom"

export default class ClasesParticularesMateriasTemplate extends Component {
  /**
   * Constructor
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props)
  }

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    const { register, errors, setValue } = this.props
    return (
      <>
        <Grid container mt={2} mb={1}>
          <Text fontWeight={"bold"}>Según datos de:</Text>
          <br />
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <ItemsSelect label={"Materia"} name={"actividad_id"} keyValue={"actividad"} register={register} errors={errors} wantToReset={this.props.reset} searcheable />
          </Grid>

          <Grid item xs={6}>
            <CursoEscolarSelect name={"curso_escolar_id"} wantToReset={this.props.reset} register={register} errors={errors} defaultValue={null} setValue={setValue} searcheable />
          </Grid>
        </Grid>
      </>
    )
  }
}
