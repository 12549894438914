import React from 'react'
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { Button, ImagePicker, Input } from 'components/forms/components'

//VALIDACION FORMULARIO
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from "./validation.js"
import { RequestAlumnoResponsable, RequestResponsable } from 'objects/requests'
import { Grid } from '@mui/material'
import { H3 } from 'components/texts/index.js'

/**
 * Formulario para crear un responsable de forma rápida y mas simple, es necesario enviar la id del alumno al que se le va a asociar
 */
export default function FastCreateForm({ id_alumno, callbackOk, callbackError }) {

    const { register, handleSubmit, formState: { errors }, getValues } = useForm({ resolver: yupResolver(validationSchema) })

    //-------------------------------------------------------
    //-------------------- CLASS FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Prepara los values y hace la petición para crear un responsable
     */
    const createResponsable = () => {
        const formData = new FormData()
        formData.append("nombre",            getValues("nombre").toUpperCase())
        formData.append("apellido1",         getValues("apellido1").toUpperCase())
        formData.append("apellido2",         getValues("apellido2")?.toUpperCase() ?? null)
        formData.append("email",             getValues("email"))
        formData.append("numero_documento",  getValues("numero_documento").toUpperCase())
        formData.append("tipo_documento_id", getValues("tipo_documento_id"))
        formData.append("profesion",         getValues("profesion").toUpperCase())
        formData.append("telefono_movil",    getValues("telefono_movil"))
        formData.append("telefono_trabajo",  getValues("telefono_trabajo"))
        formData.append("telefono_otros",    getValues("telefono_otros"))

        new RequestResponsable().create(formData, (res) => {
            createRelation(res.responsable)
        }, callbackError)
    }


    /**
     * Hace la petición para crear la relacion entre el responsable y el alumno
     * 
     * @param {Object} responsable
     */
    const createRelation = (responsable) => {
        const formData = new FormData()

        formData.append("responsable_id",   responsable.id)
        formData.append("alumno_id",        id_alumno)
        formData.append("tipo_relacion_id", getValues("tipo_relacion_id"))

        new RequestAlumnoResponsable().create(formData, callbackOk, () => {
            callbackError("Se ha creado el responsable pero no se ha podido asociar al alumno.")
        })
    }
    
    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container pt={2} spacing={2}>
            <Grid item xs={12} md={12} container spacing={2} px={2}>
                <Grid item xs={6}>
                    <Input label="Nombre" register={register} errors={errors} name={"nombre"} className="w-100"  uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Primer apellido" register={register} errors={errors} name={"apellido1"} className="w-100" uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Segundo apellido" register={register} errors={errors} name={"apellido2"} className="w-100" uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Email" register={register} errors={errors} name={"email"} className="w-100" />
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect name={"tipo_documento_id"} keyValue={"tipo_documento"} register={register} errors={errors}/>
                </Grid>

                <Grid item xs={6}>
                    <Input label="Numero de documento" register={register} errors={errors} name={"numero_documento"} className="w-100"  uppercase />
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect name={"tipo_relacion_id"} keyValue={"tipo_relacion"} register={register} errors={errors}/>
                </Grid>
            </Grid>


            <Grid item xs={12} md={12} container spacing={2} px={2}>
                <Grid item xs={12}>
                    <H3 className={"m-0"}>Otros datos</H3>
                </Grid>

                <Grid item xs={6}>
                    <Input label="Profesion" register={register} errors={errors} className="w-100" name={"profesion"} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Teléfono móvil" register={register} errors={errors} className="w-100" name={"telefono_movil"} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Teléfono trabajo" register={register} errors={errors} className="w-100" name={"telefono_trabajo"} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Otro teléfono" register={register} errors={errors} className="w-100" name={"telefono_otros"} uppercase />
                </Grid>
            </Grid> 

            <Grid item xs={12} display={"flex"} justifyContent={"end"}>
                <Button onClick={handleSubmit(createResponsable)}>
                    Guardar
                </Button>
            </Grid>
        </Grid>
    )
}
