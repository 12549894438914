import { Component } from 'react'
import { RequestAgenda } from 'objects/requests'

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests extends Component {

    /**
     * Hace la petición para crear un evento
     * 
     * @param {Array}    values 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createEvento(values, callbackOk = () => {}, callbackError = () => {}) {
        const formData = new FormData()
        formData.append("title",        values.title.toUpperCase())
        formData.append("description",  values.description)
        formData.append("date",         values.date)
        formData.append("color",        values.color)
        formData.append("empleado_id",  values.empleado_id)

        if(values.hour)
            formData.append("hour", values.hour)

        if(values.end_hour)
            formData.append("end_hour", values.end_hour)
        
        new RequestAgenda().create(formData, callbackOk, callbackError)
    }


    /**
     * Hace la petición para editar un evento
     * 
     * @param {Array}    values 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editEvento(id, values, callbackOk = () => {}, callbackError = () => {}) {
        const formData = new FormData()
        formData.append("title",        values.title.toUpperCase())
        formData.append("color",        values.color)
        formData.append("description",  values.description)
        formData.append("hour",         values.hour)
        formData.append("end_hour",     values.end_hour)

        new RequestAgenda().edit(id, formData, callbackOk, callbackError)
    }


    /**
     * Peticion para obtener los eventos del usuario logueado
     * 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    getUserEvents(params, callbackOk = () => {}, callbackError = () => {}) {
        new RequestAgenda().getUserEvents(params, callbackOk, callbackError)
    }


    /**
     * Peticion para borrar los eventos
     * 
     * @param {Int}      id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    deleteEvento(id, callbackOk = () => {}, callbackError = () => {}) {
        new RequestAgenda().delete("" + id, new FormData(), callbackOk, callbackError)
    }


    /**
     * Borra la relacion de un empleado a un evento
     * 
     * @param {Int}      id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError  
     */
    deleteEventoEmpleado(id, callbackOk = () => {}, callbackError = () => {}) {
        new RequestAgenda().deleteEventoEmpleado(id, new FormData(), callbackOk, callbackError)
    }


    /**
     * Crea la relacion de un evento con un empleado
     * 
     * @param {Object}   params       
     * @param {Function} callbackOk 
     * @param {Function} callbackError  
     */
    share(params, callbackOk = () => {}, callbackError = () => {}) {
        new RequestAgenda().share(params, callbackOk, callbackError)
    }
}
