import React from 'react'
import { Button } from 'components/forms/components'
import { Edit as EditIcon } from '@mui/icons-material'
import { Grid, Stack } from '@mui/material'
import { H3, Text } from 'components/texts'
import { Link } from 'components/data_display'
// import SkeletonAulaData from './skeleton'
import { Checkbox } from 'components/forms/components'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { Line } from 'components/data_display'

export default function PagosAlumnosData({ pagos }) {

	return (
		<Grid container spacing={2} >
			{/* {!aula ?
				<SkeletonAulaData />
				: ( */}
			<React.Fragment>
				<Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
					<H3>Datos de los pagos de los Alumnos:</H3>

					<Link to="./6/editar">
						<Button variant={"outlined"} startIcon={<EditIcon />} >
							Editar
						</Button>
					</Link>
				</Grid>

				<Grid item xs={6}>
					<Stack>
						<Text fontWeight={"bold"}>Curso Escolar:</Text>
						<Text>{pagos?.curso_escolar ?? ""}</Text>
					</Stack>
				</Grid>

				<Grid item xs={4}>
					<Stack>
						<Text fontWeight={"bold"}>Ordenante:</Text>
						<Text>{pagos?.ordenante ?? ""}</Text>
					</Stack>
				</Grid>
				<Grid item xs={4}>
					<Stack>
						<Text fontWeight={"bold"}>Mes:</Text>
						<Text>{pagos?.mes ?? ""}</Text>
					</Stack>
				</Grid>

				<Grid item xs={4}>
					<Stack>
						<Text fontWeight={"bold"}>NIF/CIF:</Text>
						<Text>{pagos?.mes ?? ""}</Text>
					</Stack>
				</Grid>

				<Grid item xs={4}>
					<Stack>
						<Text fontWeight={"bold"}>Sufijo:</Text>
						<Text>{pagos?.sufijo ?? ""}</Text>
					</Stack>
				</Grid>

				<Grid item xs={4}>
					<Stack>
						<Text fontWeight={"bold"}>Fecha de Adeudo:</Text>
						<Text>{pagos?.curso_escolar ?? ""}</Text>
					</Stack>
				</Grid>

				<Grid item xs={4}>
					<Stack>
						<Text fontWeight={"bold"}>IBAN:</Text>
						<Text>{pagos?.iban ?? ""}</Text>
					</Stack>
				</Grid>

				<Grid item xs={4}>
					<Stack>
						<Text fontWeight={"bold"}>Identificador:</Text>
						<Text>{pagos?.curso_escolar ?? ""}</Text>
					</Stack>
				</Grid>


				<Grid item >
					<Line />
				</Grid>

				<Grid item xs={12}>
					<H3> Inscripciones Actualizadas </H3>
				</Grid>

				<Grid item xs={12}>

					<FormControl>
						<FormLabel id="domiciliaciones"><Text fontWeight={"bold"}>Domiciliaciones a pasar:</Text></FormLabel>
						<RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
							<FormControlLabel value="colegio" disabled control={<Radio />} label="Colegio" />
							<FormControlLabel value="todas" disabled control={<Radio />} label="Todas" />
						</RadioGroup>
					</FormControl>

				</Grid>


				<Grid item xs={4}>
					<Stack>
						<Text fontWeight={"bold"}>Nº Domiciliaciones:</Text>
						<Text>{pagos?.num_domiciliaciones ?? ""}</Text>
					</Stack>
				</Grid>

				<Grid item xs={4}>
					<Stack>
						<Text fontWeight={"bold"}>Fichero:</Text>
						<Text>{pagos?.fichero ?? ""}</Text>
					</Stack>
				</Grid>

				<Grid item xs={4}>
					<Stack>
						<Text fontWeight={"bold"}>Fecha de Inmersiones:</Text>
						<Text>{pagos?.inmersiones ?? ""}</Text>
					</Stack>
				</Grid>

				<Grid item xs={2}>
					<Stack>
						<Checkbox disabled name={"academia"} label={"Academia"} className="mt-2" defaultChecked={pagos?.academia ? true : false} />
					</Stack>
				</Grid>
				<Grid item xs={2}>
					<Stack>
						<Checkbox disabled name={"colegios"} label={"Colegios"} className="mt-2" defaultChecked={pagos?.colegios ? true : false} />
					</Stack>
				</Grid>
				<Grid item xs={2}>
					<Stack>
						<Checkbox disabled name={"particulares"} label={"Particulares"} className="mt-2" defaultChecked={pagos?.ninos ? true : false} />
					</Stack>
				</Grid>
				<Grid item xs={2}>
					<Stack>
						<Checkbox disabled name={"empresas"} label={"Empresas"} className="mt-2" defaultChecked={pagos?.ninos ? true : false} />
					</Stack>
				</Grid>
				<Grid item xs={2}>
					<Stack>
						<Checkbox disabled name={"exOficiales"} label={"Ex. Oficiales"} className="mt-2" defaultChecked={pagos?.ninos ? true : false} />
					</Stack>
				</Grid>
				<Grid item xs={2}>
					<Stack>
						<Checkbox disabled name={"inmersiones"} label={"Inmersiones"} className="mt-2" defaultChecked={pagos?.ninos ? true : false} />
					</Stack>
				</Grid>

				<Grid item xs={8}>
					<Stack>
						<Text fontWeight={"bold"}>Colegio:</Text>
						<Text>{pagos?.colegio ?? ""}</Text>
					</Stack>
				</Grid>
				<Grid item xs={4}>
					<Stack>
						<Text fontWeight={"bold"}>Cantidad:</Text>
						<Text>{pagos?.cantidad ?? ""}</Text>
					</Stack>
				</Grid>


			</React.Fragment >
			{/* )
			} */}
		</Grid >
	)
}

