import React from 'react'
import { Grid } from '@mui/material'
import { Text } from 'components/texts'
import { itemsActual } from '../searchAndOrderOptions'
import { Input, Select } from 'components/forms/components'
import { CursoEscolarSelect, ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Componente para renderizar el formulario de búsqueda de inscripciones en actividades de academia.
 * Permite a los usuarios especificar diversos criterios para filtrar las inscripciones, mejorando la eficiencia de la búsqueda.
 *
 * @param {Object} props Propiedades recibidas por el componente para su funcionamiento.
 * @param {Function} props.reset Función de react-hook-form para resetear el formulario a sus valores iniciales.
 * @param {Object} props.errors Objeto de errores de react-hook-form para mostrar mensajes de error en el formulario.
 * @param {Function} props.register Función de react-hook-form para registrar los campos del formulario, conectándolos con el estado interno y la validación.
 * @param {Function} props.setValue Función de react-hook-form que permite establecer el valor de un campo del formulario programáticamente.
 *
 * Este componente estructura los campos del formulario de manera que los usuarios pueden introducir o seleccionar los criterios específicos para la búsqueda de inscripciones en actividades académicas.
 */
export default function InscripcionActividadesAcademiaTemplate({ reset, errors, register, setValue }) {

    return (
        <>
            <Grid container mt={2} mb={1}>
                <Text fontWeight={'bold'}>Según datos de:</Text><br />
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <ItemsSelect name={"tipo_actividad_id"} keyValue={"tipo_actividad"} register={register} errors={errors} wantToReset={reset} />
                </Grid>

                <Grid item xs={4}>
                    <ItemsSelect name={"actividad_id"} keyValue={"actividad"} register={register} errors={errors} wantToReset={reset} searcheable />
                </Grid>

                <Grid item xs={4}>
                    <ItemsSelect name={"modalidad_curso_id"} keyValue={"modalidad_cursos"} register={register} errors={errors} wantToReset={reset} />
                </Grid>

                <Grid item xs={4}>
                    <Input label="Test de Nivel" width={"100%"} name={"test_nivel"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <CursoEscolarSelect label={"Año"} name={"curso_escolar_id"} wantToReset={reset} register={register} errors={errors} defaultValue={null} setValue={setValue} searcheable />
                </Grid>

                <Grid item xs={4}>
                    <Select register={register} errors={errors} name="actual" items={itemsActual} defaultValue={itemsActual[0].value} />
                </Grid>
            </Grid>
        </>
    )
}