import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Statics from 'objects/common/Statics.js'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChangesContext } from 'contexts/ChangesContext'
import { useEffect, useRef, useState, useContext } from 'react'
import { useAlert, useCheckDirtyFields, useErrorHandler } from 'hooks'
import { EmpleadoListContext } from 'contexts/employee/ListContext.jsx'
import { validationSchema } from 'components/forms/display/academia/grupos/DataForm/GeneralDataForm/validation'

/**
 * Hook personalizado para crear un grupo académico.
 * Utiliza formularios y validaciones para gestionar el estado y las interacciones del usuario.
 * 
 * @returns {Object} Contiene todas las funciones y estados necesarios para la creación del grupo.
 */
export default function useAcademiaGrupoCreateLogic({ requests }) {
    const { register, handleSubmit, formState: { errors, dirtyFields }, setValue, getValues, control } = useForm({ resolver: yupResolver(validationSchema) })
    const navigate = useNavigate()
    const handleError = useErrorHandler()
    const updateAlert = useAlert()

    const headerRef = useRef()
    const { addItem } = useContext(EmpleadoListContext)
    const { updateChanges } = useContext(ChangesContext)
    const [libro, setLibro] = useState(null)

    const [isMounted, setIsMounted] = useState(false)
    const [tab, setTab] = useState("1")

    useEffect(() => setIsMounted(true), [])

    useCheckDirtyFields(dirtyFields, updateChanges)

    const createGrupoAcademia = (values) => {
        headerRef.current.button.setLoading(true)

        requests.createGrupo(values, (res) => {
            requests.createEmpleadoGrupo(res.id, values.empleado_id, async () => {
                updateChanges(false)
                updateAlert('Se ha creado el grupo correctamente. Redirigiendo a la pagina de edición...', 'success')
                // Añadimos el nuevo grupo al contexto
                addItem(res)
                await Statics.delay(1500).then(() => { navigate('./../' + res.id) })
            }, () => { })
        }, (err) => {
            headerRef.current.button.setLoading(false)
            handleError(err)
        })
    }

    return { register, handleSubmit, errors, setValue, getValues, control, isMounted, createGrupoAcademia, tab, setTab, headerRef, libro, setLibro }
}