import { Text } from 'components/texts'
import { Grid, Stack } from '@mui/material'
import Modal from 'components/modals/Modal'
import Statics from 'objects/common/Statics'
import React, { useEffect, useRef } from 'react'

/**
 * Modal con el formulario de añadir responsable
 */
export default function FichajeIncidenciaModal({ open, incidencia }){

    const modalRef = useRef()
    useEffect(() => {
        if(open)
            modalRef.current.open()
    }, [open])

    return (
        <Modal ref={modalRef} title={"Detalles de la incidencia del fichaje"}>
            <Grid container spacing={2} p={5}>
                <Grid item xs={6} md={4}>
                    <Stack>
                        <Text fontWeight={"bold"}>Docente:</Text>
                        <Text>{(incidencia?.empleado?.nombre ?? "") + " " + (incidencia?.empleado?.apellido1 ?? "") + " " + (incidencia?.empleado?.apellido2 ?? "")}</Text>
                    </Stack>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Stack>
                        <Text fontWeight={"bold"}>Documento de identidad:</Text>
                        <Text>{(incidencia?.empleado?.tipo_documento?.nombre ?? "") + " - " + (incidencia?.empleado?.numero_documento ?? "")}</Text>
                    </Stack>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Stack>
                        <Text fontWeight={"bold"}>Motivo de la incidencia:</Text>
                        <Text>{incidencia?.motivo_incidencia_fichaje?.valor ?? ""}</Text>
                    </Stack>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Stack>
                        <Text fontWeight={"bold"}>Fecha de la incidencia:</Text>
                        <Text>{incidencia?.fecha ? Statics.formatDate(incidencia.fecha) : ""}</Text>
                    </Stack>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Stack>
                        <Text fontWeight={"bold"}>Tramo horario:</Text>
                        <Text>{(incidencia?.hora_inicio ?? "") + " - " + (incidencia?.hora_fin ?? "")}</Text>
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <Stack>
                        <Text fontWeight={"bold"}>Comentario:</Text>
                        <Text>{incidencia?.comentario ?? ""}</Text>
                    </Stack>
                </Grid>
            </Grid>
        </Modal>
    )
}
