import { v4 as uuid } from 'uuid'
import { COLOR } from 'theme/Colors'
import React, { Component } from 'react'
import { Search } from '@mui/icons-material'
import Statics from 'objects/common/Statics'
import { FormControl, FormHelperText, InputAdornment, InputLabel, ListSubheader, MenuItem, Select, TextField, Box, Chip } from '@mui/material'


/**
 * SelectCustom. Select de Mui personalizado
 * 
 * @params {Array} items = [{value: "x", label: "X"}]
 */
export default class SearcheableSelectCustom extends Component {

    /**
     * Constructor
     * 
     * @param {Object} props 
     */
    constructor(props) {
        super(props)

        this.state = {
            value: this.props.defaultValue ?? "",
            allItems: this.props.items ?? [],
            itemsToDisplay: this.props.items ?? [],
            searchText: "",
            defaultValueLoaded: false
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if(prevProps.items != this.props.items)
            this.setState({allItems: this.props.items, itemsToDisplay: this.props.items}, () => {
                if(this.state.allItems.length > 0 && this.state.allItems[0].value != 0 && this.props.defaultValue && !this.state.defaultValueLoaded) {
                    this.setState({defaultValueLoaded: true}, () => {
                        if(this.props.onDefaultValueLoaded)
                            this.props.onDefaultValueLoaded({target: {value: this.props.defaultValue}})
                    })
                }
                    
            })

        if(prevState.searchText != this.state.searchText)
            this.setState({itemsToDisplay: this.state.allItems.filter((option) => Statics.containsText(option.label, this.state.searchText))})

        if(this.props.wantToReset && !prevProps.wantToReset)
            this.reset()
    }

    //-------------------------------------------------------
    //-------------------- CLASS FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Callback para cuando se cambia de opcion en el selector
     * 
     * @param {Object} event
     */
    onChange = (event) => {
        this.setState({ value: event.target.value }, () => {
            if (this.props.onChange)
                this.props.onChange(event)
        })
    }


    /**
     * Resetea el valor del select
     */
    reset = () => {
        this.setState({ value: "" })
    }

    
    /**
     * Devuelve un objeto de la lista a partir del value
     * 
     * @param {Int} value
     */
    getItemByValue = (value) => {
        return this.state.allItems.find(item => item.value == value)
    }


    /**
     * Devuelve el valor elegido actualmente
     * 
     * @return {Int}
     */
    getValue = () => {
        return this.state.value
    }


    /**
     * Actualiza el valor del estado
     * 
     * @param {Int} value 
     */
    setValue = (value, callback = () => {}) => {
        this.setState({value}, callback)
    }

    getTypeProps = () => {
        const { type, items, multiple } = this.props
        const typeProps = {}

        if(type == "chip" && multiple) {
            typeProps.renderValue = (selected) => {
                return (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        { 
                            selected.map((value) => {
                                const item = items.find(item => item.value == value)
                                return <Chip key={value} label={item.label} />
                            })
                        }
                    </Box>
                )}
        }

        return typeProps
    }

    render() {
        const labelId = this.props.labelId ?? (this.props.label ? this.props.label + "-id" : uuid() + "-id")
        const typeProps = this.getTypeProps()

        return (
            <FormControl variant={this.props.variant} sx={{ m: 1, minWidth: this.props.minWidth ?? 200, width: this.props.width ?? "100%", margin: 0 }} size={this.props.size ?? "small"} className={this.props.className}>
                <InputLabel id={labelId}>{this.props.label ?? ""}</InputLabel>
                <Select
                    // Disables auto focus on MenuItems and allows TextField to be in focus
                    MenuProps   = {{ autoFocus: false }}
                    labelId     = {labelId}
                    disabled    = {this.props.disabled}
                    id          = "search-select"
                    value       = {this.state.value}
                    label       = {this.props.label ?? ""}
                    onChange    = {this.onChange}
                    name        = {this.props.name}
                    inputProps  = {{ ... this.props.register ? this.props.register(this.props.name) : {} }}
                    error       = {this.props.errors && this.props.errors[this.props.name] ? true : false}
                    onClose     = {() => {this.setState({searchText: ""})}}
                    multiple    = {this.props.multiple}
                    //renderValue = {() => this.state.allItems.find(item => item.value == this.state.value)?.label ?? ""}
                    {...typeProps}>
                        <ListSubheader>
                            <TextField
                                size="small"
                                // Autofocus on textfield
                                autoFocus
                                placeholder={this.props.searchPlaceholder ?? "Búsqueda..."}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                    )
                                }}
                                onInput ={(e) => {this.setState({searchText: e.target.value})}}
                                onKeyDown={(e) => {
                                    if (e.key !== "Escape") {
                                    // Prevents autoselecting item while typing (default Select behaviour)
                                        e.stopPropagation()
                                    }
                                }}/>
                        </ListSubheader>
                        {this.state.itemsToDisplay?.map(item => <MenuItem key={uuid()} value={item.value}>{item.label ?? ""}</MenuItem>)}
                </Select>

                {this.props.errors && this.props.errors[this.props.name]?.message ? <FormHelperText style={{ color: COLOR.error.main }}>{this.props.errors[this.props.name]?.message}</FormHelperText> : null}
            </FormControl>
        )
    }
}
