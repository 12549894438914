import { useAlert } from "hooks"
import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { EmpleadoListContext } from "contexts/employee/ListContext"

/**
 * Hook personalizado para gestionar la lógica específica de la inscripción individual.
 * Utiliza el contexto del empleado para obtener datos relevantes y manejar la navegación.
 * 
 * @param {Object} navigationParams Parámetros de navegación obtenidos desde el router.
 * @returns {Object} Contiene funciones y estados para manejar la inscripción.
 */
export default function useAcademiaInscripcionSingleLogic({ navigationParams, requests}) {
    const updateAlert = useAlert()

    const navigate = useNavigate()

    const { items, updateItem } = useContext(EmpleadoListContext)

    const [tab, setTab] = useState("1")
    const [isMounted, setIsMounted] = useState(false) //Se usa para los modales de incidencias (crear incidencia / seleccionar y editar incidencias no resueltas)
    const [id_inscripcion, setIdInscripcion] = useState(navigationParams?.id_inscripcion)
    const [inscripcion, setInscripcion] = useState(null)


    /**
     * Efecto que marca el hook como montado. Esto se utiliza para controlar la renderización condicional
     * o efectos que sólo deben ejecutarse una vez que el componente ha sido completamente montado.
     */
    useEffect(() => {
        setIsMounted(true)
    }, [])

    /**
     * Actualiza el ID de la inscripción y navega a la nueva ruta.
     * 
     * @param {string} id El nuevo ID de la inscripción.
     */
    const updateId = (id) => {
        navigate(`../academias/inscripciones/${id}`)
        setIdInscripcion(id)
    }

   

    /**
     * Función para obtener los detalles de una inscripción.
     * Si los datos no están disponibles en la lista de items o se solicita explícitamente obtenerlos del servidor,
     * realiza una petición para obtenerlos y actualiza el estado y contexto global.
     * 
     * @param {boolean} getFromServer - Indica si debe obtenerse la inscripción directamente del servidor.
     */
    const getInscripcion = (getFromServer = false) => {
        const inscripcion = items?.find(item => item.id == id_inscripcion)
        if (items.length == 0 || !inscripcion || getFromServer) {
            requests.getInscripcion(id_inscripcion, (inscripcion) => {
                setInscripcion(inscripcion)
                updateItem(inscripcion)
            }, () => updateAlert("No se ha podido obtener la inscripcion"))
        } else {
            setInscripcion(inscripcion)
        }
    }

    useEffect(getInscripcion, [id_inscripcion])

    const incidenciasNoResueltas = inscripcion?.alumno?.incidencias.filter(incidencia => incidencia.solucionada === 0)

    return { getInscripcion, isMounted, incidenciasNoResueltas, navigate, setInscripcion, updateId, id_inscripcion, inscripcion, tab, setTab }
}