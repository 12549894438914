import { Text } from 'components/texts'
import Requests from './Requests/Requests.js'
import { Header, ActividadData } from './parts'
import { Container, Paper, Stack } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import React, { useState, useEffect, useContext } from 'react'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { BreadcrumbsList, MoveButtons, Line } from 'components/data_display'

export default function SingleActividadColegio() {
  const navigationParams = useParams()
  const navigate = useNavigate()

  const { items } = useContext(EmpleadoListContext)

  const [id_actividad, setIdActividad] = useState(navigationParams.id_actividad)
  const [actividad,    setActividad]   = useState(null)

  /**
   * Hace la petición para obtener el usuario
   */
  const getActividad = () => {
    const item = items.find(item => item?.id == id_actividad)
    if(items.length == 0 || !item) {
      new Requests().getActividad(id_actividad, setActividad, () => enqueueSnackbar(<Text>No se ha podido recuperar la actividad.</Text>, { variant: "error" }))
    } else {
      setActividad(item)
    }
  }
  
  const updateIdAlumno = (id) => {
    navigate(`../colegios/actividades/${id}`)
    setIdActividad(id)
  }

  useEffect(getActividad, [id_actividad])

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>

        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/",        label: "Inicio" },
            { to: "./../../", label: "Colegios" },
            { to: "./../",    label: "Actividades" },
            { to: "./",       label: "Actividad #" + id_actividad, actualItem: true  },
          ]} />

          <MoveButtons 
            actualItemId        = {id_actividad}
            beforeFetchCallback = {() => setActividad(null)} 
            onUpdateActualItem  = {updateIdAlumno} />
        </Stack>
        
        <Paper elevation={2} sx={{mx: 8, my: 2, p: 5}}>
          {actividad ? <Header actividad={actividad}/> : null}

          <Line className={"my-5"}/>

          <ActividadData actividad={actividad}/>
        </Paper>

        <SnackbarProvider autoHideDuration={3000} />
      </Container>
  )
}
