import React, { Component } from "react"
import { COLOR } from "theme/Colors"

export default class Standard extends Component {
  //-------------------------------------------------------
  //------------------- RENDER FUNCTIONS ------------------
  //-------------------------------------------------------

  /**
   * Render
   *
   * @returns {Component}
   */
  render() {
    const styles = {
      color: this.props.color ?? COLOR.cancel.main,
      textDecoration: this.props.textDecoration ?? "none",
      fontWeight: this.props.fontWeight ?? "normal",
      fontStyle: this.props.fontStyle ?? "normal", // Agrego esta línea para controlar el estilo de la fuente
      marginBottom: this.props.marginBottom ?? "",

      ...this.props.style ?? {}
    }

    return (
      <span
        className = {"standard-text" + (this.props.className ? " " + this.props.className : "")}
        style     = {styles}>
        {this.props.children ?? ""}
      </span>
    )
  }
}
