import { enqueueSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { RequestAlumno, RequestDatosColegio } from 'objects/requests'
import Requests from 'pages/logged/admin/academias/inscripcion/edit/Requests/Requests.js'

/**
 * Hook personalizado para manejar la lógica de una inscripción específica.
 * Permite obtener los detalles de una inscripción y datos adicionales relacionados con el colegio del alumno inscrito.
 * 
 * @param {Object} navigationParams - Parámetros de navegación que incluyen el ID de la inscripción.
 * @param {Array} items - Lista de inscripciones disponibles.
 * @param {Function} updateItem - Función para actualizar el estado global o contexto con los detalles de la inscripción actual.
 * @returns {Object} Objeto con estados y funciones para interactuar con la inscripción y realizar peticiones relacionadas.
 */
export default function useWrapperLogic(navigationParams, items, updateItem) {
    const [inscripcion, setInscripcion] = useState(null)
    const [id_inscripcion, setIdInscripcion] = useState(navigationParams?.id_inscripcion)
    const [datosColegio, setDatosColegio] = useState([])
    const [alumno, setAlumno] = useState([])

    /**
     * Función para obtener los detalles de una inscripción.
     * Si los datos no están disponibles en la lista de items o se solicita explícitamente obtenerlos del servidor,
     * realiza una petición para obtenerlos y actualiza el estado y contexto global.
     * 
     * @param {boolean} getFromServer - Indica si debe obtenerse la inscripción directamente del servidor.
     */
    const getInscripcion = (getFromServer = false) => {
        const inscripcion = items?.find(item => item.id == id_inscripcion)
        if (items.length == 0 || !inscripcion || getFromServer) {
            new Requests().getInscripcion(id_inscripcion, (inscripcion) => {
                setInscripcion(inscripcion)
                updateItem(inscripcion)

                // Se obtienen los datos del alumno
                getAlumno(inscripcion?.alumno_id)

                //Se obtienen los datos del colegio
                getDatosColegio(inscripcion?.alumno_id)
            }, () => enqueueSnackbar("No se ha podido obtener la inscripcion"))
        } else {
            setInscripcion(inscripcion)
        }

        // Se obtienen los datos del alumno
        getAlumno(inscripcion?.alumno_id)

        //Se obtienen los datos del colegio
        getDatosColegio(inscripcion?.alumno_id)
    }

    /**
     * Función para obtener datos adicionales del colegio relacionados con el alumno inscrito.
     * Realiza una petición al servidor utilizando los parámetros necesarios y maneja la respuesta.
     * Si el alumno no tiene datos de colegio, el estado se establece a ARRAY VACIO.
     * 
     * @param {Number} alumnoId - La ID del alumno de la inscripcion
     */
    const getDatosColegio = (alumnoId) => {
        const params = { id_alumno: alumnoId }

        new RequestDatosColegio().search(params, (res) => {
            setDatosColegio(res?.items?.data)
        }, (err) => {
            setDatosColegio([])
        })
    }

    const getAlumno = (alumnoId) => {
        const params = {
            relations: 'sexo,tipo_documento,provincia,tipo_via,provincia_nacimiento,responsables,domiciliacion_bancaria'
        }

        new RequestAlumno().get(alumnoId, params, (res) => {
            setAlumno(res?.alumno)
        }, (err) => {
            setAlumno([])
        })
    }

    useEffect(getInscripcion, [id_inscripcion])

    return { datosColegio, inscripcion, id_inscripcion, alumno, setIdInscripcion, setInscripcion, getInscripcion, setAlumno }
}