import { H3 } from 'components/texts'
import { columns } from './columns.js'
import { Box, Paper } from '@mui/material'
import Container from '@mui/material/Container'
import React, { useEffect, useState } from 'react'
import { Search as SearchIcon } from '@mui/icons-material'
import { ListTableHeader } from 'pages/logged/admin/components'
import { Accordion, BreadcrumbsList, Datagrid } from 'components/data_display'
import SearchFormIncidenciaFichaje, { search } from 'components/forms/display/empleado/fichaje/incidencia/SearchForm'
import ModalIncidencia from './parts/ModalIncidencia'

export default function ListadoIncidenciaFichaje() {
  const [data,         setData]         = useState({data: [], rowCount: 0})
  const [loadingTable, setLoadingTable] = useState(false)
  const [params,       setParams]       = useState(null)
  const [modalInfo,    setModalInfo]    = useState({open: false, incidencia: null})

  useEffect(() => {
    if(modalInfo.open) setModalInfo({open: false, incidencia: modalInfo.incidencia})
  }, [modalInfo.open])

  useEffect(() => {
    setLoadingTable(false)
  }, [data])

  /**
   * Callback para actualizar la tabla una vez hecha la petición
   * 
   * @param {Array} data 
   * @param {Int}   rowCount 
   */
  const updateTable = (data, rowCount, page, params) => {
    setData({data, rowCount})
    setParams(params)
  }
  

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <BreadcrumbsList breadcrumbsList={[
        { label: "Inicio",    to: "/" },
        { label: "Empleados", to: "../../empleado/listado" },
        { label: "Fichaje" },
        { label: "Listado de incidencias", to: "./", actualItem: true },
      ]} />

      <Accordion header={<Box display={"flex"} alignItems={"center"}><SearchIcon className={"mx-5"} /> <H3 className="my-0">Buscar incidencias</H3></Box>}>
        <SearchFormIncidenciaFichaje
          searchCallback    = {updateTable}
          onLoadingCallback = {setLoadingTable} />
      </Accordion>

      <Paper elevation={2} sx={{mx: 8, my: 2, p: 5}}>
        <ListTableHeader rowCount={data.rowCount} params={params} keyValue={"fichajes_incidencias"} hideAddButton/>

        <Datagrid
          rows             = {data.data}
          columns          = {columns((incidencia) => setModalInfo({open: true, incidencia}))}
          loading          = {loadingTable}
          rowCount         = {data.rowCount}
          onRowDoubleClick = {({row: incidencia}) => setModalInfo({open: true, incidencia})}
          onPageChange     = {(page) => { search(page, () => setLoadingTable(true), updateTable) }}
          serverSide />

        <ModalIncidencia {...modalInfo}/>
      </Paper>
    </Container>
  )
}
