import React, { Component } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Login, RecoverPassword, ChangePassword } from '../../pages/not_logged'
import { NotLoggedRoot } from '../../pages/components/roots'
import { publicRoutes } from '../routes.js'

export default class NotLoggedRoutes extends Component {
  render() {
    return (
        <Routes>
            <Route element={<NotLoggedRoot />}>
                <Route index                                element={<Login />} />
                <Route path={publicRoutes.RECOVER_PASSWORD} element={<RecoverPassword />} />
                <Route path={publicRoutes.RESET_PASSWORD}   element={<ChangePassword />} />
            </Route>
        </Routes>      
    )
  }
}
