import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { AdminSidebar } from '../../sidebars'
import { AdminHeader } from '../../headers'
import { useSelector } from 'react-redux'
import { LogoutButton } from '../../headers/components'
import { H3 } from 'components/texts'

export default function AdminRoot({ sidebar = null }) {
  const { user, roles } = useSelector((state) => state.userState)

  const setHeaderAndSidebar = () => {
    document.body.setAttribute('data-kt-app-header-fixed',         sidebar ? 'true' : 'false')
    document.body.setAttribute('data-kt-app-sidebar-enabled',      sidebar ? 'true' : 'false')
    document.body.setAttribute('data-kt-app-sidebar-fixed',        sidebar ? 'true' : 'false')
    document.body.setAttribute('data-kt-app-sidebar-hoverable',    sidebar ? 'true' : 'false')
    document.body.setAttribute('data-kt-app-sidebar-push-header',  sidebar ? 'true' : 'false')
    document.body.setAttribute('data-kt-app-sidebar-push-toolbar', sidebar ? 'true' : 'false')
    document.body.setAttribute('data-kt-app-sidebar-push-footer',  sidebar ? 'true' : 'false')
  }

  useEffect(() => {
    setHeaderAndSidebar()
  }, [sidebar])

  return (
    <div className="d-flex flex-column flex-root h-100" id="kt_app_root">
      <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
        <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
          {roles.includes('admin') ? 
            <AdminHeader /> 
            : 
            <div id="kt_app_header" className={"app-header app-header-custom justify-content-between px-20"}>
              <div className="d-flex flex-row align-items-center">
                <img alt="Logo" src={process.env.PUBLIC_URL + '/images/icons/cl-logo.svg'} className="h-75px app-sidebar-logo-default pt-2" />
                <H3 className="ms-10">¡Hola, {user.nombre}!</H3>
              </div>

              <LogoutButton />
            </div>
            }

          {sidebar ? <AdminSidebar sidebar={sidebar}/> : null}
          
          <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}