import { templates } from './template'
import React from 'react'

export default function Headers({ headerRef, template, title, actividad, needMargin = false }) {

    /**
     *  
     * templates viene del fichero templates.js que tiene un objecto que contiene dos componentes asociados a una clave que es su nombre,
     * en función de donde quieras llamar este header le tienes que pasar el template especifico.
     * 
     * Data son los datos de donde lo estes usando, le tienes que pasar el dato ya en si, no le pases un objeto, es decir, si tienes alumnos, en vez de pasar alumnos
     * tienes que pasarle alumno.nombre para que asi puedas reusar el componente en muchos más sitios.
     */

    const ActualTemplate = templates[template]

    return (
        <ActualTemplate
            headerRef={headerRef}
            title={title}
            data={actividad}
            margin={needMargin}
        />
    )
}