/**
 * Importaciones de componentes de plantilla para cada tipo de búsqueda. Estos componentes son usados
 * para renderizar formularios o vistas específicas según el criterio de búsqueda seleccionado por el usuario.
 */
import {
    DatosAlumnosTemplate,
    DatosAlumnoColegiosTemplate,
    DatosAlumnoGrupoColegioTemplate,
    DatosAlumnoGrupoCLTemplate,
} from "./parts"

// Un string JSON que define el orden por defecto para ordenar los alumnos por sus apellidos y nombre.
const alumnoOrder = '{"alumno.apellido1": "ASC", "alumno.apellido2": "ASC", "alumno.nombre": "ASC"}'

/**
 * itemsSearch define las opciones disponibles para la búsqueda de datos de alumnos.
 * Cada objeto en el array representa un criterio de búsqueda diferente, especificando:
 * - value: Un identificador único para el criterio de búsqueda.
 * - columnKey: La clave que representa la categoría de datos a buscar (utilizada internamente para lógica de filtrado).
 * - label: La etiqueta visible para el usuario que describe el criterio de búsqueda.
 * - template: El componente de plantilla que se debe renderizar cuando se selecciona este criterio.
 */
export const itemsSearch = [
    { value: 0, columnKey: "alumnos", label: "Datos del Alumno en el Colegio por Alumnos", template: DatosAlumnosTemplate },
    { value: 1, columnKey: "colegios", label: "Datos del Alumno en el Colegio por Colegios", template: DatosAlumnoColegiosTemplate },
    { value: 2, columnKey: "grupos_colegio", label: "Datos del Alumno en el Colegio por Grupos de Colegios", template: DatosAlumnoGrupoColegioTemplate },
    { value: 3, columnKey: "grupos_cl", label: "Datos del Alumno en el Colegio por Grupos de CL", template: DatosAlumnoGrupoCLTemplate },
]

export const itemsOrder = [
    [
        { value: alumnoOrder, label: "Alumnos" },
    ],
    [
        { value: alumnoOrder, label: "Alumnos" },
        { value: '{"colegio.nombre": "ASC"}', label: "Colegios" },
        { value: '{"curso_colegio.id": "ASC"}', label: "Cursos" },
    ],
    [
        { value: alumnoOrder, label: "Alumnos" },
        { value: '{"grupo_colegio.clave": "ASC"}', label: "Grupos" },
    ],
    [
        { value: alumnoOrder, label: "Alumnos" },
        { value: '{"grupo_academia.clave": "ASC"}', label: "Grupos" },
    ],
]
