import RequestMaster from '../RequestMaster'

/**
 * Clase de servicio para manejar las peticiones HTTP asociadas a los pedidos de libros.
 * Extiende de `RequestMaster`, heredando sus métodos y propiedades básicas para realizar peticiones.
 */
export default class RequestPedidoLibro extends RequestMaster {

    constructor() {
        super()
        // Se establece la base de la URL para las peticiones relacionadas con pedidos de libros.
        this.URL_BASE = this.URL_BASE + '/libro/pedido'
    }

    /**
    * Realiza una petición POST para crear pedidos de libros.
    *
    * @param {FormData} params Datos del formulario a enviar en la petición.
    * @param {Function} callback Función a ejecutar si la petición es exitosa.
    * @param {Function} callbackError Función a ejecutar si la petición falla.
    * @param {Function} callbackFinally Función a ejecutar al finalizar la petición, sin importar el resultado.
    */
    create(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.post(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Realiza una petición PUT para editar múltiples pedidos de libros.
     *
     * @param {FormData} params Datos del formulario a enviar en la petición.
     * @param {Function} callback Función a ejecutar si la petición es exitosa.
     * @param {Function} callbackError Función a ejecutar si la petición falla.
     * @param {Function} callbackFinally Función a ejecutar al finalizar la petición, sin importar el resultado.
     */
    edit(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "update"
        this.put(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Realiza una petición DELETE para eliminar múltiples pedidos de libros.
     *
     * @param {FormData} params Datos del formulario a enviar en la petición.
     * @param {Function} callback Función a ejecutar si la petición es exitosa.
     * @param {Function} callbackError Función a ejecutar si la petición falla.
     * @param {Function} callbackFinally Función a ejecutar al finalizar la petición, sin importar el resultado.
     */
    deleteMultiple(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        console.log('params', params);
        const path = "destroy-multiple"
        this.delete(path, params, callback, callbackError, callbackFinally)
    }
}
