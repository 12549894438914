import './style.css'
import React from 'react'
import { v4 as uuid } from 'uuid'
import Box from '@mui/material/Box'
import { H3, Text } from 'components/texts'
import { TableColumn } from 'components/forms/components'
import { useLibrosDevueltosLogic } from 'hooks'

/**
 * Componente `LibrosDevueltos` para mostrar una lista de libros devueltos filtrados por curso escolar y el total de unidades devueltas.
 * 
 * @param {Array} librosDevueltos - Array que contiene los libros devueltos.
 * @param {String} cursoEscolar - Curso escolar seleccionado para filtrar los libros devueltos.
 * @returns {JSX.Element} - Un componente visual que muestra la lista de libros devueltos y el total de unidades devueltas.
 */
export default function LibrosDevueltos({ librosDevueltos, cursoEscolar }) {
    const { librosToRender, sumDevueltas } = useLibrosDevueltosLogic(librosDevueltos, cursoEscolar)

    return (
        <Box px={2}>
            <H3>Libros devueltos: </H3>
            <table className='libros-devueltos-table'>
                <thead>
                    <tr>
                        <th><Text fontWeight={"bold"}>Fecha</Text></th>
                        <th><Text fontWeight={"bold"}>Unidades devueltas</Text></th>
                    </tr>
                </thead>
                <tbody className='no-border'>
                    {librosToRender.map((item, index, array) => (
                        <tr key={uuid()}>
                            <TableColumn defaultValue={item.fecha} type={"date"} disabled />

                            <TableColumn defaultValue={item.cantidad_devuelta} disabled />
                        </tr>
                    ))}

                    <tr className="total-unidades">
                        <td className='bg-white'><Text fontWeight="bold">Total devueltas</Text></td>
                        <td><Text>{sumDevueltas}</Text></td>
                    </tr>
                </tbody>
            </table>

        </Box>
    )
}