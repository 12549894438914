import { useEffect, useState } from "react"
import { useAlert, useErrorHandler } from "hooks"
import { RequestCursoColegio } from "objects/requests"

export default function useAgeCheck({ alumnoEdad }) {
    // Estado para almacenar los cursos-colegio
    const [cursosColegio, setCursosColegio] = useState([])

    const handleError = useErrorHandler()

    const updateAlert = useAlert()

    /**
     * Función para obtener los cursos del colegio. Realiza una petición al servidor y maneja la respuesta.
     */
    const getCursosColegio = () => {
        const requestCursoColegio = new RequestCursoColegio()

        const callback = (res) => setCursosColegio(res.cursos_colegio)
        const callbackError = (err) => handleError(err)

        requestCursoColegio.all({}, callback, callbackError)
    }

    useEffect(getCursosColegio, [])

    /**
     * Función que verifica que la edad del alumno corresponda con la edad que debe tener en el curso,
     * ejemplo: para 1ºEI(educación infantil), 3 años.
     * 
     * @param {object} item 
     */
    const checkEdad = (item) => {
        const idCurso = item.id_curso_colegio // id del curso (1ºESO...)
        if (cursosColegio.find(objeto => objeto.id == idCurso).edad != alumnoEdad)
            updateAlert("La edad no se corresponde con el curso.", "warning")
    }

    return { checkEdad }
}