import React, { useState } from 'react'
import { Grid, FormControlLabel, RadioGroup, Radio, FormGroup, Box } from '@mui/material'
import { CursoEscolarSelect, ItemsSelect } from 'components/forms/components/Select/custom'
import { Checkbox } from "components/forms/components"

export default function ProfesoradoTemplate({ register, errors, reset, setValue }) {
    const [opcionSeleccionada, setOpcionSeleccionada] = useState('adulto');
    const [opcionAcademia, setOpcionAcademia] = useState(false)
    const [opcionColegio, setOpcionColegio] = useState(false)
    const [opcionEmpresa, setOpcionEmpresa] = useState(false)

    const fieldsetStyles = {
        position: 'relative',
        border: '1px solid #7F8790',
        borderRadius: '4px',
        padding: '30px',
    };

    const legendStyles = {
        position: 'absolute',
        top: '-18px',
        backgroundColor: '#fff',
        borderRadius: '4px',
        width: 'auto',
    };

    const destinatario = "profesorado"
    return (
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={4}>
            <Grid item xs={3}>
                <CursoEscolarSelect name={`${destinatario}.id_curso_escolar`} register={register} errors={errors} wantToReset={reset} defaultValue={null} setValue={setValue} searcheable allOption />
            </Grid>

            <Grid item xs={3}>
                <ItemsSelect name={`${destinatario}.id_tipo_actividad`} keyValue={"tipo_actividad"} register={register} errors={errors} allOption />
            </Grid>

            <Grid item xs={3}>
                <ItemsSelect name={`${destinatario}.id_actividades`} keyValue={"actividad"} register={register} errors={errors} wantToReset={reset} searcheable allOption />
            </Grid>

            <Grid item xs={3}>
                <ItemsSelect name={`${destinatario}.id_modalidad_cursos`} keyValue={"modalidad_cursos"} register={register} errors={errors} wantToReset={reset} searcheable allOption />
            </Grid>

            <Grid item xs={5} mt={4}>
                <Box component="fieldset" display="flex" sx={fieldsetStyles} flexDirection={"row"}>
                    <FormGroup>
                        <legend style={legendStyles} >
                            <FormControlLabel sx={{ marginLeft: 1 }}
                                control={<Checkbox name={`${destinatario}.academia_cb`} label={"Academia"} register={register} onClick={(e) => setOpcionAcademia(e)} defaultChecked={null} />} />
                        </legend>
                        <RadioGroup

                            onChange={({ target }) => setOpcionSeleccionada(target.value)}
                            value={opcionSeleccionada}
                            sx={{ flexWrap: 'initial' }}
                            row>
                            <FormControlLabel value="niño" control={<Radio disabled={!opcionAcademia} />} label="Niños" />
                            <FormControlLabel value="adulto" control={<Radio disabled={!opcionAcademia} />} label="Adultos" />
                        </RadioGroup>
                    </FormGroup>
                    <ItemsSelect
                        key={opcionSeleccionada}
                        disabled={!opcionAcademia}
                        register={register}
                        errors={errors}
                        name={`${destinatario}.id_nivel_grupo_academia`}
                        keyValue={opcionSeleccionada === 'adulto' ? "nivel_grupo_adulto" : "nivel_grupo_niño"} allOption />
                </Box>
            </Grid>

            <Grid item xs={5} mt={4}>
                <Box component="fieldset" display="flex" sx={fieldsetStyles} flexDirection={"row"}>
                    <FormGroup sx={{ mr: 2 }}>
                        <legend style={legendStyles} >
                            <FormControlLabel sx={{ marginLeft: 1 }}
                                control={<Checkbox name={`${destinatario}.colegios_cb`} onClick={(e) => setOpcionColegio(e)} label={"Colegios"} register={register} defaultChecked={null} />} />
                        </legend>
                        <ItemsSelect
                            allOption
                            label={"Colegio donde se imparte"}
                            name={`${destinatario}.id_colegio`}
                            keyValue={"colegio"}
                            register={register}
                            disabled={!opcionColegio}
                            errors={errors}
                            searcheable />
                    </FormGroup>
                    <ItemsSelect
                        allOption
                        searcheable
                        register={register}
                        errors={errors}
                        disabled={!opcionColegio}
                        name={`${destinatario}.id_nivel_grupo_colegio`}
                        keyValue={"nivel_grupo"} />
                </Box>
            </Grid>

            <Grid item xs={2} mt={2}>
                <Box mb={1}>
                    <Checkbox name={`${destinatario}.empresa`} label={"Empresas"} register={register} defaultChecked={null} onClick={(e) => setOpcionEmpresa(e)} />
                    <ItemsSelect
                        allOption
                        register={register}
                        errors={errors}
                        disabled={!opcionEmpresa}
                        name={`${destinatario}.id_empresa`}
                        keyValue={"empresa"} />
                </Box>
                <Checkbox name={`${destinatario}.particular`} label={"Particulares"} register={register} defaultChecked={null} />
            </Grid>
        </Grid>
    )
}
