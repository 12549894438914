import axios from 'axios';
import { API_URL } from 'constants/Api';
import { deleteUser } from 'store/actions/userActions';
import store from 'store/store';

/**
 * Configuración de la instancia de Axios para las peticiones a la API.
 * Se incluyen interceptores para las solicitudes y respuestas, gestionando la autenticación
 * y el manejo de errores, como la expiración del token de autorización.
 */
const instance = axios.create({
  baseURL: API_URL,
});

/**
 * Interceptor de solicitud para añadir el token de autorización en los encabezados.
 */
instance.interceptors.request.use(
  config => {
    // Acceso al estado del usuario desde el store de Redux.
    const { userState } = store.getState();
    // Si el token existe, se añade al encabezado de autorización.
    if (userState && userState.token) {
      config.headers['Authorization'] = `Bearer ${userState.token}`;
    }
    return config;
  },
  error => {
    // Manejo de errores en la solicitud.
    return Promise.reject(error);
  }
);

/**
 * Interceptor de respuesta para manejar respuestas específicas como la expiración del token.
 */
instance.interceptors.response.use(
  response => response, // En caso de respuesta exitosa, simplemente se retorna la respuesta.
  error => {
    // Si el servidor responde con un error 401 (No autorizado), se despacha la acción deleteUser.
    if (error.response && error.response.status === 401) {
      store.dispatch(deleteUser());
    }
    // Rechazar la promesa, permitiendo que el llamador maneje el error.
    return Promise.reject(error);
  }
);

export default instance; // Exportar la instancia configurada de axios.