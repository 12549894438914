import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

export default function CollapsableItem({ title, childTitle }) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
        <ListItemButton onClick={handleClick}>
            <ListItemText primary={title ?? ""} />
            {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText primary={childTitle ?? ""} />
                </ListItemButton>
            </List>
        </Collapse>
    </>
  );
}