import React, { useState } from "react";
import { Grid, Box, Skeleton, Tabs, Tab, FormControlLabel, Checkbox, Stack } from "@mui/material";
import { COLOR } from 'theme/Colors.js'
import { H3, Text } from 'components/texts/index.js'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { TabContext } from '@mui/lab'
import { ActividadesData, DatosCurriculumData } from './parts'

/**
 * Formulario con los datos del currículum del empleado
 * 
 */
export default function DataCurriculumEmpleado({ empleado }) {
    const [tab, setTab] = useState("5")

    return (
        <Grid container p={3} direction={'row'}>

            <Grid item mb={2} p={2} xs={12}>

                <Grid container spacing={2} alignItems="center">

                    <Grid item xs={10} >
                        <H3>Datos Entrega</H3>
                    </Grid>

                    <Grid item xs={2} >
                        <FormControlLabel control={<Checkbox />} label="Entrega Currículum" disabled />
                    </Grid>

                    <Grid item xs={4} mt={2}>
                        {empleado ? <Stack>
                            <Text fontWeight={"bold"}>Nº Curriculum</Text>
                            <Text>{empleado?.num_curriculum ?? ""}</Text>
                        </Stack> : <Skeleton variant="text" width={'60%'} />}
                    </Grid>

                    <Grid item xs={4} >
                        {empleado ? <Stack>
                            <Text fontWeight={"bold"}>Fecha</Text>
                            <Text>{empleado?.fecha ?? ""}</Text>
                        </Stack> : <Skeleton variant="text" width={'60%'} sx={{ mt: 2 }} />}
                    </Grid>

                    <Grid item xs={4} mt={2}>
                        {empleado ? <Stack>
                            <Text fontWeight={"bold"}>Archivo</Text>
                            <Text>{empleado?.archivo ?? ""}</Text>
                        </Stack> : <Skeleton variant="text" width={'60%'} />}
                    </Grid>

                </Grid>
            </Grid>

            <Grid item mt={2} xs={12}>
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2} >
                        <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                            <Tab label={<Text fontWeight={tab === "5" ? "normal" : null} color={tab === "1" ? COLOR.standard.main : null}>Datos Actividades</Text>} value={"5"} />
                            <Tab label={<Text fontWeight={tab === "6" ? "normal" : null} color={tab === "2" ? COLOR.standard.main : null}>Datos Currículum</Text>} value={"6"} />
                        </Tabs>
                    </Box>

                    <TabPanel value={"5"}>
                        <ActividadesData  empleado={empleado}/>
                    </TabPanel>

                    <TabPanel value={"6"}>
                        <DatosCurriculumData empleado={empleado}/>
                    </TabPanel>

                </TabContext>

            </Grid>
        </Grid >
    )
}
