import RequestMaster from '../RequestMaster'

export default class RequestAlumno extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/acreedor'
    }

    /**
     * Hace el logueo del usuario
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    show(id, params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = id
        this.get(path, params, callback, callbackError, callbackFinally)
    }

}
