import { H3, Text } from 'components/texts'
import { Grid, Stack, Tooltip } from '@mui/material'
import { QuestionMark as InfoIcon } from '@mui/icons-material'
import { Button } from 'components/forms/components'
import TableMesesPagados from './parts/TableMesesPagados'
import React, { useState } from 'react'
import { ItemsSelect } from 'components/forms/components/Select/custom'
import SelectFormaPago from "components/forms/components/Select/custom/SelectFormaPago/index.jsx";
import { Input, DatePicker, Checkbox } from 'components/forms/components'
import { InsertDriveFile as FileIcon, CardMembership as CardMember } from '@mui/icons-material'
import { enqueueSnackbar } from 'notistack'
import { usePagoDataFormLogic, useReciboLogic } from 'hooks'


const TIPO_MATRICULA_SI = 1
const TIPO_MATRICULA_NO = 2
const TIPO_MATRICULA_EXENTO = 3


export default function PagoInscripcionAcademiaDataForm({ inscripcion, register, errors, setValue, handleSubmit, actividad, getValues, alumno }) {

    const { cantidadInputRef, cantidadMatriculaInputRef, updateSelect, setUpdateSelect } = usePagoDataFormLogic(actividad, setValue)

    const { setRecibosContent, isReciboDisabled, handleRecibo } = useReciboLogic(getValues, inscripcion)

    const [prevValue, setPrevValue] = useState(null);

    return (
        <Grid container py={3}>

            <Grid container p={3} spacing={2} display={"flex"} alignItems={"center"}>
                <Grid item display={'flex'} flexDirection={'row'} alignItems={'center'} xs={12} md={4}>
                    <SelectFormaPago
                        name={"forma_pago_id"}
                        keyValue={"forma_pago_inscripcion"}
                        register={register}
                        errors={errors}
                        label="Forma de pago"
                        className={"w-100"}
                        defaultValue={inscripcion?.forma_pago_id ?? ""}
                        alumno={alumno} />
                    {/* //TODO: Cambiar elemento tooltip de información por componente personalizado */}
                    <Tooltip arrow title={<Text>
                        Tenga en cuenta que si el alumno seleccionado no dispone de domiciliación bancaria definida, NO podrás usar la forma de pago "Domiciliado"
                    </Text>}>
                        <InfoIcon color={"info"} className={"mx-2"} />
                    </Tooltip>
                </Grid>
            </Grid>

            <Grid container p={3} spacing={2} display={"flex"}>
                <Grid item xs={6} md={4}>
                    <ItemsSelect
                        name={"tipo_matricula_id"}
                        keyValue={"tipo_matricula"}
                        register={register}
                        errors={errors}
                        updateValue={updateSelect}
                        className={"w-100"}
                        onChange={({ target }) => {
                            
                            console.log('inscripcion?.matricula', inscripcion?.matricula)
                            if (!actividad && target.value == TIPO_MATRICULA_SI) {
                                enqueueSnackbar(<Text>No se ha elegido ninguna actividad.</Text>, { variant: "error" })
                                setUpdateSelect({ update: true, value: TIPO_MATRICULA_NO })
                            } else if (!actividad?.matricula && target.value == TIPO_MATRICULA_SI) {
                                enqueueSnackbar(<Text>La actividad elegida no tiene matrícula.</Text>, { variant: "error" })
                                setUpdateSelect({ update: true, value: TIPO_MATRICULA_NO })
                            } else if (actividad?.matricula && target.value == TIPO_MATRICULA_NO) {
                                enqueueSnackbar(<Text>La actividad tiene matricula asociada, debe seleccionar Sí o Exento/a.</Text>, { variant: "error" })
                                setUpdateSelect({ update: true, value: TIPO_MATRICULA_SI })
                            }
                            if(target.value == TIPO_MATRICULA_EXENTO) {
                                setPrevValue(cantidadMatriculaInputRef.current.getValue());
                                cantidadMatriculaInputRef.current.setValue(0);
                            } else {
           
                                if(prevValue !== null) {
                                    cantidadMatriculaInputRef.current.setValue(prevValue);
                                    setPrevValue(null);  
                                }
                            }
                        }}
                        defaultValue={inscripcion?.tipo_matricula_id ?? ""} />
                </Grid>

                <Grid item xs={6} md={4}>
                    <Input ref={cantidadMatriculaInputRef} register={register} errors={errors} label="Cantidad matricula" name={"matricula"}
                        className="w-100" defaultValue={inscripcion?.matricula ?? ""} type={"number"} step="any" />
                </Grid>

                <Grid item xs={6} md={4}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_pago_matricula"}
                        label={"Fecha de pago"} defaultValue={inscripcion?.fecha_pago_matricula} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={6} >
                    <ItemsSelect
                        name={"tipo_pago_id"}
                        keyValue={"tipo_pago"}
                        register={register}
                        errors={errors}
                        defaultValue={inscripcion?.tipo_pago_id ?? ""} />
                </Grid>

                <Grid item xs={6} >
                    <Input
                        ref={cantidadInputRef}
                        label="Cantidad"
                        register={register}
                        errors={errors}
                        className="w-100"
                        name={"cantidad_pago"}
                        defaultValue={inscripcion?.cantidad_pago ?? ''}
                        type={"number"}
                        uppercase
                        useValue />
                </Grid>

                <Grid item xs={12}>
                    <Stack direction={"row"} columnGap={2}>
                        <Button disabled={isReciboDisabled} onClick={handleRecibo} startIcon={<FileIcon />}>
                            Recibo
                        </Button>

                        <Button startIcon={<CardMember />} variant={"outlined"} onClick={handleSubmit}>
                            Certificado
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
            <Grid container item xs={12} xl={5} style={{ borderLeft: "1px solid #D9D9D9" }} p={3}>

                <Grid item xs={12}>
                    <H3>Contrato</H3>
                </Grid>
                <Grid item xs={6}>
                    <Checkbox name={"contrato_emitido"} label={"Emitido"} register={register} />
                </Grid>

                <Grid item xs={6}>
                    <Checkbox name={"contrato_recibido"} label={"Recibido"} register={register} />
                </Grid>

            </Grid>
            <Grid container mx={3} mt={5} pb={2} sx={{ overflowX: "auto" }} className={"darker-scrollbar"}>
                <TableMesesPagados inscripcion={inscripcion} register={register} setValue={setValue} onUpdateCallback={(newPagos) => setRecibosContent(newPagos)} />
            </Grid>

            <Grid container px={3} mt={5}>
                <Input placeholder={"Observaciones de los pagos..."} register={register} errors={errors} className="w-100" name={"observaciones"}
                    defaultValue={inscripcion?.observaciones ?? ''} multiline minRows={6} maxRows={10} uppercase />
            </Grid>
        </Grid >
    )
}
