import RequestMaster from '../RequestMaster'

//TODO: NO SÉ SI ESTE REQUEST SIRVE DE ALGO. VOY A CREAR UN RequestImage, PARA OBTENER LAS IMAGENES POR EFECTO EN FORMULARIOS DE CREACION DE ALUMNO Y TAL

export default class RequestFile extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/images'
    }
}
