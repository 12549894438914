import * as Yup from 'yup'

export const validation = {
    alumno_id:               Yup.string().required('El alumno es obligatorio.'),
    id_curso_escolar:        Yup.string().required('El curso escolar es obligatorio.'),
    id_forma_recogida:       Yup.string().required('La forma de recogida es obligatoria.'),
    id_forma_conocimiento:   Yup.string().required('La forma de conocimiento es obligatoria.'),
    registro_actividad_id:   Yup.string().required('La actividad es obligatoria.'),
    fecha_inscripcion:       Yup.string().typeError('La fecha de alta es obligatoria.').required('La fecha de alta es obligatoria.'),
    fecha_inicio: Yup.string().when("profesor_id", {
        is: val => typeof val === "number",
        then: Yup.string().typeError('La fecha de alta es obligatoria.').required('La fecha de inicio es obligatoria.')
    }),
}

export const validationSchema = Yup.object().shape(validation)