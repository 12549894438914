import { useMediaQuery, useTheme } from "@mui/material";

const useCustomMediaQuery = () => {
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.only('xs'))
  const isMd = useMediaQuery(theme.breakpoints.only('md'))
  const isXl = useMediaQuery(theme.breakpoints.only('xl'))

  //buttonSize
  const buttonSize = isXs ? "small" : isMd ? "medium" : isXl ? "large" : "medium";

  const componentSizes = {
    buttonSize: buttonSize,
  }
  
  return { theme, isXs, isMd, isXl, componentSizes}
}

export default useCustomMediaQuery;