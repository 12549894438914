import { TabContext } from '@mui/lab'
import { COLOR } from 'theme/Colors.js'
import { useNavigate, useParams } from 'react-router'
import Requests from './Requests/Requests.js'
import { Text, H3 } from 'components/texts'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import React, { useState, useEffect, useContext } from 'react'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
import { Box, Container, Paper, Stack, Tab, Tabs } from '@mui/material'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { Button } from 'components/forms/components'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { GeneralInscripcionData, GrupoInscripcionData, PagoInscripcionData, LibroInscripcionData } from './parts'
import { Edit as EditIcon } from '@mui/icons-material'
import Skeleton from './skeleto.jsx'
export default function Wrapper() {
  const navigationParams = useParams()

  const { items } = useContext(EmpleadoListContext)

  const [inscripcion, setInscripcion] = useState(null)
  const [tab, setTab] = useState("1")
  const [id_inscripcion, setIdInscripcion] = useState(navigationParams.id_inscripcion)

  const getInscripcion = () => {
    const item = items.find(item => item?.id == id_inscripcion)
    if (items.length == 0 || !item) {
      new Requests().getInscripcion(id_inscripcion, setInscripcion, (res) => enqueueSnackbar(<Text>{"No se ha podido cargar la inscripción: " + res}</Text>, { variant: "error" }))
    } else {
      setInscripcion(item)
    }
  }

  useEffect(getInscripcion, [id_inscripcion])

  return (
    <React.Fragment>
      <SingleInscripcionColegio key={inscripcion?.id ?? 0} inscripcion={inscripcion} id_inscripcion={id_inscripcion} setIdInscripcion={setIdInscripcion} setInscripcion={setInscripcion} tab={tab} setTab={setTab} />

      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}

function SingleInscripcionColegio({ inscripcion, id_inscripcion, setIdInscripcion, setInscripcion, tab, setTab }) {
  const navigate = useNavigate()

  const updateId = (id) => {
    navigate(`../colegios/inscripciones/${id}`)
    setIdInscripcion(id)
  }

  return (
    <>
      {inscripcion ?
        <Container maxWidth={false} sx={{ mt: 5 }}>
          <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
            <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} marginX={8}>
              <BreadcrumbsList mx={0} breadcrumbsList={[
                { to: "/", label: "Inicio" },
                { to: "./../../", label: "Colegios" },
                { to: "./../", label: "Inscripciones" },
                { to: "./", label: "Inscripción #" + (id_inscripcion ?? "0000"), actualItem: true },
              ]} />

              <MoveButtons
                actualItemId={id_inscripcion}
                beforeFetchCallback={() => setInscripcion(null)}
                onUpdateActualItem={updateId} />
            </Stack>

            <Paper elevation={2} sx={{ mx: 8, my: 2 }}>
              <Stack py={2} px={5} display={"flex"} flexDirection={"row"} alignItems="center" justifyContent={"space-between"}>
                <H3 className="mb-0">Inscripción #{id_inscripcion ?? "0000"}</H3>

                <Button startIcon={<EditIcon />} variant={"outlined"} onClick={() => navigate('./editar')}>
                  Editar inscripción
                </Button>
              </Stack>

              <Line className={"mb-5"} />

              <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
                  <Tabs value={tab} onChange={(_, tab) => setTab(tab)} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                    <Tab label={<Text fontWeight={tab == "1" ? "bold" : null} color={tab == "1" ? COLOR.standard.main : null}>DATOS DE LA INSCRIPCIÓN</Text>} value={"1"} />
                    <Tab label={<Text fontWeight={tab == "2" ? "bold" : null} color={tab == "2" ? COLOR.standard.main : null}>DATOS DEL GRUPO</Text>} value={"2"} />
                    <Tab label={<Text fontWeight={tab == "3" ? "bold" : null} color={tab == "3" ? COLOR.standard.main : null}>DATOS DE LOS PAGOS</Text>} value={"3"} />
                    <Tab label={<Text fontWeight={tab == "4" ? "bold" : null} color={tab == "4" ? COLOR.standard.main : null}>LIBROS</Text>} value={"4"} />
                  </Tabs>
                </Box>

                <TabPanel value={"1"}>
                  {inscripcion ? <GeneralInscripcionData inscripcion={inscripcion} /> : null}
                </TabPanel>

                <TabPanel value={"2"}>
                  {inscripcion ? <GrupoInscripcionData inscripcion={inscripcion} /> : null}
                </TabPanel>

                <TabPanel value={"3"}>
                  {inscripcion ? <PagoInscripcionData inscripcion={inscripcion} /> : null}
                </TabPanel>

                <TabPanel value={"4"}>
                  {inscripcion ? <LibroInscripcionData inscripcion={inscripcion} /> : null}
                </TabPanel>
              </TabContext>

            </Paper>
          </form>
        </Container>
      : <Skeleton/>
    }
    </>

  )
}