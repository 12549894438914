import { Text } from "components/texts"
import { EmpleadoListContext } from "contexts/employee/ListContext"
import { enqueueSnackbar } from "notistack"
import { useContext, useEffect, useState } from "react"

/**
 * Hook personalizado que encapsula la lógica para manejar el estado y las operaciones relacionadas
 * con un grupo específico en un contexto de empleado. Este hook gestiona la selección de un grupo,
 * la navegación y las peticiones de datos, así como la presentación de notificaciones de error.
 *
 * @param {Object} navigationParams - Parámetros de navegación que incluyen el 'id_grupo' para identificar el grupo actual.
 * @param {Object} requests - Objeto que contiene las funciones de petición, como 'getGrupo' para obtener los detalles de un grupo.
 *
 * @returns {Object} - Objeto que contiene:
 * - grupo: El estado actual del grupo seleccionado.
 * - id_grupo: El ID del grupo actualmente seleccionado, derivado de navigationParams.
 * - setIdGrupo: Función para actualizar el ID del grupo seleccionado.
 * - setGrupo: Función para actualizar el estado del grupo seleccionado.
 * - tab: El estado que representa la pestaña activa en la interfaz de usuario.
 * - setTab: Función para cambiar la pestaña activa.
 *
 * Utiliza el contexto 'EmpleadoListContext' para obtener la lista de grupos y realizar una búsqueda para encontrar
 * el grupo específico por ID. Si no se encuentra el grupo o la lista está vacía, se realiza una petición para obtener
 * los datos del grupo y se manejan los errores con notificaciones.
 */
export default function useWrapperLogic(navigationParams, requests) {
    const { items } = useContext(EmpleadoListContext)

    const [grupo, setGrupo] = useState(null)
    const [id_grupo, setIdGrupo] = useState(navigationParams.id_grupo)
    const [tab, setTab] = useState("1")

    const getGrupo = (getFromServer = false) => {
        const item = items.find(item => item?.id == id_grupo)
        if (items.length == 0 || !grupo || getFromServer) {
            requests.getGrupo(id_grupo, setGrupo, () => enqueueSnackbar(<Text>No se ha podido obtener el grupo.</Text>, { variant: "error" }))
        } else {
            setGrupo(item)
        }
    }

    useEffect(getGrupo, [id_grupo])

    return { grupo, id_grupo, setIdGrupo, setGrupo, tab, setTab, getGrupo }
}