import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import { Text } from 'components/texts'
import { Input } from 'components/forms/components'
import { CursoEscolarSelect, ItemsSelect } from 'components/forms/components/Select/custom'


/**
 * Esta es la plantilla para cuando se busque por los datos basicos de las actividades en una inscripcion
 */
export default function InscripcionPendientesAcademiaTemplate({ reset, errors, register, setValue }) {

    return (
        <>
            <Grid container mt={2} mb={1}>
                <Text fontWeight={'bold'}>Según datos de:</Text><br />
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <ItemsSelect name={"tipo_actividad_id"} keyValue={"tipo_actividad"} register={register} errors={errors} wantToReset={reset}/>
                </Grid>

                <Grid item xs={4}>
                    <ItemsSelect name={"actividad_id"} keyValue={"actividad"} register={register} errors={errors} wantToReset={reset} searcheable/>
                </Grid>

                <Grid item xs={4}>
                    <ItemsSelect name={"modalidad_curso_id"} keyValue={"modalidad_cursos"} register={register} errors={errors} wantToReset={reset}/>
                </Grid>

                <Grid item xs={6}>
                    <Input label="Test de Nivel" width={"100%"} name={"nivel"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <CursoEscolarSelect label={"Año"} name={"curso_escolar_id"} wantToReset={reset} register={register} errors={errors} defaultValue={null} setValue={setValue} searcheable />
                </Grid>
            </Grid>
        </>
    )
}
