import React from 'react'
import { Checkbox, Input } from 'components/forms/components'
import { Grid } from '@mui/material'
import Statics from 'objects/common/Statics'
import { ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Formulario con las actividades de la academia
 */
export default function DataFormActividadesAcademia({ actividad, register, errors, isMounted = false }) {

    return (
        <Grid container mt={2} px={4} spacing={2}>
            <Grid item xs={4}>
                <Input label="Nombre abreviado" register={register} errors={errors} className="w-100" name={"nombre"} defaultValue={actividad?.nombre ?? ""} uppercase />
            </Grid>
            <Grid item xs={6}>

            </Grid>
            <Grid item xs={4}>
                {isMounted ? <ItemsSelect name={"id_tipo_actividad"} keyValue={"tipo_actividad"} register={register} errors={errors} defaultValue={actividad?.tipo_actividad_id ?? ""} /> : null}
            </Grid>
            
            <Grid item xs={4}>
                {isMounted ? <ItemsSelect name={"id_actividad"} keyValue={"actividad"} register={register} errors={errors} defaultValue={actividad?.actividad_id ?? ""} searcheable/> : null}
            </Grid>

            <Grid item xs={4}>
                {isMounted ? <ItemsSelect name={"id_modalidad_cursos"} keyValue={"modalidad_cursos"} register={register} errors={errors} defaultValue={actividad?.modalidad_cursos_id ?? ""} /> : null}
            </Grid>

            <Grid item xs={3}>
                <Input label="Precio" register={register} errors={errors} className="w-100" name={"precio"} defaultValue={actividad?.precio ? Statics.changeDotAndComma(actividad.precio) : ""} uppercase />
            </Grid>

            <Grid item xs={3}>
                <Input label="Matrícula" register={register} errors={errors} className="w-100" name={"matricula"} defaultValue={actividad?.matricula ? Statics.changeDotAndComma(actividad.matricula) : ""} uppercase />
            </Grid>

            <Grid item xs={3}>
                <Input label="Precio Junio" register={register} errors={errors} className="w-100" name={"precio_junio"} defaultValue={actividad?.precio_junio ? Statics.changeDotAndComma(actividad.precio_junio) : ""} uppercase />
            </Grid>

            <Grid item xs={3}>
                <Checkbox name={"intensivo"} label={"Curso intensivo"} register={register} defaultChecked={actividad?.intensivo ? true : false} />
            </Grid>
        </Grid>
    )
}
