import React, { Component } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Login, RecoverPassword, ChangePassword } from '../../pages/not_logged'
import { NotLoggedRoot } from '../../pages/components/roots'
import { publicRoutes } from '../routes.js'
import { LogoutButton } from '../../pages/components/headers/components'

export default class StudentRoutes extends Component {
  render() {
    return (
      <Routes>
          <Route element={<NotLoggedRoot />}>
              <Route index element={<LogoutButton />} />
          </Route>
      </Routes>
    )
  }
}
