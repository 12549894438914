import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import { validationSchema } from 'components/forms/display/academia/aula/DataForm/validation'
import { useNavigate } from "react-router-dom"
import { useContext, useEffect, useRef, useState } from "react"
import { ChangesContext } from "contexts/ChangesContext"
import { EmpleadoListContext } from "contexts/employee/ListContext"
import { useAlert, useCheckDirtyFields, useErrorHandler } from "hooks"
import Statics from "objects/common/Statics"

/**
 * Hook personalizado que encapsula la lógica para la creación de aulas. 
 * Utiliza varios hooks de React para manejar el estado del formulario, la navegación,
 * y la interacción con el contexto global de la aplicación.
 * 
 * @param {Object} requests - Objeto que contiene métodos para interactuar con la API.
 * @return {Object} Retorna los métodos y variables necesarios para gestionar el formulario de creación de aulas.
 */
export default function useAulasCreateLogic({ requests }) {
    const { register, handleSubmit, formState: { errors, dirtyFields } } = useForm({ resolver: yupResolver(validationSchema) })

    const navigate = useNavigate()

    const updateAlert = useAlert()

    const handleError = useErrorHandler()

    const headerRef = useRef()

    const [isMounted, setIsMounted] = useState(false)

    const { updateChanges } = useContext(ChangesContext)
    const { addItem } = useContext(EmpleadoListContext)

    useEffect(() => setIsMounted(true), [])

    useCheckDirtyFields(dirtyFields, updateChanges)

    const createActividad = (values) => {
        headerRef.current.button.setLoading(true)

        requests.createAula(values, async (res) => {
            updateAlert('Se ha creado el aula correctamente. Redirigiendo a la página de edición...', 'success')
            updateChanges(false)

            //Añadir la nueva aula al contexto
            addItem(res.item)

            updateId(res?.item?.id)
        }, (res) => {
            headerRef.current.button.setLoading(false)
            handleError(res)
        })
    }

    const updateId = async (id) => {
        // navigate(`../academias/actividades/${id}`);
        await Statics.delay(1500).then(() => { navigate(`../academias/aulas/${id}`) }) //Modificada la URL, para ir al single
        // setIdActividad(id);
    }

    return { isMounted, errors, register, createActividad, handleSubmit, headerRef }
}