import React from 'react'
import { Grid } from '@mui/material'
import { ItemsSelect, CursoEscolarSelect } from 'components/forms/components/Select/custom'
import { Checkbox, DatePicker, Input, LoadingButton } from 'components/forms/components'

/**
 * Formulario con los datos del alumno
 */
export default function DataFormTutoria({ tutoria, register, errors, handleSubmit, setValue, hideButton = false, isMounted = false }) {

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container mt={2} px={4} spacing={2}>
            <Grid item xs={4}>
                {isMounted ? <CursoEscolarSelect name={`id_curso_escolar`} register={register} errors={errors} defaultValue={tutoria?.id_curso_escolar ?? null} setValue={setValue} searcheable /> : false}
            </Grid>

            <Grid item xs={4}>
                <DatePicker register={register} errors={errors} className="w-100" name={"fecha"} defaultValue={tutoria?.fecha} setValue={setValue} />
            </Grid>

            <Grid item xs={4}>
                {isMounted ? <ItemsSelect name={"id_origen"} keyValue={"origen"} register={register} errors={errors} defaultValue={tutoria?.id_origen ?? ""} /> : false}
                
            </Grid>

            <Grid item xs={8}>
                {isMounted ? <ItemsSelect name={"id_causa"} keyValue={"causa"} register={register} errors={errors} defaultValue={tutoria?.id_causa ?? ""} /> : false}
            </Grid>

            <Grid item xs={4}>
                <Checkbox name={"solucionada"} label={"¿La tutoría está solucionada?"} register={register} defaultChecked={tutoria?.solucionada ? true : false} />
            </Grid>

            <Grid item xs={6}>
                <Input label="Motivo de la tutoría" register={register} errors={errors} className="w-100" name={"motivo"} defaultValue={tutoria?.motivo ?? ""} multiline minRows={6} maxRows={10} uppercase />
            </Grid>

            <Grid item xs={6}>
                <Input label="Solución" register={register} errors={errors} className="w-100" name={"solucion"} defaultValue={tutoria?.solucion ?? ""} multiline minRows={6} maxRows={10} uppercase />
            </Grid>

            <Grid item xs={6}>
                <Input label="Seguimiento adicional" register={register} errors={errors} className="w-100" name={"seguimiento_adicional"} defaultValue={tutoria?.seguimiento_adicional ?? ""} multiline minRows={6} maxRows={10} uppercase />
            </Grid>

            <Grid item xs={6}>
                <Input label="Otros comentarios" register={register} errors={errors} className="w-100" name={"otros_comentarios"} defaultValue={tutoria?.otros_comentarios ?? ""} multiline minRows={6} maxRows={10} uppercase />
            </Grid>
  

            {hideButton ? null : 
                (<Grid item xs={12} justifyContent="end" display={"flex"}>
                    <LoadingButton onClick={e => handleSubmit(e)} color={"success"}>
                        Guardar tutoría
                    </LoadingButton>
                </Grid>)
            }
        </Grid>
    )
}
