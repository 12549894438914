import { CursoEscolarSelect, ItemsSelect } from "components/forms/components/Select/custom"
import { DatePicker, Input, Checkbox, Button } from "components/forms/components"
import { EmpleadoPicker } from 'components/forms/components/custom'
import { AlumnoPicker } from 'components/forms/components/custom'
import { Grid, Skeleton, Stack } from "@mui/material"
import { Line } from "components/data_display"
import { Text, H3 } from "components/texts"
import { useState, useEffect, useRef } from "react"
import { CreateIncidenciaModal, EditIncidenciasNoResueltasModal, DatosColegioModal } from "components/modals"
import { RequestDatosColegio } from "objects/requests"
import { useErrorHandler } from "hooks"
import Requests from '../../../../../../pages/logged/admin/clases-particulares/edit/Requests/Requests.js'
import { enqueueSnackbar } from 'notistack';

/**
 * Este hook `useLogic` maneja la lógica de la funcionalidad relacionada con la gestión de datos de los alumnos y la visualización
 * de la información del colegio. Incluye estados para controlar la apertura y cierre de modales, datos del alumno seleccionado,
 * datos del colegio, y funciones para obtener los datos del colegio asociados a un alumno.
 *
 * @returns {Object} - Objeto que contiene los estados y funciones relacionadas con la lógica de la aplicación.
 */
function useLogic(claseProp) {
    // Estados para controlar la apertura y cierre de modales, y los datos del alumno seleccionado y del colegio
    const [openIncidenciaModal, setOpenIncidenciaModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDatosColegioModal, setOpenDatosColegioModal] = useState(false);
    const [alumnoSelected, setAlumnoSelected] = useState({});
    const [datosColegio, setDatosColegio] = useState([]);
    const handleError = useErrorHandler();
    const [claseParticularState, setClaseParticularState] = useState({})
    console.log('alumnoSelected', alumnoSelected)
    /**
     * Función para obtener los datos del colegio asociados a un alumno específico.
     *
     * @param {string} idAlumno - Identificador del alumno del que se desean obtener los datos del colegio.
     */
    const getDatosColegio = (idAlumno) => {
        // Realiza una solicitud para obtener los datos del colegio del alumno específico
        new RequestDatosColegio().get(`?id_alumno=${idAlumno}`, {}, (res) => { setDatosColegio(res?.items?.data) }, (err) => handleError(err))
    }

    /**
     * Efecto que marca el hook como montado. Esto se utiliza para controlar la renderización condicional
     * o efectos que sólo deben ejecutarse una vez que el componente ha sido completamente montado.
     */
    useEffect(() => {
        setClaseParticularState(claseProp)
    }, [claseProp])

    /**
     * Devuelve el alumno
     * 
     * @param {Int} id 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    const updateClaseParticularState = () => {
        new Requests().getClaseParticular(claseProp?.id, (clase) => {
            setClaseParticularState(clase)
        }, () => enqueueSnackbar("No se ha podido obtener la clase particular"))
    }

    // Retorna los estados y funciones relevantes para la lógica de la aplicación
    return {
        openDatosColegioModal,
        setOpenDatosColegioModal,
        claseParticularState,
        updateClaseParticularState,
        datosColegio,
        getDatosColegio,
        alumnoSelected,
        setAlumnoSelected,
        openIncidenciaModal,
        setOpenIncidenciaModal,
        openEditModal,
        setOpenEditModal
    };
}


/**
 * Formulario con los datos de las clases particulares
 * 
 * @returns {JSX.Element}
 */
export default function DataFormInscripcionClasesParticulares({

    claseParticular,
    setLugarClase = null,
    register,
    errors,
    isMounted = false,
    setValue,
    clearErrors,
    operador = "Sin datos",
    onAlumnoSelected = () => { },
}) {

    const {
        claseParticularState,
        updateClaseParticularState,
        datosColegio,
        getDatosColegio,
        alumnoSelected,
        setAlumnoSelected,
        openDatosColegioModal,
        setOpenDatosColegioModal
    } = useLogic(claseParticular)

    const [alumno, setAlumno] = useState(claseParticular?.alumno ?? null)
    const [openIncidenciaModal, setOpenIncidenciaModal] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [clear, setClear] = useState(false)
    const handleClick = (event) => {
        event.preventDefault()
        setClear(true)
        setValue('id_empresa', "")
    }
    const setAlumnos = (alumno) => {
        onAlumnoSelected(alumno)
        setAlumnoSelected(alumno)
        setAlumno(alumno)
    }

    return (
        <Grid container>

            <Grid container px={5}>
                <Grid item xs={6} py={3} pr={2}>
                    <AlumnoPicker
                        name={"alumno_id"}
                        label={"Alumno"}
                        editLabel={"Cambiar alumno"}
                        addLabel={"Añadir alumno"}
                        deleteLabel={"Borrar alumno"}
                        register={register}
                        errors={errors}
                        alumno={alumno}
                        onAlumnoSelected={(alumno) => setAlumnos(alumno)}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        //Si está editando ya no se puede cambiar el alumno
                        canEdit={claseParticular ? false : true} />
                </Grid>

                <Grid item xs={6} style={{ borderLeft: "1px solid #D9D9D9" }} py={3} pl={3}>
                    <Stack spacing={2}>
                        {isMounted ?
                            <CursoEscolarSelect name={"id_curso_escolar"} defaultValue={claseParticular?.curso_escolar_id ?? null} register={register} errors={errors} setValue={setValue} searcheable />
                            : <Skeleton variant="rounded" width={"100%"} height={40} />}

                        {isMounted ?
                            <ItemsSelect name={"id_forma_recogida"} keyValue={"forma_recogida"} register={register} errors={errors} defaultValue={claseParticular?.forma_recogida_id ?? ""} />
                            : <Skeleton variant="rounded" width={"100%"} height={40} />}

                        {isMounted ?
                            <ItemsSelect name={"id_forma_conocimiento"} keyValue={"forma_conocimiento"} register={register} errors={errors} defaultValue={claseParticular?.forma_conocimiento_id ?? ""} />
                            :
                            <Skeleton variant="rounded" width={"100%"} height={40} />}

                        {isMounted ?
                            <Input defaultValue={operador} label={'Operador'} disabled />
                            :
                            <Skeleton variant="rounded" width={"100%"} height={40} />}

                    </Stack>
                </Grid>
            </Grid>

            <Grid container>
                <Line />
            </Grid>

            <Grid container px={5} mt={2} spacing={2}>

                <Grid item xs={5} md={5}>
                    {isMounted ?
                        <ItemsSelect
                            name={"registro_actividad_id"}
                            keyValue={"registro_actividad_academia"}
                            register={register}
                            errors={errors}
                            params={{ relations: "modalidad_cursos,actividad" }}
                            defaultValue={claseParticular?.registro_actividad_id ?? ""}
                            renderItem={(item) => {
                                return { value: item.id, label: item.actividad?.valor + " - " + item.modalidad_cursos?.nombre }
                            }} />
                        : <Skeleton variant="rounded" width={"100%"} height={40} />}

                </Grid>
                <Grid item xs={5} md={5}>
                    {isMounted ?
                        <ItemsSelect name={"id_empresa"} keyValue={"empresa"} register={register} errors={errors} defaultValue={claseParticular?.empresa_id ?? ""} clearOption={clear} clearOptionLabel={null} setClear={setClear} />
                        : <Skeleton variant="rounded" width={"100%"} height={40} />}
                    <Button
                        variant="soft"
                        onClick={handleClick}>
                        limpiar empresa
                    </Button>
                </Grid>
                <Grid item xs={2} md={2}>
                    {isMounted ?
                        <Checkbox name={"alumno_colectivo"} label={"Alumno de Colectivo"} register={register} defaultChecked={claseParticular?.alumno_colectivo ? true : false} />
                        : null}
                </Grid>

                <Grid item xs={5} md={5}>
                    {isMounted ? <ItemsSelect name={"lugar_clase_id"} keyValue={"lugar_clase"} register={register} errors={errors} defaultValue={claseParticular?.lugar_clase_id ?? ""} onChange={
                        (_, item) => {
                            typeof setLugarClase === 'function' && setLugarClase(item.id)
                        }
                    } /> : null}
                </Grid>

            </Grid>

            <Grid container px={5} mt={2} spacing={2}>
                <Grid item xs={7}>
                    <Input placeholder={"Observaciones"} label={'Observaciones'} register={register} errors={errors} className="w-100" name={"observaciones"} defaultValue={claseParticular?.observaciones ?? null} multiline minRows={6} maxRows={10} uppercase />
                </Grid>

                <Grid item xs={2}>
                    <Stack>
                        <Checkbox name={"encuesta_telefonica"} label={"Encuesta telefónica"} register={register} defaultChecked={claseParticular?.encuestado ? true : false} />
                        <Checkbox name={"avisado_comienzo_clases"} label={"Avisado del comienzo de las clases"} register={register} />
                    </Stack>
                </Grid>
            </Grid>

            <Grid container px={5} mt={2} spacing={2}>
                <Grid item xs={6} md={4}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_inscripcion"} label={"Fecha de inscripción"} defaultValue={claseParticular?.fecha_alta} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={6} md={4}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_inicio"} label={"Fecha de inicio"} defaultValue={claseParticular?.fecha_inicio ?? ""} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={6} md={4}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_baja"} label={"Fecha baja"} defaultValue={claseParticular?.fecha_baja ?? ""} setValue={setValue} clearable />
                </Grid>
            </Grid>

            <Grid container mt={4}>
                <Line />
            </Grid>

            <Grid container px={5} mt={2} spacing={2}>
                <Grid item xs={6} py={3} pr={2}>
                    <EmpleadoPicker
                        label={"Profesor de la clase particular"}
                        editLabel={"Cambiar profesor"}
                        addLabel={"Añadir profesor"}
                        name={"profesor_id"}
                        deleteLabel={"Borrar profesor"}
                        register={register}
                        empleado={claseParticular?.profesor ?? null}
                        setValue={setValue}
                        //Si tiene más de un profesor ese grupo, ya no se podrá cambiar desde aqui
                        canEdit={claseParticular && claseParticular.profesor && claseParticular.profesor.length > 1 ? false : true} />
                </Grid>

                <Grid item xs={6} py={3} pr={2}>
                    <Stack spacing={2}>
                        {isMounted ?
                            <ItemsSelect name={"sexo_id"} keyValue={"sexo"} register={register} errors={errors} defaultValue={claseParticular?.profesor?.sexo_id ?? ""} />
                            : <Skeleton variant="rounded" width={"100%"} height={40} />}

                        {isMounted ?
                            <ItemsSelect name={"origen_profesor_id"} keyValue={"origen_empleado"} register={register} errors={errors} defaultValue={claseParticular?.origen_profesor_id ?? ""} />
                            : <Skeleton variant="rounded" width={"100%"} height={40} />}
                    </Stack>

                </Grid>
            </Grid>

            <Grid container mt={4}>
                <Line />
            </Grid>

            <Grid container px={5} mt={2} spacing={2} style={{ justifyContent: 'center' }}>
                {claseParticularState?.alumno?.incidencias.filter(incidencia => incidencia.solucionada === 0)?.length > 0 ?
                    <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                        <Button onClick={() => setOpenEditModal(true)} variant="outlined">
                            Incidencias no resueltas
                        </Button>
                    </Grid> : null}

                <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                    <Button
                        className=""
                        onClick={() => setOpenIncidenciaModal(true)}
                        variant="outlined"
                        disabled={!((alumnoSelected && alumnoSelected.hasOwnProperty('id')) || (alumno))}
                    >
                        Nueva incidencia
                    </Button>
                </Grid>

                <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>

                    <Button
                        onClick={() => setOpenDatosColegioModal(true)}
                        variant="outlined"
                        disabled={!((alumnoSelected && alumnoSelected.hasOwnProperty('id')) || (alumno))}
                    >
                        Datos del Colegio
                    </Button>

                </Grid>

                <Grid item xs={3} style={{ display: 'flex', alignItems: 'end', justifyContent: 'end', gap: 10 }}>
                    <Button disabled className="" variant="outlined">Nueva Tutoría</Button>
                </Grid>
            </Grid>

            <CreateIncidenciaModal
                isMounted={isMounted}
                open={openIncidenciaModal}
                setOpen={setOpenIncidenciaModal}
                alumno={alumnoSelected?.hasOwnProperty('id') ? alumnoSelected : (claseParticular ? claseParticular?.alumno : null)}
                onSaveFinish={updateClaseParticularState}
            />

            <EditIncidenciasNoResueltasModal
                isMounted={isMounted}
                incidencias={claseParticularState?.alumno?.incidencias.filter(incidencia => incidencia.solucionada === 0)}
                alumno={alumnoSelected?.hasOwnProperty('id') ? alumnoSelected : (claseParticular ? claseParticular?.alumno : null)}
                open={openEditModal}
                setOpen={setOpenEditModal}
                onSaveFinish={updateClaseParticularState}
            />

            <DatosColegioModal
                open={openDatosColegioModal}
                setOpen={setOpenDatosColegioModal}
                // getDatosColegio > Función para obtener los datos del colegio asociados a un alumno específico.
                getDatosColegio={getDatosColegio}
                datosColegio={datosColegio}
                alumno={alumnoSelected?.hasOwnProperty('id') ? alumnoSelected : (claseParticular ? claseParticular?.alumno : null)}
            />

        </Grid>
    )
}