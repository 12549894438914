import * as Yup from 'yup'


export const validation = {
    forma_pago_id:      Yup.string().required('La forma de pago es obligatoria'),
    cantidad_pago:      Yup.number().typeError('La cantidad es obligatoria.').required('La cantidad es obligatoria.'),
    tipo_pago_id:       Yup.string().required('El tipo de pago es obligatorio'),
    tipo_matricula_id:  Yup.string().required('El tipo de matrícula es obligatorio'),
    matricula: Yup.string().when('tipo_matricula_id', {
        is: (value) => value == 1,
        then: Yup.string().required('Debes indicar el precio de la matricula.'),
    }),
    // referencia: Yup.number().when('forma_pago_id', {
    //     is: (value) =>  value == 6,
    //     then: Yup.number().required('Debe indicar la referencia'),
    // }),
}

export const validationSchema = Yup.object().shape(validation)