import { useAlert } from "hooks"
import { Text } from 'components/texts'
import { useParams } from 'react-router'
import { useForm } from 'react-hook-form'
import Requests from './Requests/Requests.js'
import { useNavigate } from 'react-router-dom'
import Headers from 'components/headers/index'
import Statics from 'objects/common/Statics.js'
import { useErrorHandler } from "hooks/index.js"
import { yupResolver } from '@hookform/resolvers/yup'
import { Container, Paper, Stack } from '@mui/material'
import { EditHeader } from 'pages/logged/admin/components'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { ChangesContext } from 'contexts/ChangesContext/index'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import React, { useRef, useState, useEffect, useContext } from 'react'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
//FORM
import { DataFormAula } from 'components/forms/display'
import SkeletonAula from 'components/forms/display/academia/aula/DataForm/skeleton.jsx'
import { validationSchema } from 'components/forms/display/academia/aula/DataForm/validation.js'

export default function Wrapper() {

  const navigationParams = useParams()
  const updateAlert = useAlert();

  const [aula, setAula] = useState(null)
  const [id_aula, setIdAula] = useState(navigationParams.id_aula)
  const { items, updateItem } = useContext(EmpleadoListContext)

  // const updateAlert = (message, variant = "error") => {
  //   enqueueSnackbar(<Text>{message}</Text>, { variant })
  // }

  const getAula = (getFromServer = false) => {
    const aula = items.find(item => item.id == id_aula)
    if (items.length == 0 || !aula || getFromServer) {
      new Requests().getAula(id_aula, (aula) => {
        setAula(aula)
        updateItem(aula)
      }, () => updateAlert("No se ha podido obtener el aula"))
    } else {
      setAula(aula)
    }
  }

  useEffect(getAula, [id_aula])

  return (
    <React.Fragment>
      <EditAulasAcademia key={aula?.id ?? 0} aula={aula} id_aula={id_aula} setIdAula={setIdAula} setAula={setAula} updateAlert={updateAlert} onSaveFinish={() => getAula(true)} />
      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}

function EditAulasAcademia({ aula, id_aula, setIdAula, setAula, updateAlert, onSaveFinish }) {
  const navigate = useNavigate()
  const updateAlertHook = useAlert();
  const handleError = useErrorHandler()
  const { register, handleSubmit, formState: { errors, dirtyFields }, reset } = useForm({ resolver: yupResolver(validationSchema) })

  const headerRef = useRef()

  const { updateChanges } = useContext(ChangesContext)

  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0)
      updateChanges(true)
  }, [Object.keys(dirtyFields).length])


  // const updateHeaderAndAlert = ({ message, severity = "error" }, loading = false) => {
  //   headerRef.current.setLoading(loading)
  //   updateAlert(message, severity)
  // }


  /**
   * Hace la petición para editar los datos del aula
   */
  const editAula = (values) => {
    headerRef.current.setLoading(true)
    new Requests().editAula(id_aula, values, (res) => {
      // updateHeaderAndAlert({ message: "Se ha editado la actividad correctamente. Redirigiendo al single...", severity: "success" })
      updateAlertHook("Se ha editado la actividad correctamente. Redirigiendo al single...", 'success')
      reset({}, { keepValues: true })
      updateChanges(false)
      updateId(id_aula)
      onSaveFinish()
    }, (err) => {
      headerRef.current.setLoading(false)
      handleError(err)
    })
  }

  const updateId = async (id) => {
    // navigate(`../academias/aulas/${id}`);
    await Statics.delay(1500).then(() => { navigate(`../academias/aulas/${id}`) }) // URL para redirigir a Single
    setIdAula(id);
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(editAula)}>

        <Stack direction="row" justifyContent={"space-between"} marginX={8}>
          <BreadcrumbsList mx={0} breadcrumbsList={[
            { to: "/", label: "Inicio" },
            { to: "./../../", label: "Academia" }, //--> Te dirige al apartado Aulas
            { to: "./../", label: "Aula #" + id_aula },
            { to: "./", label: "Editar", actualItem: true },
            { to: "./../../", label: "Aulas", actualItem: true }//Incluyo el apartado Aulas

          ]} />

          <MoveButtons
            actualItemId={id_aula}
            beforeFetchCallback={() => setAula(null)}
            onUpdateActualItem={updateId} />
        </Stack>

        <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>
          {/* <EditHeader
            ref={headerRef}
            title={"Editar aula"}
            item={aula} /> */}
          <Headers
            template={'edit'}
            title={"Editar Aula"}
            actividad={aula?.denominacion}
            needMargin={true}
            headerRef={headerRef}
          />


          <Line />

          {aula ? <DataFormAula
            aula={aula}
            register={register}
            errors={errors}
            isMounted
            hideButton /> : <SkeletonAula />}
        </Paper>
      </form>
    </Container>
  )
}