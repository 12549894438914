import { Component } from 'react'
import Statics from 'objects/common/Statics'
import { RequestTutoria } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests extends Component {

    /**
     * Hace la petición para editar el usuario
     * 
     * @param {Function} getValues 
     * @param {Int}      id_alumno 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createTutoria(getValues, id_alumno, callbackOk = () => { }, callbackError = () => { }) {
        var formData = new FormData()
        formData.append("motivo",                getValues("motivo").toUpperCase())
        formData.append("fecha",                 Statics.datePickerTimeFormat(getValues("fecha")))
        formData.append("solucionada",           getValues("solucionada") ? 1 : 0)
        formData.append("solucion",              getValues("solucion").toUpperCase())
        formData.append("seguimiento_adicional", getValues("seguimiento_adicional").toUpperCase())
        formData.append("otros_comentarios",     getValues("otros_comentarios").toUpperCase())
        formData.append("id_curso_escolar",      getValues("id_curso_escolar"))
        formData.append("id_causa",              getValues("id_causa"))
        formData.append("id_origen",             getValues("id_origen"))
        formData.append("id_alumno",             id_alumno)

        new RequestTutoria().create(formData, callbackOk, callbackError)
    }
}
