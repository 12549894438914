import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Grid, Stack } from '@mui/material'
import Requests from '../../Requests/Requests'
import { H2, H3, Text } from 'components/texts/index.js'
import { Button, LoadingButton } from 'components/forms/components'
import { Groups as GroupsIcon, InsertDriveFile as FileIcon, Edit as EditIcon } from '@mui/icons-material'
import { enqueueSnackbar } from 'notistack'
import { ModalSelectMeses } from 'components/modals'
import { RequestGrupoAcademia, RequestGrupoColegio } from 'objects/requests'

export default function Header({ grupo }) {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)

    const [loading, setLoading] = useState(false)

    const onPrintGroupData = () => {
        setLoading(true)

        new Requests().getPreliminar(grupo.id, (err) => {
            enqueueSnackbar(<Text>{err}</Text>, { variant: "error" })
        }, () => {
            setLoading(false)
        })
    }

    const generarInforme = (selectedMonth) => {
        new RequestGrupoAcademia().informeAsistencia({ mes: selectedMonth })
    }

    useEffect(() => {
        if (open)
            setOpen(false)
    }, [open])

    return (
        <Grid container px={5} py={3} alignItems={"center"}>
            <Grid item xs={6}>
                <Stack>
                    <H2 className="profile-id-text" style={{ fontWeight: "bold" }}>Consultar grupo: {grupo?.clave_grupo ?? ""}</H2>
                    {/* <H3>{grupo?.clave_grupo ?? ""}</H3> */}
                </Stack>
            </Grid>

            <Grid item xs={6} display={"flex"} justifyContent={"end"}>
                {grupo ?
                    (<Stack spacing={2} direction={"row"}>
                        <LoadingButton colorVariant={"success"} startIcon={<GroupsIcon />} loading={loading} onClick={onPrintGroupData}>
                            Imprimir datos del grupo
                        </LoadingButton>

                        <LoadingButton loading={loading} startIcon={<FileIcon />} onClick={() => setOpen(true)}>
                            Informe asistencia
                        </LoadingButton>

                        <Button startIcon={<EditIcon />} variant={"outlined"} onClick={() => navigate('./editar')}>
                            Editar grupo
                        </Button>
                    </Stack>) : null}
            </Grid>

            <ModalSelectMeses open={open} generarInforme={generarInforme} />
        </Grid>
    )
}