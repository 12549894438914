import Skeleton from './skeleton'
import React, { useRef } from 'react'
import { ASSETS } from 'constants/Assets'
import { H3, Text } from 'components/texts'
import { Link } from 'components/data_display'
import { Button } from 'components/forms/components'
import { Alert, Avatar, Grid, Paper, Tooltip, Box } from '@mui/material'
import { SnackbarAlert } from 'components/data_display'
import { AddResponsableModal, DeleteRelacionModal, ChooseTipoRelacionModal } from 'components/modals'
import { Visibility as SeeIcon, Add as AddIcon, OpenInNewOff as RemoveAsociationIcon, SyncAlt as EditAsociationIcon } from '@mui/icons-material'
import { useImageLoader } from 'hooks'
import { DEFAULT_IMAGES_URL } from 'constants/DefaultImagesUR'
import { COLOR } from 'theme/Colors'

export default function ResponsableData({ responsables, alumno, reload }) {
  const addResponsableModal = useRef()
  const deleteRelacionModal = useRef()
  const chooseTipoRelacionModal = useRef()
  const snackBarAlertRef = useRef()

  return (
    <Grid container>
      <Grid item xs={6} display={"flex"} alignItems={"center"}>
        <H3 className={"m-0"}>Tutores:</H3>
      </Grid>

      <Grid item xs={6} display={"flex"} justifyContent={"end"} alignItems={"center"}>
        {responsables ? (
          <Button variant={"outlined"} startIcon={<AddIcon />} onClick={() => { addResponsableModal.current.modal.open() }}>
            Crear tutor
          </Button>) : null}
      </Grid>

      {!responsables ? <Skeleton /> : (
        <Grid container mt={2} spacing={2}>
          {responsables.map(item => (<ResponsableItem key={Math.random()} responsable={item} openEditRelacionModal={(responsable) => { chooseTipoRelacionModal.current.open(alumno, responsable, true) }} openDeleteRelacionModal={(responsable) => { deleteRelacionModal.current.open(responsable, alumno) }} />))}

          {responsables.length == 0 ? <Grid item xs={12}><Alert severity="info" className='mt-10 w-100'><H3 className="standard">Este alumno no tiene ningún tutor.</H3></Alert></Grid> : null}
        </Grid>
      )}

      {/* MODALES */}
      <AddResponsableModal ref={addResponsableModal} alumno={alumno} onCreateCallback={reload} />
      <DeleteRelacionModal ref={deleteRelacionModal} onDeleteCallback={reload} />
      {/* Definimos modal para actualizar la relación con el tutor */}
      <ChooseTipoRelacionModal
        ref={chooseTipoRelacionModal}
        callbackOk={() => {
          snackBarAlertRef.current.updateState({ message: "Relación con tutor actualizada correctamente.", severity: "success", open: true })
          reload()
        }}
        callbackError={(res) => {
          snackBarAlertRef.current.updateState({ message: res, severity: "error", open: true })
        }} />
        <SnackbarAlert ref={snackBarAlertRef} />
    </Grid>
  )
}

function ResponsableItem({ responsable, openDeleteRelacionModal, openEditRelacionModal }) {
  const { imageSrc } = useImageLoader(responsable, 'foto_logo', DEFAULT_IMAGES_URL.responsable, true)

  return (
    <Grid item xs={6} xl={4}>
      <Paper elevation={0} sx={{ px: 3, pt: 2, pb: 3, borderRadius: 2, border: "1px solid var(--greyLight)" }}>
        <Box justifyContent={"end"} alignItems={"center"} display={"flex"}>
          <Link to={"./../tutores/" + responsable.id}>
            <Tooltip title={<Text color={"black"}>Ver tutor</Text>}>
              <SeeIcon color={"standard"} className={"mx-2"} />
            </Tooltip>
          </Link>

          {/* Definición de nuevo tooltip para acción de editar la relación de tutor-alumno */}
          {
              <Tooltip title={<Text color={"black"}>Editar relación</Text>}>
                  <div onClick={() => openEditRelacionModal(responsable)}>
                      <EditAsociationIcon sx={{ color: COLOR.grey.main}} className={"clickable mx-2"} />
                  </div>
              </Tooltip>
          }

          <Tooltip title={<Text color={"black"}>Borrar relación</Text>}>
            <div onClick={() => { openDeleteRelacionModal(responsable) }}>
              <RemoveAsociationIcon color={"error"} className={"clickable mx-2"} />
            </div>
          </Tooltip>
        </Box>

        <Box alignItems={"center"} display={"flex"}>
          {/* <Avatar alt={responsable.nombre ?? ""} src={`${ASSETS.images + (responsable.foto_logo ? responsable.foto_logo : 'private/responsable/perfiles/0/default.png')}`} sx={{ width: 75, height: 75 }} /> */}
          <Avatar alt={responsable.nombre ?? ""} src={imageSrc} sx={{ width: 75, height: 75 }} />

          <Box mx={3} display={"flex"} flexDirection={"column"}>
            <Text>{(responsable.nombre ?? "") + " " + (responsable.apellido1 ?? "") + " " + (responsable.apellido2 ?? "")}</Text>
            <Text>{(responsable.numero_documento ?? "") + " - " + (responsable.tipo_documento ? responsable.tipo_documento.nombre : "")}</Text>
            <Text>{"Relación: " + (responsable.tipo_relacion ?? "")}</Text>
          </Box>
        </Box>
      </Paper>
    </Grid>
  )
}