export const responses = {
    cursoEscolar: {
        arrayName: "cursos_escolares",
        fieldName: "valor"
    },
    alumnos: {
        arrayName: "alumnos"
    },
    empleados: {
        arrayName: "empleados"
    },
    responsables: {
        arrayName: "responsables",
    },
    tutorias: {
        arrayName: "tutorias",
        singleName: "tutoria",
    },
    incidencias: {
        arrayName: "incidencias",
        singleName: "incidencia",
    },
    tiposIncidencia: {
        arrayName: "tipos_incidencia"
    },
    datosColegio: {
        arrayName: "items"
    },
    colegios: {
        arrayName: "colegios"
    },
    libros: {
        arrayName: "items",
    },
    tiposCondicionActividad: {
        arrayName: "tipos_condicion"
    },
    aulas: {
        arrayName: "items",
        singleName: "aula",
    },
    tiposActividad: {
        arrayName: "tipos_actividad",
    },
    inscripcionesAcademias: {
        arrayName: "data",
        singleName: "inscripcion",
    },
    actividad: {
        arrayName: "actividades",
        singleName: "actividad",
    },
    idiomas: {
        arrayName: "",
        singleName: "idioma",
    },
    modalidad: {
        arrayName: "modalidades",
        singleName: "modalidad",
    },
    actividadAcademia: {
        arrayName: "actividad",
        singleName: "act"
    },
    gruposColegio: {
        arrayName: "grupos"
    },
    inscripcionesColegio: {
        arrayName: "inscripciones"
    },
    grupoAcademia: {
        arrayName: "data",
    },
}

export const auxFieldName = {
    cursoEscolar: "valor",
    tipoIncidencia: "valor",
    causa: "nombre",
    idioma: "nombre",
    origen: "nombre",
    tiposCondicionActividad: "valor",
    tiposActividad: "valor",
    actividad: "valor",
    modalidad: "nombre",
    actividadAcademia: "nombre"
}