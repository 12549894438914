import { useState, useEffect } from 'react'

/**
 * Hook personalizado que encapsula la lógica relacionada con libros pedidos y devueltos, y su gestión en un formulario.
 *
 * @param {Array} librosPedidosProp - Un array de libros pedidos inicial.
 * @param {Array} librosDevueltosProp - Un array de libros devueltos inicial.
 * @param {function} setValue - Función proporcionada por 'react-hook-form' para establecer los valores del formulario.
 * 
 * @returns {Object} - Un objeto que contiene los arrays de libros pedidos y devueltos actualizados, y funciones para actualizarlos.
 */
export default function useLogic(librosPedidosProp, librosDevueltosProp, setValue) {
    const [cursoEscolar, setCursoEscolar] = useState(null)
    const [librosDevueltos, setLibrosDevueltos] = useState(librosDevueltosProp ?? [])
    const [librosPedidos, setLibrosPedidos] = useState(librosPedidosProp ?? [])

    useEffect(() => {
        if (librosPedidosProp !== librosPedidos)
            setLibrosPedidos(librosPedidosProp)
    }, [librosPedidosProp])

    useEffect(() => {
        if (librosDevueltosProp !== librosDevueltos)
            setLibrosDevueltos(librosDevueltosProp)
    }, [librosDevueltosProp])

    useEffect(() => {
        setValue("librosDevueltos", librosDevueltos)
    }, [librosDevueltos])

    useEffect(() => {
        setValue("librosPedidos", librosPedidos)
    }, [librosPedidos])

    /**
     * Actualiza el array de libros pedidos y establece los valores en el formulario.
     *
     * @param {Array} librosPedidos - El nuevo array de libros pedidos.
     */
    const updateLibrosPedidos = (librosPedidos) => {
        setLibrosPedidos(librosPedidos)
        setValue("librosPedidos", librosPedidos, { shouldDirty: true })
    }

    /**
     * Actualiza el array de libros devueltos y establece los valores en el formulario.
     *
     * @param {Array} librosDevueltos - El nuevo array de libros devueltos.
     */
    const updateLibrosDevueltos = (librosDevueltos) => {
        setLibrosDevueltos(librosDevueltos)
        setValue("librosDevueltos", librosDevueltos, { shouldDirty: true })
    }

    return { librosDevueltos, librosPedidos, updateLibrosPedidos, updateLibrosDevueltos, cursoEscolar, setCursoEscolar }
}