import Statics from 'objects/common/Statics'

export const columns = (onClick = () => {}) => {
    return [
        { field: "tipo_documento", headerName: "Tipo de documento", minWidth: 100, valueGetter: (params) => { return params.row.empleado?.tipo_documento?.nombre } },
        { field: "numero_documento", headerName: "Número documento", minWidth: 100, valueGetter: (params) => { return params.row.empleado?.numero_documento } },
        { field: "docente", headerName: "Docente", minWidth: 250, valueGetter: (params) => {
            const { empleado } = params.row
            return (empleado.nombre ?? "") + " " + (empleado.apellido1 ?? "") + " " + (empleado.apellido2 ?? "")
        } },
        { field: "motivo_incidencia", headerName: "Motivo de la incidencia", minWidth: 200, valueGetter: (params) => { return params.row.motivo_incidencia_fichaje?.valor } },
        { field: "fecha", headerName: "Fecha", minWidth: 100, valueGetter: (params) => {
            return params.row.fecha ? Statics.formatDate(params.row.fecha) : ""
        }},
        { field: "tramo-horario", headerName: "Tramo horario", minWidth: 150, valueGetter: (params) => {
            return (params.row.hora_inicio ?? "") + " - " + (params.row.hora_fin ?? "")
        }},
        { field: "comentario", headerName: "Comentario", flex: 1, renderCell: (params) => {
            return params.row.comentario ? <span onClick={() => onClick(params.row)} style={{ textDecoration: "underline", cursor: "pointer" }}>Ver</span> : ""
        }},
    ]

} 