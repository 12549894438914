import './style.css'
import { v4 as uuid } from 'uuid';
import Box from '@mui/material/Box';
// import TableColumn from '../TableColumn'
import React from 'react';
import { erase, add, edit } from '../CRUD'
import { enqueueSnackbar } from 'notistack';
import { H3, Text } from 'components/texts';
import Statics from 'objects/common/Statics';
import AddIcon from '@mui/icons-material/Add';
import { libroDevueltoObject } from './constants'
import { Button, TableColumn } from 'components/forms/components'
import RemoveIcon from '@mui/icons-material/Remove';
import { useEffect, useState } from "react"

/**
 * Componente `LibrosDevueltos` que proporciona una interfaz de usuario para gestionar una lista de libros devueltos.
 * Permite añadir, editar y eliminar libros de la lista, y visualizar el total de unidades devueltas.
 * 
 * Template usado para el create y el edit
 * 
 * @param {Object} props - Propiedades del componente.
 * @param {Array} props.librosDevueltos - Lista inicial de libros devueltos.
 * @param {String} props.cursoEscolar - Curso escolar actual.
 * @param {Function} props.onUpdate - Función para actualizar el estado en el componente padre.
 * 
 * @returns {JSX.Element} - Una tabla que muestra los libros devueltos con opciones para editar, eliminar y añadir nuevos libros.
 */
export default function LibrosDevueltos({ librosDevueltos: librosDevueltosProp, cursoEscolar: cursoEscolarProp, onUpdate }) {
    const [librosDevueltos, setLibrosDevueltos] = useState(librosDevueltosProp ?? [])
    const [cursoEscolar, setCursoEscolar] = useState(cursoEscolarProp)

    useEffect(() => {
        if (cursoEscolarProp !== cursoEscolar) {
            setCursoEscolar(cursoEscolarProp)
        }
        if (librosDevueltosProp !== librosDevueltos) {
            setLibrosDevueltos(librosDevueltosProp)
        }
    }, [cursoEscolarProp, librosDevueltosProp])

    const updateLibrosDevueltos = (librosDevueltos) => {
        onUpdate([...librosDevueltos])
    }

    const librosToRender = cursoEscolar ? librosDevueltos?.filter(item => item.id_curso_escolar == cursoEscolar && item.deleted != true) ?? [] : []
    const sumDevueltas = Statics.sumParamsOfObjectArray(librosToRender, "cantidad_devuelta")

    return (
        <Box px={2}>
            <H3>Libros devueltos: </H3>
            <table className='libros-devueltos-table'>
                <thead>
                    <tr>
                        <th></th>
                        <th><Text fontWeight={"bold"}>Fecha</Text></th>
                        <th><Text fontWeight={"bold"}>Unidades devueltas</Text></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className='no-border'>
                    {librosToRender.map((item, index, array) => (
                        <tr key={uuid()}>
                            <td className='bg-white'>
                                <Button style={{ borderRadius: 200 }} color={"error"} iconType onClick={() => {
                                    const librosDevueltosLocal = erase(item, librosDevueltos)
                                    updateLibrosDevueltos(librosDevueltosLocal)
                                }}>
                                    <RemoveIcon />
                                </Button>
                            </td>

                            <TableColumn defaultValue={item.fecha} type={"date"} onEdit={(value) => {
                                const librosDevueltosLocal = edit(item, value, "fecha", librosDevueltos)
                                updateLibrosDevueltos(librosDevueltosLocal)
                            }} />

                            <TableColumn defaultValue={item.cantidad_devuelta} onEdit={(value) => {
                                const librosDevueltosLocal = edit(item, value, "cantidad_devuelta", librosDevueltos)
                                updateLibrosDevueltos(librosDevueltosLocal)
                            }} />

                            {array.length === index + 1 && cursoEscolar ? (
                                <td className='bg-white'>
                                    <Button iconType onClick={() => {
                                        const librosDevueltosLocal = add(librosToRender, librosDevueltos, cursoEscolar, libroDevueltoObject)
                                        librosDevueltosLocal ? updateLibrosDevueltos(librosDevueltosLocal) : enqueueSnackbar(<Text>Rellena el pedido anterior para añadir más.</Text>, { variant: "error" })
                                    }}>
                                        <AddIcon />
                                    </Button>
                                </td>
                            ) : null}
                        </tr>
                    ))}

                    {librosToRender.length == 0 && cursoEscolar ?
                        <tr>
                            <td className='bg-white' colSpan={3}></td>
                            <td className='bg-white'>
                                <Button iconType onClick={() => {
                                    const librosDevueltosLocal = add(librosToRender, librosDevueltos, cursoEscolar, libroDevueltoObject)
                                    librosDevueltosLocal ? updateLibrosDevueltos(librosDevueltosLocal) : enqueueSnackbar(<Text>Rellena el pedido anterior para añadir más.</Text>, { variant: "error" })
                                }}>
                                    <AddIcon />
                                </Button>
                            </td>
                        </tr> : null}

                    <tr className="total-unidades">
                        <td colSpan={2} className='bg-white'><Text fontWeight="bold">Total devueltas</Text></td>
                        <td><Text>{sumDevueltas}</Text></td>
                    </tr>
                </tbody>
            </table>

        </Box>
    )
}