import React from 'react'
import { Grid } from '@mui/material'
import { Line } from 'components/data_display'
import { Input } from 'components/forms/components'
import { H3 } from 'components/texts'

/**
 * Formulario de la información del seguimiento de una inscripcion
 * 
 * @param {*} register - react-hook-form
 * @param {*} errors - react-hook-form
 * @param {Number} trimestre - 1, 2, 3
 * 
 * @returns {JSX.Element}
 */
export default function SeguimientoTrimestralModal({ register, errors, trimestre }) {
    return (
        <React.Fragment>
            <Grid container p={5} spacing={2}>
                <Grid item xs={12}>
                    <H3>Calificaciones del trimestre</H3>
                </Grid>
                <Grid item xs={6} lg={2}>
                    <Input label="Comportamiento" register={register} errors={errors} name={"comportamiento"} width={"100%"} defaultValue={trimestre?.comportamiento ?? ""} />
                </Grid>

                <Grid item xs={6} lg={2}>
                    <Input label="Expresión oral" register={register} errors={errors} name={"expresion_oral"} width={"100%"} defaultValue={trimestre?.expresion_oral ?? ""} />
                </Grid>

                <Grid item xs={6} lg={2}>
                    <Input label="Comprensión" register={register} errors={errors} name={"comprension"} width={"100%"} defaultValue={trimestre?.comprension ?? ""} />
                </Grid>

                <Grid item xs={6} lg={2}>
                    <Input label="Expresión escrita" register={register} errors={errors} name={"expresion_escrita"} width={"100%"} defaultValue={trimestre?.expresion_escrita ?? ""} />
                </Grid>

                <Grid item xs={12} lg={2}>
                    <Input label="Nota media" register={register} errors={errors} name={"nota_media"} width={"100%"} defaultValue={trimestre?.nota_media ?? ""} />
                </Grid>
            </Grid>

            <Grid container>
                <Line />
            </Grid>

            <Grid container p={5} spacing={2}>
                <Grid item xs={4}>
                    <Input label="Evolución simulacros Cambridge en clase" register={register} errors={errors} width={"100%"} defaultValue={trimestre?.evolucion_simulacros ?? ""} name={"evolucion_comentarios"} multiline minRows={6} maxRows={10} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <Input label="Necesita repasar/Materia no superada" register={register} errors={errors} width={"100%"} defaultValue={trimestre?.materia_repasar ?? ""} name={"materia_repasar"} multiline minRows={6} maxRows={10} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <Input label="Comentarios" register={register} errors={errors} name={"comentarios"} width={"100%"} defaultValue={trimestre?.comentarios ?? ""} multiline minRows={6} maxRows={10} uppercase />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}    