import Statics from 'objects/common/Statics';
import { Link } from 'components/data_display';
import { Button } from 'components/forms/components';

export const columns = [
    {
        field: "actions",
        type: 'actions',
        headerName: "Acciones",
        minWidth: 200,
        getActions: (params) => [<Link to={"" + params.row.id}><Button size={"small"}>Ver</Button></Link>, <Link to={"" + params.row.id + "/editar"}><Button size={"small"} variant={"outlined"}>Editar</Button></Link>]
    },

    { field: "id",               headerName: "ID",        maxWidth: 65 },
    { field: "nombre",           headerName: "Libro",     flex: 1 },
    { field: "editorial",        headerName: "Editorial", flex: 1, valueGetter: (params) => { return params.row.editorial?.nombre ?? "" }},
    { field: "precio_editorial", headerName: "P. Coste",  flex: 1, valueGetter: (params) => { return params.row.precio_editorial ? Statics.changeDotAndComma(params.row.precio_editorial) : "" }},
    { field: "pvp",              headerName: "PVP",       flex: 1, valueGetter: (params) => { return params.row.pvp ? Statics.changeDotAndComma(params.row.pvp) : "" }},
    { field: "isbn",             headerName: "ISBN",      flex: 1 },
]
