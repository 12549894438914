import { Checkbox, ColorInput, Input, LoadingButton } from 'components/forms/components'
import { ItemsSelect } from "components/forms/components/Select/custom"
import { yupResolver } from '@hookform/resolvers/yup'
import { Horario } from 'components/data_display'
import validationSchema from './validation';
import { enqueueSnackbar } from 'notistack'
import { Grid, Stack } from "@mui/material"
import { H3, Text } from "components/texts"
import { useEffect, useRef, useState } from "react"
import Requests from './Requests/Requests'
import { useForm } from 'react-hook-form'
import { COLOR } from "theme/Colors"
import { useAlert, useErrorHandler } from "hooks"

export default function DataFormHorariosAcademias({ grupo, register: grupoFormRegister, errors: grupoFormErrors, onSaveFinish = () => {} }) {
    const [horarios, setHorarios] = useState({ all: grupo?.horarios ?? [], current: null })

    const [aula, setAula] = useState(null)
    const [loading, setLoading] = useState(false)

    const updateAlert = useAlert()
    const handleError = useErrorHandler()

    const [horasTotales, setHorasTotales] = useState(grupo?.horarios ? grupo.horarios.reduce((suma, objeto) => suma + objeto.horas_semanales, 0) : 0)

    const inputHoras = useRef()

    const defaultValues = { curso_escolar_id: grupo?.curso_escolar_id ?? "" }

    const { register, formState: { errors }, handleSubmit } = useForm({ resolver: yupResolver(validationSchema), defaultValues });

    const request = new Requests()

    useEffect(() => {
        // Si aula esta null, no muestra el mensaje, para mejorar experiencia de usuario y evitar que salga el mensaje inmediatamente cuando renderice el componente
        if (!aula)
            return

        const horarioToShow = horarios.all.find(horario => horario.aula_id === aula)
        if (!horarioToShow) {
            // enqueueSnackbar(<Text>No se han encontrado horarios para esta aula.</Text>, { variant: "error" })
            updateAlert('No se han encontrado horarios para esta aula.')
        } else { }
        setHorarios({ all: [...horarios.all], current: horarioToShow })
    }, [aula])

    const createHorario = (values) => {
        setLoading(true)

        request.createHorario(grupo?.id, values, (res) => {
            if (res.item) {
                setHorarios({ all: [...horarios.all, res.item], current: res.item })
                // enqueueSnackbar(<Text>Se ha creado el horario correctamente.</Text>, { variant: "success" })
                updateAlert('Se ha creado el horario correctamente.', 'success')
                setLoading(false) 

            }

            setHorasTotales(res?.item?.horas_semanales)
            onSaveFinish()
        }, (err) => {
            setLoading(false)
            // enqueueSnackbar(<Text>{res}</Text>, { variant: "error" })
            handleError(err)
        })

    }

    //Variable que contiene la suma de las horas semanales
    // let horasTotales = grupo?.horarios ? grupo.horarios.reduce((suma, objeto) => suma + objeto.horas_semanales, 0) : 0;
    //console.log('HorasTotales = ', horasTotales);
    
    const deleteHorario = (id_definicion_horario) => {
        request.deleteDefinicionHorario(id_definicion_horario, (res) => {

            if (res.item) {
                //console.log('res.item', res.item);
                //Buscar el index en horarios, donde res.item.id == horario.item, horarios[index] = res.item -> setHorarios(horarios)
                res.item.definiciones = res.item.definiciones.filter(definicion => definicion.id != id_definicion_horario)

                setHorarios({ all: [...horarios.all], current: res.item })

                horarios.all.forEach(horario => {
                    if (horario.id == res.item.id) {
                        horario.definiciones = horario.definiciones.filter(definicion => definicion.id != id_definicion_horario)

                        const existeHorario = horarios.all.some(h => h.id === horario.id);
                        if (!existeHorario)
                            setHorarios(prevHorarios => [...prevHorarios, horario]);
                    }
                });

                

                // enqueueSnackbar(<Text>Se ha eliminado la definición del horario correctamente.</Text>, { variant: "success" })
                updateAlert('Se ha eliminado la definición del horario correctamente.', 'success')
            }

            setHorasTotales(res?.item?.horas_semanales)
            onSaveFinish()
        }, (err) => {
            // enqueueSnackbar(<Text>Ha habido un error al crear la definición del horario correctamente.</Text>, { variant: "error" })
            handleError(err)
        })
    }

    //console.log('horas',inputHoras);
    

    return (

        <Grid container>
            <Grid container mt={2} px={4} spacing={2}>
                <Grid item xs={12}>
                    <H3>Horas {grupo?.registro_actividad?.intensivo ? "totales" : "semanales"}</H3>
                </Grid>

                <Grid item xs={12} md={6}>
                    {/*<Input ref={inputHoras} key={Math.random()} label={"Horas totales"} register={grupoFormRegister} errors={grupoFormErrors} className="w-100" name={"horas_totales"} type={"number"} useValue value={horasTotales} setValue />*/}
                    <input ref={inputHoras} key={Math.random()} label={"Horas totales"} className="px-4 py-2 rounded-sm" name={"horas_totales"} type={"number"} value={horasTotales} />
                </Grid>
            </Grid>

            <Grid container mt={2} px={4} spacing={2}>
                <Grid item xs={12}>
                    <H3>Generar horario</H3>
                </Grid>

                <Grid item md={6} xl={4}>
                    <ItemsSelect name={"aula_id"} keyValue={"aula"} register={register} errors={errors} defaultValue={grupo?.aula_id ?? ""} onChange={(({ target }) => setAula(target.value))} />
                </Grid>

                <Grid item md={6} xl={3}>
                    <Stack direction={"row"} justifyContent={"space-evenly"}>
                        <Checkbox name={"l"} label={"L"} className={"flex-column"} register={register} />
                        <Checkbox name={"m"} label={"M"} className={"flex-column"} register={register} />
                        <Checkbox name={"x"} label={"X"} className={"flex-column"} register={register} />
                        <Checkbox name={"j"} label={"J"} className={"flex-column"} register={register} />
                        <Checkbox name={"v"} label={"V"} className={"flex-column"} register={register} />
                        <Checkbox name={"s"} label={"S"} className={"flex-column"} register={register} />
                    </Stack>

                    {errors.daySelected ? <Text color={COLOR.error.main} style={{ marginLeft: 8, fontSize: '0.75rem' }}>{errors.daySelected.message}</Text> : null}
                </Grid>

                <Grid item md={6} xl={2} p={4}>
                    <Input type={"time"} label="Hora inicio:" required register={register} errors={errors} className="w-100"
                        name={"hora_inicio"} InputLabelProps={{ shrink: true }} uppercase />
                </Grid>

                <Grid item md={6} xl={2}>
                    <Input type={"time"} label="Hora fin:" required register={register} errors={errors} className="w-100"
                        name={"hora_fin"} InputLabelProps={{ shrink: true }} uppercase />
                </Grid>

                <Grid item md={6} xl={2}>
                    <ColorInput name={"color"} register={register} errors={errors} />
                </Grid>
                <Grid item xs={12} justifyContent="end" display={"flex"}>
                    <LoadingButton onClick={handleSubmit(createHorario)} loading={loading}>
                        Añadir al horario
                    </LoadingButton>
                </Grid>
            </Grid>

            {/* HORARIO */}
            <Grid container mt={2} px={4} spacing={2}>
                <Horario horarios={grupo?.horarios ? horarios.all : []} type="academia" onDeleteClick={deleteHorario} />
            </Grid>
        </Grid>
    )
    
}
