import React, { useRef } from "react"
import { Text } from 'components/texts'
import { Stack, Box } from "@mui/material"
import { Input } from "components/forms/components"

const splitDefaultValue = (iban) => {
    if(!iban)
        return { iban_2: "", iban_3: "", iban_4: "", iban_5: "", iban_6: "" }

    // const iban_1 = 'ha'
    const iban_2 = iban.substring(0, 4)
    const iban_3 = iban.substring(4, 8)
    const iban_4 = iban.substring(8, 12)
    const iban_5 = iban.substring(12, 16)
    const iban_6 = iban.substring(16, 20)

    return { iban_2, iban_3, iban_4, iban_5, iban_6 }
}

/**
 * Input custom para el IBAN
 */
export default function IBANInput({ register, errors, label = "IBAN:", sx = {}, defaultValue, dosDigitos }) {
    // const iban2Ref = useRef()
    // const iban3Ref = useRef()
    // const iban4Ref = useRef()
    // const iban5Ref = useRef()
    // const iban6Ref = useRef()

    const defaultSX = {
        width: "fit-content",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    }

    const moveToNext = (goTo, evt, length = 4) => {
        // const ignoreKeys = ["Backspace", "Delete", "Control", "Alt", "Meta", "Shift", "CapsLock", "Tab", "Escape", "F1", "F2", "F3", "F4", "F5", "F6", "F7", "F8", "F9", "F10", "F11", "F12"]
        // const refs = {
        //     "2": iban2Ref,
        //     "3": iban3Ref,
        //     "4": iban4Ref,
        //     "5": iban5Ref,
        //     "6": iban6Ref,
        // }

        // const value = evt.target.value

        // if(value.length >= length && refs[goTo] && !(ignoreKeys.includes(evt.key) || evt.ctrlKey)) {
        //     refs[goTo].current.input.focus()
        // }
    }

    const iban = splitDefaultValue(defaultValue)
    return (
        <Box sx={{
            ...defaultSX,
            ...sx
        }}>
            <Text>{label}</Text>
            <Stack direction="row" columnGap={1}>
                <Input register={register} width={45} name={"codigo_pais"} defaultValue={"ES"} disabled />
                <Input register={register} errors={errors} width={45} name={"digitos_control"} defaultValue={dosDigitos ?? ""} inputProps={{ maxLength: 2 }} onKeyDown={(evt) => moveToNext("2", evt, 2)}/>
                <Input register={register} errors={errors} width={80} name={"iban_2"} defaultValue={iban.iban_2 ?? ""} inputProps={{ maxLength: 4, style: { textAlign: "center" } }} onKeyDown={(evt) => moveToNext("3", evt)} />
                <Input register={register} errors={errors} width={80} name={"iban_3"} defaultValue={iban.iban_3 ?? ""} inputProps={{ maxLength: 4, style: { textAlign: "center" } }} onKeyDown={(evt) => moveToNext("4", evt)} />
                <Input register={register} errors={errors} width={80} name={"iban_4"} defaultValue={iban.iban_4 ?? ""} inputProps={{ maxLength: 4, style: { textAlign: "center" } }} onKeyDown={(evt) => moveToNext("5", evt)} />
                <Input register={register} errors={errors} width={80} name={"iban_5"} defaultValue={iban.iban_5 ?? ""} inputProps={{ maxLength: 4, style: { textAlign: "center" } }} onKeyDown={(evt) => moveToNext("6", evt)} />
                <Input register={register} errors={errors} width={80} name={"iban_6"} defaultValue={iban.iban_6 ?? ""} inputProps={{ maxLength: 4, style: { textAlign: "center" } }} />
            </Stack>
        </Box>
    )
}