import React from 'react'
import { Grid } from '@mui/material'
import { Text } from 'components/texts'
import { itemsActual } from '../searchAndOrderOptions'
import { Input, Select } from 'components/forms/components'
import { CursoEscolarSelect, ItemsSelect } from 'components/forms/components/Select/custom'

/**
 * Componente que presenta un conjunto de campos de entrada para buscar inscripciones de alumnos en la academia según criterios específicos como nombre, apellidos, tipo de actividad y año escolar.
 * Es una plantilla utilizada dentro de un sistema más amplio que permite búsquedas dinámicas basadas en diferentes criterios.
 *
 * @param {Function} register - Función proporcionada por react-hook-form para "registrar" los campos del formulario, integrándolos con la validación y el manejo de estado del formulario.
 * @param {Object} errors - Objeto que contiene errores de validación de react-hook-form, usado para mostrar mensajes de error específicos por campo.
 * @param {Function} reset - Función de react-hook-form que permite resetear los campos del formulario a sus valores iniciales o a valores específicos.
 * @param {Function} setValue - Función de react-hook-form para establecer programáticamente los valores de los campos del formulario.
 *
 * El componente estructura los campos de entrada y selección de forma que se adaptan a la búsqueda de inscripciones de alumnos por datos personales y académicos.
 */
export default function InscripcionAlumnosAcademiaTemplate({ register, errors, reset, setValue }) {
    return (
        <>
            <Grid container mt={2} mb={1}>
                <Text fontWeight={'bold'}>Según datos de:</Text><br />
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Input label="Nombre" width={"100%"} name={"nombre"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <Input label="Primer apellido" width={"100%"} name={"apellido1"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <Input label="Segundo apellido" width={"100%"} name={"apellido2"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={4}>
                    <ItemsSelect name={"tipo_actividad_id"} keyValue={"tipo_actividad"} register={register} errors={errors} wantToReset={reset} />
                </Grid>
                <Grid item xs={4}>
                    <CursoEscolarSelect label={"Año"} name={"curso_escolar_id"} wantToReset={reset} register={register} errors={errors} defaultValue={null} setValue={setValue} searcheable />
                </Grid>
                <Grid item xs={4}>
                    <Select register={register} errors={errors} name="actual" items={itemsActual} defaultValue={itemsActual[0].value} />
                </Grid>
            </Grid>
        </>
    )
}