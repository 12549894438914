import Statics from 'objects/common/Statics'
import { RequestGrupoAcademia, RequestGrupoEmpleado } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
    * Devuelve grupo
    * 
    * @param {Int}      id 
    * @param {Function} callbackOk 
    * @param {Function} callbackError 
    */
    getGrupo(id, callbackOk = () => { }, callbackError = () => { }) {
        const params = { appends: ['curso_escolar', 'registro_actividad', 'nivel_grupo', 'horarios', 'profesor_actual', 'libro'] }
        new RequestGrupoAcademia().get(id, params, (res) => {
            res ? callbackOk(res) : callbackError(res)
        })
    }

    /**
     * Hace la petición para edit el grupo
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editGrupo(id, values, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData ()
        formData.append("clave_grupo", values.clave.toUpperCase())
        formData.append("curso_escolar_id", values.curso_escolar_id)
        formData.append("registro_actividad_id", values.registro_actividad_id ?? "")
        formData.append("nivel_grupo_id", values.nivel_grupo_id ?? "")
        formData.append("num_grupo", values.num_grupo ?? "")

        formData.append('fecha_inicio', Statics.datePickerTimeFormat(values.fecha_inicio))
        formData.append("min_alumnos", values.min_alumnos ?? "")
        formData.append("max_alumnos", values.max_alumnos ?? "")
        formData.append("observaciones_grupo", values.observaciones_grupo?.toUpperCase() ?? "")

        //IDIOMAS
        formData.append("libro_id", values.libro_id ?? "")
        formData.append("usar_libro_asociado", values.usar_libro_asociado ? 1 : 0)
        formData.append("usar_progress_sheet", values.usar_progress_sheet ? 1 : 0)


        if (values.horas_totales)
            formData.append("horas_totales", values.horas_totales ?? "")

        new RequestGrupoAcademia().edit(id, formData, callbackOk, callbackError)
        //onSavefinish()
    } 
    

    /**
     * Edita el empleado de un grupo
     * 
     * @param {Object}   grupo 
     * @param {Object}   values 
     * @param {Function} callbackOk 
     * @param {Function} callbackError
     * @param {Function} callbackFinally 
     */

    
    
    editEmpleadoGrupo(grupo, values, callbackOk = () => { }, callbackError = () => { }, callbackFinally = () => { }) {

        console.log('grupo', grupo);
        console.log('values', values);
        console.log('grupo.profesor_actual', grupo.profesor_actual);
        console.log('values.empleado_id', values.empleado_id);
        console.log('!grupo.profesor_actual && values.empleado_id', !grupo.profesor_actual && values.empleado_id);
        console.log('!grupo.profesor_actual && values.empleado_id', !grupo.profesor_actual && values.empleado_id);
        console.log('grupo.profesor_actual && !values.empleado_id', grupo.profesor_actual && !values.empleado_id);
        console.log('grupo.profesor_actual && grupo.profesor_actual.empleado_id === values.empleado_id', grupo.profesor_actual && grupo.profesor_actual.empleado_id === values.empleado_id);

        // Si profesor actual es nulo (se ha creado el grupo sin profesor) y el valor de empleado id del formulario de creacion esta vacio (no seleccionamos ningun profesor) que devuelva Ok
        //cuando el grupo no tiene profesor actual pero rellenamos en el formulario con un profe.
        if(grupo.profesor_actual === null && values.empleado_id === "") {// --> CAMBIO
            console.log('1')
            this.createEmpleadoGrupo(grupo.id, values.empleado_id)                                
            return
        }

        //cuando el grupo tiene profesor actual pero le hemos quitado el empleado_id, lo queremos borrar
        
        if(grupo.profesor_actual && !values.empleado_id) {// --> CAMBIO
            console.log('2')
            const formData = new FormData()
            formData.append("empleado_id" , values.empleado_id ?? "")
            formData.append("grupo_type", 'grupo-academia' ?? "")
            formData.append("grupo_id", grupo.id ?? "")
            formData.append("actual", 1)
            
            this.deleteEmpleadoGrupo("", formData, callbackOk, callbackError, callbackFinally)
            
        }
    
        
        if (grupo.profesor_actual && grupo.profesor_actual.empleado_id === values.empleado_id) {
            console.log('3')
            callbackOk()
        } else {
            console.log('4')
            const formData = new FormData()
            formData.append("empleado_id" ,  values.empleado_id ?? "")
            formData.append("grupo_type", "grupo-academia")
            formData.append("grupo_id", grupo.id)
            formData.append("actual", 1) // Lo pongo en 1 para que aparezca reflejado el profesor en front, si no el scope de la API no lo devuelve. Se podria prescindir de este campo y usar algo como la fecha de baja, para ver si es nulo que es actual, y si tiene valor datetime que se haya sobrepasado ponerlo como no actual

            new RequestGrupoEmpleado().put("", formData, callbackOk, callbackError, callbackFinally)
        }
    }

    /***
     * Metodo request de enviar el post a la base de datos e insertar el profesor en esta. Esta copiado del request de create grupo.
     */
     createEmpleadoGrupo(grupoId, empleadoId, callbackOk = () => { }, callbackError = () => { }) {
        if (empleadoId && empleadoId != "") {
            const formData = new FormData
            formData.append("grupo_id", grupoId)
            formData.append("grupo_type", "grupo-academia")
            formData.append("empleado_id", empleadoId)
            formData.append("actual", 1) // Lo pongo en 1 para que aparezca reflejado el profesor en front, si no el scope de la API no lo devuelve. Se podria prescindir de este campo y usar algo como la fecha de baja, para ver si es nulo que es actual, y si tiene valor datetime que se haya sobrepasado ponerlo como no actual

            new RequestGrupoEmpleado().create(formData, callbackOk, callbackError)
        } else {
            callbackOk()
        }

    }
}
