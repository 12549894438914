import React from 'react'
import { Grid } from '@mui/material'
import { H3, Text } from 'components/texts'
import Skeleton from './skeletonActividadesAcademia'
import { Checkbox } from 'components/forms/components'

export default function ActividadesAcademiaData({ actividad }) {
  return (
    <Grid container spacing={5}>
      {!actividad ?
        <Skeleton />
        : (
          <React.Fragment>
            <Grid item xs={12} mt={3}>
              <H3>Datos de las actividades de la academia: </H3>
            </Grid>

            <Grid item xs={3} display={"flex"} flexDirection={"column"}>
              <Text fontWeight={"bold"}>Tipo de actividad</Text>
              <Text>{actividad?.tipo_actividad?.valor ?? "Sin rellenar"}</Text>
            </Grid>

            <Grid item xs={3} display={"flex"} flexDirection={"column"}>
              <Text fontWeight={"bold"}>Nombre abreviado</Text>
              <Text>{actividad?.nombre ?? "Sin rellenar"}</Text>
            </Grid>

            <Grid item xs={3} display={"flex"} flexDirection={"column"}>
              <Text fontWeight={"bold"}>Modalidad del curso</Text>
              <Text>{actividad?.modalidad_cursos?.nombre ?? "Sin rellenar"}</Text>
            </Grid>

            <Grid item xs={3} display={"flex"} flexDirection={"column"} >
              <Checkbox key={actividad.id} name={"intensivo"} disabled label={"Curso intensivo"} className="mt-2" defaultChecked={actividad?.intensivo ? true : false} />
            </Grid>

            <Grid item xs={3} display={"flex"} flexDirection={"column"}>
              <Text fontWeight={"bold"}>Precio</Text>
              <Text>{actividad?.precio ?? "Sin rellenar"}</Text>
            </Grid>

            <Grid item xs={3} display={"flex"} flexDirection={"column"}>
              <Text fontWeight={"bold"}>Matrícula</Text>
              <Text>{actividad?.matricula ?? "Sin rellenar"}</Text>
            </Grid>

            <Grid item xs={3} display={"flex"} flexDirection={"column"}>
              <Text fontWeight={"bold"}>Precio Junio</Text>
              <Text>{actividad?.precio_junio ?? "Sin rellenar"}</Text>
            </Grid>
          </React.Fragment>
        )
      }
    </Grid>
  )
}

