import { v4 as uuid } from 'uuid'
import React, { Component } from 'react'
import { Select } from 'components/forms/components'
import { RequestHorarioColegio } from 'objects/requests'

const DEFAULT_VALUES = [{ value: "", label: "Elige una actividad para cargar sus horarios." }]
/**
 * HorarioActividadColegioSelect. Un select que se encarga de la descarga de los horarios de una actividad disponibles
 */
export default class HorarioActividadColegioSelect extends Component {

    /**
     * Constructor
     * 
     * @param {Object} props 
     */
    constructor(props) {
        super(props)

        this.state = {
            horarios: DEFAULT_VALUES,
            rawHorarios: [],
        }
    }

    componentDidMount(){
        if(this.props.id_actividad && this.props.id_curso_escolar)
            this.getHorariosActividad(this.props.id_actividad, this.props.id_curso_escolar)
    }


    componentDidUpdate(prevProps) {
        if(this.props.wantToReset && !prevProps.wantToReset) {
            this.setState({horarios: DEFAULT_VALUES, rawHorarios: []})
        }

        if(this.props.id_actividad && this.props.id_curso_escolar && (this.props.id_actividad != prevProps.id_actividad || this.props.id_curso_escolar != prevProps.id_curso_escolar))
            this.getHorariosActividad(this.props.id_actividad, this.props.id_curso_escolar)
    }
    
    //-------------------------------------------------------
    //-------------------- CLASS FUNCTIONS ------------------
    //-------------------------------------------------------

    onChange = (e) => {
        if(this.props.onChange) {
            const horario = this.state.rawHorarios.find(item => item.id == e.target.value)
            this.props.onChange(e, horario)
        }
    }


    /**
     * Hace la peticion para obtener las actividades de un colegio en un curso escolar
     * 
     * @param {Int} registro_actividad_id
     * @param {Int} curso_escolar_id
     */
    getHorariosActividad = (registro_actividad_id, curso_escolar_id = null) => {
        const params = { registro_actividad_id, relations: 'definiciones', curso_escolar_id, limit: 100 }

        new RequestHorarioColegio().search(params, (res) => {
            const horarios = []
            for (const item of res.items.data)
                horarios.push({value: item.id, label: item.horario_formatted})
                
            this.setState({ horarios, rawHorarios: res.items.data })
        }, (res) => {
            this.setState({ horarios: [{ value: "", label: "Esta actividad no tiene ningún horario creado." }], rawHorarios: [] })
        })
    }

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     * 
     * @returns {Component}
     */

    render() {
        const labelId = "horario_actividad_colegio_select-id-" + uuid()
        return (
            <Select
                ref          = {(ref) => this.select = ref}
                width        = {this.props.width ?? "100%"}
                items        = {this.state.horarios}
                className    = {this.props.className}
                defaultValue = {this.props.defaultValue}
                onChange     = {this.onChange}
                onDefaultValueLoaded={this.props.onDefaultValueLoaded}
                register     = {this.props.register}
                name         = {this.props.name ?? "id_descripcion_horario"}
                errors       = {this.props.errors}
                disabled     = {this.props.disabled}
                labelId      = {labelId}
                wantToReset  = {this.props.wantToReset}
                label        = {this.props.label ?? "Horario actividad colegio"} />
        )
    }
}
