import { Grid } from "@mui/material";
import { Input } from "components/forms/components";

/**
 * Formulario con las anotaciones de las clases particulares
 */
export default function DataFormAnotacionesClasesParticulares({claseParticular, register, errors}) {
    
    return (
        <Grid container>
            <Grid container px={5}>
                <Grid item xs={6} py={3} pr={2}>
                    <Input
                        label="Comentarios"
                        register={register}
                        errors={errors}
                        className="w-100"
                        name={"comentarios"}
                        defaultValue={claseParticular?.comentarios ?? null}
                        multiline
                        minRows={6}
                        maxRows={10}
                        uppercase />
                </Grid>

                <Grid item xs={6} py={3} pr={2}>
                    <Input
                        label="Cancelaciones y Recuperaciones"
                        register={register}
                        errors={errors}
                        className="w-100"
                        name={"cancelaciones"}
                        defaultValue={claseParticular?.cancelaciones ?? null}
                        multiline
                        minRows={6}
                        maxRows={10}
                        uppercase />
                </Grid>
            </Grid>
        </Grid>
    )
}