import * as Yup from 'yup'

export const validation = {

    //LIBRO SOLICITADO
    fecha_peticion: Yup.string().when('libro_solicitado', {
        is: true,
        then: Yup.string().required('La fecha de solicitud es requerida.'),
    }),

    precio: Yup.string().when('libro_solicitado', {
        is: true,
        then: Yup.string().required('El precio de libro solicitado es requerido.'),
    }),

    //LIBRO ASOCIADO
    fecha_asociado_solicitud: Yup.string().when('libro_asociado_solicitado', {
        is: true,
        then: Yup.string().required('La fecha de solicitud asociado es requerida.'),
    }),

    precio_asociado: Yup.string().when('libro_asociado_solicitado', {
        is: true,
        then: Yup.string().required('El precio de libro solicitado asociado es requerido.'),
    }),
}

export const validationSchema = Yup.object().shape(validation)