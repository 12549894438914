import * as Yup from 'yup'

export const validation = {
    id_alumno:               Yup.string().required('El alumno es obligatorio.'),
    curso_escolar_id:        Yup.string().required('El curso escolar es obligatorio'),
    id_forma_recogida:       Yup.string().required('La forma de recogida es obligatoria'),
    id_forma_conocimiento:   Yup.string().required('La forma de recogida es obligatoria'),
    id_colegio:              Yup.string().required('El colegio es obligatorio'),
    id_registro_actividad:   Yup.string().required('La actividad es obligatoria'),
    id_descripcion_horario:  Yup.string().required('El horario es obligatorio'),
}

export const validationSchema = Yup.object().shape(validation)