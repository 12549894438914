import { Text } from 'components/texts'
import { Line } from 'components/data_display'
import React, { useEffect, useState } from 'react'
import { AlumnoPicker } from 'components/forms/components/custom'
import { Checkbox, DatePicker, Input } from 'components/forms/components'
import { Grid, Skeleton, Stack, Box, List, ListSubheader, ListItemText, ListItem } from '@mui/material'
import { CursoEscolarSelect, ItemsSelect, HorarioActividadColegioSelect, RegistroActividadColegioSelect } from 'components/forms/components/Select/custom'

import { CollapsableItemList } from 'components/data_display'

/**
 * Formulario con los datos generales de la inscripcion a un colegio
 */
export default function GeneralInscripcionColegioDataForm({ inscripcion, register, errors, isMounted = false, setValue, onHorarioChange, clearErrors, onActividadChangeCallback }) {
    
    const [alumno,       setAlumno]       = useState(inscripcion?.alumno                ?? null)
    const [cursoEscolar, setCursoEscolar] = useState(inscripcion?.id_curso_escolar      ?? null)
    const [colegio,      setColegio]      = useState(inscripcion?.id_colegio            ?? null)
    const [actividad,    setActividad]    = useState(inscripcion?.id_registro_actividad ?? null)
    const [wantToReset,  setWantToReset]  = useState({ registroActividad: false, horarioActividad: false })

    useEffect(() => {
        if(wantToReset.registroActividad || wantToReset.horarioActividad)
            setWantToReset({ registroActividad: false, horarioActividad: false })
    }, [wantToReset.registroActividad, wantToReset.horarioActividad])

    return (
        <Grid container>

            <Grid container px={5}>
                <Grid item xs={6} py={3} pr={2}>
                    <AlumnoPicker
                        name             = {"id_alumno"}
                        label            = {"Alumno"}
                        editLabel        = {"Cambiar alumno"}
                        addLabel         = {"Añadir alumno"}
                        deleteLabel      = {"Borrar alumno"}
                        relations        = {"inscripciones_colegios"}
                        register         = {register}
                        errors           = {errors}
                        alumno           = {inscripcion?.alumno ?? null}
                        onAlumnoSelected = {setAlumno}
                        setValue         = {setValue}
                        clearErrors      = {clearErrors}
                        //Si tiene está editando ya no se puede cambiar el alumno
                        canEdit          = {inscripcion ? false : true} />
                </Grid>

                <Grid item xs={6} style={{borderLeft: "1px solid #D9D9D9"}} py={3} pl={3}>
                    <Stack spacing={2}>
                        {isMounted ?
                            <CursoEscolarSelect 
                                name={"curso_escolar_id"} 
                                onChange={({target}) => {
                                    setValue("id_descripcion_horario", "")
                                    setValue("id_registro_actividad", "")
                                    onHorarioChange(-1)
                                    setCursoEscolar(target.value)
                                    setWantToReset({ registroActividad: true, horarioActividad: true })
                                    setActividad(null)
                                    onActividadChangeCallback(null)
                                }}
                                register={register} 
                                errors={errors} 
                                defaultValue={inscripcion?.id_curso_escolar ?? null} 
                                setValue={setValue} 
                                searcheable />
                             : <Skeleton variant="rounded" width={"100%"} height={40} />}

                        {isMounted ? 
                            <ItemsSelect 
                                name          = {"id_forma_recogida"} 
                                keyValue      = {"forma_recogida"}
                                register      = {register} 
                                errors        = {errors} 
                                defaultValue  = {inscripcion?.id_forma_recogida ?? ""} /> : <Skeleton variant="rounded" width={"100%"} height={40} />}
                        
                        {isMounted ?
                            <ItemsSelect 
                                name          = {"id_forma_conocimiento"} 
                                keyValue      = {"forma_conocimiento"}
                                register      = {register} 
                                errors        = {errors} 
                                defaultValue  = {inscripcion?.id_forma_conocimiento ?? ""} /> : <Skeleton variant="rounded" width={"100%"} height={40} />}
                    </Stack>
                </Grid>
            </Grid>

            <Grid container>
                <Line />
            </Grid>

            <Grid container px={5} mt={2} spacing={2}>
                <Grid item xs={6} md={4}>
                    {isMounted ? <ItemsSelect
                                    name                 = {"id_colegio"}
                                    keyValue             = {"colegio"}
                                    register             = {register}
                                    errors               = {errors}
                                    defaultValue         = {inscripcion?.id_colegio ?? ""} 
                                    onChange             = {({target}) => {
                                        setValue("id_descripcion_horario", "")
                                        setValue("id_registro_actividad", "")
                                        onHorarioChange(-1)
                                        setWantToReset({ registroActividad: true, horarioActividad: true })
                                        setActividad(null)
                                        setColegio(target.value)
                                        onActividadChangeCallback(null)
                                    }}
                                    searcheable /> : <Skeleton variant="rounded" width={"100%"} height={50} />}
                </Grid>

                <Grid item xs={6} md={4}>
                    {isMounted ? <RegistroActividadColegioSelect
                                    register         = {register}
                                    errors           = {errors}
                                    defaultValue     = {inscripcion?.id_registro_actividad ?? ""}
                                    wantToReset      = {wantToReset.registroActividad}
                                    id_curso_escolar = {cursoEscolar}
                                    id_colegio       = {colegio}
                                    initialLabel     = {"Elige un colegio y el año para cargar las actividades."}
                                    onChange         = {({target}, actividad) => {
                                        setWantToReset({ registroActividad: wantToReset.registroActividad, horarioActividad: true })
                                        setValue("id_descripcion_horario", "")
                                        onHorarioChange(-1)
                                        setActividad(target.value)
                                        onActividadChangeCallback(actividad)
                                    }}/> : <Skeleton variant="rounded" width={"100%"} height={50} />}
                </Grid>

                <Grid item xs={6} md={4}>
                    {isMounted ? <HorarioActividadColegioSelect 
                                    register             = {register}
                                    id_curso_escolar     = {cursoEscolar}
                                    id_actividad         = {actividad}
                                    wantToReset          = {wantToReset.horarioActividad}
                                    errors               = {errors} 
                                    defaultValue         = {inscripcion?.id_descripcion_horario ?? ""}
                                    onDefaultValueLoaded = {({target}) => onHorarioChange(target.value)}
                                    onChange             = {({target}) => onHorarioChange(target.value)} /> : <Skeleton variant="rounded" width={"100%"} height={50} /> }
                </Grid>
            </Grid>

            <Grid container px={5} mt={2} spacing={2}>
                <Grid item xs={7}>
                    <Box sx={{height: 250, border: "1px solid #c3c4c9", borderRadius: 1}}>
                        <List
                            sx              = {{ width: '100%', height: "100%", overflowY: "auto" }}
                            component       = "nav"
                            aria-labelledby = "nested-list-subheader"
                            subheader       = {<ListSubheader component="div" id="nested-list-subheader"><Text>Otras actividades del alumno</Text></ListSubheader>}>
                            
                    
                            {alumno && alumno.inscripciones_colegios?.length > 0 ? alumno.inscripciones_colegios.map(item => {
                                if(inscripcion && inscripcion.id == item.id)
                                    return null

                                const title = (item.colegio ? item.colegio.nombre : "") + " " + (item.actividad ? item.actividad.valor : "") + " -> " + (item.horario ? item.horario.horario_formatted : "")
                                return item.grupo ? <CollapsableItemList key={Math.random()} title={title} childTitle={"Grupo: " + (item.grupo.clave ?? "")}/> : <ListItem key={Math.random()}><ListItemText primary={title ?? ""} /></ListItem>
                            }) : null}
                        </List>
                    </Box>
                </Grid>

                <Grid item xs={2}>
                    <Stack>
                        <Checkbox name={"hermanos"}   label={"Hermanos"}            register={register} defaultChecked={inscripcion?.hermanos ? true : false} />
                        <Checkbox name={"encuestado"} label={"Encuesta telefónica"} register={register} defaultChecked={inscripcion?.encuestado ? true : false} />
                    </Stack>
                </Grid>
                <Grid item xs={3} display={"flex"} alignItems={"end"}>
                    <Input label="Test de nivel" register={register} errors={errors} className="w-100" name={"test_nivel"} defaultValue={inscripcion?.test_nivel ?? ""} uppercase />
                </Grid>
            </Grid>

            <Grid container px={5} mt={2} mb={4} spacing={2}>
                <Grid item xs={6} md={3}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_inscripcion"} label={"Fecha de inscripción"} defaultValue={inscripcion?.fecha_inscripcion} setValue={setValue} />
                </Grid>

                <Grid item xs={6} md={3}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_incorporacion"} label={"Fecha de incorporación"} defaultValue={inscripcion?.fecha_incorporacion ?? ""} setValue={setValue} />
                </Grid>
                <Grid item xs={6} md={3}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_baja"} label={"Fecha baja"} defaultValue={inscripcion?.fecha_baja ?? ""} setValue={setValue} />
                </Grid>
                <Grid item xs={6} md={3}>
                    {isMounted ? <ItemsSelect 
                                    name         = {"id_motivo_baja"} 
                                    keyValue     = {"motivo_baja"}
                                    register     = {register} 
                                    errors       = {errors}
                                    defaultValue = {inscripcion?.id_motivo_baja ?? ""}  /> : <Skeleton variant="rounded" width={"100%"} height={40} />}
                </Grid>
            </Grid>
        </Grid>
    )
}
