import { DataFormIncidencia } from "components/forms/display"
import { Modal } from "components/modals"
import { useEffect, useRef, useState } from "react"
import { validationSchema } from "components/forms/display/incidencia/DataForm/validation"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Select } from "components/forms/components"
import { RequestIncidencia } from "objects/requests"
import { enqueueSnackbar } from "notistack"
import { Text } from "components/texts"
import { useAlert, useErrorHandler } from "hooks"
import { Alert, Grid } from "@mui/material"
import { AlumnoFullItem } from "components/data_display/users"
import Statics from "objects/common/Statics"
import { RequestAlumno } from "objects/requests"

export default function EditIncidenciasNoResueltasModal({ alumno, open, setOpen, incidencias, isMounted = false, onSaveFinish }) {
    const [fetchIncidencias, setFetchIncidencias] = useState([]);
    const handleError = useErrorHandler()
    const updateAlert = useAlert()
    const modalRef = useRef()
    const [selectedIncidencia, setSelectedIncidencia] = useState(null)
    const { register, setValue, handleSubmit, reset: resetForm, formState: { errors } } = useForm({ resolver: yupResolver(validationSchema) })

    useEffect(() => {
        if (open) {
            // Resetear formulario y restablecer el valor de la incidencia seleccionada al valor por defecto (null) antes de abrir el modal
            resetForm()
            setSelectedIncidencia(null)

            modalRef.current.open()

            setOpen(false)
        }
    }, [open, resetForm])

    // TODO: Para forzar la actualizacion del valor del campo incidencia. Habria que revisar esto para hacerlo consistente y que actualice sin necesidad de setValue
    useEffect(() => {
        if (selectedIncidencia) {
            Object.keys(selectedIncidencia).forEach(key => {
                setValue(key, selectedIncidencia[key])
            })
        }
    }, [selectedIncidencia, setValue, resetForm])

    const handleOnChange = (event) => {
        const selectedIncidencia = incidencias.find(incidencia => incidencia.id === event.target.value)
        setSelectedIncidencia(selectedIncidencia)
    }

    const editIncidencia = (values) => {
        const formData = new FormData()
        formData.append('fecha', values.fecha)
        formData.append('incidencia', values.incidencia.toUpperCase())
        formData.append('id_alumno', values.id_alumno)
        formData.append('id_curso_escolar', values.id_curso_escolar)
        formData.append('id_tipo_incidencia', values.id_tipo_incidencia)
        formData.append('solucionada', values.solucionada ? 1 : 0)

        new RequestIncidencia().edit(values.id, formData, (res) => {
            modalRef.current.close()
            updateAlert('Incidencia editada correctamente.', 'success')
            onSaveFinish()
        }, (err) => {
            handleError(err)
        })
    }

    // /**
    //  * Función para obtener las incidencias asociadas a un alumno específico.
    //  *
    //  * @param {string} idAlumno - Identificador del alumno del que se desean obtener los datos del colegio.
    //  */
    // const getAlumno = (idAlumno) => {
    //     // Realiza una solicitud para obtener los datos del colegio del alumno específico
    //     new RequestAlumno().get(`?id_alumno=${idAlumno}`, {}, (res) => {setFetchIncidencias(res?.data?.incidencias)},(err) => handleError(err))
    // }


    const items = incidencias?.map(incidencia => ({
        value: incidencia?.id,
        label: `${Statics.formatDate(incidencia?.fecha)} - ${incidencia?.tipo_incidencia?.valor}`
    }))

    return (
        <Modal ref={modalRef} title="Incidencias no resueltas">
            <Grid sx={{ mx: 2, my: 2, pb: 2 }}>
                {alumno ? <AlumnoFullItem alumno={alumno} containerClassName="m-5" /> : null}
            </Grid>

            <Grid sx={{ mt: 4, px: 4 }}>
                <Alert icon={false} className="mb-2 p-0 bg-white" severity="info">Selecciona una incidencia de la lista, la cual se muestra en el formato 'Fecha - Tipo' para facilitar su identificación.</Alert>
                <Select
                    label="Selecciona incidencia"
                    items={items}
                    onChange={handleOnChange} />
            </Grid>

            <Grid sx={{ pb: 2 }}>
                <DataFormIncidencia
                    incidencia={selectedIncidencia}
                    isMounted={isMounted}
                    register={register}
                    setValue={setValue}
                    errors={errors}
                    handleSubmit={handleSubmit}
                    onSubmit={editIncidencia} 
                />
            </Grid>
        </Modal>
    )
}