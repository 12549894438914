import { TabContext } from '@mui/lab'
import { COLOR } from 'theme/Colors.js'
import Requests from './Request/Request'
import { useParams } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { Button } from 'components/forms/components'
import { H3, Text } from 'components/texts/index.js'
import { Edit as EditIcon } from '@mui/icons-material'
import { useAcademiaInscripcionSingleLogic } from 'hooks'
import { Box, Container, Paper, Tab, Tabs, Stack } from '@mui/material'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
import { DataFormInscripcionAcademias, DataFormGrupoAcademias, PagosAcademiaDataForm, LibroAcademiaDataForm } from './parts'

import Headers from 'components/headers/index'
import Skeleton from './Skeleton'

/**
 * Componente React para mostrar la información de una inscripción individual.
 * Utiliza varios componentes para formularios y muestra la información en pestañas.
 * 
 * @returns {React.Component}
 */
export default function SingleInscripcion() {
    const navigationParams = useParams()

    const { getInscripcion, isMounted, incidenciasNoResueltas, navigate, setInscripcion, updateId, id_inscripcion, inscripcion, tab, setTab } = useAcademiaInscripcionSingleLogic({ navigationParams, requests: new Requests() })

    return (
        inscripcion ? (
            <Container maxWidth={false} sx={{ mt: 5 }}>
                <Stack direction="row" justifyContent={"space-between"} marginX={8}>
                    <BreadcrumbsList mx={0} breadcrumbsList={[
                        { to: "/", label: "Inicio" },
                        { to: "./../", label: "Academia" }, //--> Te dirige al apartado Inscripciones
                        { to: "./../", label: "Inscripciones" },
                        { to: "./", label: "Inscripción #" + (id_inscripcion ?? "0000"), actualItem: true },
                    ]} />

                    <MoveButtons
                        actualItemId={id_inscripcion}
                        beforeFetchCallback={() => setInscripcion(null)}
                        onUpdateActualItem={updateId} />
                </Stack>

                <Paper elevation={2} sx={{ mx: 8, my: 2 }}>
                    <Headers
                        template={'single'}
                        title={"Consultar Inscripción"}
                        actividad={inscripcion?.alumno?.apellido2 + ' ' + inscripcion?.alumno?.apellido1 + ',' + inscripcion?.alumno?.nombre}
                        needMargin
                    />

                    <Line className={"mb-5"} />

                    <TabContext value={tab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
                            <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                                <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>DATOS DE LA INSCRIPCIÓN</Text>} value={"1"} />
                                <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>DATOS DEL GRUPO</Text>} value={"2"} />
                                <Tab label={<Text fontWeight={tab === "3" ? "bold" : null} color={tab === "3" ? COLOR.standard.main : null}>DATOS DE LOS PAGOS</Text>} value={"3"} />
                                <Tab label={<Text fontWeight={tab === "4" ? "bold" : null} color={tab === "4" ? COLOR.standard.main : null}>LIBROS</Text>} value={"4"} />
                            </Tabs>
                        </Box>
                        <TabPanel value={"1"}>
                            <DataFormInscripcionAcademias
                                isMounted={isMounted}
                                incidenciasNoResueltas={incidenciasNoResueltas}
                                inscripcion={inscripcion}
                                onSaveFinish={() => getInscripcion(true)} />
                        </TabPanel>

                        <TabPanel value={"2"}>
                            <DataFormGrupoAcademias inscripcion={inscripcion} />
                        </TabPanel>

                        <TabPanel value={"3"}>
                            <PagosAcademiaDataForm inscripcion={inscripcion} />
                        </TabPanel>

                        <TabPanel value={"4"}>
                            <LibroAcademiaDataForm inscripcion={inscripcion} />
                        </TabPanel>

                    </TabContext>
                </Paper>

                <SnackbarProvider autoHideDuration={3000} />
            </Container>
        ) : (
            <Skeleton />
        )
    )
}
