import { Link } from 'components/data_display'
import { Button } from 'components/forms/components'

export const basicColumns = [
    {
        field: "actions",
        type: 'actions',
        headerName: "Acciones",
        minWidth: 200,
        getActions: (params) => [<Link to={"" + params?.row?.id}><Button size={"small"}>Ver</Button></Link>, <Link to={"" + params?.row?.id + "/editar"}><Button size={"small"} variant={"outlined"}>Editar</Button></Link>]
    },

    { field: "id", headerName: "ID", maxWidth: 250, sortable: false, filterable: false },
    {
        field: "Año", headerName: "Año", maxWidth: 250, flex: 1, valueGetter: (params) => {
            return params?.row?.curso_escolar?.valor
        },
        sortable: false,
        filterable: false,
    },
    {
        field: "registro_actividad_id", headerName: "Actividad", maxWidth: 250, flex: 1, valueGetter: (params) => {
            return params?.row?.actividad?.nombre
        },
        sortable: false,
        filterable: false,
    },
    {
        field: "Alumno", headerName: "Alumno", maxWidth: 250, flex: 1, valueGetter: (params) => {
            const nombre = params.row?.alumno?.nombre ?? '';
            const apellido1 = params.row?.alumno?.apellido1 ?? '';
            const apellido2 = params.row?.alumno?.apellido2 ?? '';

            return `${nombre} ${apellido1} ${apellido2}`.trim();
        },
        sortable: false,
        filterable: false,
    },
]

export const testNivelColumns = [
    {
        field: "test_nivel", headerName: "Test de nivel", maxWidth: 250, valueGetter: (params) => {
            return params?.row?.nivel ?? ""
        }, sortable: false, filterable: false
    },
]

export const grupoColumns = [
    {
        field: "clave_grupo", headerName: "Clave Grupo", maxWidth: 250, flex: 1, valueGetter: (params) => {
            return params?.row?.grupo_academia?.clave_grupo ?? ""
        },
        sortable: false,
        filterable: false,
    },
]

//TODO: revisar cuando se implemente la funcionalidad del modulo de Empresas
export const empresaColumns = [
    {
        field: "empresa", headerName: "Empresa", flex: 1, valueGetter: (params) => {
            return params?.row?.empresa?.valor ?? ""
        },
        sortable: false,
        filterable: false,
    },
]
