import Requests from '../../Requests'
import { Text } from 'components/texts'
import React, { Component } from 'react'
import { Box, Stack } from '@mui/material'
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { enqueueSnackbar } from 'notistack'

/**
 * Plantilla que se va a cargar en el tooltip de añadir empleados
 */
export default class TooltipTemplate extends Component {
    
    constructor(props) {
        super(props)
    }


    /**
     * Callback para cuando se elige un empleado
     * 
     * @param {Object} target 
     */
    onChange = ({target}) => {
        const formData = new FormData()
        formData.append('id_empleado',      target.value)
        formData.append('id_agenda_evento', this.props.evento.id)

        new Requests().share(formData, (res) => {
            enqueueSnackbar(<Text>Se ha añadido el empleado correctamente.</Text>, { variant: "success"})
            if(this.props.onShare)
                this.props.onShare(res.empleado)
        }, (res) => {
            enqueueSnackbar(<Text>{"Error: " + res}</Text>, { variant: "error"})
        })
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <Box px={3} py={2}>
                <Stack spacing={1}>
                    <Text fontWeight={"bold"}>Buscar los empleados para añadirlos al evento.</Text>

                    <ItemsSelect 
                        keyValue     = {"empleado_cl"} 
                        params       = {{roles: 'admin'}}
                        onChange     = {this.onChange} 
                        renderItem   = {(item) => ({ value: item.id, label: item.nombre + " " + (item.apellido1 ?? "") + " - " + (item.email ?? "") })}
                        searcheable />
                </Stack>
            </Box>
        )
    }
}