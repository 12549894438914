
export const ACTIVIDADES_VALUE = "actividades"
export const PROFESORES_VALUE  = "profesor"

export const itemsSearch = [
    { value: ACTIVIDADES_VALUE, label: "Grupos de los grupos por Actividades"}, 
    { value: PROFESORES_VALUE,  label: "Grupos de los grupos por Profesores" }
]

export const itemsOrder  = {
    actividades: [
        { value: '{"registro_actividad.nombre": "ASC"}',   label: "Actividades" },
        { value: '{"grupo_academias.clave_grupo": "ASC"}', label: "Clave de Grupo" },
    ],
    profesor: [
        { value: '{"empleado.apellido1": "ASC", "empleado.apellido2": "ASC", "empleado.nombre": "ASC"}', label: "Profesores" }
    ]
}

/**
 * Array que contiene las opciones para poder buscar todas los grupos, 
 * o filtrar por los grupos que son actuales (fecha_fin NULL)
 */
export const itemsActual = [
    { value: 0, label: "Todos" },
    { value: 1, label: "Actual" }
]