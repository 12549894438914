import React from 'react'
import Skeleton from './skeleton'
// import { ASSETS } from 'constants/Assets'
import { H3, Text } from 'components/texts'
import { Avatar, Box, Grid } from '@mui/material'
import { useImageLoader } from 'hooks'
import { DEFAULT_IMAGES_URL } from 'constants/DefaultImagesUR'

/**
 * Hook `useLogic` para procesar la información del alumno asociado a una tutoría.
 * Utiliza `useImageLoader` para cargar dinámicamente la imagen del alumno, proporcionando
 * una URL de imagen por defecto en caso de que el alumno no tenga una asignada.
 * 
 * @param {Object} tutoria - Objeto de tutoría que incluye información del alumno.
 * @returns {Object} - Retorna el alumno procesado y la fuente de imagen para su avatar.
 */
function useLogic(tutoria) {
	const alumno = tutoria?.alumno ?? {}

	const { imageSrc } = useImageLoader(alumno, 'foto_logo', DEFAULT_IMAGES_URL.alumno, true)

	return { alumno, imageSrc }
}

/**
 * Componente `TutoriaAlumnoData` para mostrar la información detallada del alumno asociado a una tutoría.
 * Presenta los datos principales del alumno y su foto de perfil, manejando casos donde la información
 * no está disponible mediante un componente de esqueleto.
 * 
 * @param {Object} tutoria - Objeto de tutoría que contiene información del alumno.
 * @returns {JSX.Element} - Estructura del componente con la información del alumno.
 */
export default function TutoriaAlumnoData({ tutoria }) {
	const { alumno, imageSrc } = useLogic(tutoria)

	return (
		<Grid container>
			{!tutoria ?
				<Skeleton />
				:
				(
					<React.Fragment>
						<Grid item xs={12} mb={5}>
							<H3>Alumno de la tutoría:</H3>
						</Grid>

						<Grid item xs={2}>
							<Avatar
								alt={"Foto de: " + (alumno?.nombre ?? "")}
								// src = {`${ASSETS.images + (alumno.foto_logo ? alumno.foto_logo : 'private/alumno/perfiles/0/default.png')}`}
								src={imageSrc}
								sx={{ width: "80%", height: "100%", borderRadius: 2 }} />
						</Grid>

						<Grid item xs={8}>
							<Box mt={2}>
								<H3>{alumno.nombre + " " + (alumno.apellido1 ?? "") + " " + (alumno.apellido2 ?? "")}</H3>

								<Box display={"flex"}>
									<Text fontWeight={"bold"}>Documento de identidad:</Text>
									<Text className="mx-2">{alumno.numero_documento + " - " + alumno.tipo_documento?.nombre}</Text>
								</Box>

								<Box display={"flex"}>
									<Text fontWeight={"bold"}>Género:</Text>
									<Text className="mx-2">{alumno.sexo?.valor}</Text>
								</Box>
							</Box>
						</Grid>
					</React.Fragment>
				)
			}
		</Grid>
	)
}
