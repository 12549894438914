

import React, { useEffect, useState } from 'react'
import { H3, Text } from 'components/texts'
import './style.css'
import { hours, morningHours, afternoonHours, days, types } from './constants'
import { Accordion } from 'components/data_display'
import { Stack } from '@mui/material'
import { DeleteHorarioModal } from './parts'

const ScheduleTable = ({ horarios = [], onDeleteClick = () => { }, type = "colegios", isSingle = false }) => {
    const [horariosToShow, setHorariosToShow] = useState([])
    const [deleteStatus,   setDeleteStatus]   = useState({open: false, eventToDelete: null})
    
    useEffect(() => {
        if(deleteStatus.open)
            setDeleteStatus({open: false, eventToDelete: deleteStatus.eventToDelete})
    }, [deleteStatus.open])

    useEffect(() => {
        const horariosToShow = types[type].formatter(horarios)
        setHorariosToShow(horariosToShow)
    }, [horarios])

    const calculateSteps = (horaInicial, horaFinal) => {
        let pasos = 0
        horaInicial = new Date(`1/1/2020 ${horaInicial}`)
        horaFinal = new Date(`1/1/2020 ${horaFinal}`)

        while (horaInicial < horaFinal) {
            pasos++
            horaInicial.setMinutes(horaInicial.getMinutes() + 15)
        }

        return pasos
    }


    return (
        <React.Fragment>
            <Stack direction={"column"} width={"100%"}>
                <Accordion header={<H3 className="my-0">Primera Jornada</H3>} mx={0}>
                    <table className='horarios-table'>
                        <thead>
                            <tr>
                                <th><H3 className={"m-0"}>Horas</H3></th>
                                {days.map(day => (
                                    <th key={day.value}><H3 className={"m-0"}>{day.label}</H3></th>
                                ))}
                            </tr>
                        </thead>

                        <tbody>

                            {morningHours.map((hour, i, array) => {
                                //Para que la ultima hora no se quede sola
                                if (i + 1 === array.length)
                                    return null

                                return (
                                    <tr key={hour}>
                                        <td><Text>{hour} {morningHours[i + 1] ? "- " + morningHours[i + 1] : ""}</Text></td>

                                        {days.map(day => {
                                            const event = horariosToShow.find(event => event.dia == day.value && hour >= event.hora_inicio && hour < event.hora_fin)
                                            if (event) {
                                                const first = hour == event.hora_inicio
                                                const Template = types[type].template
                                                return first ? 
                                                        (<Template 
                                                            key={day.value} 
                                                            day={day} 
                                                            event={event} 
                                                            calculateSteps={calculateSteps} 
                                                            onDeleteClick={(_, event) => setDeleteStatus({open: true, eventToDelete: event})} 
                                                            isSingle={isSingle}  />) : null
                                            }

                                            return <td key={day.value}></td>
                                        })}
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </Accordion>
                <Accordion header={<H3 className="my-0">Segunda Jornada</H3>} mx={0}>
                    <table className='horarios-table'>
                        <thead>
                            <tr>
                                <th><H3 className={"m-0"}>Horas</H3></th>
                                {days.map(day => (
                                    <th key={day.value}><H3 className={"m-0"}>{day.label}</H3></th>
                                ))}
                            </tr>
                        </thead>

                        <tbody>

                            {afternoonHours.map((hour, i, array) => {
                                //Para que la ultima hora no se quede sola
                                if (i + 1 === array.length)
                                    return null

                                return (
                                    <tr key={hour}>
                                        <td><Text>{hour} {afternoonHours[i + 1] ? "- " + afternoonHours[i + 1] : ""}</Text></td>

                                        {days.map(day => {
                                            const event = horariosToShow.find(event => event.dia == day.value && hour >= event.hora_inicio && hour < event.hora_fin)
                                            if (event) {
                                                const firstAfternoonHour = afternoonHours[0]
                                                const first = (hour == event.hora_inicio) || (event.hora_inicio < firstAfternoonHour && hour == firstAfternoonHour)
                                                const offset = first && event.hora_inicio < firstAfternoonHour ? calculateSteps(event.hora_inicio, firstAfternoonHour) : 0
                                                const Template = types[type].template
                                                return first ? 
                                                        (<Template 
                                                            key={day.value} 
                                                            day={day} 
                                                            event={event} 
                                                            calculateSteps={calculateSteps} 
                                                            onDeleteClick={(_, event) => setDeleteStatus({open: true, eventToDelete: event})} 
                                                            isSingle={isSingle} 
                                                            offset={offset} />) : null
                                            }

                                            return <td key={day.value}></td>
                                        })}
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </Accordion>
            </Stack>

            <DeleteHorarioModal deleteStatus={deleteStatus} onDeleteClick={onDeleteClick}/>
        </React.Fragment>
    )
}
export default ScheduleTable