


import React, { useState, useEffect } from "react";
import { Grid, Button, TextField } from "@mui/material";
// import useMediaQuery from "hooks/utils/useMediaQuery";
import { useMediaQuery } from "hooks";
import { COLOR } from "theme/Colors";

export default function GroupButtons({ labels, onChange = () => { }, searchable, disabled, propertyToShow = 'label', name = 'group-buttons', setValue = () => { } }) {
    // STATES
    const [buttonStates, setButtonStates] = useState(labels);
    // const [buttonStates, setButtonStates] = useState(labels.map(label => ({ ...label, isSelected: false }))

    const [filterValue, setFilterValue] = useState("");

    useEffect(() => {
        setButtonStates(labels);
    }, [labels]);

    // MEDIA QUERY
    const { componentSizes: { buttonSize } } = useMediaQuery();

    // FUNCTIONS
    const handleFilterChange = (event) => {
        const { value } = event.target;
        setFilterValue(value);
    };

    const handleClick = (index) => {
        const newButtonStates = [...buttonStates];
        newButtonStates[index] = { ...newButtonStates[index], isSelected: !newButtonStates[index].isSelected };
        setButtonStates(newButtonStates);
        onChange(newButtonStates[index]);
        setFilterValue("");

        setValue(name, newButtonStates.filter(item => item.isSelected == true));
    };

    const filteredButtons = filterValue === ""
        ? buttonStates
        : buttonStates.filter((button) =>
            button.label.toLowerCase().includes(filterValue.toLowerCase())
        );

    return (
        <Grid container direction={"row"}>

            {searchable && (
                <Grid item xs={12} mb={2}>
                    <TextField
                        type="text"
                        value={filterValue}
                        onChange={handleFilterChange}
                        placeholder="Filtrar botones..."
                        size="small"
                    />
                </Grid>
            )}
            <div style={{ display: "flex", flexWrap: "wrap" }}>
                {buttonStates.map((button, index) => (
                    <Grid item mr={2} mb={1} key={index}>
                        <Button
                            onClick={() => handleClick(index)}
                            variant={button.isSelected ? "contained" : "outlined"}
                            size={buttonSize}
                            fullWidth
                            disabled={disabled}
                            style={{
                                backgroundColor: button.isSelected ? COLOR.standard.main : "",
                                color: button.isSelected ? COLOR.white : "",
                                display: filteredButtons.includes(button) ? "block" : "none",
                            }}>
                            {button[propertyToShow]}
                        </Button>
                    </Grid>
                ))}
            </div>
        </Grid>
    );
}
