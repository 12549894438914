import { RequestHorarioColegio } from 'objects/requests'

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {
    
    /** 
     * Hace la petición para crear un horario
     * 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createHorario(getValues, callbackOk = () => {}, callbackError = () => {}, callbackFinally = () => {}) {
        const formData = new FormData()
        const valuesDias = getValues(['l', 'm', 'x', 'j', 'v', 's'])
        valuesDias.forEach((valor, indice) => {
            if (valor) 
                formData.append("dias[]", indice + 1)
        })

        //DescripcionHorario
        formData.append("curso_escolar_id",      getValues("id_curso_escolar"))
        formData.append("colegio_id",            getValues("id_colegio"))
        formData.append("registro_actividad_id", getValues("id_registro_actividad"))

        //DefinicionHorario
        formData.append("hora_inicio", getValues("hora_inicio"))
        formData.append("hora_fin",    getValues("hora_fin"))
        formData.append("clave",       getValues("clave").toUpperCase())
        formData.append("color",       getValues("color").toUpperCase())

        new RequestHorarioColegio().create(formData, callbackOk, callbackError, callbackFinally)
    }


    /**
     * Devuelve la tutoría
     * 
     * @param {Int}      id
     * @param {Function} callback 
     */
    getHorario(params, callback) {  
        new RequestHorarioColegio().search(params, (res) => {
            callback(res.items.data)
        }, () => {
            callback(null)
        })
    }


    /**
     * Hace la petición para borrar una de las definiciones del horario
     * 
     * @param {Int} id_horario
     */
    deleteHorario(id_horario, callbackOk, callbackError) {
        new RequestHorarioColegio().deleteDefinicionHorario(id_horario, {}, callbackOk, callbackError)
    }
}
