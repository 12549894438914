import { enqueueSnackbar } from 'notistack'
import { useCallback } from 'react'
import useAlert from './useAlert'

/**
 * Hook `useErrorHandler` para manejar errores globalmente en la aplicación.
 * Utiliza `enqueueSnackbar` para mostrar mensajes de error en la interfaz de usuario.
 * 
 * @returns {Function} - Una función `handleError` que acepta un error como argumento y muestra un mensaje de error adecuado.
 */
export default function useErrorHandler() {
    const updateAlert = useAlert()

    /**
     * Función `handleError` que procesa y muestra un mensaje de error.
     * Utiliza `useCallback` para memorizar la función y evitar recreaciones innecesarias.
     * 
     * @param {Error | Object | string} error - El error a manejar. Puede ser un objeto de error, un string o un objeto con información de error.
     */
    const handleError = useCallback((error) => {
        // Inicializa el mensaje de error con un valor predeterminado.
        let errorMessage = error ?? "Ha ocurrido un error inesperado."

        // Si `error` es un objeto, intenta extraer y concatenar los mensajes de error contenidos.
        if (error && typeof error === 'object')
            errorMessage = Object.values(error).map(e => Array.isArray(e) ? e.join(' ') : !Array.isArray(e) && !(error instanceof Error) ? e.message : e).join(' ')

        // Muestra el mensaje de error como una notificación emergente.
        updateAlert(errorMessage)
    }, [])

    // Retorna la función `handleError` para su uso en otros componentes.
    return handleError
}