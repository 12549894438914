import React from 'react'
import { Grid, Stack } from '@mui/material';
import { Input, DatePicker, Button } from "components/forms/components"
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { H3 } from 'components/texts';

/**
 * Formulario con los datos personales
 */
export default function DatosPersonalesDataForm({ register, errors, reset, setValue, getValue }) {
    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------
    const contratar = (item) => {
        console.log("contratar:", item);

    };


    return (
        <Grid container direction={'row'} px={5} mt={2} spacing={2} >

            <Grid container spacing={2}>

                <Grid item xs={2}  >
                    <Input label="Cod. Candidato" width={"100%"} name={"edad"} register={register} errors={errors} uppercase disabled />
                </Grid>

                <Grid item xs={2} >
                    <ItemsSelect name={"tipo_documento_id"} keyValue={"tipo_documento"} register={register} errors={errors} defaultValue={""} />
                </Grid>

                <Grid item xs={2} >
                    <Input label="Número de Documento" width={"100%"} name={"num_documento"} register={register} errors={errors} uppercase />
                </Grid>

            </Grid>

            <Grid container spacing={2} mt={2}>

                <Grid item xs={2}  >
                    <Input label="Nombre" width={"100%"} name={"nombre"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={2} >
                    <Input label="Apellido 1" width={"100%"} name={"apellido1"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={2} >
                    <Input label="Apellido 2" width={"100%"} name={"apellido2"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={1.5} >
                    <ItemsSelect name={"sexo_id"} keyValue={"sexo"} register={register} errors={errors} defaultValue={""} />
                </Grid>

            </Grid>

            <Grid container mt={2} spacing={2}>

                <Grid item xs={12} mr={0} >
                    <H3>Datos de Nacimiento</H3>
                </Grid>

                <Grid item xs={2} >
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha"} label={"Fecha"} defaultValue={""} clearable setValue={setValue} getValue={getValue} />
                </Grid>

                <Grid item xs={2} >
                    <Input label="Municipio" width={"100%"} name={"municipio"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={2}>
                    <ItemsSelect name={"provincia_id"} keyValue={"provincia"} register={register} errors={errors} wantToReset={reset} searcheable />
                </Grid>

                <Grid item xs={2}>
                    <Input label="País" width={"100%"} name={"pais"} register={register} errors={errors} uppercase />
                </Grid>

            </Grid>

            <Grid container mt={2} spacing={2}>

                <Grid item xs={12} >
                    <H3>Otros Datos</H3>
                </Grid>

                <Grid item xs={2}>
                    <Input label="C. Postal" width={"100%"} name={"c_postal"} register={register} errors={errors} uppercase />

                </Grid>

                <Grid item xs={2} >
                    <Input label="Municipio" width={"100%"} name={"municipio"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={2}>
                    <ItemsSelect name={"provincia_id"} keyValue={"provincia"} register={register} errors={errors} wantToReset={reset} searcheable />
                </Grid>

                <Grid item xs={2}>
                    <Input label="Dirección" width={"100%"} name={"direccion"} register={register} errors={errors} uppercase />
                </Grid>


                <Grid item xs={2}>
                    <Input label="Móvil" width={"100%"} name={"pais"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={2}>
                    <Input label="Teléfono" width={"100%"} name={"telefono"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={2}>
                    <Input label="Fax" width={"100%"} name={"fax"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={2}>
                    <Input label="Correo Electrónico" width={"100%"} name={"correo_principal"} register={register} errors={errors} uppercase />
                </Grid>

                <Grid item xs={2}>
                    <Input label="Correo Electrónico Adicional" width={"100%"} name={"correo_adicional"} register={register} errors={errors} uppercase />
                </Grid>

            </Grid>
            <Grid item container
                direction="column"
                alignItems="flex-end"
                justify="flex-end">

                <Button color="primary" onClick={contratar}>
                    Contratar
                </Button>
            </Grid>

        </Grid>
    )
}


