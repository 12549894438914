import React from 'react'
import { Box, Grid } from "@mui/material";
import { Datagrid } from 'components/data_display'
import moment from 'moment'

const columns = [
    {
        field: 'fecha',
        headerName: 'Fecha',
        width: 180,
        editable: true,
    },
    {
        field: 'cantidad',
        headerName: 'Cantidad',
        width: 100,
        editable: true,
    }
];

const cursoActual = moment().subtract(1, 'year').format('YYYY') + "-" + moment().format('YYYY')

export default function Anticipos({ empleado, register, errors, setValue, getValues, pagos }) {
    return (
        <Box width='100%' mt={4}>
            <Datagrid
                rows={pagos}
                columns={columns}
            />
        </Box>
    )
}