import { Component } from 'react'
import { RequestSeguimientoTrimestralGrupoColegio, RequestSeguimientoTrimestralInscripcion } from 'objects/requests'

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests extends Component {

    /**
     * Petición que realiza la creación de un nuevo seguimiento a una inscripcion
     * 
     * @param {Number}   inscripcion_id - La ID de la inscripción sobre la que se va a crear el seguimiento
     * @param {String}   groupType - El tipo de inscripcion necesario para realizar la peticion
     * @param {String}   trimestre - El tipo de inscripcion necesario para realizar la peticion
     * @param {Array}    getValues - Array con los valores del seguimiento
     * @param {Function} callbackOk - Función
     * @param {Function} callbackError 
     */
    createSeguimientoTrimestral(inscripcion_id, groupType, trimestre, getValues, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()
        formData.append("inscripcion_id", inscripcion_id)
        formData.append("inscripcion_type", groupType)
        formData.append("trimestre", trimestre)
        formData.append("comportamiento", getValues('comportamiento'))
        formData.append("comprension", getValues('comprension'))
        formData.append("expresion_oral", getValues('expresion_oral'))
        formData.append("compresion", getValues('compresion'))
        formData.append("expresion_escrita", getValues('expresion_escrita'))
        formData.append("nota_media", getValues('nota_media'))
        formData.append("evolucion_comentarios", getValues('evolucion_comentarios').toUpperCase())
        formData.append("materia_repasar", getValues('materia_repasar').toUpperCase())
        formData.append("comentarios", getValues('comentarios').toUpperCase())

        // new RequestSeguimientoTrimestralGrupoColegio().create(formData, callbackOk, callbackError)
        new RequestSeguimientoTrimestralInscripcion().create(formData, callbackOk, callbackError)
    }
}