import * as Yup from 'yup'
import moment from 'moment';

export const validation = {
    hora_inicio:    Yup.string() //.required('La hora de inicio es obligatoria')
                        .matches(/^([0-9]{1,2}):00$|^([0-9]{1,2}):30$/, {
                            message: 'La hora debe terminar en :00 o :30',
                            excludeEmptyString: true,
                        }),
                        // .test("min-07", "La hora de inicio no puede ser menor que 07:00", function (value) {
                        //     return moment(value, "HH:mm").isSameOrAfter(moment('07:00', "HH:mm"));
                        // }),

    hora_fin:       Yup.string() //.required('La hora de fin es obligatoria')
                        .matches(/^([0-9]{1,2}):00$|^([0-9]{1,2}):30$/, {
                            message: 'La hora debe terminar en :00 o :30',
                            excludeEmptyString: true,
                        }),
                        // .test("is-greater", "La hora de fin no puede ser menor que la hora de inicio", function (value) {
                        //     const { hora_inicio } = this.parent;
                        //     return moment(value, "HH:mm").isSameOrAfter(moment(hora_inicio, "HH:mm"));
                        // })
                        // .test("max-21", "La hora de fin no puede pasar las 21:00", function (value) {
                        //     return moment(value, "HH:mm").isSameOrBefore(moment("21:00", "HH:mm"));
                        // }),
}

export const validationSchema = Yup.object().shape(validation)