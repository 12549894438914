

import './style.css'
import React from 'react'
import { v4 as uuid } from 'uuid'
import { H3, Text } from 'components/texts'
import { TableColumn } from 'components/forms/components'
import { useLibrosPedidosSingleLogic } from 'hooks'

/**
 * El componente `LibrosDevueltos` muestra una lista de libros devueltos filtrados por curso escolar.
 * Proporciona detalles como la fecha de devolución y la cantidad de unidades devueltas.
 * 
 * Este template se usa en el single de libro
 * 
 * @param {Array} librosDevueltos - Un array que contiene los libros devueltos.
 * @param {String} cursoEscolar - El curso escolar seleccionado para filtrar los libros devueltos.
 * 
 * @returns {JSX.Element} - Un componente que muestra una tabla con los libros devueltos y el total de unidades devueltas.
 */
export default function LibrosPedidos({ librosPedidos, cursoEscolar }) {
    const { librosToRender, sumPedidas, sumLlegadas } = useLibrosPedidosSingleLogic(librosPedidos, cursoEscolar)

    return (
        <React.Fragment>
            <H3>Libros pedidos: </H3>
            <table className='libros-pedidos-table'>
                <thead>
                    <tr>
                        <th><Text fontWeight={"bold"}>Fecha</Text></th>
                        <th><Text fontWeight={"bold"}>Unidades pedidas</Text></th>
                        <th><Text fontWeight={"bold"}>Unidades llegadas</Text></th>
                    </tr>
                </thead>
                <tbody className='no-border'>
                    {librosToRender.map((item) => (
                        <tr key={uuid()}>
                            <TableColumn defaultValue={item.fecha} type={"date"} disabled />

                            <TableColumn defaultValue={item.cantidad_pedida} disabled />

                            <TableColumn defaultValue={item.cantidad_llegada} disabled />
                        </tr>
                    ))}

                    <tr className="total-unidades">
                        <td className='bg-white'><Text fontWeight="bold">Total unidades</Text></td>
                        <td><Text>{sumPedidas}</Text></td>
                        <td><Text>{sumLlegadas}</Text></td>
                    </tr>
                </tbody>
            </table>

        </React.Fragment>
    )
}