import React from 'react'
import { Line } from 'components/data_display'
import { Grid, Skeleton, Stack } from '@mui/material'

/**
 * Formulario con los datos de las programaciones de un libro
 */
export default function DataFormLibroProgramacionesSkeleton() {

  return (
    <Grid container px={5} pb={5}>
        <Grid container spacing={2} my={2}>
            <Grid item xs={12} xl={8}>
                <Stack>
                    <Skeleton variant="rounded" width={"100%"} height={30} className="mb-5"/>
                    <Skeleton variant="rounded" width={"100%"} height={50}/>
                </Stack>
            </Grid>

            <Grid item xs={12} xl={4} display={"flex"} alignItems={"end"}>
                <Stack direction={"row"} spacing={2} className={"w-100"}>
                    <Skeleton variant="rounded" width={"100%"} height={50}/>

                    <Skeleton variant="rounded" width={"100%"} height={50}/>
                </Stack>
            </Grid>
        </Grid>

        <Grid item xs={12} mt={2}>
            <Line />
        </Grid>

        <Grid container spacing={2} my={2}>
            <Grid item xs={12} xl={8}>
                <Stack>
                    <Skeleton variant="rounded" width={"100%"} height={30} className="mb-5"/>
                    <Skeleton variant="rounded" width={"100%"} height={50}/>
                </Stack>
            </Grid>

            <Grid item xs={12} xl={4} display={"flex"} alignItems={"end"}>
                <Stack direction={"row"} spacing={2} className={"w-100"}>
                    <Skeleton variant="rounded" width={"100%"} height={50}/>

                    <Skeleton variant="rounded" width={"100%"} height={50}/>
                </Stack>
            </Grid>
        </Grid>

        <Grid item xs={12} mt={2}>
            <Line />
        </Grid>

        <Grid container spacing={2} my={2}>
            <Grid item xs={12} xl={8}>
                <Stack>
                    <Skeleton variant="rounded" width={"100%"} height={30} className="mb-5"/>
                    <Skeleton variant="rounded" width={"100%"} height={50}/>
                </Stack>
            </Grid>

            <Grid item xs={12} xl={4} display={"flex"} alignItems={"end"}>
                <Stack direction={"row"} spacing={2} className={"w-100"}>
                    <Skeleton variant="rounded" width={"100%"} height={50}/>

                    <Skeleton variant="rounded" width={"100%"} height={50}/>
                </Stack>
            </Grid>
        </Grid>
  </Grid>
  )
}