import React, { useEffect } from 'react'
import { Container, Paper, Stack } from '@mui/material'
import { useParams } from 'react-router-dom'
import { AlumnoData, DomiciliacionData, ResponsableData } from './parts'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
import { useGetAlumno } from 'hooks'
import { SnackbarProvider } from 'notistack'

/**
 * Componente `SingleAlumnos` para visualizar la información detallada de un alumno individual.
 * Utiliza el hook `useGetAlumno` para obtener la información del alumno basándose en los parámetros de navegación,
 * permitiendo la visualización de datos personales, de domiciliación bancaria, y de responsables si es aplicable.
 * 
 * La página se estructura con una sección de encabezado que incluye breadcrumbs para la navegación y botones
 * para avanzar o retroceder entre registros de alumnos. Se hace uso de componentes de Material UI para 
 * organizar el contenido visualmente en un `Container` y `Paper`, y secciona la información con líneas horizontales
 * para una mejor distinción visual de las diferentes áreas de información del alumno.
 * 
 * @returns {JSX.Element} La estructura del componente incluye la visualización detallada de la información del alumno,
 * encapsulada en un diseño limpio y organizado que mejora la experiencia de usuario al interactuar con los datos.
 */
export default function SingleAlumnos() {
  const navigationParams = useParams()

  const { alumno, idAlumno, getAlumno, updateIdAlumno, updateAlumno } = useGetAlumno({ navigationParams })

  useEffect(getAlumno, [idAlumno])

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <Stack direction="row" justifyContent={"space-between"} marginX={8}>
        <BreadcrumbsList mx={0} breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../", label: "Alumnos" },
          { to: "./", label: "Alumno #" + (alumno ? alumno.id : "0000"), actualItem: true },
        ]} />

        <MoveButtons
          actualItemId={idAlumno}
          beforeFetchCallback={() => updateAlumno(null)}
          onUpdateActualItem={updateIdAlumno} />
      </Stack>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
        <AlumnoData alumno={alumno} />

        <Line className={"my-10"} />

        <ResponsableData responsables={alumno && alumno.responsables ? alumno.responsables : null} alumno={alumno} reload={getAlumno} />

        <Line className={"my-10"} />

        {alumno?.domiciliacion_bancaria ? <DomiciliacionData domiciliacionBancaria={alumno?.domiciliacion_bancaria} /> : null}
      </Paper>

      <SnackbarProvider autoHideDuration={3000} />
    </Container>
  )
}
