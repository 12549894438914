import { Grid } from '@mui/material'
import { Text } from 'components/texts'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Statics from 'objects/common/Statics'
import validationSchema from "./validation"
import { SearchButtons } from '../../components'
import { RequestEmpleado } from 'objects/requests'
import { Select } from 'components/forms/components'
import { yupResolver } from '@hookform/resolvers/yup'
import { itemsSearch, itemsOrder } from './constants'
import { EmpleadoTemplate, DomicilioTemplate } from './parts'

let prepareParamsGlobal = null

/**
 * Formulario de busqueda de alumnos 
 * 
 * @param {Object} 
 * @returns {JSX.Element}
 */
export default function SearchFormEmpleado({ onChangeSearchIndex, onLoadingCallback, searchCallback, options = {} }) {
    //Form
    const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm({ resolver: yupResolver(validationSchema) })

    //State
    const [resetFormState, setResetFormState] = React.useState(false)
    const [searchIndex, setSearchIndex] = React.useState(0)

    useEffect(() => {
        if (resetFormState == true) {
            resetForm(reset)
            setResetFormState(false)
        }
    })

    //-------------------------------------------------------
    //---------------------- FUNCTIONS ----------------------
    //-------------------------------------------------------

    /**
     * Controlla cuando se hace un cambio en el select de busqueda
     * 
     * @param {Object} event
     */
    const handleChange = (event) => {
        let index = event.target.value == "empleados" ? 0 : 1

        setSearchIndex(index)
        setResetFormState(true)

        if (onChangeSearchIndex)
            onChangeSearchIndex(index)
    }


    /**
     * Prepara los parametros que se van a usar
     * 
     * @param {Int} page
     */
    const prepareParams = (page) => {
        const params = Statics.objectPropsToUppercase(Statics.cleanObject(getValues()))
        params.appends = ['sexo', 'provincia', 'tipo_documento', 'tipo_via', 'user']
        params.page = page
        {/*Comentado 
        params.roles = getValues("roles").length > 0 ? getValues("roles").join(',') : ""
        */}
        if (options.contratoActivo)
            // params.contrato_activo = 1

            return params
    }

    prepareParamsGlobal = prepareParams

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    return (
        <Grid container p={2}>
            <Grid container>
                <Grid item xs={8} md={4}>
                    <Text fontWeight={'bold'} marginBottom={6}>Buscar por:</Text><br />
                    <Select width={"60%"} items={itemsSearch} defaultValue={"empleados"} onChange={handleChange} />
                </Grid>

                <Grid item xs={4} md={4}>
                    <Text fontWeight={'bold'} marginBottom={6}>Ordenar resultados por:</Text><br />
                    <Select width={"60%"} name={"order"} items={itemsOrder} defaultValue={itemsOrder[0].value} register={register} errors={errors} />
                </Grid>
            </Grid>

            {/* INICIO TEMPLATE */}
            <Grid container className={searchIndex == 0 ? "" : "d-none"}>
                <EmpleadoTemplate reset={resetFormState} register={register} errors={errors} options={options} />
            </Grid>

            <Grid container className={searchIndex == 1 ? "" : "d-none"}>
                <DomicilioTemplate reset={resetFormState} register={register} errors={errors} />
            </Grid>
            {/* FIN TEMPLATE */}

            {/* <RolTemplate register={register} /> LO QUITAMOS A PETICIÓN DE SAMUEL*/}

            <SearchButtons searchText={"Buscar empleados"} onResetClick={() => { setResetFormState(true) }} onSubmitClick={handleSubmit(() => { search(1, onLoadingCallback, searchCallback) })} />
        </Grid>
    )
}



/**
 * Hace la busqueda de alumnos
 * 
 * @param {Int}      page
 * @param {Function} onLoadingCallback
 * @param {Function} searchCallback
 * @param {Function} prepareParams
 */
export const search = (page = 1, onLoadingCallback, searchCallback) => {
    //Callback para controlar cuando esta cargando
    if (onLoadingCallback)
        onLoadingCallback(true)

    const params = prepareParamsGlobal(page)
    new RequestEmpleado().search(params, (res) => {
        if (searchCallback)
            searchCallback(res.empleados.data, res.empleados.total, page, params)
    }, () => {
        searchCallback([], 0)
    })
}


/**
 * Resetea todo el formulario
 * 
 * @param {Function} reset
 */
const resetForm = (reset) => {
    reset({
        nombre: "",
        apellido1: "",
        apellido2: "",
        email: "",
        numero_documento: "",
        direccion: "",
        municipio: "",
        cod_postal: "",

    })
}