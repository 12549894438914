import RequestMaster from '../RequestMaster'

/**
 * Clase para manejar peticiones relacionadas con la devolución de libros.
 * Extiende de la clase RequestMaster para utilizar sus métodos y propiedades.
 */
export default class RequestDevolucionLibro extends RequestMaster {

    /**
     * Constructor de la clase.
     * Inicializa la clase y establece la URL base para las peticiones relacionadas con devoluciones de libros.
     */
    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/libro/devolucion'
    }

    /**
     * Método para crear una nueva devolución de libros.
     * Envía una petición POST para crear una devolución con los datos especificados.
     *
     * @param {FormData} params - Parámetros en formato FormData que contienen los datos de la devolución.
     * @param {Function} callback - Función de callback para manejar una respuesta exitosa (opcional).
     * @param {Function} callbackError - Función de callback para manejar errores (opcional).
     * @param {Function} callbackFinally - Función de callback que se ejecuta al finalizar la petición, haya o no error (opcional).
     */
    create(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = ""
        this.post(path, params, callback, callbackError, callbackFinally)
    }


    /**
     * Método para editar múltiples devoluciones de libros.
     * Envía una petición PUT para actualizar las devoluciones con los datos especificados.
     *
     * @param {FormData} params - Parámetros en formato FormData con los datos a actualizar.
     * @param {Function} callback - Función de callback para manejar una respuesta exitosa (opcional).
     * @param {Function} callbackError - Función de callback para manejar errores (opcional).
     * @param {Function} callbackFinally - Función de callback que se ejecuta al finalizar la petición, haya o no error (opcional).
     */
    edit(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "update"
        this.put(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Método para eliminar múltiples devoluciones de libros.
     * Envía una petición DELETE para eliminar las devoluciones especificadas.
     *
     * @param {FormData} params - Parámetros en formato FormData que contienen los datos de las devoluciones a eliminar.
     * @param {Function} callback - Función de callback para manejar una respuesta exitosa (opcional).
     * @param {Function} callbackError - Función de callback para manejar errores (opcional).
     * @param {Function} callbackFinally - Función de callback que se ejecuta al finalizar la petición, haya o no error (opcional).
     */
    deleteMultiple(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "destroy-multiple"
        this.delete(path, params, callback, callbackError, callbackFinally)
    }

    /**
     * Realiza una solicitud POST al endpoint "generate" para generar las devoluciones de los libros que tengan unidades en stock.
     *
     * @param {function} callback - Función de devolución de llamada para manejar la respuesta exitosa.
     * @param {function} callbackError - Función de devolución de llamada para manejar errores.
     * @param {function} callbackFinally - Función de devolución de llamada que se ejecuta al final, independientemente del resultado.
     */
    generate(callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "generate"
        this.post(path, new FormData(), callback, callbackError, callbackFinally)
    }
}
