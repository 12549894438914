import { useEffect, useState } from "react"

/**
 * Hook personalizado para gestionar el estado de habilitación de los botones de 'Ver Programación'.
 * Este hook recibe un objeto 'libro' que representa la disponibilidad de la programación académica
 * por trimestres. Cada botón corresponde a un trimestre académico específico.
 *
 * @param {Object} libro - Objeto con las propiedades:
 *   - programacion_1t: boolean que indica si la programación del primer trimestre está disponible.
 *   - programacion_2t: boolean que indica si la programación del segundo trimestre está disponible.
 *   - programacion_3t: boolean que indica si la programación del tercer trimestre está disponible.
 * Si 'libro' es nulo, se asume que no hay datos disponibles y todos los botones se deshabilitan.
 *
 * @returns {Object} Objeto con tres estados booleanos que representan la habilitación de cada botón:
 *   - disabled1T: Estado de habilitación del botón del primer trimestre.
 *   - disabled2T: Estado de habilitación del botón del segundo trimestre.
 *   - disabled3T: Estado de habilitación del botón del tercer trimestre.
 */
export default function useHandleDisabled(libro) {
    // Estados para controlar si los botones deben estar deshabilitados
    const [disabled1T, setDisabled1T] = useState(false)
    const [disabled2T, setDisabled2T] = useState(false)
    const [disabled3T, setDisabled3T] = useState(false)

    useEffect(() => {
        if (!libro) {
            // Si no hay libro, todos los botones deben estar deshabilitados
            setDisabled1T(true)
            setDisabled2T(true)
            setDisabled3T(true)
        } else {
            // Comprobar cada trimestre individualmente
            setDisabled1T(!libro.programacion_1t)
            setDisabled2T(!libro.programacion_2t)
            setDisabled3T(!libro.programacion_3t)
        }
    }, [libro])

    return { disabled1T, disabled2T, disabled3T }
}