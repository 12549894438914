import {
    CandidatosTemplate,
    CandidatosDomicilioTemplate,
    CandidatosActividadesTemplate,
    CandidatosIdiomasTemplate
} from "./parts"

export const itemsSearch = [
    { value: 0, columnKey: "alumnos", label: "Candidatos", template: CandidatosTemplate },
    { value: 1, columnKey: "alumnos", label: "Candidatos por Domicilio", template: CandidatosDomicilioTemplate },
    { value: 2, columnKey: "alumnos", label: "Candidatos por Actividades", template: CandidatosActividadesTemplate },
    { value: 3, columnKey: "alumnos", label: "Candidatos Idiomas", template: CandidatosIdiomasTemplate },
]

export const itemsOrder  = [
    { value: '{"id": "ASC", "nombre": "ASC"}', label: "Nombre" },
    { value: '{"id": "ASC", "documento": "ASC"}', label: "Documento (Tipo, Número)" },
]
