import React, { useRef, useState, useEffect, useContext } from 'react'
import { Grid, Box, Tab, Tabs, Container, Paper, Stack } from "@mui/material";
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { EditHeader } from 'pages/logged/admin/components'
import { BreadcrumbsList, MoveButtons } from 'components/data_display'
import { COLOR } from 'theme/Colors.js'
import { Text } from 'components/texts/index.js'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { TabContext } from '@mui/lab'
import Requests from './Requests/Requests'
import RequestsCreate from '../create/Request/Request'
import {
    DatosEmpleadoDataForm, DataCurriculumEmpleado, ListadosDataForm,
    DatosContratosForm, PagosDataForm, AsistenciaYextras
} from "components/forms/display";
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import SkeletonDatosEmpleado from 'components/forms/display/ficha-empleado/DataForm/DatosPersonalesDataForm/skeleton';
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { RequestEmpleado } from 'objects/requests'
import { CursoEscolarProvider, CursoEscolarContext  } from 'contexts/CursoEscolarContext';

import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from 'components/forms/display/ficha-empleado/validation'
import { ChangesContext } from 'contexts/ChangesContext';
import { useErrorHandler } from 'hooks';

export default function Wrapper() {
    const navigationParams = useParams()

    //Contexts
    const { items, updateItem } = useContext(EmpleadoListContext)

    const [empleado, setEmpleado] = useState()
    const [contratoEmpleado, setContratoEmpleado] = useState(null)
    const [curriculum, setCurriculum] = useState(null)
    const [id_empleado, setIdEmpleado] = useState(navigationParams.id_empleado)
    const [partido, setPartido] = useState(null)
    const [horasExtra, setHorasExtra] = useState(null)
    const [sustituciones, setSustituciones] = useState(null)
    //contexto de CursoEscolar
    const { cursoEscolar, setCursoEscolar } = useContext(CursoEscolarContext);


    //Requests ----

    const getEmpleado = (getFromServer = false) => {
        const empleado = items.find(item => item.id == id_empleado)
        if (items.length == 0 || !empleado || getFromServer) {
            new Requests().getEmpleado(id_empleado, (empleado) => {
                setEmpleado(empleado)
                updateItem(empleado)
                if (empleado?.curriculum) {
                    setCurriculum(empleado?.curriculum)
                }
            }, () => enqueueSnackbar(<Text>No se ha podido obtener el empleado.</Text>, { variant: "error" }))
        } else {
            setEmpleado(empleado)
            // setCurriculum(empleado?.curriculum)
        }
    }

    const getContratoEmpleado = () => {
        new Requests().getContratoEmpleado(id_empleado, (contratoEmpleado) => {
            setContratoEmpleado(contratoEmpleado)
        }, () => console.log('error'))
    }

    const getPartidos = () => {
        let params = {
            // curso_escolar_id: 18,
            empleado_id: id_empleado
        };
        new Requests().getPartido(params, {}, (partido) => {
            setPartido(partido)
        }, () => console.error('error'))
    }

    const getHorasExtra = () => {
        let params = {
            // curso_escolar_id: cursoEscolar,
            empleado_id: id_empleado
        };
        new Requests().getHorasExtra(params, {}, (horasExtra) => {
            setHorasExtra(horasExtra)
        }, () => (console.error('error')))
    }

    const getSustitucion = () => {
        let params = {
            // curso_escolar_id: 30,
            empleado_id: id_empleado
        };
        new Requests().getSustitucion(params, {}, (sustituciones) => {
            setSustituciones(sustituciones)
        }, () => (console.error('error')))
    }

    useEffect(() => {
        getEmpleado()
        getContratoEmpleado()
        getPartidos()
        getHorasExtra()
        getSustitucion()
    }, [id_empleado]);

    return (
        <React.Fragment>
            <CursoEscolarProvider>
                <EditEmpleado
                    key={empleado?.id ?? 0}
                    empleado={empleado}
                    // curriculum={curriculum}
                    id_empleado={id_empleado}
                    contratoEmpleado={contratoEmpleado}
                    setIdEmpleado={setIdEmpleado}
                    setEmpleado={setEmpleado}
                    partido={partido}
                    // isSingle={isSingle}
                    horasExtra={horasExtra}
                    sustituciones={sustituciones}
                onSaveFinish={() => getEmpleado(true)} />
            </CursoEscolarProvider>
            <SnackbarProvider autoHideDuration={3000} />
        </React.Fragment>
    )
}

function EditEmpleado({ empleado, id_empleado, setIdEmpleado, setEmpleado, contratoEmpleado, onSaveFinish, partido, horasExtra, sustituciones, isSingle = false }) {
    const [tab, setTab] = useState("1")
    const { cursoEscolar, setCursoEscolar } = useContext(CursoEscolarContext);

    const [gruposEmpleadoColegio, setGruposEmpleadoColegio] = useState(null)
    const [gruposEmpleadoAcademia, setGruposEmpleadoAcademia] = useState(null)
    const [gruposEmpleadoClasesParticulares, setGruposEmpleadoClasesParticulares] = useState(null)
    const [pagos, setPagos] = useState(null)

    const handleError = useErrorHandler()
    const { updateChanges } = useContext(ChangesContext)

    const headerRef = useRef()
    const { register, handleSubmit, formState: { errors }, reset, getValues, setValue, unregister } = useForm({ resolver: yupResolver(validationSchema) })
    const navigate = useNavigate()

    const updateHeaderAndAlert = ({ message, variant = "error" }) => {
        enqueueSnackbar(<Text>{message}</Text>, { variant })
    }
    const updateId = (id) => {
        navigate(`../ficha-empleados/${id}/editar`);
        setIdEmpleado(id);
    }

    const editEmpleado = (values) => {

        const params = { id_empleado: id_empleado, cursoEscolar: cursoEscolar }

        new Requests().editEmpleado(params, getValues, () => {
            updateHeaderAndAlert({ message: "Se ha actualizado el empleado.", variant: "success" })
            reset({}, { keepValues: true })
            updateChanges(false)

            if (values.partido)
                editPartidos(values)

            if (values.horasExtra)
                editHorasExtra(values.horasExtra)

            if (!empleado?.curriculum?.id) {
                createCurriculum(values, getValues, id_empleado)
            } else {
                editCurriculum(empleado?.curriculum?.id, id_empleado, values)
            }

            if (values.sustituciones)
                editSustituciones(values)


            onSaveFinish()
        }, (error) => handleError(error))

    }

    const editSustituciones = (values) => {
        new Requests().editSustituciones(id_empleado, cursoEscolar, values.sustituciones, (res) => {
            updateHeaderAndAlert({ message: "Sustituciones actualizadas con éxito.", variant: "success" })
        }, (error) => {
            handleError(error)
        })
    }

    const editCurriculum = (curriculumId, empleadoId, values) => {
        new Requests().editCurriculum(curriculumId, empleadoId, values, getValues, (res) => {
            // updateHeaderAndAlert({ message: "Currículum actualizado con éxito.", variant: "success" })
            editDetallesCurriculum(curriculumId)
        }, (error) => {
            handleError(error)
        })
    }

    const editDetallesCurriculum = (curriculumId) => {
        new Requests().editDetallesCurriculum(curriculumId, getValues, (res) => {
            // updateHeaderAndAlert({ message: "Detalles del currículum actualizados con éxito.", variant: "success" })
        }, (error) => {
            handleError(error)
        })
    }

    const createCurriculum = (values, getValues, empleadoId) => {
        if (!getValues('entrega_curriculum'))
            return

        new RequestsCreate().createCurriculum(empleadoId, values, getValues, (res) => {
            const curriculumId = res?.id
            // enqueueSnackbar(<Text>Currículum creado con éxito.</Text>, { variant: 'success' })
            createDatosCurriculum(curriculumId)
        }, (error) => {
            headerRef.current.button.setLoading(false)
            handleError(error)
        })
    }

    const createDatosCurriculum = (curriculumId) => {
        if (!getValues('datos_curriculum').length > 0)
            return

        new RequestsCreate().createCurriculumDetalle(curriculumId, getValues, (res) => {
            // enqueueSnackbar(<Text>Datos del currículum creados con éxito.</Text>, { variant: 'success' })
        }, (error) => {
            headerRef.current.button.setLoading(false)
            handleError(error)
        })
    }

    const editHorasExtra = (horasExtra) => {
        new Requests().editHorasExtra(id_empleado, cursoEscolar, horasExtra, (res) => {
            // updateHeaderAndAlert({ message: "Horas extras actualizadas con éxito.", variant: "success" })
        }, (error) => handleError(error))
    }

    const editPartidos = (values) => {
        new Requests().editPartidos(id_empleado, cursoEscolar, values.partido, (res) => {
            // updateHeaderAndAlert({ message: "Se han actualizado los partidos del empleado.", variant: "success" })
        }, (error) => handleError(error))
    }

    //Obtiene los pagos del empleado
    const getPagosEmpleado = () => {
        let params = {
            curso_escolar_id: cursoEscolar,
            empleado_id: id_empleado
        };
        new Requests().getPagosEmpleado(params, {}, (pagos) => {
            setPagos(pagos)
        }, () => (console.error('error')))
    }

    //Obtiene grupos del empleado
    const getGruposEmpleado = () => {
        if (cursoEscolar != null && cursoEscolar != undefined) {
            setGruposEmpleadoColegio({})
            setGruposEmpleadoAcademia({})
            setGruposEmpleadoClasesParticulares({})
            let params = {
                appends: {
                    grupo_academia: ['horarios', 'profesor_actual'],
                    grupo_colegio: ['horario', 'profesor_actual'],
                    clase_particular: ['horarios', 'profesor', 'alumno'],
                },
                curso_escolar_id: cursoEscolar
            };
            new RequestEmpleado().getGruposEmpleado(id_empleado, params, (gruposEmpleado) => {
                setEmpleado(empleado);
                if (gruposEmpleado['colegio'] && gruposEmpleado) {
                    setGruposEmpleadoColegio(gruposEmpleado['colegio'])
                }
                if (gruposEmpleado['academia'] && gruposEmpleado) {
                    setGruposEmpleadoAcademia(gruposEmpleado['academia'])
                }
                if (gruposEmpleado['clase-particular'] && gruposEmpleado) {
                    setGruposEmpleadoClasesParticulares(gruposEmpleado['clase-particular'])
                }
            }, () => console.error('error'));
        } else {
            return;
        }
    }

    useEffect(() => {
        getGruposEmpleado()
        getPagosEmpleado()
    }, [id_empleado, cursoEscolar])


    return (
        <Grid container p={3}>
            <Container maxWidth={false} sx={{ mt: 5 }}>
                <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(editEmpleado)}>
                    <Stack direction="row" justifyContent={"space-between"} marginX={8}>
                        <BreadcrumbsList mx={0} breadcrumbsList={[
                            { to: "/", label: "Inicio" },
                            { to: "./../", label: "Empleado" },
                            { to: "./", label: "Empleado #" + (id_empleado ?? "0000"), actualItem: true },
                        ]} />

                        <MoveButtons
                            actualItemId={id_empleado}
                            beforeFetchCallback={() => setEmpleado(null)}
                            onUpdateActualItem={updateId} />

                    </Stack>

                    <Paper elevation={2} sx={{ m: 2, mx: 8, my: 2, pb: 5 }}>
                        <Grid container display={"flex"} justifyContent={"end"} alignItems={"start"}>
                            <EditHeader
                                ref={headerRef}
                                title={"Editar Empleado"}
                                item={empleado}
                                btnText={"Guardar cambios"} />
                        </Grid>

                        <TabContext value={tab}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
                                <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                                    <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>DATOS PERSONALES</Text>} value={"1"} />
                                    <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>CURRICULUM VITAE</Text>} value={"2"} />
                                    <Tab label={<Text fontWeight={tab === "3" ? "bold" : null} color={tab === "3" ? COLOR.standard.main : null}>LISTADOS</Text>} value={"3"} />
                                    <Tab label={<Text fontWeight={tab === "4" ? "bold" : null} color={tab === "4" ? COLOR.standard.main : null}>DATOS CONTRATOS</Text>} value={"4"} />
                                    <Tab label={<Text fontWeight={tab === "5" ? "bold" : null} color={tab === "5" ? COLOR.standard.main : null}>PAGOS</Text>} value={"5"} />
                                    <Tab label={<Text fontWeight={tab === "6" ? "bold" : null} color={tab === "6" ? COLOR.standard.main : null}>ASISTENCIA Y EXTRAS</Text>} value={"6"} />
                                </Tabs>
                            </Box>

                            <TabPanel value={"1"}>
                                {empleado ?
                                    <DatosEmpleadoDataForm setValue={setValue} getValues={getValues} empleado={empleado} register={register} errors={errors} /> : <SkeletonDatosEmpleado />}
                            </TabPanel>

                            <TabPanel value={"2"}>
                                {empleado ?
                                    <DataCurriculumEmpleado empleado={empleado} getValues={getValues} register={register} errors={errors} setValue={setValue} /> : <SkeletonDatosEmpleado />}
                            </TabPanel>

                            <TabPanel value={"3"}>
                                <ListadosDataForm register={register} errors={errors} />
                            </TabPanel>

                            <TabPanel value={"4"}>
                                <DatosContratosForm gruposEmpleadoColegio={gruposEmpleadoColegio} gruposEmpleadoAcademia={gruposEmpleadoAcademia}
                                    gruposEmpleadoClasesParticulares={gruposEmpleadoClasesParticulares}
                                     getValues={getValues} register={register} errors={errors} contratoEmpleado={contratoEmpleado}
                                    setValue={setValue} onChangeCursoEscolar={setCursoEscolar} />
                            </TabPanel>

                            <TabPanel value={"5"}>
                                <PagosDataForm pagos={pagos} getValues={getValues} register={register} errors={errors} setValue={setValue} />
                            </TabPanel>

                            <TabPanel value={"6"}>
                                <AsistenciaYextras partido={partido} sustituciones={sustituciones} onChangeCursoEscolar={setCursoEscolar} unregister={unregister}
                                     horasExtra={horasExtra}
                                    getValues={getValues} register={register} errors={errors} setValue={setValue} />
                            </TabPanel>

                        </TabContext>
                    </Paper>
                </form>
            </Container>
            <SnackbarProvider autoHideDuration={3000} />
        </Grid >
    )
}
