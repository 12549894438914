import React, { Component } from 'react'
import { MenuItem } from 'components/navigation'
import { employeeRoutes } from 'router/routes'
import { 
    School       as SchoolIcon,
    MapsHomeWork as ClaseParticularIcon,
    MenuBook     as LibrosIcon,
    ManageSearch as SeguimientoDelCursoIcon,
    Badge        as EmpleadoIcon,
    Apartment    as AcademiaIcon,
    AttachEmail  as ComunicacionesIcon,
    Mood         as AlumnosIcon,
} from '@mui/icons-material'

export default class AlumnoMenu extends Component {

    /**
     * ComponentDidMount
     * 
     * Inicializamos los componentes necesarios del tema
     */
    componentDidMount() {
        window.KTMenu?.createInstances()
        window.KTToggle?.createInstances()
    }


	/**
	 * Render
	 * 
	 * @returns {Component}
	 */
	render() {
		return (
            <div className="menu menu-column menu-rounded menu-sub-indention px-3" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">
                <div className="menu-item pt-5">
                    <div className="menu-content">
                        <span className="menu-heading fw-bold text-uppercase fs-7">Menú principal</span>
                    </div>
                </div>

                <MenuItem title="Alumnos"               to={employeeRoutes.LISTADO_ALUMNOS}                icon={<AlumnosIcon />}/>
                <MenuItem title="Academia"              to={employeeRoutes.ACADEMIA_LISTADO_INSCRIPCIONES} icon={<AcademiaIcon />}/>
                <MenuItem title="Colegios"              to={employeeRoutes.COLEGIO_LISTADO_INSCRIPCIONES}  icon={<SchoolIcon />}/>
                <MenuItem title="Empleados"             to={employeeRoutes.LISTADO_EMPLEADOS}              icon={<EmpleadoIcon />}/>
                <MenuItem title="Seguimiento del curso" to={employeeRoutes.SEGUIMIENTO_CURSO}              icon={<SeguimientoDelCursoIcon />}/>
                <MenuItem title="Libros"                to={employeeRoutes.LISTADO_LIBROS}                 icon={<LibrosIcon />}/>
                <MenuItem title="Clases particulares"   to={employeeRoutes.CLASES_PARTICULARES}            icon={<ClaseParticularIcon />}/>
            </div>
		)
	}
}
