import React, { useEffect, useRef, useState } from 'react'
import { H3, Text } from 'components/texts'
import { ASSETS } from 'constants/Assets'
import Statics from 'objects/common/Statics'
import { Line } from 'components/data_display'
import { Grid, Skeleton } from '@mui/material'
import { EmpleadoPicker } from 'components/forms/components/custom'
import { DatePicker, Input } from 'components/forms/components'
import { ItemsSelect, CursoEscolarSelect } from 'components/forms/components/Select/custom'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { useItemsSelectDataReload } from 'components/forms/components/Select/custom/ItemsSelect'


/**
 * Formulario con los datos generales del grupo-academia
 */
export default function GeneralGrupoAcademiaDataForm({ grupo, register, errors, setValue }) {

    const [actividad, setActividad] = useState(grupo?.registro_actividad_id ?? null)
    const [numGrupo, setNumGrupo] = useState(grupo?.num_grupo ?? "")
    const [firstLoop, setFirstLoop] = useState(true)
    const [opcionSeleccionada, setOpcionSeleccionada] = useState(grupo?.nivel_grupo?.[0]?.edad ?? 'adulto')

    const claveInput = useRef()
    useEffect(() => {
        let clave = "CL"
        if (firstLoop) {
            setFirstLoop(false)
            clave = grupo?.clave_grupo ?? ""
        } else {
            if (actividad && actividad.nombre) {
                const label = Statics.removeWhiteSpaces(actividad.nombre)
                clave += label.length >= 5 ? label.substring(0, 5) : label
            }
            clave += numGrupo
        }

        claveInput.current.setValue(clave)
        setValue("clave", clave)
    }, [actividad, numGrupo])

    /**
     * Para que todos los datos estén sincronizados, se hace un reload.
     * Así, cuando se cree, por ejemplo, una actividad, y luego vas a crear o editar un grupo, esta actividad recién creada se mostrará adecuadamente.
     * Sin hacer este reload, al crear una actividad, no se mostraba en el ItemsSelect de actividad en crear o editar grupo.
     * El hook useItemsSelectDataReload se encuentra en el componente ItemsSelect.
     */
    const { reloadKey, reload } = useItemsSelectDataReload()
    useEffect(() => reload(), [])

    return (
        <Grid container px={5}>

            <Grid container spacing={1} mt={2} justifyContent={'flex-end'}>
                {grupo ?
                    <Grid item xs={2} mb={2}  >
                        <Input disabled label="Edad" register={register} errors={errors} className="w-100" name={"edad"}
                            defaultValue={grupo.edad_grupo ?? null} uppercase useValue />
                    </Grid> : null}

                <Grid item xs={12}>
                    <Grid item container spacing={1} justifyContent="space-between">

                        <Grid item xs={2}>
                            <Input ref={claveInput} label="Clave del grupo" register={register} errors={errors} className="w-100" name={"clave"}
                                defaultValue={grupo?.clave ?? "CL"} uppercase useValue />
                        </Grid>

                        <Grid item xs={4}>
                            <CursoEscolarSelect
                                label={"Curso escolar"}
                                name={"curso_escolar_id"}
                                register={register}
                                errors={errors}
                                defaultValue={grupo?.curso_escolar_id ?? null}
                                setValue={setValue}
                                searcheable />
                        </Grid>

                        <Grid item xs={4}>
                            <ItemsSelect
                                register={register}
                                errors={errors}
                                name={"registro_actividad_id"}
                                keyValue={"registro_actividad_academia"}
                                onChange={(_, actividad) => setActividad(actividad)}
                                defaultValue={grupo?.registro_actividad_id ?? ""}
                                reloadKey={reloadKey}
                                searcheable />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container mt={4}>
                <Line />
            </Grid>

            <Grid container mt={4} alignItems="center">

                <Grid item xs={2} >
                    <FormControl>
                        <FormLabel id="nivel"><Text fontWeight={"bold"}>Nivel: </Text></FormLabel>
                        <RadioGroup onChange={({ target }) => setOpcionSeleccionada(target.value)} value={opcionSeleccionada} row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                            <FormControlLabel value="niño" control={<Radio />} label="Niños" />
                            <FormControlLabel value="adulto" control={<Radio />} label="Adultos" />
                        </RadioGroup>
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <ItemsSelect
                        key={opcionSeleccionada}
                        register={register}
                        errors={errors}
                        name={"nivel_grupo_id"}
                        keyValue={opcionSeleccionada === 'adulto' ? "nivel_grupo_adulto" : "nivel_grupo_niño"}
                        defaultValue={grupo?.nivel_grupo_id ?? ""} />
                </Grid>

            </Grid>

            <Grid container mt={4}>
                <Line />
            </Grid>

            <Grid container spacing={2} mt={1}>
                <Grid item xs={2}>
                    <Input label="Nº Grupo" register={register} errors={errors} className="w-100" name={"num_grupo"} defaultValue={grupo?.num_grupo ?? ""}
                        onInput={({ target }) => setNumGrupo(target.value)} />
                </Grid>

                <Grid item xs={3}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_inicio"} label={"Fecha inicio"} defaultValue={grupo?.fecha_inicio}
                        setValue={setValue} />
                </Grid>

                <Grid item xs={3}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_fin"} label={"Fecha fin"} defaultValue={grupo?.fecha_fin ?? null}
                        setValue={setValue} disabled />
                </Grid>

                <Grid item xs={2}>
                    <Input label="Min Alumnos" register={register} errors={errors} className="w-100" name={"min_alumnos"} defaultValue={grupo?.min_alumnos ?? ""} />
                </Grid>

                <Grid item xs={2}>
                    <Input label="Max Alumnos" register={register} errors={errors} className="w-100" name={"max_alumnos"} defaultValue={grupo?.max_alumnos ?? ""} />
                </Grid>
            </Grid>

            <Grid container mt={4}>
                <Line />
            </Grid>

            <Grid container>
                <Grid item xs={6} p={3}>
                    <EmpleadoPicker
                        label={"Profesor del grupo"}
                        editLabel={"Cambiar profesor"}
                        addLabel={"Añadir profesor"}
                        deleteLabel={"Borrar profesor"}
                        register={register}
                        name={'empleado_id'}
                        empleado={grupo?.profesor_actual?.empleado ?? null}
                        setValue={setValue}
                        //Si tiene más de un profesor ese grupo, ya no se podrá cambiar desde aqui
                        canEdit={grupo && grupo?.profesores > 1 ? false : true}
                    />
                </Grid>

                <Grid item xs={6} style={{ borderLeft: "1px solid #D9D9D9" }} p={3}>
                    <H3>Observaciones del grupo:</H3>
                    <Input placeholder={"Escribe aquí alguna observación..."} register={register} errors={errors}
                        className="w-100" name={"observaciones_grupo"} defaultValue={grupo?.observaciones_grupo ?? null} multiline minRows={6} maxRows={10} uppercase />
                </Grid>
            </Grid>
        </Grid>
    )
}
