import { SearchButtons } from 'components/forms/display/components';
import { RequestClasesParticulares } from 'objects/requests'
import { yupResolver } from '@hookform/resolvers/yup'
import { itemsSearch } from './searchAndOrderOptions'
import { Select } from 'components/forms/components'
import Statics from 'objects/common/Statics';
import validationSchema from "./validation"
import { useForm } from "react-hook-form";
import { Text } from "components/texts";
import React, { useEffect } from 'react'
import { Grid } from "@mui/material";

let prepareParamsGlobal = null

export default function SearchFormClasesParticulares({ searchCallback, onChangeSearchIndex, onLoadingCallback }) {
    //Form
    const { register, handleSubmit, formState: { errors }, getValues, setValue, reset } = useForm({ resolver: yupResolver(validationSchema) })

    //State
    const [resetFormState,  setResetFormState]  = React.useState(false);
    const [searchIndex,     setSearchIndex]     = React.useState(0)
    const [itemsOrder,      setItemsOrder]      = React.useState(itemsSearch[searchIndex].itemsOrder)

    useEffect(() => {
        if (resetFormState == true) {
            resetForm(reset)
            setResetFormState(false)
        }
    })

    //-------------------------------------------------------
    //---------------------- FUNCTIONS ----------------------
    //-------------------------------------------------------

    /**
     * Controlla cuando se hace un cambio en el select de busqueda
     * 
     * @param {Object} event
     */
    const handleChange = (event) => {
        const index = event.target.value
        setSearchIndex(index)
        setResetFormState(true)
        setItemsOrder(itemsSearch[index].itemsOrder)

        if (onChangeSearchIndex)
            onChangeSearchIndex(index, itemsSearch[index].columnKey)
    }

    /**
     * Prepara los parámetros que se van a usar
     * 
     * @param {Int} page
     */
    const prepareParams = (page) => {
        const params = Statics.objectPropsToUppercase(Statics.cleanObject(getValues()))
        if(searchIndex === 2) params.pendiente = 1
        // params.appends = ['venta_libro', 'venta_libro_asociado', 'empresa', 'empleado', 'registro_actividad', 'alumno', 'horarios', 'lugar_clase', 'profesor', 'forma_conocimiento', 'forma_recogida', 'libro', 'origen_profesor', 'sexo', 'pagos', 'curso_escolar']
        params.appends = ['empresa', 'empleado', 'registro_actividad', 'alumno', 'horarios', 'lugar_clase', 'profesor', 'forma_conocimiento', 'forma_recogida', 'libro', 'origen_profesor', 'sexo', 'pagos', 'curso_escolar']
        // params.appends = ['empleado', 'alumno']

        params.page = page
        
        return params
    }

    prepareParamsGlobal = prepareParams

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    const ActualTemplate = itemsSearch[searchIndex].template
    return (
        <Grid container p={2}>
            <Grid container>
                <Grid item xs={8} md={4}>
                    <Text fontWeight={'bold'} marginBottom={6}>Buscar por:</Text><br />
                    <Select width={"60%"} items={itemsSearch} defaultValue={itemsSearch[0].value} onChange={handleChange} />
                </Grid>

                <Grid item xs={4} md={4}>
                    <Text fontWeight={'bold'} marginBottom={6}>Ordenar resultados por:</Text><br />
                    <Select key={itemsOrder[0].value} width={"60%"} name={"order"} items={itemsOrder} defaultValue={itemsOrder[0].value} register={register} errors={errors} />
                </Grid>
            </Grid>

            <ActualTemplate reset={resetFormState} register={register} errors={errors} setValue={setValue}/>

            <SearchButtons searchText={"Buscar clases particulares"} onResetClick={() => { setResetFormState(true) }} onSubmitClick={handleSubmit(() => { search(1, onLoadingCallback, searchCallback) })} />
        </Grid>
    )
}

/**
 * Hace la búsqueda de clases particulares
 * 
 * @param {Int}      page
 * @param {Function} onLoadingCallback
 * @param {Function} searchCallback
 * @param {Function} prepareParams
 */
export const search = (page = 1, onLoadingCallback, searchCallback) => {
    //Callback para controlar cuando esta cargando
    if (onLoadingCallback)
        onLoadingCallback(true)

    const params = prepareParamsGlobal(page)
    new RequestClasesParticulares().search(params, (res) => {
        if (searchCallback)
            searchCallback(res.items.data, res.items.total, page, params)
    }, () => {
        searchCallback([], 0, page, params)
    })
}

/**
 * Reset de todo el formulario
 * 
 * @param {Function} reset
 */
const resetForm = (reset) => {
    reset({
        nombre_alumno: "",
        apellido1_alumno: "",
        apellido2_alumno: "",
        nombre_profesor: "",
        apellido1_profesor: "",
        apellido2_profesor: "",
        // pendiente: 1,
        actual: 0
    })
}