import React, { Component } from 'react'
import NavLink from '../NavLink'

/**
 * Menu Item Group
 */
export default class MenuItem extends Component {

  //===================================================================================================
  //======================================= RENDER METHODS ============================================
  //===================================================================================================

  /**
   * Render
   * 
   * @returns {Component}
   */
  render() {
    return (
      <div className="menu-item" onClick={this.props.onClick}>
        <NavLink to={this.props.to ?? ""} ignoreSelected={this.props.ignoreSelected} exact={this.props.exact}>
          {this.props.icon ? <span className="menu-icon">{this.props.icon}</span> : <span className="menu-bullet"><span className="bullet bullet-dot"></span></span>}
          <span className="menu-title">{this.props.title ?? ""}</span>
        </NavLink>
      </div>
    )
  }
}
