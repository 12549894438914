import { COLOR } from 'theme/Colors'
import { TabContext } from '@mui/lab'
import { Text } from 'components/texts'
import Requests from './Requests/Requests'
import { useNavigate, useParams } from 'react-router'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import React, { useState, useEffect, useContext} from 'react'
import { EmpleadoListContext } from 'contexts/employee/ListContext'
import { BreadcrumbsList, MoveButtons } from 'components/data_display'
import { Box, Container, Paper, Stack, Tab, Tabs } from '@mui/material'
import { ColegioData, ActividadesData, TrabajadoresData } from './parts/'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'

const Wrapper = () => {
  const navigationParams = useParams()

  const { items } = useContext(EmpleadoListContext)

  const [colegio,    setColegio]   = useState(null)
  const [id_colegio, setIdColegio] = useState(navigationParams.id_colegio)
  const [tab,        setTab]       = useState("1")

  const updateAlert = (message, variant = "error") => {
    enqueueSnackbar(<Text>{message}</Text>, { variant })
  }

  const getColegio = () => {
    const item = items.find(item => item?.id == id_colegio)
    if(items.length == 0 || !item) {
      new Requests().getColegio(id_colegio, setColegio, (res) => updateAlert("No se ha podido cargar el colegio: " + res))
    } else {
      setColegio(item)
    }
  }

  useEffect(getColegio, [id_colegio])

  return (
    <React.Fragment>
      <SingleColegio key={colegio?.id ?? 0} tab={tab} setTab={setTab} colegio={colegio} id_colegio={id_colegio} setIdColegio={setIdColegio} setColegio={setColegio} />

      <SnackbarProvider autoHideDuration={3000} />
    </React.Fragment>
  )
}
export default Wrapper

function SingleColegio({ colegio, id_colegio, setIdColegio, setColegio, tab, setTab }) {
  const navigate = useNavigate()

  const updateId = (id) => {
    navigate(`../colegios/${id}`);
    setIdColegio(id);
  }

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <Stack direction="row" justifyContent={"space-between"} alignItems="center" marginX={8}>
        <BreadcrumbsList mx={0} breadcrumbsList={[
          { to: "/",     label: "Inicio" },
          { to: "./../", label: "Colegios" },
          { to: "./",    label: "Colegio #" + id_colegio, actualItem: true },
        ]} />

        <MoveButtons 
          actualItemId        = {id_colegio}
          beforeFetchCallback = {() => setColegio(null)} 
          onUpdateActualItem  = {updateId} />
      </Stack>

      <Paper elevation={2} sx={{mx: 8, mt: 2, p: 2}}>
        <TabContext value={tab}>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2}>
            <Tabs value={tab} onChange={(_, tab) => setTab(tab)} TabIndicatorProps={{style: {backgroundColor: COLOR.standard.main}}}>
              <Tab label={<Text fontWeight={tab == "1" ? "bold" : null} color={tab == "1" ? COLOR.standard.main : null}>DATOS DEL COLEGIO</Text>} value={"1"}/>
              <Tab label={<Text fontWeight={tab == "2" ? "bold" : null} color={tab == "2" ? COLOR.standard.main : null}>ACTIVIDADES</Text>}       value={"2"}/>
              <Tab label={<Text fontWeight={tab == "3" ? "bold" : null} color={tab == "3" ? COLOR.standard.main : null}>TRABAJADORES</Text>}      value={"3"}/>
            </Tabs>
          </Box>

          <TabPanel value={"1"}>
            <ColegioData colegio={colegio} navigate={navigate}/>
          </TabPanel>
          <TabPanel value={"2"}>
            <ActividadesData id_colegio={id_colegio} />
          </TabPanel>
          <TabPanel value={"3"}>
            <TrabajadoresData colegio={colegio} />
          </TabPanel>

        </TabContext>
      </Paper>

    </Container>
  )
}