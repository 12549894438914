import { H3 } from 'components/texts'
import { ASSETS } from 'constants/Assets'
import Statics from 'objects/common/Statics'
import { Line } from 'components/data_display'
import { Grid, Skeleton } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { EmpleadoPicker } from 'components/forms/components/custom'
import { DatePicker, Input, ImagePicker } from 'components/forms/components'
import { NewRegistroActividadColegioSelect, HorarioActividadColegioSelect, ItemsSelect, CursoEscolarSelect } from 'components/forms/components/Select/custom'

/**
 * Formulario con los datos generales del grupo
 */
export default function GeneralGrupoColegioDataForm({ grupo, register, errors, isMounted = false, setValue }) {

    const [cursoEscolar, setCursoEscolar] = useState(grupo?.id_curso_escolar ?? null)
    const [colegio, setColegio] = useState(grupo?.colegio ?? null)
    const [actividad, setActividad] = useState(grupo?.actividad ?? null)
    const [profesorActual, setProfesorActual] = useState(grupo?.profesor_actual?.empleado ?? null)
    const [numGrupo, setNumGrupo] = useState(grupo?.num_grupo ?? "")
    const [horario, setHorario] = useState(grupo?.horario ? grupo.horario.horario_formatted : null)
    const [firstLoop, setFirstLoop] = useState(true)
    const [wantToReset, setWantToReset] = useState({ registroActividad: false, horarioActividad: false })

    const claveInput = useRef()

    useEffect(() => {
        if (wantToReset.registroActividad || wantToReset.horarioActividad)
            setWantToReset({ registroActividad: false, horarioActividad: false })
    }, [wantToReset.registroActividad, wantToReset.horarioActividad])

    useEffect(() => {
        let clave = ""
        if (firstLoop) {
            setFirstLoop(false)
            clave = grupo?.clave ?? ""
        } else {

            if (colegio && colegio.nombre) {
                const label = Statics.removeWhiteSpaces(colegio.nombre)
                clave += label.length >= 5 ? label.substring(0, 5) : label
            }

            if (actividad && actividad.nombre) {
                const label = Statics.removeWhiteSpaces(actividad.nombre)
                clave += label.length >= 5 ? label.substring(0, 5) : label
            }

            clave += numGrupo

            if (horario) {
                const claveHorario = horario.split("]")[0] + "]"
                clave += claveHorario
            }
        }

        claveInput.current.setValue(clave)
        setValue("clave", clave)

    }, [colegio, actividad, numGrupo, horario])


    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     *
     * @returns {Component}
     */
    return (
        <Grid container px={5}>
            <Grid container spacing={2} mt={2} alignItems={"center"}>
                <Grid item xs={3}>
                    <ImagePicker
                        mimeTypes={['image/jpeg', 'image/png']}
                        register={register}
                        className="w-100"
                        name={"image"}
                        defaultImage={ASSETS.images + (grupo?.image ?? 'private/grupo_colegio/perfiles/0/default.png')} />
                </Grid>

                <Grid item xs={9}>
                    <Grid item container spacing={2}>
                        {grupo != undefined ?
                            <Grid item container display={"flex"} justifyContent={"end"}>
                                <Input label="Edad del grupo" defaultValue={grupo?.edadGrupo ?? ""} uppercase disabled />
                            </Grid>
                            : null}

                        <Grid item xs={12}>
                            <Input ref={claveInput} label="Clave del grupo" register={register} errors={errors} className="w-100" name={"clave"} defaultValue={grupo?.clave ?? ""} uppercase useValue />
                        </Grid>

                        <Grid item xs={12}>
                            {isMounted ?
                                <CursoEscolarSelect
                                    label={"Año"}
                                    name={"id_curso_escolar"}
                                    register={register}
                                    setValue={setValue}
                                    errors={errors}
                                    defaultValue={grupo?.id_curso_escolar ?? null}
                                    onChange={({ target }) => {
                                        setCursoEscolar(target.value)

                                        setActividad(null)
                                        setValue("id_registro_actividad", "")
                                        setValue("id_descripcion_horario", "")

                                        setWantToReset({ horarioActividad: true, registroActividad: true })
                                    }}
                                    searcheable />
                                : <Skeleton variant="rounded" width={"100%"} height={40} />}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={5}>
                <Grid item xs={8}>
                    {isMounted ? <ItemsSelect
                        name={"id_colegio"}
                        keyValue={"colegio"}
                        register={register}
                        errors={errors}
                        defaultValue={grupo?.id_colegio ?? ""}
                        onChange={(_, colegio) => {
                            setColegio(colegio)

                            setActividad(null)
                            setValue("id_registro_actividad", "")
                            setValue("id_descripcion_horario", "")

                            setWantToReset({ horarioActividad: true, registroActividad: true })
                        }}
                        searcheable /> : <Skeleton variant="rounded" width={"100%"} height={50} />}
                </Grid>

                <Grid item xs={4}>
                    {isMounted ? <NewRegistroActividadColegioSelect
                        register={register}
                        errors={errors}
                        defaultValue={grupo?.id_registro_actividad ?? ""}
                        id_colegio={colegio?.id ?? null}
                        id_curso_escolar={cursoEscolar}
                        wantToReset={wantToReset.registroActividad}
                        initialLabel={"Elige un colegio y el año para cargar las actividades."}
                        onChange={(_, actividad) => {
                            setActividad(actividad)

                            setValue("id_descripcion_horario", "")
                            setWantToReset({ horarioActividad: true, registroActividad: wantToReset.registroActividad })
                        }} /> : <Skeleton variant="rounded" width={"100%"} height={50} />}

                </Grid>
            </Grid>

            <Grid container spacing={2} mt={1}>
                <Grid item xs={4}>
                    {isMounted ? <HorarioActividadColegioSelect
                        register={register}
                        errors={errors}
                        wantToReset={wantToReset.horarioActividad}
                        defaultValue={grupo?.id_descripcion_horario ?? ""}
                        id_curso_escolar={cursoEscolar}
                        id_actividad={actividad?.id}
                        onChange={(_, horario) => setHorario(horario?.horario_formatted ?? null)} />
                        : <Skeleton variant="rounded" width={"100%"} height={50} />}
                </Grid>

                <Grid item xs={4}>
                    {isMounted ? <ItemsSelect
                        register={register}
                        errors={errors}
                        name={"id_nivel_grupo"}
                        keyValue={"nivel_grupo"}
                        defaultValue={grupo?.id_nivel_grupo ?? ""} /> : <Skeleton variant="rounded" width={"100%"} height={50} />}
                </Grid>

                <Grid item xs={4}>
                    <Input label="Curso escolar" register={register} errors={errors} className="w-100" name={"curso_escolar"} defaultValue={grupo?.curso_escolar_string ?? ""} uppercase />
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={1}>
                <Grid item xs={2}>
                    <Input label="Nº Grupo" register={register} errors={errors} className="w-100" name={"num_grupo"} onInput={({ target }) => setNumGrupo(target.value)} defaultValue={grupo?.num_grupo ?? ""} />
                </Grid>
                <Grid item xs={3}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_inicio"} label={"Fecha inicio"} defaultValue={grupo?.fecha_inicio} setValue={setValue} />
                </Grid>
                <Grid item xs={3}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_fin"} label={"Fecha fin"} defaultValue={grupo?.fecha_fin ?? null} setValue={setValue} disabled />
                </Grid>
                <Grid item xs={2}>
                    <Input label="Min Alumnos" register={register} errors={errors} className="w-100" name={"min_alumnos"} defaultValue={grupo?.min_alumnos ?? ""} />
                </Grid>
                <Grid item xs={2}>
                    <Input label="Max Alumnos" register={register} errors={errors} className="w-100" name={"max_alumnos"} defaultValue={grupo?.max_alumnos ?? ""} />
                </Grid>

            </Grid>

            <Grid container mt={4}>
                <Line />
            </Grid>

            <Grid container>
                <Grid item xs={6} p={3}>
                    <EmpleadoPicker
                        label={"Profesor del grupo"}
                        editLabel={"Cambiar profesor"}
                        addLabel={"Añadir profesor"}
                        deleteLabel={"Borrar profesor"}
                        name={"id_empleado"}
                        register={register}
                        empleado={profesorActual ?? null}
                        setValue={setValue}
                        //Si tiene más de un profesor ese grupo, ya no se podrá cambiar desde aqui
                        canEdit={grupo && profesorActual > 1 ? false : true}
                    />
                </Grid>

                <Grid item xs={6} style={{ borderLeft: "1px solid #D9D9D9" }} p={3}>
                    <H3>Observaciones del grupo:</H3>
                    <Input placeholder={"Escribe aquí alguna observación..."} register={register} errors={errors} className="w-100" name={"observaciones"} defaultValue={grupo?.observaciones ?? null} multiline minRows={6} maxRows={10} uppercase />
                </Grid>
            </Grid>
        </Grid>
    )
}
