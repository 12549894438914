import React from 'react'
import { Grid, Stack } from '@mui/material'
import { H3, Text } from 'components/texts'

export default function AulaData({ aula }) {

	return (
		<Grid container spacing={2}>
			<React.Fragment>
				<Grid item xs={12} mt={2}>
					<H3>Datos del aula:</H3>
				</Grid>

				<Grid item xs={8}>
					<Stack>
						<Text fontWeight={"bold"}>Denominación aula:</Text>
						<Text>{aula?.denominacion ?? ""}</Text>
					</Stack>
				</Grid>

				<Grid item xs={4}>
					<Stack>
						<Text fontWeight={"bold"}>Capacidad aula:</Text>
						<Text>{aula?.capacidad ?? ""}</Text>
					</Stack>
				</Grid>

			</React.Fragment>
		</Grid>
	)
}

