import { AlumnoData, DatosColegioData } from './parts'
import { RequestDatosColegio } from 'objects/requests'
import React, { useState, useEffect } from 'react'
import { Container, Paper, Stack } from '@mui/material'
import { useParams, useNavigate } from 'react-router-dom'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'

/**
 * Hook `useLogic` encapsula la lógica de negocio para la gestión de los datos escolares de un alumno.
 * Realiza la carga de estos datos desde el servidor utilizando el ID proporcionado a través de los parámetros
 * de navegación y permite actualizar el ID activo para navegar entre diferentes registros de datos escolares.
 * 
 * @param {Object} navigationParams - Parámetros de navegación que incluyen el ID de los datos escolares.
 * @returns {Object} Objeto que contiene las funciones y estados necesarios para interactuar con los datos escolares.
 */
function useLogic(navigationParams) {
  const navigate = useNavigate()
  const [id_datos_colegio, setIdDatosColegio] = useState(navigationParams.id_datos_colegio)
  const [datosColegio, setDatosColegio] = useState(null)

  /**
   * Obtiene los datos escolares del alumno desde el servidor, basado en el ID actual.
   * Establece relaciones necesarias para una representación completa de los datos.
   */
  const getDatosColegio = () => {
    const params = { relations: 'alumno,curso_escolar,curso_colegio,colegio,libro' }

    new RequestDatosColegio().get(id_datos_colegio, params, (res) => {
      if (res.item)
        setDatosColegio(res.item)
    })
  }

  /**
   * Actualiza el ID de los datos escolares en la URL para la navegación entre registros.
   * 
   * @param {number} id - Nuevo ID de los datos escolares para cargar.
   */
  const updateId = (id) => {
    navigate(`../alumnos/datos-colegio/${id}`);
    setIdDatosColegio(id);
  }

  useEffect(getDatosColegio, [id_datos_colegio])

  return { updateId, id_datos_colegio, datosColegio, setDatosColegio }
}

/**
 * Componente `SingleDatosColegioAlumno` para visualizar los datos escolares específicos de un alumno.
 * Utiliza el hook `useLogic` para manejar la recuperación y actualización de estos datos.
 * Presenta una interfaz que incluye navegación, visualización de datos escolares y datos del alumno.
 * 
 * @returns {JSX.Element} Estructura del contenedor para la visualización de datos escolares de un alumno.
 */
export default function SingleDatosColegioAlumno() {
  const navigationParams = useParams()

  const { updateId, id_datos_colegio, datosColegio, setDatosColegio } = useLogic(navigationParams)

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>

      <Stack direction="row" justifyContent={"space-between"} marginX={8}>
        <BreadcrumbsList mx={0} breadcrumbsList={[
          { to: "/", label: "Inicio" },
          { to: "./../../", label: "Alumnos" },
          { to: "./../", label: "Datos en colegios" },
          { to: "./", label: "Datos en colegios #" + (id_datos_colegio ?? "0000"), actualItem: true },
        ]} />

        <MoveButtons
          actualItemId={id_datos_colegio}
          beforeFetchCallback={() => setDatosColegio(null)}
          onUpdateActualItem={updateId} />
      </Stack>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>

        <AlumnoData datosColegio={datosColegio} />

        <Line className={"my-10"} />

        <DatosColegioData datosColegio={datosColegio} />
        
      </Paper>
    </Container>
  )
}
