import { Grid } from '@mui/material'
import { H3, Text } from 'components/texts'
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { ImagePicker, Input, DatePicker } from 'components/forms/components'
import { useImageLoader } from 'hooks'
import { DEFAULT_IMAGES_URL } from 'constants/DefaultImagesUR'
import { Tooltip } from "@mui/material"
import { QuestionMark as InfoIcon } from '@mui/icons-material'

/**
 * Componente `DataFormAlumno` para la edición y visualización de datos de un alumno.
 * Incluye campos para información personal, contacto, domicilio y datos de nacimiento.
 * Utiliza componentes de formulario personalizados como `Input`, `ItemsSelect` y `DatePicker`
 * para recoger y validar la entrada del usuario.
 * 
 * @param {Object} alumno - Objeto con los datos del alumno a editar.
 * @param {Function} register - Función de `react-hook-form` para registrar los campos del formulario.
 * @param {Object} errors - Objeto que contiene los errores de validación de `react-hook-form`.
 * @param {Function} setValue - Función de `react-hook-form` para establecer programáticamente los valores de los campos.
 * @returns {JSX.Element} - Un contenedor `Grid` que organiza los campos de formulario para la edición de datos del alumno.
 */
export default function DataFormAlumno({ alumno, register, errors, setValue }) {
    const { imageSrc } = useImageLoader(alumno, 'foto_logo', DEFAULT_IMAGES_URL.alumno, true)

    return (
        <Grid container px={5}>
            <Grid container alignItems={"center"}>
                <Grid item xs={4} md={2}>
                    {imageSrc ?
                        <ImagePicker
                            mimeTypes={['image/jpeg', 'image/png']}
                            register={register}
                            className="w-100"
                            name={"foto_logo"}
                            // defaultImage={ASSETS.images + (alumno?.foto_logo ?? 'private/alumno/perfiles/0/default.png')}
                            defaultImage={imageSrc} /> : null}
                </Grid>

                <Grid item xs={8} md={10} container spacing={2} px={2}>
                    <Grid item xs={6}>
                        <Input label="Nombre" register={register} errors={errors} className="w-100" name={"nombre"} defaultValue={alumno?.nombre ?? ""} uppercase />
                    </Grid>

                    <Grid item xs={6}>
                        <Input label="Primer apellido" register={register} errors={errors} className="w-100" name={"apellido1"} defaultValue={alumno?.apellido1 ?? ""} uppercase />
                    </Grid>

                    <Grid item xs={6}>
                        <Input label="Segundo apellido" register={register} errors={errors} className="w-100" name={"apellido2"} defaultValue={alumno?.apellido2 ?? ""} uppercase />
                    </Grid>

                    <Grid item xs={6}>
                        <ItemsSelect name={"sexo_id"} keyValue={"sexo"} register={register} errors={errors} defaultValue={alumno?.sexo_id ?? ""} />
                    </Grid>

                    <Grid item xs={6}>

                        <ItemsSelect name={"tipo_documento_id"} keyValue={"tipo_documento"} register={register} errors={errors} defaultValue={alumno?.tipo_documento_id ?? ""} />
                    </Grid>

                    <Grid item xs={6}>

                        <Input label="Numero de documento" register={register} errors={errors} className="w-100" name={"numero_documento"} defaultValue={alumno?.numero_documento ?? ""} uppercase />
                    </Grid>

                    <Grid item xs={6}>
                        <Input label="Email" register={register} errors={errors} className="w-100" name={"email"} defaultValue={alumno?.email ?? ""} uppercase />
                    </Grid>

                    <Grid item xs={6}>
                        <Input label="Email secundario" register={register} errors={errors} className="w-100" name={"email2"} defaultValue={alumno?.email2 ?? ""} uppercase />
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                    <H3 className={"m-0"}>Datos de contacto</H3>
                </Grid>

                <Grid item xs={6}>
                    <Input label="Teléfono principal" register={register} errors={errors} className="w-100" name={"telefono1"} defaultValue={alumno?.telefono1 ?? ""} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Teléfono secundario" register={register} errors={errors} className="w-100" name={"telefono2"} defaultValue={alumno?.telefono2 ?? ""} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Móvil principal" register={register} errors={errors} className="w-100" name={"tlfno_movil1"} defaultValue={alumno?.tlfno_movil1 ?? ""} />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Móvil secundario" register={register} errors={errors} className="w-100" name={"tlfno_movil2"} defaultValue={alumno?.tlfno_movil2 ?? ""} />
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                    <H3 className={"m-0"}>Datos domicilio actual</H3>
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect name={"tipo_via_id"} keyValue={"tipo_via"} className="w-100" register={register} errors={errors} defaultValue={alumno?.tipo_via_id ?? ""} />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Dirección" register={register} errors={errors} className="w-100" name={"nombre_via"} defaultValue={alumno?.nombre_via ?? ""} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Municipio" register={register} errors={errors} className="w-100" name={"municipio"} defaultValue={alumno?.municipio ?? ""} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Código postal" register={register} errors={errors} className="w-100" name={"cod_postal"} defaultValue={alumno?.cod_postal ?? ""} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect name={"provincia_id"} keyValue={"provincia"} className="w-100" register={register} errors={errors} defaultValue={alumno?.provincia_id ?? ""} />
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={2}>
                <Grid container item alignItems={"center"} xs={12}>
                    <H3 className={"m-0"}>Datos de nacimiento</H3>
                    <Tooltip arrow title={<Text>Si el alumno es menor de edad al indicar su fecha de nacimiento, se pedirá añadir un tutor.</Text>}>
                        <InfoIcon color={"info"} className={"mx-2"} />
                    </Tooltip>
                </Grid>

                <Grid item xs={6}>
                    <DatePicker register={register} errors={errors} className="w-100" name={"fecha_nacimiento"} label={"Fecha de nacimiento"} defaultValue={alumno?.fecha_nacimiento ?? ""} setValue={setValue} clearable />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Pais de nacimiento" register={register} errors={errors} className="w-100" name={"pais_nacimiento"} defaultValue={alumno?.pais_nacimiento ?? ""} uppercase />
                </Grid>

                <Grid item xs={6}>
                    <ItemsSelect label={"Provincia de nacimiento"} name={"provincia_nacimiento_id"} keyValue={"provincia"} register={register} errors={errors} defaultValue={alumno?.provincia_nacimiento_id ?? ""} />
                </Grid>

                <Grid item xs={6}>
                    <Input label="Municipio de nacimiento" register={register} errors={errors} className="w-100" name={"municipio_nacimiento"} defaultValue={alumno?.municipio_nacimiento ?? ""} uppercase />
                </Grid>
            </Grid>

        </Grid>
    )
}
