import Statics from "objects/common/Statics"
import { RequestClasesParticulares, RequestVentaLibro, RequestPagosClasesParticulares } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests {

    /**
     * Hace la petición para crear la clase particular
     * 
     * @param {Object}   values 
     * @param {Number}   operadorId - La ID del empleado logueado que ha dado de alta la inscripcion de la clase particular 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createClaseParticular(values, operadorId, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()

        //-- GENERAL --//
        formData.append('forma_recogida_id', values.id_forma_recogida)
        formData.append('empleado_id', operadorId)
        formData.append('forma_conocimiento_id', values.id_forma_conocimiento)
        formData.append('alumno_id', values.alumno_id)
        formData.append('curso_escolar_id', values.id_curso_escolar)
        formData.append('registro_actividad_id', values.registro_actividad_id)
        formData.append('precio_hora', values.precio_hora)
        formData.append('profesor_id', values.profesor_id)
        formData.append('encuestado', values.encuesta_telefonica ? 1 : 0)
        formData.append('alumno_colectivo', values.alumno_colectivo ? 1 : 0)
        formData.append('empresa_id', values.id_empresa)
        formData.append('avisado_inicio_clases', values.avisado_comienzo_clases ? 1 : 0)
        formData.append('observaciones', values.observaciones.toUpperCase())
        formData.append('origen_profesor_id', values.origen_profesor_id)
        formData.append('sexo_id', values.sexo_id)
        formData.append('clase_online', values.clase_online ? 1 : 0)
        formData.append('lugar_clase_id', values.lugar_clase_id)
        formData.append('comentarios', values.comentarios.toUpperCase())
        formData.append('cancelaciones', values.cancelaciones.toUpperCase())
        formData.append('fecha_alta', Statics.datePickerTimeFormat(values.fecha_inscripcion))
        formData.append('fecha_inicio', Statics.datePickerTimeFormat(values.fecha_inicio))
        formData.append('fecha_baja', Statics.datePickerTimeFormat(values.fecha_baja))

        //-- PAGOS --//
        formData.append('forma_pago_id', values.id_forma_pago)
        formData.append('observaciones_pagos', values.observaciones_pagos.toUpperCase())
        formData.append('contrato_emitido', values.contrato_emitido ? 1 : 0)
        formData.append('contrato_recibido', values.contrato_recibido ? 1 : 0)

        //-- LIBROS --//
        formData.append('libro_id', values.libro_id)

        new RequestClasesParticulares().create(formData, callbackOk, callbackError)
    }

    /**
     * Comprueba que peticion tiene que hacer
     */
    crudLibro(claseParticular, values, resolve, reject) {
        const libroSolicitado = values.libro_solicitado

        libroSolicitado ? this.createVentaLibro(claseParticular.id, values, resolve, reject) : resolve()
    }

    /**
     * Comprueba que petición tiene que hacer (para los libros asociados)
     */
    crudLibroAsociado(claseParticular, values, resolve, reject) {
        const libroAsociadoSolicitado = values.libro_asociado_solicitado

        libroAsociadoSolicitado ? this.createVentaLibroAsociado(claseParticular.id, values, resolve, reject) : resolve()
    }

    /**
     * Método para crear un registro de una venta de un libro
     * 
     * @param {Int}      id_clase_particular 
     * @param {Object}   values 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createVentaLibro(id_clase_particular, values, callbackOk = () => { }, callbackError = () => { }) {
        const formData = this.ventaLibroFormdata(values)
        formData.append('inscripcion_id', id_clase_particular)

        new RequestVentaLibro().create(formData, callbackOk, callbackError)
    }

    /**
     * Método para crear un registro de una venta de un libro asociado
     * 
     * @param {Int}      id_inscripcion 
     * @param {Object}   values 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createVentaLibroAsociado(id_clase_particular, values, callbackOk = () => { }, callbackError = () => { }) {
        const formData = this.ventaLibroFormdata(values, true)
        formData.append('inscripcion_id', id_clase_particular)

        new RequestVentaLibro().create(formData, callbackOk, callbackError)
    }

    /**
     * Crea y devuelve el FormData para las ventas del libro
     * 
     * @param {Object} values 
     */
    ventaLibroFormdata(values, asociado = false) {
        const sufix = asociado ? "_asociado" : ""

        const formData = new FormData
        formData.append('curso_escolar_id', values['id_curso_escolar'])
        formData.append('libro_id', values[`libro${sufix}_id`])
        formData.append('precio', values[`precio${sufix}`])
        formData.append('inscripcion_type', 'inscripcion-clase-particular')
        if (asociado)
            formData.append('asociado', 1)

        const fecha_peticion = values[`fecha${sufix}_peticion`]
        formData.append("fecha_peticion", Statics.datePickerTimeFormat(fecha_peticion))

        const fecha_pago = values[`fecha_pago${sufix}`]
        formData.append("fecha_pago", Statics.datePickerTimeFormat(fecha_pago))

        const fecha_entrega = values[`fecha_entrega${sufix}`]
        formData.append("fecha_entrega", Statics.datePickerTimeFormat(fecha_entrega))

        return formData
    }

    /**
     * Método para crear los pagos de una clase particular
     * 
     * @param {Int}      id_inscripcion 
     * @param {Object}   pagos 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    createPagos(id_clase_particular, pagos, callbackOk = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        if (!pagos || !Array.isArray(pagos)) {
            callbackOk()
        } else {
            const formData = new FormData()
            formData.append('inscripcion_type', 'inscripcion-clase-particular')
            formData.append('inscripcion_id', id_clase_particular)

            for (const index in pagos) {
                formData.append(`pagos[${index}][mes]`, pagos[index].mes)
                formData.append(`pagos[${index}][fecha]`, pagos[index].fecha ?? "")
                formData.append(`pagos[${index}][cantidad]`, pagos[index].cantidad ?? "")
                formData.append(`pagos[${index}][pagado]`, pagos[index].pagado ?? "")
                formData.append(`pagos[${index}][otros_cargos]`, pagos[index].otros_cargos ?? "")
                formData.append(`pagos[${index}][total]`, pagos[index].total ?? 0)
            }

            new RequestPagosClasesParticulares().createMultiple(formData, callbackOk, callbackError, callbackFinally)
        }
    }
}