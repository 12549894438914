import Modal from 'components/modals/Modal'
import React, { useEffect, useRef } from 'react'
import { GrupoColegioListSelector } from 'components/custom_selectors'

const relations = 'actividad,colegio,curso_escolar,profesor_actual,horario,inscripciones.alumno.faltas_asistencia.seguimiento_trimestral'

export default function SearchGrupoModal({ open = false, title='Buscar grupo de colegios', onSelectedCallback = () => {} }) {

    const modalRef = useRef()

    useEffect(() => {
        if(open)
            modalRef.current.open()
    }, [open])

    const onSelected = (item) => {
        modalRef.current.close()
        onSelectedCallback(item)
    }

    return (
        <Modal ref={modalRef} title={title}>
            <GrupoColegioListSelector onSelectedCallback={onSelected} relations={relations}/>
        </Modal>
    )
}
