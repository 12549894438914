import { useEffect, useState } from "react";

export default function useCheckMinorAge(watch) {
    const [esMenorDeEdad, setEsMenorDeEdad] = useState(false)

    const calcularEdad = (fechaNacimiento) => {
        const hoy = new Date()
        const fechaNac = new Date(fechaNacimiento)
        let edad = hoy.getFullYear() - fechaNac.getFullYear()
        const m = hoy.getMonth() - fechaNac.getMonth();
        if (m < 0 || (m === 0 && hoy.getDate() < fechaNac.getDate())) {
            edad--
        }
        return edad
    }

    const esMenor = (fechaNacimiento) => {
        const edad = calcularEdad(fechaNacimiento)
        return edad < 18
    }

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === 'fecha_nacimiento') {
                const menorDeEdad = esMenor(value.fecha_nacimiento)
                setEsMenorDeEdad(menorDeEdad)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    return { esMenorDeEdad }
}