import { 
	AlumnoMenu,
	ColegioMenu,
	LibroMenu,
	AcademiaMenu,
	SeguimientoCursoMenu,
	EmpleadoMenu,
	DashboardMenu,
	ClasesParticularesMenu,
	ListadosMenu
} from './menus'

export const sidebars = {
	"alumnos": 			 	<AlumnoMenu />,
	"colegios": 		 	<ColegioMenu />,
	"libros": 			 	<LibroMenu />,
	"academias": 		 	<AcademiaMenu />,
	"seguimiento-curso": 	<SeguimientoCursoMenu />,
	"empleados": 		 	<EmpleadoMenu />,
	"dashboard": 			<DashboardMenu />,
	"clases-particulares": 	<ClasesParticularesMenu />,
	"listados": 			<ListadosMenu />,
}