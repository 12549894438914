import React, { useEffect, useState } from 'react';
import { Box, Grid } from "@mui/material";
import { Datagrid } from 'components/data_display';
import { H3, Text } from 'components/texts';
import { CursoEscolarSelect } from 'components/forms/components/Select/custom';
import { COLOR } from 'theme/Colors.js'
import { Button } from 'components/forms/components';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';

const inputStyles = {
    padding: '7px 14px',
    border: '1px solid #ccc',
    borderRadius: '4px',
};

export default function DataCurriculumTable({ isFormDisabled, register, errors, setValue, name = 'datos_curriculum', curriculum }) {
    const [editedData, setEditedData] = useState(curriculum?.detalles || []);

    useEffect(() => {
        register(name);
        setEditedData(curriculum?.detalles || []);
    }, [curriculum?.detalles, register]);

    const addRow = () => {
        const newRow = { id: editedData.length + 1, descripcion: '', observaciones: '', creating: true }
        setEditedData([...editedData, newRow])
        setValue(name, [...editedData, newRow])
    }

    const handleCellEditCommit = (params) => {
        const { id, field, value } = params;
        const updatedData = editedData.map((row) => {
            if (row.id === id) {
                return { ...row, [field]: value };
            }
            return row;
        })

        setEditedData(updatedData);
        setValue(name, updatedData);
    }

    const columns = [
        { field: "descripcion", headerName: "Descripción", flex: 1, editable: !isFormDisabled },
        { field: "comentarios", headerName: "Comentarios", flex: 1, editable: !isFormDisabled },
        {
            field: "fecha_inicio", headerName: "Fecha de inicio", flex: 1, editable: !isFormDisabled, renderCell: (params) => (
                <input
                    type="date"
                    style={inputStyles}
                    disabled={isFormDisabled}
                    value={params.row.fecha_inicio}
                    onChange={(e) => handleCellEditCommit({ ...params, value: e.target.value, field: 'fecha_inicio' })}
                />
            ),
        },
        {
            field: "fecha_fin", headerName: "Fecha de fin", flex: 1, editable: !isFormDisabled, renderCell: (params) => (
                <input
                    style={inputStyles}
                    type="date"
                    disabled={isFormDisabled}
                    value={params.row.fecha_fin}
                    onChange={(e) => handleCellEditCommit({ ...params, value: e.target.value, field: 'fecha_fin' })}
                />
            ),
        },
    ]

    return (
        <Grid sx={{ width: '100%', mt: 2, display: 'flex', flexDirection: 'column' }}>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
                <Button
                    onClick={addRow}
                    disabled={isFormDisabled}
                    sx={{ maxWidth: '100px', mb: 2, backgroundColor: COLOR.standard.main }}>
                    Añadir
                </Button>
            </Box>

            <Grid item xs={12}>
                <Datagrid
                    rowHeight={38}
                    rows={editedData}
                    columns={columns}
                    onCellEditCommit={handleCellEditCommit}
                />
            </Grid>
        </Grid>
    )
}