import * as Yup from 'yup'

export const validation = {
    fecha_entrada: Yup.string().when('entrega_curriculum', {
        is: true,
        then: Yup.string().required('La fecha de entrada es obligatoria'),
    }),

    idioma_principal_id: Yup.string().when('entrega_curriculum', {
        is: true,
        then: Yup.string().required('El idioma principal es obligatorio'),
    }),
}

export const validationSchema = Yup.object().shape(validation)