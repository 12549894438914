import { Grid, Skeleton } from "@mui/material";
import { Line } from "components/data_display";

export default function DataFormPagoClasesParticularesSkeleton() {
    return (
        <Grid container py={3}>
            <Grid item xs={6} px={3}>
                <Skeleton variant="rounded" width={"100%"} height={25} />
            </Grid>

            <Grid item xs={6} px={3}>
                <Skeleton variant="rounded" width={"100%"} height={25} />
            </Grid>

            <Grid item xs={12} mt={3} px={3}>
                <Line />
            </Grid>

            <Grid container mx={3} mt={5} pb={2} sx={{ overflowX: "auto" }} className={"darker-scrollbar"}>
                <Skeleton variant="rounded" width={"100%"} height={100} />
            </Grid>

            <Grid container px={3} mt={5}>
                <Skeleton variant="rounded" width={"100%"} height={75} />
            </Grid>

            <Grid item xs={12} px={3} mt={5}>
                <Skeleton variant="rounded" width={"25%"} height={25} />
            </Grid>

            <Grid item xs={12} px={3} mt={5}>
                <Skeleton variant="rounded" width={"25%"} height={25} />
            </Grid>
        </Grid>
    )
}