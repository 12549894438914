import React, { useEffect, useState } from 'react'
import { ChangePasswordForm } from "../../../components/forms/display";
import { Link } from "../../../components/data_display";
import { useParams } from "react-router-dom";
import { RequestAuth } from '../../../objects/requests';
import Skeleton from './skeleton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { COLOR } from '../../../theme/Colors';
import { publicRoutes } from '../../../router/routes';
import { Text, H3 } from '../../../components/texts';
import { Alert } from '@mui/material';

/**
 * Component que renderiza el formulario de cambiar la contraseña, primero comprueba el token
 */
export default function ChangePassword() {
  const { token } = useParams()

  const [isLoading, setLoading] = useState(true)
  const [isValid, setValid] = useState(true)
  const [ended, setEnded] = useState(false)


  /**
   * Hacemos la peticion para comprobar el token
   */
  useEffect(() => {
    var formData = new FormData()
    formData.append('token', token)

    new RequestAuth().checkToken(formData, () => {
      setLoading(false)
    }, () => {
      setLoading(false)
      setValid(false)
    })
  }, [])


  //RETURN
  return (
    <div>
      {isLoading && !ended ? <Skeleton /> : null}

      {!isLoading && isValid && !ended ? <ChangePasswordForm token={token} setEnded={setEnded} /> : null}

      {!isLoading && !isValid && !ended ?
        (<Alert severity="warning">
          <H3>Alerta</H3>
          <Text>El token no es válido, vuelve a enviar el formulario para recibir el nuevo enlace.</Text>
        </Alert>) : null}

      {ended ?
        (<Alert severity="success">
          <H3>Completado</H3>
          <Text>Su contraseña se ha cambiado con éxito, ya puede volver al inicio.</Text>
        </Alert>) : null}
      <div className="mt-10 d-flex">
        <ArrowBackIcon htmlColor={COLOR.standard.main} className={"mx-2"} />

        <Link to={publicRoutes.HOME} size={"large"}>Ir al inicio</Link>
      </div>

    </div>
  )
}