import React, { useRef, useEffect, useState } from 'react'
import { H3 } from 'components/texts'
import { Grid, Stack } from '@mui/material'
import { Line } from 'components/data_display'
import { RequestFile } from 'objects/requests'
import { Button, Input } from 'components/forms/components'
import { useHandleDisabled } from 'hooks'

/**
 * Formulario con los datos de las programaciones de un libro
 */
export const FORMATS = {
    '.txt': 'text/plain',
    '.pdf': 'application/pdf',
}
export default function ProgramacionesAcademiaDataForm({ libro, register, getValues, grupo }) {
    const accept = ".txt,.pdf,.docx,.doc"

    const inputT1 = useRef(null)
    const inputFileT1 = useRef(null)
    const inputT2 = useRef(null)
    const inputFileT2 = useRef(null)
    const inputT3 = useRef(null)
    const inputFileT3 = useRef(null)

    const { disabled1T, disabled2T, disabled3T } = useHandleDisabled(libro)

    const showFile = (field) => {
        const array = libro[field]?.split('.')

        const type = array[array.length - 1]

        if (libro[field])
            new RequestFile().file(libro[field], FORMATS["." + type], {}, (res) => window.open(URL.createObjectURL(res), '_blank'))


        // DA ERROR getValues(field)[0] -> LO HE MODIFICADO POR LO DE ARRIBA. DE ESTA FORMA SÍ ABRE EN UNA PESTAÑA APARTE EL DOCUMENTO TXT O PDF

        // const file = getValues(field)[0]
        // if (file) {
        //     const tmppath = URL.createObjectURL(file)
        //     window.open(tmppath, '_blank')
        // } else if (libro[field]) {
        //     const array = libro[field].split('.')
        //     const type = array[array.length - 1]
        //     new RequestFile().file(libro[field], FORMATS["." + type], {}, (res) => {
        //         console.error("response", res)
        //     })
        // }
    }

    return (
        <Grid container pb={5} p={4}>
            <Grid container spacing={2} my={2}>
                <Grid item xs={12} xl={8}>
                    <Stack>
                        <H3>1º Trimestre</H3>
                        <Input key={Math.random()} className="w-100" defaultValue={libro?.programacion_1t ?? "..."} disabled useValue />
                    </Stack>
                </Grid>

                <Grid item xs={12} xl={4} display={"flex"} alignItems={"end"}>
                    <Stack direction={"row"} spacing={2} className={"w-100"}>
                        {grupo ? <Button className={"w-100 py-3"} onClick={() => { }}>
                            Enviar programación
                        </Button> : null}

                        <Button disabled={disabled1T} className={"w-100 py-3"} color={"cancel"} onClick={() => showFile("programacion_1t")}>
                            Ver programación
                        </Button>
                    </Stack>
                </Grid>

            </Grid>

            <Grid item xs={12} mt={2}>
                <Line />
            </Grid>

            <Grid container spacing={2} my={2}>
                <Grid item xs={12} xl={8}>
                    <Stack>
                        <H3>2º Trimestre</H3>
                        <Input key={Math.random()} className="w-100" defaultValue={libro?.programacion_2t ?? "..."} disabled useValue />
                    </Stack>
                </Grid>

                <Grid item xs={12} xl={4} display={"flex"} alignItems={"end"}>
                    <Stack direction={"row"} spacing={2} className={"w-100"}>
                        {grupo ? <Button className={"w-100 py-3"} onClick={() => { }}>
                            Enviar programación
                        </Button> : null}

                        <Button disabled={disabled2T} className={"w-100 py-3"} color={"cancel"} onClick={() => showFile("programacion_2t")}>
                            Ver programación
                        </Button>
                    </Stack>
                </Grid>
            </Grid>

            <Grid item xs={12} mt={2}>
                <Line />
            </Grid>

            <Grid container spacing={2} my={2}>
                <Grid item xs={12} xl={8}>
                    <Stack>
                        <H3>3º Trimestre</H3>
                        <Input key={Math.random()} className="w-100" defaultValue={libro?.programacion_3t ?? "..."} disabled useValue />
                    </Stack>
                </Grid>

                <Grid item xs={12} xl={4} display={"flex"} alignItems={"end"}>
                    <Stack direction={"row"} spacing={2} className={"w-100"}>
                        {grupo ? <Button className={"w-100 py-3"} onClick={() => { }}>
                            Enviar programación
                        </Button> : null}
                        <Button disabled={disabled3T} className={"w-100 py-3"} color={"cancel"} onClick={() => showFile("programacion_3t")}>
                            Ver programación
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    )
}