import React from 'react'
import { Grid } from '@mui/material'
import { Text } from 'components/texts'
import { Input } from 'components/forms/components'
import { ItemsSelect } from 'components/forms/components/Select/custom'
import { CursoEscolarSelect} from 'components/forms/components/Select/custom'

export default function DatosAlumnosColegiosTemplate({ reset, register, errors, setValue }) {

    return (
        <React.Fragment>
            <Grid container mt={2} mb={1}>
                <Text fontWeight={'bold'}>Según datos de:</Text><br />
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Input label="Colegio" width={"100%"} name={"nombre_colegio"} register={register} errors={errors} uppercase/>
                </Grid>

                <Grid item xs={6}>
                    <Input label="Curso" width={"100%"} name={"nombre_curso"} register={register} errors={errors} uppercase/>
                </Grid>

                <Grid item xs={6}>
                    <CursoEscolarSelect
                         label={"Año"}
                         name={"id_curso_escolar"}
                         wantToReset={reset}
                         register={register} 
                         errors={errors} 
                         defaultValue={null}
                         setValue={setValue} 
                         searcheable
                        />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}