import React, { Component } from 'react'
import { v4 as uuid } from 'uuid';
import { Select } from '../..';

/**
 * YesNoSelect. Un select para poder seleccionar entre si y no
 */
export default class YesNoSelect extends Component {

    /**
     * Constructor
     * 
     * @param {Object} props 
     */
    constructor(props) {
        super(props)

        this.state = {
            opciones: [{value: 0, label: "No"}, {value: 1, label: "Si"}]
        }
    }


    /**
     * ComponentDidUpdate
     */
    componentDidUpdate() {
        if(this.props.wantToReset)
            this.select.reset()
    }

    //-------------------------------------------------------
    //------------------- RENDER FUNCTIONS ------------------
    //-------------------------------------------------------

    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        const labelId = "sino_select-id-" + uuid()
        return (
            <Select 
                ref          = {(ref) => this.select = ref}
                width        = {this.props.width ?? "100%"}
                minWidth     = {this.props.minWidth}
                items        = {this.state.opciones}
                register     = {this.props.register}
                defaultValue = {this.props.defaultValue}
                onChange     = {this.props.onChange}
                className    = {this.props.className}
                name         = {this.props.name}
                errors       = {this.props.errors}
                labelId      = {labelId} 
                label        = {this.props.label}/>
        )
    }
}
