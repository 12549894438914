import React, { Component } from 'react'
import { MenuItem, MenuItemGroup } from 'components/navigation'
import { employeeRoutes } from 'router/routes'
import { 
    Mood           as AlumnosIcon,
    FamilyRestroom as ResponsableIcon,
    School         as DatosColegioIcon,
    Warning        as IncidenciaIcon,
} from '@mui/icons-material'

export default class AlumnoMenu extends Component {

    /**
     * ComponentDidMount
     * 
     * Inicializamos los componentes necesarios del tema
     */
    componentDidMount() {
        window.KTMenu?.createInstances()
        window.KTToggle?.createInstances()
    }


	/**
	 * Render
	 * 
	 * @returns {Component}
	 */
	render() {
		return (
            <div className="menu menu-column menu-rounded menu-sub-indention px-3" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">
                {/* <MenuItemGroup title="Principal">
                </MenuItemGroup> */}
                <div className="menu-item pt-5">
                    <div className="menu-content">
                        <span className="menu-heading fw-bold text-uppercase fs-7">Menú listados</span>
                    </div>
                </div>

                <MenuItemGroup title={"Alumnos"} icon={<AlumnosIcon />}>
                    <MenuItem title="Datos de los alumnos"  to={employeeRoutes.LISTADOS_ALUMNO_DATOS_ALUMNOS} icon={<AlumnosIcon />} />
                    <MenuItem title="Datos de los tutores"  to={employeeRoutes.LISTADOS_ALUMNO_TUTORES}       icon={<ResponsableIcon />}/>
                    <MenuItem title="Datos en los colegios" to={employeeRoutes.LISTADOS_ALUMNO_DATOS_COLEGIO} icon={<DatosColegioIcon />}/>
                    <MenuItem title="Datos incidencias"     to={employeeRoutes.LISTADOS_ALUMNO_INCIDENCIAS}   icon={<IncidenciaIcon />}/>
                </MenuItemGroup>

                <MenuItemGroup title={"Otra agrupacion de listados"}>
                </MenuItemGroup>

                <MenuItemGroup title={"Otra agrupacion de listados"}>
                </MenuItemGroup>

                <MenuItemGroup title={"Otra agrupacion de listados"}>
                </MenuItemGroup>
            </div>
		)
	}
}
