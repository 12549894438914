import { Skeleton } from '@mui/material';
import { H3 } from "components/texts"
import { Grid } from '@mui/material'
import React from 'react';


export default function SkeletonHorario() {
    return (
        <Grid container mt={2} px={4} spacing={2}>
            <Grid container mt={2} px={4} spacing={2}>
                <Grid item xs={12}>
                    <H3>
                        <Skeleton animation="wave" width={150} />
                    </H3>
                </Grid>
            </Grid>

            <Grid container mt={2} px={4} spacing={2}>
                <Skeleton variant="rectangular" width={'100%'} height={35} animation="wave" />

            </Grid>
            <Grid container mt={2} px={4} spacing={2}>
                <Skeleton variant="rectangular" width={'100%'} height={35} animation="wave" />
            </Grid>
        </Grid>
    );
}