import RequestMaster from '../RequestMaster'

export default class RequestDetalleFacturacionColegio extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/colegio/facturacion/detalle'
    }

        
    /**
     * Hace un edit multiple
     *
     * @param {FormData} params
     * @param {Function} callback
     * @param {Function} callbackError
     * @param {Function} callbackFinally
     */
    editMultiple(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "multiple"
        this.put(path, params, callback, callbackError, callbackFinally)
    }
}
