import React, { Component, useState } from 'react'
import { ChangePasswordForm } from "../../../components/forms/display";
import Skeleton from '@mui/material/Skeleton'

/**
 * Component que renderiza el formulario de cambiar la contraseña, primero comprueba el token
 * 
 * @returns {Component}
 */
export default function SkeletonChangePassword() {
    return (
      <div>
        <div className="my-10"></div>

        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        
        <div className="my-1"></div>

        <Skeleton variant="rounded" width={"100%"} height={50} />
        
        <div className="my-4"></div>
        
        <Skeleton variant="rounded" width={"100%"} height={50} />

        <div className="mb-10"></div>

        <Skeleton variant="rounded" width={"50%"} height={40} />
      </div>
    )
}
