import React, { useEffect } from 'react'
import { Box, Grid } from "@mui/material";
import { Datagrid } from 'components/data_display'
import moment from 'moment'
import { CursoEscolarSelect } from 'components/forms/components/Select/custom'

const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
        field: 'grupo',
        headerName: 'Grupo',
        width: 200,
        editable: true,
        valueGetter: (params) => { return params.row?.grupo?.colegio?.nombre }
    },
    {
        field: 'fecha_alta',
        headerName: 'Fecha Alta',
        width: 120,
        editable: true,
    },
    {
        field: 'horas_semanales',
        headerName: 'Horas Sem.',
        type: 'number',
        width: 150,
        editable: true,
        valueGetter: (params) => { return params.row?.grupo?.horario?.horas_semanales }

    },
    {
        field: 'profAct',
        headerName: 'Prof. Act.',
        type: 'number',
        width: 200,
        editable: true,
        valueGetter: (params) => {
            return params.row?.grupo?.profesor_actual?.empleado?.nombre + ' ' + params.row?.grupo?.profesor_actual?.empleado?.apellido1
                + ' ' + params.row?.grupo?.profesor_actual?.empleado?.apellido2
        }
    },
    {
        field: 'cantidad',
        headerName: 'Cantidad',
        type: 'number',
        width: 200,
        editable: true,
    },
    {
        field: 'formaCond',
        headerName: 'Forma Cond.',
        type: 'number',
        width: 200,
        editable: true,
    },
];

export default function GruposColegio({ setValue, onChangeCursoEscolar, gruposEmpleadoColegio = [] }) {
    const cursoActual = moment().subtract(1, 'year').format('YYYY') + "-" + moment().format('YYYY');
    return (
        <Grid container>
            <Grid item xs={12} marginTop={2} marginBottom={2}>
                <Box display="flex" justifyContent="flex-end">
                    <CursoEscolarSelect
                        label={"Curso escolar"}
                        name={"id_curso_escolar"}
                        onChange={({ target }) => onChangeCursoEscolar(target.value)}
                        defaultValue={null}
                        setValue={setValue}
                        searcheable />
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Datagrid
                    rows={Array.isArray(gruposEmpleadoColegio) ? gruposEmpleadoColegio : []}
                    columns={columns}
                />
            </Grid>
        </Grid >
    )
}