import RequestMaster from '../RequestMaster'

export default class RequestFichaje extends RequestMaster {

    constructor() {
        super()
        this.URL_BASE = this.URL_BASE + '/empleado/fichaje'
    }
   
    start(params, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "start"
        this.post(path, params, callback, callbackError, callbackFinally)
    }

    end(fichajeID, callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = `${fichajeID}/end`
        this.post(path, {}, callback, callbackError, callbackFinally)
    }

    current(callback = () => { }, callbackError = () => { }, callbackFinally = () => { }) {
        const path = "current"
        this.get(path, {}, callback, callbackError, callbackFinally)
    }
}
