import { columns } from './columns.js'
import { H3 } from 'components/texts'
import { Box, Container, Paper } from '@mui/material'
import { Search as SearchIcon } from '@mui/icons-material'
import { ListTableHeader } from 'pages/logged/admin/components'
import { Accordion, BreadcrumbsList, Datagrid } from 'components/data_display'
import SearchFormResponsable, { search } from 'components/forms/display/responsable/SearchForm'
import { useResponsableListLogic } from 'hooks/index.js'

/**
 * Componente `ListadoResponsables` para renderizar el listado de responsables o tutores.
 * Incluye una interfaz para la búsqueda y visualización de responsables, con paginación y
 * manejo dinámico del estado mediante el hook `useLogic`.
 * 
 * @returns {JSX.Element} El componente de UI para el listado de responsables.
 */
export default function ListadoResponsables() {
  const { items, rowCount, updateTable, loadingTable, setLoadingTable, params } = useResponsableListLogic()

  return (
    <Container maxWidth={false} sx={{ mt: 5 }}>
      <BreadcrumbsList breadcrumbsList={[
        { to: "/", label: "Inicio" },
        { to: "./", label: "Tutores", actualItem: true },
      ]} />

      <Accordion header={<Box display={"flex"} alignItems={"center"}><SearchIcon className={"mx-5"} /> <H3 className="my-0">Buscar tutores</H3></Box>}>
        <SearchFormResponsable
          searchCallback={updateTable}
          onLoadingCallback={setLoadingTable} />
      </Accordion>

      <Paper elevation={2} sx={{ mx: 8, my: 2, p: 5 }}>
        <ListTableHeader rowCount={rowCount} params={params} keyValue={"tutores"} />

        <Datagrid
          rows={items}
          columns={columns}
          loading={loadingTable}
          rowCount={rowCount}
          onPageChange={(page) => { search(page, () => setLoadingTable(true), updateTable) }}
          serverSide />
      </Paper>
    </Container>
  )
}