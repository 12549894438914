import { Text } from 'components/texts'
import React, { Component } from 'react'
import { ASSETS } from 'constants/Assets'
import { Avatar, Box, Stack } from '@mui/material'

/**
 * Item que muestra la información más básica de un empleado
 */
export default class EmpleadoBasicItem extends Component {

    constructor(props) {
        super(props)

        this.state = {
            empleado: this.props.empleado ?? null
        }
    }


    /**
     * ComponentDidUpdate
     * 
     * @param {Object} prevProps
     */
    componentDidUpdate(prevProps) {
        if (prevProps.empleado != this.props.empleado)
            this.setState({ empleado: this.props.empleado })
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        const { empleado } = this.state
        return (
            <Box display={"flex"} alignItems={"center"} mt={2}>
                <Avatar
                    alt = {"Foto de: " + (empleado.nombre ?? "")}
                    src = {`${ASSETS.images + (empleado.foto_logo ?? 'private/empleado/perfiles/0/default.png')}`}
                    sx  = {{ width: "20%", height: "100%", borderRadius: 2}}/>

                <Stack ml={4}>
                    <Text fontWeight={"bold"}>{(empleado.nombre ?? "") + " " + (empleado.apellido1 ?? "") + " " + (empleado.apellido2 ?? "")}</Text>
                    <Text>{empleado.email ?? ""}</Text>
                </Stack>
            </Box>
        )
    }
}