import React, { Component } from 'react'

export default class H1 extends Component {

  /**
   * Render
   * 
   * @returns {Component}
   */
  render() {
    return (
      <h1 className={'custom-h1 bold ' + (this.props.className ?? "")} style={this.props.style}>{this.props.children ?? ""}</h1>
    )
  }
}
