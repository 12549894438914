import React, { Component } from 'react'
import { MenuItem } from 'components/navigation'
import {
    Assessment   as ReportTrimestralIcon,
    Update       as ActualizarIcon,
    AttachEmail  as ComunicacionesIcon
} from '@mui/icons-material'
import { employeeRoutes } from 'router/routes'

export default class AcademiaMenu extends Component {

    /**
     * ComponentDidMount
     * 
     * Inicializamos los componentes necesarios del tema
     */
    componentDidMount() {
        window.KTMenu?.createInstances()
        window.KTToggle?.createInstances()
    }


    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <div className="menu menu-column menu-rounded menu-sub-indention px-3" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">

                <div className="menu-item pt-5">
                    <div className="menu-content">
                        <span className="menu-heading fw-bold text-uppercase fs-7">Seguimiento del curso</span>
                    </div>
                </div>

                <MenuItem title="Report trimestral" to={employeeRoutes.SEGUIMIENTO_CURSO_REPORT_TRIMESTRAL} icon={<ReportTrimestralIcon />} />
                <MenuItem title="Pagos PP.AA"       to={employeeRoutes.SEGUIMIENTO_CURSO_PAGOS_PPAA}        icon={<ActualizarIcon />} />
                <MenuItem title="Comunicaciones"    to={employeeRoutes.SEGUIMIENTO_CURSO_COMUNICACIONES}    icon={<ComunicacionesIcon />} />
            </div>
        )
    }
}
