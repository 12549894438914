import React, { useRef, useState } from 'react'
import { ChooseTipoRelacionModalCreate, ChooseTipoRelacionModalEdit } from 'components/modals'
import { ResponsableListSelector } from 'components/custom_selectors'

export default function ChooseListEdit({ setIsResponsableChosen, addResponsableModalEditRef, register, getValues, setResponsables, callbackOk, callbackError }) {
    const chooseTipoRelacionModalCreate = useRef()

    const [openRelationModal, setOpenRelationModal] = useState(false)

    const [selectedResponsable, setSelectedResponsable] = useState(null)

    const handleSelection = (responsable) => {
        setSelectedResponsable(responsable)
        setOpenRelationModal(true)
    }

    return (
        <React.Fragment>
            <ResponsableListSelector
                onSelectedCallback={handleSelection} />

            <ChooseTipoRelacionModalEdit
                setIsResponsableChosen={setIsResponsableChosen}
                ref={chooseTipoRelacionModalCreate}
                addResponsableModalEditRef={addResponsableModalEditRef}
                selectedResponsable={selectedResponsable}
                openRelationModal={openRelationModal}
                setOpenRelationModal={setOpenRelationModal}
                chooseTipoRelacionModalCreate={chooseTipoRelacionModalCreate}
                register={register}
                getValues={getValues}
                setResponsables={setResponsables}
                callbackOk={callbackOk}
                callbackError={callbackError} />
        </React.Fragment>
    )
}