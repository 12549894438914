import React from 'react'
import { TabContext } from '@mui/lab'
import { COLOR } from 'theme/Colors.js'
import { SnackbarProvider } from 'notistack'
import { useParams } from 'react-router-dom'
import Requests from './Requests/Requests.js'
import { Text } from 'components/texts/index.js'
import { EditHeader } from 'pages/logged/admin/components'
import { Box, Container, Paper, Tab, Tabs, Stack } from '@mui/material'
import { TabPanel } from 'components/modifications/mui/TabPanel/index.jsx'
import { useAcademiaGrupoEditLogic, useGrupoEditWrapperLogic } from 'hooks'
import { BreadcrumbsList, Line, MoveButtons } from 'components/data_display'
import { validationSchema } from 'components/forms/display/academia/grupos/DataForm/GeneralDataForm/validation.js'
import SkeletonDataFormIdiomaGrupoAcademia from 'components/forms/display/academia/grupos/DataForm/IdiomaDataForm/skeleton'
import SkeletonDataFormGeneralGruposAcademia from 'components/forms/display/academia/grupos/DataForm/GeneralDataForm/skeleton'
import SkeletonDataFormProgramacionesGrupoColegio from 'components/forms/display/academia/grupos/DataForm/ProgramacionesDataForm/skeleton'
import { GeneralGrupoAcademiaDataForm, IdiomaGrupoAcademiaDataForm, ProgramacionesAcademiaDataForm, HorariosAcademiasDataForm } from 'components/forms/display'

/**
 * Este componente Wrapper encapsula la lógica y UI para la edición de un grupo de academia.
 * Utiliza el hook `useGrupoEditWrapperLogic` para manejar el estado inicial y la lógica específica de la edición del grupo.
 * También gestiona la presentación de mensajes de notificación utilizando `SnackbarProvider`.
 * 
 * @returns {JSX.Element} Un fragmento React que contiene el componente EditGrupoAcademia y el proveedor de Snackbar.
 */
export default function Wrapper() {
    const navigationParams = useParams()

    const { grupo, setGrupo, tab, setTab, updateAlert, id_grupo, setIdGrupo, getGrupo } = useGrupoEditWrapperLogic(navigationParams, new Requests())

    return (
        <React.Fragment>
            <EditGrupoAcademia
                key={grupo?.id ?? 0}
                tab={tab}
                setTab={setTab}
                updateAlert={updateAlert}
                grupo={grupo}
                id_grupo={id_grupo}
                setIdGrupo={setIdGrupo}
                setGrupo={setGrupo}
                onSaveFinish={() => getGrupo(true)} />

            <SnackbarProvider autoHideDuration={3000} />
        </React.Fragment>
    )
}

/**
 * Este componente maneja la interfaz y lógica para la edición de un grupo específico de academia, incluyendo sus datos generales,
 * idiomas, programaciones y horarios. Se basa en un modelo de formulario dinámico y reactivo que se adapta según las acciones del usuario.
 * 
 * @param {Object} props - Propiedades pasadas al componente para controlar su comportamiento.
 * 
 * @param {Object} props.grupo - Objeto que contiene la información actual del grupo a editar.
 * @param {number} props.id_grupo - El ID único del grupo que se está editando.
 * @param {Function} props.setIdGrupo - Función para actualizar el ID del grupo en el estado superior.
 * @param {Function} props.setGrupo - Función para actualizar la información del grupo en el estado superior.
 * @param {Function} props.updateAlert - Función para activar alertas o notificaciones basadas en acciones del usuario.
 * @param {Function} props.onSaveFinish - Callback que se ejecuta después de una operación de guardado exitosa.
 * @param {string} props.tab - Controla el tab activo actual en la interfaz de usuario.
 * @param {Function} props.setTab - Función para cambiar el tab activo.
 * 
 * @returns {JSX.Element} - Un contenedor con todos los formularios y elementos de UI necesarios para editar la información del grupo de academia.
 */
function EditGrupoAcademia({ grupo, id_grupo, setIdGrupo, setGrupo, updateAlert, onSaveFinish, tab, setTab }) {
    const { libro, setLibro, headerRef, register, handleSubmit, errors, getValues, setValue, control, updateId, editGrupo } = useAcademiaGrupoEditLogic({ validationSchema: validationSchema, grupo, id_grupo, setIdGrupo, onSaveFinish, requests: new Requests() })
    return (
        <Container maxWidth={false} sx={{ mt: 5 }}>
            <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit(editGrupo)}>

                <Stack direction="row" justifyContent={"space-between"} alignItems="center" marginX={8}>
                    <BreadcrumbsList mx={0} breadcrumbsList={[
                        { to: "/", label: "Inicio" },
                        { to: "./../../", label: "Academia" }, //--> Te dirige al apartado Grupos
                        { to: "./../../", label: "Grupos" },
                        { to: "./../", label: "Grupo #" + (id_grupo ?? "0000") },
                        { to: "./", label: "Editar", actualItem: true },
                    ]} />

                    <MoveButtons
                        actualItemId={id_grupo}
                        beforeFetchCallback={() => setGrupo(null)}
                        onUpdateActualItem={updateId} />
                </Stack>
                <Paper elevation={2} sx={{ mx: 8, my: 2, pb: 5 }}>

                    <EditHeader
                        ref={headerRef}
                        title={"Editar grupo"}
                        item={grupo} />
                    <Line />

                    <TabContext value={tab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mx={2} mt={2}>
                            <Tabs value={tab} onChange={(e, tab) => { setTab(tab) }} TabIndicatorProps={{ style: { backgroundColor: COLOR.standard.main } }}>
                                <Tab label={<Text fontWeight={tab === "1" ? "bold" : null} color={tab === "1" ? COLOR.standard.main : null}>DATOS GENERALES</Text>} value={"1"} />
                                <Tab label={<Text fontWeight={tab === "2" ? "bold" : null} color={tab === "2" ? COLOR.standard.main : null}>IDIOMAS</Text>} value={"2"} />
                                <Tab label={<Text fontWeight={tab === "3" ? "bold" : null} color={tab === "3" ? COLOR.standard.main : null}>PROGRAMACIONES</Text>} value={"3"} />
                                <Tab label={<Text fontWeight={tab === "4" ? "bold" : null} color={tab === "4" ? COLOR.standard.main : null}>HORARIOS</Text>} value={"4"} />
                            </Tabs>
                        </Box>

                        <TabPanel value={"1"}>
                            {grupo ?
                                <GeneralGrupoAcademiaDataForm
                                    grupo={grupo}
                                    setValue={setValue}
                                    register={register}
                                    getValues={getValues}
                                    errors={errors}
                                /> : <SkeletonDataFormGeneralGruposAcademia />}
                        </TabPanel>

                        <TabPanel value={"2"}>
                            {grupo ?
                                <IdiomaGrupoAcademiaDataForm
                                    setValue={setValue}
                                    getValues={getValues}
                                    register={register}
                                    errors={errors}
                                    onChangeLibro={setLibro}
                                    grupo={grupo}
                                    control={control}
                                /> : <SkeletonDataFormIdiomaGrupoAcademia />}
                        </TabPanel>

                        <TabPanel value={"3"}>
                            {grupo ?
                                <ProgramacionesAcademiaDataForm
                                    setValue={setValue}
                                    grupo={grupo}
                                    libro={libro}
                                    getValues={getValues}
                                    register={register}
                                    errors={errors}
                                    control={control} /> : <SkeletonDataFormProgramacionesGrupoColegio />}
                        </TabPanel>

                        <TabPanel value={"4"}>
                            <HorariosAcademiasDataForm register={register} errors={errors} grupo={grupo} onSaveFinish={onSaveFinish} />
                        </TabPanel>
                    </TabContext>

                </Paper>

                <SnackbarProvider autoHideDuration={3000} />
            </form>
        </Container>
    )
}