import * as yup from 'yup';

const validationSchema = yup.object().shape({
    aula_id: yup.string().required('El aula es obligatoria'),
    hora_inicio: yup.string()
        .required('La hora de inicio es obligatoria')
        .test('hora_inicio-minima', 'La hora de inicio debe ser como mínimo 07:00', function (value) {
            if (!value) return true;
            return value >= '07:00';
        }),
    hora_fin: yup.string()
        .required('La hora de fin es obligatoria')
        .test('hora_fin-posterior', 'La hora de fin debe ser posterior a la hora de inicio', function (value) {
            const { hora_inicio } = this.parent;
            if (!hora_inicio || !value) return true;
            return hora_inicio < value;
        })
        .test('hora_fin-maxima', 'La hora de fin debe ser como máximo 21:00', function (value) {
            if (!value) return true;
            return value <= '21:00';
        }),
}).test(
    'daySelected',
    null,
    (obj) => {
        if(obj.l || obj.m || obj.x || obj.j || obj.v || obj.s)
            return true

        return new yup.ValidationError('Es obligatorio elegir al menos un día', null, 'daySelected')
    }
);

export default validationSchema;
