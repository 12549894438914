import React, { Component } from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { H3 } from "../../texts";
import { COLOR } from "../../../theme/Colors";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * Alerta que aparece flotando en la parte de la pantalla elegida
 */
export default class SnackbarAlert extends Component {

    /**
     * Constructor
     * 
     * @param {Object} props 
     */
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            message: this.props. message ?? "",
            severity: this.props.severity ?? "success"
        }
    }

    // --------------------------------------------------------------
    // ---------------------- CLASS FUNCTIONS -----------------------
    // --------------------------------------------------------------

    /**
     * Callback para cuando se clicke en cerrar la alerta
     */
    onClose = () => {
        this.setState({open: false})
    }


    /**
     * Actualiza el mensaje de la alerta
     * 
     * @param {String} message 
     */
    setMessage = (message) => {
        this.setState({message})
    }


    /**
     * Actualiza el tipo de la alerta
     * 
     * @param {String} severity 
     */
    setSeverity = (severity) => {
        this.setState({severity})
    }


    /**
     * Actualiza el estado de la alerta
     * 
     * @param {Boolean} open 
     */
    setOpen = (open) => {
        this.setState({open})
    }


    /**
     * Permite actualizar todo el estado de golpe
     * 
     * @param {Object} state 
     */
    updateState = (state) => {
        this.setState({
            message:    state.message   ?? this.state.message,
            severity:   state.severity  ?? this.state.severity,
            open:       state.open      ?? this.state.open,
        })
    }

    // --------------------------------------------------------------
    // ---------------------- RENDER FUNCTIONS -----------------------
    // --------------------------------------------------------------

    /**
     * Render
     * 
     * @returns {Component}
     */
    render() {
        return (
            <Snackbar open={this.state.open} autoHideDuration={this.props.autoHideDuration ?? 6000} onClose={this.onClose}>
                <Alert onClose={this.onClose} severity={this.state.severity} sx={{ width: '100%' }}>
                    <H3 style={{color: COLOR.white.main, marginBottom: 0}}>{this.state.message}</H3>
                </Alert>
            </Snackbar>
        );
    }
}
