import { useContext, useEffect, useState } from "react"
import { EmpleadoListContext } from "contexts/employee/ListContext"

/**
 * Hook personalizado que encapsula la lógica del componente, 
 * gestionando el estado de los datos de la tabla, las columnas a mostrar y la carga de datos.
 * 
 * @returns {Object}
 */
export default function useAlumnoDatosColegioListLogic({ keyColumns, basicColumns, columnsAlumnosColegios, columnsGrupoColegio, columnsGrupoCL }) {
    const [columns, setColumns] = useState(basicColumns.concat(columnsAlumnosColegios))

    const [loadingTable, setLoadingTable] = useState(false)

    const { items, rowCount, params, update } = useContext(EmpleadoListContext)

    useEffect(() => {
        setLoadingTable(false)
    }, [items])

    /**
     * Actualiza los datos de la tabla y el contexto global con los resultados obtenidos de la búsqueda.
     * 
     * @param {Array} data - Los datos obtenidos para llenar la tabla.
     * @param {number} rowCount - El número total de filas encontradas para la búsqueda realizada.
     * @param {number} page - La página actual de los resultados.
     * @param {Object} params - Los parámetros de búsqueda utilizados.
     */
    const updateTable = (newData, newRowCount, newPage, newParams) => update(newRowCount, newData, newParams, newPage)

    /**
     * Actualiza las columnas de la tabla basándose en la categoría de búsqueda seleccionada por el usuario.
     * 
     * @param {number} _ - Índice de la categoría seleccionada (no utilizado en esta función).
     * @param {string} keyColumn - La clave que identifica la categoría de búsqueda y determina las columnas a mostrar.
     */
    const updateColumns = (_, keyColumn) => setColumns(basicColumns.concat(keyColumns[keyColumn]))

    return { items, rowCount, setLoadingTable, params, loadingTable, columns, updateTable, updateColumns }
}