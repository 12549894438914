import { COLOR } from "theme/Colors"
import { Text } from "components/texts"
import React, { Component } from "react"
import { LoadingButton } from "@mui/lab"

const colorVariants = {
	standard: { buttonColor: "standard", textColor: "white", outlinedTextColor: COLOR.standard.main },
	success: { buttonColor: "success", textColor: "white", outlinedTextColor: COLOR.success.main },
	error: { buttonColor: "error", textColor: "white", outlinedTextColor: COLOR.error.main },
	cancel: { buttonColor: "cancel", textColor: "white", outlinedTextColor: COLOR.cancel.main }
}

export default class CustomLoadingButton extends Component {


	constructor(props) {
		super(props)

		this.state = {
			loading: this.props.loading ?? false,
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.loading != this.props.loading)
			this.setState({ loading: this.props.loading })
	}

	//-------------------------------------------------------
	//------------------- CLASS FUNCTIONS -------------------
	//-------------------------------------------------------

	/**
	 * Actualiza el estado del loading
	 * 
	 * @param {Boolean} loading
	 */
	setLoading = (loading) => {
		this.setState({ loading })
	}

	//-------------------------------------------------------
	//------------------- RENDER FUNCTIONS ------------------
	//-------------------------------------------------------

	/**
	 * Render
	 * 
	 * @returns {Component}
	 */
	render() {
		const colorVariant = colorVariants[this.props.colorVariant ?? "standard"]
		return (
			<>
				<LoadingButton
					size={this.props.size ?? "medium"}
					className={this.props.className ?? ""}
					loading={this.state.loading}
					loadingPosition={this.props.loadingPosition ?? "center"}
					variant={this.props.variant ?? "contained"}
					color={colorVariant.buttonColor}
					disabled={this.props.disabled}
					startIcon={this.props.startIcon}
					onClick={this.props.onClick}>

					<Text color={this.props.variant === "outlined" ? colorVariant.outlinedTextColor : colorVariant.textColor}>{this.props.children}</Text>
				</LoadingButton>
			</>
		)
	}
}
