import { Grid } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Checkbox, ColorInput, Input, LoadingButton } from 'components/forms/components'
import { CursoEscolarSelect, ItemsSelect, NewRegistroActividadColegioSelect } from 'components/forms/components/Select/custom'

/**
 * Formulario con los datos del alumno
 */
export default function DataFormHorarios({ register, errors, handleSubmit, setValue, wantToReset, hideButton = false, isMounted = false, onActividadSelected, resetHorarios = () => {}, loading = false }) {

    const loadingButton = useRef()

    const [colegio,              setColegioSelected]      = useState(null)
    const [cursoEscolar,         setCursoEscolarSelected] = useState(null)
    const [actividad,            setActividadSelected]    = useState(null)
    const [actividadWantToReset, setActividadWantToReset] = useState(false)

    const colegioChanged = ({ target }) => {
        setColegioSelected(target.value)
        setActividadWantToReset(true)
        setActividadSelected(null)
    }

    const cursoEscolarChanged = ({ target }) => {
        setCursoEscolarSelected(target.value)
    }

    const actividadChanged = ({ target }) => {
        setActividadSelected(target.value)
    }

    useEffect(() => {
        if(isMounted && loadingButton.current)
            loadingButton.current.setLoading(loading)
    })

    useEffect(() => {
        if(actividadWantToReset)
            setActividadWantToReset(false)
    }, [actividadWantToReset])

    useEffect(() => {
        resetHorarios()
        if(colegio && cursoEscolar && actividad)
            onActividadSelected(actividad, colegio, cursoEscolar)
    }, [colegio, cursoEscolar, actividad])

    return (
        <Grid container mt={2} px={4} spacing={2}>
            <Grid item xs={4}>
                {isMounted ? <ItemsSelect
                                name         = {"id_colegio"}
                                keyValue     = {"colegio"}
                                register     = {register}
                                errors       = {errors}
                                wantToReset  = {wantToReset} 
                                onChange     = {colegioChanged}
                                searcheable /> : null}
            </Grid>

            <Grid item xs={2}>
                {isMounted ? <CursoEscolarSelect name={"id_curso_escolar"} wantToReset={wantToReset} register={register} errors={errors} defaultValue={null} onChange={cursoEscolarChanged} setValue={setValue} searcheable /> : null}
            </Grid>

            <Grid item xs={4}>
                {isMounted ? <NewRegistroActividadColegioSelect id_colegio={colegio} register={register} errors={errors} wantToReset={wantToReset ?? actividadWantToReset} onChange={actividadChanged}/> : null}
            </Grid>

            <Grid item xs={2}>
                <Input label="Clave" register={register} errors={errors} className="w-100" name={"clave"} uppercase inputProps={{ maxLength: 1 }}/>
            </Grid>

            <Grid item xs={2}>
                <Input type={"time"} label="Hora inicio:" register={register} errors={errors} className="w-100" name={"hora_inicio"} InputLabelProps={{ shrink: true }} inputProps={{ min: '07:00', max: '20:30', step: 1800 }} uppercase />
            </Grid>

            <Grid item xs={2}>
                <Input type={"time"} label="Hora fin:" register={register} errors={errors} className="w-100" name={"hora_fin"} InputLabelProps={{ shrink: true }} inputProps={{ min: '07:30', max: '21:00', step: 1800  }} uppercase />
            </Grid>

            <Grid item xs={3} display={"flex"} justifyContent={"center"}>
                <Checkbox name={"l"} label={"L"} className={"flex-column"} register={register} />
                <Checkbox name={"m"} label={"M"} className={"flex-column"} register={register} />
                <Checkbox name={"x"} label={"X"} className={"flex-column"} register={register} />
                <Checkbox name={"j"} label={"J"} className={"flex-column"} register={register} />
                <Checkbox name={"v"} label={"V"} className={"flex-column"} register={register} />
                <Checkbox name={"s"} label={"S"} className={"flex-column"} register={register} />
            </Grid>

            <Grid item xs={2}>
                <ColorInput name={"color"} register={register} errors={errors} />
            </Grid>

            {hideButton ? null : 
                (<Grid item xs={12} justifyContent="end" display={"flex"}>
                    <LoadingButton ref={loadingButton} onClick={e => handleSubmit(e)}>
                        Añadir al horario
                    </LoadingButton>
                </Grid>)
            }
        </Grid>
    )
}
