import { Component } from 'react'
import { RequestAula } from "objects/requests"

/**
 * Clase donde se guardan las peticiones que se van a usar
 */
export default class Requests extends Component {


    /**
  * Devuelve el aula
  * 
  * @param {Int}      id 
  * @param {Function} callbackOk 
  * @param {Function} callbackError 
  */
    getAula(id, callbackOk = () => { }, callbackError = () => { }) {
        new RequestAula().get(id, {}, (res) => {
            res.item ? callbackOk(res.item) : callbackError(res)
        })
    }


    /**
     * Hace la petición para editar las aulas
     * 
     * @param {Int} id 
     * @param {Function} getValues 
     * @param {Function} callbackOk 
     * @param {Function} callbackError 
     */
    editAula(id, values, callbackOk = () => { }, callbackError = () => { }) {
        const formData = new FormData()
        formData.append("denominacion", values.denominacion.toUpperCase())
        formData.append("capacidad",    values.capacidad)

        new RequestAula().edit(id, formData, callbackOk, callbackError)
    }
}


